import React, { useRef, useState } from 'react'
import tw from 'twin.macro'
import { objectIsEmpty } from '../../utility/validationFunctions'
import useBreakpoint from '../../hooks/useBreakpoint'
import Icon from '../../styles/Icons'
import { Button, Status } from '../../styles/Layout'
import { Switch } from '../Layout/Switch'
import { InfoScreenFilters, InfoScreenStatus } from '../../enums'
import { FilterButton, FilterGrid, FilterInput } from '../Filter'
import { ActiveFilters } from '../Filter/ActiveFilters'
import { useInfoScreen } from '../../contexts/InfoScreenContext'

export const InfoScreenFilter = ({ filter }) => {
  const { andFilters, clearFilters, filters, setAndFilters } = filter
  const { infoScreens } = useInfoScreen()
  const { belowTarget } = useBreakpoint('sm')
  const inputRef = useRef()
  const [showFilters, setShowFilters] = useState(false)

  return (
    <Container>
      <FilterModeWrapper>
        <FilterMode>
          <pre>OR</pre>
          <Switch
            color="blue"
            size="sm"
            onToggle={() => setAndFilters((v) => !v)}
            defaultChecked={andFilters}
          />
          <pre>AND</pre>
        </FilterMode>

        <ButtonContainer>
          {!objectIsEmpty(filters) && (
            <Button.White
              size="sm"
              tw="m-0"
              onClick={() => {
                clearFilters()
                inputRef.current.value = ''
              }}
            >
              <Icon.Close size="sm" mr="2" />
              Clear{!belowTarget && <>&nbsp;Filters</>}
            </Button.White>
          )}
          <Button.Basic
            size="sm"
            tw="m-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? (
              <>
                <Icon.ChevronUp size="sm" mr="2" />
                Less{!belowTarget && <>&nbsp;Filters</>}
              </>
            ) : (
              <>
                <Icon.ChevronDown size="sm" mr="2" />
                More{!belowTarget && <>&nbsp;Filters</>}
              </>
            )}
          </Button.Basic>
        </ButtonContainer>
      </FilterModeWrapper>

      <FilterGrid>
        <FilterButton {...InfoScreenFilters.ACTIVE} {...filter} value={false}>
          <Status active={false} size="sm" />
          <pre>INACTIVE</pre>
        </FilterButton>

        <FilterButton {...InfoScreenFilters.DRAFT} {...filter}>
          {InfoScreenStatus.DRAFT.icon('sm')}
          <pre>{InfoScreenStatus.DRAFT.message}</pre>
        </FilterButton>

        <FilterButton {...InfoScreenFilters.ENDED} {...filter}>
          {InfoScreenStatus.ENDED.icon('sm')}
          <pre>{InfoScreenStatus.ENDED.message}</pre>
        </FilterButton>

        <FilterButton {...InfoScreenFilters.ERROR} {...filter}>
          {InfoScreenStatus.ERROR.icon('sm')}
          <pre>ERROR</pre>
        </FilterButton>

        <FilterButton {...InfoScreenFilters.ACTIVE} {...filter}>
          <Status active={true} size="sm" />
          <pre>ACTIVE</pre>
        </FilterButton>

        <FilterButton {...InfoScreenFilters.LIVE} {...filter}>
          {InfoScreenStatus.LIVE.icon('sm')}
          <pre>{InfoScreenStatus.LIVE.message}</pre>
        </FilterButton>

        <FilterButton {...InfoScreenFilters.UPCOMING} {...filter}>
          {InfoScreenStatus.UPCOMING.icon('sm')}
          <pre>{InfoScreenStatus.UPCOMING.message}</pre>
        </FilterButton>
      </FilterGrid>

      {showFilters && (
        <FilterInput
          {...InfoScreenFilters.QUERY}
          {...filter}
          placeholder="Filter query (id, name)"
          ref={inputRef}
        />
      )}
      <ActiveFilters {...filter} items={infoScreens} />
    </Container>
  )
}

const Container = tw.div`flex flex-1 flex-col self-center items-center z-20 pt-2 px-2 w-full sm:w-4/5 md:w-full xl:w-4/5 gap-1.5 select-none`
const FilterModeWrapper = tw.div`flex justify-between w-full items-center gap-2`
const FilterMode = tw.div`flex items-center h-full gap-2 bg-white-pure p-1 px-2 shadow-inner w-min rounded-md text-xs`
const ButtonContainer = tw.div`flex rounded-md overflow-hidden ml-auto`
