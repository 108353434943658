import { useCallback } from 'react'
import { auth, db } from '../../lib/firebase'

export const useGetParticipations = () => {
  // Cancel participation
  const cancelParticipation = useCallback(({ path }) => {
    db.doc(path).update({
      status: 'CANCELLED',
      cancelledAt: new Date(),
      cancelledBy: db.doc(`users/${auth.currentUser.uid}`)
    })
  }, [])

  return {
    cancelParticipation
  }
}

export default useGetParticipations
