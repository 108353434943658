import React, { useCallback, useEffect, useMemo } from 'react'
import circle from '@turf/circle'
import { Layer, Marker, Source } from 'react-map-gl'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'
import { mapLayers } from '../../Map/MapLayers'
import pointsWithinPolygon from '@turf/points-within-polygon'
import { feature, featureCollection } from '@turf/helpers'
import useS2Cells from '../../../hooks/useS2Cells'
import { useFormContext } from 'react-hook-form'
import { RouteInfo } from './RouteInfo'
import { useMap } from '../../../contexts/MapContext'
import { Crosshair } from '../../Map/Crosshair'
import { isValidCircle } from '../../../utility/validationFunctions'

export const HvntArea = () => {
  const {
    activeRoute,
    addHvntCircle,
    eligibleCheckpoints,
    innerPoints,
    setS2CellData,
    setInnerPoints
  } = useHvntRoute()
  const { focusOnFeature } = useMap()
  const { getS2RectsInFeature } = useS2Cells()
  const { setValue, watch } = useFormContext()
  const area = watch('area')

  const { centerPoint, distances = [], radius, saved } = area

  const startCircle = useMemo(() => {
    if (!activeRoute?.start) return
    if (!Number(distances.start)) return
    return circle(activeRoute.start.coordinates, distances.start, {
      units: 'meters'
    })
  }, [activeRoute, distances.start])

  const circleData = useMemo(
    () =>
      isValidCircle({ centerPoint, radius }) &&
      circle([centerPoint.longitude, centerPoint.latitude], radius, {
        units: 'meters'
      }),
    [centerPoint, radius]
  )

  // Moves hvnt circle
  const onDragEnd = useCallback(
    ({ lngLat }) => {
      const centerPoint = { longitude: lngLat.lng, latitude: lngLat.lat }
      addHvntCircle({ centerPoint, radius })
    },
    [addHvntCircle, radius]
  )

  // Zoom to hvnt circle
  useEffect(() => {
    circleData &&
      focusOnFeature(circleData, {
        padding:
          activeRoute && !saved
            ? { top: 270, bottom: 80, left: 320, right: 80 }
            : { top: 200, bottom: 130, left: 130, right: 130 }
      })
  }, [activeRoute, circleData, focusOnFeature, saved])

  // Update inner points
  useEffect(() => {
    if (!eligibleCheckpoints || !circleData) return
    setInnerPoints(pointsWithinPolygon(eligibleCheckpoints, circleData))

    const s2Cells = getS2RectsInFeature({ feature: circleData })
    setValue(
      's2Cells',
      s2Cells.map(({ properties }) => properties)
    )
    setS2CellData(featureCollection(s2Cells))
  }, [
    eligibleCheckpoints,
    circleData,
    getS2RectsInFeature,
    setInnerPoints,
    setS2CellData,
    setValue
  ])

  return (
    <>
      {circleData && (
        <>
          <Source type="geojson" data={circleData}>
            <Layer {...mapLayers.circle.scan} />
            <Layer {...mapLayers.circle.border} />
          </Source>
          <Source type="geojson" data={innerPoints ?? feature()}>
            <Layer {...mapLayers.hvnt.innerCheckpoint} />
          </Source>

          {!saved && (
            <>
              <Source type="geojson" id="routeData" data={activeRoute?.routeData ?? feature()}>
                <Layer {...mapLayers.hvnt.routeStops} />
                <Layer {...mapLayers.hvnt.routeIndex} />
              </Source>
              {activeRoute?.lineData && (
                <Source type="geojson" data={activeRoute?.lineData} lineMetrics={true}>
                  <Layer {...mapLayers.hvnt.route} beforeId={mapLayers.hvnt.routeStops.id} />
                </Source>
              )}
              <Source type="geojson" data={startCircle ?? feature()}>
                <Layer
                  {...mapLayers.hvnt.routeStart}
                  beforeId={mapLayers.hvnt.innerCheckpoint.id}
                />
              </Source>
            </>
          )}
          {!saved && (
            <>
              <RouteInfo route={activeRoute} />
              <Marker
                longitude={parseFloat(centerPoint?.longitude)}
                latitude={parseFloat(centerPoint?.latitude)}
                draggable={true}
                onDragEnd={onDragEnd}
              >
                <Crosshair />
              </Marker>
            </>
          )}
        </>
      )}
    </>
  )
}
