import React, { useCallback, useEffect, useRef, useState } from 'react'
import tw, { styled, theme } from 'twin.macro'
import { useAreas } from '../../contexts/AreasContext'
import { db, FieldValue } from '../../lib/firebase'
import Icon from '../../styles/Icons'
import { Button, Input, Status, MoreButton } from '../../styles/Layout'
import { Alerts, Toast } from '../../utility/alerts'
import { City } from './CityItem'
import { cityConverter } from '../../utility/formatAreas'
import { useMap } from '../../contexts/MapContext'
import RBAC from '../RBAC'
import { isValidCountry } from '../../utility/validationFunctions'

export const Country = ({
  editing = false,
  expanded,
  country = {},
  disabled,
  onActivate,
  onDeactivate,
  onDelete
}) => {
  const {
    activeCity,
    adding,
    countryBeingEdited,
    cityBeingEdited,
    expandCountry,
    removeActiveCity,
    saveCountry,
    setAdding,
    setCancel,
    setCityBeingEdited,
    startEditingCountry,
    stopEditingCity,
    stopEditingCountry
  } = useAreas()
  const { active, name, id } = country
  const nameRef = useRef()
  const idRef = useRef()
  const [addingCity, setAddingCity] = useState(false)
  const [centerPoint, setCenterPoint] = useState(country.centerPoint)
  const { focusOnBbox } = useMap()
  // Update centerpoint
  useEffect(() => {
    if (!editing || !countryBeingEdited) return
    setCenterPoint(countryBeingEdited?.centerPoint)
  }, [editing, countryBeingEdited])

  const onClickCountry = useCallback(() => {
    expandCountry(country)
    focusOnBbox(country.bbox, { padding: 50 })
  }, [country, expandCountry, focusOnBbox])

  const onReset = useCallback(() => {
    setAddingCity(false)
    stopEditingCity()
    setCancel(() => true)
  }, [setCancel, stopEditingCity])

  const onAddCity = useCallback(() => {
    setCityBeingEdited({})
    setAdding(true)
    setAddingCity(true)
  }, [setAdding, setCityBeingEdited])

  const onSaveCity = useCallback(
    async (city) => {
      await db.runTransaction(async (transaction) => {
        const countryRef = db.collection('playable-areas').doc(id)
        const countryDoc = await transaction.get(countryRef)

        const formattedCity = cityConverter.toFirestore({ id, ...city })
        transaction.set(countryDoc.ref, { cities: { [city.name]: formattedCity } }, { merge: true })
      })
      Toast.fire({
        title: 'City saved!',
        icon: 'success'
      })
      onReset()
    },
    [id, onReset]
  )

  const onDeactivateCity = useCallback(
    async ({ name }) => {
      const { value: ok } = await Alerts.Area.DEACTIVATE_CITY(name)
      if (!ok) return

      const countryRef = db.collection('playable-areas').doc(id)
      countryRef.set({ cities: { [name]: { active: false } } }, { merge: true })
      Toast.fire({
        title: 'City deactivated!',
        icon: 'success'
      })
      onReset()
    },
    [id, onReset]
  )

  const onActivateCity = useCallback(
    async ({ name }) => {
      await db.runTransaction(async (transaction) => {
        const countryRef = db.collection('playable-areas').doc(id)
        const countryDoc = await transaction.get(countryRef)
        const countryActive = countryDoc.get('active') ?? false

        if (countryActive) {
          transaction.set(
            countryDoc.ref,
            { cities: { [name]: { active: countryActive } } },
            { merge: true }
          )
          Toast.fire({
            title: 'City activated!',
            icon: 'success'
          })
          onReset()
        } else {
          await Alerts.Area.DEACTIVATE_FAILED()
        }
      })
    },
    [id, onReset]
  )

  const onDeleteCity = useCallback(
    async (city) => {
      const { name } = city
      const { value: ok } = await Alerts.Area.DELETE_CITY(name)
      if (!ok) return

      const countryRef = db.collection('playable-areas').doc(id)
      countryRef.set({ cities: { [name]: FieldValue.delete() } }, { merge: true })

      if (activeCity?.path === city.path) removeActiveCity()

      Toast.fire({
        title: 'City deleted!',
        icon: 'success'
      })
      onReset()
    },
    [activeCity, id, onReset, removeActiveCity]
  )

  // Todo use hook-form
  const onSaveCountry = useCallback(async () => {
    const name = nameRef.current?.value
    const id = adding ? idRef.current?.value.toLowerCase() : country.id
    const countryData = { centerPoint, id, name }

    if (isValidCountry(countryData)) {
      saveCountry(countryData)
    } else {
      Toast.fire({
        title: 'Invalid country!',
        icon: 'error'
      })
    }
  }, [adding, centerPoint, country, saveCountry])

  return (
    <Container key={country?.id}>
      <CountryContainer active={active} expanded={expanded} onClick={onClickCountry}>
        <Grid>
          <FieldCol tw="col-span-1 justify-center items-center">
            <Status active={active} />
          </FieldCol>
          <FieldCol tw="col-span-4">
            <div tw="ml-2">
              {editing ? (
                <>
                  <FieldTitle>Country Name</FieldTitle>
                  <Input.Default size="sm" ref={nameRef} defaultValue={name} />
                </>
              ) : (
                name || '-'
              )}
            </div>
          </FieldCol>
          <FieldCol tw="col-span-4 truncate">
            <div>
              {editing ? (
                <>
                  <FieldTitle>LatLng (Drag marker to move)</FieldTitle>
                  {centerPoint ? (
                    <>
                      <Coord>{`${centerPoint?.latitude.toPrecision(8)} ,`}&nbsp;</Coord>
                      <Coord>{`${centerPoint?.longitude.toPrecision(8)}`}</Coord>
                    </>
                  ) : (
                    <Coord>Double click the map</Coord>
                  )}
                </>
              ) : (
                <>
                  <Coord>{`${centerPoint?.latitude.toPrecision(8)} ,`}&nbsp;</Coord>
                  <Coord>{`${centerPoint?.longitude.toPrecision(8)}`}</Coord>
                </>
              )}
            </div>
          </FieldCol>
        </Grid>

        {editing ? (
          <div tw="flex mx-1 gap-1">
            <Button.Submit
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                onSaveCountry()
              }}
              tw="text-sm"
            >
              <Icon.Check size="sm" />
            </Button.Submit>
            <Button.White
              size="sm"
              onClick={() => {
                setCancel(() => ({ cancel: true }))
                stopEditingCountry()
              }}
              tw="text-sm"
            >
              <Icon.Close size="sm" />
            </Button.White>
            {!adding && (
              <>
                <MoreButton
                  size="sm"
                  tw="m-0 p-1 hover:bg-matrix-400 active:bg-matrix-500"
                  color={theme`colors.matrix.100`}
                  content={
                    <>
                      {active ? (
                        <Button.Primary size="sm" ring onClick={() => onDeactivate(country)}>
                          <Icon.Moon size="sm" mr="2" /> Deactivate
                        </Button.Primary>
                      ) : (
                        <Button.Secondary size="sm" ring onClick={() => onActivate(country)}>
                          <Icon.Sun size="sm" mr="2" /> Activate
                        </Button.Secondary>
                      )}
                      <RBAC>
                        <Button.Warning size="sm" ring onClick={() => onDelete(country)}>
                          <Icon.Trashcan mr="2" /> Delete
                        </Button.Warning>
                      </RBAC>
                    </>
                  }
                />
              </>
            )}
          </div>
        ) : (
          <div>
            <EditCountryButton disabled={disabled} onClick={() => startEditingCountry(country)}>
              <Icon.Edit />
            </EditCountryButton>
          </div>
        )}
      </CountryContainer>
      {expanded && (
        <CitiesContainer>
          {addingCity && (
            <City
              city={cityBeingEdited}
              disabled={disabled}
              editing={true}
              onCancel={onReset}
              onSave={onSaveCity}
            />
          )}
          {country?.cities?.map((city, i) => (
            <City
              key={i}
              city={city}
              disabled={disabled}
              editing={city.name === cityBeingEdited?.name}
              onActivate={onActivateCity}
              onCancel={onReset}
              onDeactivate={onDeactivateCity}
              onDelete={onDeleteCity}
              onSave={onSaveCity}
            />
          ))}
          <AddCityButton disabled={disabled} onClick={onAddCity}>
            <Icon.Plus size="sm" mr="2" />
            {`Add City ${country?.name ? `in ${country.name}` : ''}`}
          </AddCityButton>
        </CitiesContainer>
      )}
    </Container>
  )
}

const Container = tw.div`flex flex-col w-full mb-4 shadow-md rounded-md`
const CountryContainer = styled.div`
  ${tw`flex p-2 justify-between items-center text-lg cursor-pointer `}
  ${({ expanded }) =>
    expanded
      ? tw`bg-matrix-300 rounded-t-md`
      : tw`bg-matrix-200 hover:bg-matrix-300 active:bg-matrix-400 rounded-md`}
`

const CitiesContainer = tw.div`flex flex-col bg-matrix-50 h-auto p-4`
const EditCountryButton = tw(Button.Basic)`hover:bg-matrix-400 active:bg-matrix-500`
const AddCityButton = tw(Button.Gray)`m-0 border-2 border-dashed border-gray-400`

const Grid = styled.div`
  ${tw`grid gap-2 w-full`}
  grid-template-columns: repeat(9, minmax(0, 1fr));
`
const FieldCol = tw.div`flex flex-col overflow-x-scroll`
const FieldTitle = tw.div`font-medium text-sm sticky left-0`
const Coord = tw.div`inline-flex text-gray-900 text-sm`
