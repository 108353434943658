export const TransactionTypes = Object.freeze({
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT'
})

export const TransactionSorts = Object.freeze({
  COINS_AMOUNT: (arr, inc) => arr.sort((a, b) => (inc ? b.amount - a.amount : a.amount - b.amount)),
  COINS_NEW: (arr, inc) =>
    arr.sort((a, b) => (inc ? b.newBalance - a.newBalance : a.newBalance - b.newBalance)),
  LEVEL_NEW: (arr, inc) =>
    arr.sort((a, b) => (inc ? b.level?.new - a.level?.new : a.level?.new - b.level?.new)),
  REASON: (arr, inc) =>
    arr.sort((a, b) =>
      inc ? a.reason?.localeCompare(b.reason) : b.reason?.localeCompare(a.reason)
    ),
  TIMESTAMP: (arr, inc) =>
    arr.sort((a, b) => {
      var aHas = !!a.timestamp
      var bHas = !!b.timestamp
      if (aHas && bHas) return inc ? b.timestamp - a.timestamp : a.timestamp - b.timestamp
      return aHas ? -1 : bHas ? 1 : 0
    }),
  TYPE: (arr, inc) =>
    arr.sort((a, b) => (inc ? a.type?.localeCompare(b.type) : b.type?.localeCompare(a.type))),
  XP_AMOUNT: (arr, inc) =>
    arr.sort((a, b) => (inc ? b.xp?.amount - a.xp?.amount : a.xp?.amount - b.xp?.amount)),
  XP_NEW: (arr, inc) => arr.sort((a, b) => (inc ? b.xp?.new - a.xp?.new : a.xp?.new - b.xp?.new))
})
