// https://react-select.com/styles#overriding-the-theme
import tw, { theme } from 'twin.macro'
import flags from '../../assets/country-flags.json'

export const CitySelectTheme = (t) => ({
  ...t,
  borderRadius: 6,
  borderWidth: 0,
  colors: {
    ...t.colors,
    primary: theme`colors.bismark.300`, //    list item selected
    primary25: theme`colors.bismark.300`, //  list item hover
    primary50: theme`colors.bismark.400`, //  list item click
    neutral0: theme`colors.bismark.700`, // background
    neutral5: theme`colors.bismark.700`, // background disabled
    neutral10: theme`colors.bismark.500`, // separator disabled
    neutral20: theme`colors.bismark.400`, //  x, separator, arrow
    neutral40: theme`colors.bismark.200`, //  arrow hover,
    neutral50: theme`colors.bismark.400`, //  text placeholder
    neutral60: theme`colors.bismark.400`, //   arrow active
    neutral80: theme`colors.bismark.200` //  text filled
  },
  spacing: {
    baseUnit: 2,
    controlHeight: 40
  }
})

export const CitySelectStyle = {
  container: (style, { isDisabled }) => ({
    ...style,
    ...tw`flex h-9 border-0`,
    ...(isDisabled && { ...tw`opacity-50` })
  }),
  control: (style) => ({
    ...style,
    ...tw`flex items-center px-1 cursor-pointer border-0 w-full min-height[auto] box-shadow[none] hover:bg-bismark-600`
  }),
  groupHeading: (style) => ({ ...style, ...tw`text-bismark-400` }),
  menu: (style) => ({
    ...style,
    ...tw`mt-1 text-left bg-bismark-200`
  }),
  singleValue: (style, { isDisabled, data }) => ({
    ...style,
    ...(data.city ? cityDot(data) : countryDot(data)),
    ...(isDisabled && { color: theme`colors.bismark.500` })
  }),
  option: (style, { data }) => {
    return {
      ...style,
      ...(data.city ? cityOption(data) : countryOption(data)),
      ...tw`cursor-pointer`
    }
  }
}

const status = tw`h-2 w-2 ring-2 content ml-1 mr-2 rounded-full flex-shrink-0`
const cityDot = ({ active = false }) => ({
  ...tw`flex items-center`,
  ':before': {
    ...status,
    background: active ? theme`colors.green.400` : theme`colors.red.400`,
    '--tw-ring-color': active ? theme`colors.green.600` : theme`colors.red.600`
  }
})

const countryDot = ({ value }) => ({
  ...tw`flex items-center`,
  ':before': {
    ...tw`mr-1.5`,
    content: `"${flags.find((c) => c.iso.toLowerCase() == value)?.flag}"`
  }
})

const cityOption = ({ active = false }) => ({
  ...tw`flex items-center`,
  ':before': {
    ...status,
    ...tw`ml-2`,
    background: active ? theme`colors.green.400` : theme`colors.red.400`,
    '--tw-ring-color': active ? theme`colors.green.600` : theme`colors.red.600`
  }
})
const countryOption = ({ active = false, value }) => ({
  ...tw`flex items-center tracking-wide font-medium text-xl`,
  ':before': {
    ...status,
    background: active ? theme`colors.green.400` : theme`colors.red.400`,
    '--tw-ring-color': active ? theme`colors.green.600` : theme`colors.red.600`
  },
  ':after': {
    ...tw`ml-1.5`,
    content: `"${flags.find((c) => c.iso.toLowerCase() == value)?.flag}"`
  }
})
