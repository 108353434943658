import React from 'react'
import Icon from '../styles/Icons'
import { theme } from 'twin.macro'
import uniq from 'lodash/uniq'
import { ClueType } from './Clue'

export const EventType = Object.freeze({
  ALBANO: 'albano'
})

export const EventStatusType = Object.freeze({
  DRAFT: 'DRAFT',
  ENDED: 'ENDED',
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING'
})

export const EventFormTab = Object.freeze({
  DETAILS: 'DETAILS',
  LOCATIONS: 'LOCATION'
})

export const EventLocationType = Object.freeze({
  AR: 'AR',
  HIDDEN: 'HIDDEN',
  LOGO: 'LOGO',
  TRIVIA: 'TRIVIA'
})

export const LocationOptions = Object.freeze({
  AR: { reward: 1000, timeMultiplier: 1, numberOfLocations: 3 },
  HIDDEN: { clueCost: 200, reward: 1200, numberOfLocations: 1 },
  LOGO: { reward: 400, numberOfLocations: 3 },
  TRIVIA: { reward: 400, numberOfLocations: 8 }
})

export const HiddenClueCost = Object.freeze({
  [ClueType.IMAGE]: 600,
  [ClueType.STREET_VIEW]: 600,
  [ClueType.TEXT]: 400,
  [ClueType.TREASURE]: 600
})

export const EventStatus = Object.freeze({
  DRAFT: {
    icon: Object.assign((size = 'lg') => (
      <Icon.Clipboard color={theme`colors.gray.600`} size={size} />
    )),
    locked: false,
    message: 'DRAFT',
    removable: true,
    sort: 4
  },
  ENDED: {
    icon: Object.assign((size = 'lg') => <Icon.Flag color={theme`colors.gray.600`} size={size} />),
    locked: true,
    message: 'ENDED',
    removable: false,
    sort: 5
  },
  ERROR: {
    icon: Object.assign((size = 'lg') => <Icon.Error color={theme`colors.orange`} size={size} />),
    locked: true,
    message: 'ERROR',
    removable: false,
    sort: 1
  },
  LIVE: {
    icon: Object.assign((size = 'lg') => <Icon.Live color={theme`colors.green`} size={size} />),
    locked: true,
    message: 'LIVE',
    removable: false,
    sort: 3
  },

  UPCOMING: {
    icon: Object.assign((size = 'lg') => <Icon.Clock color={theme`colors.green`} size={size} />),
    locked: false,
    message: 'UPCOMING',
    removable: false,
    sort: 2
  },
  UNSET: {
    icon: Object.assign((size = 'lg') => (
      <Icon.DotsHorizontal color={theme`colors.gray.600`} size={size} />
    )),
    locked: false,
    message: 'UNSET',
    removable: true,
    sort: 6
  }
})

export const EventFilters = Object.freeze({
  ACTIVE: {
    field: 'active',
    filter: (arr, value) =>
      value ? arr.filter((item) => item.active) : arr.filter((item) => !item.active)
  },
  DRAFT: {
    field: 'draft',
    filter: (arr) => arr.filter(({ status }) => status === EventStatus.DRAFT)
  },
  ENDED: {
    field: 'ended',
    filter: (arr, value) =>
      value
        ? arr.filter(({ status }) => status === EventStatus.ENDED)
        : arr.filter(({ status }) => status !== EventStatus.ENDED)
  },
  ERROR: {
    field: 'error',
    filter: (arr) => arr.filter((item) => item.errors?.length)
  },
  LIVE: {
    field: 'live',
    filter: (arr) => arr.filter(({ status }) => status === EventStatus.LIVE)
  },
  QUERY: {
    field: 'query',
    filter: (arr, value) => {
      const filteredIds = arr.filter((item) => item.id?.toLowerCase().indexOf(value) !== -1)
      const filteredEntryCodes = arr.filter(
        (item) => item.entryCode?.toLowerCase().indexOf(value) !== -1
      )
      const filteredNames = arr.filter(
        (item) => item.name && item.name?.toLowerCase().indexOf(value) !== -1
      )
      const filteredTitles = arr.filter(
        (item) => item.title && item.title?.toLowerCase().indexOf(value) !== -1
      )
      const filteredTags = arr.filter((item) =>
        item.tags?.some((tag) => tag.label?.indexOf(value) !== -1)
      )

      return uniq(
        filteredNames
          .concat(filteredIds)
          .concat(filteredEntryCodes)
          .concat(filteredTags)
          .concat(filteredTitles)
      )
    }
  },
  TAGS: {
    field: 'tags',
    filter: (arr, tags) =>
      arr.filter((item) => item.tags?.some((tag) => tags?.some((ref) => ref.value === tag.value)))
  },
  UPCOMING: {
    field: 'upcoming',
    filter: (arr) => arr.filter(({ status }) => status === EventStatus.UPCOMING)
  }
})

export const EventSorts = Object.freeze({
  ACTIVE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => !!b.active - !!a.active)
      : arr?.sort((a, b) => !!a.active - !!b.active),
  END_DATE: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.dateRange?.endDate
      const bHas = !!b.dateRange?.endDate
      if (aHas && bHas)
        return inc
          ? b.dateRange?.endDate - a.dateRange?.endDate
          : a.dateRange?.endDate - b.dateRange?.endDate
      return aHas ? -1 : bHas ? 1 : 0
    }),
  NAME: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.name?.localeCompare(b.name))
      : arr?.sort((a, b) => b.name?.localeCompare(a.name)),
  START_DATE: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.dateRange?.startDate
      const bHas = !!b.dateRange?.startDate
      if (aHas && bHas)
        return inc
          ? b.dateRange?.startDate - a.dateRange?.startDate
          : a.dateRange?.startDate - b.dateRange?.startDate
      return aHas ? -1 : bHas ? 1 : 0
    }),
  STATUS: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.status?.sort - b.status?.sort)
      : arr?.sort((a, b) => b.status?.sort - a.status?.sort),
  TYPE: (arr, inc) =>
    inc ? arr?.sort((a, b) => a.type - b.type) : arr?.sort((a, b) => b.type - a.type)
})

export const EventLocationSorts = Object.freeze({
  ACTIVE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => !!b.active - !!a.active)
      : arr?.sort((a, b) => !!a.active - !!b.active),
  NAME: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.name?.localeCompare(b.name))
      : arr?.sort((a, b) => b.name?.localeCompare(a.name)),
  TYPE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.type?.localeCompare(b.type))
      : arr?.sort((a, b) => b.type?.localeCompare(a.type))
})
