import React from 'react'
import { useControl } from 'react-map-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import './geocoderStyle.css'

export const GeocoderControl = ({ speed = 8, zoom }) => (
  <Control
    mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
    position="top-right"
    collapsed={true}
    flyTo={{ speed, ...(zoom && { zoom }) }}
  />
)

function Control(props) {
  const geocoder = useControl(
    () => {
      const ctrl = new MapboxGeocoder({
        ...props,
        clearOnBlur: true,
        collapsed: true,
        marker: false,
        accessToken: props.mapboxAccessToken
      })
      ctrl.on('loading', props.onLoading)
      ctrl.on('results', props.onResults)
      ctrl.on('result', (evt) => {
        props.onResult(evt)
      })
      ctrl.on('error', props.onError)
      return ctrl
    },
    {
      collapsed: props.collapsed,
      position: props.position
    }
  )

  if (geocoder._map) {
    if (geocoder.getProximity() !== props.proximity && props.proximity !== undefined) {
      geocoder.setProximity(props.proximity)
    }
    if (geocoder.getRenderFunction() !== props.render && props.render !== undefined) {
      geocoder.setRenderFunction(props.render)
    }
    if (geocoder.getLanguage() !== props.language && props.language !== undefined) {
      geocoder.setLanguage(props.language)
    }
    if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
      geocoder.setZoom(props.zoom)
    }
    if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
      geocoder.setFlyTo(props.flyTo)
    }
    if (geocoder.getPlaceholder() !== props.placeholder && props.placeholder !== undefined) {
      geocoder.setPlaceholder(props.placeholder)
    }
    if (geocoder.getCountries() !== props.countries && props.countries !== undefined) {
      geocoder.setCountries(props.countries)
    }
    if (geocoder.getTypes() !== props.types && props.types !== undefined) {
      geocoder.setTypes(props.types)
    }
    if (geocoder.getMinLength() !== props.minLength && props.minLength !== undefined) {
      geocoder.setMinLength(props.minLength)
    }
    if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
      geocoder.setLimit(props.limit)
    }
    if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {
      geocoder.setFilter(props.filter)
    }
    if (geocoder.getOrigin() !== props.origin && props.origin !== undefined) {
      geocoder.setOrigin(props.origin)
    }
  }
  return false
}

const noop = () => {}

Control.defaultProps = {
  collapsed: true,
  marker: true,
  onLoading: noop,
  onResults: noop,
  onResult: noop,
  onError: noop
}
