import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
  useReducer
} from 'react'
import { useHvnt } from '../contexts/HvntContext'
import { useAreas } from '../contexts/AreasContext'
import { useMap } from '../contexts/MapContext'
import { actionTypes, hvntListReducer } from '../reducers/hvntListReducer'
import { useUpdateEffect } from './useUpdateEffect'
import uniqBy from 'lodash/uniqBy'
import { featureCollection } from '@turf/helpers'
import circle from '@turf/circle'
import { HvntColor, HvntFilters, HvntSorts, HvntTab } from '../enums'
import { db } from '../lib/firebase'
import { hvntConverter } from '../utility/formatHvnts'
import { Alerts } from '../utility/alerts'
import useFilter from './useFilter'
import useSort from './useSort'
import { isValidCircle } from '../utility/validationFunctions'

export const HvntListContext = createContext()

export const HvntListProvider = ({ children }) => {
  const { activeCity } = useAreas()
  const { activeTab, hvnts, startEditingHvnt } = useHvnt()
  const { focusOnCircle } = useMap()
  const { applyFilters, ...filterMethods } = useFilter(HvntFilters.ACTIVE, false)
  const { applySort, ...sortMethods } = useSort(HvntSorts.START_DATE)

  const [expandedItem, setExpandedItem] = useState()
  const [filteredItems, setFilteredItems] = useState([])
  const [fetchingHvnt, setFetchingHvnt] = useState(false)
  const [listItems, dispatch] = useReducer(hvntListReducer, [])

  const assignColor = useCallback((i) => HvntColor[i % Object.keys(HvntColor).length], [])
  const selectedItems = useMemo(() => listItems.filter((item) => item.selected), [listItems])

  // Updates hvnt areas
  const hvntAreas = useMemo(() => {
    const arr = expandedItem
      ? uniqBy([...selectedItems, expandedItem], 'id')
      : filteredItems?.length
      ? uniqBy([...selectedItems, ...filteredItems], 'id')
      : selectedItems.length
      ? selectedItems
      : filteredItems
    return featureCollection(
      arr
        .filter(({ area }) => isValidCircle(area))
        .map((hvnt) => {
          const { area, index } = hvnt
          return circle(
            [parseFloat(area.centerPoint.longitude), parseFloat(area.centerPoint.latitude)],
            area.radius,
            {
              units: 'meters',
              properties: { color: assignColor(index), ...hvnt }
            }
          )
        })
    )
  }, [assignColor, expandedItem, filteredItems, selectedItems])

  // Pre-fetch hvnt and navigate to specified path
  const visitDeepRoute = useCallback(
    async ({ edit, id }) => {
      try {
        setFetchingHvnt(true)
        if (!id) return
        if (!activeCity?.path) return Alerts.Hvnt.DEEP_LINK_CHANGE_CITY()

        const hvntDoc = await db
          .doc(`hvnts/${activeCity.path}/${id}`)
          .withConverter(hvntConverter)
          .get()

        if (!hvntDoc.exists) return Alerts.Hvnt.DEEP_LINK_CHANGE_CITY(activeCity.name)

        const hvnt = hvntDoc.data()
        if (edit) return startEditingHvnt(hvnt)
        else setExpandedItem(hvnt)
      } catch (err) {
        Alerts.General.ERROR(err)
        console.error(err)
      } finally {
        setFetchingHvnt(false)
      }
    },
    [activeCity, startEditingHvnt]
  )

  // Filter and sort items
  useEffect(() => {
    setFilteredItems(applySort(applyFilters(listItems)))
  }, [applyFilters, applySort, listItems, setFilteredItems])

  // Zooms to hvnt on expand
  useEffect(() => {
    expandedItem && focusOnCircle(expandedItem.area, { padding: 180 })
  }, [expandedItem, focusOnCircle])

  // Sets which hvnts to display as list items
  useUpdateEffect(() => {
    if (!activeCity || !activeTab) return
    switch (activeTab) {
      case HvntTab.REGULAR:
        dispatch({ type: actionTypes.update, hvnts: hvnts.regular })
        break
      case HvntTab.UNLISTED: {
        // Filter out hvnts that match active city
        const unlistedHvnts = hvnts.unlisted.filter((hvnt) => hvnt.cityPath === activeCity.path)
        dispatch({ type: actionTypes.update, hvnts: unlistedHvnts })
        break
      }
    }
  }, [activeCity, activeTab, hvnts])

  const defaultValues = useMemo(
    () => ({
      ...filterMethods,
      ...sortMethods,
      assignColor,
      expandedItem,
      fetchingHvnt,
      filteredItems,
      hvntAreas,
      listItems,
      selectedItems,
      setExpandedItem,
      visitDeepRoute
    }),
    [
      filterMethods,
      assignColor,
      expandedItem,
      fetchingHvnt,
      filteredItems,
      hvntAreas,
      listItems,
      selectedItems,
      sortMethods,
      visitDeepRoute
    ]
  )
  return <HvntListContext.Provider value={defaultValues}>{children}</HvntListContext.Provider>
}

// Hook
export const useHvntList = () => {
  const context = useContext(HvntListContext)
  if (context === undefined)
    throw new Error('`useHvntList` hook must be used within a `HvntListProvider` component')
  return context
}
