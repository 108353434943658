import React, { useCallback, useEffect, useMemo, useState } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Marker } from 'react-map-gl'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { Button, Status } from '../../../styles/Layout'
import RBAC from '../../RBAC'
import { capitalize } from '../../../utility/helperFunctions'
import { useAreas } from '../../../contexts/AreasContext'
import { Role } from '../../../enums'
import { Pin } from '../../Map/Markers/Pin'

const MARKER_SIZE = 25

export const CityMarker = ({ city, onClick, editing }) => {
  const { active, centerPoint, name = 'New City' } = city
  const { isMounted } = useIsMounted()
  const { cancel, cityBeingEdited, countryBeingEdited, startEditingCity, updateCity } = useAreas()
  const startPosition = useMemo(
    () => ({
      latitude: parseFloat(centerPoint?.latitude ?? 0),
      longitude: parseFloat(centerPoint?.longitude ?? 0)
    }),
    [centerPoint]
  )
  const [hover, setHover] = useState(false)
  const [position, setPosition] = useState(startPosition)
  const showEdit = useMemo(
    () => !!hover && !editing && !countryBeingEdited && !cityBeingEdited,
    [cityBeingEdited, countryBeingEdited, editing, hover]
  )

  // Resets marker's position on cancel
  useEffect(() => {
    if (!isMounted() || !cancel) return
    setPosition(startPosition)
    setHover(false)
  }, [cancel, isMounted, startPosition])

  const onMarkerDragEnd = useCallback(
    async ({ lngLat }) => {
      const currentPosition = {
        latitude: lngLat.lat,
        longitude: lngLat.lng
      }
      // Update marker's position
      setPosition(currentPosition)
      // Send updated values to the form
      updateCity({ centerPoint: currentPosition })
    },
    [updateCity]
  )

  return useMemo(
    () => (
      <Marker
        key={name}
        longitude={position.longitude}
        latitude={position.latitude}
        draggable={editing}
        onDragEnd={onMarkerDragEnd}
        offset={[0, -MARKER_SIZE / 2]}
      >
        <Pin
          onClick={() => onClick({ city })}
          size={MARKER_SIZE}
          fill={editing ? theme`colors.gold` : theme`colors.matrix`}
          stroke={editing ? theme`colors.gold.600` : theme`colors.matrix.600`}
        />
        <Popup
          role="popup"
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => setHover(false)}
          size={MARKER_SIZE}
        >
          <NamePlate onClick={() => onClick({ city })} hover={!editing && hover}>
            {editing ? (
              <Col>
                <DragToMove>Drag to Move</DragToMove>
                <Name tw="mt-2">
                  <strong>{capitalize(name)}</strong>
                </Name>
              </Col>
            ) : (
              <>
                <Status active={active} tw="mr-2" />
                <Name>{capitalize(name)}</Name>
              </>
            )}

            <RBAC allow={[Role.CREATOR]}>
              {showEdit && (
                <EditButton size="sm" onClick={() => startEditingCity(city)}>
                  Edit
                </EditButton>
              )}
            </RBAC>
          </NamePlate>
        </Popup>
      </Marker>
    ),
    [
      active,
      showEdit,
      city,
      editing,
      hover,
      name,
      onMarkerDragEnd,
      onClick,
      position,
      startEditingCity
    ]
  )
}

//prettier-ignore
const Popup = styled.div.attrs(({ size }) => ({ size }))
` ${tw`absolute bottom-0`}
  ${({ size }) =>css`transform: translate(${size}px, ${-size}px);`}`

const DragToMove = tw.div`absolute -top-3 left-0 py-1 rounded-t-md w-full minWidth[90px] bg-norway-700 text-white-pure text-xs`
const NamePlate = styled.div`
  ${tw`flex py-2 px-3 rounded-lg rounded-bl-none shadow-lg justify-between items-center 
text-center cursor-pointer`}
  ${({ hover }) => (hover ? tw`bg-norway-600` : tw`bg-norway`)}
`
const Col = tw.div`flex flex-col`
const Name = tw.span`font-sans font-light whitespace-nowrap`
const EditButton = tw(Button.White)`m-0 ml-3`
