import React from 'react'
import tw from 'twin.macro'
import { PageLayout } from '../../styles/Layout'
import config from '../../config'

export default function HomePage() {
  return (
    <PageLayout>
      <Image src={config.background} />
    </PageLayout>
  )
}

const Image = tw.img`fixed top-0 left-0 object-cover w-full h-full select-none`
