import React from 'react'
import tw, { css, styled } from 'twin.macro'

export const CountryItem = ({ locked, name, onRemove }) => {
  return (
    <Container locked={locked} onClick={onRemove}>
      {name}
    </Container>
  )
}

const Container = styled.div`
  ${tw`text-base bg-white-pure  p-2 px-4 rounded-md `}
  ${({ locked }) =>
    !locked &&
    css`
       {
        ${tw`hover:bg-sky-300 active:bg-sky-400 cursor-pointer`}
        &:hover::after {
          float: right;
          content: '\u2715';
        }
      }
    `}
`
