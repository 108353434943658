import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { ChallengeList } from '../Challenge/ChallengeList'
import { CreateChallenge } from '../Challenge/CreateChallenge'
import { PageLayout } from '../../styles/Layout'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { useChallenge } from '../../contexts/ChallengeContext'

export const ChallengePage = () => {
  const { adding, challengeBeingEdited } = useChallenge()
  const navigate = useNavigate()

  // Update Routes through side effects
  useEffect(() => {
    if (!challengeBeingEdited) navigate('', { replace: true })
  }, [adding, navigate, challengeBeingEdited])

  return (
    <Routes>
      <Route
        element={
          <Page>
            <List>
              <Outlet />
            </List>
            <Image src="https://res.cloudinary.com/dozsbtdha/image/upload/e_saturation:-60,f_auto,q_auto/v1661341916/forrestfenn/DALL_E_2022-08-22_22.08.31_-_A_golden_v_buried_in_a_lush_green_forest_j8lpi6.png" />
          </Page>
        }
      >
        <Route path="" element={<ChallengeList />} />
        <Route path="/*" element={<CreateChallenge challenge={challengeBeingEdited} />} />
      </Route>
    </Routes>
  )
}

export default ChallengePage

const Page = tw(PageLayout)`flex-col md:flex-row`
const List = tw.div`flex w-full md:w-1/2 h-full shadow-md z-10`
const Image = tw.img`fixed top-0 right-0 object-cover w-auto h-full z-0`
