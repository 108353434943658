import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  add: 'ADD_PRIZE',
  addMany: 'ADD_MANY_PRIZES',
  edit: 'EDIT_PRIZE',
  remove: 'REMOVE_PRIZE'
}

export const prizeReducer = (state, action) => {
  // console.log({ state, action })

  switch (action.type) {
    case actionTypes.add: {
      const { prize } = action
      return uniqBy([...state, { ...prize }], 'id')
    }

    case actionTypes.addMany: {
      const { prizes } = action
      return uniqBy([...state, ...prizes], 'id')
    }

    case actionTypes.edit: {
      const { prize } = action
      return state.map((item) => (item.id === prize.id ? { ...item, ...prize } : item))
    }

    case actionTypes.remove: {
      const { prize } = action
      return state.filter((item) => item.id !== prize.id)
    }

    default:
      return state
  }
}
