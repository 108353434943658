import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  add: 'ADD_CHALLENGE',
  addMany: 'ADD_MANY_CHALLENGES',
  edit: 'EDIT_CHALLENGE',
  remove: 'REMOVE_CHALLENGE',
  clear: 'CLEAR_CHALLENGES'
}

export const challengeReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.add: {
      const { challenge } = action
      return uniqBy([...state, challenge], 'id')
    }

    case actionTypes.addMany: {
      const { challenges } = action
      return uniqBy([...state, ...challenges], 'id')
    }

    case actionTypes.edit: {
      const { challenge } = action
      return state.map((item) => (item.id === challenge.id ? challenge : item))
    }

    case actionTypes.remove: {
      const { challenge } = action
      return state.filter((item) => item.id !== challenge.id)
    }

    case actionTypes.clear: {
      return []
    }
    default:
      return state
  }
}
