import React from 'react'
import tw, { theme } from 'twin.macro'
import { usePlayer } from '../../contexts/PlayerContext'
import { List } from '../../layout/List'
import { Card } from '../../styles/Layout'
import { Loader } from '../Loader/Loader'
import { FindPlayer } from './FindPlayer'
import { Items } from './PlayerItems'

export const PlayerList = ({ items }) => {
  const { loading } = usePlayer()

  return (
    <Card.Container header={'Users'} color={theme`colors.amethyst.400`}>
      <FindPlayer />
      {!!items?.length && (
        <List.Header>
          <CenterData colSpan={1}>Enabled</CenterData>
          <List.HeaderData colSpan={4}>Email</List.HeaderData>
          <List.HeaderData colSpan={4}>Phone Number</List.HeaderData>
        </List.Header>
      )}

      <List.Container>
        <Loader loading={loading} text="Fetching user" />
        <Items items={items} />
      </List.Container>
      <Card.Footer color={theme`colors.amethyst.400`} />
    </Card.Container>
  )
}
const CenterData = tw(List.HeaderData)`text-center`
