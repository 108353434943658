import React from 'react'
import tw from 'twin.macro'
import { Button, Input, Info } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { Controller, useFormContext } from 'react-hook-form'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import RBAC from '../RBAC'
import { Switch } from '../Layout/Switch'
import { Action } from '../../layout/List'
import Dropdown from '../Dropdown'

export const CheckpointForm = ({ onActivate, onDeactivate, onDelete }) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = useFormContext()
  const { adding, customTagOptions, tagOptions } = useCheckpoint()
  const { active, centerPoint: { latitude, longitude } = {}, custom, playable = true } = watch()

  return (
    <>
      <Action.Header>
        <Action.Active active={active} />
        <Action.More
          content={
            <>
              {active ? (
                <Button.Primary ring size="sm" onClick={onDeactivate}>
                  <Icon.Moon size="sm" mr="2" /> Deactivate
                </Button.Primary>
              ) : (
                <Button.Secondary ring size="sm" onClick={onActivate}>
                  <Icon.Sun size="sm" mr="2" /> Activate
                </Button.Secondary>
              )}
              <RBAC>
                <Button.Warning ring size="sm" onClick={onDelete} disabled={adding}>
                  <Icon.Trashcan mr="2" /> Delete
                </Button.Warning>
              </RBAC>
            </>
          }
        />
      </Action.Header>

      <Form>
        <Section>
          <Input.Label>{'Name your checkpoint'}</Input.Label>
          <Input.Default
            {...register('name', {
              maxLength: {
                value: 50,
                message: 'Name cannot be longer than 50 characters'
              },
              required: 'Name is required'
            })}
            id="checkpointName"
            placeholder="Name"
          />

          <Input.Error>{errors?.name?.message}</Input.Error>
        </Section>
        <Section>
          <Input.Label>Location (Lat, Lng)</Input.Label>
          <CoordsWrapper>
            <Input.Default
              {...register('centerPoint.latitude', { required: true, valueAsNumber: true })}
              size="sm"
              placeholder="Lat"
              tw="md:max-w-1/3"
            />

            <Input.Default
              {...register('centerPoint.longitude', { required: true, valueAsNumber: true })}
              size="sm"
              placeholder="Lng"
              tw="md:max-w-1/3"
            />
          </CoordsWrapper>
          <Input.Error>{errors?.centerPoint && 'Location is required'}</Input.Error>
          {(!latitude || !longitude) && (
            <Section>
              <Info>
                {'Use the "Add Checkpoint Pin" switch on the map to add checkpoint location'}
              </Info>
            </Section>
          )}
          {!playable && (
            <Section>
              <Info>{'Please check to make sure the checkpoint is in a playable location!'} </Info>
            </Section>
          )}
        </Section>
        <Section>
          <Input.Label>Add tags</Input.Label>
          <Controller
            control={control}
            name="tags"
            render={({ field }) => (
              <Dropdown.Creatable
                {...field}
                closeMenuOnSelect={false}
                isMulti
                menuPlacement="bottom"
                options={tagOptions}
                placeholder="Tags"
              />
            )}
          />
        </Section>
        <Section>
          <Controller
            control={control}
            name="custom"
            render={({ field: { onChange, value } }) => (
              <Switch
                color="blue"
                size="md"
                label="Custom Checkpoint"
                defaultChecked={value}
                onToggle={(v) => {
                  onChange(v)
                  !v && setValue('customTags')
                }}
              />
            )}
          />
        </Section>
        {custom && (
          <Section>
            <Input.Label>Add custom tag</Input.Label>
            <Controller
              control={control}
              name="customTags"
              render={({ field }) => (
                <Dropdown.Creatable
                  {...field}
                  isMulti={true}
                  isClearable={true}
                  menuPlacement="bottom"
                  options={customTagOptions}
                  placeholder="Select or create new custom tag"
                />
              )}
              rules={{ required: 'This is required!', shouldUnregister: true }}
            />
            <Section>
              <Info>
                <span>
                  Use custom tags for <b>special</b> checkpoints that shouldn&apos;t be added to
                  hvnts by default
                </span>
                <span>Useful for unlisted hvnts or corporate event locations</span>
              </Info>
            </Section>
          </Section>
        )}
        <Input.Error>{errors.customTags?.message}</Input.Error>
      </Form>
    </>
  )
}

const Form = tw.form`flex flex-col z-30 px-6`
const Section = tw.div`flex flex-col my-2 items-start`
const CoordsWrapper = tw.div`flex flex-wrap gap-1 w-full`
