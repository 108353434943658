import React, { Fragment, useCallback, useMemo, useState } from 'react'
import tw, { styled } from 'twin.macro'
import Icon from '../../styles/Icons'

export const Paginator = ({ callback, length, limit = 1, start = 1 }) => {
  const [currentPage, setCurrentPage] = useState(start)
  const pages = useMemo(() => Math.ceil(length / limit), [length, limit])

  const onClick = useCallback(
    (page) => {
      setCurrentPage(page)
      callback && callback(page * limit - limit)
    },
    [callback, limit]
  )

  const buttons = useMemo(
    () => createPagination(pages, currentPage, onClick),
    [currentPage, onClick, pages]
  )

  return (
    <div tw="flex gap-4 items-end w-full">
      <Container>
        {buttons.map((button, i) => (
          <Fragment key={i}>{button}</Fragment>
        ))}
      </Container>
      <div tw="flex justify-center items-center cursor-pointer w-10 h-8 px-1 bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-sm text-gray-700 whitespace-nowrap ">
        50
      </div>
    </div>
  )
}

const Container = tw.div`flex w-full gap-0.5 self-start z-10 width[20rem]`

const createPagination = (pages, page = 1, callback) => {
  let buttons = []
  let pageCutLow = page - 1
  let pageCutHigh = page + 1

  buttons.push(
    <PaginationButton disabled={page === 1} end="true" onClick={() => callback(page - 1)}>
      <Icon.ChevronLeft size="sm" />
    </PaginationButton>
  )
  if (pages < 6) {
    for (let p = 1; p <= pages; p++) {
      buttons.push(<PaginationButton active={page === p}>{p}</PaginationButton>)
    }
  } else {
    if (page > 2) {
      buttons.push(<PaginationButton onClick={() => callback(1)}>{'1'}</PaginationButton>)
      if (page > 3)
        buttons.push(
          <PaginationButton onClick={() => callback(page - 2)}>{'...'}</PaginationButton>
        )
    }
    if (page === 1) pageCutHigh += 2
    else if (page === 2) pageCutHigh += 1
    if (page === pages) pageCutLow -= 2
    else if (page === pages - 1) pageCutLow -= 1

    for (let p = pageCutLow; p <= pageCutHigh; p++) {
      if (p === 0) p += 1
      if (p > pages) continue
      buttons.push(
        <PaginationButton active={page === p} onClick={() => callback(p)}>
          {p}
        </PaginationButton>
      )
    }
    if (page < pages - 1) {
      if (page < pages - 2)
        buttons.push(
          <PaginationButton onClick={() => callback(page + 2)}>{'...'}</PaginationButton>
        )
      buttons.push(<PaginationButton onClick={() => callback(pages)}>{pages}</PaginationButton>)
    }
  }

  buttons.push(
    <PaginationButton
      disabled={page >= pages}
      end="true"
      onClick={() => callback(page + 1)}
      tw="rounded-r-lg"
    >
      <Icon.ChevronRight size="sm" />
    </PaginationButton>
  )

  return buttons
}

const PaginationButton = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
)

const StyledButton = styled.button`
  ${tw`flex items-center justify-center text-black select-none rounded-sm h-8`}
  ${({ active }) =>
    active ? tw`bg-norway-600 text-white` : tw`bg-gray-300 hover:bg-gray-400 active:bg-gray`}
  ${({ disabled }) => (disabled ? tw`hover:bg-gray-300 text-gray-400` : tw`cursor-pointer`)}
  ${({ end }) => (end ? tw`w-8` : tw`flex-1`)}
`
