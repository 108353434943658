import React, { useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import config from '../../config'
import { usePlayer } from '../../contexts/PlayerContext'
import { Role } from '../../enums'
import { Item } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button } from '../../styles/Layout'
import { ATag } from '../../styles/Link'
import RBAC from '../RBAC'

export const Items = ({ items }) => {
  const [expandedItem, setExpandedItem] = useState(items?.[0])

  const onClickItem = useCallback((player, opened) => {
    console.log({ player })
    setExpandedItem(opened ? player : undefined)
  }, [])

  return items.map((item) => (
    <PlayerItem
      key={item.uid}
      expanded={expandedItem?.uid === item.uid}
      item={item}
      onClick={onClickItem}
    />
  ))
}

export const PlayerItem = ({ expanded, item, onClick }) => {
  const { showPlayerProfile } = usePlayer()

  return useMemo(() => {
    const {
      activeStatus,
      authData: {
        disabled,
        displayName,
        email,
        emailVerified,
        isAdmin,
        metadata,
        photoURL,
        phoneNumber,
        roles
      } = {},
      uid
    } = item

    return (
      <Item.Container>
        <Item.Header item={item} onClick={() => onClick(item, !expanded)} expanded={expanded}>
          <Item.HeaderData colSpan={1}>
            <Item.Center>
              {disabled ? <Icon.Close tw="text-red" /> : <Icon.Check tw="text-green" />}
            </Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={4}>{email || '-'}</Item.HeaderData>
          <Item.HeaderData colSpan={4}>{phoneNumber || '-'}</Item.HeaderData>
        </Item.Header>

        <Item.Summary expanded={expanded}>
          <RBAC allow={[Role.CREATOR]}>
            <Item.ActionButtons>
              <Button.Secondary size="sm" onClick={() => showPlayerProfile(item)}>
                <Icon.UserSolid size="xs" mr="2" /> Show Profile
              </Button.Secondary>
            </Item.ActionButtons>
          </RBAC>

          <Item.Image>
            <Image src={photoURL || config.user.DEFAULT_USER_IMAGE} />
          </Item.Image>

          <Item.SummaryList>
            {(isAdmin || !!roles?.length) && (
              <Item.Row>
                <Item.Bold>FF roles:</Item.Bold>
                <Roles>
                  {isAdmin ? (
                    <Item.Label>ADMIN</Item.Label>
                  ) : (
                    roles?.map((role, i) => <CustomRole key={i}>{role.toUpperCase()}</CustomRole>)
                  )}
                </Roles>
              </Item.Row>
            )}

            <Item.Row>
              <Item.Bold>Name:</Item.Bold>
              <Item.Text>{displayName || '-'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Phone number:</Item.Bold>
              <Item.Text>{phoneNumber || '-'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Id:</Item.Bold>
              <ATag href={`${config.firebase.FIRESTORE_URL}/users/${uid}`}>{uid}</ATag>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Email:</Item.Bold>
              <Item.Text>{email || '-'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Email verified:</Item.Bold>
              <Item.Text>{emailVerified ? 'Yes' : 'No'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Active status:</Item.Bold>
              <Item.Status>
                {activeStatus?.icon()}
                {activeStatus.status}
              </Item.Status>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Created:</Item.Bold>
              <Item.Text>{metadata?.creationTime || '-'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Last signed in:</Item.Bold>
              <Item.Text>{metadata?.lastSignInTime || '-'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>Last refreshed:</Item.Bold>
              <Item.Text>{metadata?.lastRefreshTime || '-'}</Item.Text>
            </Item.Row>
          </Item.SummaryList>
        </Item.Summary>
      </Item.Container>
    )
  }, [expanded, item, onClick, showPlayerProfile])
}

const Image = tw.img`w-48 height[min-content] shadow-md select-none`
const Roles = tw.div`flex flex-wrap gap-1`
const CustomRole = tw(Item.Label)`bg-matrix-400 text-white`
