export const extractTags = (feature) => {
  const { properties } = feature
  const { tilequery } = properties
  const tags = []
  switch (tilequery.layer) {
    case 'building': {
      const tag = 'building'
      tags.push(tag)
      const { type, underground } = properties
      switch (type) {
        case 'apartments': {
          tags.push(`${tag}:apartments`)
          underground === 'true' && tags.push(`${tag}:underground`)
          break
        }
        case 'building:part':
        case 'building': {
          underground === 'true' && tags.push(`${tag}:underground`)
          break
        }
        case 'university': {
          tags.push('school:university')
          break
        }
        default:
          console.log({ properties })
          break
      }
      break
    }
    case 'housenum_label': {
      const { house_num } = properties
      tags.push(`house_num:${house_num}`)
      break
    }
    case 'landuse': {
      const { class: classType, type } = properties
      switch (classType) {
        case 'agriculture':
        case 'airport':
        case 'cemetery':
        case 'facility':
        case 'grass':
        case 'hospital':
        case 'park': {
          console.log({ feature })
          break
        }
        case 'parking':
        case 'piste':
        case 'pitch':
        case 'rock':
        case 'sand':
        case 'school':
        case 'scrub':
        case 'wood': {
          tags.push(classType)
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        case 'residential': {
          break
        }
        default: {
          console.log(properties)
        }
      }
      break
    }
    case 'natural_label': {
      console.log('!!', properties)
      const { class: classType, name } = properties
      tags.push(classType)
      name && tags.push(`${classType}:${name}`)
      break
    }
    case 'poi_label': {
      const { type, name, class: classType } = properties
      switch (type) {
        case 'Aquarium': {
          const tag = 'aquarium'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Bank': {
          const tag = 'bank'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Beach': {
          const tag = 'beach'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Information':
        case 'Board': {
          tags.push('info')
          break
        }
        case 'Cafe': {
          const tag = 'cafe'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Castle': {
          const tag = 'castle'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Fitness': {
          const tag = 'fitness'
          tags.push(tag)
          break
        }
        case 'Garden': {
          const tag = 'garden'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Hotel': {
          const tag = 'hotel'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Ice Cream': {
          const tag = 'ice_cream'
          tags.push(tag)
          break
        }
        case 'Library': {
          const tag = 'library'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Marina': {
          const tag = 'marina'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Monument': {
          const tag = 'monument'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          break
        }
        case 'Museum': {
          const tag = 'museum'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          break
        }
        case 'Park': {
          const tag = 'park'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Parking': {
          const tag = 'parking'
          tags.push(tag)
          break
        }
        case 'Picnic Table': {
          const tag = 'picnic_table'
          tags.push(tag)
          break
        }
        case 'Resort': {
          const tag = 'resort'
          tags.push(tag)
          break
        }
        case 'Restaurant': {
          const tag = 'restaurant'
          tags.push(tag)
          break
        }
        case 'Sauna': {
          const tag = 'sauna'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Toilets': {
          tags.push('toilet')
          break
        }
        case 'University': {
          const tag = 'school:university'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        case 'Viewpoint': {
          const tag = 'viewpoint'
          tags.push(tag)
          break
        }
        case 'Zoo': {
          const tag = 'zoo'
          tags.push(tag)
          name && tags.push(`${tag}:${name}`)
          break
        }
        default: {
          console.log({ type, properties })
          break
        }
      }
      break
    }
    case 'road': {
      const { type } = properties
      switch (type) {
        case 'bridleway': {
          const tag = 'bridleway'
          const { class: classType, oneway, structure, surface } = properties
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'crossing': {
          const tag = 'crossing'
          const { class: classType, oneway, structure, surface } = properties
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'cycleway': {
          const tag = 'cycleway'
          tags.push(tag)
          const { oneway, structure, surface } = properties
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'ferry': {
          const tag = 'ferry'
          tags.push(tag)
          const { class: classType, oneway } = properties
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          oneway === 'true' && tags.push(`${tag}:oneway`)
          break
        }
        case 'footway': {
          const tag = 'footway'
          const { class: classType, oneway, structure, surface } = properties
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'path': {
          const tag = 'path'
          const { class: classType, oneway, structure, surface } = properties
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'pedestrian': {
          const tag = 'pedestrian'
          const { class: classType, name, oneway, structure, surface } = properties
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          name && tags.push(`${tag}:${name}`)
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'residential': {
          const { class: classType, bike_lane, oneway, structure, surface } = properties
          // uses classType instead of tag
          classType && classType !== type && tags.push(classType)
          oneway === 'true' && tags.push(`${classType}:oneway`)
          structure !== 'none' && tags.push(`${classType}:${structure}`)
          surface && tags.push(`${classType}:${surface}`)
          bike_lane === 'yes' && tags.push(`${classType}:bike_lane`)
          break
        }
        case 'service:parking_aisle': {
          const tag = 'parking'
          tags.push(tag)
          break
        }
        case 'sidewalk': {
          const tag = 'sidewalk'
          const { class: classType, oneway, structure, surface } = properties
          classType && classType !== type && tags.push(`${tag}:${classType}`)
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'steps': {
          const tag = 'steps'
          tags.push(tag)
          const { oneway, structure, surface } = properties
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'tertiary': {
          const tag = 'road'
          tags.push(tag)
          const { oneway, structure, surface } = properties
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'tram': {
          const tag = 'tram'
          tags.push(tag)
          const { oneway, structure, surface } = properties
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        case 'turning_circle': {
          const tag = 'turning_circle'
          tags.push(tag)
          const { oneway, structure, surface } = properties
          oneway === 'true' && tags.push(`${tag}:oneway`)
          structure !== 'none' && tags.push(`${tag}:${structure}`)
          surface && tags.push(`${tag}:${surface}`)
          break
        }
        default: {
          const { class: classType } = properties
          switch (classType) {
            case 'traffic_signals': {
              tags.push('traffic_signals')
              break
            }
            default: {
              console.log({ type, properties })
              tags.push('road')
              break
            }
          }
        }
      }
      break
    }
    case 'structure': {
      const { class: classType, type } = properties
      switch (classType) {
        case 'cliff': {
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        case 'fence': {
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        case 'gate': {
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        case 'hedge': {
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        case 'land': {
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        case 'steps': {
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        case 'tower': {
          type && type !== classType && tags.push(`${classType}:${type}`)
          break
        }
        default:
          {
            console.log({ properties })
          }
          break
      }

      break
    }
    case 'water': {
      const { distance } = tilequery
      distance && tags.push(`water:distance:${distance}`)
      break
    }

    case 'waterway': {
      const { class: classType } = properties
      classType && tags.push(`waterway:${classType}`)
      break
    }

    default: {
      console.log({ properties })
      break
    }
  }
  return tags
}

export const playableLocationsConverter = {
  fromFirestore: (snapshot) => ({ ...snapshot.data(), id: snapshot.id })
}
