import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import tw from 'twin.macro'
import config from '../../config'
import { usePrizes } from '../../contexts/PrizeContext'
import { Action } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button, Input } from '../../styles/Layout'
import { validate } from '../../utility/formValidation'
import { PRIZE_CURRENCY_OPTIONS } from '../../utility/labeledOptions'
import Dropdown from '../Dropdown'
import { ImageModal } from '../Modal'
import RBAC from '../RBAC'

export const PhysicalPrizeForm = ({ flagUpload, prize }) => {
  const { adding, categoryOptions, deletePrize, supplierOptions, tagOptions } = usePrizes()
  const {
    control,
    formState: { errors },
    register,
    setValue
  } = useFormContext()
  const [active, amount, imageUrl, marketValue] = useWatch({
    control,
    name: ['active', 'amount', 'imageUrl', 'marketValue.value']
  })

  const activeChanged = active ? !prize.active : prize.active
  const canDeletePrize = !prize.reserved

  return (
    <>
      <Action.Header>
        <Action.Active active={active} changed={activeChanged} />

        <Action.Right>
          <Action.More
            content={
              <>
                {active ? (
                  <Button.Primary
                    size="sm"
                    onClick={() => setValue('active', false)}
                    disabled={adding}
                    ring
                  >
                    <Icon.Archive size="sm" mr="2" />
                    Archive Prize
                  </Button.Primary>
                ) : (
                  <Button.Primary
                    size="sm"
                    onClick={() => setValue('active', true)}
                    disabled={adding}
                    ring
                  >
                    <Icon.Archive size="sm" mr="2" />
                    Unarchive Prize
                  </Button.Primary>
                )}

                <RBAC>
                  <Button.Warning
                    size="sm"
                    onClick={() => deletePrize(prize)}
                    disabled={adding || !canDeletePrize}
                    ring
                  >
                    <Icon.Trashcan size="sm" mr="2" />
                    Delete Prize
                  </Button.Warning>
                </RBAC>
              </>
            }
          />
        </Action.Right>
      </Action.Header>

      <Form>
        <FormCol>
          <Section>
            <Input.Label required>Who is the supplier?</Input.Label>
            <Controller
              control={control}
              name="supplier"
              render={({ field }) => (
                <Dropdown.Creatable
                  {...field}
                  placeholder="Choose or create supplier"
                  isClearable
                  menuPlacement="bottom"
                  options={supplierOptions}
                />
              )}
              rules={{ required: 'Supplier is required' }}
            />
            <Input.Error>{errors.supplier?.message}</Input.Error>
          </Section>

          <Section>
            <Input.Label required>Name the prize</Input.Label>
            <Input.Default
              {...register('name', {
                required: 'Name is required',
                maxLength: {
                  value: 30,
                  message: 'Name cannot be longer than 30 characters'
                }
              })}
              name="name"
              placeholder="Display name"
            />
            <Input.Error>{errors.name?.message}</Input.Error>
          </Section>
          <Section>
            <Input.Label>Category</Input.Label>
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <Dropdown.Creatable
                  {...field}
                  isClearable
                  closeMenuOnSelect={true}
                  options={categoryOptions}
                  placeholder="Choose or create category"
                />
              )}
              rules={{ validate: (value) => validate.category(value) }}
            />
            <Input.Error>{errors.category?.message}</Input.Error>
          </Section>

          <Section>
            <div tw="flex gap-3">
              {adding ? (
                <Col>
                  <Input.Label required>How many prizes do we want to add?</Input.Label>
                  <Input.Default
                    {...register('amount', {
                      required: 'Amount is required',
                      validate: validate.prize.amount,
                      valueAsNumber: true
                    })}
                    disabled={!adding}
                    placeholder="Amount"
                    tw="w-full md:w-36"
                  />
                  <Input.Error>{errors.amount?.message}</Input.Error>
                </Col>
              ) : (
                <>
                  <Col>
                    <Input.Label required>Available Prizes</Input.Label>
                    <Input.Default
                      {...register('available')}
                      disabled={!adding}
                      placeholder="Amount"
                      tw="w-full md:w-36"
                    />
                    <Input.Error>{errors.amount?.message}</Input.Error>
                  </Col>
                  <Col>
                    <Input.Label>Adjust the amount?</Input.Label>
                    <Input.Default
                      {...register('adjustedAmount', {
                        validate: (value) => {
                          if (!value) return true
                          if (Number(value) === amount) return 'This is the same amount...'
                          return validate.prize.amount(value)
                        },
                        shouldUnregister: true
                      })}
                      placeholder="New amount"
                      tw="w-full md:w-36"
                    />
                    <Input.Error>{errors.adjustedAmount?.message}</Input.Error>
                  </Col>
                </>
              )}
            </div>
          </Section>

          <Section>
            <Input.Label>Description</Input.Label>
            <Input.TextArea
              {...register('description')}
              placeholder="Description (optional)"
              rows="2"
            />
          </Section>

          <Section tw="flex-col md:flex-row">
            <Col tw="flex-1 truncate">
              <Input.Label>Do you want to include market prize?</Input.Label>
              <Input.Default
                placeholder="Value per prize (optional)"
                {...register('marketValue.value', {
                  validate: (value) => validate.prize.marketValue(value) ?? true,
                  shouldUnregister: true
                })}
              />
              <Input.Error>{errors.marketValue?.value?.message}</Input.Error>
            </Col>
            {!!marketValue && (
              <Col tw="min-width[15vw] my-1 md:m-0 md:ml-2">
                <Input.Label required>Which currency is this?</Input.Label>
                <Controller
                  control={control}
                  name="marketValue.currency"
                  render={({ field }) => (
                    <Dropdown.Select
                      {...field}
                      placeholder="Currency"
                      menuPlacement="bottom"
                      options={PRIZE_CURRENCY_OPTIONS}
                    />
                  )}
                  rules={{ required: 'Currency is required', shouldUnregister: true }}
                />
                <Input.Error>{errors.marketValue?.currency?.message}</Input.Error>
              </Col>
            )}
          </Section>
          <Section>
            <Input.Label>Add tags</Input.Label>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <Dropdown.Creatable
                  {...field}
                  closeMenuOnSelect={false}
                  isMulti
                  options={tagOptions}
                  placeholder="Tags"
                />
              )}
            />
          </Section>

          <Section>
            <Input.Label>Any extra info? (Only for us)</Input.Label>
            <Input.Default
              {...register('extra')}
              placeholder="Metadata (order number, expense cost, etc.)"
            />
          </Section>
        </FormCol>
        <ImageCol>
          <Section>
            <Input.Label required>Upload an image</Input.Label>
            <Controller
              control={control}
              name="imageUrl"
              render={({ field: { value, onChange } }) => (
                <ImageModal
                  OpenButton={(props) => (
                    <div tw="flex flex-col items-center gap-2">
                      <Image src={imageUrl ?? config.prize.DEFAULT_PRIZE_IMAGE} />
                      <Button.Secondary {...props} tw="w-min">
                        <Icon.Image mr="2" />
                        {value ? 'Change Image' : 'Add Image'}
                      </Button.Secondary>
                    </div>
                  )}
                  flagUpload={flagUpload}
                  imageUrl={value}
                  setImageUrl={onChange}
                  title="Prize Image"
                  tw="w-4/5 h-4/5"
                />
              )}
              rules={{ required: 'Image is required' }}
            />
            <Input.Error tw="self-center">{errors?.imageUrl?.message}</Input.Error>
          </Section>
        </ImageCol>
      </Form>
    </>
  )
}

const Form = tw.form`flex flex-col md:flex-row flex-1 w-full p-4 gap-2 shadow-md`
const Col = tw.div`flex flex-col`
const FormCol = tw(Col)`w-full md:w-2/3`
const ImageCol = tw(Col)`w-full md:w-1/3`
const Section = tw.div`flex flex-col my-2 px-4 w-full`
const Image = tw.img`rounded-md shadow-md`
