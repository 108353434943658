import React, { useMemo } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { useAreas } from '../../contexts/AreasContext'
import { useHvnt } from '../../contexts/HvntContext'
import { useHvntList } from '../../hooks/useHvntList'
import { Button, Card } from '../../styles/Layout'
import { Items } from './HvntItem'
import { HvntFilter } from './HvntFilter'
import RBAC from '../RBAC'
import Icon from '../../styles/Icons'
import { HvntSorts, HvntTab, Role } from '../../enums'
import { Loader } from '../Loader/Loader'
import { Action, List } from '../../layout/List'

export const HvntList = () => {
  const { listItems: hvnts, fetchingHvnt, filteredItems, sortingBy, sortListBy } = useHvntList()
  const { activeCity, removeActiveCity } = useAreas()
  const { addNewHvnt, activeTab, setActiveTab } = useHvnt()
  const unlisted = useMemo(() => activeTab === HvntTab.UNLISTED, [activeTab])

  return (
    <Card.Container
      header={
        `${hvnts?.length} ${unlisted ? 'Unlisted Hvnt' : 'Hvnt'}` +
        `${hvnts?.length === 1 ? '' : 's'}` +
        `${activeCity?.name ? ` in ${activeCity.name}` : ''}`
      }
      color={theme`colors.matrix.500`}
      xHover={theme`colors.matrix.600`}
      xActive={theme`colors.matrix.700`}
      onClose={removeActiveCity}
    >
      <Loader loading={fetchingHvnt} text="Fetching hvnt" />

      <Tabs>
        <Tab active={activeTab === HvntTab.REGULAR} onClick={() => setActiveTab(HvntTab.REGULAR)}>
          Regular
        </Tab>
        <Tab active={activeTab === HvntTab.UNLISTED} onClick={() => setActiveTab(HvntTab.UNLISTED)}>
          Unlisted
        </Tab>
      </Tabs>

      <RBAC allow={[Role.CREATOR]}>
        <List.Filter data-testid="hvnt-filter">
          <HvntFilter />
        </List.Filter>

        <Action.Header>
          <Action.Right>
            {unlisted ? (
              <Button.Secondary
                ring
                size="sm"
                onClick={() => addNewHvnt({ activeCity, unlisted: true })}
                data-testid="add-hvnt-button"
              >
                <Icon.Plus size="sm" mr="2" />
                {'Create New Unlisted Hvnt'}
              </Button.Secondary>
            ) : (
              <>
                <Button.Primary
                  ring
                  size="sm"
                  onClick={() => addNewHvnt({ activeCity })}
                  data-testid="add-hvnt-button"
                >
                  <Icon.Plus size="sm" mr="2" />
                  {'Create New Hvnt'}
                </Button.Primary>
              </>
            )}

            <Action.More />
          </Action.Right>
        </Action.Header>

        <List.Header>
          <CenterData
            colSpan={1}
            onClick={() => sortListBy(HvntSorts.ACTIVE)}
            active={sortingBy?.sort === HvntSorts.ACTIVE}
            inc={sortingBy?.inc}
          >
            Active
          </CenterData>
          <CenterData
            colSpan={1}
            onClick={() => sortListBy(HvntSorts.STATUS)}
            active={sortingBy?.sort === HvntSorts.STATUS}
            inc={sortingBy?.inc}
          >
            Status
          </CenterData>
          <List.HeaderData
            colSpan={2}
            onClick={() => sortListBy(HvntSorts.NAME)}
            active={sortingBy?.sort === HvntSorts.NAME}
            inc={sortingBy?.inc}
          >
            Name
          </List.HeaderData>
          <List.HeaderData
            colSpan={2}
            onClick={() => sortListBy(HvntSorts.START_DATE)}
            active={sortingBy?.sort === HvntSorts.START_DATE}
            inc={sortingBy?.inc}
          >
            Starts
          </List.HeaderData>
          <List.HeaderData
            colSpan={2}
            onClick={() => sortListBy(HvntSorts.END_DATE)}
            active={sortingBy?.sort === HvntSorts.END_DATE}
            inc={sortingBy?.inc}
          >
            Ends
          </List.HeaderData>
          <List.HeaderData
            colSpan={2}
            onClick={() => sortListBy(HvntSorts.UPDATED)}
            active={sortingBy?.sort === HvntSorts.UPDATED}
            inc={sortingBy?.inc}
          >
            Updated
          </List.HeaderData>
        </List.Header>
      </RBAC>

      <List.Container data-testid="hvnt-list">
        {filteredItems?.length ? (
          <Items items={filteredItems} />
        ) : (
          <List.Empty>{`${hvnts.length ? 'No filtered items...' : 'Nothing here...'}`}</List.Empty>
        )}
      </List.Container>
      <Card.Footer color={theme`colors.matrix`} />
    </Card.Container>
  )
}

const CenterData = tw(List.HeaderData)`text-center`
const Tabs = tw.div`flex w-full pb-0 bg-matrix-600 gap-2 first:pl-2`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[100px] text-center rounded-t-md text-lg tracking-wide`}
  ${({ active }) => css`
    ${active ? tw`bg-gray-200` : tw`text-red-200 bg-matrix-600 hover:bg-matrix-700 `}
  `}
`
