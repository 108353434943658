import React, { useCallback, useMemo, useState } from 'react'
import tw, { styled, theme } from 'twin.macro'
import { Button } from '../../../../styles/Layout'
import { format } from 'date-fns'
import config from '../../../../config'
import { ATag, CheckpointTag, HvntTag, PrizeTag, UserTag } from '../../../../styles/Link'
import Icon from '../../../../styles/Icons'
import RBAC from '../../../RBAC'
import { Role } from '../../../../enums'
import useGetParticipations from '../../../../hooks/firebase/useGetParticipations'
import { Alerts, Toast } from '../../../../utility/alerts'
import { Item } from '../../../../layout/List'
import { usePlayer } from '../../../../contexts/PlayerContext'

export const Items = ({ items }) => {
  const { cancelParticipation } = useGetParticipations()
  const [expandedItem, setExpandedItem] = useState()

  const onClickItem = (participation, opened) => {
    console.log({ participation })
    opened ? setExpandedItem(participation) : setExpandedItem()
  }

  const onCancelParticipation = useCallback(
    async (item) => {
      try {
        const { value: ok } = await Alerts.Participations.CANCEL_WARNING()
        if (!ok) return
        cancelParticipation(item)
        Toast.fire({ title: 'Participation Cancelled', icon: 'success' })
      } catch (err) {
        Alerts.General.ERROR(err)
      }
    },
    [cancelParticipation]
  )

  return useMemo(
    () =>
      items.map((participation, i) => (
        <ParticipationItem
          key={i}
          expanded={expandedItem?.id === participation?.id}
          item={participation}
          onCancel={onCancelParticipation}
          onClick={onClickItem}
        />
      )),
    [expandedItem?.id, items, onCancelParticipation]
  )
}

const ParticipationItem = ({ expanded, item, onCancel, onClick }) => {
  const { activePlayer } = usePlayer()
  const {
    cancelledAt,
    cancelledBy,
    completedAt,
    currentCheckpoint,
    endedAt,
    endsAt,
    hvnt,
    id,
    numberOfCheckpoints,
    path,
    startedAt,
    status,
    summary,
    timedOutAt
  } = item

  const cancelled = cancelledAt ?? cancelledBy ?? false
  const ended = completedAt ?? endedAt ?? timedOutAt ?? false
  const claimedPrize = useMemo(
    () => activePlayer?.prizes?.find((prize) => prize.participation?.id === id),
    [activePlayer.prizes, id]
  )

  return (
    <Item.Container>
      <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
        <Item.HeaderData colSpan={1} tw="flex justify-center items-center">
          {status?.icon()}
        </Item.HeaderData>

        <Item.HeaderData colSpan={3}>{hvnt?.name}</Item.HeaderData>

        <Item.HeaderData colSpan={3}>
          {startedAt ? format(startedAt, 'LLL do yyyy, HH:mm') : '-'}
        </Item.HeaderData>

        <Item.HeaderData colSpan={3}>
          {ended
            ? format(ended, 'LLL do yyyy, HH:mm')
            : cancelledAt
            ? format(cancelledAt, 'LLL do yyyy, HH:mm')
            : '-'}
        </Item.HeaderData>

        <Item.HeaderData colSpan={1}>
          <Item.Center>{claimedPrize ? <Icon.Trophy /> : '-'}</Item.Center>
        </Item.HeaderData>
      </Item.Header>

      <Item.Summary expanded={expanded}>
        <Item.SummaryList>
          <Item.Row>
            <Item.Bold>{'Participation id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Status:'}</Item.Bold>
            <Item.Text tw="font-mono flex items-center gap-2">
              {status.icon()}
              {status.message}
            </Item.Text>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Started at:'}</Item.Bold>
            <Item.Text>{startedAt ? format(startedAt, 'LLL do yyyy, HH:mm') : '-'}</Item.Text>
          </Item.Row>

          {cancelled ? (
            <>
              {cancelledAt && (
                <Item.Row>
                  <Item.Bold>{'Cancelled at:'}</Item.Bold>
                  <Item.Text>{format(cancelledAt, 'LLL do yyyy, HH:mm')}</Item.Text>
                </Item.Row>
              )}
              {cancelledBy && (
                <Item.Row>
                  <Item.Bold>{'Cancelled by:'}</Item.Bold>
                  <UserTag to={`/users?id=${cancelledBy}`}>{cancelledBy}</UserTag>
                </Item.Row>
              )}
            </>
          ) : (
            <>
              {ended ? (
                <Item.Row>
                  <Item.Bold>{'Ended at:'}</Item.Bold>
                  <Item.Text>{format(ended, 'LLL do yyyy, HH:mm')}</Item.Text>
                </Item.Row>
              ) : (
                <Item.Row>
                  <Item.Bold>{'Ends at:'}</Item.Bold>
                  <Item.Text>{endsAt ? format(endsAt, 'LLL do yyyy, HH:mm') : '-'}</Item.Text>
                </Item.Row>
              )}
            </>
          )}

          <Item.Row>
            <BorderText color={theme`colors.matrix.400`}>{'Hvnt:'}</BorderText>
            <Item.Text>{hvnt?.name ?? '-'}</Item.Text>
          </Item.Row>

          {hvnt?.id && (
            <Item.Row>
              <BorderText color={theme`colors.matrix.400`}>{'Id:'}</BorderText>
              <HvntTag to={`/hvnts?id=${hvnt.id}`}>{hvnt.id}</HvntTag>
            </Item.Row>
          )}

          <Item.Row>
            <Item.Bold>{'Number of checkpoints:'}</Item.Bold>
            <Item.Text>{numberOfCheckpoints ?? '-'}</Item.Text>
          </Item.Row>

          {currentCheckpoint?.id && (
            <Item.Row>
              <BorderText color={theme`colors.gold`}>{'Current checkpoint:'}</BorderText>
              <CheckpointTag to={`/checkpoints?id=${currentCheckpoint.id}&edit=true&tab=clues`}>
                {currentCheckpoint.id}
              </CheckpointTag>
            </Item.Row>
          )}

          <Item.Row>
            <BorderText color={theme`colors.gold`}>{'Checkpoints found:'}</BorderText>
            <Item.Text>{summary?.checkpointsFound ?? 0}</Item.Text>
          </Item.Row>

          <Item.Row>
            <BorderText color={theme`colors.gold`}>{'Clues used:'}</BorderText>
            <Item.Text>{summary?.cluesUsed ?? 0}</Item.Text>
          </Item.Row>

          <Item.Row>
            <BorderText color={theme`colors.gold`}>{'Coins collected:'}</BorderText>
            <Item.Text>{summary?.coinsCollected ?? 0}</Item.Text>
          </Item.Row>

          <Item.Row>
            <BorderText color={theme`colors.gold`}>{'Score:'}</BorderText>
            <Item.Text>{summary?.score ?? '-'}</Item.Text>
          </Item.Row>

          {/* TODO: Link to actual prize instance in future */}
          {claimedPrize?.prize?.id && (
            <>
              <Item.Row>
                <BorderText color={theme`colors.terracotta.500`}>{'Claimed prize:'}</BorderText>
                <PrizeTag to={`/prizes?id=${claimedPrize.prize.id}`}>
                  {claimedPrize.prize.id}
                </PrizeTag>
              </Item.Row>

              <Item.Row>
                <BorderText color={theme`colors.terracotta.500`}>{'Claimed at:'}</BorderText>
                <Item.Text>
                  {claimedPrize.claimedAt
                    ? format(claimedPrize.claimedAt, 'LLL do yyyy, HH:mm')
                    : '-'}
                </Item.Text>
              </Item.Row>
            </>
          )}
        </Item.SummaryList>

        <Item.ActionButtons>
          <Button.Secondary disabled={true} size="sm">
            <Icon.Ticket size="sm" mr="2" /> Show Participation
          </Button.Secondary>

          {item.status?.cancellable && (
            <RBAC allow={[Role.ADMIN]}>
              <Button.Warning size="sm" onClick={() => onCancel(item)}>
                <Icon.Trashcan size="sm" mr="2" /> Cancel Participation
              </Button.Warning>
            </RBAC>
          )}
        </Item.ActionButtons>
      </Item.Summary>
    </Item.Container>
  )
}

const BorderText = styled(Item.Bold)`
  ${tw`pl-2`}
  &::before {
    ${tw`absolute -left-2 -top-1 -bottom-1 border-l-8 content`};
    ${({ color }) => `border-color: ${color}`};
  }
`
