import React from 'react'
import tw from 'twin.macro'
import { AreasMap } from '../Areas/Map/AreasMap'
import { AreasList } from '../Areas/AreasList'
import { PageLayout } from '../../styles/Layout'
import { MapProvider } from '../../contexts/MapContext'

const AreasPage = () => (
  <MapProvider>
    <ListPage>
      <List>
        <AreasList />
      </List>
      <Map>
        <AreasMap />
      </Map>
    </ListPage>
  </MapProvider>
)

export default AreasPage

const ListPage = tw(PageLayout)`md:flex-row flex-col-reverse`
const List = tw.div`flex flex-col relative h-1/2s md:h-full w-full md:w-2/5 overflow-y-scroll`
const Map = tw.div`flex justify-center h-1/2s md:h-full w-full md:w-3/5`
