// https://react-select.com/styles#overriding-the-theme
import tw from 'twin.macro'

export const PaginatorStyle = {
  container: (style, { isDisabled }) => ({
    ...style,
    ...tw`flex border-0 justify-end text-sm`,
    ...(isDisabled && { ...tw`opacity-40` })
  }),
  control: (style) => ({
    ...style,
    ...tw`flex items-center w-full cursor-pointer border min-height[auto] box-shadow[none] rounded-sm bg-gray-100`
  }),
  dropdownIndicator: () => ({
    ...tw`hidden`
  }),
  indicatorsContainer: () => ({
    ...tw`hidden`
  }),
  menu: (style) => ({
    ...style,
    ...tw`mt-1 text-left border border-bismark-400 overflow-hidden box-shadow[none] rounded-sm`
  }),
  option: (style) => ({
    ...style,
    ...tw`flex cursor-pointer p-1`
  }),
  singleValue: (style) => ({
    ...style,
    ...tw`text-gray-700 text-center`
  }),
  valueContainer: (style) => ({
    ...style,
    ...tw`px-1`
  })
}

export const LimitStyle = {
  ...PaginatorStyle,
  container: (style, { isDisabled }) => ({
    ...style,
    ...tw`flex border-0 w-9 justify-end text-sm flex-grow-0`,
    ...(isDisabled && { ...tw`opacity-40` })
  }),
  control: (style) => ({
    ...style,
    ...tw`flex items-center cursor-pointer border w-9 min-height[auto] box-shadow[none] rounded-sm bg-gray-100`
  }),
  menu: (style) => ({
    ...style,
    ...tw`mt-1 text-left border border-bismark-400 overflow-hidden box-shadow[none] w-9 rounded-sm`
  }),
  option: (style) => ({
    ...style,
    ...tw`flex cursor-pointer justify-center p-1`
  }),
  valueContainer: (style) => ({
    ...style,
    ...tw`p-0`
  })
}

export const OrderStyle = {
  ...PaginatorStyle,
  container: (style, { isDisabled }) => ({
    ...style,
    ...tw`flex border-0 w-24 justify-end text-sm flex-grow-0`,
    ...(isDisabled && { ...tw`opacity-40` })
  }),
  control: (style) => ({
    ...style,
    ...tw`flex items-center cursor-pointer border w-24 min-height[auto] box-shadow[none] rounded-sm bg-gray-100`
  }),
  menu: (style) => ({
    ...style,
    ...tw`mt-1 text-left border border-bismark-400 overflow-hidden box-shadow[none] w-24 rounded-sm`
  }),
  placeholder: (style) => ({
    ...style,
    ...tw`px-1`
  }),
  valueContainer: (style) => ({
    ...style,
    ...tw`p-0`
  })
}
