import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useInfoScreen } from '../../contexts/InfoScreenContext'
import { PageLayout } from '../../styles/Layout'
import { CreateInfoScreen } from '../InfoScreens/CreateInfoScreen'
import { InfoScreensList } from '../InfoScreens/InfoScreensList'

export default function InfoScreenPage() {
  const { adding, infoScreenBeingEdited } = useInfoScreen()
  const navigate = useNavigate()

  // Update Routes through side effects
  useEffect(() => {
    if (infoScreenBeingEdited)
      navigate(adding ? 'create' : `${infoScreenBeingEdited.id}`, { replace: true })
    else navigate('', { replace: true })
  }, [adding, infoScreenBeingEdited, navigate])

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Page>
            <Image src="https://res.cloudinary.com/dozsbtdha/image/upload/e_saturation:-60,f_auto,q_auto/v1661341916/forrestfenn/DALL_E_2022-08-22_22.08.31_-_A_golden_v_buried_in_a_lush_green_forest_j8lpi6.png" />
            <List>
              {infoScreenBeingEdited ? (
                <CreateInfoScreen infoScreen={infoScreenBeingEdited} />
              ) : (
                <InfoScreensList />
              )}
            </List>
          </Page>
        }
      />
    </Routes>
  )
}

const Page = tw(PageLayout)`flex-col md:flex-row`
const List = tw.div`flex w-full md:w-1/2 h-full shadow-md z-10`
const Image = tw.img`fixed top-0 right-0 object-cover w-auto h-full z-0`
