import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  checkpoints: {
    add: 'ADD_CHECKPOINT',
    addMany: 'ADD_MANY_CHECKPOINTS',
    set: 'SET_CHECKPOINTS',
    remove: 'REMOVE_CHECKPOINT',
    edit: 'EDIT_CHECKPOINT',
    clear: 'CLEAR_CHECKPOINTS'
  },
  candidates: {
    add: 'ADD_CANDIDATE_CHECKPOINT',
    addMany: 'ADD_MANY_CANDIDATE_CHECKPOINT',
    set: 'SET_CANDIDATE_CHECKPOINTS',
    remove: 'REMOVE_CANDIDATE_CHECKPOINT',
    edit: 'EDIT_CANDIDATE_CHECKPOINT',
    clear: 'CLEAR_CANDIDATE_CHECKPOINTS'
  },
  clear: 'CLEAR_ALL'
}

export const checkpointReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    // Checkpoints
    case actionTypes.checkpoints.add: {
      const { checkpoint } = action
      const { regular } = state
      return { ...state, regular: uniqBy([...regular, { ...checkpoint }], 'id') }
    }
    case actionTypes.checkpoints.addMany: {
      const { checkpoints } = action
      const { regular } = state
      return { ...state, regular: uniqBy([...regular, ...checkpoints], 'id') }
    }
    case actionTypes.checkpoints.set: {
      const { checkpoints } = action
      return { ...state, regular: checkpoints }
    }
    case actionTypes.checkpoints.remove: {
      const { checkpoint } = action
      const { regular } = state
      return { ...state, regular: regular.filter((item) => item.id !== checkpoint.id) }
    }
    case actionTypes.checkpoints.edit: {
      const { checkpoint } = action
      const { regular } = state
      return {
        ...state,
        regular: regular.map((item) => (item.id === checkpoint.id ? checkpoint : item))
      }
    }
    case actionTypes.checkpoints.clear: {
      return { ...state, regular: [] }
    }

    // Candidates
    case actionTypes.candidates.add: {
      const { candidate } = action
      const { candidates } = state
      return { ...state, candidates: uniqBy([...candidates, { ...candidate }], 'id') }
    }
    case actionTypes.candidates.addMany: {
      const { checkpoints } = action
      const { candidates } = state
      return { ...state, candidates: uniqBy([...candidates, ...checkpoints], 'id') }
    }
    case actionTypes.candidates.set: {
      const { candidates } = action
      return { ...state, candidates }
    }
    case actionTypes.candidates.remove: {
      const { candidate } = action
      const { candidates } = state
      return { ...state, candidates: candidates.filter((item) => item.id !== candidate.id) }
    }
    case actionTypes.candidates.edit: {
      const { candidate } = action
      const { candidates } = state
      return {
        ...state,
        candidates: candidates.map((item) =>
          item.id === candidate.id ? { ...item, ...candidate } : item
        )
      }
    }
    case actionTypes.candidates.clear: {
      return { ...state, candidates: [] }
    }
    // Clear
    case actionTypes.clear: {
      return { candidates: [], regular: [] }
    }
    default:
      return state
  }
}
