import React from 'react'
import tw, { styled } from 'twin.macro'

const ContainerContent = tw.div`flex flex-col w-full h-full gap-3 whiteSpace[break-spaces] p-3`
const MapInfoContainer = styled.div`
  ${tw`relative flex flex-col bg-white text-bismark-800 rounded-md shadow-lg bg-opacity-90 border-4 text-sm overflow-auto`}
  ${({ hide }) => hide && tw`hidden`}
`
const Label = tw.div`font-mono text-sm text-bismark-600`
const Header = tw(
  Label
)`sticky top-0 left-0 right-0 flex text-bismark-800 justify-center text-center bg-gray-200 p-1 select-none`

export const MapInfo = {
  Col: styled.div`
    ${tw`flex flex-col w-full gap-1`}
    & > :not(${Label}) {
      ${tw`px-2`}
    }
  `,
  Container: Object.assign(({ children, header, hide, ...props }) => (
    <MapInfoContainer hide={hide}>
      {header && <Header>{header}</Header>}
      <ContainerContent {...props}>{children}</ContainerContent>
    </MapInfoContainer>
  )),
  Label,
  Row: tw.div`flex items-center`
}
