import React, { useEffect, useState } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { useSupport } from '../../contexts/SupportContext'
import { ConversationSorts, ConversationTabs } from '../../enums'
import useFilter from '../../hooks/useFilter'
import useSort from '../../hooks/useSort'
import { List } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button, Card } from '../../styles/Layout'
import { ConversationFilter } from './ConversationFilter'
import { Items } from './ConversationItem'

export const ConversationsList = () => {
  const { activeTab, conversations, setActiveTab } = useSupport()
  const { applyFilters, ...filterMethods } = useFilter()
  const { applySort, ...sortMethods } = useSort(ConversationSorts.LAST_SENT)
  const [filteredItems, setFilteredItems] = useState(conversations)
  const [refresh, refreshList] = useState()

  // Click tab
  const onClickTab = (tab) => {
    setActiveTab(tab)
    if (sortMethods.sortingBy?.sort === ConversationSorts.RESOLVED_AT)
      sortMethods.sortListBy(ConversationSorts.LAST_SENT)
  }

  // Filter and sort items
  useEffect(() => {
    // Initial filtering
    const items =
      activeTab == ConversationTabs.UNRESOLVED
        ? conversations.filter((c) => !c.resolved)
        : conversations.filter((c) => c.resolved)

    setFilteredItems(applySort(applyFilters(items)))
  }, [activeTab, applyFilters, applySort, conversations])

  return (
    <Card.Container header="Conversations" color={theme`colors.black.700`} tw="text-gray-100">
      <Tabs>
        <Tab
          active={activeTab === ConversationTabs.UNRESOLVED}
          onClick={() => onClickTab(ConversationTabs.UNRESOLVED)}
        >
          Unresolved
        </Tab>
        <Tab
          active={activeTab === ConversationTabs.RESOLVED}
          onClick={() => onClickTab(ConversationTabs.RESOLVED)}
        >
          Resolved
        </Tab>
      </Tabs>
      <ListContainer>
        <ConversationFilter filter={filterMethods} sort={sortMethods} />
        <Text>
          {filteredItems.length}
          {activeTab === ConversationTabs.RESOLVED ? ' resolved' : ' unresolved'} conversations
          <RefreshButton onClick={refreshList}>
            <Icon.Refresh size="sm" />
          </RefreshButton>
        </Text>

        <ConversationList>
          {filteredItems?.length ? (
            <Items items={filteredItems} refresh={refresh} />
          ) : (
            <List.Empty>Nothing here...</List.Empty>
          )}
        </ConversationList>
      </ListContainer>
    </Card.Container>
  )
}

const Tabs = tw.div`flex w-full pb-0 bg-black gap-2 first:pl-2 select-none`
const Text = tw.div`flex justify-center items-center w-full text-sm mb-1 md:mb-2`
const ListContainer = tw.div`flex flex-col p-2 h-full w-full bg-bismark-800 overflow-hidden`
const ConversationList = tw(List.Container)`p-3 gap-3 bg-black items-center rounded-md`
const RefreshButton = tw(
  Button.Basic
)`absolute right-3 text-white p-1 hover:bg-bismark-700 active:bg-bismark-900`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[100px] text-gray-300 text-center rounded-t-md text-lg font-light`}
  ${({ active }) => css`
    ${active ? tw`bg-bismark-800` : tw` bg-black hover:bg-gray-900 active:bg-bismark-900 `}
  `}
`
