import React from 'react'
import { Route, Routes } from 'react-router-dom'
import tw from 'twin.macro'
import { Loader } from '../Loader/Loader'
import { PageLayout } from '../../styles/Layout'
import UserAccount from '../Account/UserAccount'
import ChangePassword from '../Account/ChangePassword'
import { useUser } from '../../contexts/UserContext'
import NotFoundPage from './NotFoundPage'

function AccountPage() {
  const { loading, userBeingEdited } = useUser()

  return (
    <Page>
      <Loader loading={loading} text="Fetching account details" />
      <Container>
        <Routes>
          <Route exact path="/" element={<UserAccount />} />
          <Route
            path="/edit"
            element={
              userBeingEdited ? (
                <UserAccount />
              ) : (
                <PageContainer>
                  <NotFoundPage />
                </PageContainer>
              )
            }
          />
          <Route path="/change-password" element={<ChangePassword />} />
        </Routes>
      </Container>
    </Page>
  )
}

export default AccountPage

const Page = tw(PageLayout)`justify-center`
const Container = tw.div`h-full lg:height[min-content] w-full lg:w-2/5`
const PageContainer = tw.div`h-full w-full`
