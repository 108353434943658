import React from 'react'
import Icon from '../styles/Icons'
import { theme } from 'twin.macro'
import uniq from 'lodash/uniq'

export const SeasonType = Object.freeze({
  SEASON: 'SEASON',
  SPECIAL_EVENT: 'SPECIAL_EVENT'
})

export const SeasonItemType = Object.freeze({
  COMMON: 'COMMON',
  RARE: 'RARE'
})

export const UiElementType = Object.freeze({
  COUNTER: 'COUNTER',
  TIMER: 'TIMER'
})

export const SeasonStatusType = Object.freeze({
  DRAFT: 'DRAFT',
  ENDED: 'ENDED',
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING'
})

export const SeasonStatus = Object.freeze({
  DRAFT: {
    icon: Object.assign((size = 'lg') => (
      <Icon.Clipboard color={theme`colors.gray.600`} size={size} />
    )),
    locked: false,
    message: 'DRAFT',
    removable: true,
    sort: 4
  },
  ENDED: {
    icon: Object.assign((size = 'lg') => <Icon.Flag color={theme`colors.gray.600`} size={size} />),
    locked: true,
    message: 'ENDED',
    removable: false,
    sort: 5
  },
  ERROR: {
    icon: Object.assign((size = 'lg') => <Icon.Error color={theme`colors.orange`} size={size} />),
    locked: true,
    message: 'ERROR',
    removable: false,
    sort: 1
  },
  LIVE: {
    icon: Object.assign((size = 'lg') => <Icon.Live color={theme`colors.green`} size={size} />),
    locked: true,
    message: 'LIVE',
    removable: false,
    sort: 2
  },

  UPCOMING: {
    icon: Object.assign((size = 'lg') => <Icon.Clock color={theme`colors.green`} size={size} />),
    locked: false,
    message: 'UPCOMING',
    removable: false,
    sort: 3
  },
  UNSET: {
    icon: Object.assign((size = 'lg') => (
      <Icon.DotsHorizontal color={theme`colors.gray.600`} size={size} />
    )),
    locked: false,
    message: 'UNSET',
    removable: true,
    sort: 6
  }
})

export const SeasonFilters = Object.freeze({
  ACTIVE: {
    field: 'active',
    filter: (arr, value) =>
      value ? arr.filter((item) => item.active) : arr.filter((item) => !item.active)
  },
  DRAFT: {
    field: 'draft',
    filter: (arr) => arr.filter(({ status }) => status === SeasonStatus.DRAFT)
  },
  ENDED: {
    field: 'ended',
    filter: (arr, value) =>
      value
        ? arr.filter(({ status }) => status === SeasonStatus.ENDED)
        : arr.filter(({ status }) => status !== SeasonStatus.ENDED)
  },
  ERROR: {
    field: 'error',
    filter: (arr) => arr.filter((item) => item.errors?.length)
  },
  LIVE: {
    field: 'live',
    filter: (arr) => arr.filter(({ status }) => status === SeasonStatus.LIVE)
  },
  QUERY: {
    field: 'query',
    filter: (arr, value) => {
      const filteredCountries = arr.filter((item) =>
        item.countries?.some(
          ({ countryId, name }) =>
            countryId.toLowerCase().indexOf(value) !== -1 ||
            name.toLowerCase().indexOf(value) !== -1
        )
      )
      const filteredIds = arr.filter((item) => item.id?.toLowerCase().indexOf(value) !== -1)
      const filteredNames = arr.filter(
        (item) => item.name && item.name?.toLowerCase().indexOf(value) !== -1
      )
      const filteredPrizes = arr.filter((item) =>
        item.prizes?.some(({ name }) => name?.toLowerCase().indexOf(value) !== -1)
      )
      const filteredTags = arr.filter((item) =>
        item.tags?.some((tag) => tag.label?.indexOf(value) !== -1)
      )

      return uniq(
        filteredNames
          .concat(filteredIds)
          .concat(filteredCountries)
          .concat(filteredPrizes)
          .concat(filteredTags)
      )
    }
  },
  TAGS: {
    field: 'tags',
    filter: (arr, tags) =>
      arr.filter((item) => item.tags?.some((tag) => tags?.some((ref) => ref.value === tag.value)))
  },
  UPCOMING: {
    field: 'upcoming',
    filter: (arr) => arr.filter(({ status }) => status === SeasonStatus.UPCOMING)
  }
})

export const SeasonSorts = Object.freeze({
  END_DATE: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.dateRange?.endDate
      const bHas = !!b.dateRange?.endDate
      if (aHas && bHas)
        return inc
          ? b.dateRange?.endDate - a.dateRange?.endDate
          : a.dateRange?.endDate - b.dateRange?.endDate
      return aHas ? -1 : bHas ? 1 : 0
    }),
  NAME: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.name?.localeCompare(b.name))
      : arr?.sort((a, b) => b.name?.localeCompare(a.name)),
  START_DATE: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.dateRange?.startDate
      const bHas = !!b.dateRange?.startDate
      if (aHas && bHas)
        return inc
          ? b.dateRange?.startDate - a.dateRange?.startDate
          : a.dateRange?.startDate - b.dateRange?.startDate
      return aHas ? -1 : bHas ? 1 : 0
    }),
  STATUS: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.status?.sort - b.status?.sort)
      : arr?.sort((a, b) => b.status?.sort - a.status?.sort)
})
