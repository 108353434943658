import { auth, db, FieldValue } from '../lib/firebase'
import { createOption, createStringArray, getOption } from './helperFunctions'
import { DIFFICULTY_OPTIONS, TRIVIA_CATEGORY_OPTIONS } from './labeledOptions'
import { isTimestamp } from './validationFunctions'

export const challengeConverter = {
  fromFirestore: (snapshot) => {
    const id = snapshot.id
    const path = snapshot.ref.path
    const {
      active,
      category,
      createdAt,
      custom,
      customTags,
      difficulty,
      options,
      question,
      triviaType,
      type
    } = snapshot.data()

    return {
      active,
      category: getOption(category, TRIVIA_CATEGORY_OPTIONS),
      ...(isTimestamp(createdAt) && { createdAt: createdAt.toDate() }),
      ...(custom && {
        custom: true,
        customTags: customTags?.map(createOption)
      }),
      difficulty: getOption(difficulty, DIFFICULTY_OPTIONS),
      id,
      options: options.map((o) => ({ ...o, showExplanation: !!o.explanation })),
      path,
      question,
      ...(triviaType && { triviaType }),
      type
    }
  },
  toFirestore: (challenge) => {
    const {
      active,
      category,
      createdAt,
      custom,
      customTags,
      difficulty,
      options,
      question,
      triviaType,
      type
    } = challenge

    return {
      active,
      category: category.value,
      createdAt: createdAt ?? new Date(),
      ...(custom && { custom }),
      customTags: custom ? createStringArray(customTags) : FieldValue.delete(),
      difficulty: difficulty.value,
      options: options.map(({ correct, showExplanation, ...option }) => ({
        ...option,
        ...(correct && { correct })
      })),
      question,
      type: type.value ?? type,
      ...(triviaType && { triviaType: triviaType.value ?? triviaType }),
      updatedAt: new Date(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  }
}
