import React from 'react'
import { useFormContext } from 'react-hook-form'
import tw from 'twin.macro'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'
import { MapInfo } from '../../../layout/Map'
import { RouteItem } from './RouteItem'

export const RouteList = () => {
  const { activeRoute, routesData } = useHvntRoute()
  const { routes, status, accepted } = routesData ?? {}
  const { watch } = useFormContext()
  const { area = {}, numberOfCheckpoints } = watch()

  return (
    <Container
      hide={!routesData}
      header={
        <Status tw="gap-2">
          {status?.icon}
          {status?.info}
        </Status>
      }
    >
      {!!routesData && (
        <>
          {area.saved ? (
            <MapInfo.Col tw="text-center overflow-scroll">
              <MapInfo.Label>{`Checkpoints: ${numberOfCheckpoints}`}</MapInfo.Label>
              <MapInfo.Label>{`Radius: ${area.radius} m`}</MapInfo.Label>
            </MapInfo.Col>
          ) : (
            <>
              <RouteInfo>{`${accepted} out of ${routes?.length} are valid starting points`}</RouteInfo>

              <ListContainer>
                {routes?.map((item, i) => (
                  <RouteItem
                    key={i}
                    active={activeRoute?.start?.id === item.start?.id}
                    item={item}
                    init={routes[0].start}
                  />
                ))}
              </ListContainer>
            </>
          )}
        </>
      )}
    </Container>
  )
}
const Container = tw(MapInfo.Container)`maxWidth[15rem]`
const RouteInfo = tw.div`text-center`
const Status = tw.div`flex text-base`
const ListContainer = tw.div`flex flex-col gap-1 overflow-scroll h-full`
