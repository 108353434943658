import axios from 'axios'
import config from '../config'

export const generic = axios.create({
  timeout: 30000
})

export const mercator = axios.create({
  baseURL: config.mercator.API,
  timeout: 30000,
  responseType: 'json'
})

mercator.interceptors.request.use(
  async (config) => {
    config.headers['authorization'] = process.env.REACT_APP_MERCATOR_KEY

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
