import React, { useMemo } from 'react'
import { Layer, Source } from 'react-map-gl'
import { theme } from 'twin.macro'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { useCheckpointMap } from '../../../contexts/CheckpointMapContext'
import { HoveredCellInfo } from '../../Location/LocationCells'
import { mapLayers } from '../../Map/MapLayers'

export const CandidateCells = ({ hoveredItem }) => {
  const { activeCandidateCells = [] } = useCheckpoint()
  const { candidateCellData, reviewedCandidateCellData } = useCheckpointMap()
  const hoveredCellId = useMemo(() => hoveredItem?.cell?.id ?? '', [hoveredItem])
  const filterActiveCell = useMemo(
    () =>
      activeCandidateCells.length
        ? ['match', ['get', 'id'], activeCandidateCells, true, false]
        : false,
    [activeCandidateCells]
  )
  const filterNotActiveCell = useMemo(
    () => (filterActiveCell ? ['!', filterActiveCell] : true),
    [filterActiveCell]
  )
  const filterHoveredCell = useMemo(
    () => ['all', filterNotActiveCell, ['match', ['get', 'id'], hoveredCellId, true, false]],
    [filterNotActiveCell, hoveredCellId]
  )

  const colorHovered = useMemo(() => {
    if (!hoveredItem?.cell) return
    const matchExisting = ['match', ['get', 'id']]
    matchExisting.push(hoveredItem.cell['id'], theme`colors.white`)
    matchExisting.push('rgba(0,0,0,0)')
    return matchExisting
  }, [hoveredItem])

  return (
    <>
      <Source type="geojson" data={candidateCellData}>
        <Layer {...mapLayers.candidate.cell.active} filter={filterActiveCell} />
        <Layer {...mapLayers.candidate.cell.border} filter={filterNotActiveCell} />
        <Layer {...mapLayers.candidate.cell.hover(colorHovered)} filter={filterHoveredCell} />
        <Layer {...mapLayers.candidate.cell.main} filter={filterNotActiveCell} />
      </Source>
      <Source type="geojson" data={reviewedCandidateCellData}>
        <Layer {...mapLayers.candidate.cell.reviewed} />
      </Source>
      {hoveredItem?.cell && <HoveredCellInfo cell={hoveredItem.cell} reviewing={true} />}
    </>
  )
}
