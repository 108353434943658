import React from 'react'
import tw, { styled } from 'twin.macro'
import Icon from '../../styles/Icons'
import { LimitStyle } from '../../styles/Select/PaginatorStyle'
import Dropdown from '../Dropdown'
import { FirestoreFilter } from './FirestoreFilter'

const paginationOptions = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 }
]

export const FirestorePaginator = ({
  sort,
  getNext,
  getPrev,
  isEnd,
  isLoading,
  isStart,
  setLimit
}) => {
  return (
    <Container>
      {sort && <FirestoreFilter options={sort.options} onUpdate={sort.onUpdate} />}

      <Dropdown.Paginator
        defaultValue={paginationOptions[2]}
        options={paginationOptions}
        onChange={({ value }) => setLimit(value)}
        style={LimitStyle}
      />

      <StyledButton onClick={getPrev} disabled={isLoading || isStart} tw="ml-1.5">
        <Icon.ChevronLeft size="sm" />
      </StyledButton>
      <StyledButton onClick={getNext} disabled={isLoading || isEnd}>
        <Icon.ChevronRight size="sm" />
      </StyledButton>
    </Container>
  )
}

const Container = tw.div`flex gap-1`
const StyledButton = styled.button`
  ${tw`flex flex-shrink-0 items-center justify-center text-bismark-800 select-none rounded-sm w-7 h-6`}
  ${({ active }) =>
    active
      ? tw`bg-norway-600 text-white`
      : tw`bg-bismark-300 hover:bg-bismark-400 active:bg-bismark`}
  ${({ disabled }) => (disabled ? tw`hover:bg-bismark-300 text-bismark-400` : tw`cursor-pointer`)}
`
