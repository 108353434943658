import React from 'react'
import tw from 'twin.macro'
import { useFormContext } from 'react-hook-form'
import { Button, Input } from '../../styles/Layout'
import { generateDescription, generateDistance } from '../../utility/generateClues'
import Icon from '../../styles/Icons'

export const PoiForm = ({ checkpoint, clue }) => {
  const { centerPoint } = checkpoint
  const { placeType, place } = clue
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext()
  return (
    <Section>
      <Input.Label>Text</Input.Label>
      <div tw="flex gap-2">
        <Input.TextArea
          {...register('text', { required: 'This is required' })}
          placeholder="Clue"
        />
        <Button.Primary
          onClick={() =>
            setValue(
              'text',
              clue?.textType === 'distance'
                ? generateDistance({ poi: clue, centerPoint })
                : generateDescription({ place, placeType })
            )
          }
        >
          <Icon.Refresh />
        </Button.Primary>
      </div>
      <Input.Error>{errors?.text?.message}</Input.Error>
    </Section>
  )
}
const Section = tw.div`flex flex-col my-2 px-4 w-full`
