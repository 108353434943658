import React, { createContext, useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { useCheckpoint } from './CheckpointContext'
import { useMap } from '../contexts/MapContext'
import { useAreas } from '../contexts/AreasContext'
import { arrayToFeatures } from '../utility/helperFunctions'
import { useCheckpointList } from '../hooks/useCheckpointList'
import useS2Cells from '../hooks/useS2Cells'
import { featureCollection } from '@turf/helpers'
import { useClue } from './ClueContext'

export const CheckpointMapContext = createContext()

export const CheckpointMapProvider = ({ children }) => {
  const { activeCity, allCities } = useAreas()
  const { focusOnFeatures, focusOnPoint } = useMap()
  const {
    addNewCheckpoint,
    candidateCells,
    checkpoints,
    checkpointBeingEdited,
    reviewedCells,
    updateCheckpoint
  } = useCheckpoint()
  const { filteredItems = [] } = useCheckpointList()
  const { clues } = useClue()
  const { getS2RectFromId } = useS2Cells()

  const [areaShot, setAreaShot] = useState()
  // Geojson
  const checkpointData = useMemo(
    () => arrayToFeatures(checkpoints?.regular ?? []),
    [checkpoints.regular]
  )
  const candidateClueData = useMemo(() => {
    return arrayToFeatures([...clues.candidates].filter((clue) => clue.centerPoint) ?? [])
  }, [clues])
  const candidateData = useMemo(
    () => arrayToFeatures(checkpoints?.candidates ?? []),
    [checkpoints.candidates]
  )
  const candidateCellData = useMemo(() => {
    const remainingCells =
      candidateCells?.filter((cell) => !reviewedCells.some((id) => id === cell.id)) ?? []
    return featureCollection(remainingCells.map((cell) => getS2RectFromId(cell)))
  }, [candidateCells, getS2RectFromId, reviewedCells])
  const reviewedCandidateCellData = useMemo(
    () => featureCollection(reviewedCells.map((id) => getS2RectFromId({ id }))),
    [getS2RectFromId, reviewedCells]
  )
  const filteredMapFeatures = useMemo(() => arrayToFeatures(filteredItems), [filteredItems])

  // Creates or updates centerpoint of checkpoint when map is double clicked
  const addNewMarker = useCallback(
    (centerPoint) => {
      if (checkpointBeingEdited) {
        updateCheckpoint(centerPoint)
      } else {
        addNewCheckpoint({ activeCity, ...centerPoint })
      }
    },
    [activeCity, addNewCheckpoint, checkpointBeingEdited, updateCheckpoint]
  )

  // Zoom to cities
  useEffect(() => {
    if (allCities && !activeCity) focusOnFeatures(allCities, { padding: 150 })
  }, [activeCity, allCities, focusOnFeatures])

  // Zoom to checkpoints or city
  useEffect(() => {
    if (!activeCity) return
    focusOnPoint(activeCity, { duration: 1500, minZoom: 11, maxZoom: 16 })
  }, [activeCity, focusOnPoint])

  const defaultValues = useMemo(
    () => ({
      addNewMarker,
      areaShot,
      candidateCellData,
      candidateClueData,
      candidateData,
      checkpointData,
      filteredMapFeatures,
      reviewedCandidateCellData,
      setAreaShot
    }),
    [
      addNewMarker,
      areaShot,
      candidateCellData,
      candidateClueData,
      candidateData,
      checkpointData,
      filteredMapFeatures,
      reviewedCandidateCellData
    ]
  )
  return (
    <CheckpointMapContext.Provider value={defaultValues}>{children}</CheckpointMapContext.Provider>
  )
}

// Hook
export const useCheckpointMap = () => {
  const context = useContext(CheckpointMapContext)
  if (context === undefined)
    throw new Error(
      '`useCheckpointMap` hook must be used within a `CheckpointMapProvider` component'
    )
  return context
}
