import React from 'react'
import tw from 'twin.macro'

export const ActiveFilters = ({ andFilters, filteredLength, filters, items = [] }) => (
  <Container data-testid="active-filters">
    {Object.keys(filters).length ? (
      <>
        <span>{'Showing'}</span>
        {Object.keys(filters).map((filter, i) => {
          const f = `${!filters[filter]?.value ? 'NOT ' : ''}${filter.toUpperCase()}`
          const op = andFilters ? 'AND' : 'OR'
          return (
            <Filters key={i}>
              <pre>{f}</pre>
              {i == Object.keys(filters).length - 1 ? '' : <Operator>{op}</Operator>}
            </Filters>
          )
        })}
      </>
    ) : (
      <>
        <span>{'Showing'}</span>
        <pre tw="text-blue-700">ALL</pre>
      </>
    )}
    <Total>
      (<b>{filteredLength}</b> out of
      <b>{items?.length}</b>)
    </Total>
  </Container>
)

const Container = tw.div`flex flex-wrap justify-center items-center font-normal truncate gap-2`
const Filters = tw.span`text-blue-700 flex gap-2`
const Total = tw.div`flex gap-1`
const Operator = tw.pre`text-amethyst`
