export const ClueType = Object.freeze({
  AREA: 'area',
  IMAGE: 'image',
  POI: 'poi',
  STREET_VIEW_OLD: 'streetView',
  STREET_VIEW: 'STREET_VIEW',
  TEXT: 'text',
  TREASURE: 'treasure'
})

export const ClueImageType = Object.freeze({
  STREET_VIEW: 'streetView',
  PLACE_PHOTO: 'placePhoto'
})

export const ClueImageBlurOptions = Object.freeze({
  value: 'e_blur_region',
  amount: 500,
  min: 100,
  max: 1000,
  step: 100
})
