import React, { useCallback, useEffect, useState } from 'react'
import tw, { theme } from 'twin.macro'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Card } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { Loader } from '../Loader/Loader'
import RBAC from '../RBAC'
import { useIsMounted } from '../../hooks/useIsMounted'
import { Alerts } from '../../utility/alerts'
import useCloudinary from '../../hooks/useCloudinary'
import { Action } from '../../layout/List'
import { useInfoScreen } from '../../contexts/InfoScreenContext'
import { InfoScreenStatus } from '../../enums'
import { setNextStatus } from '../../utility/formatInfoScreens'
import { InfoScreenForm } from './Form/InfoScreenForm'

export const CreateInfoScreen = ({ infoScreen = {} }) => {
  const {
    adding,
    deleteInfoScreen,
    saveInfoScreen,
    setValidateForm,
    stopEditingInfoScreen,
    validateForm
  } = useInfoScreen()

  const { mounted } = useIsMounted()
  const { uploadImage } = useCloudinary()

  const methods = useForm({
    defaultValues: {
      ...merge(
        {
          active: false,
          priority: 0,
          showOnlyOnce: false
        },
        omit(infoScreen, ['errors', 'path', 'updatedAt'])
      )
    },
    mode: 'all'
  })
  const { clearErrors, handleSubmit, register, setError, setValue, watch } = methods

  const { active, dateRange } = watch()
  const [addedImage, setAddedImage] = useState(false)
  const [loading, setLoading] = useState(false)

  const activeChanged = active ? !infoScreen.active : infoScreen.active
  const canDeleteinfoScreen = !adding && infoScreen.status?.removable
  const locked = infoScreen.status?.locked
  const currentStatus = adding
    ? active
      ? InfoScreenStatus.UPCOMING
      : InfoScreenStatus.DRAFT
    : infoScreen.status
  const nextStatus = adding
    ? ''
    : setNextStatus({ active, dateRange }) === infoScreen.status
    ? ''
    : setNextStatus({ active, dateRange })

  // Submit form
  const onSubmit = useCallback(
    async (formData) => {
      // Show activate information
      if (formData.active && !infoScreen.active) {
        const { value: ok } = await mounted(Alerts.InfoScreen.LIVE_INFO())
        if (!ok) return
      }

      // Show deactivation information
      if (!formData.active && infoScreen.active) {
        if (infoScreen.status === InfoScreenStatus.UPCOMING) {
          const { value: ok } = await mounted(Alerts.InfoScreen.LIVE_CANCEL())
          if (!ok) return
        }
      }

      // Upload image
      if (addedImage) {
        setLoading(true)
        try {
          formData.imageUrl = await uploadImage({
            file: formData.imageUrl,
            id: infoScreen.id,
            path: 'info-screens/'
          })
        } catch (err) {
          setError('imageUrl', { type: 'manual', message: 'Image might be too big!' })
          setLoading(false)
          return Alerts.Image.UPLOAD_FAILED(err)
        }
      }

      // Save the info screen
      await saveInfoScreen({ ...infoScreen, ...formData })
    },
    [addedImage, infoScreen, mounted, saveInfoScreen, setError, setLoading, uploadImage]
  )

  // Activate and require validation
  const onActivateInfoScreen = useCallback(async () => {
    setValidateForm(true)
    setValue('active', true)
  }, [setValidateForm, setValue])

  // Deactivate and skip validation
  const onDeactivateInfoScreen = useCallback(async () => {
    setValidateForm(false)
    setValue('active', false)
  }, [setValidateForm, setValue])

  // Delete info screen if possible
  const onDeleteInfoScreen = useCallback(async () => {
    const { value: remove } = await mounted(Alerts.InfoScreen.DELETE_WARNING())
    if (!remove) return

    await deleteInfoScreen(infoScreen)
  }, [deleteInfoScreen, infoScreen, mounted])

  // Register fields on mount
  useEffect(() => {
    register('active')
    if (!validateForm) clearErrors()
  }, [clearErrors, register, validateForm])

  return (
    <Card.Container
      header={adding ? 'Create Info Screen' : `Edit ${infoScreen.name ?? 'Info Screen'}`}
      color={theme`colors.sky.400`}
      xHover={theme`colors.sky.500`}
      xActive={theme`colors.sky.600`}
      onClose={stopEditingInfoScreen}
    >
      <Loader loading={loading} />

      <Action.Header>
        <Action.Active active={active} changed={activeChanged} />
        <Action.Status status={currentStatus} nextStatus={nextStatus} tw="ml-auto" />
        <Action.More
          content={
            <>
              {active ? (
                <Button.Primary size="sm" onClick={onDeactivateInfoScreen} disabled={loading} ring>
                  <Icon.Moon size="sm" mr="2" />
                  Deactivate Screen
                </Button.Primary>
              ) : (
                <Button.Secondary size="sm" onClick={onActivateInfoScreen} disabled={loading} ring>
                  <Icon.Sun size="sm" mr="2" />
                  Activate Screen
                </Button.Secondary>
              )}
              <RBAC>
                <Button.Warning
                  size="sm"
                  onClick={onDeleteInfoScreen}
                  disabled={!canDeleteinfoScreen || loading}
                  ring
                >
                  <Icon.Trashcan size="sm" mr="2" />
                  Delete Screen
                </Button.Warning>
              </RBAC>
            </>
          }
        />
      </Action.Header>

      <FormProvider {...methods}>
        <InfoScreenForm flagUpload={setAddedImage} loading={loading} locked={locked} />
      </FormProvider>

      <Footer>
        <Button.White onClick={stopEditingInfoScreen} ring tw="mr-auto">
          <Icon.Close mr="2" />
          Cancel
        </Button.White>
        {active ? (
          <Button.Submit onClick={handleSubmit(onSubmit)} disabled={loading} ring>
            <Icon.Check mr="2" />
            Save Screen
          </Button.Submit>
        ) : (
          <Button.Primary onClick={handleSubmit(onSubmit)} disabled={loading} ring>
            <Icon.Download mr="2" />
            Save Draft
          </Button.Primary>
        )}
      </Footer>
    </Card.Container>
  )
}

const Footer = tw(Card.Footer)`z-30 gap-2 py-3 bg-sky-400`
