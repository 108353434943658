import React from 'react'
import { Item, List } from '../../../layout/List'
import { format } from 'date-fns'
import { ATag, HvntTag, UserTag } from '../../../styles/Link'
import tw from 'twin.macro'
import config from '../../../config'

export const ClaimedList = ({ prize = {} }) => {
  const { claimedPrizes } = prize

  return (
    <>
      <List.Sticky>
        <List.Header>
          <ListData>Instance Id</ListData>
          <ListData>Claimed By</ListData>
          <ListData>Hvnt</ListData>
          <ListData>Participation</ListData>
          <ListData>Claimed At</ListData>
        </List.Header>
      </List.Sticky>

      <List.Container>
        {claimedPrizes?.length ? (
          claimedPrizes.map(({ claimedAt, hvnt, id, participation, user }, i) => (
            <Item.Container key={i}>
              <Item.Header>
                <ItemData>
                  {id ? (
                    <ATag
                      href={`${config.firebase.FIRESTORE_URL}/prizes/${prize.id}/claimed/${id}`}
                    >
                      {id}
                    </ATag>
                  ) : (
                    '-'
                  )}
                </ItemData>
                <ItemData>
                  {user?.id ? (
                    <UserTag to={`/users?id=${user.id}`} tw="col-span-3">
                      {user.id}
                    </UserTag>
                  ) : (
                    '-'
                  )}
                </ItemData>
                <ItemData>
                  {hvnt?.id ? <HvntTag to={`/hvnts?id=${hvnt.id}`}>{hvnt.id}</HvntTag> : '-'}
                </ItemData>
                <ItemData>
                  {participation?.id ? (
                    <ATag
                      href={`${config.firebase.FIRESTORE_URL}/participations/${participation.id}`}
                    >
                      {participation.id}
                    </ATag>
                  ) : (
                    '-'
                  )}
                </ItemData>
                <ItemData>{claimedAt ? format(claimedAt, 'LLL do yyyy, HH:mm') : '-'}</ItemData>
              </Item.Header>
            </Item.Container>
          ))
        ) : (
          <List.Empty>{'Nothing here...'}</List.Empty>
        )}
      </List.Container>
    </>
  )
}

const ListData = tw(List.HeaderData)`px-4`
const ItemData = tw(Item.HeaderData)`px-4`
