import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import tw, { theme } from 'twin.macro'
import Icon from '../../styles/Icons'
import { useClue } from '../../contexts/ClueContext'
import { Button, Input, Card } from '../../styles/Layout'
import { Alerts, Toast } from '../../utility/alerts'
import { useIsMounted } from '../../hooks/useIsMounted'
import { PoiForm } from './PoiForm'
import { StreetViewForm } from './StreetViewForm'
import { TreasureMapForm } from './TreasureMapForm'
import { ImageForm } from './ImageForm'
import { AreaForm } from './AreaForm'
import { TextForm } from './TextForm'
import { db } from '../../lib/firebase'
import { Loader } from '../Loader/Loader'
import RBAC from '../RBAC'
import { ClueType } from '../../enums'
import { Switch } from '../Layout/Switch'
import { CLUE_TYPE_OPTIONS, DIFFICULTY_OPTIONS } from '../../utility/labeledOptions'
import { Action } from '../../layout/List'
import Dropdown from '../Dropdown'

export const ClueForm = ({ checkpoint, clue, onSave }) => {
  const { adding, clues, stopEditingClue } = useClue()
  const { mounted } = useIsMounted()
  const disabledOptions = useMemo(
    () =>
      clues.regular?.some(({ type }) => type.value === ClueType.STREET_VIEW)
        ? [ClueType.STREET_VIEW]
        : [],
    [clues]
  )

  const methods = useForm({
    defaultValues: { active: true, free: false, original: false, radius: 50, ...clue },
    shouldUnregister: true,
    mode: 'onChange'
  })
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
    watch
  } = methods

  const [loading, setLoading] = useState(false)
  const { active, type } = watch()
  const clueRef = useMemo(() => clue.path && db.doc(clue.path), [clue])
  const creatableClueType = useMemo(
    () =>
      [...CLUE_TYPE_OPTIONS].filter(
        (option) => ![ClueType.AREA, ClueType.POI, ClueType.STREET_VIEW_OLD].includes(option.value)
      ),
    []
  )

  // Register fields
  useEffect(() => {
    register('active')
    register('candidate')
    register('metadata')
  }, [register])

  // Trigger field validation on edit
  useEffect(() => {
    if (!clue || !clue.errors?.length) return
    clue.errors.forEach((field) =>
      field === 'broken'
        ? setError('broken', {
            type: 'manual',
            message: 'This clue is broken and should be removed'
          })
        : trigger(field)
    )
  }, [clue, setError, trigger])

  const onActivateClue = useCallback(async () => {
    setValue('active', true)
  }, [setValue])

  const onDeactivateClue = useCallback(async () => {
    const { value: ok } = await mounted(Alerts.Clue.DEACTIVATE_CONFIRM())
    if (!ok) return
    setValue('active', false)
  }, [mounted, setValue])

  // Remove Clue from form data and reset fields
  const onDeleteClue = useCallback(async () => {
    const { value: remove } = await mounted(Alerts.Clue.DELETE_CONFIRM())
    if (!remove) return

    try {
      clueRef.delete()
      Toast.fire({
        title: 'Clue deleted!',
        icon: 'success'
      })
    } catch (err) {
      console.error(err)
    }
    stopEditingClue()
  }, [clueRef, mounted, stopEditingClue])

  // Submit Form
  const onSubmit = useCallback(
    async (formData) => {
      const { imageData, imageUrl, type } = formData

      let imageMissingMessage = ''
      switch (type.value) {
        case ClueType.IMAGE:
        case ClueType.STREET_VIEW:
        case ClueType.STREET_VIEW_OLD:
        case ClueType.TREASURE: {
          if (type.value === ClueType.TREASURE) {
            imageMissingMessage = 'You need to add a treasure map!'
          } else {
            imageMissingMessage = 'You need to add an image!'
          }
          if (!imageData && !imageUrl) {
            return setError('imageUrl', {
              type: 'manual',
              message: imageMissingMessage
            })
          }
        }
      }

      try {
        setLoading(true)
        onSave({ ...clue, ...formData })
      } catch (err) {
        if (err.field) setError(err.field, { type: 'manual', message: err.message })
        Alerts.Clue.SAVE_FAILED(err)
        setLoading(false)
      }
    },
    [clue, onSave, setError]
  )

  return (
    <>
      <Loader loading={loading} />
      <Card.Container
        color={theme`colors.norway.500`}
        xHover={theme`colors.norway.600`}
        xActive={theme`colors.norway.700`}
        xDisabled={loading}
        onClose={stopEditingClue}
        header={adding ? 'Create Clue' : 'Edit Clue'}
        size="sm"
        tw="w-full"
      >
        <Action.Header>
          <Action.Active active={active} />
          <Action.Right>
            {type && (
              <>
                <Controller
                  control={control}
                  name="free"
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      color="blue"
                      size="sm"
                      label="Free Clue"
                      defaultChecked={value}
                      onToggle={onChange}
                      disabled={type?.value === ClueType.TREASURE}
                    />
                  )}
                />
              </>
            )}
            <Action.More
              content={
                <>
                  {active ? (
                    <Button.Primary ring size="sm" onClick={onDeactivateClue}>
                      <Icon.Moon size="sm" mr="2" /> Deactivate Clue
                    </Button.Primary>
                  ) : (
                    <Button.Secondary ring size="sm" onClick={onActivateClue}>
                      <Icon.Sun size="sm" mr="2" /> Activate Clue
                    </Button.Secondary>
                  )}
                  <RBAC>
                    <Button.Warning ring size="sm" onClick={onDeleteClue} disabled={adding}>
                      <Icon.Trashcan size="sm" mr="2" /> Delete Clue
                    </Button.Warning>
                  </RBAC>
                </>
              }
              disabled={loading}
            />
          </Action.Right>
        </Action.Header>

        <Form>
          <div tw="grid grid-cols-2 gap-2 px-4">
            <Section tw="p-0">
              <Input.Label required={true}>Clue Type</Input.Label>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Dropdown.Select
                    {...field}
                    isDisabled={!adding}
                    isOptionDisabled={({ value }) =>
                      [ClueType.AREA, ClueType.POI].concat(disabledOptions).includes(value)
                    }
                    options={creatableClueType}
                  />
                )}
                rules={{ required: 'This is required' }}
                defaultValue={''}
              />
              <Input.Error>{errors?.broken?.message}</Input.Error>
              <Input.Error>{errors?.type?.message}</Input.Error>
            </Section>
            {type && !errors?.broken && (
              <Section tw="p-0">
                <Input.Label required={true}>Difficulty</Input.Label>
                <Controller
                  control={control}
                  name="difficulty"
                  render={({ field }) => (
                    <Dropdown.Select {...field} options={DIFFICULTY_OPTIONS} />
                  )}
                  rules={{ required: 'Difficulty is required' }}
                />
                <Input.Error>{errors?.difficulty?.message}</Input.Error>
              </Section>
            )}
          </div>

          <FormProvider {...methods}>
            {type?.value === ClueType.AREA && <AreaForm checkpoint={checkpoint} />}
            {type?.value === ClueType.IMAGE && <ImageForm clue={clue} id={clueRef.id} />}
            {type?.value === ClueType.POI && <PoiForm checkpoint={checkpoint} clue={clue} />}
            {type?.value === ClueType.STREET_VIEW && (
              <StreetViewForm checkpoint={checkpoint} id={clueRef.id} />
            )}
            {type?.value === ClueType.TEXT && <TextForm />}
            {type?.value === ClueType.TREASURE && <TreasureMapForm checkpoint={checkpoint} />}
          </FormProvider>
        </Form>
        {/* Footer */}
        <Card.Footer color={theme`colors.norway.300`}>
          <Button.White ring size="sm" onClick={stopEditingClue} tw="mr-auto" disabled={loading}>
            <Icon.Close size="sm" mr="2" />
            Cancel
          </Button.White>
          <Button.Submit
            onClick={handleSubmit(onSubmit)}
            ring
            size="sm"
            disabled={!isValid || loading}
          >
            <Icon.Check size="sm" mr="2" />
            {clue.candidate ? 'Approve' : adding ? 'Add Clue' : 'Save Clue'}
          </Button.Submit>
        </Card.Footer>
      </Card.Container>
    </>
  )
}

const Form = tw.form`flex flex-1 flex-col w-full font-sans font-light py-4 overflow-y-scroll`
const Section = tw.div`flex flex-col my-2 px-4 w-full`
