import React, { useState, useRef, useCallback, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Map, NavigationControl, Source, Layer, GeolocateControl } from 'react-map-gl'
import { useHvnt } from '../../../contexts/HvntContext'
import { Loader } from '../../Loader/Loader'
import config from '../../../config'
import { MapControl } from '../../Map/MapControl'
import { mapLayers } from '../../Map/MapLayers'
import { feature } from '@turf/helpers'
import { useAreas } from '../../../contexts/AreasContext'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'
import { HvntArea } from './HvntArea'
import { useMap } from '../../../contexts/MapContext'
import { LayerVisibilityControl } from '../../Map/LayerVisibilityControl'
import { CheckpointsPopups } from '../../Checkpoint/Map/CheckpointPopups'
import { AddArea } from './AddArea'
import { useUser } from '../../../contexts/UserContext'
import { RouteList } from './RouteList'
import { useFormContext, useWatch } from 'react-hook-form'
import Icon from '../../../styles/Icons'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useMapControl } from '../../../hooks/useMapControl'
import { useDebounce } from 'react-use'
import { Role } from '../../../enums'
import { Overlay } from '../../../layout/Map'

export const HvntAreaMap = ({ loading }) => {
  const mapRef = useRef(null)
  const { activeCity } = useAreas()
  const { inactiveCheckpoints } = useHvnt()
  const {
    addHvntCircle,
    buildRoute,
    eligibleCheckpoints,
    hoveredItem,
    routesData,
    s2CellData,
    setHoveredItem
  } = useHvntRoute()
  const { isMounted } = useIsMounted()
  const { focusedBbox, focusedMarker, setLayerVisibility } = useMap()
  const { zoomToBbox, zoomToPoint } = useMapControl(mapRef)
  const { userHasRole } = useUser()
  const {
    formState: { errors }
  } = useFormContext()

  const area = useWatch({ name: 'area', defaultValue: {} })
  const [addingArea, setAddingArea] = useState(false)
  const [busy, setBusy] = useState(true)
  const [cursor, setCursor] = useState('auto')
  const [viewstate, setViewstate] = useState({
    longitude: activeCity?.centerPoint?.longitude ?? 13.078419,
    latitude: activeCity?.centerPoint?.latitude ?? 49.342644,
    maxZoom: 18,
    zoom: 12
  })

  // Viewport change
  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  // Interactions on map click
  const onClickMap = useCallback(
    ({ features = [], lngLat }) => {
      // Add marker on click
      if (addingArea) {
        setAddingArea(false)
        const centerPoint = { longitude: lngLat.lng, latitude: lngLat.lat }
        userHasRole([Role.CREATOR]) && addHvntCircle({ centerPoint })
        return
      }
      const { layer, properties } = features[0] ?? {}
      switch (layer?.id) {
        case mapLayers.hvnt.checkpoint.id: {
          if (!routesData) return
          const routeItem = routesData.routes?.filter(
            (route) => route.start.id === properties.id
          )[0]
          if (!routeItem) return
          return buildRoute(routeItem)
        }
      }
    },
    [addingArea, addHvntCircle, buildRoute, routesData, userHasRole]
  )

  // Hover over features
  const onHover = useCallback(
    ({ features = [] }) => {
      const { layer, properties } = features[0] ?? {}
      setCursor(addingArea ? 'crosshair' : 'pointer')

      switch (layer?.id) {
        case mapLayers.checkpoint.inactive.id:
        case mapLayers.hvnt.checkpoint.id: {
          return setHoveredItem(() => ({ checkpoint: properties }))
        }
      }
    },
    [addingArea, setHoveredItem]
  )

  const onMouseLeave = useCallback(() => {
    setHoveredItem()
    setCursor(addingArea ? 'crosshair' : 'auto')
  }, [addingArea, setHoveredItem])

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedMarker) return zoomToPoint(focusedMarker)
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    200,
    [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint]
  )

  // Update cursor
  useEffect(() => {
    setCursor(addingArea ? 'crosshair' : 'auto')
  }, [addingArea])

  // Set layer visibility
  useEffect(() => {
    setLayerVisibility({
      'Inactive Checkpoints': {
        layers: ['checkpoint-inactive'],
        visible: false
      },
      'S2 Cells': {
        layers: ['s2-cell'],
        visible: false
      }
    })
    return () => setLayerVisibility()
  }, [setLayerVisibility])

  return (
    <MapContainer>
      <Overlay tw="top-2 left-2 bottom-2">
        {!busy && <LayerVisibilityControl mapRef={mapRef} />}
        <RouteList />
      </Overlay>

      <AddArea
        adding={addingArea}
        onClick={(add) => setAddingArea(add)}
        disabled={busy || loading}
      />
      <Map
        {...viewstate}
        cursor={cursor}
        interactiveLayerIds={
          busy || addingArea
            ? null
            : [mapLayers.hvnt.checkpoint.id, mapLayers.checkpoint.inactive.id]
        }
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
        mapStyle={config.mapbox.ANALYTICS_MAP}
        onClick={onClickMap}
        onLoad={() => setBusy(false)}
        onMouseMove={onHover}
        onMouseLeave={onMouseLeave}
        onMove={onMoveMap}
        ref={mapRef}
        style={{ width: '100vw', height: '100%' }}
      >
        <Loader loading={busy || loading} text={busy ? 'Loading Map' : ''} />

        <Source type="geojson" data={s2CellData ?? feature()}>
          <Layer {...mapLayers.s2Cell.main} />
        </Source>

        <Source type="geojson" data={inactiveCheckpoints}>
          <Layer {...mapLayers.checkpoint.inactive} />
        </Source>

        <Source type="geojson" data={eligibleCheckpoints}>
          <Layer {...mapLayers.hvnt.checkpoint} />
        </Source>

        <HvntArea />

        <CheckpointsPopups hoveredItem={hoveredItem} />
        <MapControl>
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          <NavigationControl tw="bottom[-10px]" />
        </MapControl>
      </Map>

      {area?.saved ? (
        <Info>
          <Icon.Check />
        </Info>
      ) : (
        errors.area && (
          <Info error={true}>
            <Icon.Close />
            {errors.area.message ?? 'Missing valid hvnt area!'}
          </Info>
        )
      )}
    </MapContainer>
  )
}

const MapContainer = tw.div`flex relative overflow-hidden justify-center font-light`
const Info = styled.div`
  ${tw`absolute bottom-0 flex gap-2 w-full justify-center shadow-md z-10 p-5`}
  ${({ error }) => (error ? tw`bg-matrix-400 text-white-pure` : tw`bg-norway text-white-pure`)}
`
