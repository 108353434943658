import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  add: 'ADD_SEASON',
  edit: 'EDIT_SEASON',
  remove: 'REMOVE_SEASON',
  clear: 'CLEAR_SEASONS'
}

export const seasonReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.add: {
      const { season } = action
      return uniqBy([...state, season], 'id')
    }

    case actionTypes.edit: {
      const { season } = action
      return state.map((item) => (item.id === season.id ? season : item))
    }

    case actionTypes.remove: {
      const { season } = action
      return state.filter((item) => item.id !== season.id)
    }

    case actionTypes.clear: {
      return []
    }
    default:
      return state
  }
}
