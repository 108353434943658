import React, { useRef, useState } from 'react'
import tw, { styled, css, theme } from 'twin.macro'
import Icon from './Icons'
import { useClickAway } from 'react-use'

/**
 * Page Layout
 */
export const PageLayout = tw.div`relative flex flex-row h-full sm:h-auto w-full overflow-x-hidden mt-0 sm:mt-12 font-sans`
export const PageLayoutCol = tw(PageLayout)`flex-col`

/**
 * Login
 */
export const Authenticate = {
  Container: tw.div`flex flex-col w-full sm:w-1/2 xl:w-1/3 height[min-content] m-0 sm:mt-24
  rounded-md shadow-lg font-light overflow-hidden`,
  Header: tw.div`flex w-full justify-center p-3 bg-gray-900 text-white text-xl`,
  Form: tw.form`flex flex-col gap-2 flex-1 p-10 bg-white`,
  Error: tw.div`text-xs text-red truncate`
}

/**
 * Card Component
 */
export const Card = {
  Container: ({
    children,
    color,
    footer,
    footerColor,
    header,
    onClose = undefined,
    xActive,
    xDisabled = false,
    xHover,
    size = 'md',
    ...props
  }) => {
    return (
      <CardContainer {...props}>
        {header && (
          <CardHeader color={color} size={size}>
            {header}
            {!!onClose && (
              <CardCloseX
                color={color}
                colorHover={xHover}
                colorActive={xActive}
                disabled={xDisabled}
                onClick={onClose}
                size={size}
              >
                <Icon.Close size={size} />
              </CardCloseX>
            )}
          </CardHeader>
        )}
        <CardContent>
          {children}
          {footer && <CardFooter color={footerColor}>{footer()}</CardFooter>}
        </CardContent>
      </CardContainer>
    )
  },
  Footer: styled.div`
    ${tw`flex sticky bottom-0 w-full items-center justify-center py-2 px-4 z-10 gap-2`}
    ${({ color = theme`colors.gray.200` }) => color && `background-color: ${color}`}
  `
}

const CardContainer = styled.div`
  ${tw`relative flex flex-col w-full font-sans font-light shadow-md`}
  ${({ background, compact }) => css`
    & > ${CardContent} {
      ${background ? `background-color: ${background};` : tw`bg-white`}
      ${!compact && tw`h-full`}
    }
    ${!compact && tw`h-full`}
  `}
`
const CardHeader = styled.div`
  ${tw`flex p-2 justify-center items-center shadow-md zIndex[80] select-none`}
  ${({ color, headerColor, size }) => css`
    ${size === 'sm' ? tw`h-9 text-lg` : tw`h-12 text-xl`};
    ${headerColor && `color: ${headerColor}`};
    ${color && `background-color: ${color}`};
  `}
`
export const CardCloseX = styled.div`
  ${tw`absolute right-2 p-1 rounded-sm cursor-pointer`}
  ${({ disabled }) => disabled && tw`pointer-events-none text-gray-600`}
  &:hover {
    ${({ colorHover }) => `background-color: ${colorHover}`}
  }
  &:active {
    ${({ colorActive }) => `background-color: ${colorActive}`};
  }
`
const CardContent = tw.div`flex flex-col items-center overflow-y-scroll`
const CardFooter = styled.div`
  ${tw`flex w-full sticky bottom-0 z-40 gap-3`}
  ${({ color }) => color && `background-color: ${color}`}
`

/**
 * Buttons
 */
// prettier-ignore
export const BaseButton = styled.button.attrs(({ ring, type = 'button' }) => ({ ring, type }))
` ${tw`relative inline-flex font-light rounded-sm w-auto justify-center items-center
    text-center whitespace-nowrap cursor-pointer text-gray-900 select-none focus:outline-none`};
  ${({ ring }) => ring && tw`ring-2`}
  ${({ disabled, size = 'md' }) => css`
    ${size === 'sm' && tw`py-1 px-2 text-sm`};
    ${size === 'md' && tw`py-2 px-4 text-base`};
    ${size === 'lg' && tw`py-3 px-8 text-xl`};
    ${size === 'xl' && tw`py-4 px-8 text-xl`};
    ${disabled && tw`pointer-events-none opacity-50`}
  `}
`

export const Button = {
  Basic: tw(BaseButton)`hover:bg-gray-300 active:bg-gray-400`,
  Gray: tw(BaseButton)`bg-gray-200 hover:bg-gray-300 active:bg-gray-400 ring-gray-400`,
  Filter: tw(BaseButton)`m-0 text-sm h-full hover:bg-gray-300 active:bg-gray-400`,
  More: tw(BaseButton)`m-0 p-0.5 h-full hover:bg-gray-300 active:bg-gray-400 ring-gray-300`,
  Primary: tw(BaseButton)`bg-blue-400 hover:bg-blue-500 active:bg-blue-600 ring-blue-600`,
  Secondary: tw(BaseButton)`bg-orange-400 hover:bg-orange-500 active:bg-orange-600 ring-orange-600`,
  Submit: tw(BaseButton)`bg-green-400 hover:bg-green-500 active:bg-green-600 ring-green-600`,
  Warning: tw(BaseButton)`bg-red-400 hover:bg-red-500 active:bg-red-600 ring-red-500 text-black`,
  White: tw(BaseButton)`bg-white-pure hover:bg-gray-100 active:bg-gray-200 ring-gray-400`
}

export const MoreButton = ({
  bottom = true,
  color = theme`colors.gray.300`,
  content,
  disabled,
  size = 'md',
  title = 'Options',
  vertical = false,
  ...props
}) => {
  const ref = useRef()
  useClickAway(ref, () => {
    setOpen(false)
  })
  const [open, setOpen] = useState(false)

  return (
    <MoreContainer bottom={bottom} ref={ref}>
      <Button.More
        {...props}
        role="more"
        size={size}
        disabled={disabled}
        onClick={() => setOpen((open) => !open)}
      >
        {open ? (
          bottom ? (
            <Icon.ChevronUp size={size} />
          ) : (
            <Icon.ChevronDown size={size} />
          )
        ) : vertical ? (
          <Icon.DotsVertical size={size} />
        ) : (
          <Icon.DotsHorizontal size={size} />
        )}
      </Button.More>

      <div tw="relative">
        {open && (
          <More color={color}>
            <div tw="text-center pb-1 text-xs font-mono divide-solid border-b border-b-gray">
              {title}
            </div>
            {content}
          </More>
        )}
      </div>
    </MoreContainer>
  )
}
export const IconButton = ({
  bottom = true,
  color = theme`colors.gray.300`,
  content,
  disabled,
  size = 'md',
  icon,
  ...props
}) => {
  const ref = useRef()
  useClickAway(ref, () => {
    setOpen(false)
  })
  const [open, setOpen] = useState(false)

  return (
    <MoreContainer bottom={bottom} ref={ref}>
      <IconContainer
        {...props}
        size={size}
        disabled={disabled}
        onClick={() => setOpen((open) => !open)}
      >
        {icon}
      </IconContainer>
      <div tw="relative">
        {open && (
          <More color={color} tw="py-4">
            {content}
          </More>
        )}
      </div>
    </MoreContainer>
  )
}
const More = styled.div`
  ${tw`absolute right-0 flex flex-col h-64 w-64 gap-2 p-2 pb-4 shadow-lg zIndex[99] rounded-md border-2 border-gray-400`}
  ${({ color }) => `background-color: ${color}`}
`
const IconContainer = tw.div`p-1 cursor-pointer`
const MoreContainer = styled.div`
  ${tw`flex z-50`}
  ${({ bottom }) => css`
    ${bottom ? tw`flex-col` : tw`flex-col-reverse`}
    & > div > ${More} {
      ${bottom ? tw`top-1` : tw`bottom-1`}
    }
  `}
`

export const ErrorBubble = ({ size = 'lg', ...props }) => (
  <div {...props} data-testid="error-bubble" tw="z-10">
    <Icon.WarningSolid size={size} color={theme`colors.orange`} />
  </div>
)

// ************************** INPUT

// prettier-ignore
export const Input = {
  Default: styled.input.attrs(({ size = 'md' }) => ({
    'data-lpignore': true, // Ignore lastpass
    autoComplete: 'off',
    size
  }))
  `${tw`flex outline-none bg-white-pure text-gray-900 font-sans font-light 
    leading-normal border border-gray-200`};
    width: -moz-available;
    width: -webkit-fill-available;
    ${({ size }) => size === 'sm' && tw`py-1 px-3 text-sm rounded-sm`}
    ${({ size }) => size === 'md' && tw`py-1.5 px-4 text-base rounded-sm`}
    ${({ size }) => size === 'lg' && tw`py-3 px-6 text-xl rounded-md`}
    ${({ disabled }) => disabled && tw`bg-gray-100 text-gray border-dashed`}
  `,
  
  TextArea: styled.textarea.attrs(({ rows = 1 }) => ({ 'data-lpignore': true, rows }))
  `${tw`flex outline-none bg-white-pure py-2 px-4 rounded-sm text-gray-900 font-sans 
    font-light leading-normal resize-none border border-gray-200 w-full`};
    width: -moz-available;
    width: -webkit-fill-available;
    ${({ size }) => size === 'sm' && tw`py-1 px-3 text-sm rounded-sm`}
    ${({ size }) => size === 'md' && tw`py-1.5 px-4 text-base rounded-sm`}
    ${({ size }) => size === 'lg' && tw`py-3 px-6 text-xl rounded-md`}
    ${({ disabled }) => disabled && tw`bg-gray-200 text-gray-400 border-dashed`};
  `,

  Filter: styled.input.attrs({ 'data-lpignore': true, autoComplete: 'off' })
  `${tw`flex-1 font-light text-sm outline-none h-full rounded-md p-2`}
    ${({ disabled }) => disabled && tw`bg-gray-100 text-gray border-dashed`}
  `
  ,

  Label: styled.span.attrs(({ size='md' }) =>({ size }))
  `${tw`flex items-center font-sans font-light text-gray-900 truncate mb-1`}
      ${({ size }) => size === 'sm' && tw`text-xs`}
      ${({ size }) => size === 'md' && tw`text-sm`}
    ${({ required }) => required && css`
      &:before {
        ${tw`text-red mx-1`};
        content: '\\273D';
      }
    `}
  `,
  Error: styled.div.attrs(() => ({role: 'alert'}))`${tw`text-xs text-red truncate mt-1`}`
}

export const Info = ({ children, ...props }) => (
  <InfoContainer {...props}>
    <Icon.Info size="lg" color={theme`colors.sky`} />
    <div tw="flex flex-col pt-1">{children}</div>
  </InfoContainer>
)
const InfoContainer = tw.div`flex text-gray italic items-start gap-3 my-1`

export const Status = ({ active, size = 'md', text, ...props }) => (
  <StatusContainer active={active} size={size} {...props}>
    <ActiveStatus />
    {text && (active ? 'Active' : 'Inactive')}
  </StatusContainer>
)

const StatusContainer = styled.div`
  ${tw`flex items-center`}
  ${({ active, size }) => css`
     ${size === 'lg' ? tw`gap-2` : size === 'md' ? tw`gap-1.5` : tw`gap-1`};
    & > * {
       ${size === 'lg' ? tw`w-5 h-5` : size === 'md' ? tw`w-4 h-4` : tw`w-3 h-3`}
       ${active ? tw`bg-green-400 border-green-600` : tw`bg-red-400 border-red-600`}
     }
    }
  `}
`
const ActiveStatus = styled.div`
  ${tw`rounded-full border-2 flex-shrink-0`}
`
