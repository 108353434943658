import React from 'react'
import tw, { theme } from 'twin.macro'
import Icon from '../../styles/Icons'

export const ErrorBubble = ({ color = theme`colors.orange`, size = 'lg', ...props }) => (
  <Container {...props} data-testid="error-bubble">
    <Icon.WarningSolid size={size} color={color} />
  </Container>
)

const Container = tw.div`z-40`
