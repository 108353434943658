import { differenceInHours } from 'date-fns'
import { auth, db } from '../lib/firebase'
import { conditionalDelete, getOption } from './helperFunctions'
import { USER_GENDER_OPTIONS } from './labeledOptions'
import { ActiveStatus, ParticipationStatus } from '../enums'
import { isGeoPoint, isNumber, isTimestamp } from './validationFunctions'

export const authDataConverter = {
  fromAuth: (data) => {
    const { customClaims, uid, photoURL, ...authData } = data

    return {
      activeStatus: determineUserActivity(authData?.metadata?.lastRefreshTime),
      authData: {
        ...authData,
        roles: customClaims?.roles ?? [],
        isAdmin: !!customClaims?.isAdmin,
        ...(photoURL && { photoURL })
      },
      uid
    }
  },
  toAuth: (data) => {
    const { email, photoURL, ...authData } = data
    return {
      ...authData,
      ...(email && { email }),
      ...(photoURL && { photoURL })
    }
  }
}

export const userDataConverter = {
  fromFirestore: (snapshot) => {
    const { allowMockLocation, gender, location, ...data } = snapshot.data()
    return {
      ...data,
      allowMockLocation: !!allowMockLocation,
      ...(gender && { gender: getOption(gender, USER_GENDER_OPTIONS) }),
      ...(isGeoPoint(location) && {
        location: { latitude: location.latitude, longitude: location.longitude }
      })
    }
  },

  toFirestore: ({ age, allowMockLocation, createdAt, gender, ...data }) => {
    return {
      ...data,
      age: age ? Number(age) : conditionalDelete(age),
      allowMockLocation: conditionalDelete(allowMockLocation),
      createdAt: createdAt ?? new Date(),
      ...(gender && { gender: gender.value }),
      updatedAt: new Date(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  }
}

export const metadataConverter = {
  fromFirestore: (snapshot, options) => {
    // metadata has documents with ids 'timestamps', 'devices', etc.
    const id = snapshot.id
    const metadata = { [id]: snapshot.data(options) }
    const { location, timestamps } = metadata

    if (location) return { location }
    if (timestamps) {
      const { createdAt, lastLogin, updatedAt } = timestamps
      return {
        timestamps: {
          ...(createdAt && {
            createdAt: new Date(
              isTimestamp(createdAt)
                ? createdAt.toDate()
                : isNumber(createdAt)
                ? Number(createdAt)
                : createdAt
            )
          }),
          ...(lastLogin && {
            lastLogin: new Date(
              isTimestamp(lastLogin)
                ? lastLogin.toDate()
                : isNumber(lastLogin)
                ? Number(lastLogin)
                : lastLogin
            )
          }),
          ...(updatedAt && {
            updatedAt: new Date(
              isTimestamp(updatedAt)
                ? updatedAt.toDate()
                : isNumber(updatedAt)
                ? Number(updatedAt)
                : updatedAt
            )
          })
        }
      }
    }
  }
}

export const participationConverter = {
  fromFirestore: (snapshot) => {
    const id = snapshot.id
    const path = snapshot.ref.path
    const {
      cancelledAt,
      cancelledBy,
      completedAt,
      currentCheckpoint,
      endedAt,
      endsAt,
      hvnt,
      hvntRef,
      numberOfCheckpoints,
      players,
      startedAt,
      status,
      summary,
      timedOutAt
    } = snapshot.data()

    return {
      ...(cancelledAt && { cancelledAt: cancelledAt.toDate() }),
      ...(cancelledBy && { cancelledBy: cancelledBy.id }),
      ...(completedAt && { completedAt: completedAt.toDate() }),
      currentCheckpoint,
      ...(endedAt && { endedAt: endedAt.toDate() }),
      endsAt: endsAt?.toDate(),
      hvnt,
      hvntRef,
      id,
      numberOfCheckpoints,
      path,
      players,
      startedAt: startedAt?.toDate(),
      status: ParticipationStatus[status] ?? ParticipationStatus.UNSET,
      summary,
      ...(timedOutAt && { timedOutAt: timedOutAt.toDate() })
    }
  }
}

export const prizeConverter = {
  fromFirestore: (snapshot) => {
    const { claimedAt, participation, prize, ...data } = snapshot.data()
    return {
      ...data,
      ...(isTimestamp(claimedAt) && { claimedAt: claimedAt.toDate() }),
      id: snapshot.id,
      participation: { id: participation?.id, path: participation?.ref?.path },
      prize: { id: prize?.id, path: prize?.ref?.path }
    }
  }
}

export const transactionConverter = {
  fromFirestore: (snapshot) => {
    const { timestamp, ...data } = snapshot.data()
    const id = snapshot.id
    return {
      id,
      timestamp: timestamp?.toDate() ?? new Date(Number(id)),
      ...data
    }
  }
}

export const xpConverter = {
  fromFirestore: (snapshot) => {
    const { timestamp, ...data } = snapshot.data()
    const id = snapshot.id
    return {
      id,
      timestamp: timestamp?.toDate() ?? new Date(Number(id)),
      ...data
    }
  }
}

export const determineUserActivity = (lastActive = 0) => {
  if (!lastActive) return ActiveStatus.INACTIVE

  const hoursAgo = differenceInHours(new Date(), new Date(lastActive))

  return hoursAgo < 6
    ? ActiveStatus.ACTIVE
    : hoursAgo <= 120
    ? ActiveStatus.IDLE
    : ActiveStatus.INACTIVE
}
