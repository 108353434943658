import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import tw from 'twin.macro'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { useCorporate } from '../../../contexts/CorporateContext'
import { EventLocationType } from '../../../enums'
import { List } from '../../../layout/List'
import { db } from '../../../lib/firebase'
import Icon from '../../../styles/Icons'
import { Button, Card, Input } from '../../../styles/Layout'
import { clueConverter } from '../../../utility/formatClues'
import Dropdown from '../../Dropdown'
import { Section } from '../../Hvnt/FormSection'
import { Items } from './Items/HiddenLocationItems'

export const AddHiddenLocation = ({ onSubmit }) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext()
  const { eventBeingEdited, setCheckpoints, stopEditingEventLocation } = useCorporate()
  const { checkpoints, customTagOptions } = useCheckpoint()
  const [fetched, setFetched] = useState(false)
  const [locations, setLocations] = useState([])
  const customTag = watch('customTag')
  const haveLocations = !!locations?.length

  // Fetch checkpoints and clues
  const onGetCheckpoints = async () => {
    if (!customTag || !checkpoints?.regular) return // display message
    const locations = checkpoints.regular.filter((c) =>
      c.customTags?.some((tag) => tag.value === customTag.value)
    )

    await Promise.all(
      locations.map(async (location) => {
        const cluesRef = await db
          .collection(`${location.path}/clues`)
          .withConverter(clueConverter)
          .get()
        const clues = []
        cluesRef.docs.forEach((doc) => clues.push(doc.data()))
        location.clues = clues
        location.type = EventLocationType.HIDDEN
      })
    )

    setFetched(true)
    setCheckpoints(locations)
    setLocations(locations)
  }

  // Clear list
  const onClearList = () => {
    setFetched(false)
    setLocations([])
  }

  // Save checkpoints as hidden locations
  const onSaveLocations = () => {
    // Create new paths
    locations.forEach((l) => {
      l.customTag = customTag
      l.ref = db.doc(l.path)
      l.path = `corporate-events/${eventBeingEdited.id}/locations/${l.id}`
    })
    onSubmit(locations)
  }

  return (
    <>
      <Section
        field="customTag"
        label="Import custom checkpoints"
        error={errors.customTag}
        required
      >
        <Input.Label>Custom checkpoint tag</Input.Label>
        <Controller
          control={control}
          name="customTag"
          render={({ field: { onChange, ...field } }) => (
            <Dropdown.Select
              {...field}
              onChange={(v) => {
                onChange(v)
                setFetched(false)
              }}
              options={customTagOptions}
              placeholder="Select Custom Tag"
              tw="w-auto"
            />
          )}
          rules={{ required: 'Choose one' }}
        />
        <Input.Error>{errors.customTag?.message}</Input.Error>
        <ButtonContainer>
          <Button.Secondary
            ring
            size="sm"
            onClick={onGetCheckpoints}
            disabled={!customTag || fetched}
            tw="w-full sm:w-min"
          >
            <Icon.Download size="sm" mr="2" />
            Show Locations
          </Button.Secondary>
          {haveLocations && (
            <Button.Warning ring size="sm" onClick={onClearList} tw="w-full sm:w-min">
              <Icon.Trashcan size="sm" mr="2" /> Clear List
            </Button.Warning>
          )}
        </ButtonContainer>
      </Section>
      {haveLocations && (
        <ListSection>
          <InsetSection>
            <Label>{`${locations.length} Locations:`}</Label>
            <List.Header>
              <List.HeaderData colSpan={1} tw="text-center">
                Active
              </List.HeaderData>
              <List.HeaderData colSpan={5}>Name</List.HeaderData>
              <List.HeaderData colSpan={1}>Clues</List.HeaderData>
            </List.Header>
            <List.Container>
              <Items items={locations} />
            </List.Container>
          </InsetSection>
        </ListSection>
      )}
      <Footer>
        <Button.White size="sm" onClick={stopEditingEventLocation} ring tw="mr-auto">
          <Icon.Close size="sm" mr="2" />
          Cancel
        </Button.White>
        <Button.Submit size="sm" onClick={onSaveLocations} ring disabled={!locations.length}>
          <Icon.Check size="sm" mr="2" />
          Import Locations
        </Button.Submit>
      </Footer>
    </>
  )
}

const ListSection = tw.div`flex px-2 sm:px-4`
const Label = tw(Input.Label)`text-lg font-semibold text-gray-700`
const ButtonContainer = tw.div`flex w-full flex-wrap gap-2 mt-2`
const InsetSection = tw.div`flex flex-col p-4 h-auto w-full bg-gray-100 rounded-lg shadow-inner`
const Footer = tw(Card.Footer)`mt-auto z-30 gap-2 py-3 bg-terracotta-300`
