import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import tw from 'twin.macro'
import config from '../../../config'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'
import Icon from '../../../styles/Icons'
import { Button } from '../../../styles/Layout'
import { getCustomDistancesArr } from '../../../utility/formatHvnts'
import { RouteConfigInput } from './RouteConfigInput'

export const RouteConfig = () => {
  const { FIRST_EASY_PROPORTION, MAX_ANGLE, TARGET_DISTANCES } = config.hvntRoute
  const {
    formState: { errors },
    setValue
  } = useFormContext()
  const { setEditingRoute } = useHvntRoute()
  const numberOfCheckpoints = useWatch({ name: 'numberOfCheckpoints' })
  const { customRoute, distances, easyProportion, radius, saved } = useWatch({ name: 'area' })

  const customDistances = useMemo(
    () => getCustomDistancesArr({ easyProportion, numberOfCheckpoints }),
    [easyProportion, numberOfCheckpoints]
  )

  // Reset
  const resetDefaults = useCallback(() => {
    setValue('area.easyProportion', FIRST_EASY_PROPORTION, { shouldValidate: true })
    setValue('area.maxAngle', MAX_ANGLE, { shouldValidate: true })
    setValue('area.distances.default', TARGET_DISTANCES.default, { shouldValidate: true })
    setValue('area.distances.max', TARGET_DISTANCES.max, { shouldValidate: true })
    setValue('area.distances.min', TARGET_DISTANCES.min, { shouldValidate: true })
    setValue('area.distances.start', TARGET_DISTANCES.start, { shouldValidate: true })
    customDistances.forEach(({ distance, index }) =>
      setValue(`area.distances.${index}`, distance, { shouldValidate: true })
    )
  }, [customDistances, FIRST_EASY_PROPORTION, MAX_ANGLE, TARGET_DISTANCES, setValue])

  // Reset flag
  useEffect(() => {
    return () => {
      setEditingRoute(false)
    }
  }, [setEditingRoute])

  return (
    <Container>
      <Table>
        <tbody>
          <RouteConfigInput
            defaultValue={TARGET_DISTANCES.min}
            description={'Between checkpoints'}
            disabled={saved}
            error={errors.area?.distances?.min}
            label={'Min Distance'}
            name={'area.distances.min'}
            options={{
              rules: {
                validate: (v) => {
                  if (isNaN(v) || !Number.isInteger(Number(v))) return 'This must be an integer'
                  if (v <= 0) return "Can't be below 0"
                  if (v > distances.max) return "Can't be above max distance"
                }
              }
            }}
          />
          <RouteConfigInput
            defaultValue={TARGET_DISTANCES.max}
            description={'Between checkpoints'}
            disabled={saved}
            error={errors.area?.distances?.max}
            label={'Max Distance'}
            name={'area.distances.max'}
            options={{
              rules: {
                validate: (v) => {
                  if (isNaN(v) || !Number.isInteger(Number(v))) return 'This must be an integer'
                  if (v <= 0) return "Can't be below 0"
                  if (v < distances.min) return "Can't be below min distance"
                  if (v > radius * 2) return "Can't be above hvnt diameter"
                },
                valueAsNumber: true
              }
            }}
          />
          <RouteConfigInput
            defaultValue={TARGET_DISTANCES.start}
            description={
              <span>
                Max distance for <b>first</b> checkpoint
              </span>
            }
            disabled={saved}
            error={errors.area?.distances?.start}
            label={'Start Distance'}
            name={'area.distances.start'}
            options={{
              rules: {
                validate: (v) => {
                  if (v <= 50) return 'Must be above 50 m'
                  if (isNaN(v) || !Number.isInteger(Number(v))) return 'This must be an integer'
                  if (v < distances.min) return "Can't be below min distance"
                  if (v > distances.max) return "Can't be above max distance"
                }
              }
            }}
          />
          <RouteConfigInput
            defaultValue={MAX_ANGLE}
            description="Max angle between checkpoints"
            disabled={saved}
            error={errors.area?.maxAngle}
            label="Max Angle"
            name="area.maxAngle"
            options={{
              rules: {
                required: true,
                validate: (v) => {
                  if (isNaN(v) || !Number.isInteger(Number(v))) return 'This must be an integer'
                  if (v < 0) return "Can't be below 0 degrees"
                  if (v > 180) return "Can't be above 180 degrees"
                }
              }
            }}
          />
          <tr>
            <td colSpan={5} tw="w-full border-b-2 border-gray-300" />
          </tr>
          <RouteConfigInput
            defaultValue={FIRST_EASY_PROPORTION}
            description="Proportion of checkpoints with custom distance"
            disabled={saved}
            error={errors.area?.easyProportion}
            label="Custom Distances"
            name="area.easyProportion"
            options={{
              rules: {
                required: true,
                validate: (v) => {
                  if (isNaN(v)) return 'This must be a number'
                  if (v < 0) return "Can't be below 0"
                  if (v > 1) return "Can't be above 1"
                }
              }
            }}
          />

          {customDistances.map(({ distance, index }) => (
            <RouteConfigInput
              key={index}
              defaultValue={TARGET_DISTANCES[index] ?? TARGET_DISTANCES.default}
              description={`+- ${(distances[index] ?? distance) / 2} m`}
              disabled={saved}
              error={errors.area?.distances?.[index]}
              label={`Distance ${index}-${index + 1}`}
              name={`area.distances.${index}`}
              options={{
                rules: {
                  validate: (v) => {
                    if (isNaN(v) || !Number.isInteger(Number(v))) return 'This must be an integer'
                    if (v < distances.min) return "Can't be below min distance"
                    if (v > distances.max) return "Can't be above max distance"
                  }
                }
              }}
            />
          ))}

          <RouteConfigInput
            defaultValue={TARGET_DISTANCES.default}
            description={`+- ${distances?.default / 2} m, for non-custom distances`}
            disabled={saved}
            error={errors.area?.distances?.default}
            label="Default Distance"
            name="area.distances.default"
            options={{
              rules: {
                required: true,
                validate: (v) => {
                  if (!Number.isInteger(Number(v))) return 'This must be an integer'
                  if (v < distances.min) return "Can't be below min distance"
                  if (v > distances.max) return "Can't be above max distance"
                }
              }
            }}
          />

          <tr>
            <td colSpan={5} tw="text-right">
              <Button.White
                disabled={saved || !customRoute}
                ring
                role="submit"
                size="sm"
                onClick={resetDefaults}
              >
                <Icon.ChevronLeft size="sm" mr="2" />
                Reset All to Default
              </Button.White>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

const Container = tw.div`flex flex-col w-full bg-gray-200 rounded-md shadow-inner overflow-scroll`
const Table = tw.table`table-auto border-separate border-spacing[.5rem] w-full text-xs`
