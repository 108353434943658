import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { useSupport } from '../../contexts/SupportContext'
import { SupportScreens } from '../../enums'
import useBreakpoint from '../../hooks/useBreakpoint'
import Icon from '../../styles/Icons'
import { Button, PageLayout } from '../../styles/Layout'
import { ChatWindow } from '../Support/ChatWindow'
import { ConversationsList } from '../Support/ConversationsList'
import { Loader } from '../Loader/Loader'

const SupportPage = () => {
  const { activeConversation, fetchingConversation, loading, visitDeepRoute } = useSupport()
  const { belowTarget: belowXl } = useBreakpoint('lg')
  const { belowTarget: shrink } = useBreakpoint('sm')
  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [screen, setScreen] = useState()

  // Visit deep route
  useEffect(() => {
    const id = params.get('id')
    if (!id) return

    visitDeepRoute({ id })
  }, [location, params, visitDeepRoute])

  // Update routes through side effects
  useEffect(() => {
    if (!activeConversation) return navigate('', { replace: true })
  }, [activeConversation, navigate])

  // Scroll to bottom
  useEffect(() => {
    setScreen(() =>
      belowXl
        ? activeConversation
          ? SupportScreens.CHAT
          : SupportScreens.CONVERSATIONS
        : SupportScreens.ALL
    )
  }, [activeConversation, belowXl])

  return (
    <Page>
      <Loader loading={fetchingConversation} text="Fetching conversation" />
      <ListContainer show={screen !== SupportScreens.CHAT}>{<ConversationsList />}</ListContainer>
      <ChatContainer show={screen !== SupportScreens.CONVERSATIONS}>
        {activeConversation ? (
          <ChatWindow id={activeConversation.id} />
        ) : (
          <Empty>Select a conversation from the list</Empty>
        )}
      </ChatContainer>

      {belowXl && (
        <ButtonContainer shrink={shrink}>
          <ConversationsButton
            disabled={loading}
            onClick={() => setScreen(SupportScreens.CONVERSATIONS)}
          >
            <Icon.List size="lg" mr="2" />
            {!shrink && <ButtonText>{'Conversations'}</ButtonText>}
          </ConversationsButton>
          <ConversationsButton disabled={loading} onClick={() => setScreen(SupportScreens.CHAT)}>
            <Icon.Chat size="lg" mr="2" />
            {!shrink && <ButtonText>{'Chat Window'}</ButtonText>}
          </ConversationsButton>
        </ButtonContainer>
      )}
    </Page>
  )
}

export default SupportPage

const Page = tw(PageLayout)`flex-col lg:flex-row-reverse`
const ShrinkContainer = styled.div`
  ${tw`flex overflow-hidden h-full w-full transition-all duration-300`}
  ${({ show }) => (show ? tw`h-full` : tw`h-0`)}
`
const ListContainer = tw(ShrinkContainer)`flex-col lg:w-2/5 2xl:w-1/3`
const ChatContainer = tw(ShrinkContainer)`justify-center lg:w-3/5 2xl:w-2/3 bg-bismark-800`
const Empty = tw.div`flex w-full justify-center items-center bg-black shadow-inner text-lg text-gray italic rounded-md overflow-hidden
m-2`
const ConversationsButton = tw(Button.Gray)`flex px-3 m-0 w-full md:w-min`
const ButtonText = tw.div``
const ButtonContainer = styled.div`
  ${tw`flex gap-2 sticky bottom-0 p-2 pt-0 bg-bismark-800 justify-end`};
  & > ${ConversationsButton} {
    ${({ shrink }) => (shrink ? tw`py-1` : tw`py-2`)}
  }
  & > ${ConversationsButton} > ${ButtonText} {
    ${({ shrink }) => (shrink ? tw`text-sm` : tw`text-lg`)}
  }
`
