import React, { useCallback, useEffect, useMemo } from 'react'
import tw from 'twin.macro'
import { Button } from '../../styles/Layout'
import { format } from 'date-fns'
import RBAC from '../RBAC'
import Icon from '../../styles/Icons'
import { relativeDateUpToDays } from '../../utility/helperFunctions'
import config from '../../config'
import { useSeason } from '../../contexts/SeasonsContext'
import { Role, SeasonStatus } from '../../enums'
import { ATag } from '../../styles/Link'
import { Modal } from '../Modal'
import { Leaderboard } from './Leaderboard'
import { Item } from '../../layout/List'

export const SeasonItems = ({ items }) => {
  const { expandedItem, setExpandedItem } = useSeason()

  const onClickItem = useCallback(
    (season, opened) => {
      console.log({ season })
      setExpandedItem(opened ? season : undefined)
    },
    [setExpandedItem]
  )

  return useMemo(
    () =>
      items.map((item) => (
        <SeasonItem
          key={item.id}
          item={item}
          onClick={onClickItem}
          expanded={expandedItem?.id === item?.id}
        />
      )),
    [expandedItem, items, onClickItem]
  )
}

export const SeasonItem = ({ item, expanded, onClick }) => {
  const { setExpandedItem, startEditingSeason } = useSeason()

  // Collapse item on unmount
  useEffect(() => {
    return () => {
      setExpandedItem()
    }
  }, [setExpandedItem])

  const onClickItem = useCallback(
    (season) => {
      onClick(season, !expanded)
    },
    [expanded, onClick]
  )

  const onClickEdit = useCallback(
    (e, item) => {
      e.stopPropagation()
      e.preventDefault()
      startEditingSeason(item)
    },
    [startEditingSeason]
  )

  const RenderSummary = (item) => {
    const { countries, createdAt, errors, id, path, playableWorldWide, prizes, status, updatedAt } =
      item
    return (
      <Item.SummaryList>
        {!!errors?.length && (
          <Item.Row>
            <Item.Bold tw="text-red-600">
              {status == SeasonStatus.DRAFT || status == SeasonStatus.UNSET
                ? 'Missing:'
                : 'Errors:'}
            </Item.Bold>
            <Item.Text tw="italic">{errors.join(', ')}</Item.Text>
          </Item.Row>
        )}

        <Item.Row>
          <Item.Bold>{'Id:'}</Item.Bold>
          <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Status:'}</Item.Bold>
          <pre>{status.message}</pre>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Playable in:'}</Item.Bold>
          <Countries>
            {playableWorldWide ? (
              <Item.Label>World Wide</Item.Label>
            ) : (
              countries.map((c, i) => <CountryLabel key={i}>{c.name}</CountryLabel>)
            )}
          </Countries>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Prizes:'}</Item.Bold>

          {prizes?.length ? (
            <Item.Col>
              {prizes.map(({ name }, i) => (
                <Item.Text key={i}>{name ?? '-'}</Item.Text>
              ))}
            </Item.Col>
          ) : (
            <Item.Text>{'-'}</Item.Text>
          )}
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Created:'}</Item.Bold>
          <Item.Text>{createdAt ? format(createdAt, 'LLL do yyy, HH:mm') : '-'}</Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Updated:'}</Item.Bold>
          <Item.Text>{updatedAt ? format(updatedAt, 'LLL do yyy, HH:mm') : '-'}</Item.Text>
        </Item.Row>
      </Item.SummaryList>
    )
  }

  return useMemo(() => {
    const { dateRange, name, status } = item
    return (
      <Item.Container>
        <Item.Header onClick={() => onClickItem(item)} expanded={expanded}>
          <Item.HeaderData colSpan={1}>
            <Item.Center>{status?.icon('lg') ?? '-'}</Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={3}>{name}</Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.startDate ? relativeDateUpToDays(dateRange.startDate, 3) : '-'}
          </Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.endDate ? relativeDateUpToDays(dateRange.endDate, 1) : '-'}
          </Item.HeaderData>
        </Item.Header>

        <Item.Summary expanded={expanded}>
          <RBAC allow={[Role.CREATOR]}>
            <Item.ActionButtons>
              <Button.Secondary
                size="sm"
                onClick={(e) => onClickEdit(e, item)}
                data-testid="edit-button"
              >
                <Icon.Edit size="sm" mr="2" /> Edit Season
              </Button.Secondary>
              <Modal
                OpenButton={(props) => (
                  <Button.Primary size="sm" {...props}>
                    <Icon.Cake size="sm" mr="2" /> Leaderboard
                  </Button.Primary>
                )}
                body={() => <Leaderboard {...item} />}
                showSubmitButton={false}
                title="Leaderboard"
                tw="h-3/4"
              />
            </Item.ActionButtons>
          </RBAC>
          {RenderSummary(item)}
        </Item.Summary>
      </Item.Container>
    )
  }, [expanded, item, onClickEdit, onClickItem])
}

const CountryLabel = tw(Item.Label)`bg-gold-300`
const Countries = tw.div`flex flex-wrap gap-1`
