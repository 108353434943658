import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  add: 'ADD_INFO_SCREEN',
  edit: 'EDIT_INFO_SCREEN',
  remove: 'REMOVE_INFO_SCREEN',
  clear: 'CLEAR_INFO_SCREENS'
}

export const infoScreenReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.add: {
      const { infoScreen } = action
      return uniqBy([...state, infoScreen], 'id')
    }

    case actionTypes.edit: {
      const { infoScreen } = action
      return state.map((item) => (item.id === infoScreen.id ? infoScreen : item))
    }

    case actionTypes.remove: {
      const { infoScreen } = action
      return state.filter((item) => item.id !== infoScreen.id)
    }

    case actionTypes.clear: {
      return []
    }
    default:
      return state
  }
}
