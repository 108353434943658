import React from 'react'
import tw, { theme } from 'twin.macro'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Input, MoreButton } from '../../../styles/Layout'
import { useCorporate } from '../../../contexts/CorporateContext'
import { LocationList } from './LocationList'
import Icon from '../../../styles/Icons'
import { EventLocationType } from '../../../enums'
import RangeSlider from '../../RangeSlider'
import { validate } from '../../../utility/formValidation'

export const LocationForm = ({ locations, locked }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  const { addNewEventLocation, validateEvent } = useCorporate()

  return (
    <Container>
      <InsetSection>
        <Controller
          control={control}
          name="minDistance"
          rules={{
            validate: (date) => (validateEvent ? validate.event.duration(date) : null)
          }}
          render={({ field: { value, onChange } }) => (
            <RangeWrapper>
              <Input.Label>
                {'Min distance:'}&nbsp;<TextValue>{value} m</TextValue>
              </Input.Label>
              <RangeSlider
                disabled={locked}
                color="red"
                min={10}
                max={300}
                step={10}
                tickEvery={1}
                value={value}
                onChange={({ target }) => onChange(Number(target.value))}
              />
            </RangeWrapper>
          )}
        />
        <Input.Error>{errors.minDistance?.message}</Input.Error>
      </InsetSection>
      <InsetSection tw="flex-1">
        <ListContainer>
          <ListRow>
            <Label>
              {'Locations'}
              {locked && <Icon.Lock />}
            </Label>
            <MoreButton
              color={theme`colors.gray.200`}
              disabled={locked}
              content={
                <>
                  <Button.Secondary ring onClick={() => addNewEventLocation(EventLocationType.AR)}>
                    <Icon.Video mr="2" />
                    Add AR Location
                  </Button.Secondary>
                  <Button.White ring onClick={() => addNewEventLocation(EventLocationType.HIDDEN)}>
                    <Icon.Hidden mr="2" />
                    Add Hidden Locations
                  </Button.White>
                  <Button.Primary
                    ring
                    onClick={() => addNewEventLocation(EventLocationType.TRIVIA)}
                  >
                    <Icon.Puzzle mr="2" />
                    Add Trivia Location
                  </Button.Primary>
                  <Button.Primary ring onClick={() => addNewEventLocation(EventLocationType.LOGO)}>
                    <Icon.AtSymbol mr="2" />
                    Add Logo Location
                  </Button.Primary>
                </>
              }
              title="Location Type"
            />
          </ListRow>
          <LocationList locations={locations} />
        </ListContainer>
      </InsetSection>
    </Container>
  )
}
const Container = tw.div`relative flex flex-1 flex-col w-full overflow-y-scroll p-2 sm:py-4 sm:px-6 gap-4`
const RangeWrapper = tw.div`flex flex-col w-full sm:w-1/2`
const TextValue = tw.span`text-bismark-700`
const InsetSection = tw.div`flex justify-center w-full bg-gray-100 rounded-lg shadow-inner gap-2 p-4`
const ListContainer = tw.div`flex flex-col gap-2`
const ListRow = tw.div`relative flex justify-between `
const Label = tw(Input.Label)`text-lg font-semibold text-gray-700 gap-1`
