import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { Controller, useFormContext } from 'react-hook-form'
import { Section } from '../Hvnt/FormSection'
import { Button, Input } from '../../styles/Layout'
import { useCorporate } from '../../contexts/CorporateContext'
import { ImageModal } from '../Modal'
import Icon from '../../styles/Icons'
import RangeSlider from '../RangeSlider'
import DatePicker from '../DatePicker'
import { validate } from '../../utility/formValidation'

export const EventForm = ({ loading, locked, flagUpload }) => {
  const { validateEvent } = useCorporate()
  const {
    control,
    formState: { errors },
    register
  } = useFormContext()

  return (
    <Form>
      <Section label="Event name" field="name" error={errors.name} required>
        <Input.Label>Name</Input.Label>

        <Input.Default
          {...register('name', { validate: validateEvent ? validate.event.name : null })}
          placeholder="Event Name (max 30 characters)"
        />
        <Input.Error>{errors.name?.message}</Input.Error>
      </Section>

      <Section
        label="Add a title and description"
        field="description"
        error={errors.description}
        required
        tw="gap-2"
      >
        <div>
          <Input.Label>Title</Input.Label>
          <Input.Default
            {...register('title', { validate: validateEvent ? validate.event.title : null })}
            placeholder="Title (max 30 characters)"
          />
          <Input.Error>{errors.title?.message}</Input.Error>
        </div>
        <div>
          <Input.Label>Description</Input.Label>
          <Input.TextArea
            {...register('description', {
              validate: validateEvent ? validate.event.description : null
            })}
            placeholder="Description (max 300 characters)"
            rows={3}
          />
          <Input.Error>{errors.description?.message}</Input.Error>
        </div>
      </Section>

      <Section label="Upload an image" field="imageUrl" error={errors.imageUrl} required>
        <Controller
          control={control}
          name="imageUrl"
          rules={{ validate: validateEvent ? validate.image : null }}
          render={({ field: { value, onChange } }) => (
            <ImageModal
              aspect={{ x: 1, y: 1 }}
              OpenButton={(props) => (
                <ModalButtonContainer hide={value} {...props}>
                  {value && <Image src={value} />}
                  <Button.Primary tw="absolute bottom-0">
                    <Icon.Image mr="2" />
                    {value ? 'Change Image' : 'Select Image'}
                  </Button.Primary>
                </ModalButtonContainer>
              )}
              flagUpload={flagUpload}
              imageUrl={value}
              loading={loading}
              title="Event Image"
              setImageUrl={onChange}
              tw="h-4/5"
            />
          )}
        />
        <Input.Error>{errors.imageUrl?.message}</Input.Error>
      </Section>

      <Section label="When is the event?" field="dateRange" error={errors.dateRange} required>
        <Controller
          control={control}
          name="dateRange"
          rules={{ validate: validateEvent ? validate.dateRange : null }}
          render={({ field: { value, onChange }, fieldState }) => (
            <DatePicker
              error={fieldState.error}
              onChange={onChange}
              shouldValidate={validateEvent}
              startDisabled={locked}
              value={value}
            />
          )}
        />
        <Input.Error>{errors.dateRange?.message}</Input.Error>
      </Section>

      <Section
        label="How long is a round?"
        field="roundDuration"
        error={errors.roundDuration}
        locked={locked}
        required
      >
        <Controller
          control={control}
          name="roundDuration"
          rules={{ validate: validateEvent ? validate.event.duration : null }}
          render={({ field: { value, onChange } }) => (
            <DurationWrapper>
              <Input.Label>{`Duration: ${value} min`}</Input.Label>
              <RangeSlider
                disabled={locked}
                color="red"
                min={15}
                max={240}
                step={5}
                tickEvery={3}
                value={value}
                onChange={({ target }) => onChange(Number(target.value))}
              />
            </DurationWrapper>
          )}
        />

        <Input.Error>{errors.roundDuration?.message}</Input.Error>
      </Section>
    </Form>
  )
}
const Form = tw.form`relative flex flex-1 flex-col w-full overflow-scroll`
const DurationWrapper = tw.div`flex flex-col w-full sm:w-1/3`
const Image = tw.img`h-full maxHeight[24rem] shadow-md rounded-md`
const ModalButtonContainer = styled.div`
  ${tw`relative flex flex-col gap-2 min-height[40px]`}
  ${({ hide }) => css`
    ${hide && tw`cursor-pointer`};
    & > ${Button.Primary} {
      ${hide && tw`invisible`}
    }
    &:hover > ${Button.Primary} {
      ${hide && tw`visible w-full`}
    }
  `}
`
