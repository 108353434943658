import React, { useCallback, useEffect, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { Items } from './CandidateItem'
import { Button, Info, Card } from '../../../styles/Layout'
import Icon from '../../../styles/Icons'
import { gdb } from '../../../lib/firebase'
import { candidateConverter } from '../../../utility/formatCandidates'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { Alerts, Toast } from '../../../utility/alerts'
import useSort from '../../../hooks/useSort'
import { CheckpointSorts } from '../../../enums'
import { Action, List } from '../../../layout/List'
import Paginator from '../../Paginator'
import { collection, orderBy, query, where } from 'firebase/firestore'
import { usePagination } from '../../../hooks/firebase/usePaginateFirestore'

export const CandidateList = ({ cells }) => {
  const {
    activeCandidateCells,
    addCandidates,
    checkpoints: { candidates },
    clearCandidates,
    markCellsReviewed,
    startReviewingCandidates
  } = useCheckpoint()
  const { applySort, sortingBy, sortListBy } = useSort(CheckpointSorts.CREATED)
  const [limit, setLimit] = useState(20)

  const candidatesQuery = query(
    collection(gdb, 'checkpoint-candidates'),
    orderBy('geohash'),
    where('s2CellId', 'in', cells)
  ).withConverter(candidateConverter)

  const { items = [], ...pagination } = usePagination(candidatesQuery, { limit })

  // Sort and set candidates to state
  useEffect(() => {
    addCandidates(applySort(items))
  }, [addCandidates, applySort, items])

  const onMarkCellsReviewed = useCallback(
    async (ids) => {
      if (!ids.length) return
      try {
        await markCellsReviewed(ids)
        Toast.fire({ title: 'Cells marked as Reviewed!', icon: 'success' })
      } catch (err) {
        Alerts.CandidateCell.UPDATE_FAILED(err)
      }
    },
    [markCellsReviewed]
  )

  return useMemo(() => {
    return (
      <>
        <Action.Header>
          <Button.White size="sm" onClick={clearCandidates} ring>
            <Icon.Close size="sm" mr="2" /> Clear List
          </Button.White>
          <Button.Primary
            size="sm"
            onClick={() => onMarkCellsReviewed(activeCandidateCells)}
            ring
            disabled={candidates.length}
            tw="ml-auto"
          >
            <Icon.Check size="sm" mr="2" /> Mark Cell as Reviewed
          </Button.Primary>
        </Action.Header>

        <List.Header>
          <List.HeaderData
            colSpan={1}
            onClick={() => sortListBy(CheckpointSorts.LOCATION_SOURCE)}
            active={sortingBy.sort === CheckpointSorts.LOCATION_SOURCE}
            inc={sortingBy.inc}
            tw="text-center"
          >
            Source
          </List.HeaderData>
          <List.HeaderData
            colSpan={3}
            onClick={() => sortListBy(CheckpointSorts.NAME)}
            active={sortingBy.sort === CheckpointSorts.NAME}
            inc={sortingBy.inc}
          >
            Name
          </List.HeaderData>
          <List.HeaderData
            colSpan={3}
            onClick={() => sortListBy(CheckpointSorts.CREATED)}
            active={sortingBy.sort === CheckpointSorts.CREATED}
            inc={sortingBy.inc}
          >
            Created
          </List.HeaderData>
        </List.Header>

        <List.Container>
          {candidates?.length ? (
            <Items items={candidates} />
          ) : (
            <EmptyContainer>
              <Empty>{'Nothing here...'}</Empty>
              <Info>
                <strong>Before marking the cells as done!</strong>
                <div>
                  {
                    "Please make sure that there aren't any remaining candidates by updating the selector at the bottom"
                  }
                </div>
              </Info>
            </EmptyContainer>
          )}
        </List.Container>
        <StickyContainer>
          <Paginator.Firestore {...pagination} onChange={setLimit} />
        </StickyContainer>

        <Footer>
          <Button.Submit
            size="md"
            ring
            onClick={() => startReviewingCandidates(candidates)}
            disabled={!candidates.length}
          >
            <Icon.Clipboard mr="2" size="md" />
            {`Review ${candidates.length} Candidates`}
          </Button.Submit>
        </Footer>
      </>
    )
  }, [
    activeCandidateCells,
    candidates,
    clearCandidates,
    onMarkCellsReviewed,
    pagination,
    sortListBy,
    sortingBy,
    startReviewingCandidates
  ])
}

const EmptyContainer = tw.div`flex flex-col items-center gap-4 px-12`
const Empty = tw.div`grid justify-center pt-6 pb-4 italic text-sm text-gray`
const Footer = tw(Card.Footer)`flex-col`
const StickyContainer = tw.div`flex w-full sticky bottom-0 bg-white justify-end p-2 z-20`
