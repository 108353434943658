import React, { useMemo } from 'react'
import tw, { theme } from 'twin.macro'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { useClue } from '../../contexts/ClueContext'
import { Role } from '../../enums'
import { Action, List } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button, Card } from '../../styles/Layout'
import { Loader } from '../Loader/Loader'
import RBAC from '../RBAC'
import { ClueItems } from './ClueItem'
import { ReservedClueItems } from './ReservedClueItems'

export const ClueList = ({ loading, onActivateAll, onDeactivateAll, onGenerateClues }) => {
  const { checkpointBeingEdited, reviewing, submitDisabled } = useCheckpoint()
  const {
    adding,
    addNewClue,
    clueBeingEdited,
    clues: { regular: clues, candidates }
  } = useClue()

  const canGenerateClues = useMemo(
    () => !candidates?.length && checkpointBeingEdited?.centerPoint && !clueBeingEdited,
    [candidates, checkpointBeingEdited, clueBeingEdited]
  )

  return (
    <Card.Container
      header={`${
        adding ? 'Add Clue' : clueBeingEdited ? 'Edit Clue' : (clues?.length || '') + ' Saved Clues'
      }`}
      color={theme`colors.norway`}
      size="sm"
    >
      <Loader loading={loading} />
      <RBAC allow={[Role.CREATOR, Role.EDITOR]}>
        <Action.Header data-testid="header">
          <Action.Right>
            <Button.White
              size="sm"
              onClick={onGenerateClues}
              disabled={!canGenerateClues || loading || reviewing || submitDisabled}
              ring
            >
              <Icon.Sparkles mr="2" size="sm" />
              Generate Auto Clues
            </Button.White>

            <Button.Primary
              size="sm"
              ring
              onClick={() => addNewClue()}
              disabled={loading || submitDisabled}
            >
              <Icon.Plus mr="2" size="sm" />
              {reviewing ? 'Build New Clue' : 'Create New Clue'}
            </Button.Primary>

            <Action.More
              color={theme`colors.norway.300`}
              content={
                <>
                  <Button.Secondary
                    size="sm"
                    disabled={loading}
                    ring
                    onClick={() => onActivateAll(clues)}
                  >
                    <Icon.Sun mr="2" size="sm" />
                    Activate All Clues
                  </Button.Secondary>
                  <Button.Primary
                    size="sm"
                    disabled={loading}
                    ring
                    onClick={() => onDeactivateAll(clues)}
                  >
                    <Icon.Moon mr="2" size="sm" />
                    Deactivate All Clues
                  </Button.Primary>
                </>
              }
            />
          </Action.Right>
        </Action.Header>
      </RBAC>

      <StyledList>
        <ReservedClueItems />
        {clues?.length ? (
          <ClueItems items={clues} />
        ) : (
          <List.Empty>{'You have no clues...'}</List.Empty>
        )}
      </StyledList>
    </Card.Container>
  )
}

const StyledList = tw(List.Container)`gap-3 p-3`
