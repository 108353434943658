import React, { createContext, useContext, useMemo, useReducer, useState } from 'react'
import { actionTypes, checkpointListReducer } from '../reducers/checkpointListReducer'
import { useCheckpoint } from '../contexts/CheckpointContext'
import { useUpdateEffect } from './useUpdateEffect'

export const CheckpointListContext = createContext()

export const CheckpointListProvider = ({ children }) => {
  const { checkpoints, loading } = useCheckpoint()
  const [listItems, dispatch] = useReducer(checkpointListReducer, [])
  const [filteredItems, setFilteredItems] = useState([])

  // Sets which checkpoints to display as list items
  useUpdateEffect(() => {
    if (loading) return
    dispatch({ type: actionTypes.update, checkpoints: checkpoints.regular ?? [] })
  }, [checkpoints, loading])

  const defaultValues = useMemo(
    () => ({
      filteredItems,
      listItems,
      setFilteredItems
    }),
    [filteredItems, listItems]
  )
  return (
    <CheckpointListContext.Provider value={defaultValues}>
      {children}
    </CheckpointListContext.Provider>
  )
}

// Hook
export const useCheckpointList = () => {
  const context = useContext(CheckpointListContext)
  if (context === undefined)
    throw new Error(
      '`useCheckpointList` hook must be used within a `CheckpointListProvider` component'
    )
  return context
}
