import React, { useCallback, useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { Button, Status } from '../../styles/Layout'
import { useClue } from '../../contexts/ClueContext'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import Icon from '../../styles/Icons'
import Item from '../../styles/Clue/ClueItem.js'
import RBAC from '../RBAC'
import { Role } from '../../enums'

export const ClueItems = ({ items = [] }) => {
  const { displayImage, setFocusedClue } = useClue()

  const onClickItem = useCallback(
    (clue) => {
      console.log({ clue })
      if (clue.imageData || clue.imageUrl) displayImage(clue)
      setFocusedClue(clue)
    },
    [displayImage, setFocusedClue]
  )

  return useMemo(
    () => items.map((clue, i) => <ClueItem clue={clue} onClickItem={onClickItem} key={i} />),
    [items, onClickItem]
  )
}

export const ClueItem = ({ clue, onClickItem }) => {
  const { submitDisabled } = useCheckpoint()
  const { addNewClue, startEditingClue } = useClue()
  const hasError = useMemo(() => !!clue?.errors?.length, [clue])

  const onClickEdit = (e) => {
    e.stopPropagation()
    // Auto generated clues have a `ref` field
    if (clue.ref) {
      addNewClue(clue)
    } else {
      startEditingClue(clue)
    }
  }

  return (
    <Item.Container>
      <Item.Row tw="bg-norway-200">
        <Header clue={clue}>
          {<Status active={clue.active} />}
          {Item.setHeader(clue)}
        </Header>
        {hasError && <Icon.ErrorBubble size="xl" tw="absolute right-1" />}
      </Item.Row>
      <Item.ClueContent clue={clue} onClick={() => onClickItem(clue)}>
        <RBAC allow={[Role.CREATOR]}>
          <ButtonContainer>
            <Button.Gray
              size="sm"
              onClick={onClickEdit}
              disabled={submitDisabled}
              data-testid="edit-button"
            >
              <Icon.Edit size="sm" mr="2" /> Edit
            </Button.Gray>
          </ButtonContainer>
        </RBAC>
      </Item.ClueContent>
    </Item.Container>
  )
}

const Header = styled(Item.Header)`
  &::after {
    ${tw`absolute right-2`}
    ${(clue) => Item.setHeaderAttribute(clue)}
  }
`
const ButtonContainer = tw.div`m-1`
