import React from 'react'
import tw, { styled } from 'twin.macro'

export const FilterButton = ({
  addFilter,
  children,
  field,
  filter,
  filters,
  value = true,
  ...props
}) => (
  <Button
    active={filters?.[field]?.value == value}
    onClick={() => addFilter({ filter, field }, value)}
    {...props}
  >
    {children}
  </Button>
)

const Button = styled.div`
  ${tw`flex flex-1 p-0.5 bg-white hover:bg-gray-100 active:bg-gray-300 h-6
  cursor-pointer justify-center items-center rounded truncate shadow-sm gap-1.5`}
  ${({ active }) =>
    active
      ? tw`bg-orange-300 hover:bg-orange-400 active:bg-orange-300 shadow-inner`
      : tw`shadow-sm`}
  & > pre {
    ${tw`hidden sm:inline-block md:text-sm`}
  }
`
