import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import tw, { styled } from 'twin.macro'
import {
  CitySelectStyle,
  CitySelectTheme,
  CreatableStyle,
  CreatableTheme,
  PaginatorStyle,
  SelectStyle,
  SelectTheme
} from '../../styles/Select'

// prettier-ignore
const CitySelector = styled(ReactSelect).attrs(({ menuPlacement }) => ({
  'data-lpignore': true, // Ignore lastpass
  menuPlacement: menuPlacement || 'auto',
  menuShouldScrollIntoView: true,
  styles: CitySelectStyle,
  theme: CitySelectTheme
}))
`
  ${tw`font-sans font-light min-w-dropdown text-lg z-20`}
  width: -moz-available;
  width: -webkit-fill-available;
`

// prettier-ignore
const Creatable = styled(CreatableSelect).attrs(({ maxMenuHeight, menuPlacement }) => ({
  'data-lpignore': true, // Ignore lastpass
  maxMenuHeight: maxMenuHeight || 200,
  menuPlacement: menuPlacement || 'auto',
  menuShouldScrollIntoView: true,
  styles: CreatableStyle,
  theme: CreatableTheme
}))
`
  ${tw`font-sans font-light z-20`}
  width: -moz-available;
  width: -webkit-fill-available;
`

// prettier-ignore
const Select = styled(ReactSelect).attrs(({ maxMenuHeight, menuPlacement, size='md' }) => ({
  'data-lpignore': true, // Ignore lastpass
  maxMenuHeight: maxMenuHeight || 200,
  menuPlacement: menuPlacement || 'auto',
  menuShouldScrollIntoView: true,
  styles: SelectStyle,
  theme: t => SelectTheme({...t, size})
}))
`
  ${tw`font-sans font-light min-w-dropdown z-20`}
  ${({size}) => size === 'lg' ? tw`text-base` : size === 'sm' ? tw`text-xs` : tw`text-sm`}
  width: -moz-available;
  width: -webkit-fill-available;
`

// prettier-ignore
const Paginator = styled(ReactSelect).attrs(({ style }) => ({
  'data-lpignore': true, // Ignore lastpass
  isSearchable: false,
  maxMenuHeight: 200,
  menuPlacement: 'auto',
  menuShouldScrollIntoView: true,
  styles: style ?? PaginatorStyle,
  theme: SelectTheme
}))
`
  ${tw`font-sans font-light text-sm bg-orange`}
  width: -moz-available;
  width: -webkit-fill-available;
`

export default { CitySelector, Creatable, Paginator, Select }
