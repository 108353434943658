import React, { useEffect, useState } from 'react'
import { useChallenge } from '../../contexts/ChallengeContext'
import tw, { theme } from 'twin.macro'
import { Items } from './Items'
import { Button, Card } from '../../styles/Layout'
import { ChallengeFilter } from './ChallengeFilter'
import RBAC from '../RBAC'
import { Role } from '../../enums'
import useFilter from '../../hooks/useFilter'
import useSort from '../../hooks/useSort'
import { ChallengeSorts } from '../../enums'
import Icon from '../../styles/Icons'
import { Action, List } from '../../layout/List'

export const ChallengeList = () => {
  const { addNewChallenge, challengeBeingEdited, challenges = [] } = useChallenge()
  const { applyFilters, ...filterMethods } = useFilter()
  const { applySort, sortingBy = {}, sortListBy } = useSort(ChallengeSorts.TYPE)
  const [filteredItems, setFilteredItems] = useState(challenges)

  // Filter and sort items
  useEffect(() => {
    setFilteredItems(applySort(applyFilters(challenges)))
  }, [applyFilters, applySort, challenges])

  return (
    <Card.Container header={`${challenges.length} Challenges`} color={theme`colors.norway`}>
      <List.Filter>
        <ChallengeFilter filter={filterMethods} />
      </List.Filter>

      <Action.Header>
        <Action.Right>
          <RBAC allow={[Role.CREATOR]}>
            <Button.Primary
              ring
              size="sm"
              onClick={() => addNewChallenge()}
              disabled={challengeBeingEdited}
            >
              <Icon.Plus size="sm" mr="2" />
              {'Create New Challenge'}
            </Button.Primary>
          </RBAC>

          <Action.More />
        </Action.Right>
      </Action.Header>

      <List.Header>
        <List.HeaderData
          colSpan={1}
          active={sortingBy.sort === ChallengeSorts.ACTIVE}
          onClick={() => sortListBy(ChallengeSorts.ACTIVE)}
          inc={sortingBy.inc}
          tw="text-center"
        >
          Active
        </List.HeaderData>
        <List.HeaderData
          colSpan={1}
          active={sortingBy.sort === ChallengeSorts.TYPE}
          onClick={() => sortListBy(ChallengeSorts.TYPE)}
          inc={sortingBy.inc}
        >
          Type
        </List.HeaderData>
        <List.HeaderData
          colSpan={5}
          active={sortingBy.sort === ChallengeSorts.QUESTION}
          onClick={() => sortListBy(ChallengeSorts.QUESTION)}
          inc={sortingBy.inc}
        >
          Question
        </List.HeaderData>
      </List.Header>

      <List.Container>
        {filteredItems.length ? (
          <Items items={filteredItems} />
        ) : (
          <EmptyContainer>Nothing here...</EmptyContainer>
        )}
      </List.Container>

      <Card.Footer color={theme`colors.norway`} />
    </Card.Container>
  )
}

const EmptyContainer = tw.div`flex justify-center items-center p-4 text-sm text-gray-700`
