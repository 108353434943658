import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect
} from 'react'
import useGetCorporateEvents from '../hooks/firebase/useGetCorporateEvents'
import { EventFormTab } from '../enums'
import { db, FieldValue } from '../lib/firebase'
import { eventReducer } from '../reducers/eventReducer'
import { Alerts, Toast } from '../utility/alerts'
import { eventLocationConverter, eventsConverter } from '../utility/formatEvents'
import { useMap } from './MapContext'
import { ChallengeProvider } from './ChallengeContext'
import { CheckpointProvider } from './CheckpointContext'

export const CorporateContext = createContext()

export const CorporateProvider = ({ children }) => {
  const [events, dispatch] = useReducer(eventReducer, [])
  const { onNextEvents } = useGetCorporateEvents(dispatch)
  const { clearFocus } = useMap()

  const [activeFormTab, setActiveFormTab] = useState(EventFormTab.DETAILS)
  const [adding, setAdding] = useState(false)
  const [checkpoints, setCheckpoints] = useState([])
  const [locationBeingEdited, setLocationBeingEdited] = useState(false)
  const [editing, setEditing] = useState(false)
  const [expandedItem, setExpandedItem] = useState()
  const [expandedLocationItem, setExpandedLocationItem] = useState()
  const [loading, setLoading] = useState(false)
  const [eventBeingEdited, setEventBeingEdited] = useState()
  const [validateEvent, setValidateEvent] = useState(false)

  // Add new event
  const addNewEvent = useCallback((initial) => {
    // Create new document
    const newDoc = db.collection('corporate-events').doc()
    setEventBeingEdited({
      active: false,
      entryCode: Math.random().toString(16).slice(2, 8).toUpperCase(),
      id: newDoc.id,
      path: newDoc.path,
      ...initial
    })
    setAdding(true)
  }, [])

  // Start editing event
  const startEditingEvent = useCallback((event) => {
    setEventBeingEdited(event)
    setAdding(false)
    setEditing(true)
    setValidateEvent(event.status?.validate ?? event.active ?? true)
  }, [])

  // Stop editing event
  const stopEditingEvent = useCallback(() => {
    clearFocus()
    setEventBeingEdited()
    resetDefaults()
  }, [clearFocus])

  // Save event to firestore
  const saveEvent = useCallback(
    async (event) => {
      try {
        const { path } = event
        if (!path) throw new Error('Missing path')
        setLoading(true)

        const eventRef = db.doc(path)

        await eventRef.withConverter(eventsConverter).set(
          {
            ...event,
            ...(adding && { createdAt: FieldValue.serverTimestamp() })
          },
          { merge: true }
        )

        Alerts.Event.SAVE_SUCCESS(adding)

        stopEditingEvent()
      } catch (err) {
        console.error(err)
        Alerts.Event.SAVE_FAILED(adding, err)
      } finally {
        setLoading(false)
      }
    },
    [adding, stopEditingEvent]
  )

  // Delete event
  const deleteEvent = useCallback(
    async (event) => {
      try {
        setLoading(true)
        const { path } = event
        db.doc(path).delete()
        Alerts.Event.DELETE_SUCCESS()
      } catch (err) {
        Alerts.Event.SAVE_FAILED(err)
        return console.error(err)
      } finally {
        setLoading(false)
      }
      stopEditingEvent()
    },
    [stopEditingEvent]
  )

  // Add new event location
  const addNewEventLocation = useCallback(
    (type) => {
      if (!eventBeingEdited) return
      // Create new document
      const newDoc = db.collection(`corporate-events/${eventBeingEdited.id}/locations`).doc()
      setLocationBeingEdited({
        active: true,
        id: newDoc.id,
        path: newDoc.path,
        type
      })
    },
    [eventBeingEdited]
  )

  // Start editing event location
  const startEditingEventLocation = useCallback(async (location) => {
    // Get location from path
    // This is necessary to handle map data that is converted to geojson
    const doc = await db.doc(location.path).withConverter(eventLocationConverter).get()
    setLocationBeingEdited(() => ({ id: doc.id, path: doc.ref.path, ...doc.data() }))
  }, [])

  // Stop editing event location
  const stopEditingEventLocation = useCallback(() => {
    setCheckpoints([])
    setExpandedLocationItem()
    setLocationBeingEdited()
  }, [])

  // Save event location
  const saveEventLocation = useCallback(
    async (location) => {
      try {
        const { path } = location
        if (!path) throw new Error('Missing path')
        setLoading(true)

        const locationRef = db.doc(path)

        await locationRef.withConverter(eventLocationConverter).set(location, { merge: true })

        Alerts.Location.SAVE_SUCCESS(adding)

        stopEditingEventLocation()
      } catch (err) {
        console.error(err)
        Alerts.Location.SAVE_FAILED(adding, err)
      } finally {
        setLoading(false)
      }
    },
    [adding, stopEditingEventLocation]
  )

  // Delete or deactivate event
  const deleteEventLocation = useCallback(
    async (location) => {
      if (!eventBeingEdited) return
      const { value: ok } = await Alerts.Location.CONFIRM_DELETE()
      if (!ok) return

      try {
        setLoading(true)
        const { path } = location
        if (validateEvent && eventBeingEdited.status?.locked) {
          await Alerts.Location.DELETE_NOT_POSSIBLE()
          db.doc(path).set({ active: false }, { merge: true })

          Alerts.Location.SAVE_SUCCESS()
        } else {
          db.doc(path).delete()
          Toast.fire({ title: 'Location Removed!', icon: 'success' })
        }
      } catch (err) {
        Alerts.Location.SAVE_FAILED(err)
        return console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [eventBeingEdited, validateEvent]
  )

  const resetDefaults = () => {
    setActiveFormTab(EventFormTab.DETAILS)
    setCheckpoints([])
    setExpandedLocationItem()
    setLocationBeingEdited()
    setAdding(false)
    setEditing(false)
    setLoading(false)
    setValidateEvent(false)
  }

  // Reset values
  useEffect(() => {
    if (!eventBeingEdited) resetDefaults()
  }, [eventBeingEdited])

  // Subscribe to events
  useEffect(() => {
    const unsubscribe = db
      .collection('corporate-events')
      .withConverter(eventsConverter)
      .onSnapshot(onNextEvents)
    return unsubscribe
  }, [onNextEvents])

  const defaultValues = useMemo(
    () => ({
      activeFormTab,
      adding,
      addNewEvent,
      addNewEventLocation,
      checkpoints,
      editing,
      events,
      expandedLocationItem,
      deleteEvent,
      deleteEventLocation,
      expandedItem,
      loading,
      locationBeingEdited,
      saveEvent,
      saveEventLocation,
      eventBeingEdited,
      setActiveFormTab,
      setCheckpoints,
      setExpandedItem,
      setExpandedLocationItem,
      setLoading,
      setLocationBeingEdited,
      setValidateEvent,
      startEditingEvent,
      startEditingEventLocation,
      stopEditingEvent,
      stopEditingEventLocation,
      validateEvent
    }),
    [
      activeFormTab,
      adding,
      addNewEvent,
      addNewEventLocation,
      checkpoints,
      editing,
      events,
      expandedLocationItem,
      deleteEvent,
      deleteEventLocation,
      expandedItem,
      loading,
      locationBeingEdited,
      saveEvent,
      saveEventLocation,
      eventBeingEdited,
      startEditingEvent,
      startEditingEventLocation,
      stopEditingEvent,
      stopEditingEventLocation,
      validateEvent
    ]
  )

  return (
    <CheckpointProvider>
      <ChallengeProvider>
        <CorporateContext.Provider value={defaultValues}>{children}</CorporateContext.Provider>
      </ChallengeProvider>
    </CheckpointProvider>
  )
}

// Hook
export const useCorporate = () => {
  const context = useContext(CorporateContext)
  if (context === undefined)
    throw new Error('`useCorporate` hook must be used within a `CorporateProvider` component')
  return context
}
