import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  add: 'ADD_COUNTRY',
  clear: 'CLEAR_COUNTRIES',
  edit: 'EDIT_COUNTRY',
  remove: 'REMOVE_COUNTRY',
  set: 'SET_COUNTRYS'
}

export const countryReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.add: {
      const { country } = action
      return uniqBy([...state, country], 'id')
    }
    case actionTypes.clear: {
      return []
    }
    case actionTypes.edit: {
      const { country } = action
      return state.map((item) => (item.id === country.id ? { ...item, ...country } : item))
    }
    case actionTypes.remove: {
      const { country } = action
      return state.filter((item) => item.id !== country.id)
    }
    case actionTypes.set: {
      const { countries } = action
      return uniqBy([...state, ...countries], 'id')
    }
    default:
      return state
  }
}
