import React from 'react'
import { CheckpointListProvider } from '../hooks/useCheckpointList'
import { CheckpointProvider } from './CheckpointContext'
import { ClueProvider } from './ClueContext'
import { HvntProvider } from './HvntContext'
import { HvntListProvider } from '../hooks/useHvntList'
import { CheckpointMapProvider } from './CheckpointMapContext'
import { MapProvider } from './MapContext'

export const CombinedHvntProvider = ({ children }) => (
  <MapProvider>
    <CheckpointProvider>
      <HvntProvider>
        <HvntListProvider>{children}</HvntListProvider>
      </HvntProvider>
    </CheckpointProvider>
  </MapProvider>
)

export const CombinedCheckpointProvider = ({ children }) => (
  <MapProvider>
    <CheckpointProvider>
      <CheckpointListProvider>
        <ClueProvider>
          <CheckpointMapProvider>{children}</CheckpointMapProvider>
        </ClueProvider>
      </CheckpointListProvider>
    </CheckpointProvider>
  </MapProvider>
)
