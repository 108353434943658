export const conversationsConverter = {
  fromFirestore: (snapshot) => {
    const {
      createdAt,
      deviceInfo,
      messages,
      notificationStatus,
      participationRef,
      resolved,
      resolvedAt,
      resolvedBy,
      user: { displayName, email, userRef, uid }
    } = snapshot.data()
    return {
      createdAt: createdAt.toDate(),
      deviceInfo,
      id: snapshot.id,
      messages,
      notificationStatus,
      ...(participationRef && { participation: participationRef?.path || '' }),
      resolved,
      ...(resolvedAt && { resolvedAt: resolvedAt.toDate() }),
      ...(resolvedBy && { resolvedBy: resolvedBy.id }),
      user: {
        ...(displayName && { displayName }),
        ...(email && { email }),
        ...(uid && { uid }),
        path: userRef?.path ?? '' // todo this will change to `ref`
      }
    }
  }
}
