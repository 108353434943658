import React, { useState, useMemo, useCallback, useEffect } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Marker } from 'react-map-gl'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { Status } from '../../../styles/Layout'

const MARKER_SIZE = 30

export const CheckpointMarkers = () => {
  const { checkpointBeingEdited } = useCheckpoint()
  const visible = useMemo(() => !!checkpointBeingEdited?.centerPoint, [checkpointBeingEdited])

  return useMemo(
    () => <>{visible && <CheckpointMarker checkpoint={checkpointBeingEdited} editing={true} />}</>,
    [checkpointBeingEdited, visible]
  )
}

export const CheckpointMarker = ({ checkpoint, editing }) => {
  const { updateCheckpoint } = useCheckpoint()
  const [startPosition, _] = useState({ ...checkpoint?.centerPoint })

  const [position, setPosition] = useState(startPosition)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    setPosition(checkpoint?.centerPoint)
  }, [checkpoint?.centerPoint])

  const onDragEnd = useCallback(
    async (event) => {
      const currentPosition = { latitude: event.lngLat.lat, longitude: event.lngLat.lng }
      setPosition(currentPosition)
      updateCheckpoint({ centerPoint: currentPosition })
    },
    [updateCheckpoint]
  )

  return useMemo(() => {
    const { active, centerPoint, name = 'New Checkpoint' } = checkpoint
    return (
      <Marker
        key={name}
        longitude={position?.longitude || centerPoint.longitude}
        latitude={position?.latitude || centerPoint.latitude}
        draggable={true}
        onDragEnd={onDragEnd}
        offset={[0, -MARKER_SIZE / 2]}
      >
        <Pin size={MARKER_SIZE} />
        <Popup
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          size={MARKER_SIZE}
        >
          <NamePlate hover={hover}>
            {editing ? (
              <div tw="flex flex-col">
                <DragToMove>Drag to Move</DragToMove>
                <Name tw="mt-2">
                  <strong>{name}</strong>
                </Name>
              </div>
            ) : (
              <>
                <Status active={active} tw="mr-2" />
                <Name>{name}</Name>
              </>
            )}
          </NamePlate>
        </Popup>
      </Marker>
    )
  }, [editing, checkpoint, hover, onDragEnd, position])
}

//prettier-ignore
const Popup = styled.div.attrs(({ size }) => ({ size }))
` ${tw`absolute bottom-0 font-sans text-base`}
  ${({ size }) =>css`transform: translate(${size}px, ${-size}px);`}
  `
const Name = tw.span`font-light whitespace-nowrap`
const DragToMove = tw.div`absolute -top-3 left-0 py-1 rounded-t-md w-full minWidth[90px] bg-bismark-600 text-xs`
const NamePlate = styled.div`
  ${tw`flex py-2 px-3 rounded-lg rounded-bl-none shadow-lg justify-between items-center 
text-center cursor-pointer bg-bismark text-white-pure`}
  &:hover {
    ${tw`bg-bismark-600`}
  }
  &:hover > * > ${DragToMove} {
    ${tw`bg-bismark-700`}
  }
`

const Pin = ({ editing, size, onClickMarker }) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={editing ? theme`colors.gold` : theme`colors.green`}
    stroke={editing ? theme`colors.gold.600` : theme`colors.green.600`}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
    onClick={onClickMarker}
  >
    <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
  </svg>
)

const Path = tw.path`hover:cursor-pointer`
