import uniq from 'lodash/uniq'

export const ChallengeType = Object.freeze({
  TRIVIA: 'TRIVIA'
})

export const TriviaType = Object.freeze({
  ONE_CORRECT: 'ONE_CORRECT',
  MANY_CORRECT: 'MANY_CORRECT',
  INPUT: 'INPUT'
})

export const ChallengeFilters = Object.freeze({
  CUSTOM: {
    field: 'custom',
    filter: (arr) => arr.filter((item) => item.custom)
  },
  QUERY: {
    field: 'query',
    filter: (arr, value) => {
      const filteredIds = arr.filter((item) => item.id?.toLowerCase().indexOf(value) !== -1)
      const filteredQuestions = arr.filter(
        (item) => item.question && item.question?.toLowerCase().indexOf(value) !== -1
      )
      const filteredTags = arr.filter((item) =>
        item.tags?.some((tag) => tag.label?.indexOf(value) !== -1)
      )
      const filteredTypes = arr.filter((item) => item.type.toLowerCase().indexOf(value) !== -1)

      return uniq(filteredQuestions.concat(filteredIds).concat(filteredTags).concat(filteredTypes))
    }
  },
  TAGS: {
    field: 'tags',
    filter: (arr, tags) =>
      arr.filter((item) => item.tags?.some((tag) => tags?.some((ref) => ref.value === tag.value)))
  },
  TRIVIA: {
    field: 'trivia',
    filter: (arr) => arr.filter((item) => item.type === ChallengeType.TRIVIA)
  }
})

export const ChallengeSorts = Object.freeze({
  ACTIVE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => !!b.active - !!a.active)
      : arr?.sort((a, b) => !!a.active - !!b.active),
  CUSTOM: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => !!b.custom - !!a.custom)
      : arr?.sort((a, b) => !!a.custom - !!b.custom),
  QUESTION: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.question?.localeCompare(b.question))
      : arr?.sort((a, b) => b.question?.localeCompare(a.question)),
  TYPE: (arr, inc) =>
    inc ? arr?.sort((a, b) => a.type - b.type) : arr?.sort((a, b) => b.type - a.type)
})
