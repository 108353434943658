import React, { useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { useAreas } from '../../contexts/AreasContext'
import Dropdown from '../Dropdown'
import { ProfileIcon } from '../Account/ProfileIcon'
import logo from '../../assets/images/trees.png'
import { Link } from 'react-router-dom'
import useBreakpoint from '../../hooks/useBreakpoint'
import { useUser } from '../../contexts/UserContext'

export const Topbar = () => {
  const {
    activeCity,
    activeCountry,
    countries,
    lockCity,
    removeActiveCity,
    removeActiveCountry,
    setActiveCity,
    setActiveCountry
  } = useAreas()
  const { user } = useUser()

  const areaOptions = useMemo(
    () =>
      countries
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((country) => ({
          options: [
            { active: country.active, label: country.name, value: country.id, country },
            ...country.cities.map((city) => ({
              label: city.name,
              value: `${country.id}/${city.name}`,
              active: city.active,
              city,
              country
            }))
          ]
        })),
    [countries]
  )

  const selectedArea = useMemo(() => {
    if (!activeCountry) return ''
    if (activeCity) {
      const { active, name, path } = activeCity
      return { active, city: true, label: name, value: path }
    } else {
      const { active, name, id } = activeCountry
      return { active, city: false, label: name, value: id }
    }
  }, [activeCity, activeCountry])

  const name = user?.displayName?.split(' ')[0]
  const { belowTarget } = useBreakpoint('md')

  const onSelectArea = useCallback(
    (area) => {
      if (!area) return removeActiveCountry()
      const { city, country } = area
      setActiveCountry(country)

      if (city) setActiveCity(city)
      else removeActiveCity()
    },
    [removeActiveCity, removeActiveCountry, setActiveCity, setActiveCountry]
  )

  return (
    !!user && (
      <Container>
        <Logo as={Link} to="/">
          <img src={logo} />
        </Logo>

        <FlexSection>
          <CitySelect
            placeholder="Select Area"
            value={selectedArea}
            options={areaOptions}
            isClearable={true}
            isDisabled={lockCity}
            isSearchable={false}
            onChange={onSelectArea}
            tabIndex={-1}
          />
        </FlexSection>

        <GreetingsContainer>
          {!belowTarget && <ProfileName> Welcome, {user.displayName ? name : 'User'} </ProfileName>}
          <ProfileIcon />
        </GreetingsContainer>
      </Container>
    )
  )
}

const Container = tw.div`absolute top-0 left-0 right-0 h-12 flex pl-1 font-light justify-start sm:justify-center items-center bg-bismark-800 shadow-sm zIndex[999]`
const FlexSection = tw.div`flex flex-1 justify-center h-full items-center`
const CitySelect = tw(Dropdown.CitySelector)`w-48 sm:w-64`
const GreetingsContainer = tw.div`flex items-center gap-4 text-gray-300 p-4`
const ProfileName = tw.div`text-base select-none antialiased`
const Logo = tw.div`flex py-1 max-h-12 aspect-ratio[1]`
