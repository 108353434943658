import { useEffect, useMemo, useState } from 'react'
import { useWindowSize } from 'react-use'
import { theme } from 'twin.macro'

const useBreakpoint = (target) => {
  const { width } = useWindowSize()
  const [belowTarget, setBelowTarget] = useState(false)
  const screens = useMemo(() => theme`screens`, [])

  // Return min width of specified tw breakpoint
  const minWidth = useMemo(
    () => (target ? Number(screens[target].split('px')[0]) : null),
    [screens, target]
  )
  // Returns current screen
  const currentScreen = useMemo(() => {
    let current = null
    for (let key of Object.keys(screens)) {
      const minScreen = Number(screens[key].split('px')[0])
      if (width < minScreen) break
      else current = key
    }
    return current
  }, [screens, width])

  useEffect(() => {
    setBelowTarget(width < minWidth)
  }, [minWidth, screens, width])

  return { belowTarget, currentScreen }
}

export default useBreakpoint
