import React, { useCallback, useEffect, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { PageLayoutCol } from '../../styles/Layout'
import { CompleteSignup } from '../Auth/CompleteSignup'
import * as alert from 'sweetalert2'
import { auth } from '../../lib/firebase'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { useIsMounted } from '../../hooks/useIsMounted'

const CompleteSignupPage = () => {
  const { user, signout } = useUser()
  const navigate = useNavigate()
  const search = useLocation().search
  const actionCode = useMemo(() => new URLSearchParams(search).get('oobCode'), [search])
  const [codeChecked, setCodeChecked] = useState(false)
  const { isMounted } = useIsMounted()

  // Check that the link is valid
  const checkActionCode = useCallback(async () => {
    try {
      await auth.checkActionCode(actionCode)
    } catch (err) {
      await alert.fire({
        title: 'Invalid Link',
        text: 'Code has either expired, been used already or is malformed',
        icon: 'error'
      })
      user ? navigate(-1) : navigate('/login')
      return setCodeChecked(false)
    }
    // Check if current user needs to be logged out
    if (user) {
      const { value: ok } = await alert.fire({
        title: 'User Logged In',
        text: 'We need to sign out from this account in order to activate a new account',
        icon: 'warning',
        confirmButtonText: 'Sure, sign me out!',
        showCancelButton: true,
        cancelButtonText: 'Wait, not yet'
      })
      if (ok) {
        return await signout()
      } else {
        return navigate(-1)
      }
    }
    setCodeChecked(true)
  }, [actionCode, navigate, signout, user])

  useEffect(() => {
    if (!isMounted() || codeChecked) return
    if (!actionCode) return navigate('/')

    checkActionCode()
  }, [actionCode, checkActionCode, codeChecked, isMounted, navigate])

  return (
    codeChecked && (
      <Page>
        <CompleteSignup />
      </Page>
    )
  )
}

export default CompleteSignupPage

const Page = tw(PageLayoutCol)`items-center`
