import React, { useCallback } from 'react'
import { useCorporate } from '../../../../contexts/CorporateContext'
import { Item } from '../../../../layout/List'
import { Status } from '../../../../styles/Layout'
import { CheckpointTag } from '../../../../styles/Link'
import { CheckpointSummary } from '../../../Checkpoint/CheckpointSummary'

export const Items = ({ items }) => {
  const { expandedLocationItem, setExpandedLocationItem } = useCorporate()

  const onClickItem = useCallback(
    (location, opened) => {
      console.log({ location })
      opened ? setExpandedLocationItem(location) : setExpandedLocationItem()
    },
    [setExpandedLocationItem]
  )

  return items?.map((item, i) => (
    <LocationItem
      key={i}
      item={item}
      expanded={item?.id === expandedLocationItem?.id}
      onClick={onClickItem}
    />
  ))
}

const LocationItem = ({ expanded, item, onClick }) => (
  <Item.Container>
    <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
      <Item.HeaderData colSpan={1}>
        <Item.Center>
          <Status active={item.active} />
        </Item.Center>
      </Item.HeaderData>
      <Item.HeaderData colSpan={5}>{item.name}</Item.HeaderData>
      <Item.HeaderData colSpan={1}>{item.clues?.length}</Item.HeaderData>
    </Item.Header>

    <Item.Summary expanded={expanded}>
      <CheckpointSummary item={item} clues={item.clues ?? []}>
        <Item.Row>
          <Item.Bold>{'Checkpoint:'}</Item.Bold>
          <CheckpointTag to={`/checkpoints?id=${item.id}&edit=true`}>{item.id}</CheckpointTag>
        </Item.Row>
      </CheckpointSummary>
    </Item.Summary>
  </Item.Container>
)
