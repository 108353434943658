import React from 'react'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import Dashboard from './components/Pages/Dashboard'
import LoginPage from './components/Pages/LoginPage'
import CompleteSignupPage from './components/Pages/CompleteSignupPage'
import AreasPage from './components/Pages/AreasPage'
import ChallengePage from './components/Pages/ChallengesPage'
import CheckpointsPage from './components/Pages/CheckpointsPage'
import ForgotPasswordPage from './components/Pages/ForgotPasswordPage'
import SeasonsPage from './components/Pages/SeasonsPage'
import HvntsPage from './components/Pages/HvntsPage'
import PlayersPage from './components/Pages/PlayersPage'
import NotFoundPage from './components/Pages/NotFoundPage'
import PrizesPage from './components/Pages/PrizesPage'
import ResetPasswordPage from './components/Pages/ResetPasswordPage'
import { Sidebar } from './components/Navbar/Sidebar'
import { Topbar } from './components/Navbar/Topbar'
import { PrizeProvider } from './contexts/PrizeContext'
import { CombinedCheckpointProvider, CombinedHvntProvider } from './contexts/CombinedProviders'
import { UserProvider } from './contexts/UserContext'
import { AreasProvider } from './contexts/AreasContext'
import { MapProvider } from './contexts/MapContext'
import LocationsPage from './components/Pages/LocationsPage'
import { LocationsProvider } from './contexts/LocationsContext'
import SupportPage from './components/Pages/SupportPage'
import { SupportProvider } from './contexts/SupportContext'
import RBAC from './components/RBAC'
import NotAuthorizedPage from './components/Pages/NotAuthorizedPage'
import { SeasonsProvider } from './contexts/SeasonsContext'
import { Role } from './enums'
import { PlayerProvider } from './contexts/PlayerContext'
import { CorporateProvider } from './contexts/CorporateContext'
import CorporateEventsPage from './components/Pages/CorporateEventsPage'
import { ChallengeProvider } from './contexts/ChallengeContext'
import AccountPage from './components/Pages/AccountPage'
import InfoScreenPage from './components/Pages/InfoScreensPage'
import { InfoScreenProvider } from './contexts/InfoScreenContext'

const App = () => {
  return (
    <UserProvider>
      <AreasProvider>
        <Router>
          <Topbar />
          <Sidebar />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR, Role.VISITOR]} redirect="/forbidden">
                  <Dashboard />
                </RBAC>
              }
            />
            <Route exact path="/login" element={<LoginPage />} />
            <Route path="/complete-signup:email?" element={<CompleteSignupPage />} />
            <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route exact path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/seasons/*"
              element={
                <RBAC allow={[Role.CREATOR]} redirect="/forbidden">
                  <SeasonsProvider>
                    <SeasonsPage />
                  </SeasonsProvider>
                </RBAC>
              }
            />
            <Route
              path="/hvnts/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <CombinedHvntProvider>
                    <HvntsPage />
                  </CombinedHvntProvider>
                </RBAC>
              }
            />
            <Route
              path="/checkpoints/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <CombinedCheckpointProvider>
                    <CheckpointsPage />
                  </CombinedCheckpointProvider>
                </RBAC>
              }
            />
            <Route
              path="/playable-areas"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <AreasPage />
                </RBAC>
              }
            />
            <Route
              path="/support/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <SupportProvider>
                    <SupportPage />
                  </SupportProvider>
                </RBAC>
              }
            />
            <Route
              path="/locations"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <MapProvider>
                    <LocationsProvider>
                      <LocationsPage />
                    </LocationsProvider>
                  </MapProvider>
                </RBAC>
              }
            />
            <Route
              path="/challenges/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <ChallengeProvider>
                    <ChallengePage />
                  </ChallengeProvider>
                </RBAC>
              }
            />
            <Route
              path="/prizes/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <PrizeProvider>
                    <PrizesPage />
                  </PrizeProvider>
                </RBAC>
              }
            />
            <Route
              path="/users/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <PlayerProvider>
                    <PlayersPage />
                  </PlayerProvider>
                </RBAC>
              }
            />
            <Route
              path="/corporate-events/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <MapProvider>
                    <CorporateProvider>
                      <CorporateEventsPage />
                    </CorporateProvider>
                  </MapProvider>
                </RBAC>
              }
            />
            <Route
              path="/user-account/*"
              element={
                <RBAC allow={[Role.VISITOR]} redirect="/forbidden">
                  <AccountPage />
                </RBAC>
              }
            />
            <Route
              path="/info-screens/*"
              element={
                <RBAC allow={[Role.CREATOR, Role.EDITOR]} redirect="/forbidden">
                  <InfoScreenProvider>
                    <InfoScreenPage />
                  </InfoScreenProvider>
                </RBAC>
              }
            />

            <Route path="/forbidden" element={<NotAuthorizedPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </AreasProvider>
    </UserProvider>
  )
}

export default App
