import React from 'react'
import tw from 'twin.macro'
import config from '../../config'
import { ClueType } from '../../enums'
import { Item } from '../../layout/List'
import { ATag } from '../../styles/Link'

export const CheckpointSummary = ({ item, children, clues }) => (
  <Item.SummaryList>
    {!!item.errors?.length && (
      <Item.Row>
        <Item.Bold tw="text-red-600">{'Errors:'}</Item.Bold>
        <Item.Text tw="italic">{item.errors.join(', ')}</Item.Text>
      </Item.Row>
    )}

    <Item.Row>
      <Item.Bold>{'Id:'}</Item.Bold>
      <ATag href={`${config.firebase.FIRESTORE_URL}/${item.path}`}>{item.id}</ATag>
    </Item.Row>

    {children}

    <Item.Row>
      <Item.Bold>{'Name:'}</Item.Bold>
      <Item.Text>{item.name}</Item.Text>
    </Item.Row>

    <Item.Row>
      <Item.Bold>{`${clues?.length ?? 0} Clues:`}</Item.Bold>
      <Item.Text />
    </Item.Row>

    {!!clues.length && (
      <Item.InsetList>{clues.map((clue, i) => RenderClue(clue, i))}</Item.InsetList>
    )}
  </Item.SummaryList>
)

const RenderClue = (clue, i) => {
  const { errors = [], free, type } = clue
  const clueError = !!errors?.length
  let error = clueError && <span tw="text-red">{'Broken'}</span>
  let content = ''
  let title = ''

  switch (type?.value) {
    case ClueType.AREA:
      title = 'Area Shot:'
      content = <Item.Text>{clue.radius?.label || '-'}</Item.Text>
      break

    case ClueType.IMAGE:
      title = 'Image:'
      content = (
        <Item.Col>
          <Image src={clue.imageUrl} />
          {clue.imageText && <ImageText>{`"${clue.imageText}"`}</ImageText>}
        </Item.Col>
      )
      break

    case ClueType.POI:
      title = 'POI:'
      content = <Item.Text>{clue.text || '-'}</Item.Text>
      break

    case ClueType.STREET_VIEW:
    case ClueType.STREET_VIEW_OLD:
      title = 'Street View:'
      content = <Image src={clue.imageUrl} />
      break

    case ClueType.TEXT:
      title = 'Text Clue:'
      content = <Item.Text>{clue.text || '-'}</Item.Text>
      break

    case ClueType.TREASURE:
      title = 'Treasure Map:'
      content = <Image src={clue.imageUrl} />
      break

    default:
      title = 'ERROR:'
  }

  return (
    <Item.Row key={i}>
      <Item.Bold>
        {free && <span>{'Free '}</span>}
        {title}
      </Item.Bold>
      <Item.Text>{error || content}</Item.Text>
    </Item.Row>
  )
}

const Image = tw.img`h-32 w-min shadow-md rounded-md`
const ImageText = tw.div`italic text-sm text-gray-800`
