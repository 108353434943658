import { useCallback, useState } from 'react'
import { objectIsEmpty } from '../utility/validationFunctions'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'

const useFilter = () => {
  const [andFilters, setAndFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const [filteredLength, setFilteredLength] = useState()

  const addFilter = useCallback((filter, value) => {
    switch (filter.field) {
      // Remove filter when value.length is 0
      case 'clue_type':
      case 'query':
      case 'tags':
        return setFilters((filters) =>
          value?.length
            ? { ...filters, [filter.field]: { filter: filter.filter, value } }
            : omit(filters, filter.field)
        )
      // Toggle filter on or off
      default: {
        return setFilters((filters) =>
          filters[filter.field]?.value === value
            ? omit(filters, filter.field)
            : { ...filters, [filter.field]: { filter: filter.filter, value } }
        )
      }
    }
  }, [])

  const clearFilters = useCallback((filter) => {
    switch (filter?.field) {
      case 'clue_type':
      case 'query':
      case 'tags':
        return setFilters((filters) => omit(filters, filter.field))
      default:
        return setFilters({})
    }
  }, [])

  // Filter array
  const applyFilters = useCallback(
    (items = []) => {
      const applyFilter = ({ filter, items, value }) => filter(items, value)
      const combineFilters = () => {
        let allItems = [...items]

        if (objectIsEmpty(filters)) return allItems
        let combined = []

        // AND
        if (andFilters) {
          combined = allItems

          for (let filter in filters) {
            combined = applyFilter({ ...filters[filter], items: combined })
          }
        } else {
          // OR
          combined = []
          for (let type in filters) {
            combined = combined.concat(applyFilter({ ...filters[type], items: allItems }))
          }
        }

        return combined
      }
      const filteredItems = uniq(combineFilters())
      setFilteredLength(filteredItems.length)
      return filteredItems
    },
    [andFilters, filters]
  )

  return {
    addFilter,
    andFilters,
    applyFilters,
    clearFilters,
    filteredLength,
    filters,
    setAndFilters
  }
}

export default useFilter
