import React, { Fragment, useCallback, useMemo } from 'react'
import { CityMarker } from './CityMarker'
import { NewCountryMarker } from './NewCountryMarker'
import { useAreas } from '../../../contexts/AreasContext'
import { Toast } from '../../../utility/alerts'
import { useMap } from '../../../contexts/MapContext'

export const AreaMarkers = ({ newCountry, setNewCountry }) => {
  const {
    adding,
    expandCountry,
    cityBeingEdited,
    countries,
    saveCountry,
    setAdding,
    setExpandedCountry
  } = useAreas()

  const { focusOnPoint } = useMap()

  const onCancelActive = useCallback(() => {
    setNewCountry()
    setAdding(false)
  }, [setAdding, setNewCountry])

  const onAddCountry = useCallback(
    (country) => {
      saveCountry(country)
      setExpandedCountry(country)
      onCancelActive()
      Toast.fire(`${country.name} added!`, '', 'success')
    },
    [onCancelActive, saveCountry, setExpandedCountry]
  )

  const onClickCity = useCallback(
    (city) => {
      const countryId = city.path?.split('/')[0]
      if (!countryId) return

      expandCountry({ id: countryId })
      focusOnPoint(city, { maxZoom: 15 })
    },
    [expandCountry, focusOnPoint]
  )

  return useMemo(
    () => (
      <Fragment>
        {!!countries?.length &&
          countries.map(({ cities = [] }) =>
            cities.map((city, i) => (
              <CityMarker
                key={i}
                city={city}
                onClick={() => onClickCity(city)}
                editing={cityBeingEdited?.name === city.name}
              />
            ))
          )}
        {adding && cityBeingEdited?.centerPoint && (
          <CityMarker city={cityBeingEdited} editing={true} />
        )}
        {newCountry && (
          <NewCountryMarker country={newCountry} onAdd={onAddCountry} onCancel={onCancelActive} />
        )}
      </Fragment>
    ),
    [adding, cityBeingEdited, countries, newCountry, onAddCountry, onCancelActive, onClickCity]
  )
}
