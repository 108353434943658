import React, { useEffect, useState } from 'react'
import tw, { theme } from 'twin.macro'
import { useCorporate } from '../../contexts/CorporateContext'
import { EventSorts, Role } from '../../enums'
import useFilter from '../../hooks/useFilter'
import useSort from '../../hooks/useSort'
import { Action, List } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button, Card } from '../../styles/Layout'
import RBAC from '../RBAC'
import { EventFilter } from './EventFilter'
import { EventItems } from './EventItems'

export const EventList = () => {
  const { addNewEvent, events } = useCorporate()
  const { applyFilters, ...filterMethods } = useFilter()
  const { applySort, sortingBy, sortListBy } = useSort(EventSorts.STATUS)
  const [filteredItems, setFilteredItems] = useState(events)

  // Filter and sort items
  useEffect(() => {
    setFilteredItems(applySort(applyFilters(events)))
  }, [applyFilters, applySort, events])

  return (
    <Card.Container header={'Corporate Events'} color={theme`colors.terracotta`}>
      <List.Filter>
        <EventFilter filter={filterMethods} />
      </List.Filter>

      <Action.Header>
        <Action.Right>
          <RBAC allow={[Role.CREATOR]}>
            <Button.Primary ring size="sm" onClick={() => addNewEvent()}>
              <Icon.Plus size="sm" mr="2" />
              {'Create New Event'}
            </Button.Primary>
          </RBAC>

          <Action.More />
        </Action.Right>
      </Action.Header>

      <List.Header>
        <List.HeaderData
          colSpan={1}
          active={sortingBy?.sort === EventSorts.ACTIVE}
          onClick={() => sortListBy(EventSorts.ACTIVE)}
          inc={sortingBy?.inc}
          tw="text-center"
        >
          Active
        </List.HeaderData>
        <List.HeaderData
          colSpan={1}
          active={sortingBy?.sort === EventSorts.STATUS}
          onClick={() => sortListBy(EventSorts.STATUS)}
          inc={sortingBy?.inc}
          tw="text-center"
        >
          Status
        </List.HeaderData>
        <List.HeaderData
          colSpan={3}
          active={sortingBy?.sort === EventSorts.NAME}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(EventSorts.NAME)}
        >
          Name
        </List.HeaderData>
        <List.HeaderData
          colSpan={2}
          active={sortingBy?.sort === EventSorts.START_DATE}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(EventSorts.START_DATE)}
        >
          Starts
        </List.HeaderData>
        <List.HeaderData
          colSpan={2}
          active={sortingBy?.sort === EventSorts.END_DATE}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(EventSorts.END_DATE)}
        >
          Ends
        </List.HeaderData>
      </List.Header>

      <Container>
        {filteredItems?.length ? (
          <EventItems items={filteredItems} />
        ) : (
          <Empty>{`${events.length ? 'No filtered items...' : 'Nothing here...'}`}</Empty>
        )}
      </Container>
      <Card.Footer color={theme`colors.terracotta`} />
    </Card.Container>
  )
}

const Empty = tw.div`flex justify-center p-6 italic text-sm text-gray`
const Container = tw.div`flex flex-col h-full`
