import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useHvnt } from '../../contexts/HvntContext'
import tw from 'twin.macro'
import { Map, Layer, NavigationControl, Source } from 'react-map-gl'
import { Loader } from '../Loader/Loader'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useHvntList } from '../../hooks/useHvntList'
import config from '../../config'
import { MapControl } from '../Map/MapControl'
import { GeolocateControl } from 'react-map-gl'
import { useMap } from '../../contexts/MapContext'
import { useAreas } from '../../contexts/AreasContext'
import { SelectCityMarkers } from '../Map/Markers/SelectCityMarkers'
import { mapLayers } from '../Map/MapLayers'
import { useMapControl } from '../../hooks/useMapControl'

export const HvntsMap = () => {
  const mapRef = useRef(null)
  const { lockCity } = useAreas()
  const { focusedBbox, focusedMarker } = useMap()
  const { allCheckpoints } = useHvnt()
  const { hvntAreas } = useHvntList()
  const { containerRef, zoomToBbox, zoomToPoint } = useMapControl(mapRef)
  const { isMounted } = useIsMounted()

  const [busy, setBusy] = useState(true)
  const [viewstate, setViewstate] = useState({
    longitude: 18.078419,
    latitude: 59.342644,
    zoom: 3
  })

  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  // Zooms to focused marker or area
  useEffect(() => {
    if (!isMounted() || busy) return
    if (focusedBbox) return zoomToBbox(focusedBbox)
    if (focusedMarker) return zoomToPoint(focusedMarker)
  }, [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint])

  return (
    <MapContainer ref={containerRef}>
      <>
        <Loader loading={busy} text="Loading Map" />
        <Map
          {...viewstate}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
          mapStyle={config.mapbox.ALTERNATE_MAP}
          maxZoom={18}
          onLoad={() => setBusy(false)}
          onMove={onMoveMap}
          ref={mapRef}
          style={{ width: '100vw', height: '100%' }}
        >
          <SelectCityMarkers locked={lockCity} />

          <Source type="geojson" data={allCheckpoints}>
            <Layer {...mapLayers.hvnt.checkpoint} />
          </Source>

          <Source type="geojson" data={hvntAreas}>
            <Layer {...mapLayers.hvnt.main} />
            <Layer {...mapLayers.hvnt.border} />
          </Source>

          <MapControl>
            <GeolocateControl
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
            />
            <NavigationControl />
          </MapControl>
        </Map>
      </>
    </MapContainer>
  )
}
const MapContainer = tw.div`relative flex overflow-hidden justify-center items-center`
