import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { actionTypes, hvntReducer } from '../reducers/hvntReducer'
import { arrayToFeatures, compressOptions, flatMap } from '../utility/helperFunctions'
import { db } from '../lib/firebase'
import { hvntConverter } from '../utility/formatHvnts'
import { useAreas } from './AreasContext'
import { useMap } from './MapContext'
import { useCheckpoint } from './CheckpointContext'
import useGetHvnts from '../hooks/firebase/useGetHvnts'
import { noFeatures } from '../utility/validationFunctions'
import { HVNT_CATEGORY_OPTIONS, HVNT_TAGS_OPTIONS } from '../utility/labeledOptions'
import { HvntFormTab, HvntTab } from '../enums'
import { useNavigate } from 'react-router-dom'

export const HvntContext = createContext()

export const HvntProvider = ({ children }) => {
  const { checkpoints } = useCheckpoint()
  const { activeCity, allCities, setLockCity } = useAreas()
  const { focusOnFeatures, focusOnPoint } = useMap()
  const { onNextHvnts, onNextUnlistedHvnts } = useGetHvnts()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState(HvntTab.REGULAR)
  const [activeFormTab, setActiveFormTab] = useState(HvntFormTab.DETAILS)
  const [adding, setAdding] = useState(false)
  const [categoryOptions, setCategoryOptions] = useState()
  const [editing, setEditing] = useState(false)
  const [hvnts, dispatch] = useReducer(hvntReducer, { regular: [], unlisted: [] })
  const [hvntBeingEdited, setHvntBeingEdited] = useState()
  const [path, setPath] = useState()
  const [tagOptions, setTagOptions] = useState()
  const [validateHvnt, setValidateHvnt] = useState(true)
  const allCheckpoints = useMemo(() => arrayToFeatures(checkpoints.regular) ?? [], [checkpoints])
  const activeCheckpoints = useMemo(
    () => arrayToFeatures(checkpoints.regular?.filter((c) => !c.custom && c.active) ?? []),
    [checkpoints]
  )
  const customCheckpoints = useMemo(
    () => arrayToFeatures(checkpoints.regular?.filter((c) => c.custom && c.active) ?? []),
    [checkpoints]
  )
  const inactiveCheckpoints = useMemo(
    () => arrayToFeatures(checkpoints.regular?.filter((c) => !c.active) ?? []),
    [checkpoints]
  )

  const listenToPath = useCallback(
    (newPath) => {
      if (path === newPath) return
      dispatch({ type: actionTypes.regular.clear })
      setPath(newPath)
    },
    [path]
  )

  // Add new hvnt
  const addNewHvnt = useCallback(
    ({ activeCity, unlisted = false, ...initial }) => {
      // Set path depending on hvnt type
      const path = unlisted ? 'unlisted/with-code' : activeCity.path ?? ''
      if (!path) return

      // Create new document
      const newDoc = db.collection(`hvnts/${path}`).doc()
      setHvntBeingEdited({
        active: false,
        id: newDoc.id,
        path: newDoc.path,
        ...(unlisted && {
          cityPath: activeCity.path,
          entryCode: Math.random().toString(16).slice(2, 8).toUpperCase(),
          unlisted
        }),
        ...initial
      })
      setValidateHvnt(false)
      setAdding(true)
      setEditing(false)
      navigate(`${activeCity.name}/create`)
    },
    [navigate]
  )

  // Start editing hvnt
  const startEditingHvnt = useCallback(
    (hvnt) => {
      setHvntBeingEdited(hvnt)
      setAdding(false)
      setEditing(true)
      setValidateHvnt(hvnt.status?.validate ?? hvnt.active ?? true)
      navigate(`${activeCity?.name}/${hvnt.id}`)
    },
    [activeCity?.name, navigate]
  )

  // Stop editing hvnt
  const stopEditingHvnt = useCallback(() => {
    setHvntBeingEdited()
    setAdding(false)
    setEditing(false)
  }, [])

  // Update hvnts path when active city changes
  useEffect(() => {
    listenToPath(activeCity?.path ?? undefined)
  }, [activeCity, listenToPath])

  // Zoom to active city
  useEffect(() => {
    if (activeCity) return focusOnPoint(activeCity, { minZoom: 11.5, maxZoom: 12 })
    if (noFeatures(allCities)) return
    focusOnFeatures(allCities, { padding: 150 })
  }, [activeCity, allCities, focusOnFeatures, focusOnPoint, hvntBeingEdited])

  // Listen to collection changes for regular hvnts
  useEffect(() => {
    if (!path) return
    const unsubscribe = db
      .collection(`hvnts/${path}`)
      .withConverter(hvntConverter)
      .onSnapshot((onNext) => onNextHvnts(onNext, dispatch))
    return unsubscribe
  }, [onNextHvnts, path])

  // Listen to collection changes for unlisted hvnts
  useEffect(() => {
    const unsubscribe = db
      .collection('hvnts/unlisted/with-code')
      .withConverter(hvntConverter)
      .onSnapshot((onNext) => onNextUnlistedHvnts(onNext, dispatch))
    return unsubscribe
  }, [onNextUnlistedHvnts])

  // Lock City select while creating / editing hvnt
  useEffect(() => {
    setLockCity(!!hvntBeingEdited)
    return () => setLockCity(false)
  }, [hvntBeingEdited, setLockCity])

  // Create options for categories and tags
  useEffect(() => {
    const hTags = hvnts.regular?.reduce(
      (acc, current) => ({
        tags: [...acc.tags, current.tags ?? []],
        areaTags: [...acc.areaTags, current.area.tags ?? []]
      }),
      { tags: [], areaTags: [] }
    )

    const uTags = hvnts.unlisted?.reduce(
      (acc, current) => ({
        ...acc,
        tags: [...acc.tags, current.tags ?? []],
        areaTags: [...acc.areaTags, current.area.tags ?? []]
      }),
      { tags: [], areaTags: [] }
    )

    const hCategories = hvnts.regular?.map((hvnt) => hvnt.category || []) ?? []
    const uCategories = hvnts.unlisted?.map((hvnt) => hvnt.category || []) ?? []

    setCategoryOptions(
      compressOptions(hCategories.concat(uCategories).concat(HVNT_CATEGORY_OPTIONS)).sort(
        ({ value: a }, { value: b }) => a.localeCompare(b)
      )
    )

    setTagOptions(
      compressOptions(
        flatMap(hTags.tags.concat(uTags.tags), (v) => v).concat(HVNT_TAGS_OPTIONS)
      ).sort(({ value: a }, { value: b }) => a.localeCompare(b))
    )
  }, [hvnts])

  const defaultValues = useMemo(
    () => ({
      activeCheckpoints,
      activeFormTab,
      activeTab,
      adding,
      addNewHvnt,
      allCheckpoints,
      categoryOptions,
      customCheckpoints,
      dispatch,
      editing,
      hvntBeingEdited,
      hvnts,
      inactiveCheckpoints,
      setActiveFormTab,
      setActiveTab,
      setTagOptions,
      setValidateHvnt,
      startEditingHvnt,
      stopEditingHvnt,
      tagOptions,
      validateHvnt
    }),
    [
      activeCheckpoints,
      activeFormTab,
      activeTab,
      adding,
      addNewHvnt,
      allCheckpoints,
      categoryOptions,
      customCheckpoints,
      editing,
      hvntBeingEdited,
      hvnts,
      inactiveCheckpoints,
      startEditingHvnt,
      stopEditingHvnt,
      tagOptions,
      validateHvnt
    ]
  )

  return <HvntContext.Provider value={defaultValues}>{children}</HvntContext.Provider>
}

// Hook
export const useHvnt = () => {
  const context = useContext(HvntContext)
  if (context === undefined)
    throw new Error('`useHvnt` hook must be used within a `HvntProvider` component')
  return context
}
