import React, { Fragment, useEffect, useMemo } from 'react'
import tw, { css, styled } from 'twin.macro'
import { PageLayout } from '../../styles/Layout'
import { useAreas } from '../../contexts/AreasContext'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { CheckpointMap } from '../Checkpoint/Map/CheckpointMap'
import { CombinedCheckpointList } from '../Checkpoint/CombinedCheckpointList'
import { CreateCheckpoint } from '../Checkpoint/CreateCheckpoint'
import { useClue } from '../../contexts/ClueContext'
import { Outlet, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CheckpointTab } from '../../enums'

const CheckpointsPage = () => {
  const { activeCity } = useAreas()
  const {
    activeTab,
    adding,
    checkpointBeingEdited,
    clueTabOpen,
    editingClue,
    reviewing,
    visitDeepRoute
  } = useCheckpoint()
  const { clues } = useClue()
  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  // Visit deep route
  useEffect(() => {
    const id = params.get('id')
    const edit = params.get('edit')
    const tab = params.get('tab')
    if (!id) return

    visitDeepRoute({ edit, id, tab })
  }, [location, params, visitDeepRoute])

  // Update Routes through side effects
  useEffect(() => {
    if (!activeCity?.name) return navigate('', { replace: true })
    if (!checkpointBeingEdited) navigate(activeCity.name, { replace: true })
  }, [activeCity, adding, checkpointBeingEdited, navigate])

  return useMemo(
    () => (
      <PageLayout>
        <Routes>
          <Route
            path="/"
            element={
              <Container
                open={activeCity}
                wider={!!checkpointBeingEdited || activeTab === CheckpointTab.CANDIDATES}
                widest={(clueTabOpen && !!clues?.candidates?.length) || (reviewing && editingClue)}
              >
                <ListContainer>
                  <Outlet />
                </ListContainer>
                <MapContainer>
                  <CheckpointMap />
                </MapContainer>
              </Container>
            }
          >
            <Route path=":city" element={<CombinedCheckpointList />} />
            <Route
              path=":city/*"
              element={
                checkpointBeingEdited ? (
                  <CreateCheckpoint checkpoint={checkpointBeingEdited} />
                ) : (
                  <Fragment />
                )
              }
            />
          </Route>
        </Routes>
      </PageLayout>
    ),
    [activeCity, activeTab, checkpointBeingEdited, clues, clueTabOpen, editingClue, reviewing]
  )
}
export default CheckpointsPage

const ListContainer = tw.div`flex flex-col h-full transition-all duration-300`
const MapContainer = tw.div`flex justify-center overflow-hidden minHeight[50vh] w-full h-full transition-all duration-300`
const Container = styled.div`
  ${tw`flex flex-col lg:flex-row w-full`}
  ${({ open, wider, widest }) => css`
     {
      & > ${ListContainer} {
        ${widest ? tw`lg:w-2/3` : wider ? tw`lg:w-1/2` : open ? tw`lg:w-2/5` : tw`h-0 w-0`}
      }
      & > ${MapContainer} {
        ${widest ? tw`lg:w-1/3` : wider ? tw`lg:w-1/2` : open ? tw`lg:w-3/5` : tw`w-full`}
      }
    }
  `}
`
