import React, { useCallback, useMemo } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { Card } from '../../styles/Layout'
import { useAreas } from '../../contexts/AreasContext'
import { CheckpointList } from './CheckpointList'
import Icon from '../../styles/Icons'
import { CandidateInfo } from './Candidate/CandidateInfo'
import { CheckpointTab } from '../../enums'
import { Loader } from '../Loader/Loader'
import { CandidateList } from './Candidate/CandidateList'

export const CombinedCheckpointList = () => {
  const {
    activeCandidateCells,
    activeTab,
    checkpoints: { candidates = [], regular: checkpoints = [] },
    fetchingCheckpoint,
    reviewing,
    setActiveTab,
    setFocusedItem
  } = useCheckpoint()
  const { activeCity, removeActiveCity } = useAreas()

  const candidateTab = useMemo(() => activeTab === CheckpointTab.CANDIDATES, [activeTab])
  const items = useMemo(
    () => (candidateTab ? candidates : checkpoints),
    [candidates, checkpoints, candidateTab]
  )

  const onCloseList = useCallback(() => {
    removeActiveCity()
    setActiveTab(CheckpointTab.CHECKPOINTS)
  }, [removeActiveCity, setActiveTab])

  const onSwitchTab = useCallback(
    (tab) => {
      setActiveTab(tab)
      setFocusedItem()
    },
    [setActiveTab, setFocusedItem]
  )

  return useMemo(() => {
    return (
      <Card.Container
        header={
          `${items.length} ${candidateTab ? 'Candidate' : 'Checkpoint'}` +
          `${items?.length === 1 ? '' : 's'}` +
          `${activeCity?.name ? ` in ${activeCity.name}` : ''}`
        }
        color={theme`colors.gold.500`}
        xHover={theme`colors.gold.600`}
        xActive={theme`colors.gold.700`}
        onClose={onCloseList}
      >
        <Loader loading={fetchingCheckpoint} text="Fetching checkpoint" />
        <Tabs>
          <Tab active={!candidateTab} onClick={() => onSwitchTab(CheckpointTab.CHECKPOINTS)}>
            <Icon.Pin mr="2" size="sm" />
            Checkpoints
          </Tab>
          <Tab active={candidateTab} onClick={() => onSwitchTab(CheckpointTab.CANDIDATES)}>
            <Icon.Star mr="2" size="sm" /> Candidates
          </Tab>
        </Tabs>
        {candidateTab ? (
          activeCandidateCells?.length && !reviewing ? (
            <CandidateList cells={activeCandidateCells} />
          ) : (
            <CandidateInfo />
          )
        ) : (
          <CheckpointList />
        )}
      </Card.Container>
    )
  }, [
    activeCandidateCells,
    activeCity?.name,
    candidateTab,
    fetchingCheckpoint,
    items.length,
    onCloseList,
    onSwitchTab,
    reviewing
  ])
}

const Tabs = tw.div`flex w-full pb-0 bg-gold-600 gap-2 first:pl-2`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 flex cursor-pointer minWidth[100px] text-center rounded-t-md text-lg tracking-wide truncate`}
  ${({ active }) => css`
    ${active ? tw`bg-gray-200` : tw`bg-gold-600 hover:bg-gold-700 `}
  `}
`
