import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import tw, { css, styled, theme } from 'twin.macro'
import config from '../../config'
import { useHvnt } from '../../contexts/HvntContext'
import useCloudinary from '../../hooks/useCloudinary'
import { useIsMounted } from '../../hooks/useIsMounted'
import { db } from '../../lib/firebase'
import RBAC from '../RBAC'
import Icon from '../../styles/Icons'
import { Button, Card } from '../../styles/Layout'
import { Alerts } from '../../utility/alerts'
import { setNextStatus, hvntConverter } from '../../utility/formatHvnts'
import { Loader } from '../Loader/Loader'
import { HvntForm } from './HvntForm'
import { HvntAreaMap } from './Area/HvntAreaMap'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import useMapbox from '../../hooks/useMapbox'
import useGetPrizes from '../../hooks/firebase/useGetPrizes'
import { HvntPrizeError } from '../../utility/customErrors'
import { useAreas } from '../../contexts/AreasContext'
import { HvntRouteProvider } from '../../contexts/HvntRouteContext'
import { CreateArea } from './Area/CreateArea'
import uniqBy from 'lodash/uniqBy'
import { createOption } from '../../utility/helperFunctions'
import request from '../../request'
import { HVNT_DEFAULT_TAGS } from '../../utility/labeledOptions'
import { ChallengeDistributionType, HvntFormTab, HvntStatus } from '../../enums'
import { Action } from '../../layout/List'
import { validate } from '../../utility/formValidation'
import { ChallengeConfig } from './ChallengeConfig'

export const CreateHvnt = ({ hvnt = {} }) => {
  const {
    DEFAULT_NUM_CHECKPOINTS,
    DEFAULT_RADIUS,
    FIRST_EASY_PROPORTION,
    MAX_ANGLE,
    TARGET_DISTANCES
  } = config.hvntRoute
  const { activeCity } = useAreas()
  const { mounted } = useIsMounted()
  const { uploadImage } = useCloudinary()
  const { generateStaticImageUrl } = useMapbox()
  const {
    activeFormTab,
    adding,
    editing,
    setActiveFormTab,
    setValidateHvnt,
    stopEditingHvnt,
    validateHvnt
  } = useHvnt()
  const { reservePrizes, unreservePrizes } = useGetPrizes()

  const methods = useForm({
    defaultValues: {
      ...merge(
        {
          area: {
            challenge: { distribution: [ChallengeDistributionType.LAST] },
            customRoute: !!hvnt.area?.distances || false,
            distances: {
              default: TARGET_DISTANCES.default,
              max: TARGET_DISTANCES.max,
              min: TARGET_DISTANCES.min,
              start: TARGET_DISTANCES.start // locked for now
            },
            easyProportion: FIRST_EASY_PROPORTION,
            locked: hvnt.status?.routeLocked ?? false,
            maxAngle: MAX_ANGLE,
            radius: DEFAULT_RADIUS,
            saved: false,
            tags: {
              custom: false,
              regular: true,
              useCustom: false,
              useRegular: true
            }
          },
          numberOfCheckpoints: DEFAULT_NUM_CHECKPOINTS,
          featured: false
        },
        {
          ...omit(hvnt, ['errors', 'highScores', 'index', 'path', 'updatedAt'])
        }
      ),
      prizes: [] // Override prizes to register them properly
    },
    mode: 'all'
  })
  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    unregister,
    watch
  } = methods

  const [addedImage, setAddedImage] = useState(false)
  const [loading, setLoading] = useState(false)
  const { active, area = {}, dateRange, s2Cells } = watch()

  const activeChanged = active ? !hvnt.active : hvnt.active
  const canChangeActive = hvnt.status !== HvntStatus.ENDED
  const canDeleteHvnt = editing && hvnt.status?.removable
  const currentStatus = adding ? (active ? HvntStatus.UPCOMING : HvntStatus.DRAFT) : hvnt.status
  const nextStatus = adding
    ? ''
    : setNextStatus({ active, activeChanged, dateRange }) === hvnt.status
    ? ''
    : setNextStatus({ active, dateRange })
  const formErrors =
    errors.category ||
    errors.dateRange ||
    errors.description ||
    errors.imageUrl ||
    errors.name ||
    errors.prizes
  const prizesLocked = !!hvnt.prizes?.some((prize) => prize.locked)

  // Submit form data
  const onSubmitHvnt = useCallback(
    async (formData) => {
      // Get the previous values
      const { active: hvntWasActive, id, path, prizes: currentPrizes, status } = hvnt

      // Show activation information
      if (formData.active && !hvntWasActive) {
        const { value: ok } = await mounted(Alerts.Hvnt.ACTIVATE_INFO())
        if (!ok) return
      }
      if (!formData.active && hvntWasActive) {
        if (status === HvntStatus.LIVE) {
          const { value: ok } = await mounted(Alerts.Hvnt.DEACTIVATE_LIVE_INFO())
          if (!ok) return
        }
        if (status === HvntStatus.UPCOMING) {
          const { value: ok } = await mounted(Alerts.Hvnt.DEACTIVATE_UPCOMING_INFO())
          if (!ok) return
        }
      }

      // Upload image
      if (addedImage) {
        setLoading(true)
        try {
          formData.imageUrl = await uploadImage({
            file: formData.imageUrl,
            id,
            path: 'hvnts/'
          })
        } catch (err) {
          setError('imageUrl', { type: 'manual', message: 'Image might be too big!' })
          setLoading(false)
          return Alerts.Image.UPLOAD_FAILED(err)
        }
      }

      // Generate static image if we're live

      if (formData.active && !formData.area.mapUrl) {
        try {
          const {
            area: { centerPoint, radius }
          } = formData
          setLoading(true)
          const staticMap = await generateStaticImageUrl({ centerPoint, radius })

          formData.area.mapUrl = await uploadImage({
            file: staticMap,
            id,
            path: 'hvnt-areas/'
          })
        } catch (err) {
          setLoading(false)
          return Alerts.Hvnt.UPDATE_FAILED(adding, err)
        }
      }

      try {
        await db.runTransaction(async (t) => {
          const hvntDoc = db.doc(path).withConverter(hvntConverter)
          const { prizes } = formData

          /**
           * If we deactivate a hvnt that was upcoming, we need to unreserve its prizes
           * and unlock the prize again
           */
          if (!formData.active && hvntWasActive && status === HvntStatus.UPCOMING) {
            await unreservePrizes(t, { hvntDoc })
            for (let prize of prizes) {
              prize.locked = false
            }
          }

          /**
           * Reserves the prizes and creates a 'prizes' collection on the hvnt
           * It also locks each prize to the hvnt
           * Skip if we don't require validation, or if the prize is already locked
           */
          for (let prize of prizes) {
            if (prize && validateHvnt && !currentPrizes?.some((prize) => prize.locked)) {
              // Really shouldn't happen
              if (!prize.path || !prize.reservedAmount)
                throw new HvntPrizeError({ message: 'This prize is not valid!' })
              await reservePrizes(t, { hvntDoc, prize })

              prize.locked = true
            }
          }

          // For some reason t.update doesn't work with FieldValue.delete and undefined values,
          // But t.set with merge: true does
          t.set(hvntDoc, formData, { merge: true })
        })

        Alerts.Hvnt.UPDATE_SUCCESS(!hvntWasActive && formData.active)
      } catch (err) {
        console.error(err)
        if (err.field) setError(err.field, { type: 'manual', message: err.validation })
        return Alerts.Hvnt.UPDATE_FAILED(adding, err)
      } finally {
        setLoading(false)
      }

      stopEditingHvnt()
    },
    [
      hvnt,
      addedImage,
      stopEditingHvnt,
      mounted,
      uploadImage,
      setError,
      generateStaticImageUrl,
      adding,
      unreservePrizes,
      validateHvnt,
      reservePrizes,
      s2Cells
    ]
  )

  // Activate and require validation
  const onActivateHvnt = useCallback(async () => {
    if (!activeCity.active) return Alerts.Hvnt.ACTIVATE_FAILED()
    setValidateHvnt(true)
    setValue('active', true)
  }, [activeCity, setValidateHvnt, setValue])

  // Deactivate and skip validation
  const onDeactivateHvnt = useCallback(async () => {
    setValidateHvnt(false)
    setValue('active', false)
  }, [setValidateHvnt, setValue])

  // Delete hvnt if possible
  const onDeleteHvnt = useCallback(async () => {
    const { value: remove } = await mounted(Alerts.Hvnt.DELETE_WARNING())
    if (!remove) return

    try {
      setLoading(true)
      const { path } = hvnt
      if (validateHvnt && dateRange?.startDate < new Date()) {
        await mounted(Alerts.Hvnt.DELETE_NOT_POSSIBLE())
        db.doc(path).set({ active: false }, { merge: true })
        Alerts.Hvnt.UPDATE_SUCCESS()
      } else {
        db.doc(path).delete()
        Alerts.Hvnt.DELETE_SUCCESS()
      }
    } catch (err) {
      return console.error(err)
    } finally {
      setLoading(false)
    }
    stopEditingHvnt()
  }, [dateRange, hvnt, mounted, validateHvnt, stopEditingHvnt])

  // Re-register fields if validateHvnt changes
  useEffect(() => {
    register('active')
    register('area.mapUrl')
    register('s2Cells')

    if (validateHvnt) {
      register('area', { validate: validate.hvnt.area })
      register('area.tags', { validate: validate.hvnt.routeTags })
      register('category', { required: 'Category is required' })
      register('dateRange', { required: 'Start and end date is required' })
      register('description', { required: 'Description is required' })
      register('imageUrl', { required: 'Image is required' })
      register('name', { required: 'Name is required' })
      register('numberOfCheckpoints', { required: 'Number of Checkpoints is required' })
      register('prizes', { required: 'Prize is required' })
    } else {
      clearErrors()
      // re-register fields without validation
      register('area', { required: false, validate: false })
      register('area.tags', { validate: validate.hvnt.routeTags })
      register('category', { required: false, validate: false })
      register('dateRange', { required: false, validate: false })
      register('description', { required: false, validate: false })
      register('imageUrl', { required: false, validate: false })
      register('name', { required: false, validate: false })
      register('numberOfCheckpoints', { required: false, validate: false })
      register('prizes', { required: false, validate: false })
    }
  }, [clearErrors, register, unregister, validateHvnt])

  // Update tags
  const combineTags = useCallback(
    (t = []) => {
      const { tags = [] } = watch()
      setValue('tags', uniqBy(tags.concat(HVNT_DEFAULT_TAGS).concat(t), 'value'))
    },
    [setValue, watch]
  )

  const removeTag = useCallback(
    (value) => {
      const { tags = [] } = watch()
      setValue(
        'tags',
        tags.filter((t) => t.value !== value)
      )
    },
    [setValue, watch]
  )

  // Custom checkpoints
  useEffect(() => {
    if (area.tags?.useCustom) combineTags(createOption('custom_checkpoints'))
    else removeTag('custom_checkpoints')
  }, [area.tags?.useCustom, combineTags, removeTag])

  // custom route
  useEffect(() => {
    if (area.customRoute) combineTags(createOption('custom_route'))
    else removeTag('custom_route')
  }, [area.customRoute, combineTags, removeTag])

  // geo tags
  useEffect(() => {
    if (!area.saved) return
    const addGeoTags = async () => {
      const centerPoint = watch('area.centerPoint')
      combineTags(await mounted(request.getTags({ centerPoint })))
    }
    addGeoTags()
  }, [area.saved, combineTags, mounted, watch])

  // unlisted
  useEffect(() => {
    if (!hvnt?.unlisted) return
    combineTags(createOption('unlisted'))
  }, [combineTags, hvnt?.unlisted])

  return (
    <FormProvider {...methods}>
      <HvntRouteProvider>
        <Container>
          <FormContainer>
            <Card.Container
              header={
                adding
                  ? `New ${hvnt.unlisted ? 'Unlisted' : ''} Hvnt`
                  : `Edit ${hvnt.unlisted ? 'Unlisted' : ''} Hvnt`
              }
              color={theme`colors.matrix.500`}
              xHover={theme`colors.matrix.600`}
              xActive={theme`colors.matrix.700`}
              onClose={() => stopEditingHvnt()}
            >
              <Loader loading={loading} />
              <Tabs>
                <Tab
                  active={activeFormTab === HvntFormTab.DETAILS}
                  error={formErrors}
                  onClick={() => setActiveFormTab(HvntFormTab.DETAILS)}
                >
                  Details
                </Tab>
                <Tab
                  active={activeFormTab === HvntFormTab.AREA}
                  error={errors.area}
                  onClick={() => setActiveFormTab(HvntFormTab.AREA)}
                >
                  Area
                </Tab>
                <Tab
                  active={activeFormTab === HvntFormTab.CHALLENGES}
                  onClick={() => setActiveFormTab(HvntFormTab.CHALLENGES)}
                >
                  Challenges
                </Tab>
              </Tabs>

              <Action.Header>
                <Action.Active active={active} changed={activeChanged} />
                <Action.Status status={currentStatus} nextStatus={nextStatus} tw="ml-auto" />
                <Action.More
                  content={
                    <>
                      {active ? (
                        <Button.Primary
                          size="sm"
                          onClick={() => onDeactivateHvnt()}
                          disabled={!canChangeActive || loading}
                          ring
                        >
                          <Icon.Moon size="sm" mr="2" />
                          Deactivate
                        </Button.Primary>
                      ) : (
                        <Button.Secondary
                          size="sm"
                          onClick={() => onActivateHvnt()}
                          disabled={!canChangeActive || loading}
                          ring
                        >
                          <Icon.Sun size="sm" mr="2" />
                          Activate
                        </Button.Secondary>
                      )}
                      <RBAC>
                        <Button.Warning
                          size="sm"
                          onClick={onDeleteHvnt}
                          disabled={!canDeleteHvnt || loading}
                          ring
                        >
                          <Icon.Trashcan size="sm" mr="2" />
                          Delete
                        </Button.Warning>
                      </RBAC>
                    </>
                  }
                />
              </Action.Header>
              {hvnt.entryCode && (
                <Action.Header color={theme`colors.gray.300`} tw="z-10">
                  <pre>{`Entry Code: ${hvnt.entryCode}`}</pre>
                </Action.Header>
              )}

              {activeFormTab === HvntFormTab.DETAILS && (
                <HvntForm
                  loading={loading}
                  prizes={hvnt?.prizes ?? []}
                  prizesLocked={prizesLocked}
                  setAddedImage={setAddedImage}
                  startLocked={hvnt.status?.startLocked}
                />
              )}
              {activeFormTab === HvntFormTab.AREA && <CreateArea />}
              {activeFormTab === HvntFormTab.CHALLENGES && <ChallengeConfig />}

              <Card.Footer color={theme`colors.matrix.500`}>
                <Button.White onClick={stopEditingHvnt} disabled={loading} ring tw="mr-auto">
                  <Icon.Close mr="2" />
                  Cancel
                </Button.White>
                {active ? (
                  <Button.Submit onClick={handleSubmit(onSubmitHvnt)} disabled={loading} ring>
                    <Icon.Check mr="2" />
                    {hvnt.unlisted ? 'Save Unlisted Hvnt' : 'Save Hvnt'}
                  </Button.Submit>
                ) : (
                  <Button.Primary onClick={handleSubmit(onSubmitHvnt)} disabled={loading} ring>
                    <Icon.Download mr="2" />
                    Save Draft
                  </Button.Primary>
                )}
              </Card.Footer>
            </Card.Container>
          </FormContainer>

          <MapContainer>
            <HvntAreaMap loading={loading} locked={hvnt.status?.routeLocked} />
          </MapContainer>
        </Container>
      </HvntRouteProvider>
    </FormProvider>
  )
}

const FormContainer = tw.div`flex flex-col h-full transition-all duration-300`
const MapContainer = tw.div`flex justify-center overflow-hidden h-full minHeight[50vh]`
const Container = styled.div`
  ${tw`flex flex-col lg:flex-row h-full w-full transition-all overflow-scroll`}
  & > ${FormContainer} {
    ${tw`w-full lg:w-2/5`}
  }
  & > ${MapContainer} {
    ${tw`w-full lg:w-3/5 lg:p-0`}
  }
`
const Tabs = tw.div`flex w-full pb-0 bg-matrix-600 gap-2 first:pl-2`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[100px] text-center rounded-t-md text-lg tracking-wide`}
  ${({ active, error }) => css`
    ${active ? tw`bg-gray-200` : tw`text-red-200 bg-matrix-600 hover:bg-matrix-700 `}
    ${error && tw`ring-4 ring-red`}
  `}
`
