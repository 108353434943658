import { ClueType } from '../enums'
import { CLUE_TYPE_OPTIONS, DIFFICULTY_OPTIONS, IMAGE_LICENSE_OPTIONS } from './labeledOptions'
import { checkpointConverter } from './formatCheckpoints'
import { getOption } from './helperFunctions'
import { isStringOrNumber } from './validationFunctions'

export const candidateConverter = {
  fromFirestore: (doc) => {
    const {
      active = true,
      centerPoint,
      createdAt,
      errors = [],
      metadata,
      name,
      tags,
      ...data
    } = doc.data()

    let formattedCandidate = {
      active,
      candidate: true,
      centerPoint,
      ...(createdAt &&
        !!createdAt?.toDate && {
          requestedAt: new Date(createdAt.toDate()).toISOString()
        }),
      id: doc.id,
      metadata,
      name: isStringOrNumber(name) ? name : addError({ type: 'name' }),
      mercatorPath: doc.ref.path,
      tags: [],
      ...data
    }
    return { ...formattedCandidate, errors }
  },
  toFirestore: (data) => {
    const { locationSource, metadata, requestedAt, requestId, s2CellId } = data
    return {
      fromMercator: true,
      metadata: { ...metadata, locationSource, requestedAt, requestId }, // Save additional metadata
      s2CellId,
      ...checkpointConverter.toFirestore(data)
    }
  }
}

export const candidateCellConverter = {
  fromFirestore: (snapshot) => {
    const {
      city,
      country,
      enrich,
      fetch,
      generate,
      requestedAt,
      requestedBy,
      s2Cells = [],
      s2Level
    } = snapshot.data()
    return {
      cells: s2Cells.map((cell) => ({
        id: cell,
        ...(city && { city }),
        ...(country && { country }),
        hasCandidates: generate ?? enrich?.some((e) => e !== 'none') ?? !!enrich?.length ?? false,
        ...(enrich && { enrich }),
        ...(fetch && { fetch }),
        ...(requestedAt?.toDate && { requestedAt: new Date(requestedAt.toDate()).toISOString() }),
        requestedBy,
        requestId: snapshot.id,
        s2Level
      }))
    }
  }
}

// Temporary function to create image clues
export const createImageClues = (metadata) => {
  if (!metadata) return
  const images = metadata?.wikidataProps?.image ?? []
  if (!images.length) return
  const imageClues = []

  images.forEach((data) => {
    const { artist, license, value } = data
    const licenseAuthor = artist ? artist.split('>')?.[1]?.split('<')?.[0] : '' ?? ''
    const licenseSource = artist ? artist.split('"')?.[1] : '' ?? ''
    const licenseType =
      getOption(trim(license), IMAGE_LICENSE_OPTIONS) ?? getOption('cc0', IMAGE_LICENSE_OPTIONS)

    const imageData = value
    imageClues.push({
      active: true,
      auto: true,
      candidate: true,
      imageData,
      licenseAuthor,
      licenseSource,
      licenseType,
      type: getOption(ClueType.IMAGE, CLUE_TYPE_OPTIONS),
      difficulty: getOption(2, DIFFICULTY_OPTIONS)
    })
  })
  return imageClues
}

const trim = (obj) => obj.replace(/\s?\W?0?/g, '').toLowerCase()
const addError = ({ errors, type, fallback = undefined, deactivate }) => {
  errors.push(type)
  if (deactivate) errors.push('deactivated')
  return fallback
}
