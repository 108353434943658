import React, { forwardRef } from 'react'
import tw, { styled } from 'twin.macro'
import Icon from '../../styles/Icons'
import { Button, Input } from '../../styles/Layout'

function FilterInput(
  { addFilter, clearFilters, field, filter, filters, placeholder, size = 'md', ...props },
  ref
) {
  return (
    <Container {...props}>
      <StyledInput
        onChange={({ target }) => addFilter({ field, filter }, target.value.toLowerCase())}
        placeholder={placeholder ?? 'Filter query'}
        ref={ref}
        size={size}
      />
      {filters?.query && (
        <ClearButton
          size={size}
          onClick={() => {
            clearFilters({ field: 'query' })
            ref.current.value = ''
          }}
        >
          <Icon.Close size="xs" />
        </ClearButton>
      )}
    </Container>
  )
}

export default forwardRef(FilterInput)

const Container = tw.div`relative flex w-full items-center bg-white-pure rounded-md overflow-hidden`
const StyledInput = tw(Input.Default)`h-full border-none truncate p-2 text-sm`
const ClearButton = styled(Button.White)`
  ${({ size }) => (size == 'sm' ? tw`m-0.5 p-0.5` : tw`m-1 p-1`)}
`
