import tw, { css, styled } from 'twin.macro'
import * as O from '@heroicons/react/24/outline'
import * as S from '@heroicons/react/24/solid'

// prettier-ignore
const IconStyle = ({ color, size = 'md', mr }) => css`
  ${tw`flex-shrink-0`}
  ${size === 'xs' && css`${tw`w-4`} ${mr && tw`mr-1`}`}
  ${size === 'sm' && css`${tw`w-5`} ${mr && tw`mr-1`}`}
  ${size === 'md' && css`${tw`w-6`} ${mr && tw`mr-2`}`}
  ${size === 'lg' && css`${tw`w-7`} ${mr && tw`mr-2`}`}
  ${size === 'xl' && css`${tw`w-10`} ${mr && tw`mr-3`}`}
  ${size === '2xl' && css`${tw`w-14`} ${mr && tw`mr-3`}`}
  ${color && `text-color: ${color}`}
`

const Icon = {
  Archive: styled(O.ArchiveBoxArrowDownIcon)(IconStyle),
  ArrowDown: styled(O.ArrowDownIcon)(IconStyle),
  ArrowLeft: styled(O.ArrowLeftIcon)(IconStyle),
  ArrowRight: styled(O.ArrowRightIcon)(IconStyle),
  ArrowUp: styled(O.ArrowUpIcon)(IconStyle),
  AtSymbol: styled(O.AtSymbolIcon)(IconStyle),
  Bell: styled(O.BellIcon)(IconStyle),
  BellSolid: styled(S.BellIcon)(IconStyle),
  BellAlert: styled(O.BellAlertIcon)(IconStyle),
  Bookmark: styled(O.BookmarkIcon)(IconStyle),
  Briefcase: styled(O.BriefcaseIcon)(IconStyle),
  Calendar: styled(O.CalendarDaysIcon)(IconStyle),
  Cake: styled(O.CakeIcon)(IconStyle),
  Camera: styled(O.CameraIcon)(IconStyle),
  CameraSolid: styled(S.CameraIcon)(IconStyle),
  Chat: styled(O.ChatBubbleOvalLeftEllipsisIcon)(IconStyle),
  Check: styled(O.CheckIcon)(IconStyle),
  CheckDoc: styled(O.DocumentCheckIcon)(IconStyle),
  ChevronUp: styled(O.ChevronUpIcon)(IconStyle),
  ChevronRight: styled(O.ChevronRightIcon)(IconStyle),
  ChevronDown: styled(O.ChevronDownIcon)(IconStyle),
  ChevronLeft: styled(O.ChevronLeftIcon)(IconStyle),
  Click: styled(O.CursorArrowRaysIcon)(IconStyle),
  ClickRay: styled(O.CursorArrowRippleIcon)(IconStyle),
  Clipboard: styled(O.DocumentTextIcon)(IconStyle),
  ClipboardCheck: styled(O.ClipboardDocumentCheckIcon)(IconStyle),
  Clock: styled(O.ClockIcon)(IconStyle),
  Close: styled(O.XMarkIcon)(IconStyle),
  Cog: styled(O.CogIcon)(IconStyle),
  Device: styled(O.DevicePhoneMobileIcon)(IconStyle),
  DotsVertical: styled(O.EllipsisVerticalIcon)(IconStyle),
  DotsHorizontal: styled(O.EllipsisHorizontalIcon)(IconStyle),
  Download: styled(O.ArrowDownTrayIcon)(IconStyle),
  DownloadFile: styled(O.DocumentArrowDownIcon)(IconStyle),
  Edit: styled(O.PencilSquareIcon)(IconStyle),
  EditSolid: styled(S.PencilSquareIcon)(IconStyle),
  Error: styled(O.ExclamationCircleIcon)(IconStyle),
  ExternalLink: styled(O.ArrowTopRightOnSquareIcon)(IconStyle),
  Eye: styled(O.EyeIcon)(IconStyle),
  EyeOff: styled(O.EyeSlashIcon)(IconStyle),
  Fire: styled(O.FireIcon)(IconStyle),
  Flag: styled(O.FlagIcon)(IconStyle),
  Gift: styled(O.GiftIcon)(IconStyle),
  Globe: styled(O.GlobeEuropeAfricaIcon)(IconStyle),
  GlobeAlt: styled(O.GlobeAltIcon)(IconStyle),
  Hidden: styled(O.CubeTransparentIcon)(IconStyle),
  Image: styled(O.PhotoIcon)(IconStyle),
  ImageSolid: styled(S.PhotoIcon)(IconStyle),
  Import: styled(O.DocumentTextIcon)(IconStyle),
  Key: styled(O.KeyIcon)(IconStyle),
  List: styled(O.Bars3Icon)(IconStyle),
  Live: styled(O.SignalIcon)(IconStyle),
  Lock: styled(O.LockClosedIcon)(IconStyle),
  Mail: styled(O.EnvelopeIcon)(IconStyle),
  Map: styled(O.MapIcon)(IconStyle),
  Maximize: styled(O.ArrowsPointingOutIcon)(IconStyle),
  Minus: styled(O.MinusIcon)(IconStyle),
  Moon: styled(O.MoonIcon)(IconStyle),
  Pause: styled(O.PauseIcon)(IconStyle),
  Pin: styled(O.MapPinIcon)(IconStyle),
  PinSolid: styled(S.MapPinIcon)(IconStyle),
  Plus: styled(O.PlusIcon)(IconStyle),
  PlusCircle: styled(O.PlusCircleIcon)(IconStyle),
  Puzzle: styled(O.PuzzlePieceIcon)(IconStyle),
  Question: styled(O.QuestionMarkCircleIcon)(IconStyle),
  Refresh: styled(O.ArrowPathIcon)(IconStyle),
  Scissors: styled(O.ScissorsIcon)(IconStyle),
  Search: styled(O.MagnifyingGlassIcon)(IconStyle),
  Send: styled(O.PaperAirplaneIcon)(IconStyle),
  Share: styled(O.ShareIcon)(IconStyle),
  Smiley: styled(O.FaceSmileIcon)(IconStyle),
  SortDown: styled(O.BarsArrowDownIcon)(IconStyle),
  SortUp: styled(O.BarsArrowUpIcon)(IconStyle),
  Sparkles: styled(O.SparklesIcon)(IconStyle),
  Stack: styled(O.Square3Stack3DIcon)(IconStyle),
  Star: styled(O.StarIcon)(IconStyle),
  StarSolid: styled(S.StarIcon)(IconStyle),
  Sun: styled(O.SunIcon)(IconStyle),
  Ticket: styled(O.TicketIcon)(IconStyle),
  ThumbsUp: styled(O.HandThumbUpIcon)(IconStyle),
  ThumbsUpSolid: styled(S.HandThumbUpIcon)(IconStyle),
  ThumbsDown: styled(O.HandThumbDownIcon)(IconStyle),
  ThumbsDownSolid: styled(S.HandThumbDownIcon)(IconStyle),
  Trashcan: styled(O.TrashIcon)(IconStyle),
  TrashcanSolid: styled(S.TrashIcon)(IconStyle),
  Trophy: styled(O.TrophyIcon)(IconStyle),
  Upload: styled(O.ArrowUpTrayIcon)(IconStyle),
  User: styled(O.UserIcon)(IconStyle),
  UserSolid: styled(S.UserIcon)(IconStyle),
  Video: styled(O.VideoCameraIcon)(IconStyle),
  Warning: styled(O.ExclamationCircleIcon)(IconStyle),
  WarningSolid: styled(S.ExclamationCircleIcon)(IconStyle),
  Info: styled(S.InformationCircleIcon)(IconStyle, tw`bg-white-pure rounded-full shadow-md`),
  ErrorBubble: styled(S.ExclamationCircleIcon)(
    IconStyle,
    tw`text-orange-500 hover:text-orange-400 active:text-orange-600 cursor-pointer`
  )
}

export default Icon
