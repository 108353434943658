import { SeasonItemType, SeasonStatus, SeasonStatusType, SeasonType, UiElementType } from '../enums'
import { auth, db, FieldValue } from '../lib/firebase'
import { conditionalDelete, getOption } from './helperFunctions'
import uniq from 'lodash/uniq'
import { getCountryName } from './getCountryName'
import {
  isStringOrNumber,
  isTimestamp,
  isValidDateRange,
  isValidSeasonPrizes
} from './validationFunctions'
import { isNumber } from '@turf/helpers'
import { SEASON_TYPE_OPTIONS } from './labeledOptions'

export const seasonConverter = {
  fromFirestore: (snapshot) => {
    const errors = []
    const addError = (error, fallback = undefined) => {
      errors.push(error)
      return fallback
    }

    const id = snapshot.id
    const path = snapshot.ref.path
    const {
      active,
      createdAt,
      endDate,
      geoFence = [],
      items,
      itemsMinSpacing,
      itemsPerS2Cell,
      leaderboard,
      maxNumberOfItems,
      name,
      prizes,
      startDate,
      status,
      type,
      uiElements,
      updatedAt
    } = snapshot.data()

    let formattedSeason = {
      active,
      ...(isTimestamp(createdAt) && { createdAt: new Date(createdAt.toDate()) }),
      ...(geoFence.includes('worldwide') || !geoFence.length
        ? {
            countries: [],
            playableWorldWide: true
          }
        : {
            countries: geoFence.map((countryId) => ({
              countryId,
              name: getCountryName(countryId.toUpperCase())
            })),
            playableWorldWide: false
          }),
      dateRange: isValidDateRange({ startDate, endDate })
        ? { startDate: startDate.toDate(), endDate: endDate.toDate() }
        : addError('dateRange'),
      id,
      items:
        items?.reduce(
          (acc, { type, ...data }) => ({
            ...acc,
            [type.toLowerCase()]: data
          }),
          {}
        ) ?? {},
      ...(isNumber(itemsMinSpacing) ? { itemsMinSpacing } : addError('settings')),
      ...(isNumber(itemsPerS2Cell) ? { itemsPerS2Cell } : addError('settings')),
      ...(leaderboard && { leaderboard }),
      ...(isNumber(maxNumberOfItems) ? { maxNumberOfItems } : addError('settings')),
      ...(isStringOrNumber(name) ? { name } : addError('name')),
      path,
      ...(prizes &&
        (isValidSeasonPrizes(prizes)
          ? { prizes: prizes.sort((a, b) => a.order - b.order) }
          : addError('prizes'))),
      ...(SeasonType[type] ? { type: getOption(type, SEASON_TYPE_OPTIONS) } : addError('type')),
      uiElements:
        uiElements?.reduce(
          (acc, { type, ...data }) => ({
            ...acc,
            [type.toLowerCase()]: data
          }),
          {}
        ) ?? {},
      ...(isTimestamp(updatedAt) && { updatedAt: updatedAt.toDate() })
    }
    formattedSeason.status = setDisplayStatus({ ...formattedSeason, errors, status })
    return { ...formattedSeason, errors: uniq(errors) }
  },

  toFirestore: (data) => {
    const {
      active,
      createdAt,
      countries,
      dateRange,
      items,
      itemsMinSpacing,
      itemsPerS2Cell,
      maxNumberOfItems,
      name,
      playableWorldWide,
      prizes,
      type,
      uiElements
    } = data
    return {
      active,
      createdAt: createdAt ?? new Date(),
      endDate: conditionalDelete(dateRange?.endDate),
      geoFence: playableWorldWide ? ['worldwide'] : countries.map(({ countryId }) => countryId),
      items: [
        {
          type: SeasonItemType.COMMON,
          arItemAssetPath: items?.common?.arItemAssetPath,
          assetPath: items?.common?.assetPath,
          value: items?.common?.value,
          weight: items?.common?.weight
        },
        {
          type: SeasonItemType.RARE,
          arItemAssetPath: items?.rare?.arItemAssetPath,
          assetPath: items?.rare?.assetPath,
          value: items?.rare?.value,
          weight: items?.rare?.weight
        }
      ],
      itemsMinSpacing: conditionalDelete(itemsMinSpacing),
      itemsPerS2Cell: conditionalDelete(itemsPerS2Cell),
      maxNumberOfItems: conditionalDelete(maxNumberOfItems),
      name: conditionalDelete(name),
      ...(prizes.length
        ? {
            prizes: prizes.map((prize, i) => ({ ...prize, order: i }))
          }
        : { prizes: FieldValue.delete() }),
      startDate: conditionalDelete(dateRange?.startDate),
      type: type.value,
      uiElements: [
        {
          type: UiElementType.COUNTER,
          assetPath: uiElements?.counter?.assetPath
        }
      ],
      updatedAt: FieldValue.serverTimestamp(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  }
}

const setDisplayStatus = ({ errors, status }) => {
  switch (status) {
    case SeasonStatusType.DRAFT:
      return SeasonStatus.DRAFT

    case SeasonStatusType.ENDED:
      return SeasonStatus.ENDED

    case SeasonStatusType.LIVE:
      if (errors.length) return SeasonStatus.ERROR

      return SeasonStatus.LIVE
    case SeasonStatusType.UPCOMING:
      return SeasonStatus.UPCOMING

    default:
      return SeasonStatus.UNSET
  }
}

/**
 * Only used internally for display purposes
 * Backend ultimately calculates the final season status
 * */
export const setNextStatus = ({ active, dateRange = {} }) => {
  const { startDate, endDate } = dateRange
  const now = new Date()
  if (endDate < now) return SeasonStatus.ENDED
  if (active) {
    if (startDate > now) return SeasonStatus.UPCOMING
    if (startDate < now && now < endDate) return SeasonStatus.LIVE
  }
  return SeasonStatus.DRAFT
}
