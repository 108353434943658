import { findNeighbors, generateRoutes } from '@hvntapp/hvnt-route'
import config from '../config'
import { HvntRouteStatusType, RouteStatus } from '../enums'

class StatusMessage {
  constructor(status, message) {
    this.status = status
    this.icon = RouteStatus[status].icon
    this.info = RouteStatus[status].info
    this.valid = RouteStatus[status].valid
    this.message = message
  }
}

const { ACCEPTANCE_RATE, MAX_ROUTES } = config.hvntRoute

export const hvntRoutesOfDistance = ({ checkpoints = [], numberOfCheckpoints, options }) => {
  const allRoutes = []
  const candidates = addNeighbors({ checkpoints, options })

  candidates.forEach((start) => {
    let status = {}

    // Get routes
    const routes = generateRoutes({
      checkpoints: candidates,
      n: numberOfCheckpoints,
      start,
      options
    })

    if (numberOfCheckpoints === 1) {
      status = new StatusMessage(HvntRouteStatusType.OK)
    } else {
      if (!start.neighbors?.length) {
        status = new StatusMessage(HvntRouteStatusType.ERROR, 'No Neighbors!')
      } else {
        if (routes.length === 0) {
          status = new StatusMessage(HvntRouteStatusType.ERROR, 'No valid routes!')
        } else {
          if (routes.length < MAX_ROUTES) {
            status = new StatusMessage(
              HvntRouteStatusType.WARNING,
              `Only ${routes.length} valid routes`
            )
          } else {
            status = new StatusMessage(HvntRouteStatusType.OK)
          }
        }
      }
    }

    allRoutes.push({ routes, start, status })
  })

  // Add route status
  let routeStatus = {}
  const onesWithRoutes = allRoutes.filter((node) => !!node.routes?.length)
  if (onesWithRoutes.length) {
    // Don't accept routes if less than the acceptance rate
    if (onesWithRoutes.length < candidates.length * ACCEPTANCE_RATE) {
      routeStatus = new StatusMessage(HvntRouteStatusType.WARNING)
    } else {
      routeStatus = new StatusMessage(HvntRouteStatusType.OK)
    }
  } else {
    routeStatus = new StatusMessage(HvntRouteStatusType.ERROR)
  }

  const sortedArr = allRoutes
    .filter((obj) => obj.status?.status === HvntRouteStatusType.ERROR)
    .concat(allRoutes.filter((obj) => obj.status?.status !== HvntRouteStatusType.ERROR))
    .filter((obj) => obj.status?.status !== HvntRouteStatusType.OK)
    .concat(allRoutes.filter((obj) => obj.status?.status === HvntRouteStatusType.OK))

  return {
    routes: sortedArr,
    status: routeStatus,
    accepted: onesWithRoutes.length ?? 0,
    valid: routeStatus.valid
  }
}

const addNeighbors = ({ checkpoints, options }) =>
  checkpoints
    .map(makeRouteCheckpoints)
    .map(addNeighborsToCheckpoint, options.distances)
    .sort((a, b) => a.neighbors?.length - b.neighbors?.length)

// Creates geojson features from checkpoint objects
const makeRouteCheckpoints = ({
  geometry: { coordinates },
  properties: { centerPoint, id, name }
}) => ({
  centerPoint,
  coordinates,
  id,
  name
})

function addNeighborsToCheckpoint(checkpoint, _, neighborhood) {
  return {
    ...checkpoint,
    neighbors: findNeighbors({
      checkpoint,
      max: this?.max,
      min: this?.min,
      neighborhood
    })
  }
}
