import { useCallback } from 'react'
import { actionTypes } from '../../reducers/cellReducer'
import { collateBy } from '../../utility/helperFunctions'

const collateByGenerated = collateBy((obj) => obj.hasCandidates)((a = [], b) => [...a, b])

const useGetCells = () => {
  // Query snapshot for candidate cells
  const onNextCandidateCells = useCallback((snapshot, c_dispatch, l_dispatch = () => {}) => {
    if (snapshot.empty) {
      c_dispatch({ type: actionTypes.clear })
      l_dispatch && l_dispatch({ type: actionTypes.clear })
      return
    }

    const candidateCells = []
    const locationCells = []

    snapshot.docChanges().forEach(async ({ doc, type }) => {
      const { cells } = doc.data()

      // Collates cells by value 'generated' in mercator request
      const collation = collateByGenerated(cells)
      const candidateCollation = collation.get(true)
      const locationCollation = collation.get(false)

      if (type === 'added') {
        candidateCollation && candidateCells.push(...candidateCollation)
        locationCollation && locationCells.push(...locationCollation)
      }
      if (type === 'modified') console.log('modified', { cells })
      if (type === 'removed') console.log('removed', { cells })
    })
    candidateCells.length && c_dispatch({ type: actionTypes.candidate.add, cells: candidateCells })
    locationCells.length && l_dispatch({ type: actionTypes.location.add, cells: locationCells })
  }, [])

  // Query snapshot for reviewed cells
  const onNextReviewedCells = useCallback((doc, dispatch) => {
    if (!doc.exists) return
    const { reviewedCells = [] } = doc.data()
    dispatch({ type: 'SET_REVIEWED_CELLS', cells: reviewedCells })
  }, [])

  return { onNextCandidateCells, onNextReviewedCells }
}

export default useGetCells
