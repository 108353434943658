import {
  EventLocationType,
  EventStatus,
  EventStatusType,
  HiddenClueCost,
  LocationOptions
} from '../enums'
import { auth, db, GeoPoint } from '../lib/firebase'
import { conditionalDelete, getOption } from './helperFunctions'
import uniq from 'lodash/uniq'
import {
  isGeoPoint,
  isImageUrl,
  isInteger,
  isStringOrNumber,
  isTimestamp,
  isValidDateRange
} from './validationFunctions'
import { theme } from 'twin.macro'
import { converterFromType } from './formatClues'
import { CLUE_TYPE_OPTIONS } from './labeledOptions'

export const eventsConverter = {
  fromFirestore: (snapshot) => {
    const errors = []
    const addError = (error, fallback = undefined) => {
      errors.push(error)
      return fallback
    }

    const id = snapshot.id
    const path = snapshot.ref.path
    const {
      active,
      createdAt,
      description,
      endDate,
      entryCode,
      imageUrl,
      minDistance,
      name,
      roundDuration,
      settings,
      startDate,
      status,
      title,
      updatedAt
    } = snapshot.data()

    let formattedEvent = {
      active,
      ...(isTimestamp(createdAt) && { createdAt: new Date(createdAt.toDate()) }),
      dateRange: isValidDateRange({ startDate, endDate })
        ? { startDate: startDate.toDate(), endDate: endDate.toDate() }
        : addError('dateRange'),
      description: isStringOrNumber(description) ? description : addError('description'),
      ...(entryCode && { entryCode }),
      id,
      imageUrl: isImageUrl(imageUrl) ? imageUrl : addError('imageUrl'),
      minDistance: isInteger(minDistance) ? minDistance : addError('minDistance'),
      ...(isStringOrNumber(name) ? { name } : addError('name')),
      path,
      roundDuration: isInteger(roundDuration) ? roundDuration : addError('roundDuration'),
      ...(settings && { settings }),
      ...(isStringOrNumber(title) ? { title } : addError('title')),
      ...(isTimestamp(updatedAt) && { updatedAt: updatedAt.toDate() })
    }
    formattedEvent.status = setDisplayStatus({ ...formattedEvent, errors, status })
    return { ...formattedEvent, errors: uniq(errors) }
  },

  toFirestore: (data) => {
    const {
      active,
      createdAt,
      dateRange,
      description,
      entryCode,
      imageUrl,
      minDistance,
      name,
      roundDuration,
      settings,
      title
    } = data
    return {
      active,
      createdAt: createdAt ?? new Date(),
      description: conditionalDelete(description),
      endDate: conditionalDelete(dateRange?.endDate),
      entryCode,
      imageUrl: conditionalDelete(imageUrl),
      locationOptions: LocationOptions,
      minDistance: Number(minDistance),
      name: conditionalDelete(name),
      roundDuration: Number(roundDuration),
      settings: conditionalDelete(settings),
      startDate: conditionalDelete(dateRange?.startDate),
      title: conditionalDelete(title),
      updatedAt: new Date(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  }
}

export const eventLocationConverter = {
  fromFirestore: (snapshot) => {
    const { active, challenges, clues, customTag, location, name, ref, type } = snapshot.data()
    const id = snapshot.id
    const path = snapshot.ref.path

    return {
      active,
      centerPoint: isGeoPoint(location) && {
        latitude: location.latitude,
        longitude: location.longitude
      },
      ...(challenges && {
        challenges: challenges?.map((challenge) =>
          locationChallengeConverter.fromFirestore(challenge)
        )
      }),
      ...(clues && { clues: clues.map((clue) => locationClueConverter.fromFirestore(clue)) }),
      color: setLocationColor(type),
      ...(customTag && { customTag }),
      id,
      name,
      path,
      ...(ref && { ref }),
      type
    }
  },

  toFirestore: (data) => {
    const { active, centerPoint, challenges = [], clues = [], customTag, name, ref, type } = data
    return {
      active,
      ...(challenges.length && {
        challenges: challenges.map((challenge) => locationChallengeConverter.toFirestore(challenge))
      }),
      ...(clues.length && { clues: clues.map((clue) => locationClueConverter.toFirestore(clue)) }),
      ...(customTag && { customTag: customTag.value ?? customTag }),
      location: new GeoPoint(centerPoint.latitude, centerPoint.longitude),
      name,
      ...(ref && { ref }),
      type,
      updatedAt: new Date()
    }
  }
}

export const locationClueConverter = {
  fromFirestore: (clue) => {
    const { active, price, type } = clue

    let formattedClue = {
      active: active ?? true,
      price,
      type: getOption(type.toLowerCase(), CLUE_TYPE_OPTIONS), // old clue types are lower case
      free: price === 0
    }

    formattedClue = {
      ...formattedClue,
      ...converterFromType(formattedClue.type).fromFirestore(clue)
    }

    return formattedClue
  },

  toFirestore: (clue) => {
    const { active, free, type } = clue

    return {
      active,
      ...converterFromType(type).toFirestore(clue),
      price: free ? 0 : HiddenClueCost[type.value],
      type: type.value.toUpperCase(), // replace type with upper case
      unlocked: free
    }
  }
}

export const locationChallengeConverter = {
  fromChallenge: (challenge) => {
    const { category, createdAt, custom, customTags, difficulty, options, path, ...data } =
      challenge
    return {
      ref: path,
      options: options.map(({ showExplanation, ...option }) => option),
      ...data
    }
  },

  fromFirestore: (challenge) => {
    const { ref, createdAt, custom, updatedAt, updatedBy, ...data } = challenge

    return {
      ref: ref?.path,
      ...data
    }
  },

  toFirestore: (challenge) => {
    const { id, ref, ...data } = challenge

    return {
      ref: db.doc(ref),
      ...data
    }
  }
}

const setDisplayStatus = ({ errors, status }) => {
  switch (status) {
    case EventStatusType.DRAFT:
      return EventStatus.DRAFT

    case EventStatusType.ENDED:
      return EventStatus.ENDED

    case EventStatusType.LIVE:
      if (errors.length) return EventStatus.ERROR

      return EventStatus.LIVE
    case EventStatusType.UPCOMING:
      return EventStatus.UPCOMING

    default:
      return EventStatus.UNSET
  }
}

const setLocationColor = (type) => {
  switch (type) {
    case EventLocationType.AR:
      return theme`colors.matrix.600`
    case EventLocationType.HIDDEN:
      return theme`colors.gold.600`
    case EventLocationType.LOGO:
      return theme`colors.sky.700`
    case EventLocationType.TRIVIA:
      return theme`colors.norway.800`
  }
}

/**
 * Only used internally for display purposes
 * Backend ultimately calculates the final event status
 * */
export const setNextStatus = ({ active, dateRange = {} }) => {
  const { startDate, endDate } = dateRange
  const now = new Date()
  if (endDate < now) return EventStatus.ENDED
  if (active) {
    if (startDate > now) return EventStatus.UPCOMING
    if (startDate < now && now < endDate) return EventStatus.LIVE
  }
  return EventStatus.DRAFT
}
