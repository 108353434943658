import { useCallback } from 'react'
import { useMeasure } from 'react-use'
import { useUpdateEffect } from './useUpdateEffect'

export const useMapControl = (mapRef) => {
  const [containerRef, { height, width }] = useMeasure()

  // Side effect to adjust dynamic map width
  useUpdateEffect(() => {
    mapRef.current?.resize()
  }, [height, mapRef, width])

  const updateVisibility = useCallback(
    ({ layers, visible }) => {
      if (!mapRef.current) return console.log('missing mapRef')
      const map = mapRef.current.getMap()
      layers?.forEach((layer) => {
        if (!layer) return
        map?.setLayoutProperty(layer, 'visibility', visible ? 'visible' : 'none')
      })
    },
    [mapRef]
  )

  const zoomToBbox = useCallback(
    ({ bbox, options = {} }) => {
      const { duration, maxZoom, padding } = options
      if (!bbox) return console.log('missing bbox')
      if (!mapRef.current) return console.log('missing mapRef')

      const map = mapRef.current.getMap()
      const [minLng, minLat, maxLng, maxLat] = bbox

      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {
          duration,
          ...(maxZoom && { maxZoom }),
          ...(padding && { padding })
        }
      )
    },
    [mapRef]
  )

  const zoomToPoint = useCallback(
    ({ centerPoint = {}, options = {} }) => {
      const { longitude, latitude } = centerPoint
      const { duration, maxZoom = 18, minZoom = 16 } = options
      if (!longitude || !latitude) return console.log('missing point', centerPoint)
      if (!mapRef) return console.log('missing mapRef')

      const map = mapRef.current.getMap()
      const zoom = map.getZoom()

      return map.flyTo({
        center: [longitude, latitude],
        duration,
        zoom: Math.min(maxZoom, Math.max(zoom, minZoom))
      })
    },
    [mapRef]
  )

  return { containerRef, updateVisibility, zoomToBbox, zoomToPoint }
}
