import { isNumber } from '@turf/helpers'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { PrizeDeliveryType } from '../enums'
import {
  isBlob,
  isCoordinates,
  isEmail,
  isImageUrl,
  isInteger,
  isPhoneNumber,
  isRelativeUrl,
  isValidFirestoreKey
} from './validationFunctions'

export const validate = {
  account: {
    name: (name) => {
      if (!name) return 'Name is required'
      if (name?.length > 20) return 'Max 20 characters'
    },
    phoneNumber: (phoneNumber) => {
      if (!isPhoneNumber(phoneNumber)) return 'Invalid phone number'
    }
  },
  category: (category) => {
    if (category && category?.value.length > 20)
      return 'Category cannot be longer than 20 characters'
  },
  challenge: {
    question: (question) => {
      if (!question) return 'Question is required'
      if (question.length > 200) return 'Max 200 characters'
    },
    selectOne: (options) => {
      const correct = options?.filter((option) => option.correct)
      if (options?.length !== 4) return 'We currently accept 4 answers, and 4 answers only!'
      if (!correct.length) return 'Missing correct option!'
      if (correct.length > 1) return 'Only one option can be correct!'
    }
  },
  dateRange: (range) => {
    if (!range || !range.startDate || !range.endDate) return 'Missing start and end date'
    if (new Date(range.endDate) < new Date()) return 'This end date has passed'
  },
  email: (email) => {
    if (email && !isEmail(email)) return 'Missing valid email'
  },
  event: {
    dateRange: (range) => {
      if (!range || !range.startDate || !range.endDate) return 'Missing start and end date'
      if (new Date(range.endDate) < new Date()) return 'This end date has passed'
      if (differenceInMinutes(new Date(range.endDate), new Date(range.startDate)) < 30)
        return 'The duration needs to be at least 30 minutes!'
    },
    description: (description) => {
      if (!description) return 'Missing description'
      if (description.length > 300) return 'Description cannot be longer than 300 characters'
    },
    distance: (distance) => {
      if (!distance) return 'Missing min distance'
      if (!isNumber(distance)) return 'The distance needs to be a number'
    },
    duration: (duration) => {
      if (!duration) return 'Missing round duration'
      if (!isNumber(duration)) return 'The duration needs to be a number'
    },
    title: (title) => {
      if (!title) return 'Missing title'
      if (title.length > 30) return 'Title cannot be longer than 30 characters'
    }
  },
  hvnt: {
    area: ({ centerPoint, radius, saved }) => {
      if (
        !isCoordinates(centerPoint?.latitude) ||
        !isCoordinates(centerPoint?.longitude) ||
        !isInteger(radius) ||
        !saved
      )
        return 'Missing valid hvnt area!'
    },
    dateRange: (range, prizes = []) => {
      if (!range || !range.startDate || !range.endDate) return 'Missing start and end date'
      if (new Date(range.endDate) < new Date()) return 'This end date has passed'
      if (differenceInMinutes(new Date(range.endDate), new Date(range.startDate)) < 30)
        return 'The duration needs to be at least 30 minutes!'
      for (let prize of prizes) {
        if (prize?.validTo && new Date(prize.validTo) < new Date(range.endDate))
          return 'You have a prize that expires before the hvnt ends!'
      }
    },
    prize: ({
      dateRange = {},
      deliveryType,
      available,
      id,
      previousPrize,
      prizes = [],
      reservedAmount,
      validTo
    }) => {
      const { endDate } = dateRange
      if (!isNumber(parseFloat(reservedAmount))) return 'Amount needs to be a number'
      if (!isInteger(parseFloat(reservedAmount))) return "We can't give away broken prizes!"
      if (parseFloat(reservedAmount) <= 0) return 'Only positive prize amounts, please'
      if (available && parseFloat(reservedAmount) > available)
        return "We don't have that many prizes available"
      if (validTo && endDate && new Date(validTo) < new Date(endDate))
        return 'The prize expires before the hvnt ends!'
      // If we changed the prize
      if (id !== previousPrize?.id) {
        if (prizes.some((prize) => prize.id === id)) return 'You have already reserved this prize'
      }
      // Ignore this case
      if (prizes.length === 1 && previousPrize) return
      // If we mix delivery types
      if (
        prizes.length > 0 &&
        (deliveryType !== PrizeDeliveryType.PHYSICAL ||
          prizes.some((prize) => prize.deliveryType !== PrizeDeliveryType.PHYSICAL))
      )
        return 'Multiple prizes all have to be delivered physically'
    },
    routeTags: (tags) => {
      if (!tags.custom && !tags.regular) return 'You need some checkpoints!'
      if (tags.useCustom && !tags.custom?.length) return 'You need at least one custom tag!'
      if (tags.custom?.length > 10 || tags.regular?.length > 10) return 'Maximum 10 tags is allowed'
    }
  },
  integer: (value) => {
    if (isNaN(value) || !Number.isInteger(Number(value))) return 'This must be an integer'
    if (value < 0) return 'This must be above 0'
  },
  image: (imageUrl) => {
    if (!isImageUrl(imageUrl) && !isBlob(imageUrl)) return 'Image is broken'
  },
  name: (name) => {
    if (!name) return 'Missing name'
    if (name?.length > 30) return 'Name cannot be longer than 30 characters'
  },
  player: {
    age: (age) => {
      if (!age) return true
      if (!isInteger(age)) return 'This must be an integer'
      if (age <= 0) return "Can't be below 0"
      if (age > 100) return 'Really?'
    },
    gamerTag: (gamerTag) => {
      if (gamerTag?.length > 12) return 'This cannot be longer than 12 characters'
    },
    name: (name) => {
      if (name?.length > 30) return 'Name cannot be longer than 30 characters'
    },
    query: (query) => {
      if (!query) return "This can't be empty"
      if (isPhoneNumber(query)) return
      if (isEmail(query)) return
      if (isValidFirestoreKey(query)) return
      return 'Invalid query'
    }
  },
  positiveInteger: (value) => {
    if (!value) return 'Missing value'
    if (isNaN(value) || !Number.isInteger(Number(value))) return 'This must be an integer'
    if (value <= 0) return 'This must be above 0'
  },
  prize: {
    amount: (amount) => {
      if (!isNumber(amount)) return 'This needs to be a number'
      if (!isInteger(parseFloat(amount))) return 'This needs to be an integer'
      if (amount < 0) return "This can't be negative"
      if (amount === 0) return "This can't be zero!"
      if (amount > 100) return 'We allow max 100 prizes at a time'
    },
    marketValue: (value) => {
      if (value && !isNumber(value)) return 'This needs to be a number'
      if (value && parseFloat(value) < 0) return "This can't be negative"
      if (value && parseFloat(value) === 0) return "This can't be zero"
    }
  },
  season: {
    dateRange: (range) => {
      if (!range || !range.startDate || !range.endDate) return 'Missing start and end date'
      if (new Date(range.endDate) < new Date()) return 'This end date has passed'
      if (differenceInHours(new Date(range.endDate), new Date(range.startDate)) < 12)
        return 'The duration needs to be at least 12 hours!'
    },
    minSpacing: (spacing) => {
      if (!spacing) return 'This is required'
      if (isNaN(spacing) || !Number.isInteger(Number(spacing))) return 'This must be an integer'
      if (spacing <= 0) return 'This must be above 0'
      if (spacing > 200) return "This can't be above 200"
    },
    numberOfItems: (n) => {
      if (!n) return 'This is required'
      if (isNaN(n) || !Number.isInteger(Number(n))) return 'This must be an integer'
      if (n <= 0) return 'This must be above 0'
      if (n > 500) return "This can't be above 500"
    },
    urlPath: (path) => {
      if (!path) return 'Missing path'
      if (!isRelativeUrl(path)) return 'Not a valid path'
    },
    weight: (weight) => {
      if (!weight) return 'Missing weight'
      if (isNaN(weight)) return 'Weight needs to be a number'
      if (weight < 0 || weight > 1) return 'Weight must be between 0 and 1'
    }
  }
}
