import React, { useMemo } from 'react'
import tw, { css, styled } from 'twin.macro'
import { Layer, Popup, Source } from 'react-map-gl'
import { useLocations } from '../../contexts/LocationsContext'
import { featureCollection } from '@turf/helpers'
import { mapLayers } from '../Map/MapLayers'
import { format } from 'date-fns'
import { isJsonParsable } from '../../utility/validationFunctions'
import Icon from '../../styles/Icons'
import { LocationCellType } from '../../enums'
import { useAreas } from '../../contexts/AreasContext'
import { useUsa } from '../../contexts/UsaCellsContext'

export const LocationCells = ({ hoveredCell = null }) => {
  const {
    candidateCellData,
    dragging,
    locationCellData,
    playableCellData,
    s2Cells = []
  } = useLocations()
  const { activeCountry } = useAreas()
  const { stateCells } = useUsa()

  const showCells = useMemo(() => activeCountry?.id !== 'us', [activeCountry]) // Hide these if we're in the us

  return (
    <>
      <Source type="geojson" data={candidateCellData} generateId={true} id="candidate-cells">
        <Layer {...mapLayers.candidate.cell.main} />
        <Layer {...mapLayers.candidate.cell.border} />
      </Source>

      <Source
        type="geojson"
        data={showCells ? locationCellData : featureCollection(stateCells)}
        generateId={true}
        id="location-cells"
      >
        <Layer {...mapLayers.location.cell.main} />
        <Layer {...mapLayers.location.cell.border} />
      </Source>

      <Source type="geojson" data={playableCellData} id="playable-cells">
        <Layer {...mapLayers.playableCell.main} beforeId="location-cell" />
        <Layer {...mapLayers.playableCell.border} beforeId="location-cell" />
      </Source>

      <Source type="geojson" data={featureCollection(s2Cells)} generateId={true} id="s2-cells">
        <Layer {...mapLayers.s2Cell.main} />
        <Layer {...mapLayers.s2Cell.border} />
      </Source>
      {!dragging && hoveredCell && <HoveredCellInfo cell={hoveredCell} />}
    </>
  )
}

export const HoveredCellInfo = ({ cell, reviewing = false }) => {
  function RenderInfo(cell) {
    switch (cell.type) {
      case LocationCellType.CANDIDATE:
      case LocationCellType.LOCATION: {
        const { fetch, hasCandidates, requestedAt } = cell
        const fetchOptions = isJsonParsable(fetch) && JSON.parse(fetch).join(', ')
        return (
          <>
            {fetchOptions && (
              <HeaderRow hasCandidates={hasCandidates}>{fetchOptions.toUpperCase()}</HeaderRow>
            )}
            <Content data-testid="cell-content">
              {reviewing ? (
                <Row tw="font-semibold text-base">{'Click to Fetch Candidates'}</Row>
              ) : (
                <Row tw="font-semibold text-base">
                  {hasCandidates ? 'Has Locations + Candidates' : 'Has Only Locations'}
                </Row>
              )}
              {requestedAt && <Row>{format(new Date(requestedAt), 'LLL do, HH:mm')}</Row>}
            </Content>
          </>
        )
      }
      case LocationCellType.REVEIEWED: {
        return (
          <Content tw="p-5">
            <Row tw="font-semibold text-base">All Done!</Row>
            <Row>
              <Icon.Check size="xl" tw="text-green" />
            </Row>
          </Content>
        )
      }
      case LocationCellType.S2: {
        return (
          <Content tw="p-5">
            <Row tw="font-semibold text-base">Click to remove</Row>
          </Content>
        )
      }
    }
  }

  return (
    <>
      <StyledPopup
        latitude={cell.lngLat.lat}
        longitude={cell.lngLat.lng}
        closeButton={false}
        maxWidth="none"
        offset={[0, -70]}
        type={cell.type}
      >
        {RenderInfo(cell)}
      </StyledPopup>
    </>
  )
}

const StyledPopup = styled(Popup)`
  ${({ type }) => css`
    & > .mapboxgl-popup-content {
      ${tw`background[none] shadow-none p-1 font-sans `}
    }
    & > .mapboxgl-popup-tip {
      ${tw`hidden`}
    }
    & > .mapboxgl-popup-content > div {
      ${tw`relative flex flex-col w-full rounded-lg shadow-lg text-white-pure font-mono overflow-hidden`}
      ${type === LocationCellType.REVEIEWED
        ? tw`bg-norway-900`
        : type === LocationCellType.CANDIDATE
        ? tw`bg-gold-600`
        : type === LocationCellType.LOCATION
        ? tw`bg-matrix-500`
        : type === LocationCellType.S2
        ? tw`bg-bismark-600`
        : tw`bg-bismark-400`}
    }
  `}
`
const Content = tw.div`flex flex-col p-3 gap-1`
const Row = tw.div`flex justify-center`
const HeaderRow = styled(Row)`
  ${tw`w-full p-2`}
  ${({ hasCandidates }) => (hasCandidates ? tw`bg-gold-700` : tw`bg-matrix-600`)}
`
