import React, { useEffect, useState } from 'react'
import tw, { theme } from 'twin.macro'
import { useSeason } from '../../contexts/SeasonsContext'
import { Role, SeasonSorts } from '../../enums'
import useFilter from '../../hooks/useFilter'
import useSort from '../../hooks/useSort'
import { Action, List } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button, Card } from '../../styles/Layout'
import RBAC from '../RBAC'
import { SeasonFilter } from './SeasonFilter'
import { SeasonItems } from './SeasonItems'

export const SeasonList = () => {
  const { addNewSeason, seasons } = useSeason()
  const { applyFilters, ...filterMethods } = useFilter()
  const { applySort, sortingBy, sortListBy } = useSort(SeasonSorts.STATUS)
  const [filteredItems, setFilteredItems] = useState(seasons)

  // Filter and sort items
  useEffect(() => {
    setFilteredItems(applySort(applyFilters(seasons)))
  }, [applyFilters, applySort, seasons])

  return (
    <Card.Container header={'Open World Seasons'} color={theme`colors.amethyst`}>
      <List.Filter>
        <SeasonFilter filter={filterMethods} />
      </List.Filter>

      <Action.Header>
        <Action.Right>
          <RBAC allow={[Role.CREATOR]}>
            <Button.Primary ring size="sm" onClick={addNewSeason}>
              <Icon.Plus size="sm" mr="2" />
              {'Create New Season'}
            </Button.Primary>
          </RBAC>
          <Action.More />
        </Action.Right>
      </Action.Header>

      <List.Header>
        <CenterData
          colSpan={1}
          active={sortingBy?.sort === SeasonSorts.STATUS}
          onClick={() => sortListBy(SeasonSorts.STATUS)}
          inc={sortingBy?.inc}
        >
          Status
        </CenterData>
        <List.HeaderData
          colSpan={3}
          active={sortingBy?.sort === SeasonSorts.NAME}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(SeasonSorts.NAME)}
        >
          Name
        </List.HeaderData>
        <List.HeaderData
          colSpan={2}
          active={sortingBy?.sort === SeasonSorts.START_DATE}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(SeasonSorts.START_DATE)}
        >
          Starts
        </List.HeaderData>
        <List.HeaderData
          colSpan={2}
          active={sortingBy?.sort === SeasonSorts.END_DATE}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(SeasonSorts.END_DATE)}
        >
          Ends
        </List.HeaderData>
      </List.Header>

      <List.Container>
        {filteredItems?.length ? (
          <SeasonItems items={filteredItems} />
        ) : (
          <List.Empty>{`${
            seasons.length ? 'No filtered items...' : 'Nothing here...'
          }`}</List.Empty>
        )}
      </List.Container>
      <Card.Footer color={theme`colors.amethyst`} />
    </Card.Container>
  )
}

const CenterData = tw(List.HeaderData)`text-center`
