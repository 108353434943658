import { useCallback, useEffect, useRef, useMemo } from 'react'

export const useIsMounted = () => {
  const mountedRef = useRef(true)
  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  // isMounted callback for components
  const isMounted = useCallback(() => mountedRef.current, [])

  // Promise wrapper for async tasks
  const mounted = useMemo(() => {
    const race = (promise, onError) => {
      const newPromise = new Promise((resolve, reject) => {
        promise.then(
          (result) => {
            if (mountedRef.current) resolve(result)
          },
          (error) => {
            if (mountedRef.current) reject(error)
            else if (onError) onError(error)
            else console.error('useIsMounted', error)
          }
        )
      })
      return newPromise
    }
    return race
  }, [])

  return { isMounted, mounted }
}
