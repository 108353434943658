import React from 'react'
import tw from 'twin.macro'
import config from '../../config'
import { ATag, UserTag } from '../../styles/Link'

export const Leaderboard = ({ highScores, name }) => {
  return (
    <LeaderboardContainer>
      <LeaderboardTitle>{`Leaderboard for ${name}`}</LeaderboardTitle>
      <Grid tw="font-bold text-lg mb-2">
        <div tw="col-span-1">Score</div>
        <div tw="col-span-3">Player</div>
        <div tw="col-span-3">Participation</div>
      </Grid>
      {highScores
        ?.sort((a, b) => b.score - a.score)
        .map(({ participation, score, user }, i) => {
          return (
            <Grid key={i}>
              <div tw="flex col-span-1 gap-2">
                <span>{i + 1 + '.'}</span>
                <span>{score ?? '–'}</span>
              </div>
              <UserTag to={`/users?id=${user.id}&tab=participations`} tw="col-span-3">
                {user.id}
              </UserTag>
              <ATag
                external
                href={`${config.firebase.FIRESTORE_URL}/participations/${participation.id}`}
                tw="col-span-3"
              >
                {participation.id}
              </ATag>
            </Grid>
          )
        })}
    </LeaderboardContainer>
  )
}

const LeaderboardContainer = tw.div`flex flex-col py-6 px-10 w-full font-light`
const LeaderboardTitle = tw.div`text-xl pb-1 mb-4 border-b-2 border-gray-400`
const Grid = tw.div`grid grid-cols-7 gap-2`
