import Papa from 'papaparse'

export const parseCsvPrizes = (csvData) => {
  const { data, errors, meta } = Papa.parse(csvData, {
    header: true,
    skipEmpty: true,
    skipEmptyLines: true
  })
  if (errors.length) throw new Error(errors.map((error) => JSON.stringify(error)))
  return { data, fields: meta.fields }
}
