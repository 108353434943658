import React, { createContext, useMemo, useContext, useReducer, useState, useEffect } from 'react'
import { actionTypes, cellReducer } from '../reducers/cellReducer'
import { useAreas } from './AreasContext'
import { useLocations } from './LocationsContext'
import contains from '@turf/boolean-contains'
import { point } from '@turf/helpers'

export const UsaContext = createContext()

export function UsaProvider({ children }) {
  const { activeCountry } = useAreas()
  const { locationCellData } = useLocations()
  const [stateCells, dispatch] = useReducer(cellReducer, [])
  const [activeState, setActiveState] = useState()

  // Filter out the cells that are in the state
  useEffect(() => {
    if (activeCountry?.id !== 'us' || !activeState)
      return dispatch({ type: actionTypes.states.clear })
    const cells = locationCellData.features.filter(({ properties }) =>
      contains(
        activeState,
        point([properties.centerPoint.longitude, properties.centerPoint.latitude])
      )
    )
    dispatch({ type: actionTypes.states.set, cells })
  }, [activeCountry, activeState, locationCellData])

  const defaultValues = useMemo(
    () => ({
      activeState,
      setActiveState,
      stateCells
    }),
    [activeState, stateCells]
  )
  return <UsaContext.Provider value={defaultValues}>{children}</UsaContext.Provider>
}

export const useUsa = () => {
  const context = useContext(UsaContext)
  if (context === undefined)
    throw new Error('`useUsa` hook must be used within a `UsaProvider` component')
  return context
}
