import React from 'react'
import { Item, List } from '../../../layout/List'
import { format } from 'date-fns'
import { ATag, HvntTag } from '../../../styles/Link'
import tw from 'twin.macro'
import config from '../../../config'

export const ReservedList = ({ prize = {} }) => {
  const { reservedPrizes, validTo } = prize

  return (
    <>
      <List.Sticky>
        <List.Header>
          <ListData>Instance Id</ListData>
          <ListData>Hvnt</ListData>
          <ListData>Reserved At</ListData>
          <ListData>Valid Until</ListData>
        </List.Header>
      </List.Sticky>

      <List.Container>
        {reservedPrizes?.length ? (
          reservedPrizes.map(({ hvnt, id, reservedAt }, i) => (
            <Item.Container key={i}>
              <Item.Header>
                <ItemData>
                  {id ? (
                    <ATag
                      href={`${config.firebase.FIRESTORE_URL}/prizes/${prize.id}/reserved/${id}`}
                    >
                      {id}
                    </ATag>
                  ) : (
                    '-'
                  )}
                </ItemData>
                <ItemData>
                  {hvnt?.id ? <HvntTag to={`/hvnts?id=${hvnt.id}`}>{hvnt.id}</HvntTag> : '-'}
                </ItemData>
                <ItemData>{reservedAt ? format(reservedAt, 'LLL do yyyy, HH:mm') : '-'}</ItemData>
                <ItemData>{validTo ? format(validTo, 'LLL do yyyy, HH:mm') : '-'}</ItemData>
              </Item.Header>
            </Item.Container>
          ))
        ) : (
          <List.Empty>{'Nothing here...'}</List.Empty>
        )}
      </List.Container>
    </>
  )
}

const ListData = tw(List.HeaderData)`px-4`
const ItemData = tw(Item.HeaderData)`px-4`
