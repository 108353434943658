import React, { useCallback, useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import tw, { css, styled } from 'twin.macro'
import Icon from '../../styles/Icons'
import { Input } from '../../styles/Layout'
import { flatMap } from '../../utility/helperFunctions'

export const Section = ({ children, error, field, label, locked, required, ...props }) => {
  const data = useWatch({ name: field })

  const [valid, setValid] = useState()
  const isValidFormValue = useCallback((val) => val || val === false || val === 0, [])
  const isAllValid = useCallback(
    (arr) => (arr.length ? arr.every((val) => isValidFormValue(val)) : false),
    [isValidFormValue]
  )

  // Check values
  useEffect(() => {
    if (Array.isArray(data)) {
      const values = flatMap(data, (v) => {
        if (v && typeof v == 'object') return Object.values(v)
        return [v]
      })
      setValid(isAllValid(values))
    } else if (data && typeof data == 'object') {
      setValid(isAllValid(Object.values(data)))
    } else {
      setValid(!!isValidFormValue(data))
    }
  }, [data, isAllValid, isValidFormValue])

  return (
    <Container>
      <Status valid={valid} error={error}>
        <StatusBubble>
          {error ? (
            <Icon.Close size="sm" />
          ) : valid ? (
            <Icon.Check size="sm" />
          ) : (
            <Icon.Minus size="sm" />
          )}
        </StatusBubble>
        <StatusBar />
      </Status>
      <SectionContent>
        <Label required={required}>
          {label}
          {locked && <Icon.Lock />}
        </Label>
        <Content {...props}>{children}</Content>
      </SectionContent>
    </Container>
  )
}

const Container = tw.div`flex w-full px-2 even:bg-gray-100 even:shadow-inner odd:bg-white`
const SectionContent = tw.div`relative flex flex-col w-full`
const Content = tw.div`flex flex-col pt-4 pb-8 px-6 minHeight[9rem]`
const Label = tw(Input.Label)`pl-2 gap-1 py-3 text-lg font-semibold text-gray-700`
const StatusBubble = tw.div`relative top-2.5 flex items-center justify-center w-8 h-8 mx-2 rounded-full shadow-lg z-10 transition ease-linear duration-200 text-white`
const StatusBar = tw.div`absolute flex-1 border-l-2 h-full z-0`
const Status = styled.div`
  ${tw`relative flex flex-col items-center`}
  ${({ valid, error }) => css`
     {
      & > ${StatusBar} {
        ${error ? tw`border-matrix-400` : valid ? tw`border-norway` : tw`border-bismark-300`}
      }
      & > ${StatusBubble} {
        ${error ? tw`bg-matrix-400` : valid ? tw`bg-norway` : tw`bg-bismark-300`}
      }
    }
  `}
`
