import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  clues: {
    add: 'ADD_CLUE',
    addMany: 'ADD_MANY_CLUES',
    clear: 'CLEAR_CLUES',
    edit: 'EDIT_CLUE',
    remove: 'REMOVE_CLUE',
    set: 'SET_CLUES'
  },
  candidates: {
    add: 'ADD_CANDIDATE_CLUE',
    addMany: 'ADD_MANY_CANDIDATE_CLUES',
    clear: 'CLEAR_CANDIDATE_CLUES',
    edit: 'EDIT_CANDIDATE_CLUE',
    remove: 'REMOVE_CANDIDATE_CLUE',
    set: 'SET_CANDIDATE_CLUES'
  },
  clear: 'CLEAR_ALL_CLUES'
}

export const clueReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.clues.add: {
      const { clue } = action
      const { regular } = state
      return { ...state, regular: uniqBy([...regular, { ...clue }], 'id') }
    }
    case actionTypes.clues.addMany: {
      const { clues } = action
      const { regular } = state
      return { ...state, regular: uniqBy([...regular, ...clues], 'id') }
    }
    case actionTypes.clues.clear: {
      return { ...state, regular: [] }
    }
    case actionTypes.clues.edit: {
      const { clue } = action
      const { regular } = state
      return {
        ...state,
        regular: regular.map((item) => (item.id === clue.id ? { ...item, ...clue } : item))
      }
    }
    case actionTypes.clues.remove: {
      const { clue } = action
      const { regular } = state
      return { ...state, regular: regular.filter((item) => item.id !== clue.id) }
    }
    case actionTypes.clues.set: {
      const { clues } = action
      return { ...state, regular: clues }
    }

    case actionTypes.candidates.add: {
      const { clue } = action
      const { candidates } = state
      return { ...state, candidates: uniqBy([...candidates, { ...clue }], 'id') }
    }
    case actionTypes.candidates.addMany: {
      const { clues } = action
      const { candidates } = state
      return { ...state, candidates: uniqBy([...candidates, ...clues], 'id') }
    }
    case actionTypes.candidates.clear: {
      return { ...state, candidates: [] }
    }
    case actionTypes.candidates.edit: {
      const { clue } = action
      const { candidates } = state
      return {
        ...state,
        candidates: candidates.map((item) => (item.id === clue.id ? clue : item))
      }
    }
    case actionTypes.candidates.remove: {
      const { clue } = action
      const { candidates } = state
      return { ...state, candidates: candidates.filter((item) => item.id !== clue.id) }
    }
    case actionTypes.candidates.set: {
      const { clues } = action
      return { ...state, candidates: clues }
    }
    case actionTypes.clear: {
      return {}
    }
  }
}
