import React from 'react'
import { theme } from 'twin.macro'
import Icon from '../styles/Icons'

export const ParticipationStatusType = Object.freeze({
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  ENDED_NO_PRIZE: 'ENDED_NO_PRIZE',
  TIMEOUT: 'TIMEOUT'
})

export const ParticipationStatus = Object.freeze({
  ACTIVE: {
    icon: Object.assign((size = 'lg') => <Icon.Live color={theme`colors.green`} size={size} />),
    message: 'ACTIVE',
    cancellable: true,
    sort: 1
  },
  CANCELLED: {
    icon: Object.assign((size = 'lg') => <Icon.Close color={theme`colors.red`} size={size} />),
    message: 'CANCELLED',
    sort: 2
  },
  COMPLETED: {
    icon: Object.assign((size = 'lg') => <Icon.Flag color={theme`colors.gray.600`} size={size} />),
    message: 'COMPLETED',
    sort: 3
  },
  ENDED_NO_PRIZE: {
    icon: Object.assign((size = 'lg') => <Icon.Gift color={theme`colors.gray.600`} size={size} />),
    message: 'ENDED_NO_PRIZE',
    sort: 4
  },
  TIMEOUT: {
    icon: Object.assign((size = 'lg') => <Icon.Clock color={theme`colors.gray.600`} size={size} />),
    message: 'TIMEOUT',
    sort: 5
  },
  UNSET: {
    icon: Object.assign((size = 'lg') => (
      <Icon.DotsHorizontal color={theme`colors.gray.600`} size={size} />
    )),
    message: 'UNSET',
    sort: 6
  }
})

export const ParticipationSorts = Object.freeze({
  HVNT: (arr, inc) =>
    arr.sort((a, b) =>
      inc ? a.hvnt?.name?.localeCompare(b.hvnt?.name) : b.hvnt?.name?.localeCompare(a.hvnt?.name)
    ),
  ENDED_AT: (arr, inc) =>
    arr.sort((a, b) => {
      var aHas = a.completedAt ?? a.endedAt ?? a.timedOutAt ?? a.cancelledAt
      var bHas = b.completedAt ?? b.endedAt ?? b.timedOutAt ?? b.cancelledAt
      if (!!aHas && !!bHas) return inc ? bHas - aHas : aHas - bHas
      return aHas ? -1 : bHas ? 1 : 0
    }),
  STARTED_AT: (arr, inc) =>
    arr.sort((a, b) => {
      var aHas = !!a.startedAt
      var bHas = !!b.startedAt
      if (aHas && bHas) return inc ? b.startedAt - a.startedAt : a.startedAt - b.startedAt
      return aHas ? -1 : bHas ? 1 : 0
    }),
  STATUS: (arr, inc) =>
    arr.sort((a, b) => (inc ? a.status?.sort - b.status?.sort : b.status?.sort - a.status?.sort))
})
