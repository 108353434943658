import React, { useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { objectIsEmpty } from '../../utility/validationFunctions'
import useBreakpoint from '../../hooks/useBreakpoint'
import Icon from '../../styles/Icons'
import { Button, Status } from '../../styles/Layout'
import { Switch } from '../Layout/Switch'
import { SeasonFilters, SeasonStatus } from '../../enums'
import { ActiveFilters, FilterButton, FilterInput } from '../Filter'
import { useSeason } from '../../contexts/SeasonsContext'

export const SeasonFilter = ({ filter }) => {
  const { andFilters, clearFilters, filters, setAndFilters } = filter
  const { seasons } = useSeason()
  const { belowTarget } = useBreakpoint('md')
  const inputRef = useRef()
  const [showFilters, setShowFilters] = useState(false)

  return (
    <Container>
      <FilterModeWrapper>
        <FilterMode>
          <pre>OR</pre>
          <Switch
            color="blue"
            size="sm"
            onToggle={() => setAndFilters((v) => !v)}
            defaultChecked={andFilters}
          />
          <pre>AND</pre>
        </FilterMode>

        <div tw="flex rounded-md overflow-hidden">
          {!objectIsEmpty(filters) && (
            <Button.White
              size="sm"
              tw="m-0"
              onClick={() => {
                clearFilters()
                inputRef.current.value = ''
              }}
            >
              <Icon.Close size="sm" mr="2" />
              Clear{!belowTarget && <>&nbsp;Filters</>}
            </Button.White>
          )}
          <Button.Basic
            size="sm"
            tw="m-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? (
              <>
                <Icon.ChevronUp size="sm" mr="2" />
                Less{!belowTarget && <>&nbsp;Filters</>}
              </>
            ) : (
              <>
                <Icon.ChevronDown size="sm" mr="2" />
                More{!belowTarget && <>&nbsp;Filters</>}
              </>
            )}
          </Button.Basic>
        </div>
      </FilterModeWrapper>
      <FilterButtons>
        <Table>
          <tbody>
            <tr>
              <td>
                <FilterButton {...SeasonFilters.ACTIVE} {...filter} value={false}>
                  <Status active={false} size="sm" tw="m-1" />
                  {!belowTarget && <pre>INACTIVE</pre>}
                </FilterButton>
              </td>

              <td>
                <FilterButton {...SeasonFilters.DRAFT} {...filter} value={true}>
                  {SeasonStatus.DRAFT.icon('sm')}&nbsp;
                  {!belowTarget && <pre>{SeasonStatus.DRAFT.message}</pre>}
                </FilterButton>
              </td>
              <td>
                <FilterButton {...SeasonFilters.ENDED} {...filter} value={true}>
                  {SeasonStatus.ENDED.icon('sm')}&nbsp;
                  {!belowTarget && <pre>{SeasonStatus.ENDED.message}</pre>}
                </FilterButton>
              </td>
              <td>
                <FilterButton {...SeasonFilters.ERROR} {...filter} value={true}>
                  {SeasonStatus.ERROR.icon('sm')}&nbsp;
                  {!belowTarget && <pre>ERROR</pre>}
                </FilterButton>
              </td>
            </tr>
            <tr>
              <td>
                <FilterButton {...SeasonFilters.ACTIVE} {...filter} value={true}>
                  <Status active={true} size="sm" tw="m-1" />
                  {!belowTarget && <pre>ACTIVE</pre>}
                </FilterButton>
              </td>
              <td>
                <FilterButton {...SeasonFilters.LIVE} {...filter} value={true}>
                  {SeasonStatus.LIVE.icon('sm')}&nbsp;
                  {!belowTarget && <pre>{SeasonStatus.LIVE.message}</pre>}
                </FilterButton>
              </td>
              <td>
                <FilterButton {...SeasonFilters.UPCOMING} {...filter} value={true}>
                  {SeasonStatus.UPCOMING.icon('sm')}&nbsp;
                  {!belowTarget && <pre>{SeasonStatus.UPCOMING.message}</pre>}
                </FilterButton>
              </td>
              <td>
                <FilterButton {...SeasonFilters.ENDED} {...filter} value={false}>
                  {belowTarget && <span>!</span>}
                  {SeasonStatus.ENDED.icon('sm')}&nbsp;
                  {!belowTarget && <pre>NOT {SeasonStatus.ENDED.message}</pre>}
                </FilterButton>
              </td>
            </tr>
          </tbody>
        </Table>
      </FilterButtons>
      {showFilters && (
        <FilterInput
          {...SeasonFilters.QUERY}
          {...filter}
          placeholder="Filter (country, id, name)"
          ref={inputRef}
        />
      )}
      <ActiveFilters {...filter} items={seasons} />
    </Container>
  )
}

const Container = tw.div`flex flex-1 flex-col self-center items-center z-20 pt-2 px-2 w-full sm:w-4/5 lg:w-full xl:w-4/5 gap-1 select-none`
const Table = tw.table`table-fixed w-full`
const FilterButtons = styled.div`
  ${tw`table-fixed w-full gap-2`}
  ${({ hide }) => (hide ? tw`h-0 hidden` : tw`h-auto overflow-visible`)}
`
const FilterModeWrapper = tw.div`flex justify-between w-full items-center gap-2`
const FilterMode = tw.div`flex items-center h-full gap-2 bg-white-pure p-1 px-2 shadow-inner w-min rounded-md text-xs`
