import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import tw from 'twin.macro'
import { Map, Layer, Source } from 'react-map-gl'
import { Loader } from '../../Loader/Loader'
import config from '../../../config'
import polygons from '../../../assets/country-bboxes.json'
import { useAreas } from '../../../contexts/AreasContext'
import { useMap } from '../../../contexts/MapContext'
import { AreaMarkers } from './AreaMarkers'
import { mapLayers } from '../../Map/MapLayers'
import bbox from '@turf/bbox'
import { useMapControl } from '../../../hooks/useMapControl'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useDebounce } from 'react-use'
import { useMapLayer } from '../../../hooks/useMapLayer'

export const AreasMap = () => {
  const mapRef = useRef(null)
  const {
    adding,
    cityBeingEdited,
    countries = [],
    countryBeingEdited,
    expandedCountry,
    setAdding,
    setExpandedCountry,
    updateCity
  } = useAreas()
  const { focusedBbox, focusedMarker, focusOnFeature } = useMap()
  const { containerRef, zoomToBbox, zoomToPoint } = useMapControl(mapRef)
  const { colorCountries, colorCountryBorders, colorHoveredCountry } = useMapLayer()
  const { isMounted } = useIsMounted()

  const [busy, setBusy] = useState(true)
  const [cursor, setCursor] = useState('auto')
  const [viewstate, setViewstate] = useState({
    longitude: 18.078419,
    latitude: 59.342644,
    maxZoom: 18,
    zoom: 3
  })
  const [hoveredCountry, setHoveredCountry] = useState(null)
  const [newCountry, setNewCountry] = useState(null)
  const newCountryISO2 = useMemo(() => newCountry?.id ?? '', [newCountry])
  const hoveredCountryISO2 = useMemo(() => hoveredCountry?.id ?? '', [hoveredCountry])
  const filterActive = useMemo(() => ['in', 'ISO2', newCountryISO2], [newCountryISO2])
  const filterHover = useMemo(
    () => !cityBeingEdited && ['in', 'ISO2', hoveredCountryISO2],
    [cityBeingEdited, hoveredCountryISO2]
  )

  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  // Hovering on features
  const onHover = useCallback(({ features }) => {
    if (!features?.[0]?.properties) return
    const { properties } = features[0]

    setCursor('pointer')
    setHoveredCountry({
      longitude: properties.LON,
      latitude: properties.LAT,
      name: properties.NAME,
      id: properties.ISO2
    })
  }, [])

  const onMouseLeave = useCallback(() => {
    setCursor(cityBeingEdited ? 'crosshair' : 'auto')
    setHoveredCountry()
  }, [cityBeingEdited])

  // Clicking on features
  const onClickMap = useCallback(
    ({ features, lngLat }) => {
      if (countryBeingEdited) return

      // Add new city marker
      if (cityBeingEdited)
        return (
          adding && updateCity({ centerPoint: { latitude: lngLat.lat, longitude: lngLat.lng } })
        )

      if (!features?.length) return

      const { properties } = features[0]
      const existingCountry = countries.filter((c) => c.name === properties.NAME)[0]
      const country = polygons.features.filter((c) => c.id === properties.ISO3)[0]

      !!country && focusOnFeature(country, { maxZoom: 5 })

      if (existingCountry) {
        setAdding(false)
        setNewCountry()
        return setExpandedCountry(existingCountry)
      }

      setNewCountry({
        name: properties.NAME,
        bbox: !!country && bbox(country),
        id: properties.ISO2,
        centerPoint: { latitude: properties.LAT, longitude: properties.LON }
      })
      return setAdding(true)
    },
    [
      adding,
      cityBeingEdited,
      countries,
      countryBeingEdited,
      focusOnFeature,
      setAdding,
      setExpandedCountry,
      updateCity
    ]
  )

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedMarker) return zoomToPoint(focusedMarker)
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    400,
    [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint]
  )

  // Update cursor
  useEffect(() => {
    setCursor(adding && cityBeingEdited ? 'crosshair' : 'auto')
  }, [adding, cityBeingEdited])

  return (
    <MapContainer ref={containerRef}>
      <Loader loading={busy} text="Loading Map" />
      <Map
        {...viewstate}
        cursor={cursor}
        id="areasMap"
        interactiveLayerIds={cityBeingEdited || countryBeingEdited ? null : ['country']}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
        mapStyle={config.mapbox.ANALYTICS_MAP}
        onClick={onClickMap}
        onLoad={() => setBusy(false)}
        onMove={onMoveMap}
        onMouseMove={onHover}
        onMouseLeave={onMouseLeave}
        style={{ height: '100%', width: '100vw' }}
        ref={mapRef}
      >
        <AreaMarkers newCountry={newCountry} setNewCountry={setNewCountry} />

        {!countryBeingEdited && !cityBeingEdited && (
          <Source type="vector" url="mapbox://dennit.0haz83rx" id="countries">
            <Layer
              {...mapLayers.country.main(
                colorCountries({ activeCountry: expandedCountry, countries })
              )}
            />
            <Layer {...mapLayers.country.border(colorCountryBorders({ countries }))} />
            <Layer
              {...mapLayers.country.highlight(
                colorHoveredCountry({ activeCountry: expandedCountry, countries, newCountry })
              )}
              filter={filterHover}
            />
            <Layer {...mapLayers.country.active} filter={filterActive} />
          </Source>
        )}
      </Map>
    </MapContainer>
  )
}
const MapContainer = tw.div`relative flex overflow-hidden justify-center items-center`
