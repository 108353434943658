import React, { useEffect, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { useMap } from '../../contexts/MapContext'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useMapControl } from '../../hooks/useMapControl'
import { MapInfo } from '../../layout/Map'
import Icon from '../../styles/Icons'
import { Button } from '../../styles/Layout'
import { objectIsEmpty } from '../../utility/validationFunctions'
import { Checkbox } from '../Checkbox'

export const LayerVisibilityControl = ({ mapRef, title = 'Show Layers', ...props }) => {
  const { layerVisibility = {}, setLayerVisibility } = useMap()

  const { updateVisibility } = useMapControl(mapRef)
  const { isMounted } = useIsMounted()
  useEffect(() => {
    if (!isMounted() || !mapRef.current || objectIsEmpty(layerVisibility)) return
    Object.keys(layerVisibility).forEach((key) => {
      updateVisibility({
        layers: layerVisibility[key].layers,
        visible: layerVisibility[key].visible ?? true
      })
    })
  }, [mapRef, layerVisibility, isMounted, updateVisibility])

  // Toggle visibility based on layers
  const onChangeVisibility = useCallback(
    (key, visible) => {
      if (!mapRef) return
      setLayerVisibility((v) => {
        updateVisibility({ layers: v[key].layers, visible })
        return { ...v, [key]: { ...v[key], visible } }
      })
    },
    [mapRef, setLayerVisibility, updateVisibility]
  )

  return useMemo(
    () => (
      <ShrinkContainer>
        <Container {...props} header={title}>
          {Object.keys(layerVisibility).map((key) => (
            <ControlContainer key={key}>
              <Checkbox
                color="blue"
                checked={layerVisibility[key].visible ?? true}
                disabled={layerVisibility[key].disabled}
                label={key}
                onCheck={(c) => onChangeVisibility(key, c)}
                tw="gap-2"
              />
              {layerVisibility[key].onZoom && (
                <Button.White
                  disabled={layerVisibility[key].disabled}
                  onClick={() => layerVisibility[key].onZoom()}
                  size="sm"
                  ring
                  tw="p-0.5"
                >
                  <Icon.Maximize size="xs" />
                </Button.White>
              )}
            </ControlContainer>
          ))}
        </Container>
      </ShrinkContainer>
    ),
    [layerVisibility, onChangeVisibility, props, title]
  )
}

const ShrinkContainer = tw.div`w-min`
const Container = tw(MapInfo.Container)`gap-1.5`
const ControlContainer = tw.div`flex items-center justify-between gap-4`
