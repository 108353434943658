import React, { createContext, useCallback, useContext, useMemo, useReducer, useState } from 'react'
import { db, FieldValue, gdb } from '../lib/firebase'
import { actionTypes, clueReducer } from '../reducers/clueReducer'
import { clueConverter } from '../utility/formatClues'
import { useCheckpoint } from '../contexts/CheckpointContext'
import { Alerts, Toast } from '../utility/alerts'
import useCloudinary from '../hooks/useCloudinary'
import { AlertError, DismissError, ImageUrlError } from '../utility/customErrors'
import useGetClues from '../hooks/firebase/useGetClues'
import { useUpdateEffect } from '../hooks/useUpdateEffect'
import { ClueType } from '../enums'

const DYNAMIC_CLUES = [ClueType.STREET_VIEW]

export const ClueContext = createContext()

export const ClueProvider = (props) => {
  const { checkpointBeingEdited, setReservedClues, setEditingClue, setSubmitDisabled } =
    useCheckpoint()
  const { uploadImage } = useCloudinary()
  const [adding, setAdding] = useState(false)
  const [clues, dispatch] = useReducer(clueReducer, { regular: [], candidates: [] })
  const [clueBeingEdited, setClueBeingEdited] = useState()
  const [focusedClue, setFocusedClue] = useState()
  const { onNextCandidateClues, onNextClues } = useGetClues(dispatch)

  // Add new clue
  const addNewClue = useCallback(
    (initial) => {
      const { active, path } = checkpointBeingEdited ?? {}
      if (!path) return
      // Create new document
      const newDoc = db.collection(`${path}/clues`).doc()
      setClueBeingEdited({ active, id: newDoc.id, path: newDoc.path, ...initial })
      setAdding(true)
      setEditingClue(true)
    },
    [checkpointBeingEdited, setEditingClue]
  )

  // Start editing clue
  const startEditingClue = useCallback(
    (clue) => {
      setClueBeingEdited(clue)
      setAdding(false)
      setEditingClue(true)
    },
    [setEditingClue]
  )

  // Update edited clue
  const updateEditedClue = useCallback((values) => {
    setClueBeingEdited((c) => ({ ...c, ...values }))
  }, [])

  // Stop editing clue
  const stopEditingClue = useCallback(() => {
    setClueBeingEdited()
    setAdding(false)
    setEditingClue(false)
    setSubmitDisabled(false)
  }, [setEditingClue, setSubmitDisabled])

  // Upload clue image
  const uploadClueImage = useCallback(
    async ({ id, imageData, type }) => {
      try {
        if (!id || !imageData || !type) throw new ImageUrlError('Missing image data')
        setSubmitDisabled(true)
        const imageUrl = await uploadImage({ file: imageData, id, path: `clues/${type}s` })
        return imageUrl
      } finally {
        setSubmitDisabled(false)
      }
    },
    [setSubmitDisabled, uploadImage]
  )

  // Show clue image
  const displayImage = useCallback((clue) => {
    const { imageData, imageUrl } = clue
    if (!imageData && !imageUrl) return
    Alerts.Clue.DISPLAY_IMAGE(clue)
  }, [])

  // Save clue to firestore
  const saveClue = useCallback(
    async (clue) => {
      try {
        const { imageData, path, type } = clue
        if (!path) throw new Error('Missing path')

        const clueDoc = db.doc(path)
        setSubmitDisabled(true)

        // Upload image if needed
        if (imageData) {
          if (!type.value) throw new Error('Missing clue type')
          try {
            const newUrl = await uploadClueImage({
              id: clueDoc.id,
              imageData,
              type: type.value
            })
            clue.imageUrl = newUrl
          } catch (err) {
            throw new ImageUrlError(err.message)
          }
        }

        await clueDoc.withConverter(clueConverter).set(
          {
            ...clue,
            ...(adding && { createdAt: FieldValue.serverTimestamp() }),
            id: clueDoc.id
          },
          { merge: true }
        )
        stopEditingClue()

        return
      } finally {
        setSubmitDisabled(false)
      }
    },
    [adding, setSubmitDisabled, stopEditingClue, uploadClueImage]
  )

  // Accept clue and remove from Mercator
  const acceptClue = useCallback(
    async (clue) => {
      if (!checkpointBeingEdited || !clue) return
      try {
        setSubmitDisabled(true)

        const { active } = checkpointBeingEdited

        // Save clue to firestore
        await saveClue({ active, ...clue })

        // Remove clue from mercator
        if (clue.mercatorPath) await gdb.doc(clue.mercatorPath).delete()

        // generated image clues don't have a `mercatorPath` so will have to be removed like this for now...
        if (!clue.mercatorPath) dispatch({ type: actionTypes.candidates.remove, clue })

        stopEditingClue()
      } finally {
        setSubmitDisabled(false)
      }
    },
    [checkpointBeingEdited, saveClue, setSubmitDisabled, stopEditingClue]
  )

  // Reject clue and remove from Mercator
  const rejectClue = useCallback(
    async (clue) => {
      // If the clue was from mercator, remove it
      if (clue.mercatorPath) {
        await gdb.doc(clue.mercatorPath).delete()
      } else {
        dispatch({ type: actionTypes.candidates.remove, clue })
      }
      Toast.fire({
        title: 'Clue Rejected!',
        icon: 'success'
      })
      stopEditingClue()
    },
    [stopEditingClue]
  )

  // Checks clue requirements before saving checkpoint
  const checkClueRequirements = useCallback(async () => {
    const { regular = [] } = clues
    if (!regular.length) throw new AlertError(Alerts.Clue.MISSING_CLUES())
    if (!regular.some((clue) => clue.free)) throw new AlertError(Alerts.Clue.MISSING_FREE_CLUE())
    if (regular.length < 3) {
      const { value: ok } = await Alerts.Clue.FEW_CLUES()
      if (!ok) throw new DismissError()
    }
  }, [clues])

  // Clear all clue data
  const resetDefaults = useCallback(() => {
    dispatch({ type: actionTypes.clues.clear })
    dispatch({ type: actionTypes.candidates.clear })
    setClueBeingEdited()
    setFocusedClue()
    setAdding(false)
    setEditingClue(false)
  }, [setEditingClue])

  // Subscribe to clues
  useUpdateEffect(() => {
    if (!checkpointBeingEdited?.path) return
    // Clues
    const unsubscribeClues = db
      .collection(`${checkpointBeingEdited.path}/clues`)
      .withConverter(clueConverter)
      .onSnapshot((onNext) => onNextClues(onNext))
    if (!checkpointBeingEdited.candidate || !checkpointBeingEdited.mercatorPath) return

    // Candidate Clues
    const unsubscribeCandidates = gdb
      .collection(`${checkpointBeingEdited.mercatorPath}/clues`)
      .onSnapshot((onNext) =>
        onNextCandidateClues(onNext, {
          metadata: checkpointBeingEdited.metadata,
          path: checkpointBeingEdited.path
        })
      )
    return () => {
      unsubscribeClues()
      unsubscribeCandidates()
      // Reset values
      resetDefaults()
    }
  }, [
    checkpointBeingEdited?.candidate,
    checkpointBeingEdited?.mercatorPath,
    checkpointBeingEdited?.metadata,
    checkpointBeingEdited?.path,
    onNextCandidateClues,
    onNextClues,
    resetDefaults
  ])

  // Clear data if checkpoint is unset
  useUpdateEffect(() => {
    if (checkpointBeingEdited) return
    resetDefaults()
  }, [checkpointBeingEdited, resetDefaults])

  // Update clues that should be autmatically generated
  useUpdateEffect(() => {
    if (!checkpointBeingEdited) return setReservedClues([])
    const reserved = []
    DYNAMIC_CLUES.forEach((type) => {
      if (!clues.regular.some((clue) => clue.type?.value === type)) reserved.push(type)
    })
    setReservedClues(reserved)
  }, [checkpointBeingEdited, clues.regular, setReservedClues])

  const defaultValues = useMemo(
    () => ({
      acceptClue,
      adding,
      addNewClue,
      checkClueRequirements,
      clues,
      clueBeingEdited,
      dispatch,
      displayImage,
      focusedClue,
      rejectClue,
      saveClue,
      setFocusedClue,
      startEditingClue,
      stopEditingClue,
      updateEditedClue,
      uploadClueImage
    }),
    [
      acceptClue,
      adding,
      addNewClue,
      checkClueRequirements,
      clueBeingEdited,
      clues,
      displayImage,
      focusedClue,
      rejectClue,
      saveClue,
      startEditingClue,
      stopEditingClue,
      updateEditedClue,
      uploadClueImage
    ]
  )
  return <ClueContext.Provider value={defaultValues}>{props.children}</ClueContext.Provider>
}

// Hook
export const useClue = () => {
  const context = useContext(ClueContext)
  if (context === undefined)
    throw new Error('`useClue` hook must be used within a `ClueProvider` component')
  return context
}
