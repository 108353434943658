import uniq from 'lodash/uniq'

export const CheckpointTab = Object.freeze({
  CHECKPOINTS: 'CHECKPOINTS',
  CANDIDATES: 'CHECKPOINT_CANDIDATES',
  IMPORTED: 'CHECKPOINT_IMPORTED'
})

export const CheckpointFilters = Object.freeze({
  ACTIVE: {
    field: 'active',
    filter: (arr, value) =>
      value ? arr.filter((item) => item.active) : arr.filter((item) => !item.active)
  },
  AUTO: {
    field: 'auto',
    filter: (arr, value) =>
      value ? arr.filter((item) => item.auto) : arr.filter((item) => !item.auto)
  },
  CLUE_TYPE: {
    field: 'clue_type',
    filter: (arr, tags) =>
      arr.filter((item) => item.tags?.some((tag) => tags?.some((ref) => ref.value === tag.value)))
  },
  CUSTOM: {
    field: 'custom',
    filter: (arr, value) =>
      value ? arr.filter((item) => item.custom) : arr.filter((item) => !item.custom)
  },
  ERROR: {
    field: 'errors',
    filter: (arr) =>
      arr.filter((item) => item.errors?.length || item.clues?.some((clue) => clue.errors?.length))
  },
  IDS: {
    field: 'ids',
    filter: (arr, value) => arr.filter((item) => value?.some((ref) => ref.id === item.id))
  },
  QUERY: {
    field: 'query',
    filter: (arr, value) => {
      const filteredNames = arr.filter(
        (item) => item.name && item.name.toLowerCase().indexOf(value) !== -1
      )
      const filteredIds = arr.filter(
        (item) => item.id && item.id?.toLowerCase().indexOf(value) !== -1
      )
      const filteredUpdatedBy = arr.filter(
        (item) => item.updatedBy && item.updatedBy.toLowerCase().indexOf(value) !== -1
      )
      const filteredTags = arr.filter((item) =>
        item.tags?.some((tag) => tag.label && tag.label.indexOf(value) !== -1)
      )
      return uniq(filteredNames.concat(filteredIds).concat(filteredTags).concat(filteredUpdatedBy))
    }
  },
  TAGS: {
    field: 'tags',
    filter: (arr, tags) =>
      arr.filter((item) => item.tags?.some((tag) => tags?.some((ref) => ref.value === tag.value)))
  }
})

export const CheckpointSorts = Object.freeze({
  ACTIVE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => !!b.active - !!a.active)
      : arr?.sort((a, b) => !!a.active - !!b.active),
  CREATED: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.createdAt
      const bHas = !!b.createdAt
      if (aHas && bHas) return inc ? b.createdAt - a.createdAt : a.createdAt - b.createdAt
      return aHas ? -1 : bHas ? 1 : 0
    }),
  LOCATION_SOURCE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.locationSource?.localeCompare(b.locationSource))
      : arr?.sort((a, b) => b.locationSource?.localeCompare(a.locationSource)),
  NAME: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.name?.localeCompare(b.name))
      : arr?.sort((a, b) => b.name?.localeCompare(a.name)),
  UPDATED: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.updatedAt
      const bHas = !!b.updatedAt
      if (aHas && bHas) return inc ? b.updatedAt - a.updatedAt : a.updatedAt - b.updatedAt
      return aHas ? -1 : bHas ? 1 : 0
    })
})
