import {
  mapKeyValueToOption,
  createOption,
  createStringArray,
  conditionalDelete
} from './helperFunctions'
import {
  isImageUrl,
  isNumber,
  isStringOrNumber,
  isTimestamp,
  objectIsEmpty
} from './validationFunctions'
import { auth, db, FieldValue } from '../lib/firebase'
import { PRIZE_CATEGORY_OPTIONS, PRIZE_SUPPLIER_OPTIONS } from './labeledOptions'
import { PrizeStatus } from '../enums'

export const prizeConverter = {
  fromFirestore: (snapshot) => {
    const {
      active = true,
      archivedAt,
      category,
      createdAt,
      deliveryType,
      description,
      errors = [],
      extra,
      imageUrl,
      marketValue,
      name,
      supplier,
      tags,
      validTo
    } = snapshot.data()
    const id = snapshot.id

    const formattedPrize = {
      active,
      ...(isTimestamp(archivedAt) && { archivedAt: archivedAt.toDate() }),
      ...(isTimestamp(createdAt) && { createdAt: createdAt.toDate() }),
      deliveryType: deliveryType ?? addError({ errors, type: 'deliveryType' }),
      ...(description && { description }),
      ...(extra && { extra }),
      ...(category && {
        category:
          mapKeyValueToOption({ key: 'value', value: category, options: PRIZE_CATEGORY_OPTIONS }) ??
          createOption(category)
      }), // New unknown category, create option
      id,
      imageUrl: isImageUrl(imageUrl) ? imageUrl : addError({ errors, type: 'imageUrl' }),
      ...(!objectIsEmpty(marketValue) && {
        marketValue:
          isNumber(marketValue.value) && isStringOrNumber(marketValue.currency)
            ? { value: marketValue.value, currency: createOption(marketValue.currency) }
            : addError({ errors, type: 'marketValue' })
      }),
      name: isStringOrNumber(name) ? name : addError({ errors, type: 'name' }),
      path: snapshot.ref.path,
      supplier: supplier
        ? mapKeyValueToOption({
            key: 'label',
            value: supplier,
            options: PRIZE_SUPPLIER_OPTIONS
          }) ?? createOption(supplier) // New unknown supplier, create option
        : addError({ errors, type: 'supplier' }),
      ...(tags && { tags: tags.map(createOption) }),
      ...(isTimestamp(validTo) && { validTo: validTo.toDate() })
    }
    return { ...formattedPrize, errors }
  },

  toFirestore: (data) => {
    const {
      active,
      archivedAt,
      category,
      createdAt,
      deliveryType,
      description,
      extra,
      imageUrl,
      marketValue: { currency, value } = {},
      name,
      supplier,
      tags,
      validTo
    } = data

    return {
      active,
      category: conditionalDelete(category?.value),
      ...(!active && !archivedAt && { archivedAt: new Date() }),
      createdAt: createdAt ?? new Date(),
      deliveryType,
      ...(description && { description }),
      ...(extra && { extra }),
      imageUrl,
      ...(currency && value && { marketValue: { currency: currency.label, value: Number(value) } }),
      name,
      supplier: supplier?.label,
      tags: createStringArray(tags),
      updatedAt: FieldValue.serverTimestamp(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`),
      ...(validTo && { validTo })
    }
  }
}

export const prizeInstanceConverter = {
  fromFirestore: (snapshot) => {
    const { claimedAt, createdAt, reservedAt, hvnt, participation, prize, user, validTo, ...data } =
      snapshot.data()

    return {
      ...data, // Remaining prize instance data
      ...(isTimestamp(claimedAt) && { claimedAt: claimedAt.toDate() }),
      ...(isTimestamp(createdAt) && { createdAt: createdAt.toDate() }),
      ...(isTimestamp(reservedAt) && { reservedAt: reservedAt.toDate() }),
      ...(isTimestamp(validTo) && { validTo: validTo.toDate() }),
      ...(hvnt && { hvnt: { id: hvnt?.id, path: hvnt.ref?.path } }),
      ...(user && { user: { id: user?.id, path: user.ref?.path } }),
      ...(prize && { prize: { id: prize?.id, path: prize?.ref?.path } }),
      ...(participation && {
        participation: { id: participation?.id, path: participation?.ref?.path }
      }),
      id: snapshot.id
    }
  },

  toFirestore: (data) => {
    const { createdAt, id, prizeDoc, validTo, ...item } = data
    return {
      ...item,
      ...(validTo && { validTo }),
      createdAt: createdAt ?? new Date(),
      prize: { id: prizeDoc.id, ref: prizeDoc }
    }
  }
}

// Converts between prize to hvnt prize
export const hvntPrizeConverter = {
  // From prize
  fromPrize: (snapshot) => {
    const formattedPrize = prizeConverter.fromFirestore(snapshot)
    const { deliveryType, id, marketValue, reserved, supplier, validTo, ...prize } = formattedPrize
    return {
      ...prize,
      deliveryType,
      id,
      ...(marketValue && { marketValue: { ...marketValue, currency: marketValue.currency.value } }),
      supplier: supplier?.label,
      ...(validTo && { validTo })
    }
  },
  // From hvnt prize
  fromFirestore: (prize) => {
    const {
      deliveryType = '',
      description,
      id,
      imageUrl = '',
      locked = true, // default to true for older hvnts
      marketValue,
      name = '',
      order,
      ref,
      reservedAmount = 0,
      supplier = '',
      validTo
    } = prize

    return {
      deliveryType,
      ...(description && { description }),
      id: id ?? ref?.path.split('/')[1], // todo
      imageUrl,
      ...(marketValue && { marketValue }),
      locked,
      name,
      order,
      path: ref?.path,
      reservedAmount,
      supplier,
      ...(isTimestamp(validTo) && { validTo: validTo.toDate() })
    }
  },
  // To hvnt prize
  toFirestore: (data) => {
    const {
      deliveryType,
      description,
      id,
      imageUrl,
      locked = false,
      marketValue,
      name,
      order,
      path,
      reservedAmount,
      supplier,
      validTo
    } = data
    return {
      deliveryType,
      ...(description && { description }),
      id,
      imageUrl,
      locked,
      ...(marketValue && { marketValue }),
      name,
      order,
      ...(path && { ref: db.doc(path) }), // Create ref from path
      reservedAmount,
      supplier,
      ...(validTo && { validTo })
    }
  }
}

export const prizeStatus = ({ amount, available, claimed, errors, reserved, validTo }) => {
  // Priority order
  if (errors?.length) return PrizeStatus.ERROR
  if (!available && !claimed && !reserved) return PrizeStatus.NO_ITEMS
  if (amount - claimed === 0) return PrizeStatus.ALL_CLAIMED
  if (amount - reserved === 0) return PrizeStatus.ALL_RESERVED
  if (reserved > amount) return PrizeStatus.OVER_RESERVED // Shouldn't happen
  if (new Date(validTo) < new Date()) return PrizeStatus.EXPIRED
  if (amount - claimed > 0) return PrizeStatus.ITEMS_LEFT
}

const addError = ({ errors, type, fallback = undefined }) => {
  errors.push(type)
  return fallback
}
