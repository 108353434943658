import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  update: 'UPDATE_CHECKPOINT_LIST',
  item: {
    select: 'SELECT_CHECKPOINT',
    unselect: 'UNSELECT_CHECKPOINT',
    selectMany: 'SELECT_MANY_CHECKPOINTS',
    unselectMany: 'UNSELECT_MANY_CHECKPOINTS'
  }
}

export const checkpointListReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.update: {
      const { checkpoints } = action
      return checkpoints
    }
    case actionTypes.item.select: {
      const { checkpoint } = action
      return state.map((item) => (item.id === checkpoint.id ? { ...item, selected: true } : item))
    }
    case actionTypes.item.unselect: {
      const { checkpoint } = action
      return state.map((item) => (item.id === checkpoint.id ? { ...item, selected: false } : item))
    }
    case actionTypes.item.selectMany: {
      const { checkpoints } = action
      const selected = checkpoints.map((item) => ({ ...item, selected: true }))
      return uniqBy([...selected, ...state], 'id')
    }
    case actionTypes.item.unselectMany: {
      const { checkpoints } = action
      const selected = checkpoints.map((item) => ({ ...item, selected: false }))
      return uniqBy([...selected, ...state], 'id')
    }

    default:
      return state
  }
}
