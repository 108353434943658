import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { db } from '../lib/firebase'
import { useLocalStorage } from 'react-use'
import { countryConverter } from '../utility/formatAreas'
import { actionTypes, countryReducer } from '../reducers/countryReducer'
import { arrayToFeatures, flatMap } from '../utility/helperFunctions'
import { featureCollection } from '@turf/helpers'
import { useUser } from './UserContext'

export const AreasContext = createContext()

export function AreasProvider({ children }) {
  const { user } = useUser()
  const [activeCity, setActiveCity, removeActiveCity] = useLocalStorage('activeCity')
  const [activeCountry, setActiveCountry, removeActiveCountry] = useLocalStorage('activeCountry')
  const [countries, dispatch] = useReducer(countryReducer, [])

  const [adding, setAdding] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [cityBeingEdited, setCityBeingEdited] = useState()
  const [countryBeingEdited, setCountryBeingEdited] = useState()
  const [expandedCountry, setExpandedCountry] = useState()
  const [lockCity, setLockCity] = useState(false)

  const allCities = useMemo(() => {
    const cities = flatMap(countries, ({ cities }) => arrayToFeatures(cities).features) ?? []
    if (cities.length) return featureCollection(cities)
  }, [countries])

  // Subscribe to areas on mount
  useEffect(() => {
    // Wait for user
    if (!user) return
    const unsubscribe = db
      .collection('playable-areas')
      .withConverter(countryConverter)
      .onSnapshot((onNext) => {
        const countries = []
        onNext.docChanges().forEach(async ({ doc, type }) => {
          const country = doc.data()
          if (type === 'added') countries.push(country)
          if (type === 'modified') {
            dispatch({ type: actionTypes.edit, country })
            if (activeCountry?.id === country.id) setActiveCountry(country)
            const changedCity = country.cities?.find((city) => city.id === activeCity?.id)
            if (changedCity) setActiveCity(changedCity)
          }
          if (type === 'removed') {
            dispatch({ type: actionTypes.remove, country })
            if (country.id === activeCountry?.id) {
              removeActiveCity()
              removeActiveCountry()
            } else if (country.cities.some((city) => city.path === activeCity?.path))
              removeActiveCity()
          }
        })
        if (countries.length) dispatch({ type: actionTypes.set, countries })
      })
    return unsubscribe
  }, [
    activeCity,
    activeCountry,
    removeActiveCity,
    removeActiveCountry,
    setActiveCity,
    setActiveCountry,
    user
  ])

  // COUNTRY
  const startEditingCountry = useCallback((country) => {
    setCountryBeingEdited(country)
    setExpandedCountry(country)
  }, [])

  const stopEditingCountry = useCallback(() => {
    setCountryBeingEdited()
    setAdding(false)
  }, [])

  const updateCountry = useCallback((values) => {
    setCountryBeingEdited((c) => ({ ...c, ...values }))
  }, [])

  const expandCountry = useCallback(
    (country) => {
      if (adding || cityBeingEdited || countryBeingEdited) return
      setExpandedCountry(expandedCountry?.id !== country?.id ? country : null)
    },
    [adding, cityBeingEdited, countryBeingEdited, expandedCountry]
  )

  const saveCountry = useCallback(
    ({ id, ...country }) => {
      db.collection('playable-areas')
        .doc(id.toLowerCase())
        .withConverter(countryConverter)
        .set({ ...country, adding }, { merge: true })
      setCountryBeingEdited()
    },
    [adding]
  )

  // CITY
  const startEditingCity = useCallback((city) => {
    setCityBeingEdited(city)
  }, [])

  const stopEditingCity = useCallback(() => {
    setCityBeingEdited()
    stopEditingCountry()
    setAdding(false)
  }, [stopEditingCountry])

  const updateCity = useCallback((values) => {
    setCityBeingEdited((c) => ({ ...c, ...values }))
  }, [])

  const defaultValues = useMemo(
    () => ({
      activeCity,
      activeCountry,
      adding,
      allCities,
      cancel,
      cityBeingEdited,
      expandCountry,
      countries,
      countryBeingEdited,
      expandedCountry,
      lockCity,
      saveCountry,
      removeActiveCity,
      removeActiveCountry,
      setActiveCity,
      setActiveCountry,
      setAdding,
      setCancel,
      setCityBeingEdited,
      setExpandedCountry,
      setLockCity,
      startEditingCity,
      startEditingCountry,
      stopEditingCity,
      stopEditingCountry,
      updateCity,
      updateCountry
    }),
    [
      activeCity,
      activeCountry,
      adding,
      allCities,
      cancel,
      cityBeingEdited,
      expandCountry,
      countries,
      countryBeingEdited,
      expandedCountry,
      lockCity,
      saveCountry,
      removeActiveCity,
      removeActiveCountry,
      setActiveCity,
      setActiveCountry,
      startEditingCity,
      startEditingCountry,
      stopEditingCity,
      stopEditingCountry,
      updateCity,
      updateCountry
    ]
  )

  return <AreasContext.Provider value={defaultValues}>{children}</AreasContext.Provider>
}

export default AreasProvider

// Hook
export const useAreas = () => {
  const context = useContext(AreasContext)
  if (context === undefined)
    throw new Error('`useAreas` hook must be used within a `AreasProvider` component')
  return context
}
