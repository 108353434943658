import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  regular: {
    add: 'ADD_HVNT',
    set: 'SET_HVNTS',
    remove: 'REMOVE_HVNT',
    edit: 'EDIT_HVNT',
    clear: 'CLEAR_HVNTS'
  },
  unlisted: {
    add: 'ADD_UNLISTED_HVNT',
    set: 'SET_UNLISTED_HVNTS',
    remove: 'REMOVE_UNLISTED_HVNT',
    edit: 'EDIT_UNLISTED_HVNT',
    clear: 'CLEAR_UNLISTED_HVNTS'
  }
}

export const hvntReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    // Regular
    case actionTypes.regular.add: {
      const { hvnt } = action
      const { regular } = state
      return { ...state, regular: uniqBy([...regular, { ...hvnt }], 'id') }
    }
    case actionTypes.regular.set: {
      const { hvnts } = action
      const { regular } = state
      return { ...state, regular: uniqBy([...regular, ...hvnts], 'id') }
    }
    case actionTypes.regular.remove: {
      const { hvnt } = action
      const { regular } = state
      return { ...state, regular: regular.filter((item) => item.id !== hvnt.id) }
    }
    case actionTypes.regular.edit: {
      const { hvnt } = action
      const { regular } = state
      return {
        ...state,
        regular: regular.map((item) => (item.id === hvnt.id ? hvnt : item))
      }
    }
    case actionTypes.regular.clear: {
      return { ...state, regular: [] }
    }

    // Unlisted
    case actionTypes.unlisted.add: {
      const { hvnt } = action
      const { unlisted } = state
      return { ...state, unlisted: uniqBy([...unlisted, { ...hvnt }], 'id') }
    }
    case actionTypes.unlisted.set: {
      const { hvnts } = action
      const { unlisted } = state
      return { ...state, unlisted: uniqBy([...unlisted, ...hvnts], 'id') }
    }
    case actionTypes.unlisted.remove: {
      const { hvnt } = action
      const { unlisted } = state
      return { ...state, unlisted: unlisted.filter((item) => item.id !== hvnt.id) }
    }
    case actionTypes.unlisted.edit: {
      const { hvnt } = action
      const { unlisted } = state
      return {
        ...state,
        unlisted: unlisted.map((item) => (item.id === hvnt.id ? { ...item, ...hvnt } : item))
      }
    }
    case actionTypes.unlisted.clear: {
      return { ...state, unlisted: [] }
    }
    default:
      return state
  }
}
