import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import tw from 'twin.macro'
import Icon from '../../../styles/Icons'
import { Button, Input } from '../../../styles/Layout'
import { Checkbox } from '../../Checkbox'

export const TriviaOption = ({ index, onRemove }) => {
  const {
    control,
    formState: { errors },
    register
  } = useFormContext()

  const showExplanation = useWatch({
    name: `options[${index}].showExplanation`,
    defaultValue: false
  })

  return (
    <Container>
      <Input.Label>{`Option ${index + 1}`}</Input.Label>
      <TopWrapper>
        <DeleteButton size="sm" onClick={onRemove}>
          <Icon.Trashcan size="sm" />
        </DeleteButton>

        <Controller
          control={control}
          name={`options[${index}].correct`}
          render={({ field: { onChange, value } }) => (
            <Checkbox color="blue" label="Correct?" checked={value} onCheck={onChange} size="sm" />
          )}
        />
      </TopWrapper>

      <Row>
        <Input.Default
          {...register(`options[${index}].text`, { required: "This can't be empty" })}
        />

        <Controller
          defaultValue={false}
          name={`options[${index}].showExplanation`}
          render={({ field: { onChange, value } }) => (
            <StyledButton size="sm" onClick={() => onChange(!value)} data-testid="explanation-btn">
              {value ? <Icon.ChevronUp size="sm" /> : <Icon.Question size="sm" />}
            </StyledButton>
          )}
        />
      </Row>
      <Input.Error>{errors.options?.[index]?.text?.message}</Input.Error>

      {showExplanation && (
        <Explanation>
          <Input.Label>{'Explanation'}</Input.Label>
          <TextArea
            {...register(`options[${index}].explanation`, {
              required: "This can't be empty",
              shouldUnregister: true
            })}
            placeholder="Optional explanation"
            rows={2}
          />
          <Input.Error>{errors.options?.[index]?.explanation?.message}</Input.Error>
        </Explanation>
      )}
    </Container>
  )
}

const Container = tw.div`relative flex flex-col mb-2 bg-norway-200 rounded-md p-4 shadow-inner`
const Row = tw.div`flex`
const Explanation = tw.div`mt-2`
const TopWrapper = tw.div`absolute top-2 right-3 flex gap-3 items-center`
const StyledButton = tw(Button.Basic)`hover:bg-norway-300 active:bg-norway-400`
const DeleteButton = tw(StyledButton)`p-0.5 text-matrix`
const TextArea = tw(Input.TextArea)`resize-none overflow-hidden max-height[85px]` //prettier-ignore
