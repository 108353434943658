import React from 'react'
import tw, { styled } from 'twin.macro'
import { PageLayout } from '../../styles/Layout'
import { Link } from 'react-router-dom'

const NotAuthorizedPage = () => {
  return (
    <Page>
      <Col>
        <Container>
          <TextCol>
            <BigText>{'403'}</BigText>
            <Text>{"Can't let you in here..."}</Text>
          </TextCol>
          <Travolta src="https://res.cloudinary.com/dozsbtdha/image/upload/v1611563851/forrestfenn/lost-travolta_olrhtv.gif" />
        </Container>
        <Link to="/">
          <LinkText>{'Go back home'}</LinkText>
        </Link>
      </Col>
    </Page>
  )
}
export default NotAuthorizedPage

const Page = tw(
  PageLayout
)`flex-col lg:flex-row lg:overflow-hidden justify-center items-center font-sans w-full`
const Container = tw.div`flex flex-col-reverse lg:flex-row w-full justify-center my-20`
const Col = tw.div`flex flex-col items-center justify-start`
const TextCol = tw(Col)`items-center lg:items-end lg:mx-10`
const BigText = styled.span`
  ${tw`font-thin text-gray-900`}
  font-size: 12rem;
`
const Text = tw.span`font-light text-xl text-gray-900 mx-1`
const Travolta = tw.img`ml-10`
const LinkText = tw(Text)`text-blue-700`
