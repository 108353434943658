import React, { useCallback, useState, useEffect } from 'react'
import tw, { theme, styled, css } from 'twin.macro'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Loader } from '../Loader/Loader'
import { usePrizes } from '../../contexts/PrizeContext'
import { Button, Card } from '../../styles/Layout'
import { prizeConverter, prizeInstanceConverter } from '../../utility/formatPrizes'
import Icon from '../../styles/Icons'
import { db } from '../../lib/firebase'
import { Alerts } from '../../utility/alerts'
import useCloudinary from '../../hooks/useCloudinary'
import { useIsMounted } from '../../hooks/useIsMounted'
import { PrizeFormTab } from '../../enums'
import { MorePrizeInfo } from './MorePrizeInfo'
import { PhysicalPrizeForm } from './PhysicalPrizeForm'

export const CreatePhysicalPrize = ({ prize }) => {
  const { adding, stopEditingPrize } = usePrizes()
  const { uploadImage } = useCloudinary()
  const { mounted } = useIsMounted()
  const methods = useForm({ defaultValues: prize, mode: 'onChange' })
  const { control, handleSubmit, register, setError } = methods
  const [adjustedAmount, amount] = useWatch({ control, name: ['adjustedAmount', 'amount'] })

  const [activeFormTab, setActiveFormTab] = useState(PrizeFormTab.UPDATE)
  const [addedImage, setAddedImage] = useState()
  const [loading, setLoading] = useState(false)

  // Register fields on mount
  useEffect(() => {
    register('active')
  }, [register])

  // Save prize
  const onSubmit = useCallback(
    async (formData) => {
      // Upload image if one was added
      if (addedImage) {
        setLoading(true)
        try {
          formData.imageUrl = await mounted(
            uploadImage({
              file: formData.imageUrl,
              id: prize.id,
              path: 'prizes/'
            })
          )
        } catch (err) {
          setError('imageUrl', { type: 'manual', message: 'Image might be too big!' })
          return Alerts.Image.UPLOAD_FAILED(err)
        }
      }

      // Upload prize to firebase
      try {
        setLoading(true)
        await db.runTransaction(async (t) => {
          const prizeDoc = db.collection('prizes').withConverter(prizeConverter).doc(prize.id)
          t.set(prizeDoc, formData, { merge: true })

          // Add new prize instances to `available` collection
          if (adding && amount) {
            ;[...Array.from(Array(amount))].forEach(async () => {
              const prizeInstanceDoc = prizeDoc
                .collection('available')
                .withConverter(prizeInstanceConverter)
                .doc()

              t.set(prizeInstanceDoc, { prizeDoc })
            })
          } else if (!adding && adjustedAmount) {
            // We adjusted the amount
            const diff = Math.abs(adjustedAmount - amount)

            if (adjustedAmount > amount) {
              // We added prizes
              ;[...Array.from(Array(diff))].forEach(async () => {
                const newPrizeDoc = prizeDoc.collection('available').doc()

                t.set(newPrizeDoc, {
                  createdAt: new Date(),
                  prize: { id: prizeDoc.id, ref: prizeDoc }
                })
              })
            } else {
              // We remove prizes
              const availableDocs = await prizeDoc.collection('available').limit(diff).get()
              availableDocs.docs.forEach(({ ref }) => t.delete(ref))
            }
          }
        })
      } catch (err) {
        console.error(err)
        return Alerts.Prize.UPDATE_FAILED(err)
      } finally {
        setLoading(false)
      }

      Alerts.Prize.UPDATE_SUCCESS(adding)
      stopEditingPrize()
    },
    [
      addedImage,
      adding,
      adjustedAmount,
      amount,
      prize,
      mounted,
      setError,
      stopEditingPrize,
      uploadImage
    ]
  )

  return (
    <Card.Container
      header={adding ? 'New Physical Prize' : 'Edit Physical Prize'}
      color={theme`colors.terracotta.500`}
      xHover={theme`colors.terracotta.600`}
      xActive={theme`colors.terracotta.700`}
      onClose={stopEditingPrize}
    >
      <Loader loading={loading} text="Saving Prize" />

      {!adding && (
        <Tabs>
          <Tab
            active={activeFormTab === PrizeFormTab.UPDATE}
            onClick={() => setActiveFormTab(PrizeFormTab.UPDATE)}
          >
            Update Prize
          </Tab>
          <Tab
            active={activeFormTab === PrizeFormTab.DETAILS}
            onClick={() => setActiveFormTab(PrizeFormTab.DETAILS)}
          >
            More Details
          </Tab>
        </Tabs>
      )}

      <FormProvider {...methods}>
        <Container>
          {activeFormTab === PrizeFormTab.DETAILS ? (
            <MorePrizeInfo />
          ) : (
            <PhysicalPrizeForm flagUpload={setAddedImage} prize={prize} />
          )}
        </Container>

        <Card.Footer tw="bg-terracotta">
          <Button.White ring onClick={stopEditingPrize} tw="mr-auto">
            <Icon.Close mr="2" />
            Cancel
          </Button.White>
          <Button.Submit ring onClick={handleSubmit(onSubmit)}>
            <Icon.Check mr="2" />
            Save Prize
          </Button.Submit>
        </Card.Footer>
      </FormProvider>
    </Card.Container>
  )
}

const Container = tw.div`flex flex-col h-full w-full overflow-y-scroll`
const Tabs = tw.div`flex w-full pb-0 bg-terracotta-600 gap-2 first:pl-2 select-none`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer text-center rounded-t-md text-lg tracking-wide`}
  ${({ active }) => css`
    ${active ? tw`bg-gray-200` : tw`text-terracotta-200 hover:bg-terracotta-700 `}
  `}
`
