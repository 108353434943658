import React from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useAreas } from '../../../contexts/AreasContext'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { Action } from '../../../layout/List'
import Icon from '../../../styles/Icons'
import { Button, Info } from '../../../styles/Layout'

export const CandidateInfo = () => {
  const { candidateCells } = useCheckpoint()
  const { activeCity } = useAreas()
  const navigate = useNavigate()

  return (
    <Container>
      <Action.Header>
        <Button.White size="sm" ring onClick={() => navigate('/locations')}>
          <Icon.Sparkles size="sm" mr="2" />
          Visit Locations Page
        </Button.White>
        <Action.More />
      </Action.Header>

      <Content>
        {candidateCells?.length ? (
          <Info>
            <span>Click on one or more cells to fetch candidates!</span>
            <span tw="font-semibold mt-2">
              {`Note that these will be added to ${activeCity.name}`}
            </span>
          </Info>
        ) : (
          <Info>No candidate cells...</Info>
        )}
      </Content>
    </Container>
  )
}

const Container = tw.div`flex flex-col items-center w-full h-full`
const Content = tw.div`py-12`
