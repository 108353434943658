import React, { useCallback, useEffect, useReducer, useState } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Card } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { Loader } from '../Loader/Loader'
import RBAC from '../RBAC'
import { useIsMounted } from '../../hooks/useIsMounted'
import { Alerts } from '../../utility/alerts'
import { useCorporate } from '../../contexts/CorporateContext'
import { CreateEventMap } from './Map/EventLocationMap'
import { EventForm } from './EventForm'
import { LocationForm } from './Location/LocationForm'
import useCloudinary from '../../hooks/useCloudinary'
import { AddLocation } from './Location/AddLocation'
import { db } from '../../lib/firebase'
import { eventLocationConverter, setNextStatus } from '../../utility/formatEvents'
import { eventLocationReducer } from '../../reducers/eventReducer'
import useGetCorporateEvents from '../../hooks/firebase/useGetCorporateEvents'
import { Action } from '../../layout/List'
import { EventFormTab, EventStatus, LocationOptions } from '../../enums'

export const CreateEvent = ({ event = {} }) => {
  const {
    activeFormTab,
    adding,
    deleteEvent,
    loading,
    locationBeingEdited,
    saveEvent,
    setActiveFormTab,
    setLoading,
    setValidateEvent,
    stopEditingEvent,
    validateEvent
  } = useCorporate()

  const [locations, dispatch] = useReducer(eventLocationReducer, [])
  const { mounted } = useIsMounted()
  const { uploadImage } = useCloudinary()
  const { onNextEventLocations } = useGetCorporateEvents(dispatch)
  const methods = useForm({
    defaultValues: {
      ...merge(
        {
          minDistance: 40,
          roundDuration: 60
        },
        {
          ...omit(event, ['errors', 'locations', 'path', 'updatedAt'])
        }
      )
    },
    mode: 'onBlur'
  })

  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    watch
  } = methods

  const { active, dateRange } = watch()
  const [addedImage, setAddedImage] = useState(false)
  const activeChanged = active ? !event.active : event.active
  const canDeleteEvent = !adding && event.status?.removable
  const locked = event.status?.locked
  const currentStatus = adding ? (active ? EventStatus.UPCOMING : EventStatus.DRAFT) : event.status
  const nextStatus = adding
    ? ''
    : setNextStatus({ active, dateRange }) === event.status
    ? ''
    : setNextStatus({ active, dateRange })
  const showDetails = activeFormTab === EventFormTab.DETAILS
  const showHeader = !locationBeingEdited

  const formErrors =
    errors.category ||
    errors.dateRange ||
    errors.description ||
    errors.imageUrl ||
    errors.name ||
    errors.prizes

  const onSubmit = useCallback(
    async (formData) => {
      // Check location requirements
      if (validateEvent && missingLocations(locations)) {
        await Alerts.Event.MISSING_LOCATIONS()
        return setActiveFormTab(EventFormTab.LOCATIONS)
      }

      // Show activation information
      if (formData.active && !event.active) {
        const { value: ok } = await mounted(Alerts.Event.ACTIVATE_INFO())
        if (!ok) return
      }
      // Show deactivation information
      if (!formData.active && event.active) {
        if (event.status === EventStatus.UPCOMING) {
          const { value: ok } = await mounted(Alerts.Event.DEACTIVATE_UPCOMING_INFO())
          if (!ok) return
        }
      }

      // Upload image
      if (addedImage) {
        setLoading(true)
        try {
          formData.imageUrl = await uploadImage({
            file: formData.imageUrl,
            id: event.id,
            path: 'corporate-events/'
          })
        } catch (err) {
          setError('imageUrl', { type: 'manual', message: 'Image might be too big!' })
          setLoading(false)
          return Alerts.Image.UPLOAD_FAILED(err)
        }
      }

      // Save the event
      await saveEvent({ ...event, ...formData })
      setLoading(false)
    },
    [
      addedImage,
      event,
      locations,
      mounted,
      saveEvent,
      setActiveFormTab,
      setError,
      setLoading,
      uploadImage,
      validateEvent
    ]
  )

  // Activate and require validation
  const onActivateEvent = useCallback(async () => {
    setValidateEvent(true)
    setValue('active', true)
  }, [setValidateEvent, setValue])

  // Deactivate and skip validation
  const onDeactivateEvent = useCallback(async () => {
    setValidateEvent(false)
    setValue('active', false)
  }, [setValidateEvent, setValue])

  // Delete event if possible
  const onDeleteEvent = useCallback(async () => {
    const { value: remove } = await mounted(Alerts.Event.DELETE_WARNING())
    if (!remove) return

    await deleteEvent(event)
  }, [event, deleteEvent, mounted])

  // Register fields on mount
  useEffect(() => {
    register('active')
    if (!validateEvent) clearErrors()
  }, [clearErrors, register, validateEvent])

  // Fetch locations on mount
  useEffect(() => {
    const unsubscribe = db
      .collection(`corporate-events/${event.id}/locations`)
      .withConverter(eventLocationConverter)
      .onSnapshot((onNext) => onNextEventLocations(onNext))
    return unsubscribe
  }, [event.id, onNextEventLocations])

  return (
    <Container>
      <FormContainer>
        <Card.Container
          header={adding ? 'Create New Event' : `Edit ${event.name ?? 'Event'}`}
          color={theme`colors.terracotta.500`}
          xHover={theme`colors.terracotta.600`}
          xActive={theme`colors.terracotta.700`}
          onClose={stopEditingEvent}
        >
          <Loader loading={loading} />
          {showHeader && (
            <>
              <Tabs>
                <Tab
                  active={activeFormTab === EventFormTab.DETAILS}
                  error={formErrors}
                  onClick={() => setActiveFormTab(EventFormTab.DETAILS)}
                >
                  Details
                </Tab>
                <Tab
                  active={activeFormTab === EventFormTab.LOCATIONS}
                  onClick={() => setActiveFormTab(EventFormTab.LOCATIONS)}
                >
                  Locations
                </Tab>
              </Tabs>

              <Action.Header>
                <Action.Active active={active} changed={activeChanged} />
                <Action.Status status={currentStatus} nextStatus={nextStatus} tw="ml-auto" />
                <Action.More
                  content={
                    <>
                      {active ? (
                        <Button.Primary
                          size="sm"
                          onClick={onDeactivateEvent}
                          disabled={loading}
                          ring
                        >
                          <Icon.Moon size="sm" mr="2" />
                          Deactivate Event
                        </Button.Primary>
                      ) : (
                        <Button.Secondary
                          size="sm"
                          onClick={onActivateEvent}
                          disabled={loading}
                          ring
                        >
                          <Icon.Sun size="sm" mr="2" />
                          Activate Event
                        </Button.Secondary>
                      )}
                      <RBAC>
                        <Button.Warning
                          size="sm"
                          onClick={onDeleteEvent}
                          disabled={!canDeleteEvent || loading}
                          ring
                        >
                          <Icon.Trashcan size="sm" mr="2" />
                          Delete Event
                        </Button.Warning>
                      </RBAC>
                    </>
                  }
                />
              </Action.Header>

              <Action.Header color={theme`colors.gray.300`} tw="z-10">
                <pre>{`Entry Code: ${event.entryCode ?? '-'}`}</pre>
              </Action.Header>
            </>
          )}

          {showDetails ? (
            <FormProvider {...methods}>
              <EventForm flagUpload={setAddedImage} loading={loading} locked={locked} />
            </FormProvider>
          ) : locationBeingEdited ? (
            <AddLocation {...locationBeingEdited} />
          ) : (
            <FormProvider {...methods}>
              <LocationForm locations={locations} locked={locked} />
            </FormProvider>
          )}

          <Footer>
            <Button.White onClick={stopEditingEvent} ring tw="mr-auto">
              <Icon.Close mr="2" />
              Cancel
            </Button.White>
            {active ? (
              <Button.Submit
                onClick={handleSubmit(onSubmit)}
                disabled={loading || locationBeingEdited}
                ring
              >
                <Icon.Check mr="2" />
                Save Event
              </Button.Submit>
            ) : (
              <Button.Primary
                onClick={handleSubmit(onSubmit)}
                disabled={loading || locationBeingEdited}
                ring
              >
                <Icon.Download mr="2" />
                Save Draft
              </Button.Primary>
            )}
          </Footer>
        </Card.Container>
      </FormContainer>
      <MapContainer>
        <CreateEventMap locations={locations} locked={locked} />
      </MapContainer>
    </Container>
  )
}

const missingLocations = (locations) => {
  const amount = { AR: 0, HIDDEN: 0, LOGO: 0, TRIVIA: 0 }
  locations.forEach(({ type }) => (amount[type] += 1))
  if (Object.keys(amount).some((type) => amount[type] < LocationOptions[type].numberOfLocations))
    return true
  return false
}

const FormContainer = tw.div`flex flex-col h-full transition-all duration-300`
const MapContainer = tw.div`flex justify-center overflow-hidden h-full minHeight[50vh]`
const Container = styled.div`
  ${tw`flex flex-col lg:flex-row h-full w-full transition-all overflow-scroll`}
  & > ${FormContainer} {
    ${tw`w-full lg:w-1/2`}
  }
  & > ${MapContainer} {
    ${tw`w-full lg:w-1/2 lg:p-0`}
  }
`
const Footer = tw(Card.Footer)`z-30 gap-2 py-3 bg-terracotta-500`
const Tabs = tw.div`flex w-full pb-0 bg-terracotta-600 gap-2 first:pl-2`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[100px] text-center rounded-t-md text-lg tracking-wide`}
  ${({ active, error }) => css`
    ${active ? tw`bg-gray-200` : tw`text-terracotta-100 bg-terracotta-600 hover:bg-terracotta-700`}
    ${error && tw`ring-4 ring-red`}
  `}
`
