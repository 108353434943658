import React, { useMemo } from 'react'
import { Popup } from 'react-map-gl'
import tw, { css, styled } from 'twin.macro'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { Role } from '../../../enums'
import Icon from '../../../styles/Icons'
import { Button, Status } from '../../../styles/Layout'
import { isJsonParsable } from '../../../utility/validationFunctions'
import RBAC from '../../RBAC'

export const CheckpointsPopups = ({ hoveredItem }) => {
  const { focusedItem } = useCheckpoint()
  const hoveringFocusedCandidate = useMemo(
    () => hoveredItem?.candidate?.id === focusedItem?.candidate?.id,
    [focusedItem?.candidate?.id, hoveredItem?.candidate?.id]
  )
  const hoveringFocusedCheckpoint = useMemo(
    () => hoveredItem?.checkpoint?.id === focusedItem?.checkpoint?.id,
    [focusedItem?.checkpoint?.id, hoveredItem?.checkpoint?.id]
  )

  return useMemo(
    () => (
      <>
        <CluePopup clue={hoveredItem?.clue} />

        <CheckpointPopup checkpoint={focusedItem?.checkpoint} focused={true} />
        {!hoveringFocusedCheckpoint && <CheckpointPopup checkpoint={hoveredItem?.checkpoint} />}

        <CandidatePopup candidate={focusedItem?.candidate} focused={true} />
        {!hoveringFocusedCandidate && <CandidatePopup candidate={hoveredItem?.candidate} />}
      </>
    ),
    [focusedItem, hoveringFocusedCandidate, hoveringFocusedCheckpoint, hoveredItem]
  )
}

const CheckpointPopup = ({ checkpoint, focused }) => {
  const { reviewing, setFocusedItem, startEditingCheckpoint } = useCheckpoint()
  const { active = true, centerPoint, name } = checkpoint ?? {}
  const { latitude, longitude } = centerPoint
    ? isJsonParsable(centerPoint)
      ? JSON.parse(centerPoint)
      : centerPoint
    : {}

  return (
    <>
      {checkpoint && (
        <StyledPopup
          anchor="bottom-left"
          closeButton={false}
          onClose={() => focused && setFocusedItem()}
          closeOnClick={focused}
          dynamicPosition={true}
          latitude={latitude}
          longitude={longitude}
          sortByDepth={true}
          offset={[5, -10]}
          type="checkpoint"
        >
          <Row>
            <Status active={active} />
            <Text>{name}</Text>
          </Row>
          <RBAC allow={[Role.CREATOR, Role.EDITOR]}>
            <>
              {focused && !reviewing && (
                <EditButton size="sm" onClick={() => startEditingCheckpoint(checkpoint)}>
                  <Icon.Edit mr="2" size="sm" />
                  Edit
                </EditButton>
              )}
            </>
          </RBAC>
        </StyledPopup>
      )}
    </>
  )
}

const CandidatePopup = ({ candidate, focused }) => {
  const { reviewing, startReviewingCandidate } = useCheckpoint()
  const { centerPoint, name } = candidate ?? {}
  const { latitude, longitude } = centerPoint
    ? isJsonParsable(centerPoint)
      ? JSON.parse(centerPoint)
      : centerPoint
    : {}

  return (
    <>
      {candidate && (
        <StyledPopup
          anchor="bottom-left"
          closeButton={false}
          dynamicPosition={true}
          latitude={latitude}
          longitude={longitude}
          offset={[5, -10]}
          sortByDepth={true}
          type="candidate"
        >
          <Row>
            <Text>{name}</Text>
          </Row>
          <RBAC allow={[Role.CREATOR, Role.EDITOR]}>
            <>
              {focused && !reviewing && (
                <EditButton size="sm" onClick={() => startReviewingCandidate(candidate)}>
                  <Icon.Clipboard mr="2" size="sm" />
                  Review
                </EditButton>
              )}
            </>
          </RBAC>
        </StyledPopup>
      )}
    </>
  )
}

const CluePopup = ({ clue }) => {
  const { centerPoint, place, text } = clue ?? {}
  const { latitude, longitude } = centerPoint
    ? isJsonParsable(centerPoint)
      ? JSON.parse(centerPoint)
      : centerPoint
    : {}

  return (
    <>
      {clue && (
        <StyledPopup
          anchor="bottom-left"
          closeButton={false}
          dynamicPosition={true}
          latitude={latitude}
          longitude={longitude}
          offset={[5, -10]}
          sortByDepth={true}
          type="clue"
        >
          <Header>{place}</Header>
          <Text>{text}</Text>
        </StyledPopup>
      )}
    </>
  )
}

const StyledPopup = styled(Popup)`
  ${({ type }) => css`
    & > .mapboxgl-popup-content {
      ${tw`background[none] shadow-none p-1 font-sans max-width[200px]`}
    }
    & > .mapboxgl-popup-tip {
      ${tw`hidden`}
    }
    & > .mapboxgl-popup-content > div {
      ${tw`flex flex-col gap-1 p-1 rounded-lg rounded-bl-none shadow-lg text-white-pure`}
      ${type === 'candidate'
        ? tw`bg-norway-700`
        : type === 'clue'
        ? tw`bg-amethyst`
        : tw`bg-bismark`}
    }
  `}
`
const Row = tw.div`flex items-center gap-3 p-2`
const Text = tw.span`font-sans font-light text-sm text-center`
const Header = tw(Text)`p-0.5 font-bold w-full rounded-t-md bg-amethyst-700 text-base`
const EditButton = tw(Button.Secondary)`m-0 rounded-none w-full py-0.5`
