import React, { useEffect } from 'react'
import { Route, Routes, useNavigate, Outlet, useSearchParams, useLocation } from 'react-router-dom'
import tw, { css, styled } from 'twin.macro'
import { HvntList } from '../Hvnt/HvntList'
import { HvntsMap } from '../Hvnt/HvntsMap'
import { useHvnt } from '../../contexts/HvntContext'
import { PageLayout } from '../../styles/Layout'
import { useAreas } from '../../contexts/AreasContext'
import { useUser } from '../../contexts/UserContext'
import { CreateHvnt } from '../Hvnt/CreateHvnt'
import { Role } from '../../enums'
import { useHvntList } from '../../hooks/useHvntList'

const HvntsPage = () => {
  const { activeCity } = useAreas()
  const { userHasRole } = useUser()
  const { adding, hvntBeingEdited } = useHvnt()
  const { visitDeepRoute } = useHvntList()
  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  // Visit deep route
  useEffect(() => {
    const id = params.get('id')
    const edit = params.get('edit')
    if (!id) return

    visitDeepRoute({ edit, id })
  }, [location, params, visitDeepRoute])

  // Update routes through side effects
  useEffect(() => {
    if (!activeCity?.name) return navigate('', { replace: true })
    if (!hvntBeingEdited) navigate(activeCity.name, { replace: true })
  }, [activeCity, adding, hvntBeingEdited, navigate])

  return (
    <PageLayout>
      <Routes>
        <Route
          path="/"
          element={
            <Container expand={activeCity && userHasRole([Role.CREATOR, Role.EDITOR])}>
              <Outlet />
              <MapContainer>
                <HvntsMap />
              </MapContainer>
            </Container>
          }
        >
          <Route
            path=":city"
            element={
              <ListContainer>
                <HvntList />
              </ListContainer>
            }
          ></Route>
        </Route>
        <Route path="/:city/*" element={<CreateHvnt hvnt={hvntBeingEdited} />} />
      </Routes>
    </PageLayout>
  )
}

export default HvntsPage

const ListContainer = tw.div`flex flex-col h-full transition-all duration-300`
const MapContainer = tw.div`flex justify-center overflow-hidden minHeight[50vh] h-full transition-all duration-300`
const Container = styled.div`
  ${tw`flex flex-col lg:flex-row w-full`}
  ${({ expand = false }) => css`
     {
      & > ${ListContainer} {
        ${expand ? tw`w-full lg:w-1/2` : tw`h-0 w-0`}
      }
      & > ${MapContainer} {
        ${expand ? tw`w-full lg:w-1/2` : tw`w-full`}
      }
    }
  `}
`
