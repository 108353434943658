import React from 'react'
import tw from 'twin.macro'
import { useFormContext } from 'react-hook-form'
import { Info, Input } from '../../styles/Layout'

export const TextForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      <Section>
        <Input.Label required={true}>Text</Input.Label>
        <div tw="flex">
          <Input.TextArea
            {...register('text', { required: 'This is required' })}
            rows="3"
          ></Input.TextArea>
        </div>
        <Input.Error>{errors?.text?.message}</Input.Error>
      </Section>
      <Section>
        <Info>{'You may use line breaks ↵, but adding too many many look weird in the app!'}</Info>
      </Section>
    </>
  )
}
const Section = tw.div`flex flex-col my-2 px-4 w-full`
