import React, { createContext, useState, useMemo, useCallback, useContext } from 'react'
import bbox from '@turf/bbox'
import circle from '@turf/circle'

export const MapContext = createContext()

export function MapProvider({ children }) {
  const [focusedMarker, setFocusedMarker] = useState()
  const [focusedBbox, setFocusedBbox] = useState()
  const [hoveredItem, setHoveredItem] = useState()
  const [layerVisibility, setLayerVisibility] = useState()
  // Focus
  const focusOnBbox = useCallback((bbox, options = {}) => {
    if (!bbox) return console.log('missing bbox', bbox)
    const { duration = 1000, maxZoom, minZoom, padding } = options

    setFocusedMarker()
    setFocusedBbox({ bbox, options: { duration, maxZoom, minZoom, padding } })
  }, [])

  const focusOnPoint = useCallback((point = {}, options = {}) => {
    const { centerPoint: { longitude, latitude } = {}, id } = point
    if (!longitude || !latitude) return console.log('missing centerPoint', point)

    const { duration = 1000, maxZoom = 18, minZoom = 16 } = options
    setFocusedBbox()
    setFocusedMarker({
      centerPoint: { longitude, latitude },
      options: { duration, id, maxZoom, minZoom }
    })
  }, [])

  // Helpers
  const focusOnCircle = useCallback(
    ({ centerPoint = {}, radius }, options = {}) => {
      const { latitude, longitude } = centerPoint
      if (!latitude || !longitude || !radius)
        return console.log('missing centerPoint or radius', centerPoint, radius)

      focusOnBbox(bbox(circle([longitude, latitude], radius, { units: 'meters' })), options)
    },
    [focusOnBbox]
  )

  const focusOnFeature = useCallback(
    (feature, options = {}) => {
      if (!feature) return console.log('missing feature', feature)
      focusOnBbox(bbox(feature), options)
    },
    [focusOnBbox]
  )

  const focusOnFeatures = useCallback(
    (obj = {}, options = {}) => {
      if (!obj?.features?.length) return
      focusOnBbox(bbox(obj), options)
    },
    [focusOnBbox]
  )

  const clearFocus = useCallback(() => {
    setFocusedBbox()
    setFocusedMarker()
    setHoveredItem()
  }, [])

  const defaultValues = useMemo(
    () => ({
      clearFocus,
      focusedBbox,
      focusedMarker,
      focusOnBbox,
      focusOnCircle,
      focusOnFeature,
      focusOnFeatures,
      focusOnPoint,
      hoveredItem,
      layerVisibility,
      setFocusedBbox,
      setFocusedMarker,
      setHoveredItem,
      setLayerVisibility
    }),
    [
      clearFocus,
      focusedBbox,
      focusedMarker,
      focusOnBbox,
      focusOnCircle,
      focusOnFeature,
      focusOnFeatures,
      focusOnPoint,
      hoveredItem,
      layerVisibility
    ]
  )
  return <MapContext.Provider value={defaultValues}>{children}</MapContext.Provider>
}

// Hook
export const useMap = () => {
  const context = useContext(MapContext)
  if (context === undefined)
    throw new Error('`useMap` hook must be used within a `MapProvider` component')
  return context
}
