import React from 'react'
import config from '../../../config'
import { useChallenge } from '../../../contexts/ChallengeContext'
import { Item } from '../../../layout/List'
import Icon from '../../../styles/Icons'
import { Button, Status } from '../../../styles/Layout'
import { ATag } from '../../../styles/Link'
import { ChallengeSummary } from './ChallengeSummary'

export const ChallengeItem = ({ expanded, item, onClick }) => {
  const { active, id, path, question, type } = item
  const { startEditingChallenge } = useChallenge()

  return (
    <Item.Container>
      <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
        <Item.HeaderData colSpan={1}>
          <Item.Center>
            <Status active={active} />
          </Item.Center>
        </Item.HeaderData>
        <Item.HeaderData colSpan={1}>{type}</Item.HeaderData>
        <Item.HeaderData colSpan={5}>{question}</Item.HeaderData>
      </Item.Header>

      <Item.Summary expanded={expanded}>
        <Item.ActionButtons>
          <Button.Secondary size="sm" onClick={() => startEditingChallenge(item)}>
            <Icon.Edit size="sm" mr="2" /> Edit Challenge
          </Button.Secondary>
        </Item.ActionButtons>

        <Item.SummaryList>
          <Item.Row>
            <Item.Bold>{'Id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag>
          </Item.Row>

          {ChallengeSummary(item)}
        </Item.SummaryList>
      </Item.Summary>
    </Item.Container>
  )
}
