import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Status } from '../../styles/Layout'
import { format } from 'date-fns'
import Icon from '../../styles/Icons'
import { arrayToFeatures, relativeDateUpToDays } from '../../utility/helperFunctions'
import config from '../../config'
import { EventStatus } from '../../enums'
import { ATag } from '../../styles/Link'
import { useCorporate } from '../../contexts/CorporateContext'
import { db } from '../../lib/firebase'
import { eventLocationConverter } from '../../utility/formatEvents'
import { useMap } from '../../contexts/MapContext'
import { Item } from '../../layout/List'
import { ImagePopup } from '../Image'

export const EventItems = ({ items }) => {
  const { expandedItem, setExpandedItem } = useCorporate()
  const { focusOnFeatures } = useMap()

  const onClickItem = useCallback(
    (event, opened) => {
      console.log({ event })
      setExpandedItem(opened ? event : undefined)

      if (opened) {
        const { locations } = event
        focusOnFeatures(arrayToFeatures(locations), { padding: 150 })
      }
    },
    [focusOnFeatures, setExpandedItem]
  )

  return useMemo(
    () =>
      items.map((item) => (
        <EventItem
          key={item.id}
          item={{ ...item, locations: expandedItem?.locations ?? [] }}
          onClick={onClickItem}
          expanded={expandedItem?.id === item?.id}
        />
      )),
    [expandedItem, items, onClickItem]
  )
}

export const EventItem = ({ item, expanded, onClick }) => {
  const { setExpandedItem, startEditingEvent } = useCorporate()
  const [tempLocations, setTempLocations] = useState([])

  // Collapse item on unmount
  useEffect(() => {
    return () => {
      setExpandedItem()
    }
  }, [setExpandedItem])

  // Fetch locations on expand
  const onClickItem = useCallback(async () => {
    const locations = tempLocations
    if (expanded) return onClick(item, !expanded)
    if (!tempLocations.length) {
      const locationsDocs = await db
        .collection(`corporate-events/${item.id}/locations`)
        .withConverter(eventLocationConverter)
        .get()
      locationsDocs.forEach((doc) => {
        locations.push(doc.data())
      })
      setTempLocations(locations)
    }

    onClick({ ...item, locations }, !expanded)
  }, [expanded, item, onClick, tempLocations])

  const onClickEdit = useCallback(
    (e, item) => {
      e.stopPropagation()
      e.preventDefault()
      startEditingEvent(item)
    },
    [startEditingEvent]
  )

  return useMemo(() => {
    const {
      active,
      dateRange,
      description,
      entryCode,
      errors,
      id,
      imageUrl,
      locations,
      minDistance,
      name,
      path,
      roundDuration,
      status,
      title,
      updatedAt
    } = item

    return (
      <Item.Container>
        <Item.Header onClick={onClickItem} expanded={expanded}>
          <Item.HeaderData colSpan={1}>
            <Item.Center>
              <Status active={active} />
            </Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={1}>
            <Item.Center>{status?.icon('lg') ?? '-'}</Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={3}>{name}</Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.startDate ? relativeDateUpToDays(dateRange.startDate, 3) : '-'}
          </Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.endDate ? relativeDateUpToDays(dateRange.endDate, 1) : '-'}
          </Item.HeaderData>
        </Item.Header>

        <Item.Summary expanded={expanded}>
          <Item.ActionButtons>
            <Button.Secondary
              size="sm"
              onClick={(e) => onClickEdit(e, item)}
              data-testid="edit-button"
            >
              <Icon.Edit size="sm" mr="2" /> Edit Event
            </Button.Secondary>
          </Item.ActionButtons>

          {imageUrl && (
            <Item.Image>
              <ImagePopup src={imageUrl} />
            </Item.Image>
          )}

          <Item.SummaryList>
            {!!errors?.length && (
              <Item.Row>
                <Item.Bold tw="text-red-600">
                  {status == EventStatus.DRAFT || status == EventStatus.UNSET
                    ? 'Missing:'
                    : 'Errors:'}
                </Item.Bold>
                <Item.Text tw="italic">{errors.join(', ')}</Item.Text>
              </Item.Row>
            )}

            <Item.Row>
              <Item.Bold>{'Id:'}</Item.Bold>
              {id ? <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag> : '-'}
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Status:'}</Item.Bold>
              <Item.Status>
                {status?.icon('sm')}
                {status?.message ?? '-'}
              </Item.Status>
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Entry Code:'}</Item.Bold>
              <Item.Text>{entryCode}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Title:'}</Item.Bold>
              <Item.Text>{title ?? '-'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Description:'}</Item.Bold>
              <Item.Text>{description ?? '-'}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Locations:'}</Item.Bold>
              <Item.Text>{locations?.length ?? 0}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Min Location Distance:'}</Item.Bold>
              <Item.Text>{`${minDistance ? minDistance + ' m' : '-'}`}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Round Duration:'}</Item.Bold>
              <Item.Text>{`${roundDuration ? roundDuration + ' min' : '-'}`}</Item.Text>
            </Item.Row>

            <Item.Row>
              <Item.Bold>{'Updated:'}</Item.Bold>
              <Item.Text>{updatedAt ? format(updatedAt, 'LLL do yyy, HH:mm') : '–'}</Item.Text>
            </Item.Row>
          </Item.SummaryList>
        </Item.Summary>
      </Item.Container>
    )
  }, [expanded, item, onClickEdit, onClickItem])
}
