import { geohashForLocation } from 'geofire-common'
import { LocationSourceType, S2CellLevel } from '../enums'
import { GeoPoint } from '../lib/firebase'
const s2 = require('s2-geometry').S2

export const locationsConverter = {
  fromFirestore: (location) => {
    const { centerPoint, ...data } = location
    return {
      ...convertBySource.fromFirestore(data),
      centerPoint: { latitude: centerPoint.latitude, longitude: centerPoint.longitude }
    }
  },
  toFirestore: ({ properties }) => {
    const { centerPoint, data, locationSource, mapTags, playable, s2CellId, tags } = properties

    return {
      ...convertBySource.toFirestore(locationSource),
      centerPoint: new GeoPoint(centerPoint.latitude, centerPoint.longitude),
      ...(data && { data }),
      geohash: geohashForLocation([
        parseFloat(centerPoint.latitude),
        parseFloat(centerPoint.longitude)
      ]),
      locationSource,
      playable,
      ...(mapTags && { mapTags }),
      ...(s2CellId && { s2CellId }),
      ...(tags && { tags })
    }
  },

  toMercator: (doc) => {
    const { centerPoint, geohash, locationType, placeId, plusCode, snappedPoint, types, ...meta } =
      doc.data()

    // Create map of cell ids
    const s2CellIds = Object.values(S2CellLevel).reduce((acc, level) => {
      const key = s2.latLngToKey(centerPoint.latitude, centerPoint.longitude, level)
      const id = s2.keyToId(key)
      return { ...acc, [level]: id }
    }, {})

    return {
      centerPoint: snappedPoint || centerPoint,
      createdAt: new Date(),
      data: {
        google: {
          ...(centerPoint && { centerPoint }),
          ...(placeId ? { placeId, generated: false } : { generated: true }),
          snapped: !!snappedPoint,
          ...(snappedPoint && { snappedPoint }),
          ...(types && { tags: types }),
          ...meta
        }
      },
      geohash: geohashForLocation(
        snappedPoint
          ? [snappedPoint.latitude, snappedPoint.longitude]
          : [centerPoint.latitude, centerPoint.longitude]
      ),
      locationSource: LocationSourceType.PLAYABLE,
      migrated: true,
      playable: true,
      s2CellIds,
      updatedAt: new Date()
    }
  }
}

const googleConverter = {
  fromFirestore: (data) => ({ ...data, generated: data.data.google.generated }),
  toFirestore: ({ data, name, ...rest }) => ({ data, name: name ? name : rest.id, ...rest })
}
const mapboxConverter = {
  fromFirestore: (data) => ({ ...data }),
  toFirestore: ({ data, name, ...rest }) => ({
    data,
    name: name ? name : data.text,
    ...rest
  })
}
export const wikiConverter = {
  fromFirestore: (data) => ({ ...data, color: 'blue' }),
  fromNearbyApi: (locations) => {
    return locations.map(({ lat, lon, pageid, title }) => ({
      latitude: lat,
      longitude: lon,
      pageid,
      title
    }))
  },
  toFirestore: ({ name, data, ...rest }) => ({
    data,
    name: name ? name : data.title,
    ...rest
  })
}

const convertBySource = {
  fromFirestore: (data) => {
    switch (data.locationSource) {
      case LocationSourceType.PLAYABLE:
        return googleConverter.fromFirestore(data)
      case LocationSourceType.MAPBOX:
        return mapboxConverter.fromFirestore(data)
      case LocationSourceType.WIKI:
        return wikiConverter.fromFirestore(data)
    }
  },
  toFirestore: (data) => {
    switch (data.locationSource) {
      case LocationSourceType.PLAYABLE:
        return googleConverter.toFirestore(data)
      case LocationSourceType.MAPBOX:
        return mapboxConverter.toFirestore(data)
      case LocationSourceType.WIKI:
        return wikiConverter.toFirestore(data)
    }
  }
}
