import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import tw, { styled } from 'twin.macro'
import { useLocations } from '../../contexts/LocationsContext'
import { CellScanMode, LocationTab } from '../../enums'
import Icon from '../../styles/Icons'
import { Input } from '../../styles/Layout'
import { S2_CELL_LEVEL_OPTIONS } from '../../utility/labeledOptions'
import Dropdown from '../Dropdown'
import { LabelSwitch } from '../LabelSwitch'
import RangeSlider from '../RangeSlider'

export const SelectSettings = () => {
  const { control } = useFormContext()
  const { activeTab, clearS2Cells, clearScanning } = useLocations()
  const { scanMode } = useWatch({ control })
  const maxScanRadius = useMemo(
    () => (activeTab == LocationTab.GENERATE ? 4000 : 8000),
    [activeTab]
  )

  return (
    <Container>
      <Controller
        control={control}
        name="scanMode"
        render={({ field: { onChange, value } }) => (
          <LabelSwitch
            id="label-switch"
            checked={value === CellScanMode.SCAN}
            size="md"
            color="blue"
            onToggle={(scan) => {
              if (scan) {
                clearS2Cells()
                return onChange(CellScanMode.SCAN)
              } else {
                clearScanning()
                return onChange(CellScanMode.CLICK)
              }
            }}
          >
            <Row>
              <Icon.Click size="sm" mr="2" />
              Single Cell
            </Row>
            <Row>
              <Icon.ClickRay size="sm" mr="2" />
              Scan Area
            </Row>
          </LabelSwitch>
        )}
      />

      {(activeTab === LocationTab.GENERATE ||
        (activeTab === LocationTab.QUERY && scanMode === CellScanMode.CLICK)) && (
        <Controller
          control={control}
          name="s2Level"
          rules={{ onChange: clearS2Cells }}
          render={({ field }) => (
            <Col>
              <Input.Label tw="gap-1">
                {'Cell Level:'}
                <pre>{field.value.code || '-'}</pre>
              </Input.Label>
              <Dropdown.Select {...field} menuPlacement="bottom" options={S2_CELL_LEVEL_OPTIONS} />
            </Col>
          )}
        />
      )}

      {scanMode === CellScanMode.SCAN && (
        <Controller
          control={control}
          name="scanRadius"
          render={({ field }) => (
            <Col>
              <Input.Label tw="gap-1">
                {'Scan Radius:'}
                <pre>{field.value || 0} m</pre>
              </Input.Label>
              <RangeSlider
                {...field}
                color="blue"
                min={200}
                max={maxScanRadius}
                step="200"
                onChange={({ target }) => field.onChange(Number(target.value))}
              />
            </Col>
          )}
          rules={{ shouldUnregister: true }}
        />
      )}
    </Container>
  )
}

const Container = tw.div`relative flex flex-col w-full gap-4 p-4 px-10 bg-bismark-100 text-bismark-800 text-sm rounded-md shadow-inner`
const Row = tw.div`flex items-center`
const Col = styled.div`
  ${tw`flex flex-col w-full gap-1`}
  & > :not(${Input.Label}) {
    ${tw`px-2`}
  }
`
