import React, { useCallback } from 'react'
import tw, { styled } from 'twin.macro'
import { Alerts } from '../../utility/alerts'

export const ImagePopup = ({ expand = true, ...props }) => {
  const src = props.src ?? props.imageUrl ?? props.imageData

  const displayImage = useCallback(() => {
    if (!src) return
    Alerts.Image.POPUP(src)
  }, [src])

  return <HoverImage expand={expand} src={src} alt="image" onClick={displayImage} {...props} />
}

const HoverImage = styled.img`
  ${tw`w-full h-full object-fit[cover] object-position[50% 50%] cursor-pointer transition-all ease-in`}
  ${({ expand }) => expand && tw`hover:scale-105 hover:brightness-110`}
`
