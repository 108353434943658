import React from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { LocationTab } from '../../enums'
import { Card, PageLayout } from '../../styles/Layout'
import { LocationsMap } from '../Location/LocationsMap'
import { GenerateLocations } from '../Location/GenerateLocations'
import { QueryLocations } from '../Location/QueryLocations'
import { useLocations } from '../../contexts/LocationsContext'
import { useFormContext } from 'react-hook-form'
import { UsaProvider } from '../../contexts/UsaCellsContext'

const LocationsPage = () => {
  const { activeTab, clearS2Cells, setActiveTab } = useLocations()
  const { resetField } = useFormContext()

  return (
    <ListPage>
      <List>
        <Card.Container header="Locations" color={theme`colors.matrix.400`}>
          <Tabs>
            <Tab
              active={activeTab === LocationTab.GENERATE}
              onClick={() => {
                resetField('scanRadius')
                setActiveTab(LocationTab.GENERATE)
              }}
            >
              Generate
            </Tab>
            <Tab
              active={activeTab === LocationTab.QUERY}
              onClick={() => {
                clearS2Cells()
                setActiveTab(LocationTab.QUERY)
              }}
            >
              Query
            </Tab>
          </Tabs>
          {activeTab === LocationTab.GENERATE ? <GenerateLocations /> : <QueryLocations />}
        </Card.Container>
      </List>

      <Map>
        <UsaProvider>
          <LocationsMap />
        </UsaProvider>
      </Map>
    </ListPage>
  )
}

export default LocationsPage

const Container = tw.div`flex minHeight[50vh] transition-all duration-300`
const List = tw(Container)``
const Map = tw(Container)``
const ListPage = styled(PageLayout)`
  ${tw`flex-col lg:flex-row w-full`}
  & > ${List} {
    ${tw`w-full lg:w-1/3`}
  }
  & > ${Map} {
    ${tw`w-full lg:w-2/3`}
  }
`
const Tabs = tw.div`flex w-full pb-0 bg-matrix gap-2 first:pl-2`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[100px] text-center rounded-t-md text-lg tracking-wide`}
  ${({ active, error }) => css`
    ${active ? tw`bg-white-pure` : tw`text-red-200 bg-matrix hover:bg-matrix-600 `}
    ${error && tw`ring-4 ring-red`}
  `}
`
