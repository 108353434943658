import React, { useCallback, useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { useHvntList } from '../../hooks/useHvntList'
import { objectIsEmpty } from '../../utility/validationFunctions'
import useBreakpoint from '../../hooks/useBreakpoint'
import { useHvnt } from '../../contexts/HvntContext'
import Icon from '../../styles/Icons'
import { Button, Status } from '../../styles/Layout'
import { Switch } from '../Layout/Switch'
import { HvntFilters, HvntStatus } from '../../enums'
import { useAreas } from '../../contexts/AreasContext'
import { FilterButton, FilterGrid, FilterInput } from '../Filter'
import { ActiveFilters } from '../Filter/ActiveFilters'
import Dropdown from '../Dropdown'

export const HvntFilter = () => {
  const { activeCity } = useAreas()
  const { addFilter, andFilters, clearFilters, filteredLength, filters, listItems, setAndFilters } =
    useHvntList()
  const filter = { addFilter, andFilters, clearFilters, filteredLength, filters, setAndFilters }
  const { tagOptions } = useHvnt()
  const [showFilters, setShowFilters] = useState(false)
  const { belowTarget } = useBreakpoint('sm')
  const inputRef = useRef()

  const resetFilters = useCallback(() => {
    clearFilters()
    if (inputRef.current) inputRef.current.value = ''
  }, [clearFilters])

  // Reset filters when active city changes
  useEffect(() => {
    resetFilters()
  }, [activeCity, resetFilters])

  return (
    <Container>
      <FilterModeWrapper>
        <FilterMode>
          <pre>OR</pre>
          <Switch size="sm" onToggle={() => setAndFilters((v) => !v)} defaultChecked={andFilters} />
          <pre>AND</pre>
        </FilterMode>

        <div tw="flex rounded-md overflow-hidden">
          {!objectIsEmpty(filters) && (
            <Button.White size="sm" tw="m-0" onClick={resetFilters}>
              <Icon.Close size="sm" mr="2" />
              Clear{!belowTarget && <>&nbsp;Filters</>}
            </Button.White>
          )}
          <Button.Basic
            size="sm"
            tw="m-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? (
              <>
                <Icon.ChevronUp size="sm" mr="2" />
                Less{!belowTarget && <>&nbsp;Filters</>}
              </>
            ) : (
              <>
                <Icon.ChevronDown size="sm" mr="2" />
                More{!belowTarget && <>&nbsp;Filters</>}
              </>
            )}
          </Button.Basic>
        </div>
      </FilterModeWrapper>

      <FilterGrid size={5}>
        <FilterButton {...HvntFilters.ACTIVE} {...filter} value={false}>
          <Status active={false} size="sm" />
          <pre>INACTIVE</pre>
        </FilterButton>
        <FilterButton {...HvntFilters.DRAFT} {...filter}>
          {HvntStatus.DRAFT.icon('sm')}
          <pre>{HvntStatus.DRAFT.message}</pre>
        </FilterButton>
        <FilterButton {...HvntFilters.PAUSED} {...filter}>
          {HvntStatus.PAUSED.icon('sm')}
          <pre>{HvntStatus.PAUSED.message}</pre>
        </FilterButton>
        <FilterButton {...HvntFilters.ENDED} {...filter}>
          {HvntStatus.ENDED.icon('sm')}
          <pre>{HvntStatus.ENDED.message}</pre>
        </FilterButton>

        <FilterButton {...HvntFilters.ERROR} {...filter}>
          {HvntStatus.ERROR.icon('sm')}
          <pre>ERROR</pre>
        </FilterButton>
        <FilterButton {...HvntFilters.ACTIVE} {...filter}>
          <Status active={true} size="sm" />
          <pre>ACTIVE</pre>
        </FilterButton>
        <FilterButton {...HvntFilters.LIVE} {...filter}>
          {HvntStatus.LIVE.icon('sm')}
          <pre>{HvntStatus.LIVE.message}</pre>
        </FilterButton>
        <FilterButton {...HvntFilters.LIVE_SOON} {...filter}>
          {HvntStatus.UPCOMING.icon('sm')}
          <pre>{HvntStatus.UPCOMING.message}</pre>
        </FilterButton>
        <FilterButton {...HvntFilters.ENDED} {...filter} value={false}>
          {belowTarget && <code tw="text-gray">!</code>}
          {HvntStatus.ENDED.icon('sm')}
          <pre>NOT {HvntStatus.ENDED.message}</pre>
        </FilterButton>
        {showFilters && (
          <>
            <FilterInput
              {...HvntFilters.QUERY}
              {...filter}
              ref={inputRef}
              placeholder="Filter query (category, id, name, prizes, tags)"
              tw="col-span-full mt-1"
            />
            <Dropdown.Select
              closeMenuOnSelect={false}
              id="tags"
              isClearable
              isMulti
              onChange={(arr) => addFilter(HvntFilters.TAGS, arr)}
              options={tagOptions}
              placeholder="Filter tags"
              tw="col-span-full mt-0.5"
            />
          </>
        )}
      </FilterGrid>

      <ActiveFilters {...filter} items={listItems} />
    </Container>
  )
}

const Container = tw.div`flex flex-1 flex-col self-center items-center z-20 pt-2 px-2 w-full md:w-4/5 lg:w-full xl:w-4/5 gap-1 select-none`
const FilterModeWrapper = tw.div`flex justify-between w-full items-center gap-2`
const FilterMode = tw.div`flex items-center h-full gap-2 bg-white-pure p-1 px-2 shadow-inner w-min rounded-md text-xs`
