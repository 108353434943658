import React, { useState, useRef } from 'react'
import tw from 'twin.macro'
import { objectIsEmpty } from '../../utility/validationFunctions'
import { useChallenge } from '../../contexts/ChallengeContext'
import Icon from '../../styles/Icons'
import { Switch } from '../Layout/Switch'
import { Button } from '../../styles/Layout'
import useBreakpoint from '../../hooks/useBreakpoint'
import { ActiveFilters, FilterButton, FilterGrid, FilterInput } from '../Filter'
import { ChallengeFilters, ChallengeType } from '../../enums'

export const ChallengeFilter = ({ filter }) => {
  const { challenges } = useChallenge()
  const { belowTarget } = useBreakpoint('md')
  const { andFilters, clearFilters, filters, setAndFilters } = filter
  const inputRef = useRef()
  const [showFilters, setShowFilters] = useState(false)

  return (
    <Container>
      <FilterModeWrapper>
        {showFilters && (
          <FilterMode>
            <pre>OR</pre>
            <Switch
              color="blue"
              size="sm"
              onToggle={() => setAndFilters((v) => !v)}
              defaultChecked={andFilters}
            />
            <pre>AND</pre>
          </FilterMode>
        )}

        <ButtonContainer>
          {!objectIsEmpty(filters) && (
            <Button.White
              size="sm"
              tw="m-0"
              onClick={() => {
                clearFilters()
                inputRef.current.value = ''
              }}
            >
              <Icon.Close size="sm" mr="2" />
              Clear{!belowTarget && <>&nbsp;Filters</>}
            </Button.White>
          )}
          <Button.Basic
            size="sm"
            tw="m-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? (
              <>
                <Icon.ChevronUp size="sm" mr="2" />
                Less{!belowTarget && <>&nbsp;Filters</>}
              </>
            ) : (
              <>
                <Icon.ChevronDown size="sm" mr="2" />
                More{!belowTarget && <>&nbsp;Filters</>}
              </>
            )}
          </Button.Basic>
        </ButtonContainer>
      </FilterModeWrapper>
      <FilterInput
        {...ChallengeFilters.QUERY}
        {...filter}
        placeholder="Filter (query, id, type)"
        ref={inputRef}
      />

      {showFilters && (
        <FilterGrid>
          <FilterButton {...ChallengeFilters.TRIVIA} {...filter}>
            <pre>{ChallengeType.TRIVIA}</pre>
          </FilterButton>
          <FilterButton {...ChallengeFilters.CUSTOM} {...filter}>
            <pre>CUSTOM</pre>
          </FilterButton>
        </FilterGrid>
      )}
      <ActiveFilters {...filter} items={challenges} />
    </Container>
  )
}

const Container = tw.div`flex flex-1 flex-col self-center items-center z-20 py-2 px-2 w-full lg:w-4/5 gap-1.5 select-none`
const FilterModeWrapper = tw.div`flex justify-between w-full items-center gap-2`
const FilterMode = tw.div`flex items-center h-full gap-2 bg-white-pure p-1 px-2 shadow-inner w-min rounded-md text-xs`
const ButtonContainer = tw.div`flex rounded-md overflow-hidden ml-auto`
