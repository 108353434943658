import React from 'react'
import tw from 'twin.macro'
import { useNavigate } from 'react-router-dom'
import { Authenticate, Button, Input } from '../../styles/Layout'
import { useForm } from 'react-hook-form'
import { useUser } from '../../contexts/UserContext'
import { isEmail } from '../../utility/validationFunctions'
import { handleError } from '../../utility/handleErrors'

export const Login = () => {
  const { login } = useUser()
  const navigate = useNavigate()
  const {
    formState: { errors },
    setError,
    register,
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    mode: 'onChange'
  })

  const validateData = ({ email }) => {
    let errors = []
    if (!isEmail(email)) errors.push({ name: 'email', message: 'Please enter a valid email' })
    return errors
  }

  const onLogin = async (data) => {
    const errors = validateData(data)
    if (errors.length) {
      return errors.forEach(({ name, message }) => setError(name, { type: 'manual', message }))
    }
    try {
      const user = await login(data)
      user && navigate('/')
    } catch (err) {
      console.error(err)
      setError('login', { type: 'manual', message: handleError(err) })
    }
  }

  return (
    <>
      <Authenticate.Container {...register('login')}>
        <Authenticate.Header>Forrest Fenn Login</Authenticate.Header>
        <Authenticate.Form onSubmit={handleSubmit(onLogin)}>
          <div>
            <Input.Label required>Email</Input.Label>
            <Input.Default
              {...register('email', {
                required: 'Email is required'
              })}
              size="lg"
              type="email"
            />
            <Input.Error>{errors.email?.message}</Input.Error>
          </div>
          <div>
            <Input.Label required>Password</Input.Label>
            <Input.Default
              {...register('password', {
                required: 'Password is required'
              })}
              size="lg"
              name="password"
              type="password"
            />
            <Input.Error>{errors.password?.message}</Input.Error>
          </div>
          <Input.Error tw="text-center my-2">{errors.login?.message}</Input.Error>
          <ButtonContainer>
            <Button.Secondary size="lg" onClick={handleSubmit(onLogin)} type="submit">
              Log in
            </Button.Secondary>
            <Button.Filter
              onClick={() => navigate('/forgot-password')}
              tw="text-orange text-base mt-2 mx-1 hover:bg-gray-100 active:bg-gray-200"
            >
              Forgot Password?
            </Button.Filter>
          </ButtonContainer>
        </Authenticate.Form>
      </Authenticate.Container>
    </>
  )
}
const ButtonContainer = tw.div`flex flex-col`
