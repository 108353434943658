import React from 'react'
import { Link } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import Icon from './Icons'

export const ATag = ({ children, ...props }) => (
  <StyledTag {...props} tw="text-blue-700">
    <Text>{children}</Text>
    <Icon.ExternalLink size="sm" />
  </StyledTag>
)

export const UserTag = ({ children, ...props }) => (
  <StyledRoute {...props} tw="text-amethyst">
    <Text>{children}</Text>
    <Icon.UserSolid size="xs" />
  </StyledRoute>
)

export const HvntTag = ({ children, ...props }) => (
  <StyledRoute {...props} tw="text-matrix">
    <Text>{children}</Text>
    <Icon.Flag size="xs" />
  </StyledRoute>
)

export const CheckpointTag = ({ children, ...props }) => (
  <StyledRoute {...props} tw="text-gold-600">
    <Text>{children}</Text>
    <Icon.Pin size="sm" />
  </StyledRoute>
)

export const PrizeTag = ({ children, ...props }) => (
  <StyledRoute {...props} tw="text-terracotta-600">
    <Text>{children}</Text>
    <Icon.Gift size="sm" />
  </StyledRoute>
)

export const ExternalRoute = styled(Link).attrs(() => ({ target: '_blank' })) `` // prettier-ignore
export const ExternalLink = styled.a.attrs((props) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  ...props
})) `` // prettier-ignore

const linkStyle = tw`relative flex gap-1 underline items-center italic`
const Text = tw.div`truncate`
const StyledRoute = styled(ExternalRoute)`${linkStyle}` // prettier-ignore
const StyledTag = styled(ExternalLink)`${linkStyle}` // prettier-ignore
