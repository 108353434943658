import React from 'react'
import tw, { styled } from 'twin.macro'
import { useIsMounted } from '../../hooks/useIsMounted'
import { Spinner } from './Spinner'

export const Loader = ({ loading, size = 'md', text = '' }) => {
  const { isMounted } = useIsMounted()

  return (
    <>
      {isMounted() && loading && (
        <Background data-testid="loader">
          <Overlay />
          <Content size={size}>
            <Spinner />
            {text && <Text>{text}</Text>}
          </Content>
        </Background>
      )}
    </>
  )
}

const Background = tw.div`absolute top-0 left-0 flex w-full h-full items-center justify-center pointer-events-auto`
const Text = tw.div`relative font-sans font-light text-lg text-white whitespace-nowrap`
const Content = styled.div`
  ${tw`relative flex flex-col w-full h-full gap-2 p-4 justify-center items-center zIndex[200]`}
  ${({ size }) =>
    size === 'lg'
      ? tw`max-width[250px]`
      : size === 'sm'
      ? tw`max-width[80px]`
      : tw`max-width[180px]`}
`
const Overlay = tw.div`absolute top-0 left-0 w-full h-full bg-black zIndex[198] opacity-75`
