import React, { Fragment, useEffect, useMemo } from 'react'
import { Layer, Source } from 'react-map-gl'
import { usePlayer } from '../../../contexts/PlayerContext'
import { arrayToFeatures } from '../../../utility/helperFunctions'
import { mapLayers } from '../../Map/MapLayers'
import { useMap } from '../../../contexts/MapContext'
import { isGeoPoint } from '../../../utility/validationFunctions'
import { useIsMounted } from '../../../hooks/useIsMounted'

export const TransactionLocations = () => {
  const { activePlayer = {} } = usePlayer()
  const { focusOnFeatures } = useMap()
  const { isMounted } = useIsMounted()

  const transactionLocationData = useMemo(() => {
    if (!isMounted() || !activePlayer.transactionLocations?.length) return
    const locations = []
    activePlayer.transactionLocations.forEach(({ metadata, timestamp }, i) => {
      if (isGeoPoint(metadata?.userLocation)) {
        locations.push({
          centerPoint: {
            latitude: metadata.userLocation.latitude,
            longitude: metadata.userLocation.longitude
          },
          index: i,
          timestamp
        })
      }
    })

    return arrayToFeatures(locations)
  }, [activePlayer.transactionLocations, isMounted])

  // Zoom to transaction locations
  useEffect(() => {
    if (!isMounted() || !transactionLocationData) return
    focusOnFeatures(transactionLocationData, { padding: 150 })
  }, [focusOnFeatures, isMounted, transactionLocationData])

  return (
    <Fragment>
      {transactionLocationData && (
        <Source type="geojson" data={transactionLocationData} id="dataLocations">
          <Layer {...mapLayers.player.dataLocations} />
          <Layer {...mapLayers.player.locationIndex} />
        </Source>
      )}
    </Fragment>
  )
}
