import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import tw from 'twin.macro'
import { useCheckpointMap } from '../../../contexts/CheckpointMapContext'
import { Loader } from '../../Loader/Loader'
import { Map, NavigationControl, GeolocateControl, Source, Layer } from 'react-map-gl'
import { MapSwitcher } from '../../Map/MapSwitcher'
import config from '../../../config'
import { MapControl } from '../../Map/MapControl'
import { useUser } from '../../../contexts/UserContext'
import { SelectCityMarkers } from '../../Map/Markers/SelectCityMarkers'
import { useAreas } from '../../../contexts/AreasContext'
import { useMap } from '../../../contexts/MapContext'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { CheckpointMarkers } from './CheckpointMarkers'
import { mapLayers } from '../../Map/MapLayers'
import { CheckpointsPopups } from './CheckpointPopups'
import { LayerVisibilityControl } from '../../Map/LayerVisibilityControl'
import { CandidateCells } from './CandidateCells'
import { feature } from '@turf/helpers'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useMapControl } from '../../../hooks/useMapControl'
import { AddPin } from './AddPin'
import { useDebounce } from 'react-use'
import { CheckpointTab, LocationCellType, Role } from '../../../enums'
import '../../Map/geocoderStyle.css'
import { GeocoderControl } from '../../Map/GeocoderControl'
import { Overlay } from '../../../layout/Map'

export const CheckpointMap = () => {
  const mapRef = useRef(null)
  const { activeCity, lockCity } = useAreas()
  const {
    activeTab,
    checkpointBeingEdited,
    focusedItem,
    loading,
    setActiveCandidateCells,
    setFocusedItem
  } = useCheckpoint()
  const {
    addNewMarker,
    candidateCellData,
    candidateClueData,
    candidateData,
    checkpointData,
    reviewedCandidateCellData
  } = useCheckpointMap()
  const { userHasRole } = useUser()
  const { focusedBbox, focusedMarker, focusOnFeatures, setLayerVisibility } = useMap()
  const { containerRef, zoomToBbox, zoomToPoint } = useMapControl(mapRef)
  const { isMounted } = useIsMounted()

  // store hovered locally
  const [hoveredItem, setHoveredItem] = useState()
  const [addingPin, setAddingPin] = useState(false)
  const [busy, setBusy] = useState(true)
  const [mapStyle, setMapStyle] = useState(config.mapbox.ALTERNATE_MAP)
  const [cursor, setCursor] = useState('auto')
  const [viewstate, setViewstate] = useState({
    longitude: 18.078419,
    latitude: 59.342644,
    zoom: 3
  })

  const editedCheckpointId = checkpointBeingEdited?.id || ''
  const focusedCheckpointId = focusedItem?.checkpoint?.id ?? ''
  const hoveredCheckpointId = hoveredItem?.checkpoint?.id ?? ''
  const focusedCandidateId = focusedItem?.candidate?.id ?? ''
  const hoveredCandidateId = hoveredItem?.candidate?.id ?? ''

  const filterNotEdit = useMemo(() => ['!=', 'id', editedCheckpointId], [editedCheckpointId])
  const filterFocusedCheckpoint = useMemo(
    () => ['all', filterNotEdit, ['in', 'id', focusedCheckpointId]],
    [filterNotEdit, focusedCheckpointId]
  )
  const filterFocusedCandidate = useMemo(
    () => ['all', filterNotEdit, ['in', 'id', focusedCandidateId]],
    [filterNotEdit, focusedCandidateId]
  )
  const filterHoveredCheckpoint = useMemo(
    () => [
      'all',
      filterNotEdit,
      ['!=', 'id', focusedCheckpointId],
      ['in', 'id', hoveredCheckpointId]
    ],
    [filterNotEdit, focusedCheckpointId, hoveredCheckpointId]
  )
  const filterHoveredCandidate = useMemo(
    () => [
      'all',
      filterNotEdit,
      ['!=', 'id', focusedCandidateId],
      ['in', 'id', hoveredCandidateId]
    ],
    [filterNotEdit, focusedCandidateId, hoveredCandidateId]
  )

  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  const onHover = useCallback(
    ({ features = [], lngLat }) => {
      const { layer, properties } = features[0] ?? {}
      setCursor('pointer')
      switch (layer?.id) {
        case 'candidate': {
          return setHoveredItem((h) => ({ ...h, candidate: properties }))
        }
        case 'candidate-cell': {
          return setHoveredItem((h) => ({
            ...h,
            cell: { ...properties, lngLat, type: LocationCellType.CANDIDATE }
          }))
        }
        case 'candidate-cell-reviewed': {
          return setHoveredItem((h) => ({
            ...h,
            cell: { ...properties, lngLat, type: LocationCellType.REVEIEWED }
          }))
        }
        case 'checkpoint-interact': {
          if (properties.cluster) return setHoveredItem()
          if (focusedItem?.checkpoint?.id === properties.id) return
          return setHoveredItem((h) => ({ ...h, checkpoint: properties }))
        }
        case 'clue-candidate': {
          setHoveredItem((h) => ({ ...h, clue: properties }))
        }
      }
    },
    [focusedItem]
  )

  const onMouseLeave = useCallback(() => {
    setHoveredItem()
    setCursor('auto')
  }, [])

  const onClick = useCallback(
    async (event) => {
      if (!activeCity || checkpointBeingEdited?.centerPoint) return
      // Add marker on click
      if (addingPin) {
        setAddingPin(false)
        userHasRole([Role.CREATOR]) &&
          addNewMarker({ centerPoint: { latitude: event.lngLat.lat, longitude: event.lngLat.lng } })
        return
      }
      const { geometry, layer, properties } = event.features[0] ?? {}

      // Clicked layer
      switch (layer?.id) {
        case 'candidate': {
          return setFocusedItem({ candidate: properties })
        }
        case 'checkpoint-interact': {
          // Clicked cluster
          if (properties.cluster) {
            const map = mapRef.current.getMap().getSource('checkpoints')
            return map.getClusterExpansionZoom(properties.cluster_id, (err, zoom) => {
              if (err) return
              return zoomToPoint({
                centerPoint: {
                  longitude: geometry.coordinates[0],
                  latitude: geometry.coordinates[1]
                },
                options: {
                  duration: 600,
                  minZoom: Math.max(12, zoom),
                  maxZoom: 18
                }
              })
            })
          }
          if (activeTab !== CheckpointTab.CHECKPOINTS) return
          return setFocusedItem({ checkpoint: properties })
        }
        case 'candidate-cell': {
          if (!fetch) return
          return setActiveCandidateCells((c) => [...c, properties.id])
        }
      }
    },
    [
      activeCity,
      activeTab,
      addingPin,
      addNewMarker,
      checkpointBeingEdited,
      setActiveCandidateCells,
      setFocusedItem,
      userHasRole,
      zoomToPoint
    ]
  )

  const onClickAddPin = useCallback((add) => {
    setAddingPin(add)
    setCursor(add ? 'crosshair' : 'auto')
  }, [])

  // Set layer visibility
  useEffect(() => {
    setLayerVisibility({
      Checkpoints: {
        layers: [
          'checkpoint',
          'checkpoint-focus',
          'checkpoint-hover',
          'checkpoint-interact',
          'checkpoint-cluster',
          'checkpoint-cluster-count'
        ],
        visible: activeTab == CheckpointTab.CHECKPOINTS
      },
      Clues: {
        layers: ['clue-candidate'],
        disabled: !checkpointBeingEdited,
        visible: true
      },
      Candidates: {
        layers: ['candidate'],
        disabled: activeTab !== CheckpointTab.CANDIDATES,
        visible: activeTab == CheckpointTab.CANDIDATES
      },
      Cells: {
        layers: [
          'candidate-cell',
          'candidate-cell-border',
          'candidate-cell-hover',
          'candidate-cell-active'
        ],
        disabled: activeTab !== CheckpointTab.CANDIDATES,
        visible: activeTab == CheckpointTab.CANDIDATES
      },
      'Reviewed Cells': {
        layers: ['candidate-cell-reviewed'],
        disabled: activeTab !== CheckpointTab.CANDIDATES,
        visible: false
      }
    })
    return () => setLayerVisibility()
  }, [activeTab, checkpointBeingEdited, setLayerVisibility])

  // Update layer visibility
  useEffect(() => {
    setLayerVisibility((v) => ({
      Checkpoints: {
        ...v['Checkpoints'],
        onZoom: () => focusOnFeatures(checkpointData, { maxZoom: 17 })
      },
      Clues: {
        ...v['Clues'],
        onZoom: () => focusOnFeatures(candidateClueData, { maxZoom: 17 })
      },
      Candidates: {
        ...v['Candidates'],
        onZoom: () => focusOnFeatures(candidateData, { maxZoom: 17 })
      },
      Cells: {
        ...v['Cells'],
        onZoom: () => focusOnFeatures(candidateCellData, { padding: 150 })
      },
      'Reviewed Cells': {
        ...v['Reviewed Cells'],
        onZoom: () => focusOnFeatures(reviewedCandidateCellData, { padding: 150 })
      }
    }))
  }, [
    candidateCellData,
    candidateClueData,
    candidateData,
    checkpointData,
    focusOnFeatures,
    reviewedCandidateCellData,
    setLayerVisibility
  ])

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedMarker) return zoomToPoint(focusedMarker)
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    400,
    [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint]
  )

  // Update cursor
  useEffect(() => {
    setCursor('auto')
  }, [checkpointBeingEdited])

  return useMemo(
    () => (
      <MapContainer data-testid="map" ref={containerRef}>
        <Loader loading={busy || loading} text="Loading Map" />

        {!busy && activeCity && (
          <Overlay tw="top-2 left-2">
            <LayerVisibilityControl mapRef={mapRef} />
          </Overlay>
        )}
        {activeCity && activeTab === CheckpointTab.CHECKPOINTS && (
          <AddPin
            adding={addingPin}
            onClick={onClickAddPin}
            disabled={busy || loading || checkpointBeingEdited?.centerPoint}
          />
        )}

        <MapSwitcher onSwitch={setMapStyle} bottom left color="blue" />
        <Map
          {...viewstate}
          cursor={cursor}
          id="checkpointMap"
          interactiveLayerIds={
            busy || addingPin
              ? null
              : [
                  'candidate',
                  'candidate-cell',
                  'candidate-cell-reviewed',
                  'checkpoint-interact',
                  'clue-candidate'
                ]
          }
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
          mapStyle={mapStyle}
          onClick={onClick}
          onLoad={() => setBusy(false)}
          onMouseMove={onHover}
          onMouseLeave={onMouseLeave}
          onMove={onMoveMap}
          ref={mapRef}
          style={{ width: '100vw', height: '100%' }}
        >
          <SelectCityMarkers locked={addingPin || lockCity} />

          <CheckpointsPopups hoveredItem={hoveredItem} />

          <CheckpointMarkers />

          <Source
            id="checkpoints"
            type="geojson"
            data={checkpointData}
            cluster={true}
            clusterRadius={15}
          >
            <Layer {...mapLayers.checkpoint.focus} filter={filterFocusedCheckpoint} />
            <Layer {...mapLayers.checkpoint.interact} filter={filterNotEdit} />
            <Layer {...mapLayers.checkpoint.cluster} />
            <Layer {...mapLayers.checkpoint.clusterCount} />
            <Layer
              {...mapLayers.checkpoint.hover}
              filter={filterHoveredCheckpoint}
              beforeId={mapLayers.checkpoint.focus.id}
            />
            <Layer
              {...mapLayers.checkpoint.main}
              filter={filterNotEdit}
              beforeId={mapLayers.checkpoint.hover.id}
            />
          </Source>

          <Source id="clues" type="geojson" data={candidateClueData}>
            <Layer {...mapLayers.clue.candidate} />
          </Source>

          <Source type="geojson" data={candidateData ?? feature()}>
            <Layer {...mapLayers.candidate.focus} filter={filterFocusedCandidate} />
            <Layer {...mapLayers.candidate.hover} filter={filterHoveredCandidate} />
            <Layer
              {...mapLayers.candidate.main}
              filter={filterNotEdit}
              beforeId={mapLayers.candidate.hover.id}
            />
          </Source>

          <CandidateCells hoveredItem={hoveredItem} />

          <MapControl>
            <GeolocateControl
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
            />
            <NavigationControl />
            <GeocoderControl
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
              position="top-right"
            />
          </MapControl>
        </Map>
      </MapContainer>
    ),
    [
      busy,
      containerRef,
      loading,
      activeCity,
      activeTab,
      addingPin,
      onClickAddPin,
      checkpointBeingEdited?.centerPoint,
      viewstate,
      cursor,
      mapStyle,
      onClick,
      onHover,
      onMouseLeave,
      onMoveMap,
      lockCity,
      hoveredItem,
      checkpointData,
      filterFocusedCheckpoint,
      filterNotEdit,
      filterHoveredCheckpoint,
      candidateClueData,
      candidateData,
      filterFocusedCandidate,
      filterHoveredCandidate
    ]
  )
}

const MapContainer = tw.div`relative flex overflow-hidden justify-center items-center`
