import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { css, styled, theme } from 'twin.macro'
import { usePlayer } from '../../../contexts/PlayerContext'
import { useGetUser } from '../../../hooks/firebase/useGetUser'
import { db } from '../../../lib/firebase'
import { Card } from '../../../styles/Layout'
import { userDataConverter } from '../../../utility/formatUserData'
import { Loader } from '../../Loader/Loader'

export const PlayerProfile = ({ player }) => {
  const { activeTab, closePlayerProfile, dispatch, loading } = usePlayer()
  const { onNextUser } = useGetUser(dispatch)
  const uid = useMemo(() => player.uid, [player])
  const gamerTag = player.userData?.gamerTag ?? ''

  // Subscribe to player document changes
  useEffect(() => {
    if (!uid) return
    const unsubscribe = db
      .doc(`users/${uid}`)
      .withConverter(userDataConverter)
      .onSnapshot(onNextUser)
    return unsubscribe
  }, [onNextUser, uid])

  return (
    <Card.Container
      header={`User Profile ${gamerTag && `| ${gamerTag}`}`}
      color={theme`colors.amethyst.400`}
      xHover={theme`colors.amethyst.500`}
      xActive={theme`colors.amethyst.600`}
      xDisabled={loading}
      onClose={closePlayerProfile}
    >
      <Container>
        <Loader loading={loading} />
        <InfoTabs {...player} />
        <TabContentContainer>{activeTab.component}</TabContentContainer>
      </Container>
    </Card.Container>
  )
}

const InfoTabs = ({ uid }) => {
  const { activeTab, profileTabs, setActiveTab } = usePlayer()
  const navigate = useNavigate()

  const onClickTab = useCallback(
    (tab) => {
      if (!activeTab?.title === tab?.title) return
      setActiveTab(tab)
      navigate(`/users/${uid}/${tab.path}`, { replace: true })
    },
    [activeTab?.title, uid, navigate, setActiveTab]
  )

  return (
    <OverflowContainer>
      <Tabs>
        {Object.entries(profileTabs).map(([id, tab]) => (
          <Tab key={id} onClick={() => onClickTab(tab)} active={activeTab?.title === tab.title}>
            {tab.title}
          </Tab>
        ))}
      </Tabs>
    </OverflowContainer>
  )
}

const Container = tw.div`flex flex-col h-full w-full`
const TabContentContainer = tw.div`flex flex-col w-full h-full overflow-y-scroll`
const OverflowContainer = tw.div`sticky top-0 left-0 right-0 flex flex-shrink-0 items-center overflow-x-scroll bg-amethyst px-2 z-10`
const Tabs = tw.div`flex gap-2 select-none`
const Tab = styled.div`
  ${tw`mt-2 px-4 py-1 cursor-pointer minWidth[90px] text-center rounded-t-md text-base tracking-wide`}
  ${({ active }) => css`
    ${active ? tw`bg-white` : tw`text-white bg-amethyst hover:bg-amethyst-600 `}
  `}
`
