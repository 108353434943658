import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { db } from '../../lib/firebase'
import { UserTag } from '../../styles/Link'
export const Leaderboard = ({ id, name }) => {
  const [leaderboard = [], setLeaderboard] = useState()
  const [artefacts, setArtefacts] = useState()

  useEffect(() => {
    const unsubscribe = db
      .collection('leaderboards')
      .doc(id)
      .onSnapshot((doc) => {
        if (!doc.exists) return
        const { COMMON, RARE, scores } = doc.data()
        const leaderboard = Object.keys(scores).map((key) => ({ uid: key, collected: scores[key] }))
        setLeaderboard(leaderboard.sort((a, b) => b.collected - a.collected))
        setArtefacts({ common: COMMON, rare: RARE })
      })
    return unsubscribe
  }, [id])

  return (
    <LeaderboardContainer>
      <LeaderboardTitle>{`Leaderboard for "${name}"`}</LeaderboardTitle>
      <Grid tw="font-bold text-lg mb-2">
        <div tw="col-span-3">Player</div>
        <div tw="col-span-1">Score</div>
      </Grid>

      {leaderboard.map(({ collected, uid }, i) => (
        <Grid key={i}>
          <div tw="flex col-span-3 gap-3">
            {i + 1 + '.'}
            <UserTag to={`/users?id=${uid}&tab=xp`}>{uid}</UserTag>
          </div>
          <div tw="col-span-1">{collected ?? 0}</div>
        </Grid>
      ))}

      {artefacts && (
        <>
          <Grid tw="font-bold text-lg mt-2">
            <div tw="col-span-3" />
            <div tw="col-span-1">Total</div>
          </Grid>
          <Grid>
            <strong tw="col-span-3">Common Artefacts</strong>
            <div>{artefacts.common ?? '-'}</div>
          </Grid>
          <Grid>
            <strong tw="col-span-3">Rare Artefacts</strong>
            <div>{artefacts.rare ?? '-'}</div>
          </Grid>
        </>
      )}
    </LeaderboardContainer>
  )
}

const LeaderboardContainer = tw.div`flex flex-col py-6 px-10 w-full font-light`
const LeaderboardTitle = tw.div`text-xl pb-1 mb-4 border-b-2 border-gray-400`
const Grid = tw.div`grid grid-cols-7 gap-2`
