import { theme } from 'twin.macro'
import { EventLocationType, HvntColor, LocationSourceType } from '../../enums'

export const mapLayers = {
  candidate: {
    cell: {
      main: {
        id: 'candidate-cell',
        key: 'candidate-cell',
        source: 'candidate-cells',
        type: 'fill',
        'fill-sort-key': 1,
        paint: {
          'fill-color': theme`colors.gold.600`,
          'fill-opacity': 0.3
        }
      },
      active: {
        id: 'candidate-cell-active',
        key: 'candidate-cell-active',
        type: 'line',
        paint: {
          'line-color': theme`colors.matrix.400`,
          'line-opacity': 0.5,
          'line-width': 5
        }
      },
      border: {
        id: 'candidate-cell-border',
        key: 'candidate-cell-border',
        type: 'line',
        paint: {
          'line-color': theme`colors.gold.600`,
          'line-opacity': 0.3,
          'line-width': 3
        }
      },
      hover: (color) => ({
        id: 'candidate-cell-hover',
        key: 'candidate-cell-hover',
        type: 'fill',
        paint: {
          'fill-color': color ?? 'rgba(0,0,0,0)',
          'fill-opacity': 0.3
        }
      }),
      reviewed: {
        id: 'candidate-cell-reviewed',
        key: 'candidate-cell-reviewed',
        type: 'fill',
        paint: {
          'fill-color': theme`colors.norway.600`,
          'fill-opacity': 0.3
        }
      }
    },
    main: {
      id: 'candidate',
      key: 'candidate',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.gold.600`,
        'circle-stroke-width': 2,
        'circle-stroke-color': theme`colors.gold.800`,
        'circle-radius': {
          stops: [
            [10, 2],
            [14, 6],
            [15, 8]
          ]
        }
      }
    },
    hover: {
      id: 'candidate-hover',
      key: 'candidate-hover',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.gold.400`,
        'circle-stroke-width': 3,
        'circle-stroke-color': theme`colors.gold.600`,
        'circle-radius': {
          stops: [
            [10, 3],
            [14, 7],
            [15, 9]
          ]
        }
      }
    },
    focus: {
      id: 'candidate-focus',
      key: 'candidate-focus',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.terracotta`,
        'circle-stroke-width': 3,
        'circle-stroke-color': theme`colors.terracotta.700`,
        'circle-radius': {
          base: 5,
          stops: [
            [14, 8],
            [15, 9]
          ]
        }
      }
    }
  },
  checkpoint: {
    cluster: {
      id: 'checkpoint-cluster',
      key: 'checkpoint-cluster',
      type: 'circle',
      source: 'checkpoints',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          theme`colors.matrix.600`,
          5,
          theme`colors.amethyst.800`,
          30,
          theme`colors.bismark.700`,
          100,
          theme`colors.bismark.800`
        ],
        'circle-radius': ['step', ['get', 'point_count'], 15, 5, 20, 30, 30, 100, 40]
      }
    },
    clusterCount: {
      id: 'checkpoint-cluster-count',
      key: 'checkpoint-cluster-count',
      type: 'symbol',
      source: 'checkpoints',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': ['step', ['get', 'point_count'], 12, 5, 15, 30, 22, 100, 25]
      },
      paint: {
        'text-color': '#ffffff'
      }
    },
    focus: {
      id: 'checkpoint-focus',
      key: 'checkpoint-focus',
      source: 'checkpoints',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.gold`,
        'circle-stroke-width': 3,
        'circle-stroke-color': theme`colors.gold.800`,
        'circle-radius': {
          stops: [
            [14, 8],
            [15, 9]
          ]
        }
      }
    },
    hover: {
      id: 'checkpoint-hover',
      key: 'checkpoint-hover',
      source: 'checkpoints',
      type: 'circle',
      paint: {
        'circle-color': [
          'case',
          ['==', ['get', 'active'], true],
          theme`colors.amethyst.400`,
          theme`colors.red.400`
        ],
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'active'], true],
          theme`colors.amethyst`,
          theme`colors.red`
        ],
        'circle-stroke-width': 3,
        'circle-radius': {
          stops: [
            [10, 3],
            [14, 7],
            [15, 9]
          ]
        }
      }
    },
    inactive: {
      id: 'checkpoint-inactive',
      key: 'checkpoint-inactive',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.red`,
        'circle-stroke-color': theme`colors.red.700`,
        'circle-stroke-width': 1,
        'circle-radius': {
          stops: [
            [10, 1],
            [14, 4],
            [15, 5]
          ]
        }
      }
    },
    interact: {
      id: 'checkpoint-interact',
      key: 'checkpoint-interact',
      source: 'checkpoints',
      type: 'circle',
      paint: {
        'circle-color': 'rgba(0,0,0,0)',
        'circle-radius': {
          base: 10,
          stops: [
            [14, 16],
            [15, 18]
          ]
        }
      }
    },
    main: {
      id: 'checkpoint',
      key: 'checkpoint',
      source: 'checkpoints',
      type: 'circle',
      paint: {
        'circle-stroke-width': 2,
        'circle-color': [
          'case',
          ['==', ['get', 'active'], true],
          theme`colors.amethyst`,
          theme`colors.red`
        ],
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'active'], true],
          theme`colors.amethyst.700`,
          theme`colors.red.700`
        ],
        'circle-radius': {
          stops: [
            [10, 2],
            [14, 6],
            [15, 8]
          ]
        }
      }
    }
  },
  circle: {
    scan: {
      id: 'circle-scan',
      key: 'circle-scan',
      type: 'fill',
      paint: {
        'fill-color': theme`colors.pink.500`,
        'fill-opacity': 0.4
      }
    },
    border: {
      id: 'circle-border',
      key: 'circle-border',
      type: 'line',
      paint: {
        'line-opacity': 0.6,
        'line-width': 3,
        'line-color': theme`colors.pink.600`
      }
    }
  },
  clue: {
    candidate: {
      id: 'clue-candidate',
      key: 'clue-candidate',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.amethyst.400`,
        'circle-stroke-width': 2,
        'circle-stroke-color': theme`colors.amethyst.600`,
        'circle-radius': {
          base: 3,
          stops: [
            [14, 4],
            [15, 6]
          ]
        }
      }
    }
  },
  country: {
    active: {
      id: 'country-hover',
      key: 'country-hover',
      source: 'countries',
      type: 'fill',
      'source-layer': 'TM_WORLD_BORDERS-0-b9kncb',
      paint: {
        'fill-opacity': 0.5,
        'fill-color': theme`colors.pistachio.600`
      }
    },
    border: (color) => ({
      id: 'country-border',
      key: 'country-border',
      source: 'countries',
      type: 'line',
      'source-layer': 'TM_WORLD_BORDERS-0-b9kncb',
      paint: {
        'line-opacity': 1,
        'line-width': 3,
        'line-color': color ?? 'rgba(0,0,0,0)'
      }
    }),
    highlight: (color) => ({
      id: 'country-highlight',
      key: 'country-highlight',
      source: 'countries',
      type: 'fill',
      'source-layer': 'TM_WORLD_BORDERS-0-b9kncb',
      paint: {
        'fill-opacity': 0.4,
        'fill-outline-color': 'rgba(0,0,0,0)',
        'fill-color': color ?? 'rgba(0,0,0,0)'
      }
    }),
    main: (color) => ({
      id: 'country',
      key: 'country',
      source: 'countries',
      type: 'fill',
      'source-layer': 'TM_WORLD_BORDERS-0-b9kncb',
      paint: {
        'fill-opacity': 0.4,
        'fill-outline-color': 'rgba(0,0,0,0)',
        'fill-color': color ?? 'rgba(0,0,0,0)'
      }
    })
  },
  event: {
    checkpoint: {
      id: 'event-checkpoint',
      key: 'event-checkpoint',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.matrix.200`,
        'circle-stroke-color': theme`colors.matrix.600`,
        'circle-stroke-width': 4,
        'circle-radius': {
          base: 3,
          stops: [
            [10, 5],
            [13, 6],
            [14, 8]
          ]
        }
      }
    },
    location: {
      id: 'event-location',
      key: 'event-location',
      type: 'circle',
      paint: {
        'circle-color': [
          'match',
          ['get', 'type'],
          EventLocationType.AR,
          theme`colors.matrix.400`,
          EventLocationType.HIDDEN,
          theme`colors.gold.400`,
          EventLocationType.LOGO,
          theme`colors.sky`,
          EventLocationType.TRIVIA,
          theme`colors.norway.600`,
          theme`colors.terracotta.100`
        ],
        'circle-stroke-color': [
          'match',
          ['get', 'type'],
          EventLocationType.AR,
          theme`colors.matrix.600`,
          EventLocationType.HIDDEN,
          theme`colors.gold.600`,
          EventLocationType.LOGO,
          theme`colors.sky.700`,
          EventLocationType.TRIVIA,
          theme`colors.norway.800`,
          theme`colors.terracotta.300`
        ],
        'circle-stroke-width': 2,
        'circle-radius': {
          base: 3,
          stops: [
            [10, 5],
            [13, 6],
            [14, 8]
          ]
        }
      }
    }
  },
  hvnt: {
    checkpoint: {
      id: 'hvnt-checkpoint',
      key: 'hvnt-checkpoint',
      type: 'circle',
      paint: {
        'circle-stroke-width': 1,
        'circle-color': [
          'case',
          ['==', ['get', 'custom'], true],
          theme`colors.sky.600`,
          [
            'case',
            ['==', ['get', 'active'], false],
            theme`colors.red.500`,
            theme`colors.amethyst.500`
          ]
        ],
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'custom'], true],
          theme`colors.sky.800`,
          [
            'case',
            ['==', ['get', 'active'], false],
            theme`colors.red.600`,
            theme`colors.amethyst.700`
          ]
        ],
        'circle-radius': {
          stops: [
            [10, 1],
            [14, 5],
            [15, 7]
          ]
        }
      }
    },
    innerCheckpoint: {
      id: 'hvnt-checkpoint-inner',
      key: 'hvnt-checkpoint-inner',
      type: 'circle',
      paint: {
        'circle-color': [
          'case',
          ['==', ['get', 'custom'], true],
          theme`colors.sky.400`,
          theme`colors.pink.400`
        ],
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'custom'], true],
          theme`colors.sky`,
          theme`colors.pink.500`
        ],
        'circle-stroke-width': 2,
        'circle-radius': {
          stops: [
            [10, 2],
            [14, 6],
            [15, 8]
          ]
        }
      }
    },
    main: {
      id: 'hvnt',
      key: 'hvnt',
      type: 'fill',
      paint: {
        'fill-opacity': 0.2,
        'fill-color': [
          'match',
          ['get', 'color'],
          HvntColor[0],
          HvntColor[0],
          HvntColor[1],
          HvntColor[1],
          HvntColor[2],
          HvntColor[2],
          HvntColor[3],
          HvntColor[3],
          HvntColor[4],
          HvntColor[4],
          theme`colors.red.400`
        ]
      }
    },
    border: {
      id: 'hvnt-border',
      key: 'hvnt-border',
      type: 'line',
      paint: {
        'line-opacity': 1,
        'line-width': 2,
        'line-color': [
          'match',
          ['get', 'color'],
          HvntColor[0],
          HvntColor[0],
          HvntColor[1],
          HvntColor[1],
          HvntColor[2],
          HvntColor[2],
          HvntColor[3],
          HvntColor[3],
          HvntColor[4],
          HvntColor[4],
          theme`colors.red.400`
        ]
      }
    },
    route: {
      id: 'hvnt-route',
      key: 'hvnt-route',
      type: 'line',
      layout: {
        'line-cap': 'round'
      },
      paint: {
        'line-color': theme`colors.bismark`,
        'line-opacity': 0.8,
        'line-width': 10,
        'line-gradient': [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          theme`colors.green`,
          0.5,
          theme`colors.gold.600`,
          1,
          theme`colors.red`
        ]
      }
    },
    routeIndex: {
      id: 'hvnt-route-index',
      key: 'hvnt-route-index',
      type: 'symbol',
      source: 'routeData',
      layout: {
        'text-field': '{index}',
        'text-variable-anchor': ['bottom-right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 24
      },
      paint: {
        'text-color': theme`colors.gold.300`,
        'text-halo-color': theme`colors.black.500`,
        'text-halo-width': 2
      }
    },
    routeStart: {
      id: 'hvnt-route-start',
      key: 'hvnt-route-start',
      type: 'fill',
      paint: {
        'fill-color': theme`colors.bismark.400`,
        'fill-opacity': 0.5
      }
    },
    routeStops: {
      id: 'hvnt-route-stops',
      key: 'hvnt-route-stops',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.gold.500`,
        'circle-stroke-width': 2,
        'circle-stroke-color': theme`colors.gold.700`,
        'circle-radius': {
          stops: [
            [10, 2],
            [14, 6],
            [15, 8]
          ]
        }
      }
    }
  },
  location: {
    cell: {
      main: {
        id: 'location-cell',
        key: 'location-cell',
        source: 'location-cells',
        type: 'fill',
        paint: {
          'fill-color': theme`colors.matrix`,
          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.2, 0.4]
        }
      },
      border: {
        id: 'location-cell-border',
        key: 'location-cell-border',
        type: 'line',
        paint: {
          'line-color': theme`colors.matrix.600`,
          'line-opacity': 0.8,
          'line-width': 3
        }
      },
      hover: (color) => ({
        id: 'location-cell-hover',
        key: 'location-cell-hover',
        type: 'fill',
        paint: {
          'fill-color': color ?? 'rgba(0,0,0,0)',
          'fill-opacity': 0.3
        }
      })
    },
    checkpoint: {
      id: 'location-checkpoint',
      key: 'location-checkpoint',
      source: 'checkpoints',
      type: 'circle',
      paint: {
        'circle-color': theme`colors.red.700`,
        'circle-opacity': 1,
        'circle-radius': {
          base: 2,
          stops: [
            [13, 3],
            [14, 5],
            [15, 7]
          ]
        }
      }
    },
    main: {
      id: 'location',
      key: 'location',
      type: 'circle',
      paint: {
        'circle-color': [
          'match',
          ['get', 'locationSource'],
          LocationSourceType.PLAYABLE,
          [
            'case',
            ['boolean', ['get', 'migrated'], false],
            theme`colors.orange.400`,
            ['boolean', ['get', 'generated']],
            theme`colors.red.400`,
            theme`colors.red.600`
          ],
          LocationSourceType.WIKI,
          theme`colors.sky.600`,
          LocationSourceType.MAPBOX,
          theme`colors.red.300`,
          theme`colors.red.100`
        ],
        'circle-stroke-color': [
          'match',
          ['get', 'locationSource'],
          LocationSourceType.PLAYABLE,
          [
            'case',
            ['boolean', ['get', 'migrated'], false],
            theme`colors.orange.500`,
            ['boolean', ['get', 'generated']],
            theme`colors.red.500`,
            theme`colors.red.700`
          ],
          LocationSourceType.WIKI,
          theme`colors.sky.700`,
          LocationSourceType.MAPBOX,
          theme`colors.red.400`,
          theme`colors.red.300`
        ],
        'circle-stroke-width': 1,
        'circle-radius': {
          base: 2,
          stops: [
            [10, 4],
            [13, 5],
            [14, 7]
          ]
        }
      }
    }
  },
  playableCell: {
    main: {
      id: 'playable-cell',
      key: 'playable-cell',
      source: 'playable-cells',
      type: 'fill',
      paint: {
        'fill-color': theme`colors.amethyst.500`,
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0.6],
        'fill-outline-color': theme`colors.amethyst.500`
      }
    },
    border: {
      id: 'playable-cell-border',
      key: 'playable-cell-border',
      type: 'line',
      paint: {
        'line-color': theme`colors.amethyst.700`,
        'line-opacity': 0.7,
        'line-width': 3,
        'line-dasharray': [2, 2]
      }
    }
  },
  s2Cell: {
    main: {
      id: 's2-cell',
      key: 's2-cell',
      source: 's2-cells',
      type: 'fill',
      paint: {
        'fill-color': theme`colors.sky.800`,
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0.6],
        'fill-outline-color': theme`colors.sky.800`
      }
    },
    border: {
      id: 's2-border',
      key: 's2-border',
      type: 'line',
      paint: {
        'line-color': theme`colors.sky.900`,
        'line-opacity': 0.9,
        'line-width': 3,
        'line-dasharray': [2, 2]
      }
    }
  },
  player: {
    location: (color = {}) => ({
      id: 'player-location',
      type: 'circle',
      paint: {
        'circle-opacity': 0.85,
        'circle-color': color.location ?? 'rgba(0,0,0,0)',
        'circle-stroke-width': 2,
        'circle-stroke-color': color.border ?? 'rgba(0,0,0,0)',
        'circle-radius': {
          base: 15,
          stops: [
            [7, 12],
            [12, 10],
            [15, 9]
          ]
        }
      }
    }),
    dataLocations: {
      id: 'data-locations',
      key: 'data-locations',
      source: 'dataLocations',
      type: 'circle',
      paint: {
        'circle-stroke-width': 2,
        'circle-color': theme`colors.amethyst`,
        'circle-stroke-color': theme`colors.amethyst.700`,
        'circle-radius': {
          stops: [
            [10, 2],
            [14, 6],
            [15, 8]
          ]
        }
      }
    },
    locationIndex: {
      id: 'player-location-index',
      key: 'player-location-index',
      source: 'dataLocations',
      type: 'symbol',
      layout: {
        'text-field': '{index}',
        'text-variable-anchor': ['bottom-right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 24
      },
      paint: {
        'text-color': theme`colors.gold.300`,
        'text-halo-color': theme`colors.gold.900`,
        'text-halo-width': 2
      }
    },
    locationRoute: {
      id: 'xp-locations-route',
      key: 'xp-locations-route',
      type: 'line',
      layout: {
        'line-cap': 'round'
      },
      paint: {
        'line-color': theme`colors.bismark`,
        'line-opacity': 0.8,
        'line-width': 10,
        'line-gradient': [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          theme`colors.green`,
          0.5,
          theme`colors.gold.600`,
          1,
          theme`colors.red`
        ]
      }
    }
  },
  usa: {
    states: {
      id: 'us-states',
      key: 'us-states',
      source: 'us-states',
      'source-layer': 'us-states',
      type: 'fill',
      paint: {
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0.5],
        'fill-color': theme`colors.pistachio.600`
      }
    },
    border: {
      id: 'us-states-border',
      key: 'us-states-border',
      'source-layer': 'us-states',
      type: 'line',
      paint: {
        'line-opacity': 0.7,
        'line-width': 2,
        'line-color': theme`colors.pistachio.800`
      }
    }
  }
}
