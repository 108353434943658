import { useCallback } from 'react'
import { actionTypes } from '../../reducers/seasonReducer'

const useGetSeasons = (dispatch) => {
  const onNextSeasons = useCallback(
    (snapshot) => {
      snapshot.docChanges().forEach(async ({ doc, type }) => {
        const season = doc.data()

        if (type === 'added') dispatch({ type: actionTypes.add, season })
        if (type === 'modified') dispatch({ type: actionTypes.edit, season })
        if (type === 'removed') dispatch({ type: actionTypes.remove, season })
      })
    },
    [dispatch]
  )

  return { onNextSeasons }
}

export default useGetSeasons
