import React, { useMemo } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { usePrizes } from '../../../contexts/PrizeContext'
import { PrizeTab } from '../../../enums'
import { Card } from '../../../styles/Layout'
import { Loader } from '../../Loader/Loader'

export const PrizeList = () => {
  const { activeTab, fetchingPrize, prizeTabs, setActiveTab } = usePrizes()
  const colors = useMemo(() => {
    switch (activeTab?.id) {
      case PrizeTab.ARCHIVED:
        return {
          footer: tw`bg-gray-400`,
          header: theme`colors.gray.400`,
          tabs: tw`bg-gray-500`,
          tab: (active) =>
            active ? tw`bg-gray-200` : tw`text-gray-100 bg-gray-500 hover:bg-gray-600 `
        }
      case PrizeTab.DIGITAL:
        return {
          footer: tw`bg-sky`,
          header: theme`colors.sky.500`,
          tabs: tw`bg-sky-600`,
          tab: (active) =>
            active ? tw`bg-gray-200` : tw`text-sky-200 bg-sky-600 hover:bg-sky-700 `
        }
      case PrizeTab.PHYSICAL:
      default:
        return {
          footer: tw`bg-terracotta`,
          header: theme`colors.terracotta.500`,
          tabs: tw`bg-terracotta-600`,
          tab: (active) =>
            active
              ? tw`bg-gray-200`
              : tw`text-terracotta-200 bg-terracotta-600 hover:bg-terracotta-700 `
        }
    }
  }, [activeTab])

  const onClickTab = (tab) => {
    if (activeTab?.id === tab) return
    setActiveTab(prizeTabs[tab])
  }

  return (
    <ColoredCard header={`${activeTab?.title ?? ''} Prizes`} colors={colors}>
      <Tabs>
        {Object.keys(prizeTabs).map((id, i) => (
          <Tab
            key={i}
            color={colors.tab(activeTab?.id === id)}
            onClick={() => onClickTab(PrizeTab[id])}
          >
            {prizeTabs[id].title}
          </Tab>
        ))}
      </Tabs>
      <Loader loading={fetchingPrize} text="Fetching prize" />

      {activeTab?.component()}
      <Card.Footer />
    </ColoredCard>
  )
}

const ColoredCard = styled(Card.Container).attrs(({ colors }) => ({ color: colors.header }))`
  ${tw`transition-colors duration-100`}
  ${({ colors }) => css`
    & > * > ${Tabs} {
      ${colors.tabs}
    }
    & * > ${Card.Footer} {
      ${colors.footer}
    }
  `}
`
const Tabs = tw.div`flex w-full pb-0 gap-2 first:pl-2 transition-colors duration-100`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[100px] text-center rounded-t-md text-lg 
  tracking-wide select-none transition-colors duration-100`}
  ${({ color }) => color}
`
