import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { db } from '../../../lib/firebase'
import { format } from 'date-fns'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { usePlayer } from '../../../contexts/PlayerContext'
import { useGetUser } from '../../../hooks/firebase/useGetUser'
import { Tab } from '../../../layout/Player'
import Icon from '../../../styles/Icons'

export const Statistics = () => {
  const { activePlayer, dispatch } = usePlayer()
  const { isMounted } = useIsMounted()
  const { onGetStats } = useGetUser(dispatch)
  const { activeStatus, authData, stats = {}, uid, userData } = activePlayer

  // Subscribe to snapshot changes
  useEffect(() => {
    if (!isMounted() || !uid) return
    const unsubscribe = db.doc(`/users/${uid}`).onSnapshot(onGetStats)
    return unsubscribe
  }, [isMounted, onGetStats, uid])

  return (
    <Tab.Container>
      <Container>
        <Section>
          <FieldCol>
            <Bold>{'Registered'}</Bold>
            {authData.metadata?.creationTime
              ? format(new Date(authData.metadata?.creationTime), 'LLL do yyy, HH:mm')
              : '-'}
          </FieldCol>

          <FieldCol>
            <Bold tw="flex items-center gap-3">
              {'Last active'}
              {activeStatus?.icon()}
            </Bold>
            {authData.metadata?.lastSignInTime
              ? format(new Date(authData.metadata?.lastRefreshTime), 'LLL do yyy, HH:mm')
              : '-'}
          </FieldCol>

          {userData?.mockLocationDetected && (
            <FieldCol>
              <Bold tw="flex items-center gap-2">
                {'Detected mock locations'}
                <Icon.WarningSolid size="md" tw="text-red" />
              </Bold>
              {userData.mockLocationDetected}
            </FieldCol>
          )}
        </Section>

        <Section>
          <FieldCol>
            <Bold>{'Seasons played'}</Bold>
            {stats.seasons?.played ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Artefacts collected'}</Bold>
            {stats.artefacts?.collected ?? 0}
          </FieldCol>
        </Section>

        <Section>
          <FieldCol>
            <Bold>{'Hvnts started'}</Bold>
            {stats.hvnts?.started ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Hvnts completed'}</Bold>
            {stats.hvnts?.completed ?? 0}
          </FieldCol>
        </Section>

        <Section>
          <FieldCol>
            <Bold>{'Participations started'}</Bold>
            {stats.participations?.started ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Participations completed'}</Bold>
            {stats.participations?.completed ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Participations cancelled'}</Bold>
            {stats.participations?.cancelled ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Participations timed out'}</Bold>
            {stats.participations?.timedOut ?? 0}
          </FieldCol>
        </Section>

        <Section>
          <FieldCol>
            <Bold>{'Checkpoints found'}</Bold>
            {stats.checkpoints?.found ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Clues purchased'}</Bold>
            {stats.clues?.purhcased ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Clues used'}</Bold>
            {stats.clues?.used ?? 0}
          </FieldCol>
        </Section>

        <Section>
          <FieldCol>
            <Bold>{'Coins collected'}</Bold>
            {stats.coins?.collected ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Coins deducted'}</Bold>
            {stats.coins?.deducted ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Coins purchased'}</Bold>
            {stats.coins?.purchased ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Coins rewarded'}</Bold>
            {stats.coins?.rewarded ?? 0}
          </FieldCol>
          <FieldCol>
            <Bold>{'Coins spent'}</Bold>
            {stats.coins?.spent ?? 0}
          </FieldCol>
        </Section>
      </Container>
    </Tab.Container>
  )
}

const Container = tw.div`flex flex-col gap-2 w-full py-2`
const FieldCol = tw.div`flex flex-col`
const Section = tw.div`grid grid-cols-3 gap-4 bg-gray-100 rounded-md p-4 shadow-inner`
const Bold = tw.div`font-semibold text-bismark-700`
