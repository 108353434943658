import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useLocations } from '../../contexts/LocationsContext'
import { CellScanMode } from '../../enums'
import { MapInfo, Overlay } from '../../layout/Map'

export const ScanInfo = () => {
  const { s2Cells } = useLocations()
  const { control } = useFormContext()
  const { scanMode } = useWatch({ control })

  return (
    <Overlay tw="top-2 mx-auto">
      <MapInfo.Container tw="text-base">
        {s2Cells.length
          ? 'You can remove cells by clicking on them'
          : scanMode === CellScanMode.CLICK
          ? 'Click the map to add cells'
          : 'Click the map to add a scan circle'}
      </MapInfo.Container>
    </Overlay>
  )
}
