import React, { useEffect } from 'react'
import { usePlayer } from '../../../contexts/PlayerContext'
import { useGetUser } from '../../../hooks/firebase/useGetUser'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { db } from '../../../lib/firebase'
import { Tab } from '../../../layout/Player'

export const Achievements = () => {
  const { activePlayer, dispatch } = usePlayer()
  const { onNextAchievements } = useGetUser(dispatch)
  const { isMounted } = useIsMounted()
  const { achievements, uid } = activePlayer

  // Subscribe to snapshot changes
  useEffect(() => {
    if (!isMounted() || !uid) return
    const unsubscribe = db.collection(`users/${uid}/achievements`).onSnapshot(onNextAchievements)
    return unsubscribe
  }, [isMounted, onNextAchievements, uid])

  return (
    <Tab.Container>
      <Tab.Section>
        <Tab.Header>Achievements</Tab.Header>
        {JSON.stringify(achievements)}
      </Tab.Section>
    </Tab.Container>
  )
}
