import React, { useCallback } from 'react'
import tw from 'twin.macro'
import { QueryPlayerType } from '../../enums'
import { usePlayer } from '../../contexts/PlayerContext'
import Icon from '../../styles/Icons'
import { Button, Input } from '../../styles/Layout'
import { Controller, useForm } from 'react-hook-form'
import { isEmail, isPhoneNumber } from '../../utility/validationFunctions'
import { Action, List } from '../../layout/List'
import { validate } from '../../utility/formValidation'
import { useGetAuthUser } from '../../hooks/firebase/useGetUser'

export const FindPlayer = () => {
  const { addNewUser, loading, p_dispatch, setLoading } = usePlayer()
  const { onQueryUser } = useGetAuthUser(p_dispatch)
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch
  } = useForm({
    defaultValues: {
      query: ''
    }
  })
  const { query } = watch()

  // Submit form
  const onSubmit = useCallback(
    async ({ query }) => {
      try {
        setLoading(true)
        const type = isPhoneNumber(query)
          ? QueryPlayerType.PHONE_NUMBER
          : isEmail(query)
          ? QueryPlayerType.EMAIL
          : QueryPlayerType.ID

        await onQueryUser({ query, type })
      } catch (err) {
        console.error(err)
        setError('query', { type: 'manual', message: err.message })
      } finally {
        setLoading(false)
      }
    },
    [onQueryUser, setError, setLoading]
  )

  return (
    <Container>
      <SearchContainer>
        <InputContainer>
          <Controller
            control={control}
            name="query"
            render={({ field }) => (
              <InputWrapper>
                <Input.Default
                  {...field}
                  disabled={loading}
                  onChange={({ target }) => field.onChange(target.value?.trim())}
                  placeholder="Email, phone number or id"
                  tw="border-none truncate w-full"
                />
                {field.value && (
                  <Button.White
                    size="sm"
                    disabled={loading}
                    onClick={() => {
                      field.onChange()
                      setError('query')
                    }}
                    tw="m-1.5 p-1 rounded-md"
                  >
                    <Icon.Close size="sm" />
                  </Button.White>
                )}
              </InputWrapper>
            )}
            rules={{ validate: validate.player.query }}
          />

          <Button.Primary
            ring
            disabled={loading || !query}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            size="md"
            tw="gap-1"
          >
            <Icon.Search size="sm" mr="2" />
            Search
          </Button.Primary>
        </InputContainer>
        {errors.query?.message && <Input.Error tw="self-start">{errors.query.message}</Input.Error>}
      </SearchContainer>

      <Action.Header>
        {/* // todo for now */}
        <Action.Right>
          <Button.Primary ring size="sm" onClick={addNewUser} disabled={true}>
            <Icon.Plus size="sm" mr="2" />
            Add New User
          </Button.Primary>
        </Action.Right>
      </Action.Header>
    </Container>
  )
}

const Container = tw(List.Filter)`sticky top-0 pt-6 pb-3 gap-4`
const InputContainer = tw.form`flex justify-center w-full gap-3`
const InputWrapper = tw.div`relative flex w-full items-center rounded-md overflow-hidden bg-white-pure`
const SearchContainer = tw.div`flex flex-col w-full px-4 sm:w-4/5 lg:w-full xl:w-3/4 self-center`
