import { generic } from './utility/axiosDefaults'
import config from './config'
import { Toast } from './utility/alerts'
import { Buffer } from 'buffer'

class CustomError extends Error {
  constructor(message) {
    super(message)
    this.name = 'custom'
  }
}

export default {
  getPlayableLocations: ({ maxLocations, s2CellId }) =>
    generic
      .post(
        `https://playablelocations.googleapis.com/v3:samplePlayableLocations?key=${process.env.REACT_APP_GOOGLE_MAPS_API_SECRET}`,
        JSON.stringify({
          areaFilter: {
            s2CellId
          },
          criteria: [
            {
              filter: {
                maxLocationCount: maxLocations,
                spacing: {
                  minSpacingMeters: 50
                }
              },
              fields_to_return: {
                paths: ['types', 'snapped_point']
              }
            }
          ]
        }),
        {
          headers: {
            'Content-Type': 'text/plain'
          }
        }
      )
      .then(({ data }) => {
        if (data?.locationsPerGameObjectType) return data.locationsPerGameObjectType[0].locations
        else throw new CustomError('No data')
      })
      .catch((err) => {
        if (err.name === 'custom') throw err.message
        if (err.message?.startsWith('timeout')) {
          Toast.fire({
            title: 'Request timed out',
            icon: 'error'
          })
          throw 'Request timed out'
        }
        throw err
      }),
  getTileFeaturesFromCoordinates: ({
    tileset_id,
    latitude,
    longitude,
    layers,
    limit = 50,
    radius = 10
  }) =>
    generic
      .get(`https://api.mapbox.com/v4/${tileset_id}/tilequery/${longitude},${latitude}.json`, {
        params: {
          limit,
          access_token: process.env.REACT_APP_MAPBOX_SECRET,
          layers,
          radius
        }
      })
      .then(({ data }) => data),
  getTreasureMap: ({
    latitude,
    longitude,
    lngOffset,
    latOffset,
    zoom,
    rotation,
    bearing = 0,
    size = '700x500'
  }) =>
    generic
      .get(
        `${config.mapbox.TREASURE_MAP_API}/static/url-${
          config.mapbox.TREASURE_X
        }(${longitude},${latitude})/${parseFloat(longitude) + lngOffset},${
          parseFloat(latitude) + latOffset
        },${zoom},${rotation},${bearing}/${size}`,
        {
          responseType: 'arraybuffer',
          params: {
            access_token: process.env.REACT_APP_MAPBOX_SECRET
          }
        }
      )
      .then(({ data }) => {
        const dataURI = Buffer.from(data, 'binary').toString('base64')
        return 'data:image/png;base64,' + dataURI
      })
      .catch((err) => {
        console.error(err)
        if (err.message?.startsWith('timeout')) {
          Toast.fire({
            title: 'Request timed out',
            icon: 'error'
          })
          throw 'Request timed out'
        } else throw 'Error generating treasure map'
      }),
  getStaticHvntMap: ({ hvntFeature, latitude, longitude, zoom }) =>
    generic
      .get(
        `${config.mapbox.STATIC_HVNT_MAP_API}/static/geojson(${hvntFeature})/${longitude},${latitude},${zoom},0,0/720x1280`,
        {
          responseType: 'arraybuffer',
          params: {
            attribution: false,
            logo: false,
            access_token: process.env.REACT_APP_MAPBOX_SECRET
          }
        }
      )
      .then(({ data }) => {
        const dataURI = Buffer.from(data, 'binary').toString('base64')
        return 'data:image/png;base64,' + dataURI
      })
      .catch((err) => {
        if (err.message?.startsWith('timeout')) {
          Toast.fire({
            title: 'Request timed out',
            icon: 'error'
          })
          throw 'Request timed out'
        } else throw 'Error generating static hvnt map'
      }),
  downloadImage: (imageUrl) =>
    generic
      .get(`${imageUrl}`, {
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        const length = data.byteLength
        if (length > config.MAX_IMAGE_UPLOAD_SIZE) {
          throw new CustomError('Image is too big!')
        }
        const ext = imageUrl.split('.').pop()
        const dataURI = Buffer.from(data, 'binary').toString('base64')
        return `data:image/${ext};base64,` + dataURI
      })
      .catch((err) => {
        if (err.name === 'custom') throw err.message
        if (err.message?.startsWith('timeout')) {
          Toast.fire({
            title: 'Request timed out',
            icon: 'error'
          })
        }
        throw "This url doesn't seem to work!"
      }),
  getPOIs: ({ centerPoint, types = 'poi' }) =>
    generic
      .get(`${config.mapbox.GEOCODE_API}/${centerPoint.longitude},${centerPoint.latitude}.json`, {
        params: {
          access_token: process.env.REACT_APP_MAPBOX_SECRET,
          autocomplete: false,
          types,
          limit: 5
        }
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err.message?.startsWith('timeout')) {
          Toast.fire({
            title: 'Request timed out',
            icon: 'error'
          })
          throw 'Request timed out'
        }
        throw err.message || 'mapbox geocoding api failed'
      }),
  getTags: ({ centerPoint }) =>
    generic
      .get(`${config.mapbox.GEOCODE_API}/${centerPoint.longitude},${centerPoint.latitude}.json`, {
        params: {
          access_token: process.env.REACT_APP_MAPBOX_SECRET,
          autocomplete: false,
          limit: 1
        }
      })
      .then(({ data }) => {
        const tags = []
        data.features[0] &&
          data.features[0].context.forEach((item) => {
            if (item.id.startsWith('place') || item.id.startsWith('country')) {
              const tag = item.text.toLowerCase()
              if (!tags.includes(tag)) tags.push(tag)
            }
          })
        return tags.map((item) => ({ value: item, label: item })) || []
      }),
  getStaticStreetView: ({ centerPoint, heading = 0, radius = 50 }) =>
    generic
      .get('https://maps.googleapis.com/maps/api/streetview', {
        responseType: 'arraybuffer',
        params: {
          heading,
          key: process.env.REACT_APP_GOOGLE_MAPS_API_SECRET,
          location: `${centerPoint.latitude}, ${centerPoint.longitude}`,
          radius,
          return_error_code: true,
          size: '640x640' // Max size
        }
      })
      .then(({ data }) => {
        const dataURI = Buffer.from(data, 'binary').toString('base64')
        return 'data:image/jpeg;base64,' + dataURI
      })
      .catch((err) => {
        if (err.message?.startsWith('timeout')) {
          Toast.fire({
            title: 'Request timed out',
            icon: 'error'
          })
          throw 'Request timed out'
        }
        throw err.message || 'Error Fetching Street View Image'
      })
}
