import React from 'react'
import Icon from '../styles/Icons'
import { theme } from 'twin.macro'
import uniq from 'lodash/uniq'

export const HvntTab = Object.freeze({
  REGULAR: 'HVNT_REGULAR',
  UNLISTED: 'HVNT_UNLISTED'
})

export const HvntFormTab = Object.freeze({
  DETAILS: 'DETAILS',
  AREA: 'AREA',
  CHALLENGES: 'CHALLENGES'
})

export const HvntStatusType = Object.freeze({
  DRAFT: 'DRAFT',
  ENDED: 'ENDED',
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING'
})

export const HvntStatus = Object.freeze({
  DRAFT: {
    icon: Object.assign((size = 'lg') => (
      <Icon.Clipboard color={theme`colors.gray.600`} size={size} />
    )),
    message: 'DRAFT',
    removable: true,
    routeLocked: false,
    startLocked: false,
    sort: 5,
    validate: false
  },
  ENDED: {
    icon: Object.assign((size = 'lg') => <Icon.Flag color={theme`colors.gray.600`} size={size} />),
    message: 'ENDED',
    removable: true,
    routeLocked: true,
    startLocked: true,
    sort: 6,
    validate: true
  },
  ERROR: {
    icon: Object.assign((size = 'lg') => <Icon.Error color={theme`colors.orange`} size={size} />),
    message: 'ERROR',
    removable: false,
    routeLocked: false,
    startLocked: false,
    sort: 1,
    validate: true
  },
  LIVE: {
    icon: Object.assign((size = 'lg') => <Icon.Live color={theme`colors.green`} size={size} />),
    message: 'LIVE',
    removable: false,
    routeLocked: true,
    startLocked: true,
    sort: 4,
    validate: true
  },
  PAUSED: {
    icon: Object.assign((size = 'lg') => <Icon.Pause color={theme`colors.gray.600`} size={size} />),
    message: 'PAUSED',
    removable: false,
    routeLocked: true,
    startLocked: true,
    sort: 2,
    validate: true
  },
  UPCOMING: {
    icon: Object.assign((size = 'lg') => <Icon.Clock color={theme`colors.green`} size={size} />),
    message: 'UPCOMING',
    removable: false,
    routeLocked: false,
    startLocked: false,
    sort: 3,
    validate: true
  },
  UNSET: {
    icon: Object.assign((size = 'lg') => (
      <Icon.DotsHorizontal color={theme`colors.gray.600`} size={size} />
    )),
    message: 'UNSET',
    removable: true,
    routeLocked: false,
    startLocked: false,
    sort: 7,
    validate: true
  }
})

export const ChallengeDistributionType = Object.freeze({
  NONE: 'NONE',
  FIRST: 'FIRST',
  EVERY: 'EVERY',
  EVEN: 'EVEN',
  ODD: 'ODD',
  EVERY_THIRD: 'EVERY_THIRD',
  LAST: 'LAST'
})

export const HvntRouteStatusType = Object.freeze({
  ERROR: 'ERROR',
  OK: 'OK',
  WARNING: 'WARNING'
})

export const RouteStatus = Object.freeze({
  ERROR: {
    icon: <Icon.Close color={theme`colors.red`} />,
    info: 'Routes Invalid!',
    valid: false
  },
  OK: {
    icon: <Icon.Check color={theme`colors.green`} />,
    info: 'Routes OK!',
    valid: true
  },
  WARNING: {
    icon: <Icon.Warning color={theme`colors.orange`} />,
    info: 'Routes Invalid!',
    valid: false
  }
})

export const HvntColor = Object.freeze({
  0: theme`colors.terracotta`,
  1: theme`colors.gold`,
  2: theme`colors.norway`,
  3: theme`colors.sky`,
  4: theme`colors.amethyst`
})

export const HvntFilters = Object.freeze({
  ACTIVE: {
    field: 'active',
    filter: (arr, value) =>
      value ? arr.filter((item) => item.active) : arr.filter((item) => !item.active)
  },
  DRAFT: {
    field: 'draft',
    filter: (arr) =>
      arr.filter(
        ({ status }) => status === HvntStatus.DRAFT || status === HvntStatus.DRAFT_INCOMPLETE
      )
  },
  ENDED: {
    field: 'ended',
    filter: (arr, value) =>
      value
        ? arr.filter(
            ({ status }) => status === HvntStatus.ENDED || status === HvntStatus.ENDED_INACTIVE
          )
        : arr.filter(
            ({ status }) => status !== HvntStatus.ENDED && status !== HvntStatus.ENDED_INACTIVE
          )
  },
  ERROR: {
    field: 'error',
    filter: (arr) =>
      arr.filter((item) => item.errors?.length || item.clues?.some((clue) => clue.errors?.length))
  },
  FUTURE: {
    field: 'future',
    filter: (arr, value) =>
      value
        ? arr.filter(({ dateRange }) => new Date(dateRange?.startDate) > new Date())
        : arr.filter((item) => new Date(item.dateRange?.endDate) > new Date())
  },
  FEATURED: {
    field: 'featured',
    filter: (arr, value) =>
      value ? arr.filter((item) => item.featured) : arr.filter((item) => !item.featured)
  },
  LIVE: {
    field: 'live',
    filter: (arr) => arr.filter(({ status }) => status === HvntStatus.LIVE)
  },
  LIVE_SOON: {
    field: 'live_soon',
    filter: (arr) => arr.filter(({ status }) => status === HvntStatus.UPCOMING)
  },
  ONGOING: {
    field: 'ongoing',
    filter: (arr) =>
      arr.filter(
        ({ dateRange }) =>
          new Date(dateRange?.startDate) < new Date() && new Date(dateRange?.endDate) > new Date()
      )
  },
  PAUSED: {
    field: 'paused',
    filter: (arr) => arr.filter(({ status }) => status === HvntStatus.PAUSED)
  },
  QUERY: {
    field: 'query',
    filter: (arr, value) => {
      const filteredCategories = arr.filter(
        (item) => item.category && item.category.label.toLowerCase().indexOf(value) !== -1
      )
      const filteredDescriptions = arr.filter(
        (item) => item.description && item.description.toLowerCase().indexOf(value) !== -1
      )
      const filteredIds = arr.filter((item) => item.id?.toLowerCase().indexOf(value) !== -1)
      const filteredNames = arr.filter(
        (item) => item.name && item.name?.toLowerCase().indexOf(value) !== -1
      )
      const filteredPrizeName = arr.filter(
        (item) => item.prize?.name && item.prize.name.toLowerCase().indexOf(value) !== -1
      )
      const filteredPrizeDescription = arr.filter(
        (item) =>
          item.prize?.description && item.prize.description.toLowerCase().indexOf(value) !== -1
      )
      const filteredTags = arr.filter((item) =>
        item.tags?.some((tag) => tag.label?.indexOf(value) !== -1)
      )

      return uniq(
        filteredNames
          .concat(filteredIds)
          .concat(filteredDescriptions)
          .concat(filteredCategories)
          .concat(filteredPrizeName)
          .concat(filteredPrizeDescription)
          .concat(filteredTags)
      )
    }
  },
  TAGS: {
    field: 'tags',
    filter: (arr, tags) =>
      arr.filter((item) => item.tags?.some((tag) => tags?.some((ref) => ref.value === tag.value)))
  }
})

export const HvntSorts = Object.freeze({
  ACTIVE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => !!b.active - !!a.active)
      : arr?.sort((a, b) => !!a.active - !!b.active),
  END_DATE: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.dateRange?.endDate
      const bHas = !!b.dateRange?.endDate
      if (aHas && bHas)
        return inc
          ? b.dateRange?.endDate - a.dateRange?.endDate
          : a.dateRange?.endDate - b.dateRange?.endDate
      return aHas ? -1 : bHas ? 1 : 0
    }),
  NAME: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.name?.localeCompare(b.name))
      : arr?.sort((a, b) => b.name?.localeCompare(a.name)),
  NUM_CHECKPOINTS: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => b.numberOfCheckpoints - a.numberOfCheckpoints)
      : arr?.sort((a, b) => a.numberOfCheckpoints - b.numberOfCheckpoints),
  START_DATE: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.dateRange?.startDate
      const bHas = !!b.dateRange?.startDate
      if (aHas && bHas)
        return inc
          ? b.dateRange?.startDate - a.dateRange?.startDate
          : a.dateRange?.startDate - b.dateRange?.startDate
      return aHas ? -1 : bHas ? 1 : 0
    }),
  STATUS: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.status?.sort - b.status?.sort)
      : arr?.sort((a, b) => b.status?.sort - a.status?.sort),
  UPDATED: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.updatedAt
      const bHas = !!b.updatedAt
      if (aHas && bHas) return inc ? b.updatedAt - a.updatedAt : a.updatedAt - b.updatedAt
      return aHas ? -1 : bHas ? 1 : 0
    })
})

export const ChallengeDistributionFilters = Object.freeze({
  ALL: {
    field: 'all'
  },
  NONE: {
    field: 'all'
  },
  FIRST: {
    field: 'first'
  },
  LAST: {
    field: 'last'
  },
  EVEN: {
    field: 'even'
  },
  ODD: {
    field: 'even'
  },
  EVERY_THIRD: {
    field: 'third'
  }
})
