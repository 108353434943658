import React, { useState, useCallback, useMemo, useRef } from 'react'
import tw, { theme } from 'twin.macro'
import { Loader } from '../../Loader/Loader'
import { Map, NavigationControl, GeolocateControl, Source, Layer } from 'react-map-gl'
import { MapSwitcher } from '../../Map/MapSwitcher'
import config from '../../../config'
import { MapControl } from '../../Map/MapControl'
import { useMap } from '../../../contexts/MapContext'
import { mapLayers } from '../../Map/MapLayers'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useMapControl } from '../../../hooks/useMapControl'
import { useDebounce } from 'react-use'
import { useCorporate } from '../../../contexts/CorporateContext'
import { arrayToFeatures } from '../../../utility/helperFunctions'
import Popups from './Popup'
import { EventFormTab } from '../../../enums'

export const CreateEventMap = ({ locations, locked }) => {
  const mapRef = useRef(null)
  const {
    checkpoints,
    expandedLocationItem,
    loading,
    setActiveFormTab,
    setExpandedLocationItem,
    startEditingEventLocation
  } = useCorporate()
  const { focusedBbox, focusedMarker } = useMap()
  const { containerRef, zoomToBbox, zoomToPoint } = useMapControl(mapRef)
  const { isMounted } = useIsMounted()

  const locationData = useMemo(() => arrayToFeatures(locations), [locations])
  const checkpointData = useMemo(() => arrayToFeatures(checkpoints), [checkpoints])
  const [busy, setBusy] = useState(true)
  const [hoveredItem, setHoveredItem] = useState()
  const [mapStyle, setMapStyle] = useState(config.mapbox.ALTERNATE_MAP)
  const [cursor, setCursor] = useState('auto')
  const [viewstate, setViewstate] = useState({
    longitude: 18.078419,
    latitude: 59.342644,
    zoom: 3,
    maxZoom: 16
  })

  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  const onClick = useCallback(
    async (event) => {
      if (locked) return
      const { layer, properties } = event.features[0] ?? {}
      setActiveFormTab(EventFormTab.LOCATIONS)
      // Clicked layer
      switch (layer?.id) {
        case 'event-location': {
          return setExpandedLocationItem(properties)
        }
        default: {
          setExpandedLocationItem()
        }
      }
    },
    [locked, setActiveFormTab, setExpandedLocationItem]
  )

  const onHover = useCallback(({ features = [] }) => {
    const { layer, properties } = features[0] ?? {}
    if (!properties) return

    setCursor('pointer')
    switch (layer?.id) {
      case 'event-location': {
        return setHoveredItem(properties)
      }
    }
  }, [])

  const onMouseLeave = useCallback(() => {
    setCursor('auto')
    setHoveredItem()
  }, [])

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedMarker) return zoomToPoint(focusedMarker)
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    400,
    [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint]
  )

  return useMemo(
    () => (
      <MapContainer data-testid="map" ref={containerRef}>
        <Loader loading={busy || loading} text="Loading Map" />

        <MapSwitcher onSwitch={setMapStyle} bottom left />
        <Map
          {...viewstate}
          cursor={cursor}
          id="checkpointMap"
          interactiveLayerIds={busy ? null : ['event-location']}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
          mapStyle={mapStyle}
          onClick={onClick}
          onLoad={() => setBusy(false)}
          onMouseLeave={onMouseLeave}
          onMouseMove={onHover}
          onMove={onMoveMap}
          ref={mapRef}
          style={{ width: '100vw', height: '100%' }}
        >
          <Source id="locations" type="geojson" data={locationData}>
            <Layer {...mapLayers.event.location} />
          </Source>

          <Source id="checkpoints" type="geojson" data={checkpointData}>
            <Layer {...mapLayers.event.checkpoint} />
          </Source>

          <Popups
            color={theme`colors.matrix`}
            focusedItem={expandedLocationItem}
            hoveredItem={hoveredItem}
            onEdit={startEditingEventLocation}
            onClose={() => setExpandedLocationItem()}
          />

          <MapControl>
            <GeolocateControl
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
            />
            <NavigationControl />
          </MapControl>
        </Map>
      </MapContainer>
    ),
    [
      busy,
      checkpointData,
      containerRef,
      cursor,
      expandedLocationItem,
      hoveredItem,
      loading,
      locationData,
      mapStyle,
      onClick,
      onHover,
      onMouseLeave,
      onMoveMap,
      setExpandedLocationItem,
      startEditingEventLocation,
      viewstate
    ]
  )
}

const MapContainer = tw.div`relative flex overflow-hidden justify-center items-center`
