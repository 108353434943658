import React, { useCallback, useEffect, useMemo } from 'react'
import { Card } from '../../styles/Layout'
import tw, { theme } from 'twin.macro'
import { Country } from './CountryItem'
import { useAreas } from '../../contexts/AreasContext'
import { db } from '../../lib/firebase'
import { Alerts } from '../../utility/alerts'

export const AreasList = () => {
  const {
    adding,
    cityBeingEdited,
    countries,
    countryBeingEdited,
    stopEditingCountry,
    focusedMarker,
    expandedCountry,
    setExpandedCountry
  } = useAreas()

  // Expand country when country is edited
  useEffect(() => {
    if (!focusedMarker) return
    const focus = countries.find((c) => c.id === focusedMarker.id)
    focus && setExpandedCountry(focus)
  }, [focusedMarker, countries, setExpandedCountry])

  // Expand country when city is edited
  useEffect(() => {
    if (!cityBeingEdited) return
    const focus = countries.find((c) => c.cities.some((c) => c.name === cityBeingEdited.name))
    focus && setExpandedCountry(focus)
  }, [cityBeingEdited, countries, setExpandedCountry])

  const onActivateCountry = useCallback(
    async ({ id, name }) => {
      const countryRef = db.collection('playable-areas').doc(id)
      const { value: ok, dismiss } = await Alerts.Area.ACTIVATE_COUNTRY(name)

      if (ok) {
        await db.runTransaction(async (transaction) => {
          const countryDoc = await transaction.get(countryRef)
          const cities = countryDoc.get('cities')
          cities &&
            Object.keys(cities).forEach((city) =>
              transaction.set(countryRef, { cities: { [city]: { active: true } } }, { merge: true })
            )
          transaction.set(countryRef, { active: true }, { merge: true })
        })
        // 'Cancel' here means 'activate only country'
      } else if (dismiss === 'cancel') {
        countryRef.set({ active: true }, { merge: true })
      } else return
      stopEditingCountry()
    },
    [stopEditingCountry]
  )

  const onDeactivateCountry = useCallback(
    async ({ id, name }) => {
      const countryRef = db.collection('playable-areas').doc(id)

      const { value: ok } = await Alerts.Area.DEACTIVATE_COUNTRY(name)
      if (!ok) return

      await db.runTransaction(async (transaction) => {
        const countryDoc = await transaction.get(countryRef)
        const cities = countryDoc.get('cities')
        cities &&
          Object.keys(cities).forEach((city) =>
            transaction.set(countryRef, { cities: { [city]: { active: false } } }, { merge: true })
          )

        transaction.set(countryRef, { active: false }, { merge: true })
      })
      stopEditingCountry()
    },
    [stopEditingCountry]
  )

  const onDeleteCountry = useCallback(
    async ({ id, name }) => {
      const countryRef = db.collection('playable-areas').doc(id)

      const { value: ok } = await Alerts.Area.DELETE_COUNTRY(name)
      if (!ok) return

      await db.runTransaction(async (transaction) => {
        transaction.delete(countryRef)
      })
      stopEditingCountry()
    },
    [stopEditingCountry]
  )

  return useMemo(
    () => (
      <Card.Container header="Playable Areas" color={theme`colors.sky`}>
        <Container>
          {countries.map((country, i) => (
            <Country
              key={i}
              expanded={country.id === expandedCountry?.id}
              editing={country.id === countryBeingEdited?.id}
              country={country}
              disabled={adding || countryBeingEdited || cityBeingEdited}
              onActivate={onActivateCountry}
              onDeactivate={onDeactivateCountry}
              onDelete={onDeleteCountry}
            />
          ))}
        </Container>
      </Card.Container>
    ),
    [
      adding,
      cityBeingEdited,
      countries,
      countryBeingEdited,
      expandedCountry,
      onActivateCountry,
      onDeactivateCountry,
      onDeleteCountry
    ]
  )
}
const Container = tw.div`relative flex flex-col w-full p-2 my-2`
