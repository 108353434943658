import request from '../request'
import { point } from '@turf/helpers'
import bearing from '@turf/bearing'
import { getOption, randomIntFromInterval } from './helperFunctions'
import { randomInt } from './helperFunctions'
import { objectIsEmpty } from './validationFunctions'
import { db, functions } from '../lib/firebase'
import { distanceBetween } from 'geofire-common'
import { ClueType } from '../enums'
import { CLUE_TYPE_OPTIONS, DIFFICULTY_OPTIONS, TREASURE_ZOOM_OPTIONS } from './labeledOptions'

export const createClueFromType = async (type, checkpointPath, options = {}) => {
  const { difficulty = getOption(2, DIFFICULTY_OPTIONS), auto = true } = options
  // Create clue doc
  const clueDoc = db.collection(`${checkpointPath}/clues`).doc()

  let clue = { id: clueDoc.id, path: clueDoc.path }
  switch (type) {
    case ClueType.IMAGE: {
      const { imageType, attribution, url } = options
      switch (imageType) {
        case 'placePhoto': {
          clue = createImageClue({ auto, difficulty, url, imageType, attribution })
          break
        }
        default: {
          clue = createImageClue({ auto, difficulty, url, imageType, attribution })
          break
        }
      }
      break
    }
    case ClueType.TREASURE: {
      const {
        centerPoint: { longitude, latitude },
        rotated = false,
        zoom = getOption(16, TREASURE_ZOOM_OPTIONS)
      } = options

      const { lngOffset, latOffset } = offsetFromZoom(zoom)
      const params = {
        latitude,
        longitude,
        latOffset,
        lngOffset,
        zoom: zoom.value,
        rotation: rotated ? randomIntFromInterval(1, 360) : 0
      }
      const dataURI = await request.getTreasureMap(params)

      clue = {
        active: true,
        auto,
        candidate: auto,
        difficulty,
        imageData: dataURI,
        options,
        type: getOption(ClueType.TREASURE, CLUE_TYPE_OPTIONS),
        zoom
      }
      break
    }
    case ClueType.POI: {
      const { poiType, centerPoint } = options
      const { features } = await request.getPOIs({ centerPoint, types: poiType })
      const poiClues = []
      features.forEach((poi) => {
        // Create separate clueDocs
        const descriptionDoc = db.collection(`${checkpointPath}/clues`).doc()
        const distanceDoc = db.collection(`${checkpointPath}/clues`).doc()
        poiClues.push({
          ...createPoiClue({ poi, difficulty }),
          id: descriptionDoc.id,
          path: descriptionDoc.path
        })
        poiClues.push({
          ...createPoiClue({ poi, difficulty, centerPoint }),
          id: distanceDoc.id,
          path: distanceDoc.path
        })
      })
      return poiClues
    }
    case ClueType.STREET_VIEW: {
      const { adding, checkpoint } = options
      const { data } = await functions.clue.createStreetView(
        adding
          ? {
              centerPoint: checkpoint.centerPoint,
              id: db.collection(`${checkpointPath}/clues`).doc().id
            }
          : { checkpointPath }
      )
      const { active, difficulty, id, imageText, imageUrl, type, ...metadata } = data
      clue = {
        active,
        candidate: true,
        difficulty: getOption(difficulty, DIFFICULTY_OPTIONS),
        id,
        imageText,
        imageUrl,
        metadata,
        type: getOption(type, CLUE_TYPE_OPTIONS)
      }
      break
    }
    case 'plusCode': {
      const { plusCode } = options
      clue = {
        active: true,
        auto,
        candidate: auto,
        difficulty,
        text: plusCode,
        type: getOption(ClueType.TEXT, CLUE_TYPE_OPTIONS)
      }
      break
    }
    case 'phoneNumber': {
      const { phoneNumber } = options
      clue = {
        active: true,
        auto,
        candidate: auto,
        difficulty,
        text: phoneNumber,
        type: getOption(ClueType.TEXT, CLUE_TYPE_OPTIONS)
      }
      break
    }
    case 'website': {
      const { website } = options
      clue = {
        active: true,
        auto,
        candidate: auto,
        difficulty,
        text: website,
        type: getOption(ClueType.TEXT, CLUE_TYPE_OPTIONS)
      }
      break
    }
    case 'coordinates': {
      const {
        centerPoint: { latitude, longitude }
      } = options
      clue = {
        active: true,
        auto,
        candidate: auto,
        difficulty,
        text: `${latitude},${longitude}`,
        type: getOption(ClueType.TEXT, CLUE_TYPE_OPTIONS)
      }

      break
    }
  }

  return !objectIsEmpty(clue) && { ...clue, id: clueDoc.id, path: clueDoc.path }
}

const createImageClue = ({ auto, attribution = '', difficulty, imageType, url }) => {
  let imageClue = {
    active: true,
    auto,
    candidate: auto,
    difficulty,
    imageUrl: url,
    imageType,
    type: getOption(ClueType.IMAGE, CLUE_TYPE_OPTIONS)
  }
  switch (imageType) {
    case 'placePhoto': {
      const licenseAuthor = attribution[0].split('>')[1].split('<')[0]
      const licenseSource = attribution[0].split('"')[1]
      const licenseType = { value: 'google', label: 'Google Place Photo' }
      imageClue = { ...imageClue, attribution, licenseAuthor, licenseSource, licenseType }
    }
  }
  return imageClue
}

const createPoiClue = ({ poi, difficulty, centerPoint = undefined }) => {
  let clue = {
    active: true,
    auto: true,
    candidate: true,
    centerPoint: { latitude: poi.center[1], longitude: poi.center[0] },
    difficulty,
    place: poi.text,
    placeType: poi.place_type[0],
    textType: centerPoint ? 'distance' : 'description',
    type: getOption(ClueType.POI, CLUE_TYPE_OPTIONS)
  }
  return {
    ...clue,
    text: centerPoint ? generateDistance({ poi: clue, centerPoint }) : generateDescription(clue)
  }
}

export const generateDescription = ({ placeType, place }) => {
  switch (placeType) {
    case 'poi':
      return poiDescriptions(place)[randomInt() % poiDescriptions().length]
    case 'address':
      return addressDescriptions(place)[randomInt() % addressDescriptions().length]
    default:
      return ''
  }
}

export const generateDistance = ({ poi, centerPoint }) => {
  const distance =
    Math.round(
      distanceBetween(
        [poi.centerPoint.latitude, poi.centerPoint.longitude],
        [centerPoint.latitude, centerPoint.longitude]
      ) * 100
    ) * 10

  const direction = bearingToCardinal(
    bearing(
      point([poi.centerPoint.longitude, poi.centerPoint.latitude]),
      point([centerPoint.longitude, centerPoint.latitude])
    )
  )
  return distanceDescriptions(poi.place, distance, direction)[
    randomInt() % distanceDescriptions().length
  ]
}

const bearingToCardinal = (bearing) => {
  if (bearing >= -157.5 && bearing < -112.5) {
    return 'South West'
  }
  if (bearing >= -112.5 && bearing < -67.5) {
    return 'West'
  }
  if (bearing >= -67.5 && bearing < -22.5) {
    return 'North West'
  }
  if (bearing >= -22.5 && bearing < 22.5) {
    return 'North'
  }
  if (bearing >= 22.5 && bearing < 67.5) {
    return 'North East'
  }
  if (bearing >= 67.5 && bearing < 112.5) {
    return 'East'
  }
  if (bearing >= 112.5 && bearing < 157.5) {
    return 'South East'
  }
  if (bearing < -157.5 || bearing > 157.5) {
    return 'South'
  }
}

const poiDescriptions = (place) => [
  `Close to ${place}`,
  `Close to where ${place} is`,
  `Not far off from ${place}`,
  `Near ${place}`,
  `In the vicinity of ${place}`,
  `Look for ${place}`,
  `Right by ${place}`,
  `Neighboring ${place}`,
  `Finding ${place} might help`,
  `A short distance away from ${place}`,
  `Just around the corner from ${place}`,
  `Pretty close to ${place}`,
  `If you find ${place}, you're almost there`,
  `If you find ${place}, you're super close`,
  `Next to ${place}`,
  `It's right next to ${place}`
]

const addressDescriptions = (place) => [
  `Close to ${place}`,
  `Take a walk along ${place}`,
  `Look for ${place}`,
  `Near ${place}`,
  `Right next to ${place}`,
  `Right along ${place}`,
  `Finding ${place} might help`,
  `You should check out ${place}`
]

const distanceDescriptions = (place, distance, direction) => [
  `${distance}m from ${place}`,
  `${distance}m ${direction} of ${place}`,
  `It's ${direction} of ${place}`,
  `${direction} of ${place}`,
  `Walk ${direction} from ${place}`,
  `Find ${place} and head ${direction}`,
  `Find ${place} and head ${direction} for ${distance}m`
]

const offsetFromZoom = (zoom) => {
  switch (zoom?.value) {
    case 14:
    case 15:
      return { lngOffset: (Math.random() * 2 - 1) / 200, latOffset: (Math.random() * 2 - 1) / 500 } // [.005, .002] 700x500
    case 16:
      return { lngOffset: (Math.random() * 2 - 1) / 300, latOffset: (Math.random() * 2 - 1) / 1000 } // [.0033, .001] 700x500
    case 17:
      return { lngOffset: (Math.random() * 2 - 1) / 650, latOffset: (Math.random() * 2 - 1) / 2000 } // [.00154, .0005] 700x500
    case 18:
      return {
        lngOffset: (Math.random() * 2 - 1) / 1400,
        latOffset: (Math.random() * 2 - 1) / 5000
      } // [.0007, .0002] 700x500
  }
}
