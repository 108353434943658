import React from 'react'
import tw from 'twin.macro'
import { Button, Input } from '../../styles/Layout'
import { useFormContext } from 'react-hook-form'
import { isInteger } from '../../utility/validationFunctions'
import Icon from '../../styles/Icons'

export const AdjustAmount = ({ currentAmount, loading, onAdjustAmount }) => {
  const {
    formState: { errors },
    register,
    watch
  } = useFormContext()

  const [amount, comment] = watch(['amount', 'comment'])

  return (
    <AdjustContainer>
      <Input.Default
        {...register('amount', {
          valueAsNumber: true,
          validate: (v) => {
            if (v === 0) return "This can't be 0"
            if (v <= 0) return 'Only positive amounts, please!'
            if (!!v && !isInteger(v)) return 'This needs to be an integer'
          }
        })}
        disabled={loading}
        placeholder="Amount"
        size="sm"
        type="number"
        tw="max-width[150px]"
      />
      <Input.TextArea
        {...register('comment')}
        disabled={loading}
        rows={2}
        placeholder="Add a comment (for us)"
        size="sm"
      />

      {errors.amount && <Input.Error>{errors.amount.message}</Input.Error>}

      <Buttons>
        <Button.Secondary
          ring
          size="sm"
          tw="w-full max-width[120px]"
          disabled={!amount || errors.amount || loading}
          onClick={() => onAdjustAmount({ amount, comment, reward: true })}
        >
          <Icon.Plus size="sm" mr="2" />
          Reward
        </Button.Secondary>
        <Button.Gray
          size="sm"
          ring
          tw="w-full max-width[120px]"
          disabled={!amount || amount > currentAmount || errors.amount || loading}
          onClick={() => onAdjustAmount({ amount, comment, reward: false })}
        >
          <Icon.Minus size="sm" mr="2" />
          Withdraw
        </Button.Gray>
      </Buttons>
    </AdjustContainer>
  )
}

const AdjustContainer = tw.div`flex flex-col gap-1`
const Buttons = tw.div`flex gap-2 mt-0.5`
