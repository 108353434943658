import { useCallback } from 'react'
import { actionTypes } from '../../reducers/eventReducer'

const useGetCorporateEvents = (dispatch) => {
  const onNextEvents = useCallback(
    (snapshot) => {
      snapshot.docChanges().forEach(async ({ doc, type }) => {
        const event = doc.data()

        if (type === 'added') dispatch({ type: actionTypes.event.add, event })
        if (type === 'modified') dispatch({ type: actionTypes.event.edit, event })
        if (type === 'removed') dispatch({ type: actionTypes.event.remove, event })
      })
    },
    [dispatch]
  )

  const onNextEventLocations = useCallback(
    (snapshot) => {
      const locations = []
      snapshot.docChanges().forEach(async ({ doc, type }) => {
        const location = doc.data()

        if (type === 'added') locations.push(location)
        if (type === 'modified') dispatch({ type: actionTypes.location.edit, location })
        if (type === 'removed') dispatch({ type: actionTypes.location.remove, location })
      })
      if (locations.length) dispatch({ type: actionTypes.location.addMany, locations })
    },
    [dispatch]
  )

  return { onNextEvents, onNextEventLocations }
}

export default useGetCorporateEvents
