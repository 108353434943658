import React, { useEffect, useState } from 'react'
import tw, { theme } from 'twin.macro'
import { useInfoScreen } from '../../contexts/InfoScreenContext'
import { Role, InfoScreenSorts } from '../../enums'
import useFilter from '../../hooks/useFilter'
import useSort from '../../hooks/useSort'
import { Action, List } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button, Card } from '../../styles/Layout'
import RBAC from '../RBAC'
import { InfoScreenFilter } from './InfoScreenFilter'
import { InfoScreenItems } from './InfoScreenItems'

export const InfoScreensList = () => {
  const { addNewInfoScreen, infoScreens } = useInfoScreen()
  const { applyFilters, ...filterMethods } = useFilter()
  const { applySort, sortingBy, sortListBy } = useSort(InfoScreenSorts.STATUS)
  const [filteredItems, setFilteredItems] = useState(infoScreens)

  // Filter and sort items
  useEffect(() => {
    setFilteredItems(applySort(applyFilters(infoScreens)))
  }, [applyFilters, applySort, infoScreens])

  return (
    <Card.Container header={'Info Screens'} color={theme`colors.sky.400`}>
      <List.Filter>
        <InfoScreenFilter filter={filterMethods} />
      </List.Filter>

      <Action.Header>
        <Action.Right>
          <RBAC allow={[Role.CREATOR]}>
            <Button.Primary ring size="sm" onClick={addNewInfoScreen}>
              <Icon.Plus size="sm" mr="2" />
              {'Create New Info Screen'}
            </Button.Primary>
          </RBAC>
          <Action.More />
        </Action.Right>
      </Action.Header>

      <List.Header>
        <CenterData
          colSpan={1}
          active={sortingBy?.sort === InfoScreenSorts.ACTIVE}
          onClick={() => sortListBy(InfoScreenSorts.ACTIVE)}
          inc={sortingBy?.inc}
        >
          Active
        </CenterData>
        <CenterData
          colSpan={1}
          active={sortingBy?.sort === InfoScreenSorts.STATUS}
          onClick={() => sortListBy(InfoScreenSorts.STATUS)}
          inc={sortingBy?.inc}
        >
          Status
        </CenterData>
        <List.HeaderData
          colSpan={4}
          active={sortingBy?.sort === InfoScreenSorts.NAME}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(InfoScreenSorts.NAME)}
        >
          Name
        </List.HeaderData>
        <List.HeaderData
          colSpan={2}
          active={sortingBy?.sort === InfoScreenSorts.START_DATE}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(InfoScreenSorts.START_DATE)}
        >
          Start Date
        </List.HeaderData>
        <List.HeaderData
          colSpan={2}
          active={sortingBy?.sort === InfoScreenSorts.END_DATE}
          inc={sortingBy?.inc}
          onClick={() => sortListBy(InfoScreenSorts.END_DATE)}
        >
          End Date
        </List.HeaderData>
      </List.Header>

      <List.Container>
        {filteredItems?.length ? (
          <InfoScreenItems items={filteredItems} />
        ) : (
          <List.Empty>{`${
            infoScreens.length ? 'No filtered items...' : 'Nothing here...'
          }`}</List.Empty>
        )}
      </List.Container>
      <Card.Footer color={theme`colors.sky.400`} />
    </Card.Container>
  )
}

const CenterData = tw(List.HeaderData)`text-center`
