import uniqBy from 'lodash/uniqBy'
import { uniq } from '../utility/helperFunctions'

export const actionTypes = {
  candidate: { add: 'ADD_CANDIDATE_CELLS' },
  location: { add: 'ADD_LOCATION_CELLS' },
  playable: { set: 'SET_PLAYABLE_CELLS' },
  reviewed: { set: 'SET_REVIEWED_CELLS' },
  states: { set: 'SET_STATE_CELLS', clear: 'CLEAR_STATE_CELLS' },
  clear: 'CLEAR_CELLS'
}

export const cellReducer = (state, action) => {
  // console.log({ state, action })

  switch (action.type) {
    case actionTypes.candidate.add:
    case actionTypes.location.add: {
      const { cells } = action
      return uniqBy([...state, ...cells], 'id')
    }

    case actionTypes.playable.set:
    case actionTypes.reviewed.set:
    case actionTypes.states.set: {
      const { cells = [] } = action
      return cells.filter(uniq)
    }

    case actionTypes.states.clear:
    case actionTypes.clear:
      return []

    default:
      return state
  }
}
