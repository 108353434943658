export const actionTypes = {
  user: {
    add: 'ADD_USER',
    edit: 'EDIT_USER',
    remove: 'REMOVE_USER'
  }
}

export const userReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.user.add: {
      const { user } = action
      return user
    }

    case actionTypes.user.edit: {
      const { user } = action
      return { ...state, ...user }
    }

    case actionTypes.user.remove: {
      return undefined
    }
  }
}
