import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { checkpointReducer, actionTypes } from '../reducers/checkpointReducer'
import { cellReducer, actionTypes as cellTypes } from '../reducers/cellReducer'
import { compressOptions, flatMap } from '../utility/helperFunctions'
import { useAreas } from './AreasContext'
import { db, FieldValue, functions, gdb } from '../lib/firebase'
import { checkpointConverter, converterFromSource } from '../utility/formatCheckpoints'
import { candidateCellConverter } from '../utility/formatCandidates'
import { useMap } from './MapContext'
import { useUpdateEffect } from '../hooks/useUpdateEffect'
import useGetCheckpoints from '../hooks/firebase/useGetCheckpoints'
import { Alerts, Toast } from '../utility/alerts'
import useGetCells from '../hooks/firebase/useGetCells'
import { streetviewConverter } from '../utility/formatClues'
import { CheckpointTab, ClueType } from '../enums'
import { CHECKPOINT_TAGS_OPTIONS } from '../utility/labeledOptions'
import { useNavigate } from 'react-router-dom'

export const CheckpointContext = createContext()

export const CheckpointProvider = ({ children }) => {
  const { activeCity, setLockCity } = useAreas()
  const [checkpoints, dispatch] = useReducer(checkpointReducer, {
    regular: [],
    candidates: []
  })
  const [candidateCells, cDispatch] = useReducer(cellReducer, [])
  const [reviewedCells, rDispatch] = useReducer(cellReducer, [])
  const { focusOnPoint } = useMap()
  const { onNextCheckpoints } = useGetCheckpoints(dispatch)
  const { onNextCandidateCells, onNextReviewedCells } = useGetCells()
  const navigate = useNavigate()

  const [activeCandidateCells, setActiveCandidateCells] = useState([])
  const [adding, setAdding] = useState(false)
  const [activeTab, setActiveTab] = useState(CheckpointTab.CHECKPOINTS)
  const [clueTabOpen, setClueTabOpen] = useState(false)
  const [candidateQueue, setCandidateQueue] = useState([])
  const [checkpointBeingEdited, setCheckpointBeingEdited] = useState()
  const [reservedClues, setReservedClues] = useState([])
  const [editingClue, setEditingClue] = useState(false)
  const [fetchingCheckpoint, setFetchingCheckpoint] = useState(false)
  const [focusedItem, setFocusedItem] = useState()
  const [loading, setLoading] = useState(false)
  const [reviewing, setReviewing] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [customTagOptions, setCustomTagOptions] = useState([])
  const [tagOptions, setTagOptions] = useState([])

  // Add new checkpoint
  const addNewCheckpoint = useCallback(
    ({ activeCity, ...initial }) => {
      const { active, path } = activeCity ?? {}
      if (!path) return
      // Create new document
      const newDoc = db.collection(`checkpoints/${path}`).doc()
      setCheckpointBeingEdited({ active, id: newDoc.id, path: newDoc.path, ...initial })
      setAdding(true)
      setFocusedItem()
      navigate(`${activeCity.name}/create`)
    },
    [navigate]
  )

  // Start editing checkpoint
  const startEditingCheckpoint = useCallback(
    async ({ path }) => {
      if (!path) return console.log('missing path')
      // Get checkpoint from path
      // This is necessary to handle map data that is converted to geojson
      const doc = await db.doc(path).withConverter(checkpointConverter).get()
      setAdding(false)
      setCheckpointBeingEdited(() => ({ id: doc.id, path: doc.ref.path, ...doc.data() }))
      setFocusedItem()
      focusOnPoint(doc.data())
      navigate(`${activeCity?.name}/${doc.id}`)
    },
    [activeCity?.name, focusOnPoint, navigate]
  )

  // Update edited checkpoint
  const updateCheckpoint = useCallback((values) => {
    setCheckpointBeingEdited((c) => ({ ...c, ...values }))
  }, [])

  // Clear values
  const stopEditingCheckpoint = useCallback(() => {
    setAdding(false)
    setFocusedItem()
    setCheckpointBeingEdited()
  }, [])

  // Save checkpoint to firestore
  const saveCheckpoint = useCallback(
    async (checkpoint) => {
      try {
        const { path } = checkpoint
        if (!path) throw new Error('Missing path')
        setSubmitDisabled(true)
        setLoading(true)
        const checkpointDoc = db.doc(path)

        // Save checkpoint
        await checkpointDoc.withConverter(converterFromSource(checkpoint)).set(
          {
            ...checkpoint,
            ...(adding && { createdAt: FieldValue.serverTimestamp() }),
            id: checkpointDoc.id
          },
          { merge: true }
        )

        // Generate automatic clues
        if (reservedClues.length) {
          Toast.fire({ title: 'Generating Clues', icon: 'info', timerProgressBar: false })
          const errors = []
          await Promise.all(
            reservedClues.map(async (type) => {
              switch (type) {
                case ClueType.STREET_VIEW: {
                  try {
                    // If new checkpoint, provide id of new clue ref
                    const { data: clue } = await functions.clue.createStreetView(
                      adding
                        ? {
                            centerPoint: checkpoint.centerPoint,
                            id: db.collection(`${path}/clues`).doc().id
                          }
                        : { checkpointPath: path }
                    )
                    // Upload clue
                    const formattedClue = streetviewConverter.fromGenerated(clue)
                    await db.doc(`${path}/clues/${clue.id}`).set(formattedClue)
                  } catch (err) {
                    errors.push(err.message)
                  }
                  break
                }
                default: {
                  break
                }
              }
            })
          )
          if (errors.length) await Alerts.Checkpoint.GENERATE_CLUES_FAILED(errors)
        }

        Toast.fire({
          title: `Checkpoint ${adding ? 'Added!' : 'Updated!'}`,
          icon: 'success'
        })
        stopEditingCheckpoint()
      } finally {
        setSubmitDisabled(false)
        setLoading(false)
      }
    },
    [adding, reservedClues, stopEditingCheckpoint]
  )

  // Called when candidates are added. Adds additional information before dispatching
  const addCandidates = useCallback(
    (arr) => {
      if (!activeCity) return console.log('missing active city')
      const candidates = arr.map((candidate) => {
        const path = `checkpoints/${activeCity.path}/${candidate.id}`
        return { ...candidate, path }
      })
      dispatch({ type: actionTypes.candidates.set, candidates })
      setFocusedItem()
    },
    [activeCity]
  )

  // Sets current checkpoint candidate as being edited
  const startReviewingCandidate = useCallback(
    async (item) => {
      try {
        const { id } = item
        if (!activeCity || !id) throw new Error('Missing id')
        const newRef = db.doc(`checkpoints/${activeCity.path}/${id}`)
        // Get candidate from state
        // This is necessary to handle map data that is converted to geojson
        const candidate = checkpoints.candidates?.filter((candidate) => candidate.id === id)[0]
        if (!candidate) throw new Error('Failed to get candidate from state')

        setAdding(true)
        setReviewing(true)
        setFocusedItem()
        setCheckpointBeingEdited(() => ({ id: newRef.id, path: newRef.path, ...candidate }))
        focusOnPoint(candidate)
        navigate(`${activeCity?.name}/${newRef.id}`)
      } catch (err) {
        Alerts.Candidate.REVIEW_FAILED(err)
        setCandidateQueue([])
      }
    },
    [activeCity, checkpoints, focusOnPoint, navigate, setCandidateQueue]
  )

  // Reviewing multiple checkpoint candidates
  const startReviewingCandidates = useCallback(
    (candidates) => {
      if (!candidates.length) return console.log('no candidates')
      setCandidateQueue(candidates)
      startReviewingCandidate(candidates[0])
    },
    [startReviewingCandidate]
  )

  // Stop reviewing candidates
  const stopReviewingCandidates = useCallback(() => {
    setReviewing(false)
    setCandidateQueue([])
  }, [])

  // Checks the queue of candidates
  const getNextCandidate = useCallback(async () => {
    // No queue, stop reviewing
    if (!candidateQueue.length) return setReviewing(false)

    const remaining = candidateQueue.filter((item) => item.id !== checkpointBeingEdited.id)
    setCandidateQueue(remaining)

    if (remaining.length) {
      const nextItem = remaining[0]
      const { value: ok } = await Alerts.Candidate.NEXT_CANDIDATE(remaining.length)
      if (ok) return startReviewingCandidate(nextItem)
      // Cancelled
      return stopReviewingCandidates()
    }
    Alerts.Candidate.DONE_REVIEWING()
    stopReviewingCandidates()
  }, [candidateQueue, checkpointBeingEdited, startReviewingCandidate, stopReviewingCandidates])

  // Accept candidate checkpoint
  const acceptCandidate = useCallback(
    async (candidate) => {
      try {
        setSubmitDisabled(false)
        setLoading(false)

        // Save clue to firestore
        await saveCheckpoint(candidate)

        // Remove checkpoint from mercator
        if (candidate.mercatorPath) await gdb.doc(candidate.mercatorPath).delete()
        dispatch({ type: actionTypes.candidates.remove, candidate })

        stopEditingCheckpoint()
        await getNextCandidate()
      } finally {
        setSubmitDisabled(false)
        setLoading(false)
      }
    },
    [getNextCandidate, saveCheckpoint, stopEditingCheckpoint]
  )

  // Reject candidate checkpoint
  const rejectCandidate = useCallback(
    async (candidate) => {
      try {
        setSubmitDisabled(true)
        const { value: ok } = await Alerts.Candidate.CONFIRM_DELETE()
        if (!ok) return

        await gdb.doc(candidate.mercatorPath).delete()
        dispatch({ type: actionTypes.candidates.remove, candidate })

        // save geohash of rehected candidate
        const countryISO = activeCity.path.split('/')[0]
        const countryDoc = db.collection('checkpoints').doc(countryISO)
        await countryDoc.set(
          {
            rejectedCandidates: FieldValue.arrayUnion({
              geohash: candidate.id,
              s2CellId: candidate.s2CellId
            })
          },
          { merge: true }
        )

        stopEditingCheckpoint()
        Toast.fire({ title: 'Candidate Rejected', icon: 'success' })
        await getNextCandidate()
      } catch (err) {
        Alerts.Candidate.DELETE_FAILED(err)
      } finally {
        setSubmitDisabled(false)
      }
    },
    [activeCity, getNextCandidate, stopEditingCheckpoint]
  )

  // Marks s2 cell as reviewed
  const markCellsReviewed = useCallback(
    async (cells) => {
      if (!activeCity?.path) return 'missing active city'
      const countryISO = activeCity.path.split('/')[0]
      const countryDoc = db.collection('checkpoints').doc(countryISO)
      await countryDoc.set({ reviewedCells: FieldValue.arrayUnion(...cells) }, { merge: true })
      setActiveCandidateCells([])
    },
    [activeCity]
  )

  // Clears Candidates
  const clearCandidates = useCallback(() => {
    dispatch({ type: actionTypes.candidates.clear })
    setActiveCandidateCells([])
    setFocusedItem()
  }, [])

  // Reset values to default
  const resetDefaults = useCallback(() => {
    dispatch({ type: actionTypes.clear })
    cDispatch({ type: cellTypes.candidate.clear })
    rDispatch({ type: cellTypes.reviewed.clear })
    setActiveCandidateCells([])
    setAdding(false)
    setCandidateQueue([])
    setCheckpointBeingEdited()
    setEditingClue(false)
    setFocusedItem()
    setLockCity(false)
    setReservedClues([])
    setReviewing(false)
    setSubmitDisabled(false)
  }, [setLockCity])

  // Pre-fetch checkpoint and navigate to specified path
  const visitDeepRoute = useCallback(
    async ({ edit, id, tab }) => {
      try {
        setFetchingCheckpoint(true)
        if (!id) return
        if (!activeCity?.path) return Alerts.Checkpoint.DEEP_LINK_CHANGE_CITY()

        const checkpointDoc = await db
          .doc(`checkpoints/${activeCity.path}/${id}`)
          .withConverter(checkpointConverter)
          .get()

        if (!checkpointDoc.exists) return Alerts.Checkpoint.DEEP_LINK_CHANGE_CITY(activeCity.name)

        const checkpoint = checkpointDoc.data()
        if (edit) {
          if (tab === 'clues') setClueTabOpen(true)
          return startEditingCheckpoint(checkpoint)
        }
        setFocusedItem({ checkpoint })
        focusOnPoint(checkpoint, { minZoom: 15, maxZoom: 18 })
      } catch (err) {
        Alerts.General.ERROR(err)
        console.error(err)
      } finally {
        setFetchingCheckpoint(false)
      }
    },
    [activeCity, focusOnPoint, startEditingCheckpoint]
  )

  // Subscribe when active city changes
  useEffect(() => {
    if (!activeCity?.path) return
    // Checkpoints
    const unsubscribeCheckpoints = db
      .collection(`checkpoints/${activeCity.path}`)
      .withConverter(checkpointConverter)
      .onSnapshot((onNext) => onNextCheckpoints(onNext))

    // Candidate Cells
    const unsubscribeCells = db
      .collection('mercator-request-log')
      .where('city', '==', activeCity.path)
      .withConverter(candidateCellConverter)
      .onSnapshot((onNext) => onNextCandidateCells(onNext, cDispatch))

    // Reviewed Cells
    const countryISO = activeCity.path.split('/')[0]
    const unsubscribeReviewed = db
      .collection('checkpoints')
      .doc(countryISO)
      .onSnapshot((onNext) => onNextReviewedCells(onNext, rDispatch))

    // Unmount
    return () => {
      unsubscribeCheckpoints()
      unsubscribeCells()
      unsubscribeReviewed()
      // reset values
      resetDefaults()
    }
  }, [activeCity, onNextCandidateCells, onNextCheckpoints, onNextReviewedCells, resetDefaults])

  // Lock City select while creating / editing checkpoint
  useUpdateEffect(() => {
    setLockCity(!!checkpointBeingEdited)
  }, [checkpointBeingEdited, setLockCity])

  // Update tag options
  useEffect(() => {
    const checkpointTags = checkpoints.regular?.reduce(
      (acc, current) => ({
        tags: [...acc.tags, current.tags ?? []],
        customTags: [...acc.customTags, current.customTags ?? []]
      }),
      { tags: [], customTags: [] }
    )

    const candidateTags = checkpoints.candidates?.map((checkpoint) => checkpoint.tags || []) ?? []

    setTagOptions(
      compressOptions(
        flatMap(checkpointTags.tags, (t) => t)
          .concat(flatMap(candidateTags, (t) => t))
          .concat(CHECKPOINT_TAGS_OPTIONS)
      ).sort((a, b) => a?.value.localeCompare(b?.value))
    )

    setCustomTagOptions(compressOptions(flatMap(checkpointTags.customTags, (t) => t)))
  }, [checkpoints])

  const defaultValues = useMemo(
    () => ({
      acceptCandidate,
      activeCandidateCells,
      activeTab,
      addCandidates,
      adding,
      editingClue,
      addNewCheckpoint,
      candidateCells,
      checkpointBeingEdited,
      checkpoints,
      clearCandidates,
      clueTabOpen,
      customTagOptions,
      dispatch,
      fetchingCheckpoint,
      focusedItem,
      loading,
      markCellsReviewed,
      rejectCandidate,
      reservedClues,
      reviewedCells,
      reviewing,
      saveCheckpoint,
      setActiveCandidateCells,
      setActiveTab,
      setClueTabOpen,
      setEditingClue,
      setFocusedItem,
      setReservedClues,
      setSubmitDisabled,
      startEditingCheckpoint,
      startReviewingCandidate,
      startReviewingCandidates,
      stopEditingCheckpoint,
      stopReviewingCandidates,
      submitDisabled,
      tagOptions,
      updateCheckpoint,
      visitDeepRoute
    }),
    [
      acceptCandidate,
      activeCandidateCells,
      activeTab,
      addCandidates,
      adding,
      editingClue,
      addNewCheckpoint,
      candidateCells,
      checkpointBeingEdited,
      checkpoints,
      clearCandidates,
      clueTabOpen,
      customTagOptions,
      fetchingCheckpoint,
      focusedItem,
      loading,
      markCellsReviewed,
      rejectCandidate,
      reservedClues,
      reviewedCells,
      reviewing,
      saveCheckpoint,
      startEditingCheckpoint,
      startReviewingCandidate,
      startReviewingCandidates,
      stopEditingCheckpoint,
      stopReviewingCandidates,
      submitDisabled,
      tagOptions,
      updateCheckpoint,
      visitDeepRoute
    ]
  )

  return <CheckpointContext.Provider value={defaultValues}>{children}</CheckpointContext.Provider>
}

// Hook
export const useCheckpoint = () => {
  const context = useContext(CheckpointContext)
  if (context === undefined)
    throw new Error('`useCheckpoint` hook must be used within a `CheckpointProvider` component')
  return context
}
