import firebase from 'firebase/compat/app'
import 'firebase/compat/app-check'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import config from '../config'

const firebaseConfig = {
  apiKey: config.firebase.API_KEY,
  authDomain: config.firebase.AUTH_DOMAIN,
  projectId: config.firebase.PROJECT_ID,
  storageBucket: config.firebase.STORAGE_BUCKET,
  messagingSenderId: config.firebase.MESSAGING_SENDER_ID,
  appId: config.firebase.APP_ID,
  measurementId: config.firebase.MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const db = firebase.firestore()
const fns = firebase.app().functions('europe-west3')
const storage = firebase.app().storage('gs://default-bucket')
const gerardusConfig = {
  apiKey: config.mercator.API_KEY,
  projectId: config.mercator.PROJECT_ID
}

const gerardusMercator = firebase.initializeApp(gerardusConfig, 'gerardus')
const gdb = gerardusMercator.firestore()

const FieldValue = firebase.firestore.FieldValue
const GeoPoint = firebase.firestore.GeoPoint
const Timestamp = firebase.firestore.Timestamp
const FieldPath = firebase.firestore.FieldPath

if (location.hostname === 'localhost') {
  auth.useEmulator('http://localhost:9099/')
  db.useEmulator('localhost', 8080)
  fns.useEmulator('localhost', 5001)
  storage.useEmulator('localhost', 9199)
}

// Enables debug mode
if (process.env.NODE_ENV !== 'production') self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
else self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN_FROM_CI

firebase
  .appCheck()
  .activate(
    new firebase.appCheck.ReCaptchaEnterpriseProvider(config.firebase.RECAPTCHA_SITEKEY),
    true
  )

const functions = {
  authCreateUser: fns.httpsCallable('user-createUser'), // not deployed
  authGetUser: fns.httpsCallable('user-getUser'),
  authUpdateUser: fns.httpsCallable('user-updateUser'),
  authSetCustomClaims: fns.httpsCallable('user-setCustomClaims'),
  clue: {
    createStreetView: fns.httpsCallable('clue-createStreetview')
  },
  image: {
    cloudinaryUpload: fns.httpsCallable('cloudinary-uploadImage')
  },
  user: {
    adjustCoinBalance: fns.httpsCallable('user-adjustCoinBalance'),
    adjustXPAmount: fns.httpsCallable('user-adjustXpAmount')
  },
  sendPushNotification: {
    supportMessage: fns.httpsCallable('user-pushNotificationNewSupportMessage')
  }
}

export default firebase
export { auth, db, FieldPath, FieldValue, functions, gdb, GeoPoint, Timestamp }
