import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import tw from 'twin.macro'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'
import Icon from '../../../styles/Icons'
import { Button, Input } from '../../../styles/Layout'

export const RouteConfigInput = ({
  defaultValue = '',
  description = '',
  disabled,
  error,
  label = '',
  name = '',
  options = {}
}) => {
  const { setEditingRoute } = useHvntRoute()
  const { control, setValue, trigger } = useFormContext()
  const area = useWatch({ name: 'area' })
  const [inputDisabled, setInputDisabled] = useState(true)

  const onReset = useCallback(
    (field) => {
      setInputDisabled(true)
      setValue(field, defaultValue, { shouldValidate: true })
      trigger('area')
    },
    [defaultValue, setValue, trigger]
  )

  const onEdit = () => {
    setInputDisabled(false)
    setEditingRoute(true)
  }

  const onSave = () => {
    setInputDisabled(true)
    setEditingRoute(false)
  }

  // Reset inputs if custom routes is toggled off whilst editing
  useEffect(() => {
    if (!area?.customRoute && !inputDisabled) onReset(name)
  }, [area?.customRoute, inputDisabled, name, onReset, setEditingRoute])

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      {...options}
      render={({ field }) => (
        <>
          <tr>
            <td>{label}</td>
            <td>
              <ConfigInput
                {...field}
                disabled={disabled || inputDisabled}
                onChange={({ target }) => {
                  trigger('area')
                  field.onChange(target.value)
                }}
                value={field.value}
                role="input"
              />
            </td>
            <WrapData>{description}</WrapData>
            <td>
              {inputDisabled ? (
                <Button.Primary
                  disabled={!area?.customRoute || disabled}
                  size="sm"
                  ring
                  onClick={onEdit}
                  data-testid="edit-button"
                >
                  <Icon.Edit size="sm" />
                </Button.Primary>
              ) : (
                <Button.Submit
                  disabled={!area?.customRoute || error}
                  size="sm"
                  ring
                  onClick={() => {
                    field.onChange(Number(field.value))
                    onSave()
                  }}
                  data-testid="save-button"
                >
                  <Icon.Check size="sm" />
                </Button.Submit>
              )}
            </td>
            <td tw="text-right">
              <Button.White
                size="sm"
                ring
                onClick={() => onReset(field.name)}
                disabled={!area?.customRoute || (inputDisabled && field.value == defaultValue)}
                data-testid="reset-button"
              >
                {inputDisabled ? 'Reset' : 'Cancel'}
              </Button.White>
            </td>
          </tr>
          {error && <Error>{error.message}</Error>}
        </>
      )}
    />
  )
}

const WrapData = tw.td`min-width[100px]`
const ConfigInput = tw(Input.Filter)`max-width[4rem] place-self-center`

const Error = ({ children }) => (
  <tr>
    <td colSpan={4}>
      <Input.Error tw="m-0">{children}</Input.Error>
    </td>
  </tr>
)
