import React, { useCallback, useEffect, useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { useHvnt } from '../../contexts/HvntContext'
import { useHvntList } from '../../hooks/useHvntList'
import { Button, Status } from '../../styles/Layout'
import { format } from 'date-fns'
import RBAC from '../RBAC'
import Icon from '../../styles/Icons'
import { relativeDateUpToDays } from '../../utility/helperFunctions'
import { Modal } from '../Modal'
import config from '../../config'
import { HvntStatus, Role } from '../../enums'
import { ATag, PrizeTag } from '../../styles/Link'
import { Leaderboard } from './Leaderboard'
import { Item } from '../../layout/List'
import { ImagePopup } from '../Image'

export const Items = ({ items }) => {
  const { expandedItem, setExpandedItem } = useHvntList()

  const onClickItem = useCallback(
    (hvnt, opened) => {
      console.log({ hvnt })
      setExpandedItem(opened ? hvnt : undefined)
    },
    [setExpandedItem]
  )

  return useMemo(
    () =>
      items.map((item, i) => (
        <HvntItem
          key={item.id}
          item={item}
          index={i}
          onClick={onClickItem}
          expanded={expandedItem?.id === item?.id}
        />
      )),
    [expandedItem, items, onClickItem]
  )
}

export const HvntItem = ({ item, expanded, onClick }) => {
  const { assignColor, setExpandedItem } = useHvntList()
  const { startEditingHvnt } = useHvnt()

  // Collapse item on unmount
  useEffect(() => {
    return () => {
      setExpandedItem()
    }
  }, [setExpandedItem])

  const onClickItem = useCallback(
    (hvnt) => {
      onClick(hvnt, !expanded)
    },
    [expanded, onClick]
  )

  const onClickEdit = useCallback(
    (e, item) => {
      e.stopPropagation()
      e.preventDefault()
      startEditingHvnt(item)
    },
    [startEditingHvnt]
  )

  return useMemo(() => {
    const { active, dateRange, highScores, index, name, status, updatedAt } = item

    return (
      <Item.Container>
        <Item.Header onClick={() => onClickItem(item)} expanded={expanded}>
          <Item.HeaderData colSpan={1}>
            <Item.Center>
              <Status active={active} />
            </Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={1}>
            <Item.Center>{status?.icon('lg') ?? '-'}</Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={2} data-testid="hvnt-name">
            {name}
          </Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.startDate ? relativeDateUpToDays(dateRange?.startDate, 3) : '-'}
          </Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.endDate ? relativeDateUpToDays(dateRange?.endDate, 1) : '-'}
          </Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {updatedAt ? relativeDateUpToDays(updatedAt, 1) : '-'}
          </Item.HeaderData>
        </Item.Header>

        <Item.Summary expanded={expanded}>
          <RBAC allow={[Role.CREATOR]}>
            <Item.ActionButtons>
              <Button.Secondary
                size="sm"
                onClick={(e) => onClickEdit(e, item)}
                data-testid="edit-button"
              >
                <Icon.Edit size="sm" mr="2" /> Edit Hvnt
              </Button.Secondary>
              <Modal
                disabled={!highScores}
                OpenButton={(props) => (
                  <Button.Primary size="sm" {...props}>
                    <Icon.Cake size="sm" mr="2" /> Leaderboard
                  </Button.Primary>
                )}
                body={() => <Leaderboard {...item} />}
                showSubmitButton={false}
                title={status === HvntStatus.ENDED ? 'End Results' : 'Current Standings'}
                tw="h-3/4"
              />
            </Item.ActionButtons>
          </RBAC>
          {RenderSummary(item)}
        </Item.Summary>
        <Color color={assignColor(index)} />
      </Item.Container>
    )
  }, [assignColor, expanded, item, onClickEdit, onClickItem])
}

const RenderSummary = (item) => {
  const {
    area: { radius },
    category,
    createdAt,
    dateRange,
    description,
    entryCode,
    errors,
    featured,
    id,
    imageUrl,
    name,
    numberOfCheckpoints,
    path,
    status,
    prizes,
    updatedAt
  } = item

  return (
    <>
      {imageUrl && (
        <Item.Image>
          <ImagePopup src={imageUrl} />
        </Item.Image>
      )}

      <Item.SummaryList>
        {!!errors?.length && (
          <Item.Row>
            <Item.Bold tw="text-red-600">
              {status == HvntStatus.DRAFT ? 'Missing:' : 'Errors:'}
            </Item.Bold>
            <Item.Text tw="italic">{errors.join(', ')}</Item.Text>
          </Item.Row>
        )}
        <Item.Row>
          <Item.Bold>{'Id:'}</Item.Bold>
          <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Status:'}</Item.Bold>
          <Item.Status>
            {status?.icon()}
            {status?.message ?? '-'}
          </Item.Status>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Name:'}</Item.Bold>
          <Item.Text>{name || '-'}</Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Starts:'}</Item.Bold>
          <Item.Text>
            {dateRange?.startDate ? format(dateRange.startDate, 'LLL do yyy, HH:mm') : '-'}
          </Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Ends:'}</Item.Bold>
          <Item.Text>
            {dateRange?.endDate ? format(dateRange?.endDate, 'LLL do yyy, HH:mm') : '-'}
          </Item.Text>
        </Item.Row>

        {entryCode && (
          <Item.Row>
            <Item.Bold>{'Entry Code:'}</Item.Bold>
            <Item.Text>{entryCode || '-'}</Item.Text>
          </Item.Row>
        )}

        <Item.Row>
          <Item.Bold>{'Radius:'}</Item.Bold>
          <Item.Text>{radius ? radius + ' m' : '-'}</Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Checkpoints:'}</Item.Bold>
          <Item.Text>{numberOfCheckpoints ?? 0}</Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Description:'}</Item.Bold>
          <Item.Text>{description || '-'}</Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Category:'}</Item.Bold>
          <Item.Text>{category?.label || '-'}</Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Prizes:'}</Item.Bold>

          {prizes?.length ? (
            <Item.Col>
              {prizes.map(({ id, name, reservedAmount }, i) => (
                <Item.Text key={i} tw="flex gap-2">
                  <PrizeTag to={`/prizes?id=${id}`}>{name}</PrizeTag> - x{reservedAmount}
                </Item.Text>
              ))}
            </Item.Col>
          ) : (
            <Item.Text>{'-'}</Item.Text>
          )}
        </Item.Row>

        {featured && (
          <Item.Row>
            <Item.Bold>{'Featured:'}</Item.Bold>
            <Item.Text>{'Yes'}</Item.Text>
          </Item.Row>
        )}
        <Item.Row>
          <Item.Bold>{'Created:'}</Item.Bold>
          <Item.Text>{createdAt ? format(createdAt, 'LLL do yyy, HH:mm') : '-'}</Item.Text>
        </Item.Row>
        <Item.Row>
          <Item.Bold>{'Updated:'}</Item.Bold>
          <Item.Text>{updatedAt ? format(updatedAt, 'LLL do yyy, HH:mm') : '-'}</Item.Text>
        </Item.Row>
      </Item.SummaryList>
    </>
  )
}

const Color = styled.div`
  ${tw`absolute top-0 bottom-0 left-0 w-2`}
  ${({ color }) => color && `background: ${color}`}
`
