export const LocationSourceType = Object.freeze({
  WIKI: 'wiki',
  PLAYABLE: 'google',
  MAPBOX: 'mapbox'
})

export const LocationCellType = Object.freeze({
  LOCATION: 'location-cell',
  CANDIDATE: 'candidate-cell',
  S2: 's2-cell',
  REVEIEWED: 'reviewed-cell'
})

export const LocationTab = Object.freeze({
  GENERATE: 'GENERATE_LOCATIONS',
  QUERY: 'QUERY_LOCATIONS'
})

export const S2CellLevel = Object.freeze({
  11: 11,
  12: 12,
  13: 13,
  14: 14
})

export const CellScanMode = Object.freeze({
  CLICK: 'CLICK',
  SCAN: 'SCAN'
})
