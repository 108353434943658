import React from 'react'
import tw, { theme } from 'twin.macro'
const Path = tw.path`hover:cursor-pointer`

export const Pin = ({ editing, fill, size = 25, stroke, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={fill ?? theme`colors.matrix`}
    stroke={stroke ?? theme`colors.matrix.600`}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
  </svg>
)
