import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import tw, { styled, theme } from 'twin.macro'
import { useAreas } from '../../contexts/AreasContext'
import { useMap } from '../../contexts/MapContext'
import { Role } from '../../enums'
import Icon from '../../styles/Icons'
import { Button, Input, Status, MoreButton } from '../../styles/Layout'
import { Toast } from '../../utility/alerts'
import { capitalize } from '../../utility/helperFunctions'
import { isValidCity } from '../../utility/validationFunctions'
import RBAC from '../RBAC'

export const City = ({
  city = {},
  disabled,
  editing = false,
  onActivate,
  onCancel,
  onDeactivate,
  onDelete,
  onSave
}) => {
  const { adding, cityBeingEdited, startEditingCity } = useAreas()
  const { focusOnPoint } = useMap()
  const [centerPoint, setCenterPoint] = useState(city.centerPoint)
  const nameRef = useRef()
  const { active, name } = city

  // Update centerpoint
  useEffect(() => {
    if (!editing || !cityBeingEdited?.centerPoint) return
    setCenterPoint(cityBeingEdited.centerPoint)
  }, [editing, cityBeingEdited])

  const onClickCity = useCallback(() => {
    focusOnPoint(city, { maxZoom: 15 })
  }, [city, focusOnPoint])

  // Todo use hook-form
  const onSaveCity = useCallback(async () => {
    const name = adding ? nameRef.current.value : city.name
    const cityData = { active, name, centerPoint }

    if (isValidCity(cityData)) {
      onSave(cityData)
    } else {
      Toast.fire({
        title: 'Invalid city!',
        icon: 'error'
      })
    }
  }, [active, adding, centerPoint, city, onSave])

  return (
    <Container onClick={onClickCity} editing={editing}>
      <Grid>
        <FieldCol tw="col-span-1 justify-center items-center">
          <Status active={active} />
        </FieldCol>

        <FieldCol tw="col-span-4">
          <div tw="ml-2">
            {editing ? (
              <>
                <FieldTitle>City Name</FieldTitle>
                {adding ? (
                  <Input.Default size="sm" ref={nameRef} defaultValue={name} />
                ) : (
                  capitalize(name)
                )}
              </>
            ) : (
              capitalize(name) || '-'
            )}
          </div>
        </FieldCol>
        <FieldCol tw="col-span-5">
          <div>
            {editing ? (
              <>
                <FieldTitle>LatLng (Drag marker to move)</FieldTitle>
                {centerPoint ? (
                  <>
                    <Coord>{`${centerPoint.latitude.toPrecision(8)} ,`}&nbsp;</Coord>
                    <Coord>{`${centerPoint.longitude.toPrecision(8)}`}</Coord>
                  </>
                ) : (
                  <Coord>Double click the map to add location</Coord>
                )}
              </>
            ) : (
              <>
                <Coord>{`${centerPoint?.latitude.toPrecision(8)} ,`}&nbsp;</Coord>
                <Coord>{`${centerPoint?.longitude.toPrecision(8)}`}</Coord>
              </>
            )}
          </div>
        </FieldCol>
      </Grid>

      {editing ? (
        <>
          <div tw="flex ml-1 gap-1">
            <Button.Submit
              role="submit"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                onSaveCity()
              }}
            >
              <Icon.Check size="xs" />
            </Button.Submit>
            <Button.White
              role="cancel"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                onCancel()
              }}
            >
              <Icon.Close size="xs" />
            </Button.White>
            {!adding && (
              <>
                <MoreButton
                  size="sm"
                  tw="m-0 p-1 hover:bg-norway-700 active:bg-norway-800 rounded-sm"
                  color={theme`colors.norway.400`}
                  content={
                    <>
                      {active ? (
                        <Button.Primary size="sm" ring onClick={() => onDeactivate(city)}>
                          <Icon.Moon size="sm" mr="2" /> Deactivate
                        </Button.Primary>
                      ) : (
                        <Button.Secondary size="sm" ring onClick={() => onActivate(city)}>
                          <Icon.Sun size="sm" mr="2" /> Activate
                        </Button.Secondary>
                      )}
                      <RBAC allow={[Role.ADMIN]}>
                        <Button.Warning size="sm" ring onClick={() => onDelete(city)}>
                          <Icon.Trashcan size="sm" mr="2" /> Delete
                        </Button.Warning>
                      </RBAC>
                    </>
                  }
                />
              </>
            )}
          </div>
        </>
      ) : (
        <div>
          <EditCityButton size="sm" disabled={disabled} onClick={() => startEditingCity(city)}>
            <Icon.Edit size="sm" />
          </EditCityButton>
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  ${tw`flex flex-1 mb-2 p-2 justify-between items-center text-base rounded-md cursor-pointer bg-norway`}
  ${({ editing }) => (editing ? tw`bg-norway-600` : tw`hover:bg-norway-600 active:bg-norway-700`)}
`
const EditCityButton = tw(Button.Basic)`hover:bg-norway-700`
const Grid = styled.div`
  ${tw`grid gap-2 w-full`}
  grid-template-columns: repeat(10, minmax(0, 1fr));
`
const FieldCol = tw.div`flex flex-col overflow-x-scroll`
const FieldTitle = tw.div`font-medium text-sm sticky left-0`
const Coord = tw.div`inline-flex text-sm`
