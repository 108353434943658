import React, { useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { Popup } from 'react-map-gl'
import { Button } from '../../../styles/Layout'
import Icon from '../../../styles/Icons'

export const NewCountryMarker = ({ country, onAdd, onCancel }) =>
  useMemo(
    () => (
      <InfoPopup {...country.centerPoint}>
        <NamePlate>
          <DragToMove>Add Country?</DragToMove>
          <Name>
            <strong>{country.name ?? 'New Country'}</strong>
          </Name>

          <ButtonContainer>
            <AddButton size="sm" onClick={() => onAdd(country)} role="add">
              <Icon.Check size="sm" mr="2" />
              Add
            </AddButton>
            <CancelButton size="sm" onClick={onCancel} role="cancel">
              <Icon.Close size="sm" mr="2" /> Cancel
            </CancelButton>
          </ButtonContainer>
        </NamePlate>
      </InfoPopup>
    ),
    [country, onAdd, onCancel]
  )

const InfoPopup = styled(Popup).attrs(() => ({
  anchor: 'bottom',
  closeButton: false,
  closeOnClick: false,
  tipSize: 0
}))`
  & > * {
    ${tw`p-0 mb-5 border-none rounded-md`}
  }
`
const NamePlate = tw.div`flex flex-col bg-amethyst-700 p-2 rounded-md shadow-lg justify-between items-center 
text-center cursor-pointer text-white-pure font-sans font-medium select-none`
const ButtonContainer = tw.div`flex gap-2`
const AddButton = tw(Button.Submit)`w-full py-0.5`
const CancelButton = tw(Button.White)`w-full py-0.5`
const DragToMove = tw.div`absolute -top-3 left-0 py-1 rounded-t-md w-full minWidth[90px] bg-amethyst-800 text-xs`
const Name = tw.span`font-sans font-light text-2xl m-3`
