import { isImageUrl, isNumber, isStringOrNumber } from '.'

export const isValidPrize = ({
  deliveryType,
  description,
  imageUrl,
  marketValue,
  name,
  ref,
  supplier
} = {}) => {
  if (!isStringOrNumber(deliveryType)) return false
  if (description && !isStringOrNumber(description)) return false
  if (!isImageUrl(imageUrl)) return false
  if (marketValue && (!isNumber(marketValue.value) || !isStringOrNumber(marketValue.currency)))
    return false
  if (!isStringOrNumber(name)) return false
  if (!ref) return false
  if (!isStringOrNumber(supplier)) return false
  return true
}
export const isValidHvnt = ({ id, ref }) => {
  if (!isStringOrNumber(id)) return false
  if (!ref) return false
}

export const isValidSeasonPrizes = (prizes = []) =>
  prizes.every(({ name, reservedAmount }) => isStringOrNumber(name) && !!reservedAmount)
