import { useCallback } from 'react'
import { db } from '../../lib/firebase'
import { actionTypes } from '../../reducers/prizeReducer'
import { HvntPrizeError } from '../../utility/customErrors'
import { hvntPrizeConverter, prizeInstanceConverter, prizeStatus } from '../../utility/formatPrizes'

const useGetPrizes = (dispatch) => {
  // Query snapshot for prizes
  const onNextPrizes = useCallback(
    async (snapshot) => {
      const prizes = []
      await Promise.all(
        snapshot.docChanges().map(async ({ doc, type }) => {
          const [availableDocs, claimedDocs, reservedDocs] = await Promise.all([
            doc.ref.collection('available').withConverter(prizeInstanceConverter).get(),
            doc.ref.collection('claimed').withConverter(prizeInstanceConverter).get(),
            doc.ref.collection('reserved').withConverter(prizeInstanceConverter).get()
          ])

          const available = availableDocs.size
          const claimed = claimedDocs.size
          const reserved = reservedDocs.size
          const amount = available + claimed + reserved

          const availablePrizes = availableDocs.docs.map((doc) => doc.data()) ?? []
          const claimedPrizes = claimedDocs.docs.map((doc) => doc.data()) ?? []
          const reservedPrizes = reservedDocs.docs.map((doc) => doc.data()) ?? []

          const prize = {
            amount,
            available,
            availablePrizes,
            claimed,
            claimedPrizes,
            reserved,
            reservedPrizes,
            status: prizeStatus({ amount, available, claimed, reserved, ...doc.data() }),
            ...doc.data()
          }
          if (type === 'added') prizes.push(prize)
          if (type === 'modified') dispatch({ type: actionTypes.edit, prize })
          if (type === 'removed') dispatch({ type: actionTypes.remove, prize })
        })
      )

      if (prizes.length) dispatch({ type: actionTypes.addMany, prizes })
    },
    [dispatch]
  )

  // Query snapshot for hvnt prizes
  const onNextHvntPrizes = useCallback((snapshot, dispatch) => {
    snapshot.docChanges().forEach(async ({ doc, type }) => {
      // We are only interesting in available prizes
      const availableDocs = await doc.ref.collection('available').get()
      const available = availableDocs.size

      const hvntPrize = hvntPrizeConverter.fromPrize(doc)
      const prize = {
        available,
        ...hvntPrize
      }
      if (type === 'added') dispatch({ type: actionTypes.add, prize })
      if (type === 'modified') dispatch({ type: actionTypes.edit, prize })
      if (type === 'removed') dispatch({ type: actionTypes.remove, prize })
    })
  }, [])

  // Reserve prizes for hvnt
  const reservePrizes = useCallback(async (t, { hvntDoc, prize }) => {
    const prizeRef = db.doc(prize.path)
    const reservedColRef = prizeRef.collection('reserved')
    const availableDocs = await prizeRef.collection('available').limit(prize.reservedAmount).get()

    // There is no longer enough available prizes to make the reservation
    if (availableDocs.empty || availableDocs.docs.length !== prize.reservedAmount)
      throw new HvntPrizeError({
        message: "We don't have enough available prizes to reserve",
        validation: 'You need to add a new prize!'
      })

    for (let availableDoc of availableDocs.docs) {
      // Add the prize to `reserved`
      const reservedPrizeRef = reservedColRef.doc(availableDoc.id)
      t.set(reservedPrizeRef, {
        reservedAt: new Date(),
        hvnt: { id: hvntDoc.id, ref: hvntDoc },
        ...availableDoc.data()
      })

      // Remove the prize from `available`
      t.delete(availableDoc.ref)

      // Update the prize document
      t.update(prizeRef, { updatedAt: new Date() })

      // Add the prize to `{hvnt.id}/prizes` collection
      const hvntPrizesRef = hvntDoc.collection('prizes')
      const newPrizeRef = hvntPrizesRef.doc(availableDoc.id)
      t.set(newPrizeRef, { claimed: false, ...availableDoc.data() })
    }
  }, [])

  // Unreserve prizes from hvnt
  const unreservePrizes = useCallback(async (t, { hvntDoc }) => {
    const hvntPrizes = await hvntDoc.collection('prizes').get()

    for (let hvntPrize of hvntPrizes.docs) {
      const { claimed, ...data } = hvntPrize.data()
      const prizeId = hvntPrize.id
      const prizeRef = data.prize.ref
      const availableRef = prizeRef.collection('available').doc(prizeId)
      const reservedRef = prizeRef.collection('reserved').doc(prizeId)

      /** Adds prize back to `available`, delete it from `reserved`
       * and update the prize document
       */
      t.set(availableRef, { ...data })
      t.delete(reservedRef)
      t.update(prizeRef, { updatedAt: new Date() })

      // Delete it from hvnts/prizes
      t.delete(hvntPrize.ref)
    }
  }, [])

  return { reservePrizes, onNextPrizes, onNextHvntPrizes, unreservePrizes }
}

export default useGetPrizes
