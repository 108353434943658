import React, { useState, useCallback } from 'react'
import tw, { css, styled } from 'twin.macro'
import { useUpdateEffect } from '../../hooks/useUpdateEffect'

export function Switch({ checked, defaultChecked = false, disabled, label, onToggle, ...props }) {
  const [selected, setSelected] = useState(defaultChecked)

  const _onToggle = useCallback(
    ({ target }) => {
      setSelected(target.checked)
      onToggle(target.checked)
    },
    [onToggle]
  )

  // Control
  useUpdateEffect(() => {
    setSelected(checked ?? false)
  }, [checked])

  return (
    <Container {...props} checked={selected} disabled={disabled}>
      <Checkbox
        checked={selected}
        disabled={disabled}
        onChange={_onToggle}
        role="checkbox"
        type="checkbox"
      />
      <Toggle />
      <Label>{label}</Label>
    </Container>
  )
}

const Toggle = tw.div`relative flex items-center rounded-full shadow-inner border`
const Checkbox = tw.input`absolute hidden`
const Label = tw.div`relative whitespace-nowrap font-light text-black select-none`
const Container = styled.label`
  ${({ checked, disabled, labelLeft, size = 'md', color }) => css`
    ${tw`flex items-center cursor-pointer`}
    ${labelLeft ? tw`flex-row-reverse` : tw`flex-row`}
    ${disabled && tw`cursor-default opacity-50`}
    ${size === 'lg' ? tw`text-lg gap-2` : size === 'md' ? tw`text-base gap-2` : tw`text-sm gap-1`}
    ${Toggle} {
      transition: background 0.3s ease;
      ${size === 'lg' ? tw`w-12 h-6` : size === 'md' ? tw`w-10 h-5` : tw`w-7 h-3.5`}
      ${checked
        ? color === 'red'
          ? tw`bg-matrix-400`
          : color === 'green'
          ? tw`bg-norway`
          : color === 'blue'
          ? tw`bg-sky-400`
          : tw`bg-gray-600`
        : tw`bg-gray-400`}
    }
    ${Toggle}:before {
      ${tw`absolute rounded-full border-r-2 border-b-2 bg-gray-100 border-gray-300 content`};
      transition: left 300ms ease-in-out;
      ${size === 'lg' ? tw`h-4 w-4` : size === 'md' ? tw`h-3 w-3` : tw`h-2 w-2`}
      ${checked
        ? size === 'lg'
          ? tw`left-7`
          : size === 'md'
          ? tw`left-6`
          : tw`left-4`
        : tw`left-0.5`}
    }
    ${Checkbox}:hover + ${Toggle} {
      ${!disabled &&
      (checked
        ? color === 'red'
          ? tw`bg-matrix-500`
          : color === 'green'
          ? tw`bg-norway-600`
          : color === 'blue'
          ? tw`bg-sky-500`
          : tw`bg-gray-600`
        : tw`bg-gray-500`)}
    }
  `}
`
