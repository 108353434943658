import React, { useEffect } from 'react'
import tw, { css, styled } from 'twin.macro'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Button, Info, Input } from '../../styles/Layout'
import { useHvnt } from '../../contexts/HvntContext'
import Icon from '../../styles/Icons'
import { HvntPrizes } from './Prize/HvntPrizes'
import { ImageModal } from '../Modal'
import { validate } from '../../utility/formValidation'
import { Checkbox } from '../Checkbox'
import { Section } from './FormSection'
import { useIsMounted } from '../../hooks/useIsMounted'
import Dropdown from '../Dropdown'
import DatePicker from '../DatePicker'

export const HvntForm = ({ loading, prizes, prizesLocked, setAddedImage, startLocked }) => {
  const { categoryOptions, tagOptions, validateHvnt } = useHvnt()
  const { isMounted } = useIsMounted()
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = useFormContext()

  const { append, fields, update } = useFieldArray({
    control,
    name: 'prizes',
    keyName: 'prizeId',
    shouldUnregister: false
  })

  // Register prizes field array
  useEffect(() => {
    if (!isMounted()) return

    if (!prizes?.length || fields.length) return
    prizes.forEach((prize) => setTimeout(() => append({ ...prize, prizeId: prize.id }), []))
    // This only prevents prize duplication on hot reload
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, prizes])

  return (
    <Form>
      <Section label="Name your hvnt" field="name" error={errors.name} required>
        <Input.Default
          {...register('name', {
            maxLength: {
              value: 30,
              message: 'Name cannot be longer than 30 characters'
            }
          })}
          placeholder="Name"
        />
        <Input.Error>{errors.name?.message}</Input.Error>
      </Section>

      <Section label="Add a description" field="description" error={errors.description} required>
        <Input.TextArea
          {...register('description', {
            maxLength: {
              value: 300,
              message: 'Maximum 300 characters'
            }
          })}
          placeholder="Description (max 300 characters)"
          rows={3}
        />
        <Input.Error>{errors.description?.message}</Input.Error>
      </Section>

      <Section label="Category" field="category" error={errors.category} tw="z-20" required>
        <Controller
          control={control}
          name="category"
          render={({ field }) => (
            <Dropdown.Creatable
              {...field}
              options={categoryOptions}
              placeholder="Choose or create category"
            />
          )}
        />
        <Input.Error>{errors.category?.message}</Input.Error>
        <Controller
          control={control}
          name="featured"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              color="blue"
              label="Featured hvnt"
              onCheck={onChange}
              tw="mt-6"
              size="lg"
            />
          )}
        />
      </Section>

      <Section label="Upload an image" field="imageUrl" error={errors.imageUrl} required>
        <Controller
          control={control}
          name="imageUrl"
          rules={{
            validate: (imageUrl) => (validateHvnt ? validate.image({ imageUrl }) : null)
          }}
          render={({ field: { value, onChange } }) => (
            <ImageModal
              aspect={{ x: 3, y: 4 }}
              OpenButton={(props) => (
                <ModalButtonContainer hide={value} {...props}>
                  {value && <Image src={value} />}
                  <Button.Primary tw="absolute bottom-0">
                    <Icon.Image mr="2" />
                    {value ? 'Change image' : 'Select image'}
                  </Button.Primary>
                </ModalButtonContainer>
              )}
              flagUpload={setAddedImage}
              imageUrl={value}
              loading={loading}
              title="Hvnt Image"
              setImageUrl={onChange}
              tw="h-4/5"
            />
          )}
        />
        <Input.Error>{errors.imageUrl?.message}</Input.Error>
      </Section>

      <Section label="Set duration" field="dateRange" error={errors.dateRange} required>
        <Controller
          control={control}
          name="dateRange"
          rules={{
            validate: (date) => (validateHvnt ? validate.hvnt.dateRange(date, fields) : null)
          }}
          render={({ field: { onChange, value }, fieldState }) => (
            <DatePicker
              error={fieldState.error}
              onChange={onChange}
              shouldValidate={validateHvnt}
              startDisabled={startLocked}
              value={value}
            />
          )}
        />
        <Input.Error>{errors.dateRange?.message}</Input.Error>
      </Section>
      <Section label="Add nice prizes" field="prizes" error={errors.prizes} required>
        <HvntPrizes
          addPrize={append}
          locked={prizesLocked}
          hvntPrizes={prizes}
          // instead of fieldArray.remove
          removePrize={(i) => setValue('prizes', watch('prizes').splice(i + 1, 1))}
          updatePrize={update}
        />

        <Input.Error>{errors.prizes?.message}</Input.Error>
      </Section>

      <Section label="Add tags" field="tags" tw="z-20">
        <Controller
          control={control}
          name="tags"
          render={({ field }) => (
            <Dropdown.Creatable
              {...field}
              closeMenuOnSelect={false}
              isMulti
              options={tagOptions}
              placeholder="Tags"
            />
          )}
        />
      </Section>

      <Section label="Other options" field={['hideImage', 'hidePrize']}>
        <Info tw="self-center">These are not available yet</Info>

        <Controller
          control={control}
          name="hidden.imageUrl"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              color="blue"
              disabled={true}
              // disabled={editingDisabled}
              label="Hide image until the hvnt starts [ Coming Soon ]"
              onCheck={onChange}
              tw="my-2"
              size="lg"
            />
          )}
        />
        <Controller
          control={control}
          name="hidden.prize"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              color="blue"
              disabled={true}
              // disabled={editingDisabled}
              label="Hide prize until the hvnt starts [ TBA ]"
              onCheck={onChange}
              tw="my-2"
              size="lg"
            />
          )}
        />
      </Section>
    </Form>
  )
}
const Form = tw.form`relative flex flex-col w-full overflow-scroll`
const Image = tw.img`h-full maxHeight[24rem] shadow-md rounded-md`
const ModalButtonContainer = styled.div`
  ${tw`relative flex flex-col gap-2 min-height[40px]`}
  ${({ hide }) => css`
    ${hide && tw`cursor-pointer`};
    & > ${Button.Primary} {
      ${hide && tw`invisible`}
    }
    &:hover > ${Button.Primary} {
      ${hide && tw`visible w-full`}
    }
  `}
`
