import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { Button, Input } from '../../../styles/Layout'
import { Section } from '../../Hvnt/FormSection'
import Icon from '../../../styles/Icons'
import { TriviaOption } from './TriviaOption'
import { useChallenge } from '../../../contexts/ChallengeContext'
import { validate } from '../../../utility/formValidation'

export const SelectOneForm = () => {
  const {
    control,
    formState: { errors },
    register
  } = useFormContext()
  const { adding } = useChallenge()
  const { append, fields, remove } = useFieldArray({ control, name: 'options' })
  const options = useWatch({ name: 'options' })
  const [init, setInit] = useState(false)

  // Add initial options
  useEffect(() => {
    if (init || !adding) return
    append(['', '', '', ''])
    setInit(true)
  }, [adding, append, init])

  return (
    <>
      <Section
        label="Add the challenge"
        field={['options', 'question']}
        error={errors.question || errors.options || errors.validateOption}
        required
      >
        <Input.Label>Question</Input.Label>
        <Input.Default
          size="lg"
          {...register('question', { validate: validate.challenge.question })}
        />
        <Input.Error>{errors.question?.message}</Input.Error>
        <OptionsSection>
          <Options
            {...register('validateOption', {
              validate: () => validate.challenge.selectOne(options)
            })}
          >
            {fields.map((option, index) => (
              <TriviaOption key={option.id} index={index} onRemove={() => remove(index)} />
            ))}
            <Input.Error>{errors.validateOption?.message}</Input.Error>
          </Options>

          <AddOptionButton ring size="sm" onClick={() => append('')}>
            <Icon.Plus size="sm" mr="2" />
            Add Option
          </AddOptionButton>
        </OptionsSection>
      </Section>
    </>
  )
}

const OptionsSection = tw.div`flex flex-col pt-4 gap-2`
const Options = tw.div`flex flex-col`
const AddOptionButton = tw(Button.Primary)`w-full sm:w-min place-self-end`
