import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import tw from 'twin.macro'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { Button, Status } from '../../styles/Layout'
import { ErrorBubble } from '../Layout/Error'
import { db } from '../../lib/firebase'
import { clueConverter } from '../../utility/formatClues'
import { CheckpointSummary } from './CheckpointSummary'
import Icon from '../../styles/Icons'
import { format } from 'date-fns'
import { useMap } from '../../contexts/MapContext'
import useGetClues from '../../hooks/firebase/useGetClues'
import { clueReducer } from '../../reducers/clueReducer'
import RBAC from '../RBAC'
import { Role } from '../../enums'
import { Item } from '../../layout/List'

export const Items = ({ items = [] }) => {
  const { focusedItem, setFocusedItem } = useCheckpoint()
  const { focusOnPoint } = useMap()

  const onClickItem = useCallback(
    (checkpoint, opened) => {
      console.log({ checkpoint })
      if (opened) {
        focusOnPoint(checkpoint, { minZoom: 15, maxZoom: 18 })
        setFocusedItem({ checkpoint })
      } else {
        setFocusedItem()
      }
    },
    [focusOnPoint, setFocusedItem]
  )

  return useMemo(
    () =>
      items.map((item) => (
        <CheckpointItem
          key={item.id}
          item={item}
          onClick={onClickItem}
          expanded={focusedItem?.checkpoint?.id === item?.id}
        />
      )),
    [focusedItem, items, onClickItem]
  )
}

export const CheckpointItem = ({ item, expanded, onClick }) => {
  const [clues, dispatch] = useReducer(clueReducer, { regular: [], candidates: [] })
  const { checkpointBeingEdited, startEditingCheckpoint } = useCheckpoint()
  const { onNextClues } = useGetClues(dispatch)

  // Fetch clues on expand
  useEffect(() => {
    if (!expanded) return
    const unsubscribe = db
      .collection(`${item.path}/clues`)
      .withConverter(clueConverter)
      .onSnapshot((onNext) => onNextClues(onNext))

    return unsubscribe
  }, [expanded, onNextClues, item.path])

  const onClickEdit = useCallback(
    (checkpoint) => {
      if (!checkpointBeingEdited) {
        startEditingCheckpoint(checkpoint)
      } else {
        alert.fire({
          title: 'You have unsaved changes',
          text: 'Save or discard your changes first',
          icon: 'warning',
          confirmButtonText: 'Ok!'
        })
      }
    },
    [checkpointBeingEdited, startEditingCheckpoint]
  )

  return useMemo(() => {
    const { active, errors, name, updatedAt } = item
    return (
      <Item.Container id={item.id}>
        {!!errors.length && <ErrorBubble size="md" tw="absolute right-2 top-2.5" />}
        <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
          <Item.HeaderData colSpan={1}>
            <Center>
              <Status active={active} />
            </Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={4}>{name}</Item.HeaderData>
          <Item.HeaderData colSpan={3}>
            {updatedAt ? format(new Date(updatedAt), 'LLL do yyyy, HH:mm') : '-'}
          </Item.HeaderData>
        </Item.Header>

        <Item.Summary expanded={expanded}>
          <RBAC allow={[Role.CREATOR, Role.EDITOR]}>
            <Item.ActionButtons>
              <Button.Secondary size="sm" onClick={() => onClickEdit(item)}>
                <Icon.Edit size="sm" mr="2" /> Edit
              </Button.Secondary>
            </Item.ActionButtons>
          </RBAC>

          <CheckpointSummary item={item} clues={clues.regular} />
        </Item.Summary>
      </Item.Container>
    )
  }, [clues, expanded, item, onClick, onClickEdit])
}

const Center = tw.div`flex justify-center items-center`
