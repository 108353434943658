import React, { useState } from 'react'
import { Button, Card, Input } from '../../styles/Layout'
import { useForm } from 'react-hook-form'
import tw, { theme } from 'twin.macro'
import { useUser } from '../../contexts/UserContext'
import { handleError } from '../../utility/handleErrors'
import { useNavigate } from 'react-router-dom'
import { Toast, Alerts } from '../../utility/alerts'
import { Loader } from '../Loader/Loader'
import Icon from '../../styles/Icons'

function ChangePassword() {
  const { changePassword, signout } = useUser()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const {
    formState: { errors },
    setError,
    register,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onChange'
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await changePassword(data.newPassword)
      setLoading(false)
      navigate('/user-account')
      Toast.fire({
        title: 'Password updated!',
        icon: 'success'
      })
      await Alerts.Auth.LOGOUT_USER()
      signout()
      navigate('/login')
    } catch (err) {
      console.error(err)
      setError('login', { type: 'manual', message: handleError(err) })
    }
  }
  return (
    <>
      <Loader loading={loading} text="Saving details" />

      <Card.Container header="Change Password" color={theme`colors.sky`}>
        <FormField>
          <div>
            <Input.Label required>New Password</Input.Label>
            <Input.Default
              {...register('newPassword', {
                minLength: {
                  value: 6,
                  message: 'Password needs to be at least 6 characters'
                },
                required: 'Password is required'
              })}
              type="password"
            />
            <Input.Error>{errors.newPassword?.message}</Input.Error>
          </div>
          <div>
            <Input.Label required>Confirm new Password</Input.Label>
            <Input.Default
              {...register('repeatPassword', {
                required: 'Confirm Password is required',
                validate: (value) => {
                  if (watch('newPassword') != value) {
                    return 'Passwords do no match'
                  }
                }
              })}
              type="password"
            />
            <Input.Error tw="text-center my-2">{errors.repeatPassword?.message}</Input.Error>
            {!errors.repeatPassword && <Input.Error>{errors.confirm?.message}</Input.Error>}
          </div>
          <BtnContainer>
            <Button.Primary onClick={handleSubmit(onSubmit)}>
              <Icon.Check mr="2" size="sm" />
              Save Password
            </Button.Primary>
            <Button.Gray onClick={() => navigate('/user-account')}>
              <Icon.ArrowLeft mr="2" />
              Back
            </Button.Gray>
          </BtnContainer>
        </FormField>
      </Card.Container>
    </>
  )
}
const BtnContainer = tw.div`flex gap-x-1 m-[1rem]`
const FormField = tw.form`mt-[2rem]`
export default ChangePassword
