import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  event: { add: 'ADD_EVENT', edit: 'EDIT_EVENT', remove: 'REMOVE_EVENT', clear: 'CLEAR_EVENTS' },
  location: {
    add: 'ADD_EVENT_LOCATION',
    addMany: 'ADD_MANY_EVENT_LOCATIONS',
    edit: 'EDIT_EVENT_LOCATION',
    remove: 'REMOVE_EVENT_LOCATION',
    clear: 'CLEAR_EVENT_LOCATIONS'
  }
}

export const eventReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.event.add: {
      const { event } = action
      return uniqBy([...state, event], 'id')
    }

    case actionTypes.event.edit: {
      const { event } = action
      return state.map((item) => (item.id === event.id ? event : item))
    }

    case actionTypes.event.remove: {
      const { event } = action
      return state.filter((item) => item.id !== event.id)
    }

    case actionTypes.event.clear: {
      return []
    }

    default:
      return state
  }
}

export const eventLocationReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.location.add: {
      const { location } = action
      return uniqBy([...state, location], 'id')
    }

    case actionTypes.location.addMany: {
      const { locations } = action
      return uniqBy([...state, ...locations], 'id')
    }

    case actionTypes.location.edit: {
      const { location } = action
      return state.map((item) => (item.id === location.id ? location : item))
    }

    case actionTypes.location.remove: {
      const { location } = action
      return state.filter((item) => item.id !== location.id)
    }

    case actionTypes.location.clear: {
      return []
    }
  }
}
