import React, { Fragment, useEffect, useMemo } from 'react'
import { lineString } from '@turf/helpers'
import { Layer, Source } from 'react-map-gl'
import { usePlayer } from '../../../contexts/PlayerContext'
import { arrayToFeatures } from '../../../utility/helperFunctions'
import { mapLayers } from '../../Map/MapLayers'
import { useMap } from '../../../contexts/MapContext'
import { isGeoPoint } from '../../../utility/validationFunctions'
import { useIsMounted } from '../../../hooks/useIsMounted'

export const XpLocations = () => {
  const { activePlayer = {} } = usePlayer()
  const { focusOnFeatures } = useMap()
  const { isMounted } = useIsMounted()

  const xpLocationData = useMemo(() => {
    if (!isMounted() || !activePlayer.xpLocations) return

    const locations = []
    activePlayer.xpLocations.forEach(({ metadata, timestamp }, i) => {
      if (isGeoPoint(metadata?.userLocation)) {
        locations.push({
          centerPoint: {
            latitude: metadata.userLocation?.latitude,
            longitude: metadata.userLocation?.longitude
          },
          index: i,
          timestamp
        })
      }
    })

    const features = arrayToFeatures(locations)
    if (locations.length < 2) return // Skip adding line data

    const lineData = lineString(
      locations.flatMap(({ centerPoint }) => [[centerPoint.longitude, centerPoint.latitude]]),
      { name: 'lineString' }
    )
    return { locations: features, lineData }
  }, [activePlayer.xpLocations, isMounted])

  // Zoom to xp locations
  useEffect(() => {
    if (!isMounted() || !xpLocationData?.locations) return
    focusOnFeatures(xpLocationData.locations, { padding: 150 })
  }, [focusOnFeatures, isMounted, xpLocationData])

  return (
    <Fragment>
      {xpLocationData?.locations && (
        <Source type="geojson" data={xpLocationData.locations} id="dataLocations">
          <Layer {...mapLayers.player.dataLocations} />
          <Layer {...mapLayers.player.locationIndex} />
        </Source>
      )}

      {xpLocationData?.lineData && (
        <Source type="geojson" data={xpLocationData?.lineData} lineMetrics={true}>
          <Layer {...mapLayers.player.locationRoute} />
        </Source>
      )}
    </Fragment>
  )
}
