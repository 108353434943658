import React, { useState, useRef } from 'react'
import tw, { theme } from 'twin.macro'
import { objectIsEmpty } from '../../utility/validationFunctions'
import Icon from '../../styles/Icons'
import { Button } from '../../styles/Layout'
import useBreakpoint from '../../hooks/useBreakpoint'
import { PrizeFilters, PrizeStatus } from '../../enums'
import { ActiveFilters } from '../Filter/ActiveFilters'
import { FilterButton, FilterGrid, FilterInput } from '../Filter'
import { Switch } from '../Layout/Switch'

export const PrizeFilter = ({ filter, items }) => {
  const { clearFilters, filters, andFilters, setAndFilters } = filter
  const [showFilters, setShowFilters] = useState(false)
  const { belowTarget } = useBreakpoint('sm')
  const inputRef = useRef()

  const resetFilters = () => {
    clearFilters()
    if (inputRef.current) inputRef.current.value = ''
  }

  return (
    <FilterContainer>
      <FilterModeWrapper>
        <FilterMode>
          <pre>OR</pre>
          <Switch size="sm" onToggle={() => setAndFilters((v) => !v)} defaultChecked={andFilters} />
          <pre>AND</pre>
        </FilterMode>
        <ButtonsWrapper>
          {!objectIsEmpty(filters) && (
            <Button.White size="sm" tw="m-0" onClick={resetFilters}>
              <Icon.Close size="sm" mr="2" />
              Clear{!belowTarget && <>&nbsp;Filters</>}
            </Button.White>
          )}
          <Button.Basic
            size="sm"
            tw="m-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? (
              <>
                <Icon.ChevronUp size="sm" mr="2" />
                Less{!belowTarget && <>&nbsp;Filters</>}
              </>
            ) : (
              <>
                <Icon.ChevronDown size="sm" mr="2" />
                More{!belowTarget && <>&nbsp;Filters</>}
              </>
            )}
          </Button.Basic>
        </ButtonsWrapper>
      </FilterModeWrapper>

      <FilterGrid size={3}>
        <FilterButton {...PrizeFilters.EXPIRED} {...filter}>
          {PrizeStatus.EXPIRED.icon('sm')}
          <pre>{PrizeStatus.EXPIRED.message}</pre>
        </FilterButton>
        <FilterButton {...PrizeFilters.ALL_CLAIMED} {...filter}>
          {PrizeStatus.ALL_CLAIMED.icon('sm')}
          <pre>{PrizeStatus.ALL_CLAIMED.message}</pre>
        </FilterButton>
        <FilterButton {...PrizeFilters.ALL_RESERVED} {...filter}>
          {PrizeStatus.ALL_RESERVED.icon('sm')}
          <pre>{PrizeStatus.ALL_RESERVED.message}</pre>
        </FilterButton>

        <FilterButton {...PrizeFilters.EXPIRED} {...filter} value={false}>
          {PrizeStatus.EXPIRED.icon('sm', theme`colors.green`)}
          <pre>NOT EXPIRED</pre>
        </FilterButton>
        <FilterButton {...PrizeFilters.ITEMS_LEFT} {...filter}>
          {PrizeStatus.ITEMS_LEFT.icon('sm')}
          <pre>AVAILABLE</pre>
        </FilterButton>
        <FilterButton {...PrizeFilters.ERROR} {...filter}>
          {PrizeStatus.ERROR.icon('sm')}
          <pre>{PrizeStatus.ERROR.message}</pre>
        </FilterButton>
      </FilterGrid>

      {showFilters && (
        <FilterInput
          {...PrizeFilters.QUERY}
          {...filter}
          placeholder="Filter query (name, id, supplier, category, tags)"
          ref={inputRef}
        />
      )}
      <ActiveFilters {...filter} items={items} />
    </FilterContainer>
  )
}

const FilterContainer = tw.div`flex flex-1 flex-col self-center items-center z-20 py-2 px-2 w-full lg:w-3/4 gap-1.5 select-none`
const ButtonsWrapper = tw.div`flex h-full min-w-min overflow-hidden rounded-md justify-end`
const FilterModeWrapper = tw.div`flex justify-between w-full items-center gap-2`
const FilterMode = tw.div`flex items-center h-full gap-2 bg-white-pure p-1 px-2 shadow-inner w-min rounded-md text-xs`
