import React, { Children } from 'react'
import tw, { styled } from 'twin.macro'

export const MapControl = ({ children }) => (
  <ControlsContainer>
    {Children.toArray(children).map((child, i) => (
      <ControlButton key={i}>{child}</ControlButton>
    ))}
  </ControlsContainer>
)

const ControlsContainer = tw.div`absolute top-0 right-0 h-full flex flex-col`
const ControlButton = styled.div`
  ${tw`relative`}
  & > * {
    position: relative !important;
  }
  & > * > small {
    position: relative;
    bottom: 0;
  }
`
