import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import tw, { styled } from 'twin.macro'
import { useDropArea } from 'react-use'
import { Button } from '../styles/Layout'
import { useIsMounted } from '../hooks/useIsMounted'
import Icon from '../styles/Icons'
import config from '../config'
import { Alerts } from '../utility/alerts'
import RangeSlider from './RangeSlider'
import { ImageInput } from './Image'

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const ImageCropper = ({
  aspect = { x: 1, y: 1 },
  imageData,
  flagUpload,
  setImage,
  reset,
  ...props
}) => {
  const MIN_WIDTH = 42 //420
  const MIN_HEIGHT = 75 //750

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropping, setCropping] = useState(false)
  const [error, setError] = useState(false)
  const [saved, setSaved] = useState(false)
  const [imageBlob, setImageBlob] = useState()
  const [croppedImage, setCroppedImage] = useState()
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()
  const { isMounted, mounted } = useIsMounted()
  const [bond] = useDropArea({
    onFiles: (files) => onSelectFile(files)
  })

  useEffect(() => {
    // We had an image, display it
    isMounted() && imageData && setCroppedImage(imageData)
  }, [imageData, isMounted])

  const onMediaLoaded = ({ naturalWidth: width, naturalHeight: height }) => {
    if (width < MIN_WIDTH || height < MIN_HEIGHT) {
      Alerts.Image.LOW_RESOLUTION(width, height)
      setError(true)
      return
    }
  }

  const onSelectFile = async (files) => {
    if (files && !!files.length) {
      const file = files[0]
      if (file.size < config.MAX_IMAGE_UPLOAD_SIZE) {
        const imageBlob = await readFile(file)
        setCropping(true)
        setImageBlob(imageBlob)
        setSaved(false)
      } else {
        Alerts.Image.TOO_BIG(file)
      }
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    if (croppedAreaPixels.width < MIN_WIDTH || croppedAreaPixels.height < MIN_HEIGHT) {
      setError(true)
    } else {
      setError(false)
    }
  }, [])

  const getCroppedImage = async (area) => {
    const image = await mounted(createImage(imageBlob))
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = false
    canvas.width = area.width * zoom
    canvas.height = area.height * zoom
    ctx.drawImage(
      image,
      area.x,
      area.y,
      area.width,
      area.height,
      0,
      0,
      area.width * zoom,
      area.height * zoom
    )
    return canvas.toDataURL(imageBlob.split(';').shift().split(':').pop())
  }

  const showCroppedImage = async () => {
    try {
      const croppedImage = await mounted(getCroppedImage(croppedAreaPixels))
      // Update both croppedImage for displaying and image for saving
      setCroppedImage(croppedImage)
      setImage(croppedImage)
      flagUpload(true) // Tell parent to upload the image
      setSaved(true)
    } catch (err) {
      console.error(err)
    }
    setCropping(false)
    return false
  }

  const resetValues = (hard = false) => {
    if (hard) {
      // Reset both
      setCroppedImage()
      setImage()
      reset && reset()
    } else {
      setImage(imageData)
      setCroppedImage(imageData)
    }
    setImageBlob()
    setCropping(false)
    setZoom(1)
    setSaved(false)
    setError(false)
  }

  const deleteImage = () => {
    setImage(null)
    setCroppedImage()
    setImageBlob()
    setSaved(true)
  }

  return (
    <Container {...props}>
      {croppedImage && (
        <CanvasDiv visible={!cropping}>
          <CroppedImage src={croppedImage} alt="Cropped" />
        </CanvasDiv>
      )}
      {imageBlob && (
        <ImgContainer visible={cropping}>
          <Cropper
            image={imageBlob}
            crop={crop}
            zoom={zoom}
            aspect={aspect.x / aspect.y}
            showGrid={false}
            onMediaLoaded={onMediaLoaded}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{ position: 'relative' }}
          />
        </ImgContainer>
      )}
      {error && <StyledError>Resolution is too low!</StyledError>}

      {imageBlob && !saved ? (
        <StickyFooter tw="flex-col">
          <ZoomSlider
            color="blue"
            hideSteps={true}
            min="1"
            max="3"
            step="0.1"
            value={zoom}
            onChange={({ target }) => {
              setZoom(target.value)
            }}
          />

          <ButtonContainer>
            <Button.Secondary ring size="sm" onClick={showCroppedImage} disabled={error}>
              <Icon.Check size="sm" mr="1" /> Done
            </Button.Secondary>
            <Button.White ring size="sm" onClick={resetValues}>
              <Icon.Close size="sm" mr="1" />
              Cancel
            </Button.White>
          </ButtonContainer>
        </StickyFooter>
      ) : croppedImage ? (
        <StickyFooter>
          <ButtonContainer>
            <Label htmlFor="file-upload">
              <Button.Secondary ring as="div" size="sm">
                <Icon.Upload size="sm" mr="1" />
                Change
                <ImageInput
                  id="file-upload"
                  onChange={({ target }) => onSelectFile(target.files)}
                />
              </Button.Secondary>
            </Label>
            <Button.Warning ring size="sm" onClick={deleteImage}>
              <Icon.Trashcan size="sm" mr="1" />
              Remove
            </Button.Warning>
          </ButtonContainer>
        </StickyFooter>
      ) : (
        <UploadImageButton as="label" htmlFor="file-upload" {...bond}>
          <StyledInput>
            <Icon.Download size="2xl" />
            Click to upload image, <br /> or drag here
          </StyledInput>
          <ImageInput id="file-upload" onChange={({ target }) => onSelectFile(target.files)} />
        </UploadImageButton>
      )}
    </Container>
  )
}

const Container = tw.div`flex flex-col w-full h-full justify-center items-center p-6`
const CanvasDiv = styled.div`
  ${tw`relative flex flex-1 items-center p-4`}
  ${({ visible }) => (visible ? tw`visible` : tw`invisible h-0`)}
`
const CroppedImage = tw.img`object-contain w-full maxHeight[500px] rounded-md`
const ImgContainer = styled.div`
  ${tw`relative flex w-full h-full`}
  ${({ visible }) => (visible ? tw`visible` : tw`invisible h-0`)}
`
const ZoomSlider = tw(RangeSlider)`w-full sm:w-1/3 mb-6`
const StickyFooter = tw.div`flex justify-center items-center w-full sticky bottom-0 p-4 z-50 bg-gray-200`
const ButtonContainer = tw.div`flex gap-3`
const Label = tw.label`flex`
const StyledError = tw.div`text-xs m-2 text-red`
const UploadImageButton = tw.div`flex w-full sm:w-3/5 h-full sm:h-64 justify-center items-center select-none`
const StyledInput = tw.div`
flex flex-col p-4 gap-4 font-light text-3xl rounded-lg justify-center items-center w-full h-full
focus:outline-none hover:bg-gray-300 border-4 border-dashed border-gray-300 hover:border-gray-400 
text-center text-gray cursor-pointer`
