import React from 'react'
import tw, { theme } from 'twin.macro'
import { Button, Card, Input } from '../../styles/Layout'
import { CHALLENGE_TYPE_OPTIONS, TRIVIA_TYPE_OPTIONS } from '../../utility/labeledOptions'
import { Role, ChallengeType } from '../../enums'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { Section } from '../Hvnt/FormSection'
import { TriviaForm } from './Trivia/TriviaForm'
import { useChallenge } from '../../contexts/ChallengeContext'
import Icon from '../../styles/Icons'
import { Loader } from '../Loader/Loader'
import { getOption } from '../../utility/helperFunctions'
import { Action } from '../../layout/List'
import { Alerts } from '../../utility/alerts'
import { db } from '../../lib/firebase'
import Dropdown from '../Dropdown'
import RBAC from '../RBAC'

export const CreateChallenge = ({ challenge }) => {
  const { adding, loading, saveChallenge, setLoading, stopEditingChallenge } = useChallenge()

  const methods = useForm({
    defaultValues: {
      ...challenge,
      triviaType: challenge?.triviaType && getOption(challenge.triviaType, TRIVIA_TYPE_OPTIONS),
      type: challenge?.type && getOption(challenge.type, CHALLENGE_TYPE_OPTIONS)
    },
    mode: 'onBlur'
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = methods
  const [active, type] = useWatch({ control, name: ['active', 'type'] })

  const activeChanged = active ? !challenge?.active : challenge?.active

  const renderForm = (type) => {
    switch (type?.value) {
      case ChallengeType.TRIVIA:
        return <TriviaForm />
      default:
        return <></>
    }
  }

  const onSubmit = async (formData) => {
    await saveChallenge({ ...challenge, ...formData })
  }

  // Delete challenge
  const onDeleteChallenge = async () => {
    const { value: remove } = await Alerts.Challenge.DELETE_WARNING()
    if (!remove) return

    try {
      setLoading(true)
      const { path } = challenge
      db.doc(path).delete()
      Alerts.Challenge.DELETE_SUCCESS()
    } catch (err) {
      return console.error(err)
    } finally {
      setLoading(false)
    }
    stopEditingChallenge()
  }

  return (
    <Card.Container
      header={adding ? 'Create Challenge' : 'Edit Challenge'}
      color={theme`colors.norway.500`}
      xHover={theme`colors.norway.600`}
      xActive={theme`colors.norway.700`}
      xDisabled={loading}
      onClose={stopEditingChallenge}
    >
      <Loader loading={loading} text="Saving challenge..." />

      <Action.Header>
        <Action.Active active={active} changed={activeChanged} />
        <Action.More
          content={
            <>
              {active ? (
                <Button.Primary
                  size="sm"
                  onClick={() => setValue('active', false)}
                  disabled={loading}
                  ring
                >
                  <Icon.Moon size="sm" mr="2" />
                  Deactivate Challenge
                </Button.Primary>
              ) : (
                <Button.Secondary
                  size="sm"
                  onClick={() => setValue('active', true)}
                  disabled={loading}
                  ring
                >
                  <Icon.Sun size="sm" mr="2" />
                  Activate Challenge
                </Button.Secondary>
              )}

              <RBAC allow={[Role.CREATOR]}>
                <Button.Warning
                  size="sm"
                  onClick={onDeleteChallenge}
                  disabled={adding || loading}
                  ring
                >
                  <Icon.Trashcan size="sm" mr="2" />
                  Delete Challenge
                </Button.Warning>
              </RBAC>
            </>
          }
        />
      </Action.Header>

      <FormProvider {...methods}>
        <Form>
          <Controller
            control={control}
            name="type"
            rules={{ required: 'This is required' }}
            render={({ field }) => (
              <Section label="Select challenge type" field={'type'} error={errors.type} required>
                <Input.Label>Challenge type</Input.Label>
                <SelectChallenge {...field} options={CHALLENGE_TYPE_OPTIONS} />
                <Input.Error>{errors.type?.message}</Input.Error>
              </Section>
            )}
          />
          {renderForm(type)}
        </Form>
      </FormProvider>

      <Footer>
        <Button.White onClick={stopEditingChallenge} ring tw="mr-auto">
          <Icon.Close mr="2" />
          Cancel
        </Button.White>

        <Button.Submit onClick={handleSubmit(onSubmit)} disabled={loading} ring>
          <Icon.Check mr="2" />
          Save Challenge
        </Button.Submit>
      </Footer>
    </Card.Container>
  )
}

const Form = tw.form`relative flex flex-1 flex-col w-full overflow-scroll`
const SelectChallenge = tw(Dropdown.Select)`w-full sm:w-1/2`
const Footer = tw(Card.Footer)`z-30 gap-2 py-3 bg-norway`
