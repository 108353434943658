import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  add: 'ADD_LOCATION',
  addData: 'ADD_LOCATION_TAGS',
  set: 'SET_LOCATIONS',
  remove: 'REMOVE_LOCATION',
  clear: 'CLEAR_LOCATIONS'
}

export const locationReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.add: {
      const { location } = action
      return [...state, { ...location }]
    }
    case actionTypes.addData: {
      const { location, data } = action
      return state.map((item) => {
        const { geohash } = item
        return geohash === location.geohash ? { ...item, data: { ...item.data, ...data } } : item
      })
    }
    case actionTypes.clear: {
      return []
    }
    case actionTypes.remove: {
      const { location } = action
      return state.filter((item) => item.geohash !== location.geohash)
    }
    case actionTypes.set: {
      const { locations } = action
      return uniqBy([...state, ...locations], 'geohash')
    }
  }
}
