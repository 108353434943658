import React from 'react'
import tw from 'twin.macro'

export const Crosshair = () => (
  <Container>
    <Leg tw="w-1 h-6" />
    <Leg tw="w-6 h-1" />
  </Container>
)
const Container = tw.div`relative flex justify-center items-center p-2 opacity-70`
const Leg = tw.div`absolute w-6 h-1 rounded-full bg-black`
