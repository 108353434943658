import React from 'react'
import { useMemo } from 'react'
import tw from 'twin.macro'
import { useFormContext, useWatch } from 'react-hook-form'
import { Button, Input } from '../../styles/Layout'
import { validate } from '../../utility/formValidation'
import Icon from '../../styles/Icons'

export const SeasonPrize = ({ index, locked, onMoveDown, onMoveUp, onRemove }) => {
  const {
    formState: { errors },
    register
  } = useFormContext()
  const prizes = useWatch({ name: 'prizes' })

  const prizeRanges = useMemo(() => {
    const ranges = []
    let counter = 0
    for (let i = 0; i < prizes?.length; i++) {
      const amount = prizes[i].reservedAmount
      const min = counter + 1
      counter += amount
      ranges[i] = min !== counter ? `${min} - ${counter}` : min
    }
    return ranges
  }, [prizes])

  return (
    <Row>
      <Col>
        <Input.Label>Placement: {prizeRanges[index]}</Input.Label>
        <PrizeRow>
          <Col>
            <Input.Label required>Prize Name</Input.Label>
            <Input.Default
              {...register(`prizes[${index}].name`, { validate: validate.name })}
              disabled={locked}
            />
            <Input.Error>{errors.prizes?.[index]?.name?.message}</Input.Error>
          </Col>

          <Col>
            <Input.Label required>Amount</Input.Label>
            <Input.Default
              {...register(`prizes[${index}].reservedAmount`, {
                valueAsNumber: true,
                validate: validate.integer
              })}
              disabled={locked}
            />
            <Input.Error>{errors.prizes?.[index]?.reservedAmount?.message}</Input.Error>
          </Col>
        </PrizeRow>
      </Col>

      <ButtonContainer>
        <Button.Primary size="sm" disabled={locked || !index} onClick={onMoveUp} tw="p-1">
          <Icon.ArrowUp size="xs" />
        </Button.Primary>
        <Button.Primary
          size="sm"
          disabled={locked || index === prizes?.length - 1}
          onClick={onMoveDown}
          tw="p-1"
        >
          <Icon.ArrowDown size="xs" />
        </Button.Primary>
        <Button.Warning size="sm" disabled={locked} onClick={onRemove} tw="p-1">
          <Icon.Trashcan size="xs" />
        </Button.Warning>
      </ButtonContainer>
    </Row>
  )
}

const Col = tw.div`flex flex-col flex-1`
const Row = tw.div`flex flex-1 gap-2 items-end`
const PrizeRow = tw(Row)`flex-wrap bg-bismark-200 p-2 rounded-md shadow-inner items-start`
const ButtonContainer = tw.div`flex flex-col gap-1`
