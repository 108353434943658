import { featureCollection, point, polygon } from '@turf/helpers'
import { addDays, formatRelative, isWithinInterval, subDays } from 'date-fns'
import format from 'date-fns/format'
import { enGB } from 'date-fns/locale'
import uniqBy from 'lodash/uniqBy'
import { FieldValue } from '../lib/firebase'

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomInt = () => Math.floor(Math.random() * 100)

export const capitalize = (str) => {
  if (typeof str === 'string') return str.replace(/^[a-zåäö]/, (c) => c.toUpperCase())
}

export const arrayToFeatures = (items) => {
  const features = items.reduce((acc, { centerPoint, ...props }) => {
    if (centerPoint)
      acc.push(
        point([centerPoint.longitude, centerPoint.latitude], {
          centerPoint,
          latitude: centerPoint.latitude,
          longitude: centerPoint.longitude,
          ...props
        })
      )
    return acc
  }, [])

  return featureCollection(features)
}

export const arrayToChunks = (arr, chunkSize) => {
  const output = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    output.push(arr.slice(i, i + chunkSize))
  }
  return output
}

export const createStringArray = (arr = []) =>
  arr?.map((obj) => obj?.value?.toLowerCase().trim() ?? '')
export const createOption = (value) => ({ value: value.trim(), label: value.trim() })
export const optionsToStringArr = (arr) => arr?.map(({ value }) => value)
export const mapKeyValueToOption = ({ key, value, options }) =>
  options.find((option) => option[key] === value)

export const compressOptions = (options) =>
  uniqBy(
    options
      .filter(({ value, label }) => value && label)
      .map(({ value, label }) => ({
        value: value.toLowerCase().trim(),
        label: label.trim()
      })),
    'value'
  )
export const relativeDateUpToDays = (date, days) => {
  const now = new Date()
  const future = date > now
  const within = future
    ? isWithinInterval(date, { start: now, end: addDays(now, days) })
    : isWithinInterval(date, { start: subDays(now, days), end: now })
  return within ? formatRelative(date, now, { locale: enGB }) : format(date, 'LLL dd, HH:mm')
}

export const collateBy = (f) => (g) => (xs) => {
  return xs.reduce((m, x) => {
    let v = f(x)
    return m.set(v, g(m.get(v), x))
  }, new Map())
}

export const getOption = (value, options) => options.find((o) => o.value === value)

export const flatMap = (arr, fn) => arr?.reduce((acc, val) => [...acc, ...fn(val)], [])
export const uniq = (val, i, self) => self.indexOf(val) === i
export const polygonFromBbox = (bbox) => {
  const [minX, minY, maxX, maxY] = bbox
  return polygon([
    [
      [minX, minY],
      [minX, maxY],
      [maxX, maxY],
      [maxX, minY],
      [minX, minY]
    ]
  ])
}

// Deletes value from Firestore
export const conditionalDelete = (value) => (value && value) || FieldValue.delete()

export const timeAgo = (current, previous) => {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = current - previous

  if (elapsed < msPerMinute) return 'now'
  if (elapsed < msPerHour) return Math.round(elapsed / msPerMinute) + ' m'
  if (elapsed < msPerDay) return Math.round(elapsed / msPerHour) + ' h'
  if (elapsed < msPerMonth) return Math.round(elapsed / msPerDay) + ' d'
  if (elapsed < msPerYear) return Math.floor(elapsed / msPerMonth) + ' months'
  return Math.floor(elapsed / msPerYear) + ' years'
}
