import { useCallback } from 'react'
import { locationsConverter } from '../../utility/formatLocations'
import { mercator } from '../../utility/axiosDefaults'
import { actionTypes as locationActions } from '../../reducers/locationsReducer'
import { FieldPath, db, gdb } from '../../lib/firebase'

const useMercator = (dispatch) => {
  // Query locations from Mercator
  const queryLocations = useCallback(
    async (params) => {
      const { data } = await mercator.get('/query', { params })

      const locations = data.locations?.map((location) =>
        locationsConverter.fromFirestore(location)
      )
      dispatch({ type: locationActions.set, locations })
    },
    [dispatch]
  )

  // Request locations from Mercator
  const requestCandidates = useCallback(async (params) => {
    const { data } = await mercator.get('/request', { params })

    return data.requestId
  }, [])

  const migratePlayableLocations = useCallback(async ({ s2Cells }) => {
    // Combine ids in batches of 10
    const s2CellsBundle = createBundle(s2Cells, 10)
    const locations = []

    // Fetch and format data from playable-locations
    await Promise.all(
      s2CellsBundle.map(async (cells) => {
        const snapshot = await db
          .collection('playable-locations')
          .where(FieldPath.documentId(), 'in', cells)
          .get()

        if (snapshot.empty) return
        for (let doc of snapshot.docs) {
          if (!doc.exists) continue
          const cellLocations = []
          const snap = await doc.ref.collection('locations').get()

          snap.forEach((locationsDoc) => {
            const mercatorLocation = locationsConverter.toMercator(locationsDoc)
            cellLocations.push({ id: mercatorLocation.geohash, data: mercatorLocation })
          })
          locations.push(...cellLocations)
        }
      })
    ).then(async () => {
      if (!locations.length) throw new Error('No playable locations found')
      // Write to firestore
      const locationsBundle = createBundle(locations, 450)

      locationsBundle.map(async (bundle) => {
        const batch = gdb.batch()

        for (let location of bundle) {
          const mercDocRef = gdb.collection('locations').doc(location.id)
          batch.set(mercDocRef, location.data)
        }

        await batch.commit()
      })
    })
  }, [])

  return {
    migratePlayableLocations,
    queryLocations,
    requestCandidates
  }
}

export default useMercator

const createBundle = (items, maxLength = 450) => {
  const arr = []
  let batch = []

  for (let item of items) {
    batch.push(item)
    if (batch.length >= maxLength) {
      arr.push(batch)
      batch = []
    }
  }
  // Add remaining batch
  if (batch.length) arr.push(batch)

  return arr
}
