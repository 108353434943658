import React, { createContext, useCallback, useContext, useMemo, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import {
  Achievements,
  Participations,
  Profile,
  Statistics,
  Transactions,
  XP
} from '../components/Player/Tabs'
import { useGetAuthUser } from '../hooks/firebase/useGetUser'
import { actionTypes, playerDataReducer, playerReducer } from '../reducers/playerReducer'
import { Alerts } from '../utility/alerts'
import { ProfileTab } from '../enums'

export const PlayerContext = createContext()

export const PlayerProvider = ({ children }) => {
  const navigate = useNavigate()
  const [players, p_dispatch] = useReducer(playerReducer, [])
  const [activePlayer, dispatch] = useReducer(playerDataReducer)
  const [activeTab, setActiveTab] = useState(profileTabs[ProfileTab.PROFILE])
  const [addingUser, setAddingUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fetchingUser, setFetchingUser] = useState(false)
  const { onQueryUser } = useGetAuthUser(p_dispatch)

  // Add user
  const addNewUser = useCallback(() => {
    setAddingUser(true)
  }, [])

  // Show profile
  const showPlayerProfile = useCallback(
    (data) => {
      dispatch({ type: actionTypes.activePlayerData.add, data })
      const tab = profileTabs[ProfileTab.PROFILE]
      setActiveTab(tab)
      navigate(`${data.uid}/${tab.path}`, { replace: true })
    },
    [navigate, setActiveTab]
  )

  // Close profile
  const closePlayerProfile = useCallback(() => {
    dispatch({ type: actionTypes.activePlayerData.clear })
  }, [])

  // Pre-fetch user data and navigate to specified path
  const visitDeepRoute = useCallback(
    async (state) => {
      try {
        setFetchingUser(true)
        const { tab, id } = state
        const player = await onQueryUser({ query: id, type: 'id' })

        // set as activePlayer
        dispatch({ type: actionTypes.activePlayerData.add, data: player })
        const profileTab = profileTabs[tab.toUpperCase()] ?? profileTabs[ProfileTab.PROFILE]

        setActiveTab(profileTab)
        navigate(`${id}/${profileTab.path}`, { replace: true })
      } catch (err) {
        Alerts.General.ERROR(err)
        console.error(err)
      } finally {
        setFetchingUser(false)
      }
    },
    [navigate, onQueryUser]
  )

  // Sync active player if players are updated
  useUpdateEffect(() => {
    if (!activePlayer) return
    const newPlayer = players.find((p) => p.uid === activePlayer.uid)
    dispatch({ type: actionTypes.activePlayerData.add, data: newPlayer })
  }, [players])

  const defaultValues = useMemo(
    () => ({
      activePlayer,
      activeTab,
      addingUser,
      addNewUser,
      closePlayerProfile,
      dispatch,
      fetchingUser,
      loading,
      p_dispatch,
      players,
      profileTabs,
      setActiveTab,
      setLoading,
      showPlayerProfile,
      visitDeepRoute
    }),
    [
      activePlayer,
      activeTab,
      addNewUser,
      addingUser,
      closePlayerProfile,
      fetchingUser,
      loading,
      players,
      showPlayerProfile,
      visitDeepRoute
    ]
  )

  return <PlayerContext.Provider value={defaultValues}>{children}</PlayerContext.Provider>
}

// Hook
export const usePlayer = () => {
  const context = useContext(PlayerContext)
  if (context === undefined)
    throw new Error('`usePlayer` hook must be used within a `PlayerProvider` component')
  return context
}

const profileTabs = {
  [ProfileTab.PROFILE]: {
    component: <Profile />,
    id: ProfileTab.PROFILE,
    path: 'profile',
    title: 'Profile'
  },
  [ProfileTab.STATS]: {
    component: <Statistics />,
    id: ProfileTab.STATS,
    path: 'stats',
    title: 'Stats'
  },
  [ProfileTab.PARTICIPATIONS]: {
    component: <Participations />,
    id: ProfileTab.PARTICIPATIONS,
    path: 'participations',
    title: 'Participations'
  },
  [ProfileTab.XP]: {
    component: <XP />,
    id: ProfileTab.XP,
    path: 'xp',
    title: 'XP'
  },
  [ProfileTab.TRANSACTIONS]: {
    component: <Transactions />,
    id: ProfileTab.TRANSACTIONS,
    path: 'transactions',
    title: 'Coins'
  },
  [ProfileTab.ACHIEVEMENTS]: {
    component: <Achievements />,
    id: ProfileTab.ACHIEVEMENTS,
    path: 'achievements',
    title: 'Achievements'
  }
}
