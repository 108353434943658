import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect
} from 'react'
import useGetSeasons from '../hooks/firebase/useGetSeasons'
import { db, FieldValue } from '../lib/firebase'
import { seasonReducer } from '../reducers/seasonReducer'
import { Alerts } from '../utility/alerts'
import { seasonConverter } from '../utility/formatSeasons'

export const SeasonsContext = createContext()

export const SeasonsProvider = ({ children }) => {
  const [seasons, dispatch] = useReducer(seasonReducer, [])
  const { onNextSeasons } = useGetSeasons(dispatch)

  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(false)
  const [expandedItem, setExpandedItem] = useState()
  const [loading, setLoading] = useState(false)
  const [seasonBeingEdited, setSeasonBeingEdited] = useState()
  const [validateForm, setValidateForm] = useState(false)

  // Add new season
  const addNewSeason = useCallback(() => {
    // Create new document
    const newDoc = db.collection('seasons').doc()
    setSeasonBeingEdited({ id: newDoc.id, path: newDoc.path })
    setAdding(true)
  }, [])

  // Start editing season
  const startEditingSeason = useCallback((season) => {
    setSeasonBeingEdited(season)
    setAdding(false)
    setEditing(true)
    setValidateForm(season.active ?? true)
  }, [])

  // Stop editing season
  const stopEditingSeason = useCallback(() => {
    setSeasonBeingEdited()
    setAdding(false)
    setEditing(false)
  }, [])

  // Save season to firestore
  const saveSeason = useCallback(
    async (season) => {
      try {
        const { path } = season
        if (!path) throw new Error('Missing path')
        setLoading(true)

        const seasonRef = db.doc(path)

        await seasonRef.withConverter(seasonConverter).set(
          {
            ...season,
            ...(adding && { createdAt: FieldValue.serverTimestamp() })
          },
          { merge: true }
        )

        Alerts.Season.SAVE_SUCCESS(adding)

        stopEditingSeason()
      } catch (err) {
        console.error(err)
        Alerts.Season.SAVE_FAILED(adding, err)
      } finally {
        setLoading(false)
      }
    },
    [adding, stopEditingSeason]
  )

  // Delete or deactivate season
  const deleteSeason = useCallback(
    async (season) => {
      try {
        setLoading(true)
        const { path } = season
        if (validateForm && season.dateRange?.startDate < new Date()) {
          await Alerts.Season.DELETE_NOT_POSSIBLE()
          db.doc(path).set({ active: false }, { merge: true })
          Alerts.Season.SAVE_SUCCESS()
        } else {
          db.doc(path).delete()
          Alerts.Season.DELETE_SUCCESS()
        }
      } catch (err) {
        Alerts.Season.SAVE_FAILED(err)
        return console.error(err)
      } finally {
        setLoading(false)
      }
      stopEditingSeason()
    },
    [stopEditingSeason, validateForm]
  )

  // Subscribe to seasons
  useEffect(() => {
    const unsubscribe = db
      .collection('seasons')
      .withConverter(seasonConverter)
      .onSnapshot(onNextSeasons)
    return unsubscribe
  }, [onNextSeasons])

  const defaultValues = useMemo(
    () => ({
      adding,
      addNewSeason,
      editing,
      deleteSeason,
      expandedItem,
      loading,
      saveSeason,
      seasonBeingEdited,
      seasons,
      setExpandedItem,
      setValidateForm,
      startEditingSeason,
      stopEditingSeason,
      validateForm
    }),
    [
      adding,
      addNewSeason,
      editing,
      deleteSeason,
      expandedItem,
      loading,
      saveSeason,
      seasonBeingEdited,
      seasons,
      startEditingSeason,
      stopEditingSeason,
      validateForm
    ]
  )

  return <SeasonsContext.Provider value={defaultValues}>{children}</SeasonsContext.Provider>
}

// Hook
export const useSeason = () => {
  const context = useContext(SeasonsContext)
  if (context === undefined)
    throw new Error('`useSeason` hook must be used within a `SeasonsProvider` component')
  return context
}
