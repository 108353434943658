import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { Controller, useFormContext } from 'react-hook-form'
import { Input } from '../../styles/Layout'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useCheckpointMap } from '../../contexts/CheckpointMapContext'
import turfCircle from '@turf/circle'
import { RADIUS_OPTIONS } from '../../utility/labeledOptions'
import Dropdown from '../Dropdown'

export const AreaForm = ({ checkpoint }) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext()
  const { setAreaShot } = useCheckpointMap()
  const { isMounted } = useIsMounted()
  const radius = watch('radius')

  // Update Area Shot when radius is specified
  useEffect(() => {
    if (!isMounted()) return
    if (radius && checkpoint?.centerPoint) {
      const { latitude, longitude } = checkpoint.centerPoint
      const circle = turfCircle([longitude, latitude], radius.value, { units: 'meters' })
      setAreaShot(circle)
    } else {
      setAreaShot()
    }
    return () => setAreaShot()
  }, [radius, checkpoint, isMounted, setAreaShot])

  return (
    <Section>
      <Input.Label required={true}>Radius</Input.Label>
      <Controller
        control={control}
        name="radius"
        render={({ field }) => (
          <Dropdown.Select {...field} placeholder="Radius" options={RADIUS_OPTIONS} />
        )}
        rules={{ required: 'Required field' }}
      />
      <Input.Error>{errors?.radius?.message}</Input.Error>
    </Section>
  )
}
const Section = tw.div`flex flex-col my-2 px-4 w-full`
