import React, { useCallback, useEffect, useState } from 'react'
import tw, { styled, theme, css } from 'twin.macro'
import { FormProvider, useForm } from 'react-hook-form'
import { Loader } from '../Loader/Loader'
import { usePrizes } from '../../contexts/PrizeContext'
import { Button, Card } from '../../styles/Layout'
import { prizeConverter, prizeInstanceConverter } from '../../utility/formatPrizes'
import Icon from '../../styles/Icons'
import { Alerts } from '../../utility/alerts'
import { db } from '../../lib/firebase'
import useCloudinary from '../../hooks/useCloudinary'
import { validate } from '../../utility/formValidation'
import { PrizeFormTab } from '../../enums'
import { MorePrizeInfo } from './MorePrizeInfo'
import { DigitalPrizeForm } from './DigitalPrizeForm'

export const CreateDigitalPrize = ({ prize }) => {
  const { uploadImage } = useCloudinary()
  const { adding, stopEditingPrize } = usePrizes()
  const methods = useForm({ defaultValues: prize, mode: 'onChange' })
  const { handleSubmit, register, setError } = methods

  const [activeFormTab, setActiveFormTab] = useState(PrizeFormTab.UPDATE)
  const [addedImage, setAddedImage] = useState()
  const [loading, setLoading] = useState(false)

  // Register fields on mount
  useEffect(() => {
    register('active')
    register('amount', { required: 'Prizes are required', validate: validate.prize.amount })
    register('importedPrizes')
  }, [register])

  // Save prize
  const onSubmit = useCallback(
    async (formData) => {
      const { importedPrizes, ...prizeData } = formData

      // Upload image if one was added
      if (addedImage) {
        setLoading(true)
        try {
          prizeData.imageUrl = await uploadImage({
            file: prizeData.imageUrl,
            id: prize.id,
            path: 'prizes/'
          })
        } catch (err) {
          setError('imageUrl', { type: 'manual', message: 'Image might be too big!' })
          return Alerts.Image.UPLOAD_FAILED(err)
        }
      }

      // Upload prize to firebase
      try {
        setLoading(true)
        await db.runTransaction(async (t) => {
          const prizeDoc = db.collection('prizes').withConverter(prizeConverter).doc(prize.id)
          t.set(prizeDoc, prizeData, { merge: true })

          // Add new prize instances to `available` collection
          if (adding) {
            const { validTo } = prizeData
            importedPrizes.forEach((item) => {
              const prizeInstanceDoc = prizeDoc
                .collection('available')
                .withConverter(prizeInstanceConverter)
                .doc()

              t.set(prizeInstanceDoc, { prizeDoc, validTo, ...item })
            })
          }
        })
      } catch (err) {
        console.error(err)
        return Alerts.Prize.UPDATE_FAILED(err)
      } finally {
        setLoading(false)
      }

      Alerts.Prize.UPDATE_SUCCESS(adding)
      stopEditingPrize()
    },
    [addedImage, adding, prize, setError, stopEditingPrize, uploadImage]
  )

  return (
    <Card.Container
      header={adding ? 'New Digital Prize' : 'Edit Digital Prize'}
      color={theme`colors.sky.500`}
      xHover={theme`colors.sky.600`}
      xActive={theme`colors.sky.700`}
      onClose={stopEditingPrize}
    >
      <Loader loading={loading} text="Saving Prize" />
      {!adding && (
        <Tabs>
          <Tab
            active={activeFormTab === PrizeFormTab.UPDATE}
            onClick={() => setActiveFormTab(PrizeFormTab.UPDATE)}
          >
            Update Prize
          </Tab>
          <Tab
            active={activeFormTab === PrizeFormTab.DETAILS}
            onClick={() => setActiveFormTab(PrizeFormTab.DETAILS)}
          >
            More Details
          </Tab>
        </Tabs>
      )}

      <FormProvider {...methods}>
        <Container>
          {activeFormTab === PrizeFormTab.DETAILS ? (
            <MorePrizeInfo />
          ) : (
            <DigitalPrizeForm flagUpload={setAddedImage} prize={prize} />
          )}
        </Container>

        <Card.Footer tw="bg-sky">
          <Button.White ring onClick={stopEditingPrize} tw="mr-auto">
            <Icon.Close mr="2" />
            Cancel
          </Button.White>
          <Button.Submit ring onClick={handleSubmit(onSubmit)}>
            <Icon.Check mr="2" />
            Save Prize
          </Button.Submit>
        </Card.Footer>
      </FormProvider>
    </Card.Container>
  )
}

const Container = tw.div`flex flex-col h-full w-full overflow-y-scroll`
const Tabs = tw.div`flex w-full pb-0 bg-sky-600 gap-2 first:pl-2`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer text-center rounded-t-md text-lg tracking-wide select-none`}
  ${({ active }) => css`
    ${active ? tw`bg-gray-200` : tw`text-white hover:bg-sky-700 `}
  `}
`
