import React, { useEffect, useReducer } from 'react'
import tw from 'twin.macro'
import { Button, Info } from '../../../styles/Layout'
import Icon from '../../../styles/Icons'
import { db } from '../../../lib/firebase'
import useGetPrizes from '../../../hooks/firebase/useGetPrizes'
import { prizeReducer } from '../../../reducers/prizeReducer'
import { ReservedPrizeItem } from './HvntPrizeItem'
import { HvntPrizeModal } from './HvntPrizeModal'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

export const HvntPrizes = ({ addPrize, locked, removePrize, updatePrize }) => {
  const [prizes, dispatch] = useReducer(prizeReducer, [])
  const { onNextHvntPrizes } = useGetPrizes()
  const { watch } = useFormContext()
  const hvntPrizes = watch('prizes')
  const prizeRanges = useMemo(() => {
    const ranges = []
    let counter = 0
    for (let i = 0; i < hvntPrizes?.length; i++) {
      const amount = hvntPrizes[i].reservedAmount
      const min = counter + 1
      counter += amount
      ranges[i] = min !== counter ? `${min} - ${counter}` : min
    }
    return ranges
  }, [hvntPrizes])

  // Subscribe to prizes
  useEffect(() => {
    const unsubscribe = db
      .collection('prizes')
      .where('active', '==', true)
      .onSnapshot((onNext) => onNextHvntPrizes(onNext, dispatch))
    return unsubscribe
  }, [onNextHvntPrizes])

  return (
    <Container>
      {hvntPrizes?.map((prize, i) => (
        <PrizeWrapper key={i}>
          <div tw="text-gray-900 text-lg">Placement: {prizeRanges[i]}</div>
          <ReservedPrizeItem
            prize={prize}
            prizes={prizes}
            removePrize={() => removePrize(i)}
            updatePrize={(prize) => updatePrize(i, prize)}
            tw="flex-1"
          />
        </PrizeWrapper>
      ))}

      {locked ? (
        <Info>Prizes cannot be changed once the hvnt has been activated!</Info>
      ) : (
        <HvntPrizeModal
          addPrize={addPrize}
          OpenButton={(props) => (
            <OpenButton {...props} disabled={locked}>
              <Icon.Gift mr="2" />
              {hvntPrizes.length ? 'Add Another Prize' : 'Add Prize'}
            </OpenButton>
          )}
          prizes={prizes}
        />
      )}
    </Container>
  )
}

const OpenButton = tw(Button.Primary)`m-0 w-full sm:w-min`
const Container = tw.div`flex flex-col w-full gap-4`
const PrizeWrapper = tw.div`flex flex-col gap-0.5`
