import { useCallback } from 'react'
import { db, functions } from '../../lib/firebase'
import { actionTypes } from '../../reducers/playerReducer'
import { authDataConverter, userDataConverter } from '../../utility/formatUserData'

const useGetUser = (dispatch) => {
  // Subscribe to user
  const onNextUser = useCallback(
    (snapshot) => {
      const userData = snapshot.exists ? snapshot.data() : {}
      const path = snapshot.ref.path
      dispatch({ type: actionTypes.activePlayerData.add, data: { userData, path } })
    },
    [dispatch]
  )

  // Get user meta data
  const onGetStats = useCallback(
    async (snapshot) => {
      if (!snapshot.exists) return
      const { stats } = snapshot.data()
      dispatch({ type: actionTypes.activePlayerData.add, data: { stats } })
    },
    [dispatch]
  )

  // Get user achievements
  const onNextAchievements = useCallback(
    (snapshot) => {
      const achievements = []
      snapshot.forEach((doc) => achievements.push({ ...doc.data(), id: doc.id }))
      dispatch({ type: actionTypes.activePlayerData.add, data: { achievements } })
    },
    [dispatch]
  )

  // Get user inventory
  const onNextInventory = useCallback(
    (snapshot) => {
      const inventory = []
      snapshot.forEach((doc) => inventory.push({ ...doc.data(), id: doc.id }))
      dispatch({ type: actionTypes.activePlayerData.add, data: { inventory } })
    },
    [dispatch]
  )

  // Get user participations
  const onNextParticipations = useCallback(
    (snapshot) => {
      const participations = []
      snapshot.forEach((doc) => participations.push(doc.data()))
      dispatch({ type: actionTypes.activePlayerData.add, data: { participations } })
    },
    [dispatch]
  )

  // Get user prizes
  const onNextPrizes = useCallback(
    (snapshot) => {
      const prizes = []
      snapshot.forEach((doc) => prizes.push(doc.data()))
      dispatch({ type: actionTypes.activePlayerData.add, data: { prizes } })
    },
    [dispatch]
  )

  // Get user transactions
  const onNextTransactions = useCallback(
    (snapshot) => {
      const transactions = []
      snapshot.forEach((doc) => transactions.push(doc.data()))
      dispatch({ type: actionTypes.activePlayerData.add, data: { transactions } })
    },
    [dispatch]
  )

  // Get user xp
  const onNextXP = useCallback(
    (snapshot) => {
      const xpEntries = []
      snapshot.forEach((doc) => xpEntries.push(doc.data()))
      dispatch({ type: actionTypes.activePlayerData.add, data: { xpEntries } })
    },
    [dispatch]
  )

  // Update firestore data
  const updateUserData = useCallback(
    ({ data = {}, uid }) =>
      db.doc(`/users/${uid}`).withConverter(userDataConverter).set(data, { merge: true }),
    []
  )

  return {
    onGetStats,
    onNextAchievements,
    onNextInventory,
    onNextParticipations,
    onNextPrizes,
    onNextTransactions,
    onNextUser,
    onNextXP,
    updateUserData
  }
}

const useGetAuthUser = (p_dispatch) => {
  // Query user
  const onQueryUser = useCallback(
    async ({ query, type }) => {
      const user = await functions.authGetUser({ type, query })
      if (!user?.data) throw new Error('Error fetching user')

      const player = authDataConverter.fromAuth(user.data)
      p_dispatch({ type: actionTypes.players.add, player })
      return player
    },
    [p_dispatch]
  )

  // Update authentication data
  const updateAuthData = useCallback(
    async ({ data, uid }) => {
      const authData = authDataConverter.toAuth(data)
      const res = await functions.authUpdateUser({ uid, user: authData })
      const player = authDataConverter.fromAuth(res.data)

      p_dispatch({ type: actionTypes.players.edit, player })
    },
    [p_dispatch]
  )

  // Set custom claims
  const setCustomClaims = useCallback(
    async ({ data, uid }) => {
      const res = await functions.authSetCustomClaims({ uid, ...data })
      const player = authDataConverter.fromAuth(res.data)

      p_dispatch({ type: actionTypes.players.edit, player })
    },
    [p_dispatch]
  )

  return {
    onQueryUser,
    setCustomClaims,
    updateAuthData
  }
}

export { useGetAuthUser, useGetUser }
