import uniqBy from 'lodash/uniqBy'
export const actionTypes = {
  players: {
    add: 'ADD_PLAYER',
    edit: 'EDIT_PLAYER',
    remove: 'REMOVE_PLAYER',
    clear: 'CLEAR_PLAYERS'
  },
  activePlayerData: {
    add: 'ADD_ACTIVE_PLAYER_DATA',
    edit: 'EDIT_ACTIVE_PLAYER_DATA',
    remove: 'REMOVE_ACTIVE_PLAYER_DATA',
    clear: 'CLEAR_ACTIVE_PLAYER_DATA'
  }
}

export const playerReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.players.add: {
      const { player } = action
      return uniqBy([{ ...player }, ...state], 'uid')
    }
    case actionTypes.players.edit: {
      const { player } = action
      return state.map((item) => (item.uid === player.uid ? { ...item, ...player } : item))
    }

    case actionTypes.players.remove: {
      const { player } = action
      return state.filter((item) => item.uid !== player.uid)
    }

    case actionTypes.players.clear: {
      return []
    }
  }
}

export const playerDataReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.activePlayerData.add: {
      const { data } = action
      return { ...state, ...data }
    }

    case actionTypes.activePlayerData.clear: {
      return
    }
  }
}
