import { useCallback, useState } from 'react'

const useSort = (init = (arr) => arr) => {
  const [sortingBy, setSortingBy] = useState({ sort: init, inc: true })

  // Update state
  const sortListBy = useCallback((sort) => {
    setSortingBy((sortingBy) => ({ sort, inc: sortingBy?.sort === sort ? !sortingBy.inc : true }))
  }, [])

  // Sort array
  const applySort = useCallback(
    (items = []) => {
      if (!sortingBy?.sort) return items

      const { sort, inc } = sortingBy
      return sort([...items], inc)
    },
    [sortingBy]
  )

  return { applySort, sortingBy, sortListBy }
}

export default useSort
