import React, { Fragment } from 'react'
import tw, { styled, theme } from 'twin.macro'
import Icon from '../../styles/Icons'
import { MoreButton, Status } from '../../styles/Layout'

export const Action = {
  Active: Object.assign(({ active, changed, size = 'sm', ...props }) => (
    <ActionWrapper {...props} data-testid="action-active">
      {changed && (
        <>
          <Status active={!active} size={size} text={true} />
          <Icon.ArrowRight size="xs" />
        </>
      )}
      <Status active={active} size={size} text={true} />
    </ActionWrapper>
  )),
  Header: styled.div`
    ${tw`flex sticky top-0 w-full items-center gap-2 py-1.5 px-4 z-30 text-sm font-medium text-gray-900`}
    ${({ color = theme`colors.gray.200` }) => color && `background-color: ${color}`}
  `,
  Right: tw.div`flex items-center ml-auto gap-2`,
  More: (props) => (
    <ButtonWrapper>
      <MoreButton size="md" {...props} />
    </ButtonWrapper>
  ),
  Status: Object.assign(({ nextStatus, status, ...props }) => (
    <ActionWrapper {...props}>
      {status?.icon('md')}
      {status?.message}
      {nextStatus && (
        <>
          <Icon.ArrowRight size="xs" />
          {nextStatus.icon('sm')}
          {nextStatus.message}
        </>
      )}
    </ActionWrapper>
  ))
}

const ActionWrapper = tw.div`flex items-center select-none gap-1`
const ButtonWrapper = tw.div`ml-auto`
