import React, { useState, useMemo, useCallback, useEffect } from 'react'
import tw, { theme } from 'twin.macro'
import { Button, Status } from '../../../styles/Layout'
import { useClue } from '../../../contexts/ClueContext'
import { Loader } from '../../Loader/Loader'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { Checkbox } from '../../Checkbox'
import Icon from '../../../styles/Icons'
import { Alerts, Toast } from '../../../utility/alerts'
import { ClueType } from '../../../enums'
import Item from '../../../styles/Clue/ClueItem.js'

export const CandidateClueItems = ({ items = [] }) => {
  const { displayImage, setFocusedClue } = useClue()

  const onClickItem = useCallback(
    (clue) => {
      console.log({ clue })
      if (clue.imageData || clue.imageUrl) displayImage(clue)
      setFocusedClue(clue)
    },
    [displayImage, setFocusedClue]
  )

  return useMemo(
    () =>
      items.map((clue) => (
        <CandidateClueItem clue={clue} onClickItem={onClickItem} key={clue.id} />
      )),
    [items, onClickItem]
  )
}

const CandidateClueItem = ({ clue, onClickItem }) => {
  const { submitDisabled } = useCheckpoint()
  const { acceptClue, addNewClue, rejectClue } = useClue()
  const [free, setFree] = useState(clue.free ?? false)
  const [loading, setLoading] = useState(false)

  const hasError = useMemo(() => !!clue?.errors?.length, [clue])

  useEffect(() => setLoading(false), [])

  const onClickEdit = (e) => {
    e.stopPropagation()
    addNewClue(clue)
  }

  const onClickAccept = async (clue) => {
    try {
      setLoading(true)
      await acceptClue({ ...clue, free })
      Toast.fire({ title: 'Clue Accepted!', icon: 'success' })
    } catch (err) {
      Alerts.Clue.SAVE_FAILED(err)
      setLoading(false)
    }
  }

  const onClickReject = async (clue) => {
    try {
      setLoading(true)
      await rejectClue(clue)
      Toast.fire({ title: 'Clue Rejected!', icon: 'success' })
    } catch (err) {
      Alerts.Clue.SAVE_FAILED(err)
      setLoading(false)
    }
  }

  return (
    <Item.Container>
      <Loader loading={loading} size="sm" />
      <Item.Row tw="bg-bismark-200">
        <Item.Header clue={clue}>
          <Status active={clue.active} />
          {Item.setHeader(clue)}
        </Item.Header>

        <Item.ButtonData>
          <Checkbox
            color="blue"
            size="sm"
            label="Free"
            checked={free}
            onCheck={setFree}
            disabled={
              submitDisabled || [ClueType.TREASURE, ClueType.STREET_VIEW].includes(clue.type?.value)
            }
            tw="mr-2"
          />

          <ActionButton size="sm" onClick={() => onClickAccept(clue)} disabled={submitDisabled}>
            <Icon.ThumbsUpSolid color={theme`colors.green`} size="xs" />
          </ActionButton>
          <ActionButton size="sm" onClick={() => onClickReject(clue)} disabled={submitDisabled}>
            <Icon.ThumbsDownSolid color={theme`colors.red`} size="xs" />
          </ActionButton>
        </Item.ButtonData>
        {!!hasError && <Icon.ErrorBubble size="xl" tw="absolute right-1" />}
      </Item.Row>
      <Item.ClueContent clue={clue} onClick={() => onClickItem(clue)}>
        <ButtonContainer>
          <Button.Gray size="sm" onClick={onClickEdit} disabled={submitDisabled}>
            <Icon.Edit size="sm" mr="2" /> Edit
          </Button.Gray>
        </ButtonContainer>
      </Item.ClueContent>
    </Item.Container>
  )
}

const ButtonContainer = tw.div`m-1`
const ActionButton = tw(Button.Basic)`bg-bismark-100 p-1`
