import React, { useCallback, useMemo } from 'react'
import config from '../../../../config'
import { useCorporate } from '../../../../contexts/CorporateContext'
import { EventLocationType } from '../../../../enums'
import { Item } from '../../../../layout/List'
import Icon from '../../../../styles/Icons'
import { Button, Status } from '../../../../styles/Layout'
import { ATag, CheckpointTag } from '../../../../styles/Link'
import { CheckpointSummary } from '../../../Checkpoint/CheckpointSummary'

export const Items = ({ items }) => {
  const { expandedLocationItem, setExpandedLocationItem } = useCorporate()

  const onClickItem = useCallback(
    (location, opened) => {
      console.log({ location })
      opened ? setExpandedLocationItem(location) : setExpandedLocationItem()
    },
    [setExpandedLocationItem]
  )

  return useMemo(
    () =>
      items.map((event, i) => (
        <LocationItem
          key={i}
          expanded={expandedLocationItem?.id === event?.id}
          item={event}
          onClick={onClickItem}
        />
      )),
    [expandedLocationItem?.id, items, onClickItem]
  )
}

const LocationItem = ({ expanded, item, onClick }) => {
  const { deleteEventLocation, startEditingEventLocation } = useCorporate()
  const { active, name, type } = item
  const editable = item.type !== EventLocationType.HIDDEN

  return (
    <Item.Container>
      <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
        <Item.HeaderData colSpan={1}>
          <Item.Center>
            <Status active={active} />
          </Item.Center>
        </Item.HeaderData>
        <Item.HeaderData colSpan={1}>{type}</Item.HeaderData>
        <Item.HeaderData colSpan={3}>{name}</Item.HeaderData>
      </Item.Header>

      <Item.Summary expanded={expanded}>
        {Summary(item)}
        <Item.ActionButtons>
          <>
            {editable && (
              <Button.Secondary size="sm" onClick={() => startEditingEventLocation(item)}>
                <Icon.Edit size="sm" mr="2" /> Edit Location
              </Button.Secondary>
            )}
            <Button.Warning size="sm" onClick={() => deleteEventLocation(item)}>
              <Icon.Trashcan size="sm" mr="2" />
              Remove
            </Button.Warning>
          </>
        </Item.ActionButtons>
      </Item.Summary>
    </Item.Container>
  )
}

const Summary = (item) => {
  const { centerPoint, challenges, customTag, id, path, ref } = item
  const defaultItems = () => (
    <>
      <Item.Row>
        <Item.Bold>{'Location (Lat, Lng):'}</Item.Bold>
        <Item.Text>
          {centerPoint ? `${centerPoint?.latitude}, ${centerPoint?.longitude}` : '-'}
        </Item.Text>
      </Item.Row>

      {customTag && (
        <Item.Row>
          <Item.Bold>{'Custom Tag:'}</Item.Bold>
          <pre>{customTag ?? '-'}</pre>
        </Item.Row>
      )}
    </>
  )

  switch (item.type) {
    case EventLocationType.HIDDEN:
      return (
        <CheckpointSummary item={item} clues={item.clues ?? []}>
          <Item.Row>
            <Item.Bold>{'Checkpoint:'}</Item.Bold>
            <CheckpointTag to={`/checkpoints?id=${ref.id}&edit=true`}>{ref.id}</CheckpointTag>
          </Item.Row>

          {defaultItems()}
        </CheckpointSummary>
      )

    case EventLocationType.TRIVIA:
    case EventLocationType.LOGO:
      return (
        <Item.SummaryList>
          <Item.Row>
            <Item.Bold>{'Id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag>
          </Item.Row>

          {defaultItems()}

          {challenges?.map(({ question }, i) => (
            <Item.Row key={i}>
              <Item.Bold>{`Question ${i + 1}`}</Item.Bold>
              <Item.Text>{question}</Item.Text>
            </Item.Row>
          ))}
        </Item.SummaryList>
      )
    case EventLocationType.AR:
      return (
        <Item.SummaryList>
          <Item.Row>
            <Item.Bold>{'Id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag>
          </Item.Row>
          {defaultItems()}
        </Item.SummaryList>
      )
  }
}
