import React, { useCallback, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import tw from 'twin.macro'
import config from '../../config'
import { usePrizes } from '../../contexts/PrizeContext'
import { Action, Item } from '../../layout/List'
import Icon from '../../styles/Icons'
import { Button, Info, Input } from '../../styles/Layout'
import { Alerts } from '../../utility/alerts'
import { validate } from '../../utility/formValidation'
import { parseCsvPrizes } from '../../utility/parseCsvPrizes'
import { createOption } from '../../utility/helperFunctions'
import { PRIZE_CURRENCY_OPTIONS } from '../../utility/labeledOptions'
import Dropdown from '../Dropdown'
import { ImageModal } from '../Modal'
import RBAC from '../RBAC'
import { PrizeDateRange } from './PrizeDateRange'

export const DigitalPrizeForm = ({ flagUpload, prize }) => {
  const { adding, categoryOptions, deletePrize, supplierOptions, tagOptions } = usePrizes()
  const {
    control,
    formState: { errors },
    register,
    resetField,
    setValue
  } = useFormContext()
  const [active, amount, imageUrl, marketValue] = useWatch({
    control,
    name: ['active', 'amount', 'imageUrl', 'marketValue.value']
  })

  const [addedPrizes, setAddedPrizes] = useState(false)
  const [prizeCodes, setPrizeCodes] = useState([])
  const [voucherFieldOptions, setVoucherFieldOptions] = useState([])

  const activeChanged = active ? !prize.active : prize.active
  const canDeletePrize = !prize.reserved

  // Import csv file
  const onImportCsv = useCallback(
    ({ target }) => {
      if (!target.files?.length) return

      var fr = new FileReader()
      fr.readAsText(target.files[0])
      fr.onload = (e) => {
        let lines = e.target.result
        try {
          const { data, fields } = parseCsvPrizes(lines)
          if (!data.length) throw new Error('Failed to read file')

          setVoucherFieldOptions(
            fields.map((field) => {
              if (!field) throw new Error('Empty fields')
              return createOption(field)
            })
          )
          setValue('amount', data.length)
          setPrizeCodes(data)
          setAddedPrizes(true)
        } catch (err) {
          fr.abort()
          setAddedPrizes(false)
          setPrizeCodes([])
          return Alerts.Prize.CSV_IMPORT_FAILED(err)
        }
      }
    },
    [setValue]
  )

  const onResetPrizes = useCallback(() => {
    setAddedPrizes(false)
    setPrizeCodes([])
    resetField('amount')
    resetField('importedPrizes')
    resetField('voucherCode')
  }, [resetField])

  // Replaces selected key with `voucherCode`
  const setVoucherCodeField = useCallback(
    (arr, key) => {
      const prizeItems = arr.map((field) => {
        const { [key]: dummy, ...values } = field
        return { ...values, voucherCode: field[key] }
      })
      // Update form data
      setValue('importedPrizes', prizeItems)
    },
    [setValue]
  )

  return (
    <>
      <Action.Header>
        <Action.Active active={active} changed={activeChanged} />
        <Action.Right>
          <Action.More
            content={
              <>
                {active ? (
                  <Button.Primary
                    size="sm"
                    onClick={() => setValue('active', false)}
                    disabled={adding}
                    ring
                  >
                    <Icon.Archive size="sm" mr="2" />
                    Archive Prize
                  </Button.Primary>
                ) : (
                  <Button.Secondary
                    size="sm"
                    onClick={() => setValue('active', true)}
                    disabled={adding}
                    ring
                  >
                    <Icon.Archive size="sm" mr="2" />
                    Unarchive Prize
                  </Button.Secondary>
                )}

                <RBAC>
                  <Button.Warning
                    size="sm"
                    onClick={() => deletePrize(prize)}
                    disabled={adding || !canDeletePrize}
                    ring
                  >
                    <Icon.Trashcan size="sm" mr="2" />
                    Delete Prize
                  </Button.Warning>
                </RBAC>
              </>
            }
          />
        </Action.Right>
      </Action.Header>

      <Form>
        <FormCol>
          <Section>
            <Input.Label required>Who is the supplier?</Input.Label>
            <Controller
              control={control}
              name="supplier"
              render={({ field }) => (
                <Dropdown.Creatable
                  {...field}
                  placeholder="Choose or create supplier"
                  isClearable
                  menuPlacement="bottom"
                  options={supplierOptions}
                />
              )}
              rules={{ required: 'Supplier is required' }}
            />
            <Input.Error>{errors.supplier?.message}</Input.Error>
          </Section>

          <Section>
            <Input.Label required>Name the prize</Input.Label>
            <Input.Default
              {...register('name', {
                required: 'Name is required',
                maxLength: {
                  value: 30,
                  message: 'Name cannot be longer than 30 characters'
                }
              })}
              name="name"
              placeholder="Display name"
            />
            <Input.Error>{errors.name?.message}</Input.Error>
          </Section>

          <Section>
            <Input.Label>Category</Input.Label>
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <Dropdown.Creatable
                  {...field}
                  isClearable
                  closeMenuOnSelect={true}
                  options={categoryOptions}
                  placeholder="Choose or create category"
                />
              )}
              rules={{ validate: (value) => validate.category(value) }}
            />
            {errors.category && <Input.Error>{errors.category?.message}</Input.Error>}
          </Section>

          <Section>
            <Input.Label>Description</Input.Label>
            <Input.TextArea
              {...register('description')}
              placeholder="Description (optional)"
              rows="2"
            />
          </Section>

          <Section tw="flex-col sm:flex-row gap-3 flex-wrap">
            <Col tw="w-full sm:w-auto">
              <Input.Label required>Upload your prizes</Input.Label>

              <Button.Secondary
                as="label"
                htmlFor="upload"
                tw="w-full md:w-min"
                disabled={addedPrizes || !adding}
              >
                <Icon.Upload mr="2" />
                Import .csv
                <input
                  tw="hidden"
                  id="upload"
                  type="file"
                  accept=".csv"
                  onChange={onImportCsv}
                  disabled={!adding}
                />
              </Button.Secondary>

              {addedPrizes && <Input.Error tw="text-green">Looks good! 👌</Input.Error>}
              <Input.Error>{errors.amount?.message}</Input.Error>
            </Col>

            {(addedPrizes || !adding) && (
              <>
                <Col tw="w-full sm:w-28">
                  <Input.Label required>Amount</Input.Label>
                  <Input.Default disabled value={amount} />
                </Col>

                {adding ? (
                  <Col tw="flex-1">
                    <Input.Label required>Which of these is the voucher code?</Input.Label>
                    <Controller
                      control={control}
                      name="voucherCode"
                      render={({ field }) => (
                        <Dropdown.Select
                          {...field}
                          placeholder="Voucher Code"
                          options={voucherFieldOptions}
                          onChange={(e) => {
                            setVoucherCodeField(prizeCodes, e.value)
                            field.onChange(e)
                          }}
                        />
                      )}
                      rules={{ required: 'This is required', shouldUnregister: true }}
                    />
                    <Input.Error>{errors.voucherCode?.message}</Input.Error>
                  </Col>
                ) : (
                  <Info>{'To add more prizes, you need to create a new digital prize!'}</Info>
                )}
              </>
            )}
          </Section>

          {addedPrizes && (
            <Section tw="gap-1.5 mt-0">
              <PrizeList>
                {prizeCodes.map((prize, i) => (
                  <PrizeInstance key={i}>
                    <Col tw="truncate gap-1">
                      {Object.keys(prize).map((key, i) => (
                        <Row key={i}>
                          <Item.Bold>{key}:</Item.Bold>
                          <Item.Text>{prize[key].toString()}</Item.Text>
                        </Row>
                      ))}
                    </Col>
                  </PrizeInstance>
                ))}
              </PrizeList>

              <Button.Warning size="sm" tw="ml-auto" onClick={onResetPrizes}>
                <Icon.Trashcan size="sm" mr="2" /> Remove Prizes
              </Button.Warning>
            </Section>
          )}

          <Section tw="flex-col md:flex-row">
            <Col tw="flex-1 truncate">
              <Input.Label>Do you want to include market prize?</Input.Label>
              <Input.Default
                placeholder="Value per prize (optional)"
                {...register('marketValue.value', {
                  validate: (value) => validate.prize.marketValue(value)
                })}
              />
              <Input.Error>{errors.marketValue?.value?.message}</Input.Error>
            </Col>

            {marketValue && (
              <Col tw="min-width[15vw] my-1 md:m-0 md:ml-2">
                <Input.Label required>Which currency is this?</Input.Label>
                <Controller
                  control={control}
                  name="marketValue.currency"
                  render={({ field }) => (
                    <Dropdown.Select
                      {...field}
                      placeholder="Currency"
                      menuPlacement="bottom"
                      options={PRIZE_CURRENCY_OPTIONS}
                    />
                  )}
                  rules={{ required: 'Currency is required' }}
                />
                <Input.Error>{errors.marketValue?.currency?.message}</Input.Error>
              </Col>
            )}
          </Section>

          <Section>
            <Input.Label>Add tags</Input.Label>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <Dropdown.Creatable
                  {...field}
                  closeMenuOnSelect={false}
                  isMulti
                  options={tagOptions}
                  placeholder="Tags"
                />
              )}
            />
          </Section>

          <Section>
            <Input.Label>Any extra info? (only for us)</Input.Label>
            <Input.Default
              {...register('extra')}
              placeholder="Metadata (order number, expense cost, etc.)"
            />
          </Section>

          <Section>
            <Input.Label>Do you want to add an expiration date?</Input.Label>
            <Controller
              control={control}
              name={'validTo'}
              render={({ field: { onChange, value } }) => (
                <PrizeDateRange validDate={value} setValidDate={onChange} />
              )}
            />
            <Input.Error>{errors.validDate?.message}</Input.Error>
          </Section>
        </FormCol>

        <ImageCol>
          <Section>
            <Input.Label required>Upload an image</Input.Label>
            <Controller
              control={control}
              name="imageUrl"
              render={({ field: { value, onChange } }) => (
                <ImageModal
                  OpenButton={(props) => (
                    <ImageWrapper>
                      <Image src={imageUrl ?? config.prize.DEFAULT_PRIZE_IMAGE} />
                      <Button.Secondary {...props} tw="w-min">
                        <Icon.Image mr="2" />
                        {value ? 'Change Image' : 'Add Image'}
                      </Button.Secondary>
                    </ImageWrapper>
                  )}
                  flagUpload={flagUpload}
                  imageUrl={value}
                  setImageUrl={onChange}
                  title="Prize Image"
                  tw="w-4/5 h-4/5"
                />
              )}
              rules={{ required: 'Image is required' }}
            />
            <Input.Error tw="self-center">{errors?.imageUrl?.message}</Input.Error>
          </Section>
        </ImageCol>
      </Form>
    </>
  )
}

const Form = tw.form`flex flex-col md:flex-row flex-1 w-full p-4 gap-2 shadow-md`
const Col = tw.div`flex flex-col`
const Row = tw.div`flex gap-2`
const FormCol = tw(Col)`w-full md:w-2/3`
const ImageCol = tw(Col)`w-full md:w-1/3`
const Section = tw.div`flex flex-col my-2 px-4 w-full`
const Image = tw.img`rounded-md shadow-md`
const ImageWrapper = tw.div`flex flex-col items-center gap-2`
const PrizeList = tw.div`flex flex-col max-h-96 overflow-y-scroll p-4 shadow-inner bg-sky-200 gap-3`
const PrizeInstance = tw.div`flex bg-white-pure p-2 shadow-md text-sm rounded-sm`
