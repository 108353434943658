import alert from 'sweetalert2'
import { theme } from 'twin.macro'
import config from '../config'

export const Toast = alert.mixin({
  position: 'bottom-left',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  toast: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', alert.stopTimer)
    toast.addEventListener('mouseleave', alert.resumeTimer)
  }
})
const swal = alert.mixin({
  confirmButtonColor: theme`colors.bismark.400`,
  cancelButtonColor: theme`colors.matrix.400`
})

export const Alerts = {
  Area: {
    ACTIVATE_COUNTRY: (name) =>
      swal.fire({
        title: `Activating ${name}`,
        text: `Do you want to activate all cities in ${name} as well?`,
        icon: 'info',
        confirmButtonText: 'Yes, activate everything!',
        cancelButtonText: `Activate only ${name}`,
        cancelButtonColor: theme`colors.norway`,
        focusCancel: true,
        showCancelButton: true,
        showCloseButton: true
      }),
    DEACTIVATE_FAILED: () =>
      swal.fire({
        title: 'No can do!',
        text: "We can't activate cities when the country is inactive",
        icon: 'warning',
        confirmButtonText: 'Ah, ok!'
      }),
    DEACTIVATE_COUNTRY: (name) =>
      swal.fire({
        title: `Deactivate ${name}?`,
        text: `This will also deactivate all cities in ${name} and prevent all hvnts and checkpoints from being played`,
        icon: 'info',
        confirmButtonText: 'Yes, please!',
        showCancelButton: true
      }),
    DEACTIVATE_CITY: (name) =>
      swal.fire({
        title: `Deactivate ${name}?`,
        text: 'Hvnts and checkpoints in this city will not be playable',
        icon: 'info',
        confirmButtonText: 'Yes, please!',
        showCancelButton: true
      }),
    DELETE_COUNTRY: (name) =>
      swal.fire({
        title: `Delete ${name}?`,
        text: 'This will remove the country completely',
        icon: 'warning',
        confirmButtonColor: '#FD8888',
        confirmButtonText: "I know what I'm doing!",
        showCancelButton: true
      }),
    DELETE_CITY: (name) =>
      swal.fire({
        title: `Delete ${name}?`,
        text: 'This will remove the city completely',
        icon: 'warning',
        confirmButtonColor: '#FD8888',
        confirmButtonText: "I know what I'm doing!",
        showCancelButton: true
      })
  },
  Candidate: {
    DONE_REVIEWING: () =>
      swal.fire({
        title: 'All done!',
        text: 'Your new checkpoints have been added!',
        icon: 'success',
        confirmButtonText: 'Nice!'
      }),
    FETCH: () =>
      swal.fire({
        title: 'Fetching candidates',
        input: 'select',
        inputOptions: { 5: 5, 10: 10, 20: 20, 50: 50 },
        inputPlaceholder: 'How many do you want to review?',
        showCancelButton: true,
        confirmButtonColor: 'Fetch'
      }),
    CONFIRM_DELETE: () =>
      swal.fire({
        title: 'Reject Candidate?',
        text: 'This location will not show up again',
        icon: 'question',
        confirmButtonText: 'Yes, please!',
        showCancelButton: true
      }),
    DELETE_FAILED: (err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to reject the checkpoint! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    NEXT_CANDIDATE: (remaining) =>
      swal.fire({
        title: `${remaining} candidates remaining`,
        icon: 'success',
        confirmButtonText: 'Next one!',
        cancelButtonText: 'Skip the rest',
        showCancelButton: true
      }),
    REVIEW_FAILED: (err, more) =>
      swal.fire({
        title: 'Hmm!',
        html: `Failed to review this candidate! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: more ? 'Stop Reviewing' : 'Oh, ok',
        showCancelButton: more,
        cancelButtonText: 'Try next candidate!',
        cancelButtonColor: theme`colors.norway`
      })
  },
  CandidateCell: {
    UPDATE_FAILED: (err) =>
      swal.fire({
        title: 'Hmm!',
        html: `Failed to update this cell. <br> <br> 
      <strong><i>${err.message}</i></strong><br><br>
      Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      })
  },
  Challenge: {
    DELETE_WARNING: () =>
      swal.fire({
        title: 'Delete challenge?',
        text: 'This will remove the challenge completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing",
        cancelButtonText: 'No, wait!'
      }),
    DELETE_SUCCESS: () =>
      swal.fire({
        title: 'Challenge deleted!',
        text: 'Poof!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      }),
    SAVE_SUCCESS: (adding) =>
      swal.fire({
        title: `Challenge ${adding ? 'Added!' : 'Updated!'}`,
        icon: 'success',
        confirmButtonText: 'Nice!'
      }),
    SAVE_FAILED: (adding, err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to ${adding ? 'add' : 'update'} the challenge! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      })
  },
  Checkpoint: {
    ACTIVATE_CONFIRM: () =>
      swal.fire({
        title: 'Activating checkpoint',
        html: `Do you want to activate any clues as well?
      <br><br><br>Also, don't forget to save the checkpoint afterwards! ✅`,
        icon: 'info',
        confirmButtonColor: theme`colors.norway`,
        confirmButtonText: 'Activate clues also',
        cancelButtonText: 'Activate only checkpoint',
        cancelButtonColor: theme`colors.bismark`,
        focusCancel: true,
        showCancelButton: true,
        showCloseButton: true
      }),
    DEEP_LINK_CHANGE_CITY: (city) =>
      swal.fire({
        title: "Hmm, couldn't find checkpoint",
        html: `
        <style>
        #container {background: #EFEFEF; padding: 1rem; margin: 0.5rem 0; text-align: left; border-radius: 0.5rem} 
        </style>
      ${city ? `This checkpoint probably exists in another city than ${city}.<br><br>` : ''}
      Due to how we organize checkpoints, I can't really display this checkpoint if it doesn't belong to your selected city <br>
      (for now at least) <br><br>
      If you want to try again: <br>
      <div id="container">
      <li>Close this tab </li>
      <li>Select the city this checkpoint belongs to </li> 
      <li>Click the link again</li>
      </div>
      `,
        icon: 'info',
        confirmButtonText: 'Ok!'
      }),
    UNSAVED_CHANGES_EDITING: () =>
      swal.fire({
        title: 'Unsaved Changes',
        html: `You are in the middle of creating a checkpoint. <br>
      Are you sure you want to close?`,
        icon: 'question',
        confirmButtonText: 'Yes, please!',
        showCancelButton: true
      }),
    DEACTIVATE_INFO: () =>
      swal.fire({
        title: 'Deactivate Checkpoint?',
        html: `The checkpoint <b>and its clues</b> will become invisible and unplayable. 
        <br><br>Don't forget to save the checkpoint afterwards! ✅`,
        icon: 'info',
        confirmButtonText: 'Sounds good!',
        showCancelButton: true
      }),
    DELETE_NOT_POSSIBLE: () =>
      swal.fire({
        title: 'No can do! 😓',
        text: "We can't activate checkpoints within an inactive city",
        icon: 'warning',
        confirmButtonText: 'Ahh, ok!',
        showCancelButton: true
      }),
    DELETE_WARNING: () =>
      swal.fire({
        title: 'Delete checkpoint?',
        text: 'This will remove the checkpoint completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        cancelButtonText: 'Wait, no!',
        confirmButtonText: "I know what I'm doing"
      }),
    GENERATE_CLUES_FAILED: (errors) =>
      swal.fire({
        title: 'Automatic Clues',
        html: `Some clues couldn't be automatically generated for this checkpoint <br><br>
        <strong><i>${errors.join('<br>')}</i></strong>
        `,
        icon: 'info',
        confirmButtonText: 'OK'
      }),
    UPDATE_FAILED: (adding, err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to ${adding ? 'add' : 'update'} the checkpoint! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      })
  },
  Clue: {
    DEACTIVATE_ALL: ({ name }) =>
      swal.fire({
        title: 'Checkpoint is active',
        html: `The checkpoint ${name} is still active, and will be unplayable without any clues <br><br>
      The checkpoint will therefore be deactivated as well`,
        icon: 'warning',
        confirmButtonText: 'Sure, deactivate everything!',
        focusCancel: true,
        showCancelButton: true
      }),
    DEACTIVATE_CONFIRM: () =>
      swal.fire({
        title: 'Deactivate clue?',
        html: `This clue will not show up for this Checkpoint<br><br>
        Don't forget to save the clue afterwards! ✅`,
        icon: 'info',
        confirmButtonText: 'Yes, please!',
        showCancelButton: true
      }),
    DELETE_CONFIRM: () =>
      swal.fire({
        title: 'Delete clue?',
        text: 'This will remove the clue completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing",
        cancelButtonText: 'No, wait!'
      }),
    DISPLAY_IMAGE: ({ difficulty, imageData, imageText, imageUrl, rotated, type }) =>
      swal.fire({
        title: type?.label ?? 'Image Clue',
        imageUrl: imageUrl ?? imageData,
        imageAlt: 'Image Clue',
        html: `
      ${difficulty ? `<span>Difficulty: ${difficulty?.value}</span><br><br>` : ''}
      ${rotated ? '<span>North Rotated</span><br>' : ''}
      ${imageText ? `<span><i>"${imageText}"</i></span>` : ''}`,
        width: '66%',
        confirmButtonText: 'Nice'
      }),
    FEW_CLUES: () =>
      swal.fire({
        title: 'Few clues detected!',
        text: 'You have very few clues. Are you sure you still want to save this checkpoint?',
        icon: 'warning',
        confirmButtonText: "Yes I'm sure",
        showCancelButton: true
      }),
    GENERATED_CLUES: (clues) =>
      swal.fire({
        title: `Generated ${clues.length} clues 🥳`,
        text: 'Go through them one by one and approve or discard them as you wish!',
        icon: 'success',
        confirmButtonText: 'Gotcha!'
      }),
    GENERATE_FAILED: (err) =>
      swal.fire({
        title: "Didn't manage to generate any clues",
        html: `Something went wrong<br><br>
      <strong><i>${err.message}</i></strong><br><br>
      Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Ok'
      }),
    MISSING_CLUES: () =>
      swal.fire({
        title: 'You have no clues!',
        text: 'Please add some clues before submitting this checkpoint!',
        icon: 'error',
        confirmButtonText: 'Ahh, my bad!'
      }),
    MISSING_FREE_CLUE: () =>
      swal.fire({
        title: 'Missing free clue!',
        text: 'Please make sure that at least one of your clues is marked as "free"',
        icon: 'error',
        confirmButtonText: 'Got it!'
      }),
    REJECT_CANDIDATES: () =>
      swal.fire({
        title: 'Are you sure?',
        text: 'This will clear all generated clues',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure",
        showCancelButton: true
      }),
    SAVE_FAILED: (err) =>
      swal.fire({
        title: 'Failed to save clue',
        html: `Something went wrong<br><br>
      <strong><i>${err.message}</i></strong><br><br>
      Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
  },
  Event: {
    ACTIVATE_INFO: () =>
      swal.fire({
        title: 'Creating Event! 🥳',
        html: `The event will now automatically become LIVE on the specified start date <br><br>
       Until then it is UPCOMING and invisible. <br><br>
       You shouldn't have to edit anything at this point 🧐<br> 
       but if you do, you can still change values until the season becomes LIVE.
    `,
        icon: 'success',
        confirmButtonText: "Sweet, let's go!",
        cancelButtonText: 'Abort!',
        showCancelButton: true,
        width: '700px'
      }),
    DEACTIVATE_UPCOMING_INFO: () =>
      swal.fire({
        title: 'Deactivate Event? 🤨',
        html: `This will turn it back into a draft! <br><br> 
        Anyone currently playing should not be affected, but no new user can play this event!`,
        icon: 'question',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing!",
        cancelButtonText: 'Nevermind!'
      }),
    MISSING_LOCATIONS: () =>
      swal.fire({
        title: 'Missing Locations',
        html: "You're missing the minimum required locations for this event",
        icon: 'error',
        confirmButtonText: "I'll add them!"
      }),

    SAVE_SUCCESS: (adding) =>
      swal.fire({
        title: `Event ${adding ? 'Added!' : 'Updated!'}`,
        icon: 'success',
        confirmButtonText: 'Nice!'
      }),
    SAVE_FAILED: (adding, err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to ${adding ? 'add' : 'update'} the event! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    DELETE_WARNING: () =>
      swal.fire({
        title: 'Delete Event?',
        text: 'This will remove the event completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing",
        cancelButtonText: 'No, wait!'
      }),
    DELETE_SUCCESS: () =>
      swal.fire({
        title: 'Event deleted!',
        text: 'Poof!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })
  },
  General: {
    ERROR: (err) =>
      swal.fire({
        title: 'Something went wrong! 😔',
        html: `<strong><i>${err.message ? err.message : JSON.stringify(err)}</i></strong>`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      })
  },
  Hvnt: {
    ACTIVATE_FAILED: () =>
      swal.fire({
        title: 'No can do!',
        text: "We can't activate a hvnt when the city is inactive",
        icon: 'warning',
        confirmButtonText: 'Aha, ok!',
        showCancelButton: true
      }),
    ACTIVATE_INFO: () =>
      swal.fire({
        title: 'Going Live! 🥳',
        html: `
        <style>
        div {display: flex;} 
        #info {flex-direction: column; justify-content: start; gap: 3; background: #DEDEDE; padding: 1rem; margin: 0.5rem 0;} 
        #strong {display: flex; align-items: center; margin: 0.5rem 0;} 
        #row { align-items: center; margin: 0.3rem 0;} 
        #boldrow { align-items: center; margin: 0.5rem 0; font-size: 1.5rem; margin-top: 1.2rem; font-weight: 800; } 
        #em {font-size: 1.5rem; margin-right: 1rem;}
        </style>
        When activating a hvnt a few things will happen:
        <br><br>
        <div id="info">
          <div id="row"><div id="em">👀</div><div>The hvnt will become visible for users in the hvnt list!</div></div>
          <div id="row"><div id="em">🔔</div><div>Until the hvnt starts, it will be visible under the "Upcoming" category</div></div>
          <div id="boldrow">As soon as the hvnt starts:</div>
          <div id="row"><div id="em">🎁</div><div>The prize will become locked and &nbsp;<b>can not be changed!</b></div></div>
          <div id="row"><div id="em">📆</div><div>The start date can no longer be changed!</div></div>
        </div>
        <br>
        You can still edit other values as usual, even when it's live!
        `,
        icon: 'success',
        confirmButtonText: "Sweet, let's go!",
        cancelButtonText: 'Abort!',
        showCancelButton: true,
        width: '700px'
      }),
    DEACTIVATE_LIVE_INFO: () =>
      swal.fire({
        title: 'Deactivate live hvnt? 😬',
        html: `
        <style>
        div {display: flex;} 
        #info {flex-direction: column; justify-content: start; gap: 3; background: #DEDEDE; padding: 2rem; margin: 0.5rem 0;} 
        #strong {display: flex; align-items: center; margin: 0.5rem 0;} 
        #row { align-items: center; margin: 0.5rem 0;} 
        #em {font-size: 2rem; margin-right: 1rem;}
        </style>
        This hvnt has started! If you deactivate it now, a few things will happen:<br><br>
        <div id="info">
          <div id="row"><div id="em">📝</div><div>The hvnt will turn into a draft!</div></div>
          <div id="row"><div id="em">🕶</div><div>The hvnt will become hidden for users in the hvnt list!</div></div>
          <div id="row"><div id="em">📆</div><div>Players who have started already will be able to finish!</div></div>
        </div>
        <br><br>
        `,
        icon: 'warning',
        confirmButtonText: 'Yes, please!',
        cancelButtonText: 'Abort!',
        showCancelButton: true,
        width: '700px'
      }),
    DEACTIVATE_UPCOMING_INFO: () =>
      swal.fire({
        title: 'Deactivate hvnt? 🤨',
        html: 'This will remove the hvnt from the hvnt list and turn it back into a draft!',
        icon: 'question',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Yes, please!',
        cancelButtonText: 'Nevermind!'
      }),
    DELETE_WARNING: () =>
      swal.fire({
        title: 'Delete hvnt?',
        text: 'This will remove the hvnt completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing",
        cancelButtonText: 'No, wait!'
      }),
    DELETE_NOT_POSSIBLE: () =>
      swal.fire({
        title: 'Hvnt has started',
        text: 'For safety reasons, in case any users have started playing, the hvnt will be deactivated instead of removed',
        icon: 'info',
        confirmButtonText: "That's fine"
      }),
    DELETE_SUCCESS: () =>
      swal.fire({
        title: 'Hvnt deleted!',
        text: 'Poof!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      }),
    DEEP_LINK_CHANGE_CITY: (city) =>
      swal.fire({
        title: "Hmm, couldn't find hvnt",
        html: `
        <style>
        #container {background: #EFEFEF; padding: 1rem; margin: 0.5rem 0; text-align: left; border-radius: 0.5rem} 
        </style>
      ${city ? `This hvnt probably exists in another city than ${city}.<br><br>` : ''}
      Due to how we organize hvnts, I can't really display this hvnt if it doesn't belong to your selected city <br>
      (for now at least) <br><br>
      If you want to try again: <br>
      <div id="container">
      <li>Close this tab </li>
      <li>Select the city this hvnt belongs to </li> 
      <li>Click the link again</li>
      </div>
      `,
        icon: 'info',
        confirmButtonText: 'Ok!'
      }),
    UPDATE_FAILED: (adding, err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to ${adding ? 'add' : 'save'} the hvnt! <br> <br> 
        <strong><i>${err.message}</i></strong>`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    UPDATE_SUCCESS: (celebrate = false) =>
      swal.fire({
        title: `${celebrate ? '🙌 Hvnt is Live! 🙌' : 'Hvnt saved!'}`,
        icon: 'success',
        confirmButtonText: 'Nice!'
      }),
    VISIT_PRIZE_LIST: () =>
      swal.fire({
        title: 'Visit Prize List?',
        text: 'Your current hvnt and its values will be cleared',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: "That's fine!",
        cancelButtonText: 'Go back'
      })
  },
  Image: {
    POPUP: (imageUrl) =>
      swal.fire({
        imageUrl: imageUrl,
        imageAlt: 'Image Clue',
        backdrop: theme`colors.black`,
        confirmButtonText: 'Nice'
      }),
    TOO_BIG: (file) =>
      swal.fire({
        title: 'This is too big!',
        text: `The image is ${
          Math.round(file.size / 10000) / 100
        } MB and exceeds the allowable limit of ${config.MAX_IMAGE_UPLOAD_SIZE / 1000000} MB`,
        icon: 'warning',
        confirmButtonText: 'Darn it'
      }),
    LOW_RESOLUTION: (width, height) =>
      swal.fire({
        title: 'Resolution too low!',
        html: `The resolution of this image is way too low<br> 
        Please upload a higher resolution image! <br>
        Yours was ${width} x ${height} :)
        `,
        icon: 'warning',
        confirmButtonText: "I'll do that"
      }),
    UPLOAD_FAILED: (err) =>
      swal.fire({
        title: 'Failed to upload image',
        html: `Most likely the image is too big to be saved on Cloudinary
    <br>Try with a smaller image maybe, or contact Dennis
    <br><br>${err.message}`,
        icon: 'error',
        confirmButtonText: 'Dang it'
      })
  },
  InfoScreen: {
    DELETE_WARNING: () =>
      swal.fire({
        title: 'Delete Screen?',
        text: 'This will remove the screen completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing",
        cancelButtonText: 'No, wait!'
      }),
    LIVE_CANCEL: () =>
      swal.fire({
        title: 'Deactivate screen?',
        html: 'This will un-schedule the screen and turn it back into a draft',
        icon: 'question',
        confirmButtonText: 'Yes, please!',
        cancelButtonText: 'No, wait!',
        showCancelButton: true
      }),
    LIVE_INFO: () =>
      swal.fire({
        title: 'Scheduling Info Screen! 📱',
        html: `The screen will show in the app from the specified start date <br><br>
        You shouldn't have to edit anything at this point 🧐<br> 
        but if you do, you can still change values until it goes LIVE.
  `,
        icon: 'success',
        confirmButtonText: "Sweet, let's go!",
        cancelButtonText: 'Abort!',
        showCancelButton: true,
        width: '700px'
      }),
    UPDATE_FAILED: (adding, err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to ${adding ? 'add' : 'update'} the info screen! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    SAVE_SUCCESS: (adding) =>
      swal.fire({
        title: `Info Screen ${adding ? 'Added!' : 'Updated!'}`,
        icon: 'success',
        confirmButtonText: 'Nice!'
      })
  },
  Integrity: {
    CONTENT_CHANGED: () =>
      swal.fire({
        title: 'Content changed!',
        html: `This content was edited by someone else
        <br><br> Do you want to ignore this and keep your changes?
        `,
        icon: 'info',
        confirmButtonText: "Don't update",
        cancelButtonText: 'Sure, update',
        focusCancel: true,
        showCancelButton: true,
        cancelButtonColor: theme`colors.bismark.400`,
        confirmButtonColor: theme`colors.matrix.400`
      })
  },
  Location: {
    SAVE_SUCCESS: (adding) =>
      swal.fire({
        title: `Location ${adding ? 'Added!' : 'Updated!'}`,
        icon: 'success',
        confirmButtonText: 'Nice!'
      }),
    SAVE_FAILED: (adding, err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to ${adding ? 'add' : 'update'} the location! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    DELETE_NOT_POSSIBLE: () =>
      swal.fire({
        title: 'Event has started',
        text: 'For safety reasons, for any users that have started playing, the location will be deactivated instead of removed',
        icon: 'info',
        confirmButtonText: "That's fine"
      }),
    CONFIRM_DELETE: () =>
      swal.fire({
        title: 'Remove location?',
        text: 'Do you want to remove this location from the event?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes please!',
        cancelButtonText: 'No, wait'
      })
  },
  Locations: {
    CREATED: (arr) =>
      swal.fire({
        icon: 'success',
        title: 'Checkpoints Generated!',
        text: `${arr.length} new locations were added `,
        confirmButtonText: 'Nice!'
      }),
    GENERATE_FAILED: (err) =>
      swal.fire({
        title: 'Something went wrong!',
        html: `Failed to generate locations <br><br>
    <strong><i>${err.message}</i></strong><br><br>
    Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    TOO_MANY_CELLS: (max) =>
      swal.fire({
        title: 'Too many cells!',
        html: `We have a limit of ${max} cells per request <br><br>
    This is to make sure the backend has resources to process all the information<br><br>
    Please reduce the number of cells and try again`,
        icon: 'error',
        confirmButtonText: "Ok, I'll do that!"
      }),
    QUERY_FAILED: (err) =>
      swal.fire({
        title: 'Something went wrong!',
        html: `Failed to query locations <br><br>
    <strong><i>${err.message}</i></strong><br><br>
    Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    CHECKPOINTS_REQUESTED: (id) =>
      swal.fire({
        icon: 'success',
        title: 'Checkpoints Requested!',
        html: `Waiting for checkpoints to be generated<br>
        requestId: <b>${id}</b> <br><br>
        Do you want to visit the Checkpoints page?`,
        confirmButtonText: 'Yes, I do!',
        cancelButtonText: 'Not yet',
        showCancelButton: true
      }),
    CONFIRM_CHECKPOINT_REQUEST: ({ cells, city, limit }) =>
      swal.fire({
        icon: 'question',
        title: `Request Checkpoints in ${city}?`,
        html: `This will create checkpoints from up to ${limit} locations inside the ${cells} selected cells <br><br>
        When they are created, they will show up under <b>Checkpoints > Candidates</b> in ${city}<br>
        where you can review them. <br><br>
        Note also that it might take a minute or so for them to show up 🥳`,
        showCancelButton: true,
        confirmButtonText: 'Sounds Good!',
        cancelButtonText: 'No, wait!'
      }),
    CONFIRM_LOCATION_REQUEST: ({ cells, country, limit }) =>
      swal.fire({
        icon: 'info',
        title: `Fetching Locations in ${country}`,
        html: `This will request and save up to ${limit} locations in all of the ${cells} selected cells <br><br>
        Note that this will only fetch locations and the ${cells} cells will be managed in <b>${country}</b>! <br><br>
        <b>No</b> checkpoints will be generated`,
        showCancelButton: true,
        confirmButtonText: "Let's go!",
        cancelButtonText: 'No, wait!'
      }),
    LOCATIONS_REQUESTED: (id) =>
      swal.fire({
        icon: 'success',
        title: 'Locations Requested!',
        html: `The locations are now being generated and will be stored in Mercator<br><br>
      requestId: <b>${id}</b>`,
        confirmButtonText: 'Nice!',
        showCancelButton: false
      }),
    SAVED: (arr) =>
      swal.fire({
        icon: 'success',
        title: 'Locations Saved!',
        text: `${arr.length} new locations were added `,
        confirmButtonText: 'Nice!'
      })
  },
  Participations: {
    CANCEL_WARNING: () =>
      swal.fire({
        icon: 'warning',
        title: 'Cancel Participation?',
        text: "This action is irreversible, and the user's participation will end immediately",
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: "I know what I'm doing!",
        cancelButtonText: 'No, wait!'
      })
  },
  Prize: {
    CONFIRM_DELETE: () =>
      swal.fire({
        title: 'Delete prizes?',
        text: 'This will remove the prize completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing",
        cancelButtonText: 'No, wait!'
      }),
    CONFIRM_ARCHIVE: () =>
      swal.fire({
        icon: 'question',
        title: 'Archive Prize?',
        html: `This will add the item to the archived list
        and you will not be able to use it for reservations any more`,
        focusCancel: true,
        confirmButtonText: 'Yes, archive!',
        cancelButtonText: 'No, wait!',
        showCancelButton: true
      }),
    CONFIRM_UNARCHIVE: (item) =>
      swal.fire({
        icon: 'question',
        title: 'Unarchive Prize?',
        html: `This will put the item back into the ${item.deliveryType} prize list <br>
        and make it available for reservation `,
        focusCancel: true,
        confirmButtonText: 'Yes, please!',
        cancelButtonText: 'No, wait!',
        showCancelButton: true
      }),
    DELETE_SUCCESS: () =>
      swal.fire({
        title: 'Prize Deleted!',
        text: 'Poof!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1200
      }),
    ARCHIVE_NOT_POSSIBLE: (item) =>
      swal.fire({
        icon: 'warning',
        title: 'Active Reservations',
        html: `There are ${item.reserved} prizes that are still reserved for a competition.<br>
        Please mark the remaining prizes as claimed before archiving the prize.
        `
      }),
    UPDATE_FAILED: (err) =>
      swal.fire({
        title: 'Something went wrong!',
        html: `Failed to update the prize properly <br><br>
    <strong><i>${err.message}</i></strong><br><br>
    Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    UPDATE_SUCCESS: (adding = false) =>
      swal.fire({
        title: `Prize ${adding ? 'Added!' : 'Updated!'}`,
        icon: 'success',
        confirmButtonText: 'Nice!'
      }),
    CSV_IMPORT_FAILED: (err) =>
      swal.fire({
        title: 'Failed to read file',
        html: `
    <style>
      #csv {display: flex; flex-direction: column; align-items: center; background: #DEDEDE; padding: 0.5rem; margin: 0.5rem 0;} 
      #row {display: flex; justify-content: space-between; margin: 5px 0; text-align: left} 
    </style>
    Make sure the CSV has <br>
    <b>one header row</b>, with comma separated fields, <br>
    followed by <br>
    <b>one row for each prize</b> with comma seperated fields<br><br>

    <div id="csv">
      <div id="row"><b>Field1, Field2</b></div>
      <div id="row">123, Banana</div>
      <div id="row">234, Potato</div>
      <div id="row">345, Pastrami</div>
    </div>

    <b>Parser says:</b><br>
    <i>${err}</i>
    `,
        icon: 'error',
        confirmButtonText: 'I will check my file!'
      })
  },
  Route: {
    NO_ROUTES: () =>
      swal.fire({
        title: 'No checkpoints here!',
        icon: 'warning',
        text: 'Move the hvnt area to where you have checkpoints!'
      })
  },
  Season: {
    ACTIVATE_INFO: () =>
      swal.fire({
        title: 'Creating Season! 🥳',
        html: `The season will now automatically become LIVE on the specified start date <br><br>
         Until then it is UPCOMING and invisible. <br><br>
         You shouldn't have to edit anything at this point 🧐<br> 
         but if you do, you can still change values until the season becomes LIVE.
      `,
        icon: 'success',
        confirmButtonText: "Sweet, let's go!",
        cancelButtonText: 'Abort!',
        showCancelButton: true,
        width: '700px'
      }),
    DEACTIVATE_UPCOMING_INFO: () =>
      swal.fire({
        title: 'Deactivate season? 🤨',
        html: `This will turn it back into a draft! <br><br> 
        Please make sure that we have an upcoming season ready for when the current season ends!`,
        icon: 'question',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Yes, please!',
        cancelButtonText: 'Nevermind!'
      }),
    DELETE_WARNING: () =>
      swal.fire({
        title: 'Delete season?',
        text: 'This will remove the season completely and is not reversible',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "I know what I'm doing",
        cancelButtonText: 'No, wait!'
      }),
    DELETE_NOT_POSSIBLE: () =>
      swal.fire({
        title: 'Season has started',
        text: 'For safety reasons, for any users that have started playing, the season will be deactivated instead of removed',
        icon: 'info',
        confirmButtonText: "That's fine"
      }),
    DELETE_SUCCESS: () =>
      swal.fire({
        title: 'Season deleted!',
        text: 'Poof!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      }),
    SAVE_FAILED: (adding, err) =>
      swal.fire({
        title: 'Uh oh!',
        html: `Failed to ${adding ? 'add' : 'update'} the season! <br> <br> 
        <strong><i>${err.message}</i></strong><br><br>
        Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      }),
    SAVE_SUCCESS: (adding) =>
      swal.fire({
        title: `Season ${adding ? 'Added!' : 'Updated!'}`,
        icon: 'success',
        confirmButtonText: 'Nice!'
      })
  },
  Support: {
    DEEP_LINK_FAILED: () => {
      swal.fire({
        title: 'Uh oh!',
        html: "This conversation doesn't exist!",
        icon: 'error',
        confirmButtonText: 'Hmm, ok!'
      })
    }
  },
  User: {
    DISABLE_USER: () =>
      swal.fire({
        title: 'Disable user? 🤨',
        html: `The user will not be able to sign in to the app anymore! <br><br> 
      Their data will not be removed, and they can be enabled again at any time, but they might be automatically signed out from the app.`,
        icon: 'question',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Yes, please!',
        cancelButtonText: 'Nevermind!'
      }),
    ADJUST_BALANCE: ({ amount, reward }) =>
      swal.fire({
        title: `${reward ? 'Reward coins 🤑' : 'Withdraw coins 🥺'}`,
        text: `Are you sure you want to ${
          reward ? `reward this user ${amount} coins?` : `withdraw ${amount} coins from this user?`
        } `,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, please',
        cancelButtonText: 'Nevermind!'
      }),
    ADJUST_XP: ({ amount, reward }) =>
      swal.fire({
        title: `${reward ? 'Reward xp? 🤑' : 'Remove xp? 🥺'}`,
        text: `Are you sure you want to ${
          reward ? `give ${amount} xp to this user?` : `remove ${amount} xp from this user?`
        } `,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, please',
        cancelButtonText: 'Nevermind!'
      }),
    REVERSE_TRANSACTION: () =>
      swal.fire({
        title: 'Reverse Transaction',
        text: 'Are you sure you want to reverse this transaction?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, please',
        cancelButtonText: 'No, wait!'
      }),
    REMOVE_EMAIL: () =>
      swal.fire({
        title: "Can't remove email",
        html: 'You can enter a different email, but unfortunately not remove it completely',
        icon: 'error',
        confirmButtonText: 'Oh, ok!'
      }),
    ADJUST_FAILED: (err) =>
      swal.fire({
        title: 'Something went wrong! 😔',
        html: `Failed to update balance <br><br>
  <strong><i>${err.message}</i></strong><br><br>
  Sorry about that`,
        icon: 'error',
        confirmButtonText: 'Dang it!'
      })
  },
  Auth: {
    LOGOUT_USER: () =>
      swal.fire({
        title: 'Account information updated!',
        html: `And since you updated your authentication information, <br>
        we will ask you to please sign in again before proceeding further`,
        icon: 'success',
        confirmButtonText: "That's fine!"
      }),
    SIGNOUT_WARING: () =>
      swal.fire({
        title: 'Sign Out',
        text: 'Are you sure you want to sign out?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, please!',
        cancelButtonText: 'Wait, not yet'
      })
  }
}
