import React, { useCallback, useState } from 'react'
import tw from 'twin.macro'
import { useClue } from '../../contexts/ClueContext'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { ClueList } from './ClueList'
import { ClueForm } from './ClueForm'
import { createClueFromType } from '../../utility/generateClues'
import { useIsMounted } from '../../hooks/useIsMounted'
import { CandidateClueList } from './Candidate/CandidateClueList'
import { Alerts, Toast } from '../../utility/alerts'
import { actionTypes } from '../../reducers/clueReducer'
import { ClueHelper } from './Candidate/ClueHelperList'
import { db } from '../../lib/firebase'
import { ClueType } from '../../enums'
import { getOption } from '../../utility/helperFunctions'
import { DIFFICULTY_OPTIONS, TREASURE_ZOOM_OPTIONS } from '../../utility/labeledOptions'

export const ManageClues = ({ checkpoint }) => {
  const { acceptClue, adding, clueBeingEdited, clues, dispatch, saveClue } = useClue()
  const { adding: addingCheckpoint, setSubmitDisabled } = useCheckpoint()
  const { mounted } = useIsMounted()
  const [loading, setLoading] = useState(false)
  const showCandidates = !!clues?.candidates?.length

  const onSaveClue = useCallback(
    async (clue) => {
      if (clue.candidate) await mounted(acceptClue(clue))
      else await mounted(saveClue(clue))

      Toast.fire({
        title: adding ? (clue.candidate ? 'Clue Accepted' : 'Clue Added') : 'Clue Saved!',
        icon: 'success'
      })
    },
    [acceptClue, adding, mounted, saveClue]
  )

  // Activate all clues
  const onActivateAllClues = useCallback(async (clues) => {
    try {
      setLoading(true)
      const batch = db.batch()

      clues.forEach((clue) => {
        const clueRef = db.doc(clue.path)
        batch.update(clueRef, { active: true })
      })
      await batch.commit()
    } catch (err) {
      Alerts.Clue.SAVE_FAILED(err)
    } finally {
      setLoading(false)
    }
  }, [])

  // Deactivate all clues
  const onDeactivateAllClues = useCallback(
    async (clues) => {
      try {
        const batch = db.batch()

        // Deactivate checkpoint as well
        if (checkpoint.active) {
          const { value: ok } = await Alerts.Clue.DEACTIVATE_ALL(checkpoint)
          if (!ok) return
          const checkpointRef = db.doc(checkpoint.path)
          batch.update(checkpointRef, { active: false })
        }
        setLoading(true)
        clues.forEach((clue) => {
          const clueRef = db.doc(clue.path)
          batch.update(clueRef, { active: false })
        })
        await batch.commit()
      } catch (err) {
        Alerts.Clue.SAVE_FAILED(err)
      } finally {
        setLoading(false)
      }
    },
    [checkpoint]
  )

  // Generate automatic clues
  const onGenerateClues = useCallback(async () => {
    if (loading) return
    const { centerPoint, path } = checkpoint

    const newClues = []
    try {
      setLoading(true)
      setSubmitDisabled(true)

      // Streetview panorama
      // Skip if we already have one
      if (!clues.regular?.some(({ type }) => type.value === ClueType.STREET_VIEW)) {
        const streetviewClue = await mounted(
          createClueFromType(ClueType.STREET_VIEW, path, { adding: addingCheckpoint, checkpoint })
        )
        newClues.push(streetviewClue)
      }

      // Treasure map clue
      // Skip if we already have one
      if (!clues.regular?.some(({ type }) => type.value === ClueType.TREASURE)) {
        const treasureClues = await mounted(
          Promise.all([
            createClueFromType(ClueType.TREASURE, path, {
              centerPoint,
              difficulty: getOption(3, DIFFICULTY_OPTIONS),
              zoom: getOption(17, TREASURE_ZOOM_OPTIONS)
            })
          ])
        )
        newClues.push(...treasureClues)
      }

      // POI
      const poiTypes = ['address', 'poi']
      const poiClues = await Promise.all(
        poiTypes.map(
          async (type) =>
            await createClueFromType('poi', path, {
              auto: true,
              poiType: type,
              centerPoint
            })
        )
      )

      newClues.push(...poiClues.flatMap((x) => x))

      setLoading(false)
      setSubmitDisabled(false)

      if (!newClues.length) throw new Error('No clues...')
      dispatch({ type: actionTypes.candidates.addMany, clues: newClues })
      Alerts.Clue.GENERATED_CLUES(newClues)
    } catch (err) {
      console.error(err)
      Alerts.Clue.GENERATE_FAILED(err)
    } finally {
      setLoading(false)
      setSubmitDisabled(false)
    }
  }, [addingCheckpoint, checkpoint, clues, dispatch, loading, mounted, setSubmitDisabled])

  return clueBeingEdited ? (
    <EditClue>
      <ClueForm checkpoint={checkpoint} clue={clueBeingEdited} onSave={onSaveClue} />
      {checkpoint.metadata && <ClueHelper metadata={checkpoint.metadata} />}
    </EditClue>
  ) : (
    <Lists>
      <ClueList
        loading={loading}
        onActivateAll={onActivateAllClues}
        onDeactivateAll={onDeactivateAllClues}
        onGenerateClues={onGenerateClues}
      />
      {showCandidates && <CandidateClueList clues={clues?.candidates ?? []} loading={loading} />}
    </Lists>
  )
}
const Lists = tw.div`flex flex-col xl:flex-row w-full`
const EditClue = tw.div`flex w-full`
