const environments = {
  development: {
    cloudinary: {
      API: 'https://api.cloudinary.com/v1_1/dozsbtdha/image/upload',
      NAME: 'dozsbtdha',
      KEY: '986787777164854'
    },
    firebase: {
      API_KEY: 'AIzaSyA-m9Dq2mzKzQkLEyB_yBWfWBgYffr-Eh0',
      APP_ID: '1:269927260343:web:6e0d966d939afc010b2d32',
      AUTH_DOMAIN: 'hvnt-develop.firebaseapp.com',
      FIRESTORE_URL: 'https://console.firebase.google.com/u/0/project/hvnt-develop/firestore/data',
      MEASUREMENT_ID: 'G-JX1X7H14CK',
      MESSAGING_SENDER_ID: '269927260343',
      PROJECT_ID: 'hvnt-develop',
      STORAGE_BUCKET: 'hvnt-develop.appspot.com',
      RECAPTCHA_SITEKEY: '6LdAQVsiAAAAAMs1lY81cgsl-bMYwpdbfKcC7iIv'
    },
    prize: {
      DEFAULT_PRIZE_IMAGE:
        'https://res.cloudinary.com/dozsbtdha/image/upload/v1619169104/forrestfenn/fallback%20images/d24a54c7-e571-473f-bd21-daf906e9315b.jpg'
    }
  },

  staging: {
    cloudinary: {
      API: 'https://api.cloudinary.com/v1_1/hvnt-staging',
      NAME: 'hvnt-staging',
      KEY: '263571328834196'
    },
    firebase: {
      API_KEY: 'AIzaSyDn2NSmxO2AE9sMW_Ad47d-Ken0l7WDpao',
      APP_ID: '1:879788720048:web:0864a802e1f55fc986a075',
      AUTH_DOMAIN: 'hvnt-staging.firebaseapp.com',
      FIRESTORE_URL: 'https://console.firebase.google.com/u/0/project/hvnt-staging/firestore/data',
      MEASUREMENT_ID: 'G-Z9S14GZYXZ',
      MESSAGING_SENDER_ID: '879788720048',
      PROJECT_ID: 'hvnt-staging',
      STORAGE_BUCKET: 'hvnt-staging.appspot.com',
      RECAPTCHA_SITEKEY: '6LcBbLsjAAAAAC_XMP1aP-8XH5CkIt0rLgkXdXfH'
    },
    prize: {
      DEFAULT_PRIZE_IMAGE:
        'https://res.cloudinary.com/hvnt-staging/image/upload/v1620394426/forrestfenn/fallback_images/gift_bi0dmh.jpg'
    }
  },

  production: {
    cloudinary: {
      API: 'https://api.cloudinary.com/v1_1/hvnt',
      NAME: 'hvnt',
      KEY: '621889781882615'
    },
    firebase: {
      API_KEY: 'AIzaSyDJCDfWJil1D7RWJgME-5xWIgTQvAW_6BE',
      APP_ID: '1:5532380568:web:d5c223233adc79f9a80fed',
      AUTH_DOMAIN: 'hvnt-5c989.firebaseapp.com',
      FIRESTORE_URL: 'https://console.firebase.google.com/u/0/project/hvnt-5c989/firestore/data',
      MEASUREMENT_ID: 'G-5T3LBVR6VP',
      MESSAGING_SENDER_ID: '5532380568',
      PROJECT_ID: 'hvnt-5c989',
      STORAGE_BUCKET: 'hvnt-5c989.appspot.com',
      RECAPTCHA_SITEKEY: '6LcpoLojAAAAAOqRxPl9mp2TC_-iOpBpCJxRskKO'
    },
    prize: {
      DEFAULT_PRIZE_IMAGE:
        'https://res.cloudinary.com/hvnt/image/upload/v1620394338/forrestfenn/fallback_images/gift_kicbmr.jpg'
    }
  }
}

const config = environments[process.env.REACT_APP_STAGE] || {
  // Override values for localhost
  ...environments.development,
  firebase: {
    ...environments.development.firebase,
    FIRESTORE_URL: 'http://localhost:4000/firestore/data'
  }
}

export default {
  ...config,
  background:
    'https://res.cloudinary.com/dozsbtdha/image/upload/e_tilt_shift:60,f_auto,q_auto/v1633975789/forrestfenn/shutterstock_762344239_pzday1.webp',
  clue: {
    PREVIEW:
      'https://res.cloudinary.com/dozsbtdha/image/upload/v1603270947/clues/images/4a852cf5-801d-4974-9197-f3acc90b123a.jpg'
  },
  hvntRoute: {
    ACCEPTANCE_RATE: 0.5, // accept < 50 % valid routes
    DEFAULT_NUM_CHECKPOINTS: 5,
    DEFAULT_RADIUS: 1000,
    MIN_NUM_CHECKPOINTS: 1,
    MAX_NUM_CHECKPOINTS: 16,
    MIN_RADIUS: 600,
    MAX_RADIUS: 5000,
    LOCATION_EXPIRATION_DAYS: 30,
    // node-hvnt-route config
    FIRST_EASY_PROPORTION: 0.3,
    MAX_ANGLE: 110,
    MAX_ROUTES: 10,
    TARGET_DISTANCES: {
      min: 100,
      max: 750,
      start: 150,
      1: 200,
      2: 200,
      3: 250,
      default: 500
    },
    TOLERANCE_DISTANCE: 100,
    TOLERANCE_PROPORTION: 0.5,
    RELATIVE_TOLERANCE: true
  },
  mercator: {
    FIRESTORE_URL:
      'https://console.firebase.google.com/u/0/project/gerardus-mercator/firestore/data',
    API_KEY: 'AIzaSyDWshWBJLz_fB_PoUYkbWVfOA6EybJKptU',
    API: 'https://mercator-service-z22jw2jmaq-ew.a.run.app',
    PROJECT_ID: 'gerardus-mercator'
  },
  mapbox: {
    ALTERNATE_MAP: 'mapbox://styles/hvnt/ckushwoz43eut17k984nkyxtx',
    MINERAL_MAP: 'mapbox://styles/hvnt/ckusht2g2kg7017pr9zlx5xty',
    ANALYTICS_MAP: 'mapbox://styles/hvnt/ckushsme73eqw17k94vhui2q2',
    DEFAULT_MAP: 'mapbox://styles/hvnt/cklgmtzfs7vow17savfltzgg7',
    MONOCHROME_MAP: 'mapbox://styles/hvnt/cklgmers50i9p17oc5x3llheq',
    NORTH_STAR: 'mapbox://styles/hvnt/cl4826jc6003715p680ln5k91',
    SATELLITE_MAP: 'mapbox://styles/hvnt/cklgmvxox4ze517o0refcy17d',
    TREASURE_MAP: 'mapbox://styles/hvnt/ckemq4g8e1jrp19rcb6ehu16e',
    PENCIL_MAP: 'mapbox://styles/hvnt/ckushmw9k6fyi17ryw0stp2bg',
    BLUEPRINT_MAP: 'mapbox://styles/hvnt/ckemq3kav2j3r19oc1q52d8qf',
    STATIC_HVNT_MAP: 'mapbox://styles/hvnt/ckvgkwb2lhfe614o8jgbvrium',
    STATIC_HVNT_MAP_API: 'https://api.mapbox.com/styles/v1/hvnt/ckvgkwb2lhfe614o8jgbvrium',
    TREASURE_MAP_API: 'https://api.mapbox.com/styles/v1/hvnt/ckemq4g8e1jrp19rcb6ehu16e',
    TREASURE_X:
      'https%3A%2F%2Fres.cloudinary.com%2Fdozsbtdha%2Fimage%2Fupload%2Fv1626086835%2Fforrestfenn%2Fassets%2Fx_marks_the_spot_pb8w3q.png',
    GEOCODE_API: 'https://api.mapbox.com/geocoding/v5/mapbox.places'
  },
  support: {
    DEFAULT_SUPPORT_IMAGE:
      'https://res.cloudinary.com/hvnt/image/upload/v1636399218/forrestfenn/fallback_images/hvnt-logo_ko3t77.ico'
  },
  user: {
    DEFAULT_USER_IMAGE:
      'https://res.cloudinary.com/dozsbtdha/image/upload/v1626086678/forrestfenn/fallback%20images/blank-profile-picture_vx6iiu.png'
  },
  wiki: {
    API_EN: 'https://en.wikipedia.org/w/api.php',
    API_SV: 'https://sv.wikipedia.org/w/api.php',
    API_SR: 'https://sr.wikipedia.org/w/api.php'
  },
  MAX_IMAGE_UPLOAD_SIZE: 36000000, // 36MB
  DEFAULT_COORDS: { latitude: 54.437379627108555, longitude: 14.513727452781978 }
}
