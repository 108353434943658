import { auth, db, FieldValue, GeoPoint } from '../lib/firebase'
import { isGeoPoint, isStringOrNumber, isTimestamp } from './validationFunctions'
import { geohashForLocation } from 'geofire-common'
import { candidateConverter } from './formatCandidates'
import pull from 'lodash/pull'
import { createOption, createStringArray } from './helperFunctions'

export const checkpointConverter = {
  fromFirestore: (doc) => {
    const {
      active = true,
      createdAt,
      custom,
      customTags,
      elevation,
      errors = [],
      fromMercator,
      geohash,
      location,
      metadata,
      name,
      s2CellId,
      tags,
      updatedAt
    } = doc.data() // todo: change location -> centerPoint

    let formattedCheckpoint = {
      active,
      centerPoint: isGeoPoint(location)
        ? { latitude: location.latitude, longitude: location.longitude }
        : addError({ errors, type: 'broken' }),
      ...(createdAt &&
        !!createdAt?.toDate && {
          createdAt: new Date(createdAt.toDate()).toISOString()
        }),
      ...(elevation && { elevation }),
      ...(fromMercator && { fromMercator }),
      ...(geohash && { geohash }),
      id: doc.id,
      ...(metadata && { metadata }),
      name: isStringOrNumber(name) ? name : addError({ errors, type: 'name' }),
      path: doc.ref.path,
      ...(custom && {
        custom: true,
        customTags: customTags?.map(createOption)
      }),
      ...(s2CellId && { s2CellId }),
      ...(tags && { tags: tags.map(createOption) }),
      ...(isTimestamp(updatedAt) && { updatedAt: updatedAt.toDate() })
    }
    return { ...formattedCheckpoint, errors }
  },
  toFirestore: (data) => {
    const {
      active = true,
      centerPoint,
      createdAt,
      custom,
      customTags,
      elevation,
      id,
      name,
      tags
    } = data
    return {
      active,
      ...(createdAt && { createdAt }),
      ...(elevation && { elevation }),
      geohash: geohashForLocation([
        parseFloat(centerPoint.latitude),
        parseFloat(centerPoint.longitude)
      ]),
      location: new GeoPoint(centerPoint.latitude, centerPoint.longitude), // TODO: location -> centerPoint
      id,
      custom: custom || FieldValue.delete(),
      customTags: custom ? createStringArray(customTags) : FieldValue.delete(),
      name,
      tags: createStringArray(tags),
      updatedAt: FieldValue.serverTimestamp(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  },
  fromJson: (data) => {
    const {
      active = true,
      centerPoint,
      createdAt,
      errors = [],
      id,
      latitude,
      longitude,
      location,
      name,
      path,
      tags,
      updatedAt
    } = data
    const formattedCheckpoint = {
      active,
      centerPoint:
        latitude && longitude
          ? isGeoPoint({ latitude, longitude }) && { latitude, longitude }
          : location && isGeoPoint(location)
          ? location
          : centerPoint && isGeoPoint(centerPoint)
          ? centerPoint
          : addError({ errors, type: 'broken' }),
      ...(createdAt &&
        !!createdAt?.toDate && {
          createdAt: new Date(createdAt.toDate()).toISOString()
        }),
      geohash:
        latitude && longitude && geohashForLocation([parseFloat(latitude), parseFloat(longitude)]),
      id: id ?? addError({ type: 'broken', deactivate: true }),
      name: isStringOrNumber(name) ? name : addError({ errors, type: 'name' }),
      path: `checkpoints/${path}/${id}`,
      ...(tags && { tags: tags?.map(createOption) }),
      ...(updatedAt?.toDate && { updatedAt: new Date(updatedAt.toDate()).toISOString() })
    }
    return examineErrors({ ...formattedCheckpoint, errors })
  }
}

const addError = ({ errors, type, fallback = undefined, deactivate }) => {
  errors.push(type)
  if (deactivate) errors.push('deactivated')
  return fallback
}

const examineErrors = ({ errors, ...checkpoint }) => {
  if (errors.find((e) => e === 'deactivated')) {
    pull(errors, 'deactivated')
    checkpoint.active = false
  }
  return {
    ...checkpoint,
    errors: errors.some((e) => e === 'broken') ? ['broken'] : errors
  }
}

export const converterFromSource = (checkpoint) => {
  if (checkpoint.candidate) return candidateConverter
  else return checkpointConverter
}
