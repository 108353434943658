import React, { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import tw from 'twin.macro'
import Icon from '../../../styles/Icons'
import { Button } from '../../../styles/Layout'
import { Switch } from '../../Layout/Switch'

export const AddArea = ({ adding, color = 'blue', disabled, onClick }) => {
  const { setValue, watch } = useFormContext()
  const { area = {} } = watch()

  return (
    <Container>
      <Row>
        <Switch
          role="switch"
          defaultChecked={false}
          color={color}
          disabled={area.saved || disabled}
          onToggle={onClick}
          size="lg"
          checked={adding}
          label={
            <div tw="flex">
              {'Add Hvnt Area'}&nbsp;
              <Icon.PinSolid size="sm" />
            </div>
          }
        />
      </Row>
      {!area.saved && (
        <>
          {adding ? (
            <Info>Click the map to set midpoint location</Info>
          ) : (
            area.centerPoint && <Info>Drag the midpoint to move</Info>
          )}

          {!!area.centerPoint && (
            <Button.Gray disabled={disabled} size="sm" onClick={() => setValue('area.centerPoint')}>
              <Icon.Trashcan mr="2" size="sm" />
              Remove Area
            </Button.Gray>
          )}
        </>
      )}
    </Container>
  )
}

const Container = tw.div`absolute top-2 flex flex-col z-10 p-2 bg-white text-bismark-700 
rounded-md shadow-lg bg-opacity-90 border-4 select-none gap-2`
const Row = tw.div`flex justify-center items-center gap-2 text-lg`
const Info = tw(Row)`text-sm`
