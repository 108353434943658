import React from 'react'
import tw, { theme } from 'twin.macro'
import { List } from '../../../layout/List'
import { Card } from '../../../styles/Layout'
import { ExtractItem, LinkItem, PropsItem } from './HelperItems'

export const ClueHelper = ({ metadata = {} }) => {
  const { extract, wikidataProps, wikiDataUrl, wikipediaUrl } = metadata

  return (
    <Card.Container header="Clue Helpers" color={theme`colors.sky`} size="sm" tw="w-1/3">
      <StyledList>
        {wikipediaUrl && <LinkItem url={wikipediaUrl} />}
        {wikiDataUrl && <LinkItem url={wikiDataUrl} />}
        {wikidataProps && <PropsItem props={wikidataProps} />}
        {extract && <ExtractItem>{extract}</ExtractItem>}
      </StyledList>
    </Card.Container>
  )
}

const StyledList = tw(List.Container)`p-2 gap-4`
