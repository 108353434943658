import { feature, point } from '@turf/helpers'
import React, { useEffect, useMemo } from 'react'
import { Layer, Source } from 'react-map-gl'
import { theme } from 'twin.macro'
import config from '../../../config'
import { useMap } from '../../../contexts/MapContext'
import { usePlayer } from '../../../contexts/PlayerContext'
import { ActiveStatusType } from '../../../enums'
import { mapLayers } from '../../Map/MapLayers'

export const PlayerLocation = () => {
  const { activePlayer } = usePlayer()
  const { focusOnPoint } = useMap()

  const locationData = useMemo(() => {
    if (!activePlayer?.userData?.location) return feature()
    const { activeStatus, userData } = activePlayer
    return point([userData.location.longitude, userData.location.latitude], activeStatus)
  }, [activePlayer])

  const colorLocation = useMemo(() => {
    if (!activePlayer?.activeStatus) return {}
    const matchLocation = ['match', ['get', 'status']]
    const matchBorder = ['match', ['get', 'status']]
    const { status } = activePlayer.activeStatus

    switch (status) {
      case ActiveStatusType.ACTIVE:
        matchLocation.push(status, theme`colors.green`)
        matchBorder.push(status, theme`colors.green.600`)
        break
      case ActiveStatusType.IDLE:
        matchLocation.push(status, theme`colors.orange`)
        matchBorder.push(status, theme`colors.orange.600`)
        break
      case ActiveStatusType.INACTIVE:
        matchLocation.push(status, theme`colors.red`)
        matchBorder.push(status, theme`colors.red.600`)
        break
    }
    matchLocation.push('rgba(0,0,0,0.5)')
    matchBorder.push('rgba(0,0,0,0.7)')

    return { border: matchBorder, location: matchLocation }
  }, [activePlayer?.activeStatus])

  // Zoom to user location
  useEffect(() => {
    if (!activePlayer?.userData?.location)
      return focusOnPoint({ centerPoint: config.DEFAULT_COORDS }, { maxZoom: 3 })
    focusOnPoint({ centerPoint: activePlayer.userData.location }, { padding: 100 })
  }, [activePlayer?.userData, focusOnPoint])

  return (
    <Source type="geojson" data={locationData}>
      <Layer {...mapLayers.player.location(colorLocation)} />
    </Source>
  )
}
