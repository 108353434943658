import React, { Fragment } from 'react'
import tw, { theme } from 'twin.macro'
import { ChallengeType } from '../../../enums'
import { Item } from '../../../layout/List'
import Icon from '../../../styles/Icons'

export const ChallengeSummary = (item) => {
  const { category, customTags, difficulty, options = [], question, triviaType, type } = item

  switch (type) {
    case ChallengeType.TRIVIA: {
      return (
        <>
          <Item.Row>
            <Item.Bold>{'Trivia Type:'}</Item.Bold>
            <pre>{triviaType ?? '-'}</pre>
          </Item.Row>

          {!!customTags?.length && (
            <Item.Row>
              <Item.Bold>{'Custom Tags:'}</Item.Bold>
              <pre>{customTags.map((tag) => tag.label).join(',')}</pre>
            </Item.Row>
          )}

          <Item.Row>
            <Item.Bold>{'Question:'}</Item.Bold>
            <Item.Text>{question ?? '-'}</Item.Text>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Options:'}</Item.Bold>
            <Item.Text />
          </Item.Row>

          <Options>
            {options.map(({ correct, explanation, text }, i) => (
              <Fragment key={i}>
                <Item.Row>
                  <Item.Bold>{`Option ${i + 1}:`}</Item.Bold>
                  <OptionText>
                    {correct ? (
                      <Icon.Check size="xs" color={theme`colors.green`} />
                    ) : (
                      <Icon.Close size="xs" color={theme`colors.red`} />
                    )}
                    {text}
                  </OptionText>
                </Item.Row>

                {explanation && (
                  <Item.Row>
                    <Item.Bold tw="pl-2">{'Explanation:'}</Item.Bold>
                    <Item.Text>{`"${explanation}"`}</Item.Text>
                  </Item.Row>
                )}
              </Fragment>
            ))}
          </Options>

          <Item.Row>
            <Item.Bold>{'Category:'}</Item.Bold>
            <Item.Label>{category?.value ?? '-'}</Item.Label>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Difficulty:'}</Item.Bold>
            <Item.Text>{difficulty?.value ?? '-'}</Item.Text>
          </Item.Row>
        </>
      )
    }
    default: {
      return <></>
    }
  }
}

const Options = tw(Item.SummaryList)`col-span-2 bg-gray-100 shadow-inner rounded-sm`
const OptionText = tw(Item.Text)`inline-flex gap-2`
