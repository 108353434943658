import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import tw from 'twin.macro'
import { useCorporate } from '../../../contexts/CorporateContext'
import { EventLocationType } from '../../../enums'
import Icon from '../../../styles/Icons'
import { Button, Card, Input } from '../../../styles/Layout'
import { validate } from '../../../utility/formValidation'
import { isCoordinates } from '../../../utility/validationFunctions'
import { Section } from '../../Hvnt/FormSection'

export const AddARLocation = ({ onSubmit }) => {
  const { stopEditingEventLocation } = useCorporate()
  const {
    formState: { errors },
    register
  } = useFormContext()

  useEffect(() => {
    register('type', { value: EventLocationType.AR })
  }, [register])

  return (
    <>
      <Section field="name" label="Name of the location" error={errors.name} required>
        <Input.Default
          {...register('name', { required: 'Name is required', validate: validate.event.name })}
          placeholder="Display Name"
        />
        <Input.Error>{errors.name?.message}</Input.Error>
      </Section>
      <Section
        field={['centerPoint.latitude', 'centerPoint.longitude']}
        label="Add coordinates"
        error={errors.centerPoint}
        required
      >
        <Input.Label>Location (Lat, Lng)</Input.Label>
        <CoordsWrapper>
          <Input.Default
            {...register('centerPoint.latitude', {
              required: 'Latitide is required',
              validate: isCoordinates,
              valueAsNumber: true
            })}
            size="sm"
            placeholder="Latitude"
            tw="md:max-w-1/3"
          />

          <Input.Default
            {...register('centerPoint.longitude', {
              required: 'Longitude is required',
              validate: isCoordinates,
              valueAsNumber: true
            })}
            size="sm"
            placeholder="Longitude"
            tw="md:max-w-1/3"
          />
        </CoordsWrapper>

        {errors?.centerPoint && <Input.Error>{'Invalid coordinates'}</Input.Error>}
      </Section>
      <Footer>
        <Button.White size="sm" onClick={stopEditingEventLocation} ring tw="mr-auto">
          <Icon.Close size="sm" mr="2" />
          Cancel
        </Button.White>
        <Button.Submit size="sm" onClick={onSubmit} ring>
          <Icon.Check size="sm" mr="2" />
          Save Location
        </Button.Submit>
      </Footer>
    </>
  )
}
const CoordsWrapper = tw.div`flex flex-wrap gap-1 w-full`
const Footer = tw(Card.Footer)`mt-auto z-30 gap-2 py-3 bg-terracotta-300`
