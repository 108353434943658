import React, { useCallback, useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Input } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { ImageEditor } from '../ImageEditor'
import { Loader } from '../Loader/Loader'
import { useIsMounted } from '../../hooks/useIsMounted'
import { isCloudinaryImage, isImageUrl } from '../../utility/validationFunctions'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import request from '../../request'
import config from '../../config'
import { useClue } from '../../contexts/ClueContext'
import { IMAGE_LICENSE_OPTIONS } from '../../utility/labeledOptions'
import { ClueImageType } from '../../enums'
import { Alerts } from '../../utility/alerts'
import Dropdown from '../Dropdown'
import { ImageInput } from '../Image'

export const ImageForm = ({ clue, id }) => {
  const { auto, imageType } = clue
  const {
    clearErrors,
    control,
    register,
    setError,
    setValue,
    trigger,
    watch,
    formState: { errors }
  } = useFormContext()
  const { displayImage, uploadClueImage } = useClue()
  const { mounted } = useIsMounted()
  const { setSubmitDisabled } = useCheckpoint()
  const {
    imageData,
    imageUrl: { modified: modifiedUrl, original: originalUrl } = {},
    licenseType,
    original,
    type,
    wikiUrl
  } = watch()

  const [editingImage, setEditingImage] = useState(false)
  const [uploadingImage, setUploadingImage] = useState(false)

  const image = modifiedUrl ?? originalUrl ?? imageData

  useEffect(() => {
    register('imageUrl')
    register('original')
  }, [register])

  useEffect(() => {
    const { imageUrl } = clue
    if (!clue || !imageUrl) return
    setValue('imageUrl.original', imageUrl)
  }, [clue, setValue])

  const onClearImage = useCallback(() => {
    setValue('imageData')
    setValue('imageUrl')
    setValue('wikiUrl')
    setValue('original')
    clearErrors('wikiUrl')
    setEditingImage(false)
  }, [clearErrors, setValue])

  const onEditImage = useCallback(
    async ({ image }) => {
      if (!isCloudinaryImage(image)) {
        // Upload the image to Cloudinary first
        try {
          setUploadingImage(true)
          const imageUrl = await uploadClueImage({ id, imageData: image, type: type?.value })

          setValue('imageUrl.original', imageUrl)
          setValue('imageData')
          // If successful, toggle these
          setSubmitDisabled(true)
          setEditingImage(true)
        } catch (err) {
          return setError('imageUrl', {
            type: 'manual',
            message: 'Try adding another image!'
          })
        } finally {
          setUploadingImage(false)
        }
      } else {
        setEditingImage(true)
        setSubmitDisabled(true)
      }
    },
    [id, setError, setSubmitDisabled, setUploadingImage, setValue, type, uploadClueImage]
  )

  const onCancelEditedImage = useCallback(() => {
    setEditingImage(false)
    setSubmitDisabled(false)
  }, [setSubmitDisabled])

  // Fetch image from url and create image id
  const onFetchImage = useCallback(
    async (imageUrl) => {
      clearErrors('wikiUrl')
      if (!isImageUrl(imageUrl)) {
        trigger('wikiUrl')
      }
      try {
        const image64 = await mounted(request.downloadImage(imageUrl))
        setValue('imageData', image64)
        setValue('original', false)
      } catch (err) {
        console.error(err)
        setError('wikiUrl', { type: 'manual', message: err })
      }
    },
    [clearErrors, mounted, setError, setValue, trigger]
  )

  // Save image from file and create image id
  const onSelectFile = useCallback(
    async (e) => {
      function readFile(file) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => resolve(reader.result), false)
          reader.readAsDataURL(file)
        })
      }
      const files = e.target?.files
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size < config.MAX_IMAGE_UPLOAD_SIZE) {
          onClearImage()
          const imageData = await mounted(readFile(file))
          setValue('imageData', imageData)
          setValue('original', true)
        } else {
          Alerts.Image.TOO_BIG(file)
        }
      }
    },
    [mounted, onClearImage, setValue]
  )

  const onSaveEditedImage = useCallback(
    (url) => {
      setValue('imageUrl.modified', url)
      setEditingImage(false)
    },
    [setValue]
  )

  return (
    <>
      <Loader loading={uploadingImage} text="Uploading Image" />
      <Section>
        <ButtonsContainer>
          {!image && (
            <>
              <Input.Label>Add Image</Input.Label>
              <ButtonsContainer>
                <InputWrapper>
                  <Input.Filter
                    {...register('wikiUrl', {
                      required: {
                        pattern: {
                          value: isImageUrl,
                          message: 'We accept jpg, jpeg and png'
                        }
                      }
                    })}
                    size="md"
                    tw="m-0"
                    placeholder="Wikimedia URL"
                    disabled={auto}
                  />

                  <Button.Gray tw="m-0" onClick={() => onFetchImage(wikiUrl)}>
                    Fetch
                  </Button.Gray>
                </InputWrapper>

                <Small>or</Small>
                <Button.Secondary as="label" htmlFor="img-upload" disabled={auto} tw="m-0 w-full">
                  <Icon.Upload mr="2" />
                  {`Upload ${imageData ? 'New' : ''} Image`}
                  <ImageInput id="img-upload" onChange={onSelectFile} />
                </Button.Secondary>
              </ButtonsContainer>
            </>
          )}
        </ButtonsContainer>
        <Input.Error>{errors?.imageUrl?.message || errors?.wikiUrl?.message}</Input.Error>
      </Section>

      {!!image && (
        <Section>
          <Input.Label>Image Text</Input.Label>
          <Input.Default
            {...register('imageText', {
              maxLength: {
                value: 70,
                message: 'This cannot be longer than 70 characters'
              }
            })}
            placeholder="Optional Hint [Max 70 characters]"
            size="md"
            textarea
          />
          <Input.Error>{errors?.imageText?.message}</Input.Error>
        </Section>
      )}

      {editingImage && originalUrl ? (
        <ImageEditor
          imageUrl={originalUrl}
          onSaveImage={onSaveEditedImage}
          onCancel={onCancelEditedImage}
        />
      ) : (
        !!image && (
          <>
            <ImageSection {...register('imageData', { shouldUnregister: true })}>
              <ImageWrapper>
                <Image
                  src={image}
                  alt="Image Clue"
                  onClick={() => displayImage({ imageUrl: image })}
                />

                <ButtonsContainer>
                  <Button.Secondary
                    disabled={imageType === ClueImageType.PLACE_PHOTO}
                    ring
                    size="sm"
                    onClick={() =>
                      onEditImage({
                        image: originalUrl ?? imageData
                      })
                    }
                  >
                    <Icon.Star mr="2" size="sm" />
                    {' Modify Image'}
                  </Button.Secondary>
                  <Button.Warning ring size="sm" onClick={onClearImage}>
                    <Icon.Trashcan mr="2" size="sm" />
                    {'Remove Image'}
                  </Button.Warning>
                </ButtonsContainer>
              </ImageWrapper>
            </ImageSection>

            {/* License */}
            {image && original === false && (
              <>
                <Section>
                  <Input.Label required={true}>License Type</Input.Label>
                  <Controller
                    control={control}
                    name="licenseType"
                    render={({ field }) => (
                      <Dropdown.Select
                        {...field}
                        isDisabled={auto}
                        isOptionDisabled={(option) => option?.value === 'google'}
                        menuPlacement="top"
                        options={IMAGE_LICENSE_OPTIONS}
                        placeholder="License"
                      />
                    )}
                    rules={{ required: 'License is required' }}
                  />
                  <Input.Error>{errors?.licenseType?.message}</Input.Error>
                </Section>
                {licenseType?.value !== 'cc0' && (
                  <>
                    <Section>
                      <Input.Label required={true}>License Author</Input.Label>
                      <Input.Default
                        {...register('licenseAuthor', { required: 'Author is required' })}
                        disabled={auto}
                        placeholder="Author"
                        size="md"
                        type="text"
                      />

                      <Input.Error>{errors?.licenseAuthor?.message}</Input.Error>
                    </Section>
                    <Section>
                      <Input.Label>Source URL</Input.Label>
                      <Input.Default
                        {...register('licenseSource', {
                          pattern: {
                            value:
                              // eslint-disable-next-line no-useless-escape
                              /^(?:https?:\/\/)[\w.-]+(?:\.[\wåäöÅÄÖ]+)+[\w\-._~:?#[@!$%&'()*+,;=\]\/]*[\w]+$/gi,
                            message: 'This must be a valid url'
                          }
                        })}
                        disabled={auto}
                        placeholder="Original / Other Source"
                        size="md"
                      />
                      <Input.Error>{errors?.licenseSource?.message}</Input.Error>
                    </Section>
                  </>
                )}
                <LicenseDisclaimer>
                  <a
                    href="https://en.wikipedia.org/wiki/Creative_Commons_license#Types_of_licenses"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More info
                  </a>
                  <a
                    href="https://wiki.creativecommons.org/wiki/Best_practices_for_attribution"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Example
                  </a>
                </LicenseDisclaimer>
              </>
            )}
          </>
        )
      )}
    </>
  )
}
const Section = tw.div`flex flex-col my-2 px-4 w-full`
const ImageSection = tw(Section)`relative flex items-center justify-center`
const ImageWrapper = tw.div`relative flex flex-col items-center gap-3 bg-gray-200 rounded-md p-4`
const Image = tw.img`w-full h-full max-h-96 cursor-pointer`
const ButtonsContainer = tw.div`flex justify-center items-center w-full whitespace-nowrap gap-3`
const Small = tw.span`font-light text-sm text-gray-900 whitespace-nowrap`
const InputWrapper = tw.div`flex w-full items-center bg-white-pure rounded-md overflow-hidden`
const LicenseDisclaimer = styled.div`
  ${tw`flex w-full justify-center`}
  & > * {
    ${tw`text-blue-700 text-sm mx-2 underline`};
  }
`
