import React, { useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import tw from 'twin.macro'
import { useLocations } from '../../contexts/LocationsContext'
import { CellScanMode } from '../../enums'
import useMercator from '../../hooks/firebase/useMercator'
import Icon from '../../styles/Icons'
import { Button } from '../../styles/Layout'
import { Alerts, Toast } from '../../utility/alerts'
import { Loader } from '../Loader/Loader'
import { SelectSettings } from './SelectSettings'

export const QueryLocations = () => {
  const { clearS2Cells, clearScanning, dispatch, loading, s2Cells, setLoading } = useLocations()
  const { queryLocations } = useMercator(dispatch)
  const { control, handleSubmit } = useFormContext()
  const { s2Level, scanCenterPoint, scanMode, scanRadius } = useWatch({ control })

  const canQuery = useMemo(() => {
    if (!s2Level) return false
    switch (scanMode) {
      case CellScanMode.CLICK:
        return s2Cells.length
      case CellScanMode.SCAN:
        return scanCenterPoint && scanRadius
    }
  }, [s2Cells.length, s2Level, scanCenterPoint, scanMode, scanRadius])

  // Query locations from Mercator
  const onQuery = useCallback(
    async (formData) => {
      try {
        setLoading(true)
        const { scanCenterPoint, scanMode, scanRadius, s2Level } = formData

        switch (scanMode) {
          case CellScanMode.CLICK:
            await queryLocations({
              s2Cells: s2Cells.map(({ properties }) => properties.id).join(','),
              s2Level: s2Level.value,
              type: 'cell'
            })
            break
          case CellScanMode.SCAN:
            await queryLocations({
              ...scanCenterPoint,
              radius: scanRadius,
              type: 'circle'
            })
            break
        }
        clearS2Cells()
        clearScanning()
        Toast.fire({ title: 'Locations Fetched', icon: 'success' })
      } catch (err) {
        console.error(err)
        Alerts.Locations.QUERY_FAILED(err)
      } finally {
        setLoading(false)
      }
    },
    [clearS2Cells, clearScanning, queryLocations, s2Cells, setLoading]
  )

  return (
    <Container>
      <Loader loading={loading} text={'Fetching Locations...'} />
      <Label>Select Cells</Label>
      <SelectSettings />

      <ButtonContainer>
        <Button.Primary onClick={handleSubmit(onQuery)} disabled={loading || !canQuery} ring>
          <Icon.Sparkles mr="2" /> Fetch Locations
        </Button.Primary>
      </ButtonContainer>
    </Container>
  )
}

const Container = tw.div`flex flex-col flex-1 w-full gap-1.5 p-3 bg-white-pure overflow-scroll`
const Label = tw.div`font-mono text-bismark-800 justify-center text-center p-1 select-none`
const ButtonContainer = tw.div`flex flex-wrap justify-center p-3 gap-3`
