import React, { Fragment } from 'react'
import tw, { styled } from 'twin.macro'

export const Item = {
  ActionButtons: tw.div`sm:order-3 sticky right-0 top-0 sm:top-2 flex flex-row sm:flex-col justify-end sm:justify-start gap-1 m-0 sm:m-2 z-10 p-1 sm:p-0 bg-white-pure`,
  Bold: tw.div`relative font-bold whitespace-nowrap text-matrix-800`,
  Center: tw.div`flex justify-center items-center`,
  Col: tw.div`flex flex-col`,
  Container: tw.div`relative`,
  Header: Object.assign(({ children, ...props }) => (
    <Table>
      <tbody>
        <ListItem {...props} data-testid="item-row">
          {children}
        </ListItem>
      </tbody>
    </Table>
  )),
  HeaderData: tw.td`px-2 py-2.5 truncate`,
  Image: tw.div`relative sm:sticky top-0 mx-auto w-full sm:max-w-item-image max-w-full h-32 sm:h-auto overflow-visible sm:overflow-hidden shadow-lg`,
  InsetList: tw.div`grid col-span-2 gap-y-2 gap-x-4 p-2 grid-template-columns[minmax(70px, min-content) minmax(0,1fr)] height[fit-content] w-full bg-gray-100 shadow-inner rounded-sm`,
  Label: tw.pre`w-min py-0.5 px-2 rounded-md shadow-md whitespace-nowrap bg-sky-400 text-gray-900`,
  Row: Object.assign(({ children, ...props }) => <Fragment {...props}>{children}</Fragment>),
  Status: tw.div`font-mono inline-flex gap-1.5 items-center`,
  Summary: styled.div`
    ${tw`relative flex flex-col sm:flex-row shadow-inner bg-white-pure overflow-scroll max-h-96`}
    ${({ expanded }) => (expanded ? tw`border-b-2 border-gray-100 min-height[16rem]` : tw`h-0`)}
  `,
  SummaryGrid: tw.div`order-2 grid grid-template-columns[repeat(auto-fit, minmax(200px,1fr))] height[fit-content] w-full p-5 gap-4`,
  SummaryList: tw.div`grid gap-y-2 gap-x-4 p-4 grid-template-columns[minmax(70px, min-content) minmax(0,1fr)] height[fit-content] w-full`,
  Text: tw.div`break-words`
}

const Table = tw.table`table-fixed w-full`
const ListItem = styled.tr`
  ${tw`relative font-sans font-light text-sm hover:bg-bismark-100 text-gray-900 cursor-pointer`}
  ${({ expanded }) => expanded && tw`bg-norway-200 `}
`
