import {
  ChallengeType,
  ClueType,
  InfoScreenType,
  PrizeCurrencyType,
  QueryPlayerType,
  Role,
  S2CellLevel,
  SeasonType,
  TriviaType
} from '../enums'

export const ROLE_OPTIONS = [
  { value: Role.CREATOR, label: 'Creator' },
  { value: Role.EDITOR, label: 'Editor' },
  { value: Role.VISITOR, label: 'Visitor' }
]

export const USER_GENDER_OPTIONS = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'other', label: 'Other' }
]

export const CHECKPOINT_TAGS_OPTIONS = [
  { value: 'castle', label: 'castle' },
  { value: 'mall', label: 'mall' },
  { value: 'monument', label: 'monument' },
  { value: 'museum', label: 'museum' },
  { value: 'park', label: 'park' },
  { value: 'royal', label: 'royal' },
  { value: 'sport', label: 'sport' },
  { value: 'square', label: 'square' },
  { value: 'theatre', label: 'theatre' },
  { value: 'underground', label: 'underground' }
]

export const HVNT_DEFAULT_TAGS = [{ value: 'hvnt', label: 'hvnt' }]

export const HVNT_TAGS_OPTIONS = [
  { value: 'city', label: 'city' },
  { value: 'event', label: 'event' },
  { value: 'explore', label: 'explore' },
  { value: 'guide', label: 'guide' },
  { value: 'scenery', label: 'scenery' },
  { value: 'sponsored', label: 'sponsored' },
  { value: 'stockholm', label: 'stockholm' },
  { value: 'sweden', label: 'sweden' }
]

export const CHALLENGE_TAGS_OPTIONS = [{ value: 'trivia', label: 'trivia' }]

export const DIFFICULTY_OPTIONS = [
  { value: 1, label: '1: Super Easy' },
  { value: 2, label: '2: Easy' },
  { value: 3, label: '3: Medium' },
  { value: 4, label: '4: Hard' },
  { value: 5, label: '5: Hardest' }
]

export const CLUE_TYPE_OPTIONS = [
  { value: ClueType.AREA, label: 'Area Shot' },
  { value: ClueType.IMAGE, label: 'Image Clue' },
  { value: ClueType.POI, label: 'Poi Clue' },
  { value: ClueType.STREET_VIEW_OLD, label: 'Street View Image' },
  { value: ClueType.STREET_VIEW, label: 'Street View Panorama' },
  { value: ClueType.TEXT, label: 'Text Clue' },
  { value: ClueType.TREASURE, label: 'Treasure Clue' }
]

export const RADIUS_OPTIONS = [
  { value: '100', label: '100 m' },
  { value: '250', label: '250 m' },
  { value: '500', label: '500 m' },
  { value: '1000', label: '1000 m' },
  { value: '2000', label: '2000 m' }
]

export const IMAGE_EFFECT_OPTIONS = [
  { value: 'e_sepia', label: 'Sepia' },
  { value: 'e_grayscale', label: 'Grayscale' },
  { value: 'e_blackwhite', label: 'Blackwhite' },
  { value: 'e_cartoonify', label: 'Cartoonify' },
  { value: 'e_saturation', label: 'Saturation', amount: 100, min: 50, max: 200, step: 50 },
  { value: 'e_blur', label: 'Blur', amount: 500, min: 100, max: 1000, step: 100 },
  { value: 'e_blur_faces', label: 'Blur Faces', amount: 500, min: 100, max: 1000, step: 100 },
  { value: 'e_pixelate', label: 'Pixelate', amount: 10, min: 5, max: 40, step: 5 },
  { value: 'e_pixelate_faces', label: 'Pixelate Faces', amount: 10, min: 5, max: 40, step: 5 }
]

export const IMAGE_LICENSE_OPTIONS = [
  {
    value: 'ccbysa4',
    deed: 'https://creativecommons.org/licenses/by-sa/4.0/',
    label: 'CC BY-SA 4.0'
  },
  {
    value: 'ccbysa3',
    deed: 'https://creativecommons.org/licenses/by-sa/3.0/',
    label: 'CC BY-SA 3.0'
  },
  {
    value: 'ccbysa25',
    deed: 'https://creativecommons.org/licenses/by-sa/2.5/',
    label: 'CC BY-SA 2.5'
  },
  {
    value: 'ccbysa2',
    deed: 'https://creativecommons.org/licenses/by-sa/2.0/',
    label: 'CC BY-SA 2.0'
  },
  {
    value: 'ccby4',
    deed: 'https://creativecommons.org/licenses/by/4.0/',
    label: 'CC BY 4.0'
  },
  {
    value: 'ccby3',
    deed: 'https://creativecommons.org/licenses/by/3.0/',
    label: 'CC BY 3.0'
  },
  {
    value: 'ccby25',
    deed: 'https://creativecommons.org/licenses/by/2.5/',
    label: 'CC BY 2.5'
  },
  {
    value: 'ccby2',
    deed: 'https://creativecommons.org/licenses/by/2.0/',
    label: 'CC BY 2.0'
  },
  {
    value: 'cc0',
    deed: 'https://creativecommons.org/publicdomain/zero/1.0/',
    label: 'Public Domain'
  },
  {
    value: 'google',
    deed: '',
    label: 'Google Place Photo'
  }
]

export const CHALLENGE_TYPE_OPTIONS = [{ value: ChallengeType.TRIVIA, label: 'Trivia Challenge' }]
export const TRIVIA_TYPE_OPTIONS = [
  { value: TriviaType.INPUT, label: 'Text Input' },
  { value: TriviaType.ONE_CORRECT, label: 'One Correct Answer' },
  { value: TriviaType.MANY_CORRECT, label: 'Multiple Correct Answers' }
]

export const TRIVIA_CATEGORY_OPTIONS = [
  { value: 'animal', label: 'Animal' },
  { value: 'art', label: 'Art' },
  { value: 'food', label: 'Food' },
  { value: 'games', label: 'Games' },
  { value: 'geography', label: 'Geography' },
  { value: 'general', label: 'General Knowledge' },
  { value: 'history', label: 'History' },
  { value: 'language', label: 'Language' },
  { value: 'literature', label: 'Literature' },
  { value: 'movie', label: 'Movie' },
  { value: 'music', label: 'Music' },
  { value: 'mythology', label: 'Mythology' },
  { value: 'politics', label: 'Politics' },
  { value: 'religion', label: 'Religion' },
  { value: 'science', label: 'Science' },
  { value: 'sports', label: 'Sports' },
  { value: 'other', label: 'Other' }
]

export const TREASURE_ZOOM_OPTIONS = [
  { value: 14, label: 'Zoomed out max' },
  { value: 15, label: 'Easier' },
  { value: 16, label: 'Medium' },
  { value: 17, label: 'Harder' },
  { value: 18, label: 'Zoomed in max' }
]

export const HVNT_CATEGORY_OPTIONS = [
  { value: 'city', label: 'City' },
  { value: 'explore', label: 'Explore' },
  { value: 'guided', label: 'Guided' },
  { value: 'sponsored', label: 'Sponsored' }
]

export const SEASON_TYPE_OPTIONS = [
  { value: SeasonType.SEASON, label: 'Regular Season' },
  { value: SeasonType.SPECIAL, label: 'Seasonal Event' }
]

export const PRIZE_CURRENCY_OPTIONS = [
  { value: PrizeCurrencyType.SEK, label: 'SEK' },
  { value: PrizeCurrencyType.EUR, label: 'EUR' },
  { value: PrizeCurrencyType.USD, label: 'USD' }
]

export const PRIZE_SUPPLIER_OPTIONS = [
  { value: 'hvnt', label: 'HVNT' },
  { value: 'pressbyrån', label: 'Pressbyrån' }
]

export const QUERY_PLAYER_TYPE_OPTIONS = [
  { value: QueryPlayerType.EMAIL, label: 'Email' },
  { value: QueryPlayerType.PHONE_NUMBER, label: 'Phone number' },
  { value: QueryPlayerType.ID, label: 'Id' }
]

export const INFO_SCREEN_TYPE_OPTIONS = [{ value: InfoScreenType.IMAGE_TIME, label: 'Image Time' }]

export const PRIZE_TAGS_OPTIONS = [
  { value: 'hvnt', label: 'hvnt' },
  { value: 'sponsored ', label: 'sponsored ' }
]

export const PRIZE_CATEGORY_OPTIONS = [
  { value: 'electronics', label: 'Electronics' },
  { value: 'voucher', label: 'Gift Voucher' },
  { value: 'consolation', label: 'Consolation' }
]

export const S2_CELL_LEVEL_OPTIONS = [
  { value: S2CellLevel[13], label: 'Small (hvnt size)', code: '13' },
  { value: S2CellLevel[12], label: 'Medium (city centers)', code: '12' },
  { value: S2CellLevel[11], label: 'Large (good coverage)', code: '11' }
]
