import React, { useCallback, useEffect, useMemo, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Section } from './FormSection'

import Icon from '../../styles/Icons'
import without from 'lodash/without'
import { ChallengeDistributionType } from '../../enums'
import { Info, Input } from '../../styles/Layout'
import { Controller, useFormContext } from 'react-hook-form'
const { EVEN, EVERY, EVERY_THIRD, FIRST, LAST, NONE, ODD } = ChallengeDistributionType

export const ChallengeConfig = () => {
  const { control, setValue, watch } = useFormContext()
  const distribution = watch('area.challenge.distribution')
  const numberOfCheckpoints = watch('numberOfCheckpoints')
  const [markers, setMarkers] = useState([])
  const checkpointsWithChallenges = useMemo(
    () => markers.filter((marker) => marker).length,
    [markers]
  )

  // Set distribution values
  const addDistribution = useCallback(
    (distribution, type, override) => {
      setValue(
        'area.challenge.distribution',
        distribution.some((val) => val === NONE || val === EVERY)
          ? distribution.includes(type)
            ? [NONE]
            : [type]
          : distribution.includes(type)
          ? distribution.length === 1
            ? [NONE]
            : without(distribution, type)
          : override
          ? [type]
          : [...distribution, type]
      )
    },
    [setValue]
  )

  const checkpointHasChallenge = (ordinalNumber, length, distribution = []) => {
    if (distribution.includes(ChallengeDistributionType.NONE)) return false
    if (distribution.includes(ChallengeDistributionType.EVERY)) return true
    if (distribution.includes(ChallengeDistributionType.FIRST) && ordinalNumber === 1) return true
    if (distribution.includes(ChallengeDistributionType.LAST) && ordinalNumber === length)
      return true
    if (distribution.includes(ChallengeDistributionType.EVEN) && ordinalNumber % 2 === 0)
      return true
    if (distribution.includes(ChallengeDistributionType.ODD) && ordinalNumber % 2 !== 0) return true
    if (distribution.includes(ChallengeDistributionType.EVERY_THIRD)) {
      const reference = length % 3
      if (ordinalNumber % 3 === reference) return true
    }
    return false
  }

  // Update checkpoint markers
  useEffect(() => {
    const checkpoints = Array.from(Array(numberOfCheckpoints))
    setMarkers(
      checkpoints.map((val, i) => checkpointHasChallenge(i + 1, checkpoints.length, distribution))
    )
  }, [distribution, numberOfCheckpoints, setValue])

  return (
    <Container>
      <Section label="Challenge distribution" tw="gap-6 pb-12">
        Which checkpoints should have a challenge?
        <Controller
          control={control}
          name="area.challenge.distribution"
          render={({ field: { value } }) => (
            <Options>
              <Option
                active={value.includes(EVERY)}
                onClick={() => addDistribution(value, EVERY, true)}
              >
                EVERY
              </Option>
              <Option active={value.includes(FIRST)} onClick={() => addDistribution(value, FIRST)}>
                FIRST
              </Option>
              <Option active={value.includes(LAST)} onClick={() => addDistribution(value, LAST)}>
                LAST
              </Option>
              <Option active={value.includes(EVEN)} onClick={() => addDistribution(value, EVEN)}>
                EVEN
              </Option>
              <Option active={value.includes(ODD)} onClick={() => addDistribution(value, ODD)}>
                ODD
              </Option>
              <Option
                active={value.includes(EVERY_THIRD)}
                onClick={() => addDistribution(value, EVERY_THIRD)}
              >
                EVERY THIRD
              </Option>
            </Options>
          )}
        />
      </Section>

      <ChallengePreview>
        <Label>Preview</Label>
        {!markers.length ? (
          <Info>Add some checkpoints to preview challenge distribution</Info>
        ) : (
          <>
            <MarkersContainer>
              <Markers markers={markers}>
                {markers.map((challenge, i) => (
                  <Marker key={i}>
                    {challenge ? (
                      <Icon.PinSolid size="xl" tw="text-amethyst" />
                    ) : (
                      <Icon.PinSolid size="xl" tw="text-white stroke[gray] stroke-width[0.7px]" />
                    )}
                    <Index>{i + 1}</Index>
                  </Marker>
                ))}
                {markers.length > 1 && <Timeline />}
              </Markers>
            </MarkersContainer>
            <Input.Label tw="place-self-start mt-4 gap-2">
              <b>Checkpoints with challenge:</b> <span>{checkpointsWithChallenges}</span>
            </Input.Label>
          </>
        )}
      </ChallengePreview>
    </Container>
  )
}

const Container = tw.div`flex flex-1 flex-col w-full`
const Options = styled.div`
  ${tw`grid grid-cols-3 gap-2`}
`
const Option = styled.div`
  ${tw`flex justify-center py-1 rounded-md shadow-md text-sm whitespace-nowrap 
  cursor-pointer hover:brightness-95 active:brightness-90 select-none`}
  ${({ active }) => (active ? tw`bg-amethyst text-white` : tw`bg-sky-200`)}
`
const ChallengePreview = tw.div`flex flex-col flex-1 justify-start items-start w-full py-6 px-10 bg-bismark-100`
const MarkersContainer = tw.div`flex flex-col w-full overflow-x-scroll`
const Markers = styled.div`
  ${tw`relative flex gap-7 w-min ml-4 mb-12`}
  ${({ markers = [] }) => (markers.length === 1 ? tw`justify-center` : tw`justify-between`)}
`
const Marker = tw.div`relative flex justify-center`
const Index = tw.div`absolute -bottom-5 text-xs`
const Label = tw(Input.Label)`pl-2 gap-1 py-3 text-lg font-semibold text-gray-700`
const Timeline = styled.div`
  ${tw`absolute w-full -bottom-7 border-b border-gray-500 text-xs`}
  &:before {
    content: 'Start';
    ${tw`absolute left-0 my-0.5`}
  }
  &:after {
    content: 'End';
    ${tw`absolute right-0 my-0.5`}
  }
`
