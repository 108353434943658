import React from 'react'
import { Navigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useUser } from '../../contexts/UserContext'
import { PageLayoutCol } from '../../styles/Layout'
import { ForgotPassword } from '../Auth/ForgotPassword'

const ForgotPasswordPage = () => {
  const { user } = useUser()

  return user ? (
    <Navigate to="/" />
  ) : (
    <Page>
      <ForgotPassword />
    </Page>
  )
}

export default ForgotPasswordPage
const Page = tw(PageLayoutCol)`items-center `
