import React, { createRef, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import tw, { css, styled, theme } from 'twin.macro'
import { Link } from 'react-router-dom'
import Icon from '../../styles/Icons'
import useBreakpoint from '../../hooks/useBreakpoint'
import RBAC from '../RBAC'
import { Role } from '../../enums'
import { useLocalStorage } from 'react-use'
import { useUser } from '../../contexts/UserContext'

export const Sidebar = () => {
  const sidebarRef = createRef()
  const location = useLocation()
  const { user } = useUser()
  const { belowTarget } = useBreakpoint('sm')
  const [sidebarOpen, setSidebarOpen] = useLocalStorage('sidebarOpen', true)
  const path = useMemo(() => location.pathname, [location.pathname])

  return (
    !!user && (
      <RBAC allow={[Role.CREATOR]}>
        <Container
          id="sidebar"
          belowTarget={belowTarget}
          expanded={sidebarOpen ?? true}
          ref={sidebarRef}
        >
          <LinkGroup>
            <NavItem
              to={path.startsWith('/playable-areas') ? '#' : '/playable-areas'}
              color={theme`colors.sky`}
              $current={path.startsWith('/playable-areas')}
              $expanded={sidebarOpen}
              $text={theme`colors.black`}
            >
              <Icon.Map />
              <Title>{'Areas'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/seasons') ? '#' : '/seasons'}
              color={theme`colors.amethyst`}
              $current={path.startsWith('/seasons')}
              $expanded={sidebarOpen}
            >
              <Icon.Calendar />
              <Title>{'Seasons'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/hvnts') ? '#' : '/hvnts'}
              color={theme`colors.matrix`}
              $current={path.startsWith('/hvnt')}
              $expanded={sidebarOpen}
            >
              <Icon.Flag />
              <Title>{'Hvnts'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/prizes') ? '#' : '/prizes'}
              color={theme`colors.terracotta`}
              $current={path.startsWith('/prizes')}
              data-testid="prize-link"
              $expanded={sidebarOpen}
            >
              <Icon.Gift />
              <Title>{'Prizes'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/checkpoints') ? '#' : '/checkpoints'}
              data-testid="checkpoint-link"
              color={theme`colors.gold`}
              $current={path.startsWith('/checkpoints')}
              $expanded={sidebarOpen}
              $text={theme`colors.black`}
            >
              <Icon.Pin />
              <Title>{'Checkpoints'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/challenges') ? '#' : '/challenges'}
              color={theme`colors.norway`}
              $current={path.startsWith('/challenges')}
              $expanded={sidebarOpen}
              $text={theme`colors.black`}
            >
              <Icon.Puzzle />
              <Title>{'Challenges'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/info-screens') ? '#' : '/info-screens'}
              color={theme`colors.sky.400`}
              $current={path.startsWith('/info-screens')}
              $expanded={sidebarOpen}
              $text={theme`colors.black`}
            >
              <Icon.Device />
              <Title>{'Info Screens'}</Title>
            </NavItem>

            <NavItem
              to={path.startsWith('/users') ? '#' : '/users'}
              color={theme`colors.amethyst.400`}
              $current={path.startsWith('/users')}
              $expanded={sidebarOpen}
              $text={theme`colors.black`}
            >
              <Icon.User />
              <Title>{'Users'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/locations') ? '#' : '/locations'}
              color={theme`colors.matrix.400`}
              $current={path.startsWith('/locations')}
              $expanded={sidebarOpen}
            >
              <Icon.Globe />
              <Title>{'Locations'}</Title>
            </NavItem>
            <NavItem
              to={path.startsWith('/corporate-events') ? '#' : '/corporate-events'}
              color={theme`colors.terracotta`}
              $current={path.startsWith('/corporate-events')}
              $expanded={sidebarOpen}
            >
              <Icon.Star />

              <Title>{'Events'}</Title>
            </NavItem>

            <RBAC allow={[Role.CREATOR, Role.EDITOR]}>
              <NavItem
                to={path.startsWith('/support') ? '#' : '/support'}
                color={theme`colors.black`}
                $current={path.startsWith('/support')}
                $expanded={sidebarOpen}
              >
                <Icon.Chat />

                <Title>{'Support'}</Title>
              </NavItem>
            </RBAC>
            {!belowTarget && (
              <ExpandButton as="div" onClick={() => setSidebarOpen(!sidebarOpen)}>
                {sidebarOpen ? <Icon.ChevronLeft /> : <Icon.ChevronRight />}
              </ExpandButton>
            )}
          </LinkGroup>
        </Container>
      </RBAC>
    )
  )
}
const Title = tw.div`absolute -left-40 whitespace-nowrap ml-2`
const Container = styled.div`
  ${tw`sticky bottom-0 flex flex-row sm:flex-col sm:h-full flex-shrink-0 w-full sm:w-min items-center justify-center sm:justify-start zIndex[998] 
  font-sans font-light bg-bismark-700 overflow-x-scroll`};
  ${({ belowTarget, expanded }) => css`
  ${({ expanded }) => !expanded && tw`overflow-visible`}
    & > ${LinkGroup} {
      ${belowTarget ? tw`w-full` : expanded ? tw`w-40` : tw`w-12`}
    }
    & > ${LinkGroup} > ${NavItem}::before {
      ${belowTarget && tw`h-0 border-b-4 -bottom-1 left-0 right-0`}
    }
    & > ${LinkGroup} * > ${Title} {
      ${belowTarget ? tw`hidden` : expanded ? tw`left-9 opacity-100` : tw`opacity-0`}
    }
    & > ${LinkGroup} > ${NavItem}:hover > ${Title} {
      ${!expanded && tw`opacity-100 left-9 py-2 px-3 rounded-r-md shadow-md`}
  `}
`
const LinkGroup = tw.div`flex flex-row sm:flex-col w-full sm:mt-24 transition-all duration-100 ease-in-out`
const NavItem = styled(Link).attrs(({ current }) => ({ current }))`
  ${tw`relative flex w-full items-center justify-start cursor-pointer p-2 select-none border-bismark-700 border-4
  bg-bismark-700 hover:bg-bismark-800 active:bg-bismark-900 text-white `}
  ${({ $current, color }) => css`
    ${$current
      ? css`
          border-color: ${color};
        `
      : css`
          &::before {
            border-color: ${color};
            content: '';
            ${tw`absolute h-full -left-1 border-l-4 `};
          }
        `}
  `}
  & > ${Title} {
    ${({ $expanded, color, $text }) =>
      $expanded
        ? tw`bg-none`
        : css`
            background-color: ${color};
            color: ${$text};
          `}
  }
`

const ExpandButton = tw(NavItem)`bg-bismark-600 border-none mt-1 justify-center`
