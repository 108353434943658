import React, { useCallback, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { db, functions } from '../../../lib/firebase'
import { Items } from './Items/XPItems'
import { Tab } from '../../../layout/Player'
import { FormProvider, useForm } from 'react-hook-form'
import { Alerts, Toast } from '../../../utility/alerts'
import { xpConverter } from '../../../utility/formatUserData'
import { usePlayer } from '../../../contexts/PlayerContext'
import { AdjustAmount } from '../AdjustAmount'
import useSort from '../../../hooks/useSort'
import { TransactionSorts } from '../../../enums'
import { List } from '../../../layout/List'
import { actionTypes } from '../../../reducers/playerReducer'
import { collection, orderBy, query } from 'firebase/firestore'
import { usePagination } from '../../../hooks/firebase/usePaginateFirestore'
import Paginator from '../../Paginator'

const orderByOptions = [
  { value: 'timestamp', label: 'timestamp' },
  { value: 'type', label: 'type' },
  { value: 'reason', label: 'reason' },
  { value: 'xp.amount', label: 'amount' }
]

export const XP = () => {
  const { activePlayer, dispatch, loading, setLoading } = usePlayer()
  const methods = useForm({ mode: 'onChange' })
  const { applySort, sortingBy, sortListBy } = useSort(TransactionSorts.TIMESTAMP)
  const { uid, userData: { level } = {}, xpEntries } = activePlayer
  const [sortedItems, setSortedItems] = useState(xpEntries)
  const [limit, setLimit] = useState(20)
  const [queryConstraints, setQueryConstraints] = useState([
    collection(db, 'users', uid, 'xp-log'),
    orderBy('timestamp', 'desc')
  ])

  const { items = [], ...pagination } = usePagination(
    query(...queryConstraints).withConverter(xpConverter),
    { limit }
  )

  // Sort items
  useEffect(() => {
    const sorted = applySort(items)
    setSortedItems(sorted)
    // Save xp locations
    dispatch({ type: actionTypes.activePlayerData.add, data: { xpLocations: sorted } })
  }, [applySort, dispatch, items])

  const updateQuery = useCallback(
    (order = []) => {
      const constraints = [collection(db, 'users', uid, 'xp-log'), orderBy(...order)]
      setQueryConstraints(constraints)
    },
    [uid]
  )

  // Adjust xp amount
  const onAdjustAmount = async ({ amount, comment, reward }) => {
    const { value: ok } = await Alerts.User.ADJUST_XP({ amount, reward })
    if (!ok) return
    try {
      setLoading(true)

      await functions.user.adjustXPAmount({
        amount: reward ? amount : amount * -1,
        uid,
        ...(comment && { comment })
      })

      methods.setValue('amount')
      methods.setValue('comment')

      Toast.fire({ title: 'XP updated!', icon: 'success' })
    } catch (err) {
      Alerts.User.ADJUST_FAILED(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  // Reverse xp entry
  const onReverse = async ({ id, xp }) => {
    const { value: ok } = await Alerts.User.REVERSE_TRANSACTION()
    if (!ok) return
    try {
      setLoading(true)
      await functions.user.adjustXPAmount({
        amount: xp.amount * -1,
        comment: 'XP reversed',
        reverse: true,
        ref: id,
        uid
      })

      Toast.fire({ title: 'XP updated!', icon: 'success' })
    } catch (err) {
      Alerts.User.ADJUST_FAILED(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <Tab.Container tw="gap-6">
        <Tab.SplitSection>
          <Amounts>
            <Tab.Col>
              <Tab.Header>Current level</Tab.Header>
              {`${level?.currentLevel ?? 0}`}
            </Tab.Col>
            <Tab.Col>
              <Tab.Header>Current xp</Tab.Header>
              {`${level?.totalXP ?? 0}`}
            </Tab.Col>
          </Amounts>
          <Tab.Col>
            <Tab.Header>Adjust xp</Tab.Header>
            <AdjustAmount
              currentAmount={level?.totalXP}
              onAdjustAmount={onAdjustAmount}
              loading={loading}
            />
          </Tab.Col>
        </Tab.SplitSection>

        <Tab.Section tw="overflow-y-hidden">
          <Tab.PaginatorHeader>
            {'Xp entries'}
            <Paginator.Firestore
              {...pagination}
              setLimit={setLimit}
              sort={{ options: orderByOptions, onUpdate: updateQuery }}
            />
          </Tab.PaginatorHeader>

          <List.Header>
            <List.HeaderData colSpan={1} tw="text-center">
              Index
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.TIMESTAMP}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.TIMESTAMP)}
              colSpan={3}
            >
              Timestamp
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.TYPE}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.TYPE)}
              colSpan={2}
            >
              Type
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.REASON}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.REASON)}
              colSpan={3}
            >
              Reason
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.XP_AMOUNT}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.XP_AMOUNT)}
              colSpan={2}
            >
              Amount
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.XP_NEW}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.XP_NEW)}
              colSpan={2}
            >
              New XP
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.LEVEL_NEW}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.LEVEL_NEW)}
              colSpan={2}
            >
              New Level
            </List.HeaderData>
          </List.Header>

          <List.Container>
            {sortedItems?.length ? (
              <Items items={sortedItems} onReverse={onReverse} path={activePlayer.path} />
            ) : (
              <Empty>Nothing here</Empty>
            )}
          </List.Container>
        </Tab.Section>
      </Tab.Container>
    </FormProvider>
  )
}

const Amounts = tw.div`flex gap-6`
const Empty = tw.div`flex justify-center items-center p-4 text-sm text-gray-700`
