import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  update: 'UPDATE_HVNT_LIST',
  item: {
    select: 'SELECT_HVNT',
    unselect: 'UNSELECT_HVNT',
    selectAll: 'SELECT_ALL_HVNTS',
    selectNone: 'UNSELECT_ALL_HVNTS'
  }
}

export const hvntListReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.update: {
      const { hvnts } = action
      return hvnts.map((hvnt, i) => ({ ...hvnt, index: i }))
    }
    case actionTypes.item.select: {
      const { hvnt } = action
      return state.map((item) => (item.id === hvnt.id ? { ...item, selected: true } : item))
    }
    case actionTypes.item.unselect: {
      const { hvnt } = action
      return state.map((item) => (item.id === hvnt.id ? { ...item, selected: false } : item))
    }
    case actionTypes.item.selectAll: {
      const { hvnts } = action
      const selected = hvnts.map((item) => ({ ...item, selected: true }))
      return uniqBy([...selected, ...state], 'id')
    }
    case actionTypes.item.selectNone: {
      const { hvnts } = action
      const selected = hvnts.map((item) => ({ ...item, selected: false }))
      return uniqBy([...selected, ...state], 'id')
    }
    default:
      return state
  }
}
