import React, { useState } from 'react'
import tw, { styled, css } from 'twin.macro'
import { Button } from '../../../../styles/Layout'
import { format } from 'date-fns'
import Icon from '../../../../styles/Icons'
import config from '../../../../config'
import { ATag, UserTag } from '../../../../styles/Link'
import { Item } from '../../../../layout/List'

export const Items = ({ items, onReverseTransaction, uid }) => {
  const [expandedItem, setExpandedItem] = useState()

  const onClickItem = (transaction, opened) => {
    console.log({ transaction })
    opened ? setExpandedItem(transaction) : setExpandedItem()
  }

  return items.map((transaction, i) => (
    <TransactionItem
      key={i}
      expanded={expandedItem?.id === transaction?.id}
      index={i}
      item={transaction}
      onClick={onClickItem}
      onReverseTransaction={onReverseTransaction}
      uid={uid}
    />
  ))
}

const TransactionItem = ({ expanded, index, item, onClick, onReverseTransaction, uid }) => {
  const { amount, id, metadata, newBalance, oldBalance, reason, timestamp, type } = item
  const { adjustedBy, comment, transactionRef } = metadata

  return (
    <Item.Container>
      <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
        <Item.HeaderData colSpan={1}>
          <Item.Center>{index}</Item.Center>
        </Item.HeaderData>
        <Item.HeaderData colSpan={3}>
          {timestamp ? format(timestamp, 'LLL do yyyy, HH:mm') : '-'}
        </Item.HeaderData>
        <Item.HeaderData colSpan={2}>{type}</Item.HeaderData>
        <Item.HeaderData colSpan={3}>{reason}</Item.HeaderData>
        <AmountData colSpan={3} type={type}>
          {amount}
        </AmountData>
        <Item.HeaderData colSpan={3}>{newBalance}&nbsp;coins</Item.HeaderData>
      </Item.Header>

      <Item.Summary expanded={expanded} height="auto">
        <Item.SummaryGrid>
          <Item.Col>
            <Item.Bold>{'Id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/users/${uid}/transaction-log/${id}`}>
              {id}
            </ATag>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Type:'}</Item.Bold>
            <Item.Text>
              <pre>{type}</pre>
            </Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Reason:'}</Item.Bold>
            <Item.Text>
              <pre>{reason}</pre>
            </Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Old balance:'}</Item.Bold>
            <Item.Text>{oldBalance} coins</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Amount:'}</Item.Bold>
            <Item.Text>{amount} coins</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'New balance:'}</Item.Bold>
            <Item.Text>{newBalance} coins</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Timestamp:'}</Item.Bold>
            <Item.Text>{timestamp ? format(timestamp, 'PP HH:mm:ss') : '-'}</Item.Text>
          </Item.Col>
          {transactionRef && (
            <Item.Col>
              <Item.Bold>{'Reference:'}</Item.Bold>
              <Item.Text>{transactionRef}</Item.Text>
            </Item.Col>
          )}
          {adjustedBy && (
            <Item.Col>
              <Item.Bold>{'Adjusted by:'}</Item.Bold>
              <UserTag to={`/users?id=${adjustedBy}`}>{adjustedBy}</UserTag>
            </Item.Col>
          )}
          <Item.Col>
            <Item.Bold>{'Comment:'}</Item.Bold>
            <Item.Text>{comment ? `"${comment}"` : '–'}</Item.Text>
          </Item.Col>
        </Item.SummaryGrid>
        <Item.ActionButtons>
          <Button.Secondary size="sm" onClick={() => onReverseTransaction(item)}>
            <Icon.Refresh size="sm" mr="1" />
            Reverse
          </Button.Secondary>
        </Item.ActionButtons>
      </Item.Summary>
    </Item.Container>
  )
}

const AmountData = styled(Item.HeaderData)`
  ${tw`font-semibold`}
  ${({ type }) =>
    type === 'DEBIT'
      ? tw`text-red`
      : css`
          ${tw`text-green`}
          &:before {
            content: '+';
          }
        `}
`
