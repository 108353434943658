import React, { useMemo, useState } from 'react'
import { ChallengeItem } from './ChallengeItem'

export const Items = ({ items = [] }) => {
  const [expandedItem, setExpandedItem] = useState()

  const onClickItem = (challenge, opened) => {
    console.log({ challenge })
    opened ? setExpandedItem(challenge) : setExpandedItem()
  }

  return useMemo(
    () =>
      items.map((item) => (
        <ChallengeItem
          key={item.id}
          item={item}
          onClick={onClickItem}
          expanded={expandedItem?.id === item.id}
        />
      )),
    [expandedItem?.id, items]
  )
}
