import circle from '@turf/circle'
import axios from 'axios'
import { geohashForLocation } from 'geofire-common'
import { useCallback } from 'react'
import request from '../request'

const format = Object.freeze({
  fromPoi: ({ locations }) =>
    locations.map(({ center, context, geometry, id, place_name, properties, type, ...data }) => {
      const [longitude, latitude] = center
      return {
        centerPoint: { longitude, latitude },
        color: 'gold', // for map styles
        data: {
          ...data,
          ...(place_name && { name: place_name }),
          ...properties
        },
        geohash: geohashForLocation([parseFloat(latitude), parseFloat(longitude)], 10),
        locationSource: 'mapbox',
        playable: false // todo determine by category?
      }
    })
})

const useMapbox = () => {
  const getFeatureTags = useCallback(
    async ({ latitude, layers, limit = 50, longitude, radius = 10 }) => {
      const tileset_id = 'mapbox.mapbox-streets-v8'

      const data = await request.getTileFeaturesFromCoordinates({
        latitude,
        layers,
        limit,
        longitude,
        tileset_id,
        radius
      })

      return data
    },
    []
  )

  const getNearbyPOIs = useCallback(async ({ centerPoint }) => {
    try {
      // Returns a geojson
      const { features } = await request.getPOIs({ centerPoint })
      return format.fromPoi({ locations: features })
    } catch (err) {
      console.error(err)
    }
  }, [])

  const getDatasetFeature = useCallback(async ({ dataset_id, feature_id }) => {
    const { data } = await axios.get(
      `https://api.mapbox.com/datasets/v1/hvnt/${dataset_id}/features/${feature_id}?access_token=${process.env.REACT_APP_MAPBOX_SECRET}`
    )
    return data
  }, [])

  const generateStaticImageUrl = useCallback(async ({ centerPoint, radius }) => {
    try {
      const { latitude, longitude } = centerPoint
      const hvntCircle = circle([longitude, latitude], radius, {
        units: 'meters',
        properties: {
          fill: '#f9c263',
          'fill-opacity': '0.2',
          stroke: '#f9c263',
          'stroke-width': '4'
        }
      })
      // Replaces # with %23
      // https://docs.mapbox.com/api/maps/static-images/#overlay-options
      const hvntFeature = encodeURI(JSON.stringify(hvntCircle)).replace(/#/g, '%23')

      const zoom =
        radius > 2500
          ? 11.7
          : radius > 2000
          ? 12
          : radius > 1400
          ? 12.5
          : radius > 800
          ? 13
          : radius > 500
          ? 13.5
          : 14
      const imageData = await request.getStaticHvntMap({ hvntFeature, latitude, longitude, zoom })
      // Upload the image
      return imageData
    } catch (err) {
      console.error(err)
    }
  }, [])

  return { getFeatureTags, getNearbyPOIs, getDatasetFeature, generateStaticImageUrl }
}
export default useMapbox
