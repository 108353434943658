import React, { Fragment, useMemo, useState } from 'react'
import tw, { theme } from 'twin.macro'
import config from '../../../../config'
import { ChallengeType } from '../../../../enums'
import { Item } from '../../../../layout/List'
import Icon from '../../../../styles/Icons'
import { Status } from '../../../../styles/Layout'
import { ATag } from '../../../../styles/Link'

export const Items = ({ items }) => {
  const [expandedItem, setExpandedItem] = useState()

  const onClickItem = (challenge, opened) => {
    console.log({ challenge })
    opened ? setExpandedItem(challenge) : setExpandedItem()
  }

  return useMemo(
    () =>
      items.map((item) => (
        <ChallengeItem
          key={item.id}
          item={item}
          onClick={onClickItem}
          expanded={expandedItem?.id === item?.id}
        />
      )),
    [expandedItem?.id, items]
  )
}

export const ChallengeItem = ({ expanded, item, onClick }) => {
  const { active, id, question, type } = item

  return (
    <Item.Container>
      <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
        <Item.HeaderData colSpan={1}>
          <Item.Center>
            <Status active={active} />
          </Item.Center>
        </Item.HeaderData>
        <Item.HeaderData colSpan={1}>{type}</Item.HeaderData>
        <Item.HeaderData colSpan={5}>{question}</Item.HeaderData>
      </Item.Header>

      <Item.Summary expanded={expanded}>
        <Item.SummaryList>
          <Item.Row>
            <Item.Bold>{'Id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/challenges/${id}`}>{id}</ATag>
          </Item.Row>

          {ChallengeSummary(item)}
        </Item.SummaryList>
      </Item.Summary>
    </Item.Container>
  )
}

export const ChallengeSummary = (item) => {
  const { options = [], question, triviaType, type } = item

  switch (type) {
    case ChallengeType.TRIVIA: {
      return (
        <>
          <Item.Row>
            <Item.Bold>{'Trivia Type:'}</Item.Bold>
            <pre>{triviaType}</pre>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Question:'}</Item.Bold>
            <Item.Text>{question}</Item.Text>
          </Item.Row>

          <Item.InsetList>
            {options.map(({ correct, text }, i) => (
              <Fragment key={i}>
                <Item.Row>
                  <Item.Bold>{`• Option ${i + 1}`}</Item.Bold>
                  <OptionText>
                    {correct ? (
                      <Icon.Check size="xs" color={theme`colors.green`} />
                    ) : (
                      <Icon.Close size="xs" color={theme`colors.red`} />
                    )}
                    {text}
                  </OptionText>
                </Item.Row>
              </Fragment>
            ))}
          </Item.InsetList>
        </>
      )
    }
  }
}

const OptionText = tw(Item.Text)`inline-flex gap-2`
