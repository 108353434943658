import React from 'react'
import tw, { theme } from 'twin.macro'
import config from '../../config'
import { Button, IconButton } from '../../styles/Layout'
import { useUser } from '../../contexts/UserContext'
import Icon from '../../styles/Icons'
import { useNavigate } from 'react-router-dom'
import { Alerts } from '../../utility/alerts'

export const ProfileIcon = () => {
  const { user, signout } = useUser()
  const navigate = useNavigate()
  const onSignOut = async () => {
    {
      const { value: ok } = await Alerts.Auth.SIGNOUT_WARING()
      if (ok) return await signout()
    }
  }
  user.displayName?.split(' ')[0]
  return (
    <Container>
      <ProfileIconContainer>
        <IconButton
          size="sm"
          bottom={true}
          color={theme`colors.bismark.600`}
          icon={<Image src={user?.photoURL ?? config.user.DEFAULT_USER_IMAGE} />}
          content={
            <>
              <Button.Secondary
                ring
                size="sm"
                onClick={() => navigate('/user-account')}
                tw="mb-auto"
              >
                <Icon.Eye size="sm" mr="1" />
                View Account
              </Button.Secondary>
              <Button.Primary ring size="sm" onClick={() => onSignOut()}>
                <Icon.Moon size="sm" mr="1" />
                Sign Out
              </Button.Primary>
            </>
          }
        />
      </ProfileIconContainer>
    </Container>
  )
}

const Container = tw.div`flex gap-2 z-50`
const ProfileIconContainer = tw.div`w-10 justify-center items-center rounded-full`
const Image = tw.img`rounded-full ring ring-bismark-500 hover:ring-bismark-200`
