import React, { useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Info, Input } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { Loader } from '../Loader/Loader'
import { useIsMounted } from '../../hooks/useIsMounted'
import { createClueFromType } from '../../utility/generateClues'
import { Toast } from '../../utility/alerts'
import { useUpdateEffect } from '../../hooks/useUpdateEffect'
import { useClue } from '../../contexts/ClueContext'
import { Switch } from '../Layout/Switch'
import { TREASURE_ZOOM_OPTIONS } from '../../utility/labeledOptions'
import Dropdown from '../Dropdown'

export const TreasureMapForm = ({ checkpoint }) => {
  const {
    control,
    register,
    setError,
    setValue,
    trigger,
    watch,
    formState: { errors }
  } = useFormContext()
  const { centerPoint, path } = checkpoint
  const { mounted } = useIsMounted()
  const { displayImage } = useClue()
  const { difficulty, imageData, imageUrl, rotated, zoom } = watch()
  const [loading, setLoading] = useState(false)

  // Invalidate form if values are changed after image exists
  useUpdateEffect(() => {
    setValue('imageUrl')
    setValue('imageData')
    if (imageData || imageUrl)
      setError('imageUrl', { type: 'manual', message: 'You need to generate a new map!' })
  }, [rotated, zoom])

  const onCreateTreasureMap = useCallback(async () => {
    setLoading(true)
    try {
      const treasure = await mounted(
        createClueFromType('treasure', path, {
          auto: false,
          centerPoint,
          rotated,
          difficulty,
          zoom
        })
      )
      if (treasure) {
        const { imageData } = treasure // Exctract anything useful here
        setValue('imageData', imageData)
        trigger('imageUrl')
      }
    } catch (err) {
      setError('imageUrl', { type: 'manual', message: err })
      setValue('imageData')
      setValue('imageUrl')
      Toast.fire({
        title: 'Failed to create treasure map',
        icon: 'error'
      })
    } finally {
      setLoading(false)
    }
  }, [
    centerPoint,
    difficulty,
    mounted,
    path,
    rotated,
    setError,
    setLoading,
    setValue,
    trigger,
    zoom
  ])

  return (
    <>
      <Section>
        <Input.Label required={true}>Zoom level</Input.Label>
        <Controller
          control={control}
          name="zoom"
          render={({ field }) => <Dropdown.Select {...field} options={TREASURE_ZOOM_OPTIONS} />}
          rules={{ required: 'Zoom is required' }}
        />
        <Input.Error>{errors?.zoom?.message}</Input.Error>
      </Section>
      <Section>
        <Controller
          control={control}
          name="rotated"
          render={({ field: { value, onChange } }) => (
            <Switch
              color="blue"
              defaultChecked={value}
              onToggle={onChange}
              label="Randomly rotate north"
            />
          )}
        />
      </Section>
      <Section>
        <div tw="flex justify-center">
          <Button.Primary disabled={!centerPoint || loading || !zoom} onClick={onCreateTreasureMap}>
            <Icon.Star mr="2" />
            {(imageData || imageUrl ? 'Regenerate' : 'Generate') + ' Map'}
          </Button.Primary>
        </div>
      </Section>

      <ImageSection
        {...register('imageUrl')}
        {...register('imageData', { shouldUnregister: true })}
      >
        <Loader loading={loading} />
        {!centerPoint ? (
          <Info>Coordinates must be set to create a treasure map</Info>
        ) : errors?.imageUrl ? (
          <Info>{errors?.imageUrl?.message}</Info>
        ) : imageData ? (
          <ImageWrapper>
            <Image src={imageData} alt="Treasure" onClick={() => displayImage({ imageData })} />
          </ImageWrapper>
        ) : (
          imageUrl && (
            <ImageWrapper>
              <Image src={imageUrl} alt="Treasure" onClick={() => displayImage({ imageUrl })} />
            </ImageWrapper>
          )
        )}
      </ImageSection>
    </>
  )
}

const Section = tw.div`flex flex-col my-2 px-4 w-full`
const ImageSection = tw.div`relative flex flex-1 items-start justify-center my-2 mx-4`
const ImageWrapper = tw.div`flex bg-gray-200 rounded-md overflow-hidden`
const Image = tw.img`w-full h-full cursor-pointer`
