import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect
} from 'react'
import useGetInfoScreens from '../hooks/firebase/useGetInfoScreens'
import { db, FieldValue } from '../lib/firebase'
import { infoScreenReducer } from '../reducers/infoScreenReducer'
import { Alerts, Toast } from '../utility/alerts'
import { infoScreenConverter } from '../utility/formatInfoScreens'

export const InfoScreenContext = createContext()

export const InfoScreenProvider = ({ children }) => {
  const [infoScreens, dispatch] = useReducer(infoScreenReducer, [])
  const { onNextInfoScreens } = useGetInfoScreens(dispatch)

  const [adding, setAdding] = useState(false)
  const [expandedItem, setExpandedItem] = useState()
  const [loading, setLoading] = useState(false)
  const [infoScreenBeingEdited, setInfoScreenBeingEdited] = useState()
  const [validateForm, setValidateForm] = useState(false)

  // Add new info-screen
  const addNewInfoScreen = useCallback(() => {
    // Create new document
    const newDoc = db.collection('info-screens').doc()
    setInfoScreenBeingEdited({ id: newDoc.id, path: newDoc.path })
    setAdding(true)
  }, [])

  // Start editing info-screen
  const startEditingInfoScreen = useCallback((infoScreen) => {
    setInfoScreenBeingEdited(infoScreen)
    setAdding(false)
    setValidateForm(infoScreen.status?.validate ?? infoScreen.active ?? true)
  }, [])

  // Stop editing info-screen
  const stopEditingInfoScreen = useCallback(() => {
    setInfoScreenBeingEdited()
    setAdding(false)
    resetDefaults()
  }, [])

  // Save info-screen to firestore
  const saveInfoScreen = useCallback(
    async (infoScreen) => {
      try {
        const { path } = infoScreen
        if (!path) throw new Error('Missing path')
        setLoading(true)

        const infoScreenRef = db.doc(path)

        await infoScreenRef.withConverter(infoScreenConverter).set(
          {
            ...infoScreen,
            ...(adding && { createdAt: FieldValue.serverTimestamp() })
          },
          { merge: true }
        )

        Alerts.InfoScreen.SAVE_SUCCESS(adding)

        stopEditingInfoScreen()
      } catch (err) {
        console.error(err)
        Alerts.InfoScreen.UPDATE_FAILED(adding, err)
      } finally {
        setLoading(false)
      }
    },
    [adding, stopEditingInfoScreen]
  )

  // Delete or deactivate info-screen
  const deleteInfoScreen = useCallback(
    async (infoScreen) => {
      if (!infoScreenBeingEdited) return
      try {
        setLoading(true)
        const { path } = infoScreen
        db.doc(path).delete()
        Toast.fire({ title: 'Info Screen Removed!', icon: 'success' })
      } catch (err) {
        Alerts.Location.SAVE_FAILED(err)
        return console.error(err)
      } finally {
        setLoading(false)
      }
      stopEditingInfoScreen()
    },
    [infoScreenBeingEdited, stopEditingInfoScreen]
  )

  const resetDefaults = () => {
    setAdding(false)
    setLoading(false)
    setValidateForm(false)
  }

  // Reset values
  useEffect(() => {
    if (!infoScreenBeingEdited) resetDefaults()
  }, [infoScreenBeingEdited])

  // Subscribe to info-screens
  useEffect(() => {
    const unsubscribe = db
      .collection('info-screens')
      .withConverter(infoScreenConverter)
      .onSnapshot(onNextInfoScreens)
    return unsubscribe
  }, [onNextInfoScreens])

  const defaultValues = useMemo(
    () => ({
      adding,
      addNewInfoScreen,
      deleteInfoScreen,
      expandedItem,
      infoScreenBeingEdited,
      infoScreens,
      loading,
      saveInfoScreen,
      setExpandedItem,
      setValidateForm,
      startEditingInfoScreen,
      stopEditingInfoScreen,
      validateForm
    }),
    [
      adding,
      addNewInfoScreen,
      deleteInfoScreen,
      expandedItem,
      infoScreenBeingEdited,
      infoScreens,
      loading,
      saveInfoScreen,
      startEditingInfoScreen,
      stopEditingInfoScreen,
      validateForm
    ]
  )

  return <InfoScreenContext.Provider value={defaultValues}>{children}</InfoScreenContext.Provider>
}

// Hook
export const useInfoScreen = () => {
  const context = useContext(InfoScreenContext)
  if (context === undefined)
    throw new Error('`useInfoScreen` hook must be used within a `InfoScreenProvider` component')
  return context
}
