import { useCallback } from 'react'
import { functions } from '../lib/firebase'

const useCloudinary = () => {
  const uploadImage = useCallback(async ({ file, id, path }) => {
    try {
      const { data: imageUrl } = await functions.image.cloudinaryUpload({ file, id, path })
      return imageUrl
    } catch (err) {
      throw new Error(err)
    }
  }, [])

  return { uploadImage }
}
export default useCloudinary
