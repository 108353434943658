import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useSeason } from '../../contexts/SeasonsContext'
import { PageLayout } from '../../styles/Layout'
import { CreateSeason } from '../Season/CreateSeason'
import { SeasonList } from '../Season/SeasonList'
import { SeasonsMap } from '../Season/Map/SeasonsMap'
import { MapProvider } from '../../contexts/MapContext'

const SeasonsPage = () => {
  const { adding, seasonBeingEdited } = useSeason()
  const navigate = useNavigate()

  // Update Routes through side effects
  useEffect(() => {
    if (seasonBeingEdited)
      navigate(adding ? 'create' : `${seasonBeingEdited.id}`, { replace: true })
    else navigate('', { replace: true })
  }, [adding, navigate, seasonBeingEdited])

  return (
    <MapProvider>
      <Routes>
        <Route
          path="/"
          element={
            <Page>
              <ListContainer>
                <SeasonList />
              </ListContainer>
              <MapContainer>
                <SeasonsMap />
              </MapContainer>
            </Page>
          }
        />
        <Route
          path="/*"
          element={
            <Page>
              <CreateSeason season={seasonBeingEdited} />
            </Page>
          }
        />
      </Routes>
    </MapProvider>
  )
}

export default SeasonsPage

const Page = tw(PageLayout)`flex-col lg:flex-row`
const ListContainer = tw.div`flex flex-col w-full lg:w-5/12 h-full`
const MapContainer = tw.div`relative flex justify-center overflow-hidden w-full lg:w-7/12 h-full minHeight[50vh] transition-all duration-300`
