// https://react-select.com/styles#overriding-the-theme
import tw, { theme } from 'twin.macro'

export const CreatableTheme = (t) => ({
  ...t,
  borderRadius: 6,
  colors: {
    ...t.colors,
    danger: theme`colors.matrix`, // multi hover x
    dangerLight: theme`colors.bismark.300`, // multi hover x-background
    primary: theme`colors.bismark.400`, //    list item selected
    primary25: theme`colors.bismark.200`, //  list item hover
    primary50: theme`colors.bismark.300`, //  list item click
    neutral0: theme`colors.white.pure`, // background
    neutral5: theme`colors.white.pure`, // background disabled
    neutral10: theme`colors.bismark.200`, // separator disabled, multi values
    neutral20: theme`colors.bismark.300`, //  border, x, separator, arrow
    neutral30: theme`colors.bismark.400`, //   border, x, separator, arrow hover
    neutral40: theme`colors.bismark.400`, //  arrow hover,
    neutral50: theme`colors.gray.400`, //  text placeholder
    neutral60: theme`colors.bismark.400`, // arrow active hovering
    neutral80: theme`colors.gray.900` //  text filled
  },
  spacing: {
    baseUnit: 3,
    controlHeight: 40
  }
})

export const CreatableStyle = {
  container: (style, { isDisabled }) => ({
    ...style,
    ...tw`flex min-height[2.25rem] border-0`,
    ...(isDisabled && { ...tw`opacity-40` })
  }),
  control: (style) => ({
    ...style,
    ...tw`flex items-center cursor-pointer border w-full min-height[auto] box-shadow[none]`
  }),
  menu: (style) => ({
    ...style,
    ...tw`mt-1 text-left border border-bismark-400 overflow-hidden box-shadow[none]`
  }),
  option: (style) => ({
    ...style,
    ...tw`cursor-pointer`
  }),
  valueContainer: (style) => ({
    ...style,
    ...tw`text-matrix`
  })
}
