import React, { useCallback, useState } from 'react'
import tw from 'twin.macro'
import { useFormContext } from 'react-hook-form'
import { Button, Info, Input } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { Loader } from '../Loader/Loader'
import { useClue } from '../../contexts/ClueContext'
import { functions } from '../../lib/firebase'

export const StreetViewForm = ({ checkpoint, id }) => {
  const {
    formState: { errors },
    register,
    setError,
    setValue,
    trigger,
    watch
  } = useFormContext()

  const { displayImage } = useClue()
  const { imageText, imageUrl, metadata } = watch()
  const [loading, setLoading] = useState(false)

  const onCreateStreetView = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await functions.clue.createStreetView({
        centerPoint: checkpoint.centerPoint,
        id
      })
      if (data) {
        const { active, difficulty, id, imageText, imageUrl, type, ...clue } = data

        setValue('imageText', imageText)
        setValue('imageUrl', imageUrl)
        trigger(['imageText', 'imageUrl'])
        // Save temaining fields as metadata
        for (let key in clue) {
          setValue(`metadata[${key}]`, clue[key])
        }
      }
    } catch (err) {
      console.error(err)
      setError('imageUrl', { type: 'manual', message: err.message })
      setValue('imageUrl')
    } finally {
      setLoading(false)
    }
  }, [checkpoint.centerPoint, id, setError, setValue, trigger])

  return (
    <>
      <Section>
        <Info>
          <span>
            {'We will try to generate a streetview as close to the checkpoint as possible'}
          </span>
          <span>{'Maximum possible distance is 150 m away'}</span>
        </Info>
      </Section>
      {!!imageUrl && (
        <>
          <Section>
            <Input.Label>Image Text</Input.Label>
            <Input.Default
              {...register('imageText', {
                maxLength: {
                  value: 80,
                  message: 'This cannot be longer than 80 characters'
                }
              })}
              placeholder="Optional Hint [Max 80 characters]"
              size="md"
              textarea
            />
            <Input.Error>{errors?.imageText?.message}</Input.Error>
          </Section>
        </>
      )}

      <ButtonsSection>
        <Button.Primary
          ring
          disabled={!checkpoint.centerPoint || loading}
          onClick={onCreateStreetView}
        >
          {imageUrl ? <Icon.Refresh mr="2" /> : <Icon.Star mr="2" />}
          {imageUrl ? 'Regenerate Panorama Image' : 'Generate Panorama Image'}
        </Button.Primary>
      </ButtonsSection>
      <Section tw="items-center">
        {!checkpoint.centerPoint ? (
          <Input.Error>Coordinates must be set to create a streetview image</Input.Error>
        ) : (
          errors?.imageUrl && <Input.Error>{errors?.imageUrl?.message}</Input.Error>
        )}
      </Section>
      <ImageSection {...register('imageUrl')}>
        <Loader loading={!imageUrl && loading} text="Generating Streetview Image" />
        {imageUrl && (
          <>
            <Section tw="flex-col gap-1 text-gray-700 text-sm">
              <ImageWrapper>
                <Loader loading={loading} text="Regenerating Street View Image" />
                <Image
                  src={imageUrl}
                  alt="streetview"
                  onClick={() => displayImage({ imageText, imageUrl })}
                />
              </ImageWrapper>
              <div tw="font-mono">
                {`Actual Distance: ${Math.round(metadata?.distance * 100) / 100} m`}
              </div>
            </Section>
          </>
        )}
      </ImageSection>
    </>
  )
}
const Section = tw.div`flex flex-col my-2 px-4 w-full`
const ButtonsSection = tw(Section)`flex-row justify-center whitespace-nowrap gap-3`
const ImageSection = tw.div`relative flex flex-1 items-start justify-center my-2 mx-4`
const ImageWrapper = tw.div`relative flex bg-gray-200 rounded-md overflow-hidden`
const Image = tw.img`w-full h-full cursor-pointer`
