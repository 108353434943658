export class HvntPrizeError extends Error {
  constructor({ message, validation }) {
    super(message)
    this.validation = validation ?? message
    this.field = 'prize'
  }
}

export class ImageUrlError extends Error {
  constructor(message) {
    super(message)
    this.field = 'imageUrl'
  }
}

export class AlertError extends Error {
  constructor(alert) {
    super(alert)
    this.type = 'alert'
    this.alert = alert
  }
}
export class DismissError extends Error {
  constructor(message) {
    super(message)
    this.dismiss = true
  }
}
