import React, { useMemo } from 'react'
import { ClueType } from '../../../enums'
import tw, { css, theme } from 'twin.macro'

const Container = tw.div`relative flex flex-col w-full shadow-md font-sans font-light`
const Row = tw.div`flex relative text-sm justify-between items-center bg-bismark-200 p-1 pr-2`
const Summary = tw.div`flex shadow-inner bg-white-pure overflow-scroll text-gray-900 gap-2`
const SummaryText = tw.div`flex flex-col flex-1 m-2 gap-2`
const Image = tw.img`max-width[10rem] object-contain cursor-pointer`
const ButtonData = tw.div`flex items-center gap-1`
const Text = tw.div`text-sm break-words`
const Header = tw.div`flex flex-1 gap-2 items-center font-semibold text-bismark-600 px-2 py-1 truncate`

const ClueContent = ({ clue, onClick, children }) => {
  const hasImage = useMemo(() => !!clue?.imageData || !!clue?.imageUrl, [clue])
  const { difficulty, imageData, imageText, imageUrl, radius, rotated, text } = clue

  return (
    <Summary onClick={() => onClick(clue)}>
      {hasImage && <Image src={imageData ?? imageUrl} />}

      <SummaryText>
        {difficulty && (
          <Text tw="text-gray-600">{`Difficulty: ${difficulty?.label.split(':')[0]}`}</Text>
        )}
        {rotated && <Text>{'North Rotated'}</Text>}
        {radius && <Text>{`Radius: ${radius?.value ?? radius}m`}</Text>}
        {text && <Text tw="whitespace-pre-wrap">&quot;{text}&quot;</Text>}
        {imageText && <Text>&quot;{imageText}&quot;</Text>}
      </SummaryText>

      {children}
    </Summary>
  )
}

function setHeader(clue) {
  const { placeType, type } = clue
  switch (type?.value) {
    case ClueType.TEXT:
      return 'TEXT CLUE'
    case ClueType.AREA:
      return 'AREA CLUE'
    case ClueType.IMAGE:
      return 'IMAGE CLUE'
    case ClueType.TREASURE:
      return 'TREASURE MAP'
    case ClueType.POI:
      return placeType?.toUpperCase() || 'POI CLUE'
    case ClueType.STREET_VIEW_OLD:
      return 'STREET VIEW CLUE'
    case ClueType.STREET_VIEW:
      return 'STREET VIEW PANORAMA'
    default:
      return 'BROKEN CLUE'
  }
}

function setHeaderAttribute({ clue }) {
  if (clue.free)
    return css`
      color: ${theme`colors.green.700`};
      content: 'FREE';
    `
  if (clue.fromMercator)
    return css`
      color: ${theme`colors.amethyst.600`};
      content: 'MERCATOR';
    `
  if (clue.auto)
    return css`
      color: ${theme`colors.gold.600`};
      content: 'AUTO';
    `
  return css`
    content: '';
  `
}

export default {
  ButtonData,
  Container,
  Header,
  Image,
  Row,
  Text,
  setHeader,
  setHeaderAttribute,
  ClueContent,
  Summary,
  SummaryText
}
