import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import tw from 'twin.macro'
import { Map, Layer, NavigationControl, Source } from 'react-map-gl'
import { Loader } from '../Loader/Loader'
import config from '../../config'
import { MapControl } from '../Map/MapControl'
import { GeolocateControl } from 'react-map-gl'
import { useLocations } from '../../contexts/LocationsContext'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useMap } from '../../contexts/MapContext'
import { ScanCircle } from './ScanCircle'
import { LocationCells } from './LocationCells'
import { mapLayers } from '../Map/MapLayers'
import { arrayToFeatures } from '../../utility/helperFunctions'
import useS2Cells from '../../hooks/useS2Cells'
import { useMapControl } from '../../hooks/useMapControl'
import { useDebounce } from 'react-use'
import { useUpdateEffect } from '../../hooks/useUpdateEffect'
import { CellScanMode, LocationTab, S2CellLevel } from '../../enums'
import { LayerVisibilityControl } from '../Map/LayerVisibilityControl'
import { ScanInfo } from './ScanInfo'
import { Overlay } from '../../layout/Map'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClearData } from './ClearData'
import { MapSwitcher } from '../Map/MapSwitcher'
import { useAreas } from '../../contexts/AreasContext'
import { useMapLayer } from '../../hooks/useMapLayer'
import { GeocoderControl } from '../Map/GeocoderControl'
import useMapbox from '../../hooks/useMapbox'
import { useUsa } from '../../contexts/UsaCellsContext'

export const LocationsMap = () => {
  const mapRef = useRef(null)
  const { focusedBbox, focusOnFeature, focusOnFeatures, setLayerVisibility } = useMap()
  const {
    activeTab,
    addCells,
    candidateCellData,
    dragging,
    loading,
    locationCellData,
    locations = [],
    playableCellData,
    removeCells
  } = useLocations()
  const { activeState, setActiveState } = useUsa()
  const { activeCountry, countries } = useAreas()
  const { isMounted } = useIsMounted()
  const { getLevel12Children, getS2RectFromCenter } = useS2Cells()
  const { containerRef, zoomToBbox } = useMapControl(mapRef)
  const { colorCountries, colorCountryBorders } = useMapLayer(mapRef)
  const { getDatasetFeature } = useMapbox()

  const [busy, setBusy] = useState(true)
  const [cursor, setCursor] = useState('auto')
  const [hoveredFeature, setHoveredFeature] = useState({ id: null, source: null })
  const [mapStyle, setMapStyle] = useState(config.mapbox.ALTERNATE_MAP)

  const [viewstate, setViewstate] = useState({
    longitude: 18.078419,
    latitude: 59.342644,
    maxZoom: 15,
    zoom: 3
  })

  const { control, setValue } = useFormContext()
  const { s2Level, scanMode } = useWatch({ control })

  const locationsData = useMemo(() => arrayToFeatures(locations), [locations])
  const showStates = useMemo(() => activeCountry?.id === 'us', [activeCountry])
  const activeStateId = useMemo(() => activeState?.id ?? '', [activeState])
  const filterNotActiveState = useMemo(() => ['!=', 'id', activeStateId], [activeStateId])

  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  const onClickMap = useCallback(
    ({ features, lngLat }) => {
      const cell = getS2RectFromCenter({
        centerPoint: { latitude: lngLat.lat, longitude: lngLat.lng },
        level: s2Level?.value
      })
      if (!features.length && scanMode === CellScanMode.CLICK) return addCells([cell])

      let scanningEnabled = true
      features.forEach(async ({ layer, ...feature }) => {
        switch (layer.id) {
          case 'location-cell': {
            if (activeTab === LocationTab.QUERY) return addCells([cell])
            const clickedCellLevel = feature.properties.s2Level
            if (s2Level?.value !== S2CellLevel[12] || clickedCellLevel !== S2CellLevel[11]) return

            const children = getLevel12Children(feature)
            return addCells([...children])
          }
          case 's2-cell':
            scanningEnabled = false
            return removeCells([cell])
          case 'us-states': {
            scanningEnabled = false
            const stateFeature = await getDatasetFeature({
              dataset_id: 'clcrnixy90au220p2wxef8v97',
              feature_id: feature.id
            })
            setActiveState(stateFeature)
            return focusOnFeature(stateFeature, { padding: 150 })
          }
        }
      })

      if (scanMode === CellScanMode.SCAN && scanningEnabled)
        setValue('scanCenterPoint', { latitude: lngLat.lat, longitude: lngLat.lng })
    },
    [
      activeTab,
      addCells,
      focusOnFeature,
      getDatasetFeature,
      getLevel12Children,
      getS2RectFromCenter,
      removeCells,
      s2Level,
      scanMode,
      setActiveState,
      setValue
    ]
  )

  // Update hovered feature
  const onHover = useCallback(
    ({ features = [] }) => {
      if (dragging || !features.length) return
      const { id, layer } = features[0]

      if (hoveredFeature.id !== null)
        mapRef.current.setFeatureState(hoveredFeature, { hover: false })

      let feature = { id: null, source: null }
      switch (layer?.id) {
        case 'candidate-cell': {
          feature = { id, source: 'candidate-cells' }
          break
        }
        case 'location-cell': {
          feature = { id, source: 'location-cells' }
          break
        }
        case 's2-cell': {
          feature = { id, source: 's2-cells' }
          break
        }
        case 'us-states': {
          feature = { id, source: 'us-states', sourceLayer: 'us-states' }
          break
        }
        default:
          return
      }

      mapRef.current.setFeatureState(feature, { hover: true })
      setHoveredFeature(feature)
    },
    [activeTab, dragging, hoveredFeature]
  )

  // Remove hover state
  const onMouseLeave = useCallback(() => {
    if (hoveredFeature.id !== null) mapRef.current.setFeatureState(hoveredFeature, { hover: false })
    setHoveredFeature({ id: null, source: null })
  }, [hoveredFeature])

  // Update cursor
  useEffect(() => {
    setCursor(
      hoveredFeature.id ? 'pointer' : scanMode === CellScanMode.SCAN ? 'crosshair' : 'pointer'
    )
  }, [dragging, hoveredFeature, scanMode])

  // Set Layer Visibility on mount
  useEffect(() => {
    setLayerVisibility({
      'Location Cells': {
        layers: ['location-cell', 'location-cell-border'],
        visible: true
      },
      'Candidate Cells': {
        layers: ['candidate-cell', 'candidate-cell-border'],
        visible: true
      },
      'Playable Cells': {
        layers: ['playable-cell', 'playable-cell-border'],
        visible: false
      },
      Locations: {
        layers: ['location'],
        title: 'Locations',
        visible: true
      }
    })
    return () => setLayerVisibility()
  }, [setLayerVisibility])

  // Update Layer Visibility
  useUpdateEffect(() => {
    setLayerVisibility((v) => ({
      'Location Cells': {
        ...v['Location Cells'],
        onZoom: () => focusOnFeatures(locationCellData, { padding: 250 })
      },
      'Candidate Cells': {
        ...v['Candidate Cells'],
        onZoom: () => focusOnFeatures(candidateCellData, { padding: 250 })
      },
      'Playable Cells': {
        ...v['Playable Cells'],
        onZoom: () => focusOnFeatures(playableCellData, { padding: 250 })
      },
      Locations: {
        ...v['Locations'],
        onZoom: () => focusOnFeatures(locationsData, { padding: 150 })
      }
    }))
  }, [candidateCellData, focusOnFeatures, locationCellData, locationsData, setLayerVisibility])

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    400,
    [focusedBbox, busy, isMounted, zoomToBbox]
  )

  return useMemo(
    () => (
      <MapContainer ref={containerRef}>
        <>
          <Loader loading={busy || loading} text={busy && 'Loading Map'} />
          <MapSwitcher onSwitch={setMapStyle} bottom left color="blue" />
          <Overlay tw="top-2 left-2">
            {!busy && (
              <>
                <LayerVisibilityControl mapRef={mapRef} />
                <ClearData />
              </>
            )}
          </Overlay>
          <ScanInfo />

          <Map
            {...viewstate}
            cursor={dragging ? 'grabbing' : cursor}
            id="locationsMap"
            interactiveLayerIds={
              !dragging &&
              ['s2-cell', 'candidate-cell', 'location-cell'].concat(showStates ? ['us-states'] : [])
            }
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
            mapStyle={mapStyle}
            onLoad={() => setBusy(false)}
            doubleClickZoom={false}
            onClick={onClickMap}
            onMouseMove={onHover}
            onMouseLeave={onMouseLeave}
            onMove={onMoveMap}
            ref={mapRef}
            style={{ width: '100vw', height: '100%' }}
          >
            <ScanCircle />

            <LocationCells hoveredFeature={hoveredFeature} />

            {showStates && (
              <Source type="vector" url="mapbox://hvnt.us-states" id="us-states">
                <Layer {...mapLayers.usa.states} filter={filterNotActiveState} />
                <Layer {...mapLayers.usa.border} filter={filterNotActiveState} />
              </Source>
            )}

            <Source type="vector" url="mapbox://dennit.0haz83rx" id="countries">
              <Layer {...mapLayers.country.main(colorCountries({ activeCountry, countries }))} />
              <Layer
                {...mapLayers.country.border(colorCountryBorders({ activeCountry, countries }))}
              />
            </Source>

            <Source type="geojson" data={locationsData}>
              <Layer {...mapLayers.location.main} />
            </Source>

            <MapControl>
              <GeolocateControl
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
              />

              <NavigationControl />

              <GeocoderControl />
            </MapControl>
          </Map>
        </>
      </MapContainer>
    ),
    [
      activeCountry,
      busy,
      colorCountries,
      colorCountryBorders,
      containerRef,
      countries,
      cursor,
      dragging,
      filterNotActiveState,
      hoveredFeature,
      loading,
      locationsData,
      onClickMap,
      mapStyle,
      onHover,
      onMouseLeave,
      onMoveMap,
      showStates,
      viewstate
    ]
  )
}

const MapContainer = tw.div`relative flex overflow-hidden justify-center items-center`
