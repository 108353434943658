import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { usePlayer } from '../../../contexts/PlayerContext'
import { ParticipationSorts } from '../../../enums'
import { useGetUser } from '../../../hooks/firebase/useGetUser'
import { useIsMounted } from '../../../hooks/useIsMounted'
import useSort from '../../../hooks/useSort'
import { db } from '../../../lib/firebase'
import { Tab } from '../../../layout/Player'
import { participationConverter, prizeConverter } from '../../../utility/formatUserData'
import { Items } from './Items/ParticipationItems'
import { List } from '../../../layout/List'

export const Participations = () => {
  const { activePlayer, dispatch } = usePlayer()
  const { onNextParticipations, onNextPrizes } = useGetUser(dispatch)
  const { applySort, sortingBy, sortListBy } = useSort(ParticipationSorts.STATUS)
  const { isMounted } = useIsMounted()

  const { participations, uid } = activePlayer
  const [sortedItems, setSortedItems] = useState(participations)

  // Sort items
  useEffect(() => {
    setSortedItems(applySort(participations))
  }, [applySort, participations])

  // Subscribe to participations and prizes
  useEffect(() => {
    if (!isMounted() || !uid) return
    const unsubscribeParticipations = db
      .collection('participations')
      .where('players', 'array-contains', uid)
      .withConverter(participationConverter)
      .onSnapshot(onNextParticipations)

    const unsubscribePrizes = db
      .collection('users')
      .doc(uid)
      .collection('prizes')
      .withConverter(prizeConverter)
      .onSnapshot(onNextPrizes)

    return () => {
      unsubscribeParticipations()
      unsubscribePrizes()
    }
  }, [isMounted, onNextParticipations, onNextPrizes, uid])

  return (
    <Tab.Container>
      <Tab.Section>
        <Tab.Header>Participations</Tab.Header>
        <List.Header>
          <List.HeaderData
            active={sortingBy?.sort === ParticipationSorts.STATUS}
            onClick={() => sortListBy(ParticipationSorts.STATUS)}
            inc={sortingBy?.inc}
            colSpan={1}
            tw="text-center"
          >
            Status
          </List.HeaderData>
          <List.HeaderData
            active={sortingBy?.sort === ParticipationSorts.HVNT}
            onClick={() => sortListBy(ParticipationSorts.HVNT)}
            inc={sortingBy?.inc}
            colSpan={3}
          >
            Hvnt
          </List.HeaderData>
          <List.HeaderData
            active={sortingBy?.sort === ParticipationSorts.STARTED_AT}
            onClick={() => sortListBy(ParticipationSorts.STARTED_AT)}
            inc={sortingBy?.inc}
            colSpan={3}
          >
            Started at
          </List.HeaderData>
          <List.HeaderData
            active={sortingBy?.sort === ParticipationSorts.ENDED_AT}
            onClick={() => sortListBy(ParticipationSorts.ENDED_AT)}
            inc={sortingBy?.inc}
            colSpan={3}
          >
            Ended at
          </List.HeaderData>
          <List.HeaderData colSpan={1} tw="text-center">
            Prize
          </List.HeaderData>
        </List.Header>

        <List.Container tw="shadow-inner">
          {sortedItems?.length ? <Items items={sortedItems} /> : <Empty>Nothing here</Empty>}
        </List.Container>
      </Tab.Section>
    </Tab.Container>
  )
}

const Empty = tw.div`flex justify-center items-center p-4 text-sm text-gray-700`
