import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import tw from 'twin.macro'
import { useChallenge } from '../../../contexts/ChallengeContext'
import { useCorporate } from '../../../contexts/CorporateContext'
import { EventLocationType } from '../../../enums'
import { List } from '../../../layout/List'
import Icon from '../../../styles/Icons'
import { Button, Card, Input } from '../../../styles/Layout'
import { locationChallengeConverter } from '../../../utility/formatEvents'
import { validate } from '../../../utility/formValidation'
import { isCoordinates } from '../../../utility/validationFunctions'
import Dropdown from '../../Dropdown'
import { Section } from '../../Hvnt/FormSection'
import { Items } from './Items/ChallengeItems'

export const AddTriviaLocation = ({ isLogo, onSubmit }) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = useFormContext()

  const { stopEditingEventLocation } = useCorporate()
  const { challenges, customTagOptions } = useChallenge()
  const { challenges: customChallenges, customTag } = watch()
  const haveChallenges = !!customChallenges?.length

  // Register fields
  useEffect(() => {
    register('challenges')
    register('type', { value: isLogo ? EventLocationType.LOGO : EventLocationType.TRIVIA })
  }, [isLogo, register])

  // Filter out challenges by tag
  const onGetChallenges = async () => {
    if (!customTag || !challenges) return // display message
    const customChallenges = challenges
      .filter((c) => c.customTags?.some((tag) => tag.value === customTag.value))
      .map((challenge) => locationChallengeConverter.fromChallenge(challenge))

    setValue('challenges', customChallenges)
  }

  // Clear list
  const onClearList = () => {
    setValue('challenges')
  }

  return (
    <>
      <Section field="name" label="Name of the location" error={errors.name} required>
        <Input.Default
          {...register('name', { required: 'Name is required', validate: validate.event.name })}
          placeholder="Display Name"
        />
        <Input.Error>{errors.name?.message}</Input.Error>
      </Section>
      <Section
        field={['centerPoint.latitude', 'centerPoint.longitude']}
        label="Add coordinates"
        error={errors.centerPoint}
        required
      >
        <Input.Label>Location (Lat, Lng)</Input.Label>
        <CoordsWrapper>
          <Input.Default
            {...register('centerPoint.latitude', {
              required: 'Latitide is required',
              validate: isCoordinates,
              valueAsNumber: true
            })}
            size="sm"
            placeholder="Latitude"
            tw="md:max-w-1/3"
          />

          <Input.Default
            {...register('centerPoint.longitude', {
              required: 'Longitude is required',
              validate: isCoordinates,
              valueAsNumber: true
            })}
            size="sm"
            placeholder="Longitude"
            tw="md:max-w-1/3"
          />
        </CoordsWrapper>

        {errors?.centerPoint && <Input.Error>{'Invalid coordinates'}</Input.Error>}
      </Section>
      <Section field="customTag" label="Import trivia challenges" error={errors.customTag} required>
        <Input.Label>Custom challenge tag</Input.Label>
        <Controller
          control={control}
          name="customTag"
          render={({ field: { onChange, ...field } }) => (
            <Dropdown.Select
              {...field}
              menuPlacement="top"
              onChange={(v) => {
                onClearList()
                onChange(v)
              }}
              options={customTagOptions}
              placeholder="Select Custom Tag"
              tw="w-auto"
            />
          )}
          rules={{ required: 'Choose one' }}
        />
        <Input.Error>{errors.customTag?.message}</Input.Error>
        <ButtonContainer>
          <Button.Secondary
            ring
            size="sm"
            onClick={onGetChallenges}
            disabled={!customTag || haveChallenges}
            tw="w-full sm:w-min"
          >
            <Icon.Download size="sm" mr="2" />
            Show Challenges
          </Button.Secondary>
          {haveChallenges && (
            <Button.Warning ring size="sm" onClick={onClearList} tw="w-full sm:w-min">
              <Icon.Trashcan size="sm" mr="2" /> Clear List
            </Button.Warning>
          )}
        </ButtonContainer>
      </Section>
      {haveChallenges && (
        <ListSection>
          <InsetSection>
            <Label>{`${customChallenges?.length} Challenges:`}</Label>
            <List.Header>
              <List.HeaderData colSpan={1} tw="text-center">
                Active
              </List.HeaderData>
              <List.HeaderData colSpan={1}>Type</List.HeaderData>
              <List.HeaderData colSpan={5}>Question</List.HeaderData>
            </List.Header>
            <List.Container>
              <Items items={customChallenges} />
            </List.Container>
          </InsetSection>
        </ListSection>
      )}

      <Footer>
        <Button.White size="sm" onClick={stopEditingEventLocation} ring tw="mr-auto">
          <Icon.Close size="sm" mr="2" />
          Cancel
        </Button.White>
        <Button.Submit size="sm" onClick={onSubmit} ring disabled={!customChallenges?.length}>
          <Icon.Check size="sm" mr="2" />
          Save Trivia Location
        </Button.Submit>
      </Footer>
    </>
  )
}

const CoordsWrapper = tw.div`flex flex-wrap gap-1 w-full`
const ButtonContainer = tw.div`flex w-full flex-wrap gap-2 mt-2`
const ListSection = tw.div`flex px-2 sm:px-4`
const Label = tw(Input.Label)`text-lg font-semibold text-gray-700`
const InsetSection = tw.div`flex flex-col p-4 h-auto w-full bg-gray-100 rounded-lg shadow-inner`
const Footer = tw(Card.Footer)`mt-auto z-30 gap-2 py-3 bg-terracotta-300`
