import React, { Fragment, useEffect, useMemo } from 'react'
import { Marker, Layer, Source } from 'react-map-gl'
import { useLocations } from '../../contexts/LocationsContext'
import { feature } from '@turf/helpers'
import circle from '@turf/circle'
import { mapLayers } from '../Map/MapLayers'
import { Crosshair } from '../Map/Crosshair'
import useS2Cells from '../../hooks/useS2Cells'
import { useFormContext, useWatch } from 'react-hook-form'
import { LocationTab } from '../../enums'

export const ScanCircle = () => {
  const { activeTab, addCells, setDragging } = useLocations()
  const { getS2RectsInFeature } = useS2Cells()
  const { control, setValue } = useFormContext()
  const { s2Level, scanCenterPoint, scanRadius } = useWatch({ control })

  const scanCircle = useMemo(
    () =>
      scanCenterPoint &&
      circle([scanCenterPoint.longitude, scanCenterPoint.latitude], scanRadius, {
        units: 'meters'
      }),
    [scanCenterPoint, scanRadius]
  )

  useEffect(() => {
    if (!scanCircle || activeTab === LocationTab.QUERY) return
    addCells(getS2RectsInFeature({ feature: scanCircle, level: s2Level.value }))
  }, [activeTab, addCells, getS2RectsInFeature, s2Level, scanCircle])

  return (
    <Fragment>
      {scanCircle && (
        <Marker
          data-testid="scan-circle"
          longitude={parseFloat(scanCenterPoint.longitude)}
          latitude={parseFloat(scanCenterPoint.latitude)}
          draggable
          onDragStart={() => setDragging(true)}
          onDragEnd={({ lngLat }) => {
            setValue('scanCenterPoint', { latitude: lngLat.lat, longitude: lngLat.lng })
            setDragging(false)
          }}
        >
          <Crosshair />
        </Marker>
      )}
      <Source type="geojson" data={scanCircle ?? feature()}>
        <Layer {...mapLayers.circle.scan} afterId="s2-cell" />
        <Layer {...mapLayers.circle.border} />
      </Source>
    </Fragment>
  )
}
