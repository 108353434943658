import { InfoScreenStatus, InfoScreenStatusType, InfoScreenType } from '../enums'
import { auth, db } from '../lib/firebase'
import { conditionalDelete, getOption } from './helperFunctions'
import { INFO_SCREEN_TYPE_OPTIONS } from './labeledOptions'
import { isImageUrl, isStringOrNumber, isTimestamp, isValidDateRange } from './validationFunctions'

export const infoScreenConverter = {
  fromFirestore: (snapshot) => {
    const errors = []
    const addError = (error, fallback = undefined) => {
      errors.push(error)
      return fallback
    }

    const id = snapshot.id
    const path = snapshot.ref.path
    const {
      active,
      createdAt,
      endDate,
      imageUrl,
      name,
      priority,
      showOnlyOnce = false,
      startDate,
      status,
      type,
      updatedAt
    } = snapshot.data()

    let formattedInfoScreen = {
      active,
      ...(isTimestamp(createdAt) && { createdAt: new Date(createdAt.toDate()) }),
      dateRange: isValidDateRange({ startDate, endDate })
        ? { startDate: startDate.toDate(), endDate: endDate.toDate() }
        : addError('dateRange'),
      id,
      imageUrl: isImageUrl(imageUrl) ? imageUrl : addError('imageUrl'),
      ...(isStringOrNumber(name) ? { name } : addError('name')),
      path,
      priority,
      showOnlyOnce,
      ...(InfoScreenType[type]
        ? { type: getOption(type, INFO_SCREEN_TYPE_OPTIONS) }
        : addError('type')),
      ...(isTimestamp(updatedAt) && { updatedAt: updatedAt.toDate() })
    }
    formattedInfoScreen.status = setDisplayStatus({ ...formattedInfoScreen, errors, status })

    return { ...formattedInfoScreen, errors }
  },

  toFirestore: (data) => {
    const { active, createdAt, dateRange, imageUrl, name, priority = 0, showOnlyOnce, type } = data
    return {
      active,
      createdAt: createdAt ?? new Date(),
      endDate: conditionalDelete(dateRange?.endDate),
      imageUrl: conditionalDelete(imageUrl),
      name: conditionalDelete(name),
      priority,
      showOnlyOnce,
      startDate: conditionalDelete(dateRange?.startDate),
      type: conditionalDelete(type?.value),
      updatedAt: new Date(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  }
}

const setDisplayStatus = ({ errors, status }) => {
  switch (status) {
    case InfoScreenStatusType.DRAFT:
      return InfoScreenStatus.DRAFT
    case InfoScreenStatusType.ENDED:
      return InfoScreenStatus.ENDED
    case InfoScreenStatusType.LIVE:
      if (errors.length) return InfoScreenStatus.ERROR
      return InfoScreenStatus.LIVE
    case InfoScreenStatusType.UPCOMING:
      if (errors.length) return InfoScreenStatus.ERROR
      return InfoScreenStatus.UPCOMING
    default:
      return InfoScreenStatus.UNSET
  }
}

/**
 * Only used internally for display purposes
 * Backend ultimately calculates the final status
 * */
export const setNextStatus = ({ active, dateRange = {} }) => {
  const { startDate, endDate } = dateRange
  const now = new Date()
  if (endDate < now) return InfoScreenStatus.ENDED
  if (active) {
    if (startDate > now) return InfoScreenStatus.UPCOMING
    if (startDate < now && now < endDate) return InfoScreenStatus.LIVE
  }
  return InfoScreenStatus.DRAFT
}
