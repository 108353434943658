import React, {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useReducer,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { ConversationTabs, NotificationStatusTypes } from '../enums'
import { auth, db, FieldValue, functions } from '../lib/firebase'
import { conversationReducer } from '../reducers/conversationReducer'
import { Alerts } from '../utility/alerts'
import { conversationsConverter } from '../utility/formatSupport'

const SupportContext = createContext()

export const SupportProvider = (props) => {
  const navigate = useNavigate()

  const [conversations, dispatch] = useReducer(conversationReducer, [])
  const [activeConversation, setActiveConversation] = useState()
  const [activeTab, setActiveTab] = useState(ConversationTabs.UNRESOLVED)
  const [fetchingConversation, setFetchingConversation] = useState(false)
  const [loading, setLoading] = useState(false)

  const openConversation = useCallback(
    (conversation) => {
      setActiveConversation(conversation)
      navigate(`${conversation.id}`, { replace: true })
    },
    [navigate]
  )

  const resolveConversation = useCallback(({ id }) => {
    const conversationRef = db.collection('support-chat').doc(id)
    conversationRef.update({
      resolved: true,
      resolvedAt: FieldValue.serverTimestamp(),
      resolvedBy: db.doc(`users/${auth.currentUser.uid}`)
    })
  }, [])

  const unresolveConversation = useCallback(({ id }) => {
    const conversationRef = db.collection('support-chat').doc(id)
    conversationRef.update({
      resolved: false,
      resolvedAt: FieldValue.delete(),
      resolvedBy: FieldValue.delete()
    })
  }, [])

  // Send push notification to user
  const sendPushNotification = useCallback(async ({ id, uid, message, nextIdx }) => {
    let status
    try {
      await functions.sendPushNotification.supportMessage({ message, uid })
      status = NotificationStatusTypes.SUCCESS
    } catch (err) {
      status = NotificationStatusTypes.FAILED
    }
    // Update messages array with notification success
    try {
      await db.runTransaction(async (transaction) => {
        const conversationRef = db.collection('support-chat').doc(id)
        const conversationDoc = await conversationRef.get()
        const { messages } = conversationDoc.data()
        if (!messages[nextIdx]) return Promise.reject()
        messages[nextIdx].notificationStatus = status
        transaction.update(conversationRef, { messages })
      })
    } catch (err) {
      console.error(err)
    }
  }, [])

  const sendMessage = useCallback(
    async ({ id, message, user }) => {
      try {
        if (!message) throw new Error('No message')
        if (!user || !user.path) throw new Error('Missing user ref')
        setLoading(true)
        let pushMessage = { body: message }
        let nextIdx
        await db.runTransaction(async (transaction) => {
          const conversationRef = db.collection('support-chat').doc(id)
          const conversationDoc = await conversationRef.get()
          nextIdx = conversationDoc.data()?.messages?.length ?? 0 + 1

          const userRef = db.doc(user.path)
          const newMessage = {
            message,
            seenAt: null,
            sentAt: new Date(),
            sentByUser: false
          }

          transaction.update(userRef, { unreadMessages: FieldValue.increment(1) })
          transaction.update(conversationRef, {
            messages: FieldValue.arrayUnion(newMessage),
            'user.unreadMessages': FieldValue.increment(1)
          })
          const userDoc = await userRef.get()
          const unread = userDoc.get('unreadMessages')
          pushMessage.badge = unread ? `${unread + 1}` : '1'
        })
        // Send push
        sendPushNotification({ id, uid: user.uid, message: pushMessage, nextIdx })
      } finally {
        setLoading(false)
      }
    },
    [sendPushNotification]
  )

  // Pre-fetch conversation and navigate
  const visitDeepRoute = useCallback(
    async ({ id }) => {
      try {
        setFetchingConversation(true)
        if (!id) return Alerts.Support.DEEP_LINK_FAILED()

        const conversationDoc = await db
          .doc(`support-chat/${id}`)
          .withConverter(conversationsConverter)
          .get()

        if (!conversationDoc.exists) return Alerts.Support.DEEP_LINK_FAILED()
        const conversation = conversationDoc.data()

        if (conversation.resolved) setActiveTab(ConversationTabs.RESOLVED)
        openConversation(conversation)
      } catch (err) {
        Alerts.General.ERROR(err)
        console.error(err)
      } finally {
        setFetchingConversation(false)
      }
    },
    [openConversation]
  )

  const onNextConversation = useCallback((snapshot, dispatch) => {
    snapshot.docChanges().forEach(async ({ doc, type }) => {
      const conversation = doc.data()
      if (type === 'added') dispatch({ type: 'ADD_CONVERSATION', conversation })
      if (type === 'modified') dispatch({ type: 'EDIT_CONVERSATION', conversation })
      if (type === 'removed') dispatch({ type: 'REMOVE_CONVERSATION', conversation })
    })
  }, [])

  // Listen to collection changes
  useEffect(() => {
    const unsubscribe = db
      .collection('support-chat')
      .withConverter(conversationsConverter)
      .onSnapshot((onNext) => onNextConversation(onNext, dispatch))
    return unsubscribe
  }, [onNextConversation])

  const defaultValues = useMemo(
    () => ({
      activeConversation,
      activeTab,
      conversations,
      fetchingConversation,
      loading,
      openConversation,
      resolveConversation,
      sendMessage,
      setActiveConversation,
      setActiveTab,
      unresolveConversation,
      visitDeepRoute
    }),
    [
      activeConversation,
      activeTab,
      conversations,
      fetchingConversation,
      loading,
      openConversation,
      resolveConversation,
      sendMessage,
      unresolveConversation,
      visitDeepRoute
    ]
  )
  return <SupportContext.Provider value={defaultValues}>{props.children}</SupportContext.Provider>
}

// Hook
export const useSupport = () => {
  const context = useContext(SupportContext)
  if (context === undefined)
    throw new Error('`useSupport` hook must be used within a `SupportProvider` component')
  return context
}
