import React, { useCallback, useMemo, useState } from 'react'
import { Button, Status } from '../../styles/Layout'
import { format } from 'date-fns'
import RBAC from '../RBAC'
import Icon from '../../styles/Icons'
import { relativeDateUpToDays } from '../../utility/helperFunctions'
import config from '../../config'
import { InfoScreenStatus, Role } from '../../enums'
import { ATag } from '../../styles/Link'
import { Item } from '../../layout/List'
import { useInfoScreen } from '../../contexts/InfoScreenContext'
import { ImagePopup } from '../Image'

export const InfoScreenItems = ({ items }) => {
  const [expandedItem, setExpandedItem] = useState()
  const onClickItem = useCallback(
    (infoScreen, opened) => {
      console.log({ infoScreen })
      setExpandedItem(opened ? infoScreen : undefined)
    },
    [setExpandedItem]
  )

  return useMemo(
    () =>
      items.map((item) => (
        <InfoScreenItem
          key={item.id}
          item={item}
          onClick={onClickItem}
          expanded={expandedItem?.id === item?.id}
        />
      )),
    [expandedItem, items, onClickItem]
  )
}

export const InfoScreenItem = ({ item, expanded, onClick }) => {
  const { startEditingInfoScreen } = useInfoScreen()
  const onClickItem = useCallback(
    (infoScreen) => {
      onClick(infoScreen, !expanded)
    },
    [expanded, onClick]
  )

  const onClickEdit = useCallback(
    (e, item) => {
      e.stopPropagation()
      e.preventDefault()
      startEditingInfoScreen(item)
    },
    [startEditingInfoScreen]
  )

  return useMemo(() => {
    const { active, dateRange, name, status } = item
    return (
      <Item.Container>
        <Item.Header onClick={() => onClickItem(item)} expanded={expanded}>
          <Item.HeaderData colSpan={1}>
            <Item.Center>
              <Status active={active} />
            </Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={1}>
            <Item.Center>{status?.icon() ?? '-'}</Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={4}>{name ?? '-'}</Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.startDate ? relativeDateUpToDays(dateRange.startDate, 1) : '-'}
          </Item.HeaderData>
          <Item.HeaderData colSpan={2}>
            {dateRange?.endDate ? relativeDateUpToDays(dateRange.endDate, 1) : '-'}
          </Item.HeaderData>
        </Item.Header>

        <Item.Summary expanded={expanded}>
          <RBAC allow={[Role.CREATOR]}>
            <Item.ActionButtons>
              <Button.Secondary
                size="sm"
                onClick={(e) => onClickEdit(e, item)}
                data-testid="edit-button"
              >
                <Icon.Edit size="sm" mr="2" /> Edit Screen
              </Button.Secondary>
            </Item.ActionButtons>
          </RBAC>
          {RenderSummary(item)}
        </Item.Summary>
      </Item.Container>
    )
  }, [expanded, item, onClickEdit, onClickItem])
}

const RenderSummary = (item) => {
  const {
    createdAt,
    dateRange,
    errors,
    id,
    imageUrl,
    name,
    path,
    priority,
    showOnlyOnce,
    status,
    type,
    updatedAt
  } = item
  return (
    <>
      {imageUrl && (
        <Item.Image>
          <ImagePopup imageUrl={imageUrl} />
        </Item.Image>
      )}

      <Item.SummaryList>
        {!!errors?.length && (
          <Item.Row>
            <Item.Bold tw="text-red-600">
              {status == InfoScreenStatus.DRAFT || status == InfoScreenStatus.UNSET
                ? 'Missing:'
                : 'Errors:'}
            </Item.Bold>
            <Item.Text tw="italic">{errors.join(', ')}</Item.Text>
          </Item.Row>
        )}

        <Item.Row>
          <Item.Bold>{'Id:'}</Item.Bold>
          <ATag href={`${config.firebase.FIRESTORE_URL}/${path}`}>{id}</ATag>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Status:'}</Item.Bold>
          <Item.Status>
            {status?.icon('sm')}
            {status?.message ?? '-'}
          </Item.Status>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Type:'}</Item.Bold>
          <pre>{type?.value ?? '-'}</pre>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Priority:'}</Item.Bold>
          {priority ?? '-'}
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Name:'}</Item.Bold>
          {name ?? '-'}
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Start Date:'}</Item.Bold>
          <Item.Text>
            {dateRange?.startDate ? format(dateRange.startDate, 'LLL do yyy, HH:mm') : '-'}
          </Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'End Date:'}</Item.Bold>
          <Item.Text>
            {dateRange?.endDate ? format(dateRange?.endDate, 'LLL do yyy, HH:mm') : '-'}
          </Item.Text>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Show Once:'}</Item.Bold>
          <pre>{showOnlyOnce ? 'true' : 'false'}</pre>
        </Item.Row>

        <Item.Row>
          <Item.Bold>{'Created:'}</Item.Bold>
          <Item.Text>{createdAt ? format(createdAt, 'LLL do yyy, HH:mm') : '-'}</Item.Text>
        </Item.Row>
        <Item.Row>
          <Item.Bold>{'Updated:'}</Item.Bold>
          <Item.Text>{updatedAt ? format(updatedAt, 'LLL do yyy, HH:mm') : '-'}</Item.Text>
        </Item.Row>
      </Item.SummaryList>
    </>
  )
}
