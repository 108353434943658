import React, { Fragment, useCallback, useMemo, useState } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Layer, Marker, Source } from 'react-map-gl'
import { useAreas } from '../../../contexts/AreasContext'
import { mapLayers } from '../MapLayers'
import { Status } from '../../../styles/Layout'
import { capitalize } from '../../../utility/helperFunctions'
import { Pin } from './Pin'

export const SelectCityMarkers = ({ locked = false, showActive = false }) => {
  const { activeCity, countries, setActiveCity } = useAreas()

  const countryColors = useMemo(() => {
    if (!countries?.length) return
    const activeCode = activeCity?.path.split('/')[0] ?? ''
    const matchExisting = ['match', ['get', 'ISO2']]
    for (const country of countries) {
      if (activeCode && activeCode === country.id) {
        matchExisting.push(activeCode.toUpperCase(), 'rgba(0,0,0,0)')
      } else {
        matchExisting.push(country['id'].toUpperCase(), theme`colors.gold.700`) // gold
      }
    }
    matchExisting.push('rgba(0,0,0,0)')
    return matchExisting
  }, [activeCity, countries])

  const onClickCity = useCallback(
    ({ city }) => {
      !locked && setActiveCity(city)
    },
    [locked, setActiveCity]
  )

  return useMemo(
    () => (
      <Fragment>
        <Source type="vector" url="mapbox://dennit.0haz83rx">
          <Layer {...mapLayers.country.main(countryColors)} />
          <Layer {...mapLayers.country.border(countryColors)} />
        </Source>

        {countries?.map((country) => (
          <Fragment key={country.id}>
            {country.cities?.map((city, i) => (
              <Fragment key={`${country.id}-${i}`}>
                {(showActive || activeCity?.name !== city.name) && (
                  <StyledMarker
                    key={`${country.id}-${i}`}
                    active={activeCity?.name === city.name}
                    city={city}
                    onClick={onClickCity}
                  />
                )}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </Fragment>
    ),
    [activeCity, countries, countryColors, onClickCity, showActive]
  )
}

export const StyledMarker = ({ city, onClick }) => {
  const { active, centerPoint, name } = city
  const MARKER_SIZE = 28
  const [hover, setHover] = useState(false)
  return (
    <Marker
      key={name}
      offset={[0, -MARKER_SIZE / 2]}
      longitude={centerPoint.longitude}
      latitude={centerPoint.latitude}
    >
      <Pin onClick={() => onClick({ city })} size={MARKER_SIZE} />
      <Popup
        id="popup"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        size={MARKER_SIZE}
      >
        <NamePlate onClick={() => onClick({ city })} hover={hover}>
          <Status active={active} tw="mr-2" />
          <Name>{capitalize(name)}</Name>
        </NamePlate>
      </Popup>
    </Marker>
  )
}

const Popup = styled.div.attrs(({ size }) => ({ size }))`
  ${tw`absolute bottom-0`}
  ${({ size }) =>
    css`
      transform: translate(${size}px, ${-size}px);
    `}
`
const Name = tw.span`font-sans font-light whitespace-nowrap`
const NamePlate = styled.div`
  ${tw`flex py-2 px-3 rounded-lg rounded-bl-none shadow-lg justify-between items-center 
text-center cursor-pointer`}
  ${({ hover }) => (hover ? tw`bg-norway-600` : tw`bg-norway`)}
`
