import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import tw, { styled, theme } from 'twin.macro'
import config from '../../../config'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'
import Icon from '../../../styles/Icons'
import { Button, Card, Info, Input } from '../../../styles/Layout'
import { Alerts } from '../../../utility/alerts'
import { hvntRoutesOfDistance } from '../../../utility/calculateHvntRoute'
import { createRouteOptions } from '../../../utility/formatHvnts'
import { noFeatures } from '../../../utility/validationFunctions'
import { Switch } from '../../Layout/Switch'
import { mapLayers } from '../../Map/MapLayers'
import { RouteConfig } from './RouteConfig'
import { Checkbox } from '../../Checkbox'
import { HvntRouteStatusType, Role } from '../../../enums'
import RBAC from '../../RBAC'
import Dropdown from '../../Dropdown'
import RangeSlider from '../../RangeSlider'

export const CreateArea = () => {
  const { editingRoute, innerPoints, loading, routesData, setRoutesData } = useHvntRoute()
  const { customTagOptions, tagOptions } = useCheckpoint()
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch
  } = useFormContext()

  const { area = {}, numberOfCheckpoints } = watch()
  const [overrideWarning, setOverrideWarning] = useState(false)
  const [showOverride, setShowOverride] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const generationDisabled = useMemo(
    () =>
      editingRoute ||
      !!errors.area?.distances ||
      !(area.centerPoint && area.radius && numberOfCheckpoints),
    [area.centerPoint, area.radius, editingRoute, errors.area, numberOfCheckpoints]
  )

  // Generate routes
  const generateExampleRoutes = useCallback(
    async ({ numberOfCheckpoints, checkpoints }) => {
      // Trigger each individual field
      const fields = Object.keys(area).map((key) => `area.${key}`)
      const validData = await trigger(fields)

      if (!area.radius) return
      if (!validData) return
      if (noFeatures(checkpoints)) return Alerts.Route.NO_ROUTES()

      try {
        const routesData = hvntRoutesOfDistance({
          checkpoints: checkpoints.features,
          numberOfCheckpoints,
          options: area.customRoute ? createRouteOptions({ ...area, numberOfCheckpoints }) : {}
        })

        setRoutesData(routesData)
      } catch (err) {
        console.error(err.message)
        trigger('area')
      }
    },
    [area, setRoutesData, trigger]
  )

  // Override route save
  useEffect(() => {
    setSaveDisabled(!routesData?.valid)
    if (routesData?.status?.status == HvntRouteStatusType.WARNING) return setShowOverride(true)

    setOverrideWarning(false)
    setShowOverride(false)
  }, [routesData])

  return (
    <>
      <Container>
        {area.locked && (
          <Section>
            <Info tw="self-center">Since the hvnt has started, some values are locked</Info>
          </Section>
        )}

        <InsetSection tw="py-8 px-6 md:px-20 gap-6 md:gap-10 flex-wrap md:flex-nowrap">
          <Controller
            control={control}
            defaultValue=""
            name="numberOfCheckpoints"
            render={({ field }) => (
              <Col>
                <Input.Label>
                  {'Checkpoints:'}&nbsp; <TextValue>{field.value}</TextValue>
                </Input.Label>
                <Slider
                  {...field}
                  onChange={(e) => field.onChange(parseInt(e.target.value))}
                  color="red"
                  disabled={area.locked || area.saved}
                  min={config.hvntRoute.MIN_NUM_CHECKPOINTS}
                  max={config.hvntRoute.MAX_NUM_CHECKPOINTS}
                  step={1}
                />
              </Col>
            )}
          />
          <Controller
            control={control}
            defaultValue=""
            name="area.radius"
            render={({ field }) => (
              <Col>
                <Input.Label>
                  {'Radius:'}&nbsp;<TextValue>{field.value || 0} m</TextValue>
                </Input.Label>
                <Slider
                  {...field}
                  onChange={(e) => {
                    setValue('area.mapUrl')
                    field.onChange(parseInt(e.target.value))
                  }}
                  color="red"
                  disabled={area.saved}
                  min={20}
                  max={config.hvntRoute.MAX_RADIUS}
                  tickEvery={5}
                  step={20}
                />
              </Col>
            )}
          />
        </InsetSection>
        <Section>
          <Text tw="self-center">
            {'Checkpoints in area:'}&nbsp;
            <TextValue>{innerPoints?.features?.length || 0}</TextValue>
          </Text>
        </Section>

        <InsetSection>
          <Col tw="gap-5">
            {/* Regular Checkpoints  */}
            <Col tw="gap-2">
              <Controller
                control={control}
                defaultValue={true}
                name="area.tags.useRegular"
                render={({ field: { onChange, value } }) => (
                  <Switch
                    color="red"
                    size="md"
                    label={
                      <div tw="flex gap-2 items-center">
                        <span>Use Regular Checkpoints</span>
                        <CheckpointColor />
                      </div>
                    }
                    defaultChecked={value}
                    onToggle={(v) => {
                      onChange(v)
                      setValue('area.tags.regular', v)
                    }}
                    disabled={area.locked || area.saved}
                  />
                )}
              />

              <InsetSection tw="p-3 bg-gray-200">
                <Col>
                  <Input.Label>{'Include checkpoints with tag:'}</Input.Label>
                  <Controller
                    control={control}
                    defaultValue={[]}
                    name="area.tags.regular"
                    render={({ field }) => (
                      <Dropdown.Select
                        closeMenuOnSelect={false}
                        isDisabled={area.locked || area.saved || !area.tags?.useRegular}
                        isClearable
                        isMulti
                        options={tagOptions}
                        placeholder="Default: All"
                        {...field}
                      />
                    )}
                  />
                  {errors.area?.tags?.regular && <Input.Error>{'Maximum 10 tags'}</Input.Error>}
                </Col>
              </InsetSection>
            </Col>

            {/* Custom Tags */}
            <Col tw="gap-2">
              <Controller
                control={control}
                defaultValue={false}
                name="area.tags.useCustom"
                render={({ field: { onChange, value } }) => (
                  <Switch
                    color="red"
                    size="md"
                    label={
                      <div tw="flex gap-2 items-center">
                        <span>Use Custom Checkpoints</span>
                        <CheckpointColor isCustom={true} />
                      </div>
                    }
                    defaultChecked={value}
                    onToggle={(v) => {
                      onChange(v)
                      setValue('area.tags.custom', v)
                    }}
                    disabled={area.locked || area.saved}
                  />
                )}
              />

              <InsetSection tw="p-3 bg-gray-200">
                <Col>
                  <Input.Label>{'Include checkpoints with custom tag:'}</Input.Label>
                  <Controller
                    control={control}
                    defaultValue={[]}
                    name="area.tags.custom"
                    render={({ field }) => (
                      <Dropdown.Select
                        isDisabled={area.locked || area.saved || !area.tags?.useCustom}
                        isClearable
                        isMulti
                        options={customTagOptions}
                        placeholder="Select custom tag"
                        {...field}
                      />
                    )}
                  />
                </Col>
              </InsetSection>

              {errors.area?.tags && <Input.Error>{errors.area.tags.message}</Input.Error>}
            </Col>
          </Col>
        </InsetSection>

        <InsetSection>
          <Col tw="gap-2">
            <Controller
              control={control}
              name="area.customRoute"
              render={({ field: { onChange, value } }) => (
                <Switch
                  color="red"
                  size="md"
                  label="Configure Route"
                  defaultChecked={value}
                  onToggle={onChange}
                  disabled={area.locked || area.saved}
                />
              )}
            />
            <RouteConfig />
          </Col>
        </InsetSection>
      </Container>

      <Card.Footer color={theme`colors.matrix.600`}>
        {area.saved ? (
          <Button.White ring onClick={() => setValue('area.saved', false)}>
            <Icon.Edit mr="2" />
            Change Area
          </Button.White>
        ) : (
          <>
            <div tw="flex flex-row gap-2">
              <Button.Secondary
                disabled={area.saved || loading || generationDisabled}
                onClick={() =>
                  generateExampleRoutes({ numberOfCheckpoints, checkpoints: innerPoints })
                }
                ring
              >
                {routesData ? <Icon.Refresh mr="2" /> : <Icon.Map mr="2" />}
                Test Routes
              </Button.Secondary>

              <Icon.ArrowRight size="sm" />

              <Button.Submit
                disabled={!overrideWarning && saveDisabled}
                onClick={() => setValue('area.saved', true)}
                ring
              >
                <Icon.Check mr="2" />
                Save Area
              </Button.Submit>
            </div>
            <RBAC allow={Role.ADMIN}>
              {showOverride && (
                <Checkbox
                  checked={overrideWarning}
                  onCheck={setOverrideWarning}
                  color="red"
                  label="Override routes warning"
                  tw="self-end text-white"
                />
              )}
            </RBAC>
          </>
        )}
      </Card.Footer>
    </>
  )
}

const Container = tw.div`relative flex flex-1 flex-col w-full overflow-y-scroll py-4 px-6 gap-4`
const Col = tw.div`flex flex-col items-baseline w-full`
const Section = tw.div`flex gap-2 justify-center`
const InsetSection = tw(Section)`p-4 h-auto w-full bg-gray-100 rounded-lg shadow-inner`
const Text = tw.span`flex text-sm text-gray-700 items-center justify-center truncate`
const TextValue = tw.span`text-bismark-700`
const Slider = tw(RangeSlider)`w-full min-width[150px] `
const CheckpointColor = styled.div`
  ${tw`h-4 w-4 border-2 rounded-full`}
  ${({ isCustom }) =>
    isCustom
      ? {
          'background-color': mapLayers.hvnt.innerCheckpoint.paint['circle-color'][2],
          'border-color': mapLayers.hvnt.innerCheckpoint.paint['circle-stroke-color'][2]
        }
      : {
          'background-color': mapLayers.hvnt.innerCheckpoint.paint['circle-color'][3],
          'border-color': mapLayers.hvnt.innerCheckpoint.paint['circle-stroke-color'][3]
        }}
`
