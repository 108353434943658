import React from 'react'
import uniq from 'lodash/uniq'
import Icon from '../styles/Icons'
import { theme } from 'twin.macro'

export const PrizeTab = Object.freeze({
  ARCHIVED: 'ARCHIVED',
  DIGITAL: 'DIGITAL',
  PHYSICAL: 'PHYSICAL'
})
export const PrizeFormTab = Object.freeze({
  UPDATE: 'UPDATE',
  DETAILS: 'DETAILS'
})
export const PrizeInfoTab = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  RESERVED: 'RESERVED',
  CLAIMED: 'CLAIMED'
})

export const PrizeDeliveryType = Object.freeze({
  DIGITAL: 'digital',
  PHYSICAL: 'physical'
})

export const PrizeCurrencyType = Object.freeze({
  SEK: 'SEK',
  EUR: 'EUR',
  USD: 'USD'
})

export const PrizeStatusType = Object.freeze({
  EXPIRED: 'EXPIRED',
  ALL_CLAIMED: 'ALL_CLAIMED',
  ALL_RESERVED: 'ALL_RESERVED',
  ITEMS_LEFT: 'ITEMS_LEFT',
  NO_ITEMS: 'NO_ITEMS',
  OVER_RESERVED: 'OVER_RESERVED',
  NOT_EXPIRED: 'NOT_EXPIRED'
})

export const PrizeStatus = Object.freeze({
  ERROR: {
    icon: Object.assign((size = 'md') => <Icon.Error color={theme`colors.orange`} size={size} />),
    message: 'ERROR',
    sort: 1
  },
  [PrizeStatusType.OVER_RESERVED]: {
    icon: Object.assign((size = 'md') => <Icon.Warning color={theme`colors.red`} size={size} />),
    message: 'OVER-RESERVED',
    sort: 2
  },
  [PrizeStatusType.NO_ITEMS]: {
    icon: Object.assign((size = 'md') => <Icon.Close color={theme`colors.red`} size={size} />),
    message: 'NO ITEMS',
    sort: 2
  },
  [PrizeStatusType.ITEMS_LEFT]: {
    icon: Object.assign((size = 'md') => <Icon.Check color={theme`colors.green`} size={size} />),
    message: 'ITEMS LEFT',
    sort: 3
  },
  [PrizeStatusType.ALL_CLAIMED]: {
    icon: Object.assign((size = 'md') => <Icon.Trophy color={theme`colors.gray`} size={size} />),
    message: 'ALL CLAIMED',
    sort: 4
  },
  [PrizeStatusType.ALL_RESERVED]: {
    icon: Object.assign((size = 'md') => <Icon.Close color={theme`colors.gray`} size={size} />),
    message: 'ALL RESERVED',
    sort: 5
  },
  [PrizeStatusType.EXPIRED]: {
    icon: Object.assign((size = 'md', color = theme`colors.gray`) => (
      <Icon.Clock color={color} size={size} />
    )),
    message: 'EXPIRED',
    sort: 6
  }
})

export const PrizeFilters = Object.freeze({
  EXPIRED: {
    field: 'expired',
    filter: (arr, value) =>
      value
        ? arr.filter((item) => new Date(item.validTo) < new Date())
        : arr.filter((item) => (item.validTo ? new Date(item.validTo) >= new Date() : true))
  },
  ALL_CLAIMED: {
    field: 'all claimed',
    filter: (arr, value) =>
      value
        ? arr.filter((item) => item.claimed >= item.amount)
        : arr.filter((item) => item.claimed < item.amount)
  },
  ALL_RESERVED: {
    field: 'all reserved',
    filter: (arr, value) =>
      value
        ? arr.filter((item) => item.reserved >= item.amount)
        : arr.filter((item) => item.reserved < item.amount)
  },
  ITEMS_LEFT: {
    field: 'available',
    filter: (arr, value) =>
      value
        ? arr.filter((item) => item.available > item.claimed)
        : arr.filter((item) => item.available <= item.claimed)
  },
  ERROR: {
    field: 'errors',
    filter: (arr) => arr.filter((item) => !!item.errors?.length)
  },
  QUERY: {
    field: 'query',
    filter: (arr, value) => {
      const filteredCategories = arr.filter(
        (item) => item.category && item.category.label.toLowerCase().indexOf(value) !== -1
      )
      const filteredId = arr.filter((item) => item.id?.indexOf(value) !== -1)
      const filteredItemId = arr.filter((item) =>
        item.prizeItems?.some((prizeItem) => prizeItem.itemId.toLowerCase().indexOf(value) !== -1)
      )
      const filteredName = arr.filter((item) => item.name?.toLowerCase().indexOf(value) !== -1)
      const filteredSupplier = arr.filter(
        (item) => item.supplier?.value?.toLowerCase().indexOf(value) !== -1
      )
      const filteredTags = arr.filter((item) =>
        item.tags?.some((tag) => tag.label?.indexOf(value) !== -1)
      )
      return uniq(
        filteredName
          .concat(filteredCategories)
          .concat(filteredId)
          .concat(filteredItemId)
          .concat(filteredSupplier)
          .concat(filteredTags),
        'id'
      )
    }
  }
})

export const PrizeSorts = Object.freeze({
  STATUS: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.status?.sort - b.status?.sort)
      : arr?.sort((a, b) => b.status?.sort - a.status?.sort),
  NAME: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.name?.localeCompare(b.name))
      : arr?.sort((a, b) => b.name?.localeCompare(a.name)),
  TYPE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => a.deliveryType?.localeCompare(b.deliveryType))
      : arr?.sort((a, b) => b.deliveryType?.localeCompare(a.deliveryType)),

  AVAILABLE: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => b.available - a.available)
      : arr?.sort((a, b) => a.available - b.available),
  CLAIMED: (arr, inc) =>
    inc ? arr?.sort((a, b) => b.claimed - a.claimed) : arr?.sort((a, b) => a.claimed - b.claimed),
  RESERVED: (arr, inc) =>
    inc
      ? arr?.sort((a, b) => b.reserved - a.reserved)
      : arr?.sort((a, b) => a.reserved - b.reserved),
  VALID_UNTIL: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.validTo
      const bHas = !!b.validTo
      if (aHas && bHas) return inc ? b.validTo - a.validTo : a.validTo - b.validTo
      return aHas ? -1 : bHas ? 1 : 0
    }),
  ARCHIVED_AT: (arr, inc) =>
    arr.sort((a, b) => {
      const aHas = !!a.archivedAt
      const bHas = !!b.archivedAt
      if (aHas && bHas) return inc ? b.archivedAt - a.archivedAt : a.archivedAt - b.archivedAt
      return aHas ? -1 : bHas ? 1 : 0
    })
})
