import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { useMap } from '../../../contexts/MapContext'
import { gdb } from '../../../lib/firebase'
import Icon from '../../../styles/Icons'
import { Button } from '../../../styles/Layout'
import { CandidateSummary } from './CandidateSummary'
import useGetClues from '../../../hooks/firebase/useGetClues'
import { clueReducer } from '../../../reducers/clueReducer'
import { Item } from '../../../layout/List'
import { Role } from '../../../enums'
import RBAC from '../../RBAC'

export const Items = ({ items = [] }) => {
  const { focusedItem, setFocusedItem } = useCheckpoint()
  const { focusOnPoint } = useMap()

  const onClickItem = useCallback(
    (candidate, opened) => {
      console.log({ candidate })
      if (opened) {
        focusOnPoint(candidate, { minZoom: 15, maxZoom: 18 })
        setFocusedItem({ candidate })
      } else setFocusedItem()
    },
    [focusOnPoint, setFocusedItem]
  )

  return useMemo(
    () =>
      items.length &&
      items.map((item) => (
        <CandidateItem
          key={item.id}
          item={item}
          onClick={onClickItem}
          expanded={focusedItem?.candidate?.id === item?.id}
        />
      )),
    [focusedItem, items, onClickItem]
  )
}

export const CandidateItem = ({ item, expanded, onClick }) => {
  const { rejectCandidate, startReviewingCandidate } = useCheckpoint()
  const [clues, dispatch] = useReducer(clueReducer, { regular: [], candidates: [] })
  const { onNextCandidateClues } = useGetClues(dispatch)

  // Fetch clues on expand
  useEffect(() => {
    if (!expanded || clues?.candidates?.length) return
    const { mercatorPath, metadata, path } = item
    const unsubscribe = gdb
      .collection(`${mercatorPath}/clues`)
      .onSnapshot((onNext) => onNextCandidateClues(onNext, { metadata, path }))
    return unsubscribe
  }, [clues, expanded, item, onNextCandidateClues])

  return useMemo(() => {
    const { locationSource, name, requestedAt } = item

    return (
      <Item.Container>
        <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
          <Item.HeaderData colSpan={1}>
            <Item.Center>
              <pre>{locationSource.toUpperCase()}</pre>
            </Item.Center>
          </Item.HeaderData>
          <Item.HeaderData colSpan={3}>{name}</Item.HeaderData>
          <Item.HeaderData colSpan={3}>
            {requestedAt ? format(new Date(requestedAt), 'LLL do, HH:mm') : '-'}
          </Item.HeaderData>
        </Item.Header>

        <Item.Summary expanded={expanded}>
          <RBAC allow={[Role.CREATOR, Role.EDITOR]}>
            <Item.ActionButtons>
              <Button.Secondary size="sm" onClick={() => startReviewingCandidate(item)}>
                <Icon.CheckDoc mr="2" size="sm" /> Review
              </Button.Secondary>
              <Button.Warning size="sm" onClick={() => rejectCandidate(item)}>
                <Icon.ThumbsDown size="sm" mr="2" /> Reject
              </Button.Warning>
            </Item.ActionButtons>
          </RBAC>
          <CandidateSummary item={item} clues={clues?.candidates} />
        </Item.Summary>
      </Item.Container>
    )
  }, [clues, expanded, item, onClick, rejectCandidate, startReviewingCandidate])
}
