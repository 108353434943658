import { isCoordinates, isStringOrNumber } from '.'

export const isValidCountry = ({ centerPoint, id, name }) => {
  if (
    !centerPoint ||
    !isCoordinates(centerPoint.latitude.toPrecision(8)) ||
    !isCoordinates(centerPoint.longitude.toPrecision(8))
  )
    if (!id || !isStringOrNumber(id)) return false
  if (!name || !isStringOrNumber(name)) return false
  return true
}

export const isValidCity = ({ name, centerPoint }) => {
  if (!name || !isStringOrNumber(name)) return false
  if (!centerPoint || !isCoordinates(centerPoint.latitude) || !isCoordinates(centerPoint.longitude))
    return false
  return true
}
