import { useCallback, useEffect, useState } from 'react'
import { db } from '../../lib/firebase'
import { actionTypes } from '../../reducers/checkpointReducer'
import { checkpointConverter } from '../../utility/formatCheckpoints'

const useGetCheckpoints = (dispatch) => {
  const [batch, setBatch] = useState(false)
  const [cursor, setCursor] = useState({ first: null, last: null })
  const [limit, setLimit] = useState(50)
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState()
  const [pageDisabled, setPageDisabled] = useState({ first: true, last: true })
  const [ref, setRef] = useState()

  // Listens to the query and dispatches actions
  useEffect(() => {
    if (!dispatch || !query) return
    const unsubscribe = query.withConverter(checkpointConverter).onSnapshot((next) => {
      let checkpoints = []

      next.docChanges().forEach(async ({ doc, type }) => {
        const checkpoint = doc.data()
        if (type === 'added') checkpoints.push(checkpoint)
        if (type === 'modified') dispatch({ type: actionTypes.checkpoints.edit, checkpoint })
        if (type === 'removed') dispatch({ type: actionTypes.checkpoints.remove, checkpoint })
      })
      // Set all checkpoints at once
      if (checkpoints.length) {
        dispatch({ type: actionTypes.checkpoints.set, checkpoints })
      }
      // Update cursor if we're batching
      if (batch) {
        setPageDisabled((p) => ({ ...p, last: next.docs.length < limit }))
        setCursor({ first: next.docs[0], last: next.docs[next.docs.length - 1] })
      }
    })
    return unsubscribe
  }, [batch, dispatch, limit, query, setCursor])

  // Disable if page == 1
  useEffect(() => {
    if (!batch) return
    setPageDisabled((p) => ({ ...p, first: page === 1 }))
  }, [batch, limit, page])

  const getCheckpoints = useCallback(({ orderBy = 'name', path }) => {
    if (!path) return
    const ref = db.collection(`checkpoints/${path}`)
    setBatch(false)
    setRef(ref)
    setOrderBy(orderBy)
    setQuery(ref.orderBy(orderBy))
  }, [])

  const getPaginatedCheckpoints = useCallback(({ limit = 50, orderBy = 'name', path }) => {
    if (!path) return
    const ref = db.collection(`checkpoints/${path}`)
    setBatch(true)
    setRef(ref)
    setLimit(limit)
    setOrderBy(orderBy)
    setPage(1)
    setQuery(ref.orderBy(orderBy).limit(limit))
  }, [])

  // Query snapshot for checkpoints
  const onNextCheckpoints = useCallback(
    (snapshot) => {
      const checkpoints = []
      snapshot.docChanges().forEach(({ doc, type }) => {
        const checkpoint = doc.data()
        if (type === 'added') checkpoints.push(checkpoint)
        if (type === 'modified') dispatch({ type: actionTypes.checkpoints.edit, checkpoint })
        if (type === 'removed') dispatch({ type: actionTypes.checkpoints.remove, checkpoint })
      })
      if (checkpoints.length) dispatch({ type: actionTypes.checkpoints.addMany, checkpoints })
    },
    [dispatch]
  )

  // Query snapshot for candidates
  const onNextCandidates = useCallback(
    (snapshot) => {
      const candidates = []
      snapshot.docChanges().forEach(async ({ doc, type }) => {
        const candidate = doc.data()
        if (type === 'added') candidates.push(candidate)
        if (type === 'modified') dispatch({ type: actionTypes.candidates.edit, candidate })
        if (type === 'removed') dispatch({ type: actionTypes.candidates.remove, candidate })
      })
      if (candidates.length) dispatch({ type: actionTypes.candidates.addMany, candidates })
    },
    [dispatch]
  )
  // Updates the query to get the next batch of checkpoints
  const getNextPage = useCallback(() => {
    if (!ref) return
    setPage((old) => (!pageDisabled.last ? old : old + 1))
    setQuery(ref.orderBy(orderBy).startAfter(cursor.last).limit(limit))
  }, [ref, pageDisabled, limit, orderBy, cursor])

  // Updates the query to get the previous batch of checkpoints
  const getPreviousPage = useCallback(() => {
    if (!ref) return
    setPage((old) => Math.max(old - 1, 1))
    setQuery(ref.orderBy(orderBy).endBefore(cursor.first.data()[orderBy]).limitToLast(limit))
  }, [ref, limit, orderBy, cursor])

  return {
    getCheckpoints,
    getPaginatedCheckpoints,
    getNextPage,
    getPreviousPage,
    onNextCandidates,
    onNextCheckpoints,
    pageDisabled
  }
}

export default useGetCheckpoints
