import { useCallback } from 'react'
import { theme } from 'twin.macro'

export const useMapLayer = () => {
  // Color country borders
  const colorCountryBorders = useCallback(
    ({ activeCountry, color = theme`colors.gold.500`, countries = [] }) => {
      if (!countries.length) return

      const matchExisting = ['match', ['get', 'ISO2']]
      for (const country of countries) {
        if (activeCountry && activeCountry?.id === country.id) {
          matchExisting.push(country['id'].toUpperCase(), 'rgba(0,0,0,0)')
        } else {
          matchExisting.push(country['id'].toUpperCase(), color)
        }
      }
      matchExisting.push('rgba(0,0,0,0)')
      return matchExisting
    },
    []
  )

  // Color countries
  const colorCountries = useCallback(
    ({ activeCountry, color = theme`colors.gold.600`, countries = [] }) => {
      if (!countries.length) return

      const matchExisting = ['match', ['get', 'ISO2']]
      for (const country of countries) {
        if (activeCountry && activeCountry?.id === country.id) {
          matchExisting.push(country['id'].toUpperCase(), 'rgba(0,0,0,0)')
        } else {
          matchExisting.push(country['id'].toUpperCase(), color)
        }
      }
      matchExisting.push('rgba(0,0,0,0)')
      return matchExisting
    },
    []
  )

  // Color hovered countries
  const colorHoveredCountry = useCallback(
    ({ activeCountry, color = theme`colors.gold.500`, countries = [], newCountry }) => {
      if (!countries.length) return theme`colors.pistachio`

      const matchExisting = ['match', ['get', 'ISO2']]
      for (const country of countries) {
        if (country.id === activeCountry?.id) {
          matchExisting.push(country['id'].toUpperCase(), 'rgba(0,0,0,0)')
        } else {
          matchExisting.push(country['id'].toUpperCase(), color)
        }
      }
      if (newCountry?.id) matchExisting.push(newCountry['id'], 'rgba(0,0,0,0)')
      matchExisting.push(theme`colors.pistachio`)

      return matchExisting
    },
    []
  )

  return { colorCountryBorders, colorCountries, colorHoveredCountry }
}
