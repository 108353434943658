import React, { createContext, useMemo, useState, useContext, useCallback } from 'react'
import { compressOptions } from '../utility/helperFunctions'
import { PRIZE_SUPPLIER_OPTIONS } from '../utility/labeledOptions'
import { flatMap } from '../utility/helperFunctions'
import { PRIZE_CATEGORY_OPTIONS, PRIZE_TAGS_OPTIONS } from '../utility/labeledOptions'
import { db } from '../lib/firebase'
import { useNavigate } from 'react-router-dom'
import { PrizeTab } from '../enums'
import { PhysicalPrizeList } from '../components/Prizes/List/PhysicalPrizeList'
import { DigitalPrizeList } from '../components/Prizes/List/DigitalPrizeList'
import { ArchivedPrizeList } from '../components/Prizes/List/ArchivedPrizeList'
import { Alerts } from '../utility/alerts'
import { prizeConverter } from '../utility/formatPrizes'

const PrizeContext = createContext()

export const PrizeProvider = ({ children }) => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(prizeTabs[PrizeTab.PHYSICAL])
  const [adding, setAdding] = useState(false)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [expandedItem, setExpandedItem] = useState()
  const [fetchingPrize, setFetchingPrize] = useState(false)
  const [tagOptions, setTagOptions] = useState([])
  const [prizeBeingEdited, setPrizeBeingEdited] = useState()
  const [supplierOptions, setSupplierOptions] = useState(PRIZE_SUPPLIER_OPTIONS)

  // Add new Prize
  const addNewPrize = useCallback(
    (deliveryType) => {
      const newDoc = db.collection('prizes').doc()

      setPrizeBeingEdited({ id: newDoc.id, path: newDoc.path, deliveryType, active: true })
      setAdding(true)
      navigate(`${activeTab.path}/create`, { replace: true })
    },
    [activeTab, navigate]
  )

  // Start editing prize
  const startEditingPrize = useCallback(
    (prize) => {
      setPrizeBeingEdited(prize)
      setAdding(false)
      navigate(`${activeTab.path}/${prize.id}`, { replace: true })
    },
    [activeTab, navigate]
  )

  // Stop editing prize
  const stopEditingPrize = useCallback(() => {
    setPrizeBeingEdited()
    setAdding(false)
    navigate(activeTab.path, { replace: true })
  }, [activeTab, navigate])

  // Delete prize
  const deletePrize = useCallback(
    async ({ id }) => {
      const { value: ok } = await Alerts.Prize.CONFIRM_DELETE()
      if (!ok) return

      await db.collection('prizes').doc(id).delete()
      Alerts.Prize.DELETE_SUCCESS()
      stopEditingPrize()
    },
    [stopEditingPrize]
  )

  // Create labeled options
  const createFormOptions = useCallback((prizes) => {
    const prizeTags = prizes?.reduce(
      (acc, current) => ({
        tags: [...acc.tags, current.tags ?? []]
      }),
      { tags: [] }
    )
    const pCategories = prizes?.map((prize) => prize.category || [])

    // Category options
    setCategoryOptions(
      compressOptions(PRIZE_CATEGORY_OPTIONS.concat(pCategories)).sort(
        ({ value: a }, { value: b }) => a.localeCompare(b)
      )
    )
    // Tag options
    setTagOptions(
      compressOptions(flatMap(prizeTags.tags, (t) => t).concat(PRIZE_TAGS_OPTIONS)).sort((a, b) =>
        a?.value.localeCompare(b?.value)
      )
    )
    // Supplier options
    setSupplierOptions((s) =>
      compressOptions(prizes.map((prize) => prize.supplier || []).concat(s)).sort((a, b) =>
        a?.value.localeCompare(b?.value)
      )
    )
  }, [])

  // Pre-fetch prize and expand
  const visitDeepRoute = useCallback(async ({ id }) => {
    try {
      setFetchingPrize(true)
      if (!id) return Alerts.General.ERROR({ message: 'Missing prize id!' })
      const prizeDoc = await db.doc(`prizes/${id}`).withConverter(prizeConverter).get()
      if (!prizeDoc.exists) return Alerts.General.ERROR({ message: "This prize doesn't exist!" })

      const prize = prizeDoc.data()
      if (!prize.active) setActiveTab(prizeTabs[PrizeTab.ARCHIVED])
      else setActiveTab(prizeTabs[prize.deliveryType.toUpperCase()])

      return setExpandedItem(prize)
    } catch (err) {
      Alerts.General.ERROR(err)
      console.error(err)
    } finally {
      setFetchingPrize(false)
    }
  }, [])

  const defaultValues = useMemo(
    () => ({
      activeTab,
      adding,
      addNewPrize,
      categoryOptions,
      createFormOptions,
      deletePrize,
      expandedItem,
      fetchingPrize,
      prizeBeingEdited,
      prizeTabs,
      setActiveTab,
      setExpandedItem,
      startEditingPrize,
      stopEditingPrize,
      supplierOptions,
      tagOptions,
      visitDeepRoute
    }),

    [
      activeTab,
      adding,
      addNewPrize,
      categoryOptions,
      createFormOptions,
      deletePrize,
      expandedItem,
      fetchingPrize,
      prizeBeingEdited,
      startEditingPrize,
      stopEditingPrize,
      supplierOptions,
      tagOptions,
      visitDeepRoute
    ]
  )
  return <PrizeContext.Provider value={defaultValues}>{children}</PrizeContext.Provider>
}

// Hook
export const usePrizes = () => {
  const context = useContext(PrizeContext)
  if (context === undefined)
    throw new Error('`usePrizes` hook must be used within a `PrizeProvider` component')
  return context
}

const prizeTabs = {
  [PrizeTab.PHYSICAL]: {
    id: 'PHYSICAL',
    component: Object.assign(() => <PhysicalPrizeList />),
    path: 'physical',
    title: 'Physical'
  },
  [PrizeTab.DIGITAL]: {
    component: Object.assign(() => <DigitalPrizeList />),
    id: 'DIGITAL',
    path: 'digital',
    title: 'Digital'
  },
  [PrizeTab.ARCHIVED]: {
    component: Object.assign(() => <ArchivedPrizeList />),
    id: 'ARCHIVED',
    path: 'archived',
    title: 'Archived'
  }
}
