import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../lib/firebase'
import { challengeConverter } from '../utility/formatChallenges'
import { challengeReducer } from '../reducers/challengeReducer'
import { compressOptions, flatMap } from '../utility/helperFunctions'
import { Alerts } from '../utility/alerts'
import useGetChallenges from '../hooks/firebase/useGetChallenges'

export const ChallengeContext = createContext()

export const ChallengeProvider = (props) => {
  const [challenges, dispatch] = useReducer(challengeReducer, [])
  const navigate = useNavigate()
  const { onNextChallenges } = useGetChallenges(dispatch)

  const [adding, setAdding] = useState(false)
  const [challengeBeingEdited, setChallengeBeingEdited] = useState()
  const [customTagOptions, setCustomTagOptions] = useState()
  const [loading, setLoading] = useState(false)

  // Add new challenge
  const addNewChallenge = useCallback(
    (initial) => {
      // Create new document
      const newDoc = db.collection('challenges').doc()
      setChallengeBeingEdited({
        active: true,
        id: newDoc.id,
        path: newDoc.path,
        ...initial
      })
      setAdding(true)
      navigate('create')
    },
    [navigate]
  )

  // Start editing challenge
  const startEditingChallenge = useCallback(
    (challenge) => {
      setChallengeBeingEdited(challenge)
      setAdding(false)
      navigate(`challenges/${challenge.id}`)
    },
    [navigate]
  )

  // Stop editing challenge
  const stopEditingChallenge = useCallback(() => {
    setChallengeBeingEdited()
    setAdding(false)
  }, [])

  // Save challenge
  const saveChallenge = useCallback(
    async (challenge) => {
      try {
        const { path } = challenge
        const challengeRef = db.doc(path)
        if (!path) throw new Error('Missing path')
        setLoading(true)

        await challengeRef.withConverter(challengeConverter).set(challenge, { merge: true })
        Alerts.Challenge.SAVE_SUCCESS(adding)

        stopEditingChallenge()
      } catch (err) {
        console.error(err)
        Alerts.Challenge.SAVE_FAILED(adding, err)
      } finally {
        setLoading(false)
      }
    },
    [adding, stopEditingChallenge]
  )

  // Listen to collection changes for unlisted hvnts
  useEffect(() => {
    const unsubscribe = db
      .collection('challenges')
      .withConverter(challengeConverter)
      .onSnapshot(onNextChallenges)
    return unsubscribe
  }, [onNextChallenges])

  // Update custom tag options
  useEffect(() => {
    const customTags = challenges?.reduce((acc, current) => [...acc, current.customTags ?? []], [])
    setCustomTagOptions(compressOptions(flatMap(customTags, (t) => t)))
  }, [challenges])

  const defaultValues = useMemo(
    () => ({
      adding,
      addNewChallenge,
      challengeBeingEdited,
      challenges,
      customTagOptions,
      loading,
      saveChallenge,
      setLoading,
      startEditingChallenge,
      stopEditingChallenge
    }),
    [
      adding,
      addNewChallenge,
      challengeBeingEdited,
      challenges,
      customTagOptions,
      loading,
      saveChallenge,
      startEditingChallenge,
      stopEditingChallenge
    ]
  )
  return (
    <ChallengeContext.Provider value={defaultValues}>{props.children}</ChallengeContext.Provider>
  )
}

// Hook
export const useChallenge = () => {
  const context = useContext(ChallengeContext)
  if (context === undefined)
    throw new Error('`useChallenge` hook must be used within a `ChallengeProvider` component')
  return context
}
