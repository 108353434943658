import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import tw, { styled } from 'twin.macro'
import Icon from '../styles/Icons'
import { Button } from '../styles/Layout'
import { ImageCropper } from './ImageCropper'

export const Modal = ({
  body,
  disabled = false,
  title = 'Modal',
  onCancel,
  onOpen,
  onSubmit,
  showCancelButton = true,
  showSubmitButton = true,
  CancelButton = Object.assign((props) => (
    <Button.White {...props}>
      <Icon.Close mr="2" />
      Cancel
    </Button.White>
  )),
  OpenButton = Object.assign((props) => (
    <Button.Gray {...props}>
      <Icon.Star mr="2" />
      Open Modal
    </Button.Gray>
  )),
  SubmitButton = Object.assign((props) => (
    <Button.Submit {...props}>
      <Icon.Check mr="2" />
      Save
    </Button.Submit>
  )),
  ...props
}) => {
  const [active, setActive] = useState(false)
  const onClose = () => {
    onCancel && onCancel()
    setActive(false)
  }

  const onSave = () => {
    onSubmit && onSubmit()
    setActive(false)
  }

  const RenderContent = (props) => (
    <Content {...props}>
      <Header>
        {title}
        <CloseX onClick={onClose}>
          <Icon.Close size="lg" />
        </CloseX>
      </Header>
      <Body>{!!body && body()}</Body>
      <Footer>
        {showSubmitButton && <SubmitButton onClick={onSave} />}
        {showCancelButton && <CancelButton onClick={onClose} />}
      </Footer>
    </Content>
  )

  return (
    <>
      <OpenButton
        as={ModalButton}
        disabled={disabled}
        onClick={() => {
          !disabled && setActive(true)
          onOpen && onOpen(true)
        }}
      />

      {active &&
        createPortal(
          <Background active={active}>
            <Overlay onClick={onClose} />
            {RenderContent(props)}
          </Background>,
          document.body
        )}
    </>
  )
}

export const ImageModal = ({
  aspect = { x: 1, y: 1 },
  disabled,
  flagUpload,
  imageUrl,
  loading,
  OpenButton,
  setImageUrl,
  title,
  ...props
}) => {
  const [tempImage, setTempImage] = useState()

  const saveImage = (image) => {
    setImageUrl(image)
    setTempImage()
    flagUpload(!!image)
  }
  const onCancelImage = () => {
    setTempImage()
  }
  const onResetImage = () => {
    setImageUrl()
    flagUpload(false)
  }

  return (
    <div tw="flex">
      <Modal
        OpenButton={OpenButton}
        body={() => (
          <ImageCropper
            aspect={aspect}
            imageData={imageUrl}
            flagUpload={flagUpload}
            setImage={setTempImage}
            reset={onResetImage}
          />
        )}
        disabled={disabled || loading}
        title={title}
        onCancel={onCancelImage}
        onSubmit={() => saveImage(tempImage)}
        SubmitButton={(props) => (
          <Button.Submit {...props} disabled={tempImage === undefined}>
            <Icon.Check mr="2" />
            Save
          </Button.Submit>
        )}
        {...props}
        tw="w-min h-3/4s bg-red"
      />
    </div>
  )
}

const Background = styled.div`
  ${tw`fixed w-screen h-screen top-0 left-0 flex items-start p-12 justify-center zIndex[1059]`} // swal uses 1060
  transition: opacity 200ms ease;
  ${({ active }) => (active ? tw`pointer-events-auto` : tw`opacity-0 pointer-events-none`)}
`
const ModalButton = styled.div`
  ${({ disabled }) => !disabled && tw`hover:cursor-pointer`}
`
const Overlay = tw.div`fixed top-0 left-0 w-screen h-screen flex bg-black opacity-80`
const Content = tw.div`fixed flex flex-col rounded-md overflow-hidden w-full lg:w-2/3 max-h-4/5s`
const Header = tw.div`relative flex w-full justify-center items-center font-light text-2xl p-3 bg-bismark text-gray-100 select-none`
const Footer = tw.div`flex justify-center py-2 bg-bismark text-gray-100 gap-2`
const CloseX = tw.div`absolute right-3 hover:bg-bismark-600 active:bg-bismark-700 hover:cursor-pointer p-1 rounded-md`
const Body = tw.div`flex items-start bg-gray-200 overflow-y-scroll min-height[250px] h-full`
