import React, { useState, useEffect, useReducer } from 'react'
import tw from 'twin.macro'
import { db } from '../../../lib/firebase'
import { prizeConverter } from '../../../utility/formatPrizes'
import useGetPrizes from '../../../hooks/firebase/useGetPrizes'
import { prizeReducer } from '../../../reducers/prizeReducer'
import useSort from '../../../hooks/useSort'
import useFilter from '../../../hooks/useFilter'
import { usePrizes } from '../../../contexts/PrizeContext'
import RBAC from '../../RBAC'
import { PrizeSorts, Role } from '../../../enums'
import { Card } from '../../../styles/Layout'
import { List, Action } from '../../../layout/List'
import { PrizeFilter } from '../PrizeFilter'
import { Items } from '../PrizeItem'
import { useDebounce } from 'react-use'

export const ArchivedPrizeList = () => {
  const [prizes, dispatch] = useReducer(prizeReducer, [])
  const { onNextPrizes } = useGetPrizes(dispatch)
  const { expandedItem, prizeBeingEdited } = usePrizes()
  const [filteredItems, setFilteredItems] = useState([])
  const { applySort, sortingBy, sortListBy } = useSort(PrizeSorts.STATUS)
  const { applyFilters, ...filterMethods } = useFilter()

  // Subscribe to archived prizes
  useEffect(() => {
    const unsubscribe = db
      .collection('prizes')
      .where('active', '==', false)
      .withConverter(prizeConverter)
      .onSnapshot(onNextPrizes)
    return unsubscribe
  }, [onNextPrizes])

  // Filter and sort items
  useEffect(() => {
    setFilteredItems(applySort(applyFilters(prizes)))
  }, [applyFilters, applySort, prizes, setFilteredItems])

  // Scroll to item after 100ms to allow time for item collapse
  useDebounce(
    () => {
      if (!expandedItem || !prizes.length) return
      const element = document.getElementById(expandedItem?.id)
      if (!element) return

      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    100,
    [expandedItem, prizes]
  )

  return (
    <Card.Container>
      <RBAC allow={[Role.CREATOR]}>
        <List.Filter data-testid="prize-filter">
          <PrizeFilter filter={filterMethods} items={prizes} />
        </List.Filter>
      </RBAC>
      <Action.Header>
        <Action.Right>
          <Action.More />
        </Action.Right>
      </Action.Header>

      <List.Header>
        <List.HeaderData
          colSpan={1}
          onClick={() => sortListBy(PrizeSorts.STATUS)}
          active={sortingBy?.sort === PrizeSorts.STATUS}
          inc={sortingBy.inc}
          tw="text-center"
        >
          Status
        </List.HeaderData>

        <List.HeaderData
          colSpan={3}
          onClick={() => sortListBy(PrizeSorts.NAME)}
          active={sortingBy?.sort === PrizeSorts.NAME}
          inc={sortingBy.inc}
        >
          Name
        </List.HeaderData>

        <List.HeaderData
          colSpan={2}
          onClick={() => sortListBy(PrizeSorts.TYPE)}
          active={sortingBy?.sort === PrizeSorts.TYPE}
          inc={sortingBy.inc}
        >
          Type
        </List.HeaderData>

        <List.HeaderData
          colSpan={1}
          onClick={() => sortListBy(PrizeSorts.AVAILABLE)}
          active={sortingBy?.sort === PrizeSorts.AVAILABLE}
          inc={sortingBy.inc}
        >
          Available
        </List.HeaderData>

        <List.HeaderData
          colSpan={1}
          onClick={() => sortListBy(PrizeSorts.RESERVED)}
          active={sortingBy?.sort === PrizeSorts.RESERVED}
          inc={sortingBy.inc}
        >
          Reserved
        </List.HeaderData>

        <List.HeaderData
          colSpan={1}
          onClick={() => sortListBy(PrizeSorts.CLAIMED)}
          active={sortingBy?.sort === PrizeSorts.CLAIMED}
          inc={sortingBy.inc}
        >
          Claimed
        </List.HeaderData>

        <List.HeaderData
          colSpan={2}
          onClick={() => sortListBy(PrizeSorts.ARCHIVED_AT)}
          active={sortingBy?.sort === PrizeSorts.ARCHIVED_AT}
          inc={sortingBy.inc}
        >
          Archived
        </List.HeaderData>
      </List.Header>
      <List.Container>
        {!prizeBeingEdited && filteredItems.length ? (
          <Items items={filteredItems} />
        ) : (
          <Empty>{'Nothing here...'}</Empty>
        )}
      </List.Container>
    </Card.Container>
  )
}
const Empty = tw.div`grid justify-center pt-6 pb-4 italic text-sm text-gray`
