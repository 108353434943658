export { Action } from './Action'
export { Item } from './Item'
import React from 'react'
import tw, { css, styled } from 'twin.macro'

export const List = {
  Container: tw.div`flex flex-col flex-1 w-full overflow-scroll bg-white`,
  Empty: tw.div`flex justify-center p-6 italic text-sm text-gray-600`,
  Filter: tw.div`relative flex flex-col w-full bg-gray-200 z-40 text-gray-800 text-sm`,
  Header: Object.assign(({ children, ...props }) => (
    <HeaderContainer {...props}>
      <Table>
        <tbody>
          <tr>{children}</tr>
        </tbody>
      </Table>
    </HeaderContainer>
  )),
  HeaderData: styled.td`
    ${tw`relative bg-gray-200 hover:bg-gray-300 p-2 cursor-pointer truncate select-none gap-0.5 font-medium text-sm`};
    ${({ active, inc }) => css`
      ${active && (inc ? ' &:before { content: "↓ "; }' : '&:before { content: "↑ "; }')}
    `}
  `,
  Sticky: tw.div`sticky top-0 z-30`
}

const HeaderContainer = tw.div`flex flex-col w-full bg-gray-200 text-gray-800 z-20 shadow-md`
const Table = tw.table`table-fixed w-full`
