import React, { useCallback, useMemo, useRef, useState } from 'react'
import tw from 'twin.macro'
import { Loader } from '../../Loader/Loader'
import { Map } from 'react-map-gl'
import config from '../../../config'
import { useMapControl } from '../../../hooks/useMapControl'
import { useDebounce } from 'react-use'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useMap } from '../../../contexts/MapContext'
import { XpLocations } from './XpLocations'
import { PlayerLocation } from './PlayerLocation'
import { TransactionLocations } from './TransactionLocations'
import { usePlayer } from '../../../contexts/PlayerContext'
import { ProfileTab } from '../../../enums'

export const PlayerMap = () => {
  const mapRef = useRef(null)
  const { activeTab } = usePlayer()
  const { containerRef } = useMapControl(mapRef)
  const { isMounted } = useIsMounted()
  const { focusedBbox, focusedMarker } = useMap()
  const { zoomToBbox, zoomToPoint } = useMapControl(mapRef)

  const [busy, setBusy] = useState(true)
  const [cursor] = useState('auto')
  const [viewstate, setViewstate] = useState({
    longitude: config.DEFAULT_COORDS.longitude,
    latitude: config.DEFAULT_COORDS.latitude,
    maxZoom: 20,
    zoom: 3
  })

  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedMarker) return zoomToPoint(focusedMarker)
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    200,
    [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint]
  )

  return useMemo(
    () => (
      <MapContainer ref={containerRef}>
        <Loader loading={busy} text="Loading Map" />
        <Map
          {...viewstate}
          cursor={cursor}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
          mapStyle={config.mapbox.NORTH_STAR}
          onLoad={() => setBusy(false)}
          onMove={onMoveMap}
          ref={mapRef}
          style={{ width: '100%', height: '100%' }}
        >
          <PlayerLocation />
          {activeTab?.id === ProfileTab.TRANSACTIONS && <TransactionLocations />}
          {activeTab?.id === ProfileTab.XP && <XpLocations />}
        </Map>
      </MapContainer>
    ),
    [activeTab, busy, containerRef, cursor, onMoveMap, viewstate]
  )
}

const MapContainer = tw.div`relative flex w-full overflow-hidden justify-center items-center`
