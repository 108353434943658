import React, { Fragment } from 'react'
import { PrizeDeliveryType } from '../../enums'
import { CreateDigitalPrize } from './CreateDigitalPrize'
import { CreatePhysicalPrize } from './CreatePhysicalPrize'

export const CreatePrize = (prize) => {
  switch (prize?.deliveryType) {
    case PrizeDeliveryType.DIGITAL:
      return <CreateDigitalPrize prize={prize} />
    case PrizeDeliveryType.PHYSICAL:
      return <CreatePhysicalPrize prize={prize} />
    default:
      return <Fragment />
  }
}
