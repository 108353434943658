import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import tw from 'twin.macro'
import { useSeason } from '../../../contexts/SeasonsContext'
import Icon from '../../../styles/Icons'
import { Input } from '../../../styles/Layout'
import { Switch } from '../../Layout/Switch'
import { CountryItem } from './CountryItem'

export const AddCountries = ({ locked, remove }) => {
  const { loading } = useSeason()
  const {
    clearErrors,
    control,
    formState: { errors },
    watch
  } = useFormContext()
  const { countries, playableWorldWide } = watch()

  const onRemoveCountry = useCallback(
    (id) => {
      remove(countries.findIndex((c) => c.countryId === id))
    },
    [countries, remove]
  )

  return (
    <Container>
      <>
        <Controller
          control={control}
          name="playableWorldWide"
          render={({ field: { onChange, value } }) => (
            <Switch
              role="switch"
              defaultChecked={value}
              color="blue"
              disabled={loading || locked}
              onToggle={(v) => {
                v && clearErrors('countries')
                onChange(v)
              }}
              size="lg"
              checked={value}
              label={
                <div tw="flex gap-2">
                  {'Playable World Wide'}
                  <Icon.Globe size="lg" />
                </div>
              }
            />
          )}
        />
        {!playableWorldWide && (
          <>
            {!locked && (
              <>
                <Divider />
                <Info>Select countries to include for this season</Info>
              </>
            )}

            {!!countries?.length && (
              <>
                <Divider />
                <ListContainer>
                  {countries?.map((item, i) => (
                    <CountryItem
                      {...item}
                      key={i}
                      locked={locked}
                      onRemove={() => !locked && onRemoveCountry(item.id)}
                    />
                  ))}
                </ListContainer>
              </>
            )}
          </>
        )}
        {errors.countries && <Input.Error tw="text-base">{errors.countries.message}</Input.Error>}
      </>
    </Container>
  )
}
const Container = tw.div`flex flex-col overflow-hidden select-none py-2 px-3 shadow-lg rounded-md gap-3
items-center bg-white  border-4 bg-opacity-90`
const Info = tw.div`text-sm py-2`
const ListContainer = tw.div`flex flex-col w-full gap-1 overflow-scroll`
const Divider = tw.div`w-full border-b-2 border-gray-300`
