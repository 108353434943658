import React from 'react'
import tw from 'twin.macro'
import Icon from '../../../styles/Icons'
import { Switch } from '../../Layout/Switch'

export const AddPin = ({ adding, color = 'blue', disabled, onClick }) => {
  return (
    <Container>
      <Row>
        <Switch
          defaultChecked={false}
          color={color}
          disabled={disabled}
          onToggle={onClick}
          size="lg"
          checked={adding}
          label={
            <div tw="flex">
              {'Add Checkpoint Pin'}&nbsp;
              <Icon.PinSolid size="sm" />
            </div>
          }
        />
      </Row>
      {adding && <div>Click the map to set checkpoint location</div>}
    </Container>
  )
}

const Container = tw.div`absolute top-2 flex flex-col z-10 p-2 bg-white text-bismark-700 
rounded-md shadow-lg bg-opacity-90 border-4 items-center gap-2`
const Row = tw.div`flex items-center gap-2 text-lg `
