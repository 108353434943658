import React, { useState } from 'react'
import tw from 'twin.macro'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Authenticate, Button, Input } from '../../styles/Layout'
import { useUser } from '../../contexts/UserContext'
import { handleError } from '../../utility/handleErrors'
import * as alert from 'sweetalert2'
import Icon from '../../styles/Icons'

export const ForgotPassword = () => {
  const { sendPasswordResetEmail } = useUser()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {
    formState: { errors },
    setError,
    register,
    handleSubmit
  } = useForm()

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await sendPasswordResetEmail(data)
      alert.fire({
        title: 'Check your inbox!',
        html: `We just sent an email to <b>${data.email}</b> with a password reset link`,
        icon: 'success',
        confirmButtonText: 'Thanks!'
      })
    } catch (err) {
      setError('submit', { type: 'manual', message: handleError(err) })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Authenticate.Container {...register('submit')}>
        <Authenticate.Header>Password Reset</Authenticate.Header>
        <Authenticate.Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input.Label required>Email</Input.Label>
            <Input.Default {...register('email', { required: 'Email is required' })} size="lg" />
            <Input.Error>{errors.email?.message}</Input.Error>
          </div>

          <Input.Error>{errors.submit?.message}</Input.Error>

          <ButtonContainer>
            <Button.Secondary size="lg" type="submit" disabled={loading}>
              <Icon.Mail mr="2" /> Send reset link
            </Button.Secondary>
            <Button.Filter
              onClick={() => navigate('/login')}
              disabled={loading}
              tw="text-orange text-base mt-2 mx-1 hover:bg-gray-100 active:bg-gray-200"
            >
              Login
            </Button.Filter>
          </ButtonContainer>
        </Authenticate.Form>
      </Authenticate.Container>
    </>
  )
}
const ButtonContainer = tw.div`flex flex-col`
