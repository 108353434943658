import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import tw from 'twin.macro'
import { useChallenge } from '../../../contexts/ChallengeContext'
import { TriviaType } from '../../../enums'
import { Info, Input } from '../../../styles/Layout'
import {
  DIFFICULTY_OPTIONS,
  TRIVIA_CATEGORY_OPTIONS,
  TRIVIA_TYPE_OPTIONS
} from '../../../utility/labeledOptions'
import { Section } from '../../Hvnt/FormSection'
import { Switch } from '../../Layout/Switch'
import { SelectOneForm } from './SelectOneForm'
import Dropdown from '../../Dropdown'

export const TriviaForm = () => {
  const { customTagOptions } = useChallenge()
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext()
  const [custom, triviaType] = useWatch({ name: ['custom', 'triviaType'] })

  const renderForm = (type) => {
    switch (type?.value) {
      case TriviaType.ONE_CORRECT:
        return <SelectOneForm />
    }
  }

  return (
    <>
      <Section label="Select trivia type" field={'triviaType'} error={errors.triviaType} required>
        <Input.Label>Trivia type</Input.Label>
        <Controller
          control={control}
          name="triviaType"
          render={({ field }) => (
            <StyledDropdown
              {...field}
              isOptionDisabled={({ value }) =>
                [TriviaType.INPUT, TriviaType.MANY_CORRECT].includes(value)
              }
              options={TRIVIA_TYPE_OPTIONS}
            />
          )}
          rules={{ required: 'This is required' }}
        />
        <Input.Error>{errors.triviaType?.message}</Input.Error>
      </Section>

      {renderForm(triviaType)}

      {triviaType && (
        <>
          <Section label="Select a category" field={'category'} error={errors.category} required>
            <Input.Label>Category</Input.Label>
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <StyledDropdown {...field} options={TRIVIA_CATEGORY_OPTIONS} />
              )}
              rules={{ required: 'This is required' }}
            />
            <Input.Error>{errors.category?.message}</Input.Error>
          </Section>
          <Section
            label="How difficult is it?"
            field={'difficulty'}
            error={errors.difficulty}
            required
          >
            <Input.Label>Difficulty</Input.Label>
            <Controller
              control={control}
              name="difficulty"
              render={({ field }) => <StyledDropdown {...field} options={DIFFICULTY_OPTIONS} />}
              rules={{ required: 'This is required' }}
            />
            <Input.Error>{errors.difficulty?.message}</Input.Error>
          </Section>
          <Section
            label="Exclusive to a specific location?"
            field={'customTags'}
            error={!!custom && errors.customTags}
            tw="gap-4"
          >
            <Controller
              control={control}
              name="custom"
              render={({ field: { onChange, value } }) => (
                <Switch
                  color="blue"
                  size="md"
                  label="Yes"
                  defaultChecked={value}
                  onToggle={(v) => {
                    onChange(v)
                    !v && setValue('customTags')
                  }}
                />
              )}
            />
            {custom && (
              <Col>
                <Input.Label>Add custom tag</Input.Label>
                <Controller
                  control={control}
                  name="customTags"
                  render={({ field }) => (
                    <Dropdown.Creatable
                      {...field}
                      isMulti={true}
                      isClearable={true}
                      menuPlacement="top"
                      options={customTagOptions}
                      placeholder="Select or create a new custom tag"
                    />
                  )}
                  rules={{ required: 'This is required!', shouldUnregister: true }}
                />
                <Input.Error>{errors.customTags?.message}</Input.Error>
              </Col>
            )}
            <Info>
              <span>These unique tags are used to associate challenges to locations</span>
              <span>
                This is done on location creation, so when creating checkpoints or event locations
              </span>
            </Info>
          </Section>
        </>
      )}
    </>
  )
}

const StyledDropdown = tw(Dropdown.Select)`w-full sm:w-1/2`
const Col = tw.div`flex flex-col`
