import config from '../../config'
import { Timestamp } from '../../lib/firebase'
export { isValidCircle } from './hvnt'
export { isValidCity, isValidCountry } from './area'
export { isValidPrize, isValidSeasonPrizes, isValidHvnt } from './prize'

export const isBlob = (obj) => new Blob([obj], { type: 'text/plain' }) instanceof Blob
export const isCloudinaryImage = (obj) =>
  obj?.startsWith(`https://res.cloudinary.com/${config.cloudinary.NAME}/`)
export const isCoordinates = (obj) => /^-?\d{1,3}\.\d{1,}$/.test(obj) // Accepts 1 digit accuracy
export const isDate = (obj) => obj && !isNaN(new Date(obj.toString()).getDate())
export const isEmail = (obj) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(obj).toLowerCase()
  )
export const isGeoPoint = (obj) => {
  obj ??= {} // Reassigning prevents obj=null to slip past
  const { latitude, longitude } = obj
  return latitude && longitude && isCoordinates(latitude) && isCoordinates(longitude)
}
export const isInteger = (obj) => Number.isInteger(Number(obj))
export const isJson = (obj) => {
  try {
    JSON.parse(obj)
    return true
  } catch (_) {
    return false
  }
}
export const isJsonParsable = (string) => {
  try {
    JSON.parse(string)
  } catch (err) {
    return false
  }
  return true
}
export const isNumber = (obj) => !isNaN(obj)
export const isPhoneNumber = (obj) => /^\+[1-9]{1}[0-9]{3,14}$/.test(obj)
export const isStringOrNumber = (obj) => !!obj?.length && (typeof obj === 'string' || !isNaN(obj))
export const isTimestamp = (obj) => obj && obj instanceof Timestamp
export const isRelativeUrl = (obj) => /^[^/]+\/[^/].*\.[a-zA-Z]+$/.test(obj)

export const isValidFirestoreKey = (text) =>
  typeof text === 'string' && text.length && !text.match(/[.$[\]#/]/)

export const noFeatures = (obj) => !obj?.features || !obj.features.length
export const objectIsEmpty = (obj) => !obj || !Object.keys(obj).length
export const arraysEqual = (a, b) => {
  if (a === b) return true
  if (a === null || b === null) return false
  if (a.length !== b.length) return false
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

/* eslint-disable */

export const isImageUrl = (obj) =>
  /^(?:https?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\wåäöÅÄÖ\/\]\-._~:?#"[@!$&'()*+%,;=.]+(?:png|jpg|jpeg|webp)+$/gi.test(
    obj
  )
export const isGoogleImage = (obj) =>
  /^(https:\/\/maps.googleapis.com\/maps\/api\/place\/js\/photoservice.GetPhoto)[\wåäöÅÄÖ\/\]\-._~:?#"[@!$&'()*+%,;=.]+/gi.test(
    obj
  )
export const isUrl = (obj) =>
  /^(?:http(s)?:\/\/)([\wåäö.-])+(?:[\wåäö\.-]+)+([\wåäö\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.])+$/gi.test(
    obj
  )

export const isValidDateRange = (dateRange = {}) => {
  const { startDate, endDate } = dateRange
  return isTimestamp(startDate) && isTimestamp(endDate)
    ? endDate.toDate() > startDate.toDate()
    : endDate > startDate
}
