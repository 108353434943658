import React, { useCallback, useEffect, useMemo } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { ManageClues } from '../Clue/ManageClues'
import { Button, Card } from '../../styles/Layout'
import { useForm, FormProvider } from 'react-hook-form'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useClue } from '../../contexts/ClueContext'
import Icon from '../../styles/Icons'
import { db } from '../../lib/firebase'
import { checkpointConverter } from '../../utility/formatCheckpoints'
import { CheckpointForm } from './CheckpointForm'
import { Alerts, Toast } from '../../utility/alerts'
import request from '../../request'
import { Loader } from '../Loader/Loader'

export const CreateCheckpoint = ({ checkpoint }) => {
  const { mounted } = useIsMounted()
  const {
    acceptCandidate,
    adding,
    clueTabOpen,
    editingClue,
    loading,
    rejectCandidate,
    reviewing,
    saveCheckpoint,
    setClueTabOpen,
    stopEditingCheckpoint,
    stopReviewingCandidates,
    submitDisabled
  } = useCheckpoint()

  const { checkClueRequirements } = useClue()
  const methods = useForm({ defaultValues: { custom: false, ...checkpoint }, mode: 'all' })
  const {
    formState: { errors },
    setValue,
    reset,
    watch
  } = methods
  const { name } = watch()

  const checkpointErrors = !!errors?.name || !!errors?.centerPoint
  const checkpointDoc = useMemo(() => checkpoint?.path && db.doc(checkpoint.path), [checkpoint])

  // Warns if checkpoint changed during edit
  const integrityWarning = useCallback(async () => {
    const { value: keep } = await mounted(Alerts.Integrity.CONTENT_CHANGED())
    return keep
  }, [mounted])

  // Close clues on unmount
  useEffect(() => {
    return () => setClueTabOpen(false)
  }, [setClueTabOpen])

  // Detects checkpoint changes during edit
  useEffect(() => {
    if (adding || !checkpointDoc || loading) return

    const unsubscribe = checkpointDoc
      .withConverter(checkpointConverter)
      .onSnapshot(async (snapshot) => {
        const { fromCache } = snapshot.metadata
        if (!fromCache) {
          const { active, ...checkpoint } = snapshot.data()
          reset({ active }) // update reset regardless
          const keep = await integrityWarning()
          if (!keep) reset(checkpoint)
        }
      })
    return unsubscribe
  }, [adding, checkpointDoc, integrityWarning, loading, reset])

  // Updates the centerPoint on external changes (marker move)
  useEffect(() => {
    const addTags = async ({ centerPoint }) => {
      let tags = []
      // Tags for candidates
      // todo get tags from metadata
      if (checkpoint.candidate)
        tags.push(...['auto', checkpoint.locationSource].map((tag) => ({ value: tag, label: tag })))

      // Get geo tags
      tags.push(...(await mounted(request.getTags({ centerPoint }))))
      setValue('tags', tags)
    }

    const { centerPoint, tags } = checkpoint
    if (!centerPoint) return
    setValue('centerPoint', centerPoint)
    !tags?.length && addTags({ centerPoint })
  }, [checkpoint, mounted, setValue])

  const onSubmit = useCallback(
    async (formData) => {
      try {
        // Check clues
        await checkClueRequirements()
        if (reviewing) await acceptCandidate({ ...checkpoint, ...formData })
        else await saveCheckpoint({ ...checkpoint, ...formData })
      } catch (err) {
        if (err.alert) return err.alert
        if (err.dismiss) return
        Alerts.Checkpoint.UPDATE_FAILED(adding, err)
      }
    },
    [acceptCandidate, adding, checkClueRequirements, checkpoint, reviewing, saveCheckpoint]
  )

  const onCancel = useCallback(async () => {
    if (reviewing) {
      const { value: ok } = await Alerts.Checkpoint.UNSAVED_CHANGES_EDITING()
      if (!ok) return
      stopReviewingCandidates()
    }

    stopEditingCheckpoint()
  }, [reviewing, stopEditingCheckpoint, stopReviewingCandidates])

  const onDeleteCheckpoint = useCallback(async () => {
    const { value: remove } = await mounted(Alerts.Checkpoint.DELETE_WARNING())
    if (remove) {
      try {
        checkpointDoc.delete()
        Toast.fire({
          title: 'Checkpoint deleted!',
          icon: 'success'
        })
        stopEditingCheckpoint()
      } catch (err) {
        console.error(err)
      }
    }
  }, [checkpointDoc, mounted, stopEditingCheckpoint])

  const onActivate = useCallback(async () => {
    const { value: ok, dismiss } = await mounted(Alerts.Checkpoint.ACTIVATE_CONFIRM())
    if (ok) {
      await db.runTransaction(async (transaction) => {
        const { path } = checkpoint
        const clues = await db.collection(`${path}/clues`).get()
        clues.forEach((clueDoc) => {
          transaction.set(clueDoc.ref, { active: true }, { merge: true })
        })
        setValue('active', true)
      })
      // Cancel means only checkpoint
    } else if (dismiss === 'cancel') {
      setValue('active', true)
    } else return
  }, [checkpoint, mounted, setValue])

  const onDeactivate = useCallback(async () => {
    const { value: ok } = await mounted(Alerts.Checkpoint.DEACTIVATE_INFO())
    if (!ok) return

    await db.runTransaction(async (transaction) => {
      const { path } = checkpoint
      const clues = await db.collection(`${path}/clues`).get()
      clues.forEach((clueDoc) => {
        transaction.set(clueDoc.ref, { active: false }, { merge: true })
      })
      setValue('active', false)
    })
  }, [checkpoint, mounted, setValue])

  return (
    <Card.Container
      header={
        <div tw="flex items-center gap-2">
          {reviewing ? `Reviewing ${name}` : adding ? 'New Checkpoint' : name}
        </div>
      }
      color={theme`colors.gold.500`}
      xHover={theme`colors.gold.600`}
      xActive={theme`colors.gold.700`}
      xDisabled={submitDisabled}
      onClose={onCancel}
    >
      <Loader loading={loading} />
      <Tabs>
        <Tab
          active={!clueTabOpen}
          color="gold"
          error={checkpointErrors}
          onClick={() => setClueTabOpen(false)}
        >
          Checkpoint
        </Tab>
        <Tab
          active={clueTabOpen}
          color="norway"
          error={!clueTabOpen && editingClue}
          onClick={() => setClueTabOpen(true)}
        >
          Clues
        </Tab>
      </Tabs>

      <FormProvider {...methods}>
        <FormContainer cluesOpen={clueTabOpen}>
          <CheckpointWrapper>
            <CheckpointForm
              {...methods}
              onActivate={onActivate}
              onDeactivate={onDeactivate}
              onDelete={onDeleteCheckpoint}
            />
          </CheckpointWrapper>
          <CluesWrapper>
            <ManageClues checkpoint={checkpoint} />
          </CluesWrapper>
        </FormContainer>

        <Card.Footer color={theme`colors.gold.400`}>
          <Button.White
            onClick={() => onCancel(false)}
            disabled={loading || submitDisabled}
            ring
            tw="mr-auto"
          >
            <Icon.Close mr="2" />
            Cancel
          </Button.White>
          <Button.Submit
            onClick={methods.handleSubmit((data) => onSubmit(data))}
            disabled={editingClue || loading || submitDisabled}
            ring
          >
            {reviewing ? (
              <>
                <Icon.ThumbsUp mr="2" />
                Add Checkpoint
              </>
            ) : (
              <>
                <Icon.Check mr="2" />
                Save Checkpoint
              </>
            )}
          </Button.Submit>
          {reviewing && (
            <Button.Warning
              onClick={() => rejectCandidate(checkpoint)}
              disabled={editingClue || loading || submitDisabled}
              ring
            >
              <Icon.ThumbsDown mr="2" />
              Skip Checkpoint
            </Button.Warning>
          )}
        </Card.Footer>
      </FormProvider>
    </Card.Container>
  )
}

const FormContainer = styled.div`
  ${tw`flex flex-col w-full h-full overflow-y-scroll`}
  ${({ cluesOpen }) => css`
    & > ${CheckpointWrapper} {
      ${cluesOpen ? tw`hidden` : tw`block`}
    }
    & > ${CluesWrapper} {
      ${cluesOpen ? tw`flex` : tw`hidden`}
    }
  `}
`
const Tabs = tw.div`flex w-full pb-0 bg-gold-600 gap-2 first:pl-2`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[100px] text-center rounded-t-md text-lg tracking-wide`}
  ${({ active, color, error }) => css`
    ${
      color === 'gold'
        ? active
          ? tw`bg-gray-200`
          : tw`bg-gold-600 hover:bg-gold-700 `
        : color === 'norway'
        ? active
          ? tw`bg-norway-500`
          : tw`bg-gold-600 hover:bg-gold-700 `
        : tw`bg-gold-600 hover:bg-gold-700`
    }
    ${error && tw`ring-4 ring-red`}
    }
  `}
`
const CheckpointWrapper = tw.div`flex-1`
const CluesWrapper = tw.div`flex-1 h-full`
