import React from 'react'
import tw from 'twin.macro'
import { Controller, useFormContext } from 'react-hook-form'
import { Section } from '../../Hvnt/FormSection'
import { Input } from '../../../styles/Layout'
import { useInfoScreen } from '../../../contexts/InfoScreenContext'
import Dropdown from '../../Dropdown'
import { INFO_SCREEN_TYPE_OPTIONS } from '../../../utility/labeledOptions'
import { Switch } from '../../Layout/Switch'
import { InfoScreenType } from '../../../enums'
import { ImageTypeForm } from './ImageTypeForm'
import { validate } from '../../../utility/formValidation'

export const InfoScreenForm = ({ locked, flagUpload }) => {
  const { validateForm } = useInfoScreen()
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = useFormContext()

  const type = watch('type')

  return (
    <Form>
      <Section
        label="Select screen type"
        field={['type', 'showOnlyOnce']}
        error={errors.type || errors.showOnlyOnce}
        tw="gap-4"
      >
        <Col>
          <Input.Label required>Type</Input.Label>
          <Controller
            control={control}
            defaultValue=""
            name="type"
            render={({ field }) => (
              <Dropdown.Select
                {...field}
                isClearable={true}
                options={INFO_SCREEN_TYPE_OPTIONS}
                placeholder="Select info screen type"
                tw="w-full lg:w-1/2"
              />
            )}
            rules={{ required: 'This is required' }}
          />
          <Input.Error>{errors.type?.message}</Input.Error>
        </Col>

        <Col>
          <Controller
            control={control}
            defaultValue={true}
            name="showOnlyOnce"
            render={({ field: { onChange, value } }) => (
              <Switch
                color="blue"
                size="md"
                label="Show only once"
                defaultChecked={value}
                onToggle={(v) => {
                  onChange(v)
                  setValue('showOnlyOnce', v)
                }}
                tw="text-sm w-min"
              />
            )}
          />
          <Input.Error>{errors.showOnlyOnce?.message}</Input.Error>
        </Col>
      </Section>

      <Section label="Name the screen" field="name" error={errors.name}>
        <Input.Label required>Name</Input.Label>

        <Input.Default
          {...register('name', { validate: validateForm ? validate.name : null })}
          placeholder="Name (max 30 characters)"
        />
        <Input.Error>{errors.name?.message}</Input.Error>
      </Section>

      {type?.value === InfoScreenType.IMAGE_TIME && (
        <ImageTypeForm flagUpload={flagUpload} locked={locked} />
      )}

      <Section label="Set priority" field="priority" error={errors.priority}>
        <Input.Label>Priority</Input.Label>

        <Input.Default
          {...register('priority', {
            validate: validateForm ? validate.integer : null,
            valueAsNumber: true
          })}
          placeholder="Priority"
          type="number"
          tw="w-full lg:w-1/2"
        />
        <Input.Error>{errors.priority?.message}</Input.Error>
      </Section>
    </Form>
  )
}
const Form = tw.form`relative flex flex-1 flex-col w-full overflow-scroll`
const Col = tw.div`flex flex-col`
