import React, { useRef, useState } from 'react'
import tw from 'twin.macro'
import { objectIsEmpty } from '../../utility/validationFunctions'
import useBreakpoint from '../../hooks/useBreakpoint'
import Icon from '../../styles/Icons'
import { Button, Status } from '../../styles/Layout'
import { Switch } from '../Layout/Switch'
import { EventFilters, EventStatus } from '../../enums'
import { FilterButton, FilterGrid, FilterInput } from '../Filter'
import { ActiveFilters } from '../Filter/ActiveFilters'
import { useCorporate } from '../../contexts/CorporateContext'

export const EventFilter = ({ filter }) => {
  const { andFilters, clearFilters, filters, setAndFilters } = filter
  const { events } = useCorporate()
  const { belowTarget } = useBreakpoint('sm')
  const inputRef = useRef()
  const [showFilters, setShowFilters] = useState(false)

  return (
    <Container>
      <FilterModeWrapper>
        {showFilters && (
          <FilterMode>
            <pre>OR</pre>
            <Switch
              color="blue"
              size="sm"
              onToggle={() => setAndFilters((v) => !v)}
              defaultChecked={andFilters}
            />
            <pre>AND</pre>
          </FilterMode>
        )}

        <ButtonContainer>
          {!objectIsEmpty(filters) && (
            <Button.White
              size="sm"
              tw="m-0"
              onClick={() => {
                clearFilters()
                inputRef.current.value = ''
              }}
            >
              <Icon.Close size="sm" mr="2" />
              Clear{!belowTarget && <>&nbsp;Filters</>}
            </Button.White>
          )}
          <Button.Basic
            size="sm"
            tw="m-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? (
              <>
                <Icon.ChevronUp size="sm" mr="2" />
                Less{!belowTarget && <>&nbsp;Filters</>}
              </>
            ) : (
              <>
                <Icon.ChevronDown size="sm" mr="2" />
                More{!belowTarget && <>&nbsp;Filters</>}
              </>
            )}
          </Button.Basic>
        </ButtonContainer>
      </FilterModeWrapper>
      {showFilters && (
        <FilterGrid>
          <FilterButton {...EventFilters.ACTIVE} {...filter} value={false}>
            <Status active={false} size="sm" />
            <pre>INACTIVE</pre>
          </FilterButton>

          <FilterButton {...EventFilters.DRAFT} {...filter}>
            {EventStatus.DRAFT.icon('sm')}
            <pre>{EventStatus.DRAFT.message}</pre>
          </FilterButton>

          <FilterButton {...EventFilters.ENDED} {...filter}>
            {EventStatus.ENDED.icon('sm')}
            <pre>{EventStatus.ENDED.message}</pre>
          </FilterButton>

          <FilterButton {...EventFilters.ERROR} {...filter}>
            {EventStatus.ERROR.icon('sm')}
            <pre>ERROR</pre>
          </FilterButton>

          <FilterButton {...EventFilters.ACTIVE} {...filter}>
            <Status active={true} size="sm" />
            <pre>ACTIVE</pre>
          </FilterButton>

          <FilterButton {...EventFilters.LIVE} {...filter}>
            {EventStatus.LIVE.icon('sm')}
            <pre>{EventStatus.LIVE.message}</pre>
          </FilterButton>

          <FilterButton {...EventFilters.ENDED} {...filter} value={false}>
            {belowTarget && <code tw="text-gray">!</code>}
            {EventStatus.ENDED.icon('sm')}
            <pre>NOT {EventStatus.ENDED.message}</pre>
          </FilterButton>

          <FilterButton {...EventFilters.UPCOMING} {...filter}>
            {EventStatus.UPCOMING.icon('sm')}
            <pre>{EventStatus.UPCOMING.message}</pre>
          </FilterButton>
        </FilterGrid>
      )}

      <FilterInput
        {...EventFilters.QUERY}
        {...filter}
        placeholder="Filter query (id, entry code, name, title)"
        ref={inputRef}
      />
      <ActiveFilters {...filter} items={events} />
    </Container>
  )
}

const Container = tw.div`flex flex-1 flex-col self-center items-center z-20 pt-2 px-2 w-full sm:w-4/5 lg:w-full xl:w-4/5 gap-1.5 select-none`
const FilterModeWrapper = tw.div`flex justify-between w-full items-center gap-2`
const FilterMode = tw.div`flex items-center h-full gap-2 bg-white-pure p-1 px-2 shadow-inner w-min rounded-md text-xs`
const ButtonContainer = tw.div`flex rounded-md overflow-hidden ml-auto`
