import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import tw, { theme } from 'twin.macro'
import { ChatMessage } from './ChatMessage'
import Picker from 'emoji-picker-react'
import { db } from '../../lib/firebase'
import { Button, Input } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { format } from 'date-fns'
import { useSupport } from '../../contexts/SupportContext'
import { conversationsConverter } from '../../utility/formatSupport'
import useBreakpoint from '../../hooks/useBreakpoint'
import { useClickAway } from 'react-use'
import { ExternalLink, ExternalRoute, UserTag } from '../../styles/Link'
import config from '../../config'
import { Loader } from '../Loader/Loader'
import { Action } from '../../layout/List'

export const ChatWindow = ({ id }) => {
  const {
    loading,
    resolveConversation,
    sendMessage,
    setActiveConversation,
    unresolveConversation
  } = useSupport()
  const [conversation, setConversation] = useState({})
  const [messages, setMessages] = useState([])
  const [showEmojis, setShowEmojis] = useState(false)
  const [showDeviceInfo, setShowDeviceInfo] = useState(false)
  const [error, _setError] = useState(new Map())
  const [text, _setText] = useState(new Map())
  const unread = useMemo(
    () => messages.filter((message) => message.sentByUser && !message.seenAt),
    [messages]
  )
  const { belowTarget } = useBreakpoint('md')
  const emojiRef = useRef()
  useClickAway(emojiRef, () => setShowEmojis(false))
  // Triggers re-render for Maps
  const setText = useCallback((msg) => _setText((t) => new Map(t.set(id, msg))), [id])
  const setError = useCallback((msg) => _setError((e) => new Map(e.set(id, msg))), [id])

  // Send message and clear values
  const onSendMessage = useCallback(async () => {
    try {
      const { id, user } = conversation
      await sendMessage({ id, message: text.get(id).trim(), user })
      setError('')
      setText('')
      const input = document.getElementById('messageInput')
      input.value = ''
      input.style.height = '5px'
    } catch (err) {
      console.error(err)
      setError(err.message)
    }
  }, [conversation, sendMessage, setError, setText, text])

  // Adds the `seenAt` field to messages
  const markMessagesAsRead = useCallback(() => {
    try {
      const conversationRef = db.collection('support-chat').doc(id)
      db.runTransaction(async (t) => {
        // Get latest messages and update array
        const doc = await t.get(conversationRef)
        const { messages } = doc.data()
        t.update(conversationRef, {
          messages: messages.map((message) => ({
            ...message,
            ...(message.sentByUser && !message.seenAt && { seenAt: new Date() })
          }))
        })
      })
    } catch (err) {
      console.error(err)
    }
  }, [id])

  // Dynamically adjusts height of input field
  const onInput = useCallback(
    ({ target }) => {
      target.style.height = '5px'
      target.style.height = target.scrollHeight + 'px'
      setText(target.value)
    },
    [setText]
  )

  const onEmojiClick = useCallback(
    (_, { emoji }) => {
      const insertAtCursor = (input, text) => {
        const { selectionStart, selectionEnd, value } = input
        return (
          value.substring(0, selectionStart) + text + value.substring(selectionEnd, value.length)
        )
      }
      const input = document.getElementById('messageInput')
      setText(insertAtCursor(input, emoji))
      setShowEmojis(false)
      input.focus()
    },
    [setText]
  )

  // Subscribe to messages for active conversation
  useEffect(() => {
    // Add potential draft message to input
    const input = document.getElementById('messageInput')
    input.value = text.get(id) ?? ''
    input.style.height = '5px'
    input.focus()

    const unsubscribe = db
      .doc(`support-chat/${id}`)
      .withConverter(conversationsConverter)
      .onSnapshot((snapshot) => {
        const { messages, ...conversation } = snapshot.data()
        conversation && setConversation(conversation)
        messages && setMessages(messages)
      })
    return unsubscribe
    // Ignore changes to text map
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setText])

  // Mark unread messages as read
  useEffect(() => {
    // Scroll to bottom
    const scrollContainer = document.getElementById('messages')
    scrollContainer.scrollTo(0, scrollContainer.scrollHeight)

    unread.length && markMessagesAsRead()
    return () => setShowDeviceInfo(false)
  }, [markMessagesAsRead, messages, unread])

  const {
    createdAt,
    deviceInfo,
    participation,
    resolved,
    resolvedAt,
    resolvedBy,
    user = {}
  } = conversation
  return (
    <Container>
      <Header>
        <Title>
          <span>{'Chatting with'}</span>
          <span>&nbsp;{user.displayName || user.email || user.uid || '-'}</span>
          <CloseX size="sm" disabled={loading} onClick={() => setActiveConversation()}>
            <Icon.Close />
          </CloseX>
        </Title>
        <Action.Header color={theme`colors.white`}>
          <pre>{`Conversation started: ${
            createdAt ? format(createdAt, 'LLL do yyyy, HH:mm ') : '-'
          }`}</pre>

          <Action.More
            title="Options"
            color={theme`colors.bismark.300`}
            disabled={loading}
            content={
              <>
                <Button.Submit
                  size="sm"
                  onClick={() =>
                    resolved ? unresolveConversation({ id }) : resolveConversation({ id })
                  }
                  ring
                >
                  {resolved ? (
                    <Icon.ThumbsDown size="sm" mr="2" />
                  ) : (
                    <Icon.ThumbsUp size="sm" mr="2" />
                  )}
                  {resolved ? 'Unresolve Conversation' : 'Resolve Conversation'}
                </Button.Submit>

                <Button.Primary size="sm" onClick={() => setShowDeviceInfo((d) => !d)} ring>
                  <Icon.Device size="sm" mr="2" />
                  {showDeviceInfo ? 'Hide Device Info' : 'Show Device Info'}
                </Button.Primary>
                <ExternalRoute to={`/users?id=${user.uid}`}>
                  <Button.Secondary ring size="sm" tw="w-full">
                    <Icon.User size="sm" mr="2" />
                    Visit User Page
                  </Button.Secondary>
                </ExternalRoute>
                <ExternalLink
                  href={`${config.firebase.FIRESTORE_URL}/support-chat/${id}`}
                  tw="mt-auto"
                >
                  <Button.White ring size="sm" tw="w-full">
                    <Icon.Fire size="sm" mr="2" />
                    Show Chat in Firebase
                  </Button.White>
                </ExternalLink>
                <ExternalLink href={`${config.firebase.FIRESTORE_URL}/users/${user.uid}`}>
                  <Button.White ring size="sm" tw="w-full">
                    <Icon.Fire size="sm" mr="2" />
                    Show User in Firebase
                  </Button.White>
                </ExternalLink>
              </>
            }
          />
        </Action.Header>

        {showDeviceInfo && (
          <Action.Header tw="relative flex-wrap bg-bismark-200 max-h-60 z-0 overflow-scroll items-start">
            <span tw="whitespace-pre-line text-gray-700 flex-1 my-2">
              {deviceInfo ? JSON.stringify(deviceInfo, null, 2) : 'No device info...'}
            </span>
            <Button.Basic
              size="sm"
              tw="sticky top-1 right-0 p-1 hover:bg-bismark-300"
              onClick={() => setShowDeviceInfo(false)}
            >
              <Icon.Close size="sm" />
            </Button.Basic>
          </Action.Header>
        )}
      </Header>

      <MessagesContainer id="messages">
        <Metadata>
          {
            <pre tw="whitespace-pre-wrap">
              {JSON.stringify(
                {
                  ...user,
                  participation
                },
                null,
                1
              )}
            </pre>
          }
        </Metadata>
        {messages?.map((message, i) => (
          <ChatMessage chatMessage={message} key={i} />
        ))}
      </MessagesContainer>

      <Footer>
        {error.get(id) && (
          <Error>
            <Icon.Error />
            <span>Failed to send message</span>
            <span tw="text-xs">- {error.get(id)} -</span>
          </Error>
        )}
        {resolvedAt && (
          <Resolved>
            <Icon.Check />
            <span tw="whitespace-nowrap ">
              Conversation resolved {format(resolvedAt, 'LLL do yyyy, HH:mm')} by
            </span>
            <UserTag to={`/users?id=${resolvedBy}`} tw="text-matrix-600">
              {resolvedBy}
            </UserTag>
          </Resolved>
        )}
        <InputContainer>
          <Loader loading={loading} size="sm" />
          {showEmojis && (
            <Emojis ref={emojiRef}>
              <Picker onEmojiClick={onEmojiClick} />
            </Emojis>
          )}
          <TextArea
            disabled={resolved}
            id="messageInput"
            onInput={onInput}
            placeholder="Type a message"
            value={text.get(id)}
            tw="rounded-md"
          />
          <ButtonContainer>
            <EmojiButton disabled={loading || resolved} onClick={() => setShowEmojis((s) => !s)}>
              <Icon.Smiley />
            </EmojiButton>
            <SendButton
              disabled={loading || resolved || !text.get(id)?.trim()}
              onClick={onSendMessage}
            >
              <Icon.Send mr="2" size={belowTarget ? 'sm' : 'md'} /> {'Send'}
            </SendButton>
          </ButtonContainer>
        </InputContainer>
      </Footer>
    </Container>
  )
}

const Container = tw.div`relative flex flex-col w-full shadow-inner rounded-lg`
const CloseX = tw(
  Button.Basic
)`absolute right-1.5 p-0.5 hover:bg-bismark-600 active:bg-bismark-700 text-white`

const InfoText = tw.pre`flex justify-center items-center text-gray-600 text-xs`
const Metadata = tw(InfoText)`flex-col p-6 whitespace-pre-wrap`
const Status = tw(
  InfoText
)`flex text-sm md:text-base p-1 md:p-2 gap-1 md:gap-2 shadow-md overflow-scroll`
const Resolved = tw(Status)`bg-green text-white-pure text-xs md:text-sm flex-wrap`
const Error = tw(Status)`bg-red text-white-pure whitespace-nowrap`
const Header = tw.div`sticky top-0 flex flex-col z-20 shadow-md`
const Title = tw.div`flex justify-center py-2 px-6 flex-wrap w-full bg-bismark text-white text-lg font-light `
const MessagesContainer = tw.div`flex flex-1 flex-col w-full gap-1 pb-4 px-2 overflow-scroll bg-gray-200`
const Footer = tw.div`flex flex-col`
const InputContainer = tw.div`relative flex p-2 sm:p-4 bg-bismark h-auto items-center gap-2`
const TextArea = tw(
  Input.TextArea
)`resize-none overflow-hidden min-height[85px] max-height[170px] text-base md:text-lg `
const ButtonContainer = tw.div`flex flex-col sm:flex-row gap-2 h-full justify-between sm:items-end`
const Emojis = tw.div`absolute bottom-20 right-8 z-30`

const EmojiButton = tw(Button.Basic)`text-gray-100 hover:bg-bismark-400 active:bg-bismark-600 p-2`
const SendButton = tw(Button.Primary)`text-sm sm:text-base`
