import { useCallback } from 'react'
import { actionTypes } from '../../reducers/cellReducer'

const useGetCells = (dispatch) => {
  // Query snapshot for reviewed cells
  const onNextPlayableLocationCells = useCallback(
    (snapshot) => {
      const cells = []
      snapshot.docs.forEach((doc) => {
        cells.push(doc.data())
      })
      if (cells.length) dispatch({ type: actionTypes.playable.set, cells })
    },
    [dispatch]
  )

  return { onNextPlayableLocationCells }
}

export default useGetCells
