import React, { useState } from 'react'
import { Card } from '../../styles/Layout'
import tw, { theme } from 'twin.macro'
import Icon from '../../styles/Icons'
import { useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Button, Input } from '../../styles/Layout'
import ProfilePicture from './ProfilePicture'
import { handleError } from '../../utility/handleErrors'
import { useIsMounted } from '../../hooks/useIsMounted'
import useCloudinary from '../../hooks/useCloudinary'
import { Loader } from '../Loader/Loader'
import { objectIsEmpty } from '../../utility/validationFunctions'
import { Toast, Alerts } from '../../utility/alerts'
import { useUser } from '../../contexts/UserContext'
import { ATag } from '../../styles/Link'
import config from '../../config'
import { validate } from '../../utility/formValidation'

const UserAccount = () => {
  const { uploadImage } = useCloudinary()
  const { mounted } = useIsMounted()
  const { updateUser, user, userBeingEdited, startEditingUser, stopEditingUser, signout } =
    useUser()
  const navigate = useNavigate()
  const [flagUpload, setFlagUpload] = useState(false)
  const [loading, setLoading] = useState(false)

  const methods = useForm({
    defaultValues: {
      displayName: user?.displayName ?? '',
      email: user?.email ?? '',
      photoURL: user?.photoURL ?? ''
    },
    mode: 'onChange'
  })
  const {
    formState: { errors },
    handleSubmit,
    setError,
    register,
    reset
  } = methods

  const onCancel = () => {
    reset()
    stopEditingUser()
    setFlagUpload(false)
    navigate('/user-account')
  }

  const onEditProfile = () => {
    startEditingUser(user)
    navigate('/user-account/edit')
  }

  const onUpdateProfile = async (formData, activeUserID) => {
    try {
      setLoading(true)

      // Upload potential image to cloudinary
      if (flagUpload) {
        formData.photoURL = await uploadImage({
          file: formData.photoURL,
          id: activeUserID,
          path: 'users/'
        })
      }

      await mounted(updateUser({ uid: activeUserID, data: formData }))

      setLoading(false)
      stopEditingUser()
      navigate('/user-account')
      Toast.fire({
        title: 'Account updated!',
        icon: 'success'
      })
      if (user.email !== formData.email) {
        await Alerts.Auth.LOGOUT_USER()
        signout()
        navigate('/login')
      }
    } catch (err) {
      console.error(err)
      setError('login', { type: 'manual', message: handleError(err) })
    }
  }

  return (
    <FormProvider {...methods}>
      <Loader loading={loading} text="Saving details" />
      <>
        <Card.Container header="Account Information" color={theme`colors.sky`}>
          <EditProfile>
            <Button.Basic size="sm" onClick={onEditProfile}>
              <Icon.Edit />
            </Button.Basic>
          </EditProfile>

          <ProfilePicture flagUpload={setFlagUpload} />

          <Container>
            <UserDetails>
              <DataField>
                <Bold>Display Name: </Bold>
                <UserData>
                  {userBeingEdited ? (
                    <StyledInput
                      {...register('displayName', { validate: validate.account.name })}
                      required
                      size="sm"
                      disabled={loading}
                    />
                  ) : (
                    user?.displayName || '-'
                  )}

                  {errors.displayName && <Input.Error>{errors.displayName.message}</Input.Error>}
                </UserData>
              </DataField>
              <DataField>
                <Bold>Email: </Bold>
                <UserData>
                  {userBeingEdited ? (
                    <StyledInput
                      {...register('email', { validate: validate.email })}
                      required
                      size="sm"
                      disabled={loading}
                    />
                  ) : (
                    user?.email || '-'
                  )}

                  {errors.email && <Input.Error>{errors.email.message}</Input.Error>}
                </UserData>
              </DataField>
              <DataField>
                <Bold>Phone: </Bold>
                <UserData>{user ? user.phoneNumber : '-'} </UserData>
                {errors.phoneNumber && <Input.Error>{errors.phoneNumber.message}</Input.Error>}
              </DataField>
              <DataField>
                <Bold>Id: </Bold>

                <ATag href={`${config.firebase.FIRESTORE_URL}/users/${user.uid}`}>
                  {user.uid || '-'}
                </ATag>
              </DataField>
            </UserDetails>
            {!userBeingEdited && (
              <ButtonContainer>
                <Button.Primary onClick={() => navigate('/user-account/change-password')}>
                  <Icon.Key mr="2" />
                  Change Password
                </Button.Primary>
                <Button.Secondary onClick={() => navigate('/')}>
                  <Icon.ArrowLeft mr="2" />
                  Back
                </Button.Secondary>
              </ButtonContainer>
            )}
            {userBeingEdited && user.uid && (
              <ButtonContainer>
                <Button.Submit
                  disabled={!objectIsEmpty(errors) || loading}
                  onClick={handleSubmit((data) => onUpdateProfile(data, user.uid))}
                >
                  <Icon.Check mr="1" />
                  Save
                </Button.Submit>
                <Button.Gray onClick={onCancel}>
                  <Icon.Close mr="1" />
                  Cancel
                </Button.Gray>
              </ButtonContainer>
            )}
          </Container>
        </Card.Container>
      </>
    </FormProvider>
  )
}
const StyledInput = tw(Input.Default)`w-full`
const Container = tw.div`flex flex-col p-4 pb-6 gap-4`
const UserDetails = tw.div`flex flex-col p-4 truncate gap-2`
const ButtonContainer = tw.div`flex justify-between gap-3`
const UserData = tw.div`flex flex-col `
const EditProfile = tw.div`flex w-full justify-end mt-[1rem] mr-[2rem]`
const Bold = tw.div`font-bold text-gray-900 whitespace-nowrap pr-4`
const DataField = tw.div`flex justify-between `

export default UserAccount
