import React from 'react'
import { Navigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useUser } from '../../contexts/UserContext'
import { PageLayoutCol } from '../../styles/Layout'
import { Login } from '../Auth/Login'

const LoginPage = () => {
  const { user } = useUser()

  return user ? (
    <Navigate to="/" />
  ) : (
    <Page>
      <Login />
    </Page>
  )
}

export default LoginPage

const Page = tw(PageLayoutCol)`items-center`
