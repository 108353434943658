import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { usePrizes } from '../../contexts/PrizeContext'
import { PageLayout } from '../../styles/Layout'
import { PrizeList } from '../Prizes/List/PrizeList'
import { Outlet, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { CreatePrize } from '../Prizes/CreatePrize'

const PrizesPage = () => {
  const { activeTab, prizeBeingEdited, visitDeepRoute } = usePrizes()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  // Visit deep route
  useEffect(() => {
    const id = params.get('id')
    if (!id) return

    visitDeepRoute({ id })
  }, [params, visitDeepRoute])

  // Update routes through side effects
  useEffect(() => {
    if (!activeTab?.path) return navigate('physical', { replace: true })
    if (!prizeBeingEdited) navigate(activeTab.path, { replace: true })
  }, [activeTab, navigate, prizeBeingEdited])

  return (
    <PageLayout>
      <Routes>
        <Route
          path="/"
          element={
            <Container expand={prizeBeingEdited}>
              <Image src="https://res.cloudinary.com/dozsbtdha/image/upload/e_saturation:-60,f_auto,q_auto/v1661341916/forrestfenn/DALL_E_2022-08-22_22.08.31_-_A_golden_v_buried_in_a_lush_green_forest_j8lpi6.png" />
              <ListContainer>
                <Outlet />
              </ListContainer>
            </Container>
          }
        >
          <Route path=":prizeTab" element={<PrizeList />} />
          <Route path="/:prizeTab/*" element={<CreatePrize {...prizeBeingEdited} />} />
        </Route>
      </Routes>
    </PageLayout>
  )
}

export default PrizesPage

const ListContainer = tw.div`flex flex-col w-full h-full transition-all duration-300`
const Image = tw.img`fixed top-0 right-0 object-cover w-auto h-full z-0`
const Container = styled.div`
  ${tw`flex transition-all duration-300 w-full`}
  ${({ expand = false }) => (expand ? tw`lg:w-3/4` : tw`lg:w-7/12`)}
`
