import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import tw from 'twin.macro'
import { Map, NavigationControl, Source, Layer } from 'react-map-gl'
import { Loader } from '../../Loader/Loader'
import config from '../../../config'
import { MapControl } from '../../Map/MapControl'
import { useAreas } from '../../../contexts/AreasContext'
import { mapLayers } from '../../Map/MapLayers'
import { useSeason } from '../../../contexts/SeasonsContext'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useMapControl } from '../../../hooks/useMapControl'
import { useMap } from '../../../contexts/MapContext'
import { useDebounce } from 'react-use'

export const SeasonsMap = () => {
  const mapRef = useRef(null)
  const { activeCity } = useAreas()
  const { expandedItem } = useSeason()
  const { isMounted } = useIsMounted()
  const { containerRef, zoomToBbox, zoomToPoint } = useMapControl(mapRef)
  const { focusedMarker, focusedBbox, focusOnPoint } = useMap()

  const [busy, setBusy] = useState(true)
  const [viewstate, setViewstate] = useState({
    longitude: activeCity?.centerPoint?.longitude ?? config.DEFAULT_COORDS.longitude,
    latitude: activeCity?.centerPoint?.latitude ?? config.DEFAULT_COORDS.latitude,
    maxZoom: 18,
    zoom: 2
  })

  // Viewport change
  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  // Color countries
  const colorCurrent = useMemo(() => {
    if (busy || !expandedItem?.countries?.length) return {}
    const matchCountry = ['match', ['get', 'ISO2']]
    const matchBorder = ['match', ['get', 'ISO2']]
    const matchHover = ['match', ['get', 'ISO2']]
    for (const country of expandedItem.countries) {
      matchCountry.push(country.countryId.toUpperCase(), 'rgba(255,196,18,0.4)')
      matchBorder.push(country.countryId.toUpperCase(), 'rgba(249,194,99,0.7)')
      matchHover.push(country.countryId.toUpperCase(), 'rgba(236,169,72,0.3)')
    }
    matchCountry.push('rgba(0,0,0,0)')
    matchBorder.push('rgba(0,0,0,0)')
    matchHover.push('rgba(0,0,0,0)')
    return { border: matchBorder, country: matchCountry, hover: matchHover }
  }, [busy, expandedItem])

  // Zoom out on mount and item click
  useEffect(() => {
    focusOnPoint({ centerPoint: config.DEFAULT_COORDS }, { maxZoom: 2.5 })
  }, [expandedItem, focusOnPoint])

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedMarker) return zoomToPoint(focusedMarker)
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    200,
    [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint]
  )

  return (
    <MapContainer ref={containerRef}>
      <Map
        {...viewstate}
        id="seasonsMap"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
        mapStyle={config.mapbox.NORTH_STAR}
        onLoad={() => setBusy(false)}
        onMove={onMoveMap}
        ref={mapRef}
        style={{ width: '100vw', height: '100%' }}
      >
        <Loader loading={busy} text={busy ? 'Loading Map' : ''} />

        <Source type="vector" url="mapbox://dennit.0haz83rx" id="countries">
          <Layer {...mapLayers.country.main(colorCurrent.country)} />
          <Layer {...mapLayers.country.border(colorCurrent.border)} />
        </Source>

        <MapControl>
          <NavigationControl tw="bottom[-10px]" />
        </MapControl>
      </Map>
    </MapContainer>
  )
}

const MapContainer = tw.div`flex relative overflow-hidden justify-center font-light`
