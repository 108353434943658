import { useCallback } from 'react'
import { actionTypes } from '../../reducers/hvntReducer'

const useGetHvnts = () => {
  // Changes for regular hvnts
  const onNextHvnts = useCallback((snapshot, dispatch) => {
    snapshot.docChanges().forEach(async ({ doc, type }) => {
      const hvnt = doc.data()

      if (type === 'added') dispatch({ type: actionTypes.regular.add, hvnt })
      if (type === 'modified') dispatch({ type: actionTypes.regular.edit, hvnt })
      if (type === 'removed') dispatch({ type: actionTypes.regular.remove, hvnt })
    })
  }, [])

  // Changes for unlisted hvnts
  const onNextUnlistedHvnts = useCallback((snapshot, dispatch) => {
    snapshot.docChanges().forEach(async ({ doc, type }) => {
      const hvnt = doc.data()

      if (type === 'added') dispatch({ type: actionTypes.unlisted.add, hvnt })
      if (type === 'modified') dispatch({ type: actionTypes.unlisted.edit, hvnt })
      if (type === 'removed') dispatch({ type: actionTypes.unlisted.remove, hvnt })
    })
  }, [])

  return { onNextHvnts, onNextUnlistedHvnts }
}

export default useGetHvnts
