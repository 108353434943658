import React, { useRef, useState } from 'react'
import tw from 'twin.macro'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { objectIsEmpty } from '../../utility/validationFunctions'
import { Button, Status } from '../../styles/Layout'
import { CLUE_TYPE_OPTIONS } from '../../utility/labeledOptions'
import { FilterButton, FilterGrid, FilterInput } from '../Filter'
import { CheckpointFilters } from '../../enums'
import Icon from '../../styles/Icons'
import useBreakpoint from '../../hooks/useBreakpoint'
import { ActiveFilters } from '../Filter/ActiveFilters'
import Dropdown from '../Dropdown'

export const CheckpointFilter = ({ filter }) => {
  const { addFilter, clearFilters, filters } = filter
  const { checkpoints, tagOptions } = useCheckpoint()
  const inputRef = useRef()
  const { belowTarget } = useBreakpoint('md')
  const [showFilters, setShowFilters] = useState(false)

  const resetFilters = () => {
    clearFilters()
    inputRef.current.value = ''
  }

  return (
    <Container>
      <InputRow>
        <FilterInput
          {...CheckpointFilters.QUERY}
          {...filter}
          placeholder="Filter query (name, id, clues, tags, images)"
          ref={inputRef}
          tw="flex-1"
        />
        <ButtonsWrapper>
          {showFilters && !objectIsEmpty(filters) && (
            <Button.White size="sm" tw="m-0" onClick={resetFilters}>
              <Icon.Close size="sm" mr="2" />
              Clear{!belowTarget && <>&nbsp;Filters</>}
            </Button.White>
          )}
          <Button.Basic
            size="sm"
            tw="m-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? (
              <>
                <Icon.ChevronUp size="sm" mr="2" />
                Less{!belowTarget && <>&nbsp;Filters</>}
              </>
            ) : (
              <>
                <Icon.ChevronDown size="sm" mr="2" />
                More{!belowTarget && <>&nbsp;Filters</>}
              </>
            )}
          </Button.Basic>
        </ButtonsWrapper>
      </InputRow>

      {showFilters && (
        <FilterGrid>
          <FilterButton {...CheckpointFilters.ACTIVE} {...filter}>
            <Status active={true} size="sm" />
            <pre>ACTIVE</pre>
          </FilterButton>
          <FilterButton {...CheckpointFilters.AUTO} {...filter}>
            <pre>AUTO</pre>
          </FilterButton>
          <FilterButton {...CheckpointFilters.CUSTOM} {...filter}>
            <pre>CUSTOM</pre>
          </FilterButton>
          <FilterButton {...CheckpointFilters.ERROR} {...filter}>
            <pre>ERRORS</pre>
          </FilterButton>

          <FilterButton {...CheckpointFilters.ACTIVE} {...filter} value={false}>
            <Status active={false} size="sm" />
            <pre>INACTIVE</pre>
          </FilterButton>
          <FilterButton {...CheckpointFilters.AUTO} {...filter} value={false}>
            <pre>MANUAL</pre>
          </FilterButton>

          <Dropdown.Select
            isClearable
            isMulti
            closeMenuOnSelect={false}
            onChange={(arr) => addFilter(CheckpointFilters.TAGS, arr)}
            options={tagOptions}
            placeholder="Filter tags"
            tw="col-span-4 mt-1"
          />

          <Dropdown.Select
            isClearable
            isDisabled={true}
            isMulti
            onChange={(arr) => addFilter(CheckpointFilters.CLUE_TYPE, arr)}
            options={CLUE_TYPE_OPTIONS}
            placeholder="Filter clues"
            tw="col-span-4 mt-1"
          />
        </FilterGrid>
      )}
      <ActiveFilters {...filter} items={checkpoints.regular} />
    </Container>
  )
}

const Container = tw.div`flex flex-1 flex-col self-center items-center z-20 pt-2 px-2 w-full sm:w-4/5 lg:w-full xl:w-4/5 gap-2 select-none`
const InputRow = tw.div`flex gap-2 w-full`
const ButtonsWrapper = tw.div`flex overflow-hidden rounded-md`
