import React from 'react'
import tw from 'twin.macro'
import { Info, Status } from '../../styles/Layout'
import { getOption } from '../../utility/helperFunctions'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { CLUE_TYPE_OPTIONS } from '../../utility/labeledOptions'

export const ReservedClueItems = () => {
  const { reservedClues = [] } = useCheckpoint()

  return (
    !!reservedClues.length && (
      <Container>
        <Info>{'Clues that will automatically be generated upon creation:'}</Info>
        <div tw="flex flex-col gap-2">
          {reservedClues.map((clue, i) => (
            <ClueItem key={i}>
              <Status active={true} />
              {getOption(clue, CLUE_TYPE_OPTIONS)?.label.toUpperCase()}
            </ClueItem>
          ))}
        </div>
      </Container>
    )
  )
}

const Container = tw.div`sticky bottom-0 flex flex-col gap-3 p-3 border-2 border-matrix-200 border-dashed 
font-semibold bg-matrix-50 text-gray-800`
const ClueItem = tw.div`flex gap-4 py-2 px-4 items-center shadow-md bg-norway-100 truncate text-sm`
