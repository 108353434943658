import React, { useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { useAreas } from '../../contexts/AreasContext'
import { useLocations } from '../../contexts/LocationsContext'
import useMercator from '../../hooks/firebase/useMercator'
import { useIsMounted } from '../../hooks/useIsMounted'
import { auth, db } from '../../lib/firebase'
import Icon from '../../styles/Icons'
import { Button } from '../../styles/Layout'
import { Alerts, Toast } from '../../utility/alerts'
import { Loader } from '../Loader/Loader'
import { EnrichmentSettings } from './EnrichmentSettings'
import { SelectSettings } from './SelectSettings'
import { CellScanMode, S2CellLevel } from '../../enums'

export const GenerateLocations = () => {
  const { activeCity, activeCountry } = useAreas()
  const { mounted } = useIsMounted()
  const navigate = useNavigate()
  const {
    clearS2Cells,
    clearScanning,
    loading,
    s2Cells = [],
    setLoading,
    setQueriedCells
  } = useLocations()
  const { migratePlayableLocations, requestCandidates } = useMercator()
  const { control, handleSubmit } = useFormContext()
  const { fetchOptions, maxCells, s2Level } = useWatch({ control })

  const canFetch = fetchOptions.google || fetchOptions.wiki
  const canMigrate = useMemo(
    () => !!s2Cells.length && s2Level?.value === S2CellLevel[13],
    [s2Cells, s2Level]
  )

  // Generate locations in the selected cells
  const onRequest = useCallback(
    async (formData) => {
      const { enrichOptions, fetchOptions, generateCandidates, locationLimit, s2Level } = formData
      if (s2Cells.length > maxCells) return Alerts.Locations.TOO_MANY_CELLS(maxCells)

      try {
        if (generateCandidates) {
          if (!activeCity) return Alerts.Locations.GENERATE_FAILED({ message: 'No city selected' })
          const { value: ok } = await Alerts.Locations.CONFIRM_CHECKPOINT_REQUEST({
            cells: s2Cells.length,
            city: activeCity.name,
            limit: locationLimit
          })
          if (!ok) return
        } else {
          const { value: ok } = await Alerts.Locations.CONFIRM_LOCATION_REQUEST({
            cells: s2Cells.length,
            country: activeCountry.name,
            limit: locationLimit
          })
          if (!ok) return
        }

        // Create request params
        setLoading(true)
        const s2CellIds = s2Cells.map(({ properties }) => properties.id)
        const fetch = Object.keys(fetchOptions).filter((key) => (fetchOptions[key] ? key : ''))
        const enrich = Object.keys(enrichOptions).filter((key) => (enrichOptions[key] ? key : ''))

        // Make request
        const requestId = await mounted(
          requestCandidates({
            enrich: enrich.join(',') || 'none',
            fetch: fetch.join(',') || 'none',
            generate: generateCandidates,
            limit: locationLimit ?? 1000,
            s2Cells: s2CellIds.join(','),
            s2Level: s2Level.value
          })
        )

        // Save request to firestore
        const requestRef = db.collection('mercator-request-log').doc(requestId)
        await requestRef.set({
          country: activeCountry.id,
          ...(activeCity && { city: activeCity.path }),
          enrich,
          fetch,
          generate: generateCandidates,
          limit: locationLimit ?? 1000,
          requestedAt: new Date(),
          requestedBy: db.doc(`users/${auth.currentUser.uid}`),
          requestId,
          s2Cells: s2CellIds,
          s2Level: s2Level.value
        })

        // Update cells so that they can be queried again
        setQueriedCells((q) => ({
          ...q,
          ...s2Cells.reduce((obj, key) => ({ ...obj, [key]: false }), {})
        }))

        clearS2Cells()
        clearScanning()
        if (!generateCandidates) return Alerts.Locations.LOCATIONS_REQUESTED(requestId)

        const { value: ok } = await Alerts.Locations.CHECKPOINTS_REQUESTED(requestId)
        if (ok) return navigate('/checkpoints')
      } catch (err) {
        Alerts.Locations.GENERATE_FAILED(err)
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [
      activeCity,
      activeCountry,
      clearS2Cells,
      clearScanning,
      maxCells,
      mounted,
      navigate,
      requestCandidates,
      s2Cells,
      setLoading,
      setQueriedCells
    ]
  )

  // Fetch playable locations from hvnt and add to mercator
  const onFetchPlayable = useCallback(async () => {
    setLoading(true)
    const s2CellIds = s2Cells.map(({ properties }) => properties.id)

    try {
      await migratePlayableLocations({
        s2Cells: s2Cells.map(({ properties }) => properties.id)
      })

      // Save request to firestore
      const requestRef = db.collection('mercator-request-log').doc() // No request id
      await requestRef.set({
        country: activeCountry.id,
        ...(activeCity && { city: activeCity.path }),
        migrate: true,
        requestedAt: new Date(),
        requestedBy: db.doc(`users/${auth.currentUser.uid}`),
        s2Cells: s2CellIds,
        s2Level: s2Level.value
      })

      clearS2Cells()
      clearScanning()
      Toast.fire({ title: 'Playable locations migrated', icon: 'success' })
    } catch (err) {
      Alerts.Locations.GENERATE_FAILED(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [
    activeCity,
    activeCountry,
    clearS2Cells,
    clearScanning,
    migratePlayableLocations,
    s2Cells,
    s2Level.value,
    setLoading
  ])

  return (
    <Container>
      <Loader loading={loading} text={'Generating Locations...'} />
      <Label>Select Cells</Label>
      <SelectSettings />

      <Label>Location Settings</Label>
      <EnrichmentSettings />

      <ButtonContainer>
        <Label id="label" tw="text-center" warning={s2Cells.length > maxCells}>
          {(s2Cells.length || 0) + ' Selected Cell' + (s2Cells.length === 1 ? '' : 's')}
        </Label>
        <Button.Primary
          onClick={handleSubmit(onRequest)}
          disabled={!activeCountry || loading || !s2Cells?.length || !canFetch}
          ring
        >
          <Icon.Sparkles mr="2" /> Generate Locations
        </Button.Primary>
        <Button.Primary
          onClick={handleSubmit(onFetchPlayable)}
          disabled={loading || !canMigrate}
          ring
        >
          <Icon.Sparkles mr="2" /> Migrate Playable Locations to Mercator
        </Button.Primary>
      </ButtonContainer>
    </Container>
  )
}

const Container = tw.div`flex flex-col flex-1 w-full gap-1.5 p-3 bg-white-pure overflow-scroll`
const Label = styled.div`
  ${tw`font-mono justify-center text-center p-1 select-none`}
  ${({ warning }) => (warning ? tw`text-red` : tw`text-bismark-800`)}
`
const ButtonContainer = tw.div`flex flex-col items-center justify-start gap-2`
