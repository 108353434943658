import { useCallback } from 'react'
import { actionTypes } from '../../reducers/infoScreenReducer'

const useGetInfoScreens = (dispatch) => {
  const onNextInfoScreens = useCallback(
    (snapshot) => {
      snapshot.docChanges().forEach(async ({ doc, type }) => {
        const infoScreen = doc.data()
        if (type === 'added') dispatch({ type: actionTypes.add, infoScreen })
        if (type === 'modified') dispatch({ type: actionTypes.edit, infoScreen })
        if (type === 'removed') dispatch({ type: actionTypes.remove, infoScreen })
      })
    },
    [dispatch]
  )

  return { onNextInfoScreens }
}

export default useGetInfoScreens
