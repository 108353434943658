import React, { useCallback, useEffect, useMemo, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { usePlayer } from '../../../contexts/PlayerContext'
import { useGetAuthUser, useGetUser } from '../../../hooks/firebase/useGetUser'
import { Tab } from '../../../layout/Player'
import useCloudinary from '../../../hooks/useCloudinary'
import { Controller, useForm } from 'react-hook-form'
import { Alerts, Toast } from '../../../utility/alerts'
import Icon from '../../../styles/Icons'
import { ImageModal } from '../../Modal'
import config from '../../../config'
import { Button, Input } from '../../../styles/Layout'
import { objectIsEmpty } from '../../../utility/validationFunctions'
import { validate } from '../../../utility/formValidation'
import Dropdown from '../../Dropdown'
import { ATag } from '../../../styles/Link'
import { USER_GENDER_OPTIONS } from '../../../utility/labeledOptions'
import { Switch } from '../../Layout/Switch'
import { RoleBadge } from '../../RBAC/RoleBadge'
import { ClaimsModal } from '../Profile/ClaimsModal'
import RBAC from '../../RBAC'
import { Role } from '../../../enums'
import { format } from 'date-fns'

export const Profile = () => {
  const { activePlayer, dispatch, loading, p_dispatch, setLoading } = usePlayer()
  const { uploadImage } = useCloudinary()
  const { mounted } = useIsMounted()
  const { updateUserData } = useGetUser(dispatch)
  const { setCustomClaims, updateAuthData } = useGetAuthUser(p_dispatch)
  const { activeStatus, authData, uid, userData } = activePlayer
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset
  } = useForm({ mode: 'onChange' })

  const emailVerified = authData?.emailVerified
  const isAdmin = !!authData?.isAdmin
  const mockingAllowed = !!userData?.allowMockLocation
  const userDisabled = authData?.disabled

  const [editing, setEditing] = useState(false)
  const [flagUpload, setFlagUpload] = useState(false)
  const defaultValues = useMemo(
    () => ({
      displayName: authData?.displayName,
      email: authData?.email,
      photoURL: authData?.photoURL,
      userData: {
        age: userData?.age,
        allowMockLocation: userData?.allowMockLocation ?? false,
        gamerTag: userData?.gamerTag ?? '',
        gender: userData?.gender
      }
    }),
    [authData, userData]
  )

  // Update field values
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  // Verify email
  const verifyEmail = async () => {
    try {
      setLoading(true)
      await mounted(updateAuthData({ uid, data: { emailVerified: true } }))

      Toast.fire({
        title: 'Email verified!',
        icon: 'success'
      })
    } catch (err) {
      console.error(err)
      Alerts.General.ERROR(err)
    } finally {
      setLoading(false)
    }
  }

  // Disable user
  const disableUser = async () => {
    try {
      const { value: ok } = await Alerts.User.DISABLE_USER()
      if (!ok) return

      setLoading(true)
      await mounted(updateAuthData({ uid, data: { disabled: true } }))

      Toast.fire({
        title: 'User Disabled!',
        icon: 'success'
      })
    } catch (err) {
      console.error(err)
      Alerts.General.ERROR(err)
    } finally {
      setLoading(false)
    }
  }

  // Enable user
  const enableUser = async () => {
    try {
      setLoading(true)
      await mounted(updateAuthData({ uid, data: { disabled: false } }))

      Toast.fire({
        title: 'User Enabled!',
        icon: 'success'
      })
    } catch (err) {
      console.error(err)
      Alerts.General.ERROR(err)
    } finally {
      setLoading(false)
    }
  }

  // Add custom claim
  const addCustomClaims = async (customClaims) => {
    try {
      setLoading(true)
      await setCustomClaims({ data: customClaims, uid })
      Toast.fire({
        title: 'User Updated!',
        icon: 'success'
      })
    } catch (err) {
      console.error(err)
      Alerts.General.ERROR(err)
    } finally {
      setLoading(false)
    }
  }

  // Submit form
  const onSubmit = useCallback(
    async (formData) => {
      try {
        setLoading(true)
        if (authData?.email && !formData.email) return Alerts.User.REMOVE_EMAIL()
        // Upload potential image to cloudinary
        if (flagUpload) {
          formData.photoURL = await uploadImage({
            file: formData.photoURL,
            id: uid,
            path: 'users/'
          })
        }
        const { userData: uData, ...aData } = formData
        // Update both user data and auth data
        await mounted(
          Promise.all([updateUserData({ data: uData, uid }), updateAuthData({ data: aData, uid })])
        )

        setEditing(false)
        setFlagUpload(false)

        Toast.fire({
          title: 'User Updated!',
          icon: 'success'
        })
      } catch (err) {
        console.error(err)
        Alerts.General.ERROR(err)
      } finally {
        setLoading(false)
      }
    },
    [authData, flagUpload, mounted, setLoading, uid, updateAuthData, updateUserData, uploadImage]
  )

  const onCancel = () => {
    reset(defaultValues)
    setEditing(false)
    setFlagUpload(false)
  }

  return (
    <Tab.Container>
      <Container>
        <LeftContainer>
          <Controller
            control={control}
            name="photoURL"
            render={({ field: { onChange, value } }) => (
              <ModalButtonContainer editing={editing}>
                {editing && (
                  <ModalHover>
                    <Icon.Upload size="sm" mr="2" />
                    {value ? 'Change Image' : 'Upload Image'}
                  </ModalHover>
                )}
                <ImageModal
                  OpenButton={(props) => (
                    <div {...props}>
                      <ProfileImage src={value || config.user.DEFAULT_USER_IMAGE} />
                    </div>
                  )}
                  disabled={!editing}
                  flagUpload={setFlagUpload}
                  imageUrl={value}
                  loading={loading}
                  title="User Profile Picture"
                  setImageUrl={onChange}
                  tw="h-4/5"
                />
              </ModalButtonContainer>
            )}
          />

          <StatusContainer>
            {isAdmin ? (
              <RoleBadge admin={true}>ADMIN</RoleBadge>
            ) : (
              authData.roles?.map((role, i) => <RoleBadge key={i}>{role.toUpperCase()}</RoleBadge>)
            )}
            {userDisabled && <Disabled>USER DISABLED</Disabled>}
          </StatusContainer>

          {userDisabled ? (
            <Button.Submit
              ring
              size="sm"
              onClick={enableUser}
              disabled={editing || loading}
              tw="absolute sm:relative right-2 sm:right-0 top-2 sm:top-0 mt-auto"
            >
              <Icon.Check size="sm" mr="1" />
              Enable User
            </Button.Submit>
          ) : (
            <Button.Warning
              ring
              size="sm"
              onClick={disableUser}
              disabled={editing || loading}
              tw="absolute sm:relative right-2 sm:right-0 top-2 sm:top-0 mt-auto"
            >
              <Icon.Close size="sm" mr="1" />
              Disable User
            </Button.Warning>
          )}
        </LeftContainer>

        <FormContainer>
          <FieldCol>
            <Bold>{'Id'}</Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/users/${uid}`}>{uid}</ATag>
          </FieldCol>

          <FieldCol>
            <Bold tw="flex items-center gap-3">
              {'Last active'}
              {activeStatus?.icon()}
            </Bold>

            {authData.metadata?.lastSignInTime
              ? format(new Date(authData.metadata?.lastRefreshTime), 'LLL do yyy, HH:mm')
              : '-'}
          </FieldCol>

          <FieldCol>
            <Bold>{'Gamer tag'}</Bold>
            {editing ? (
              <StyledInput
                {...register('userData.gamerTag', { validate: validate.player.gamerTag })}
                disabled={loading}
              />
            ) : (
              userData?.gamerTag || '-'
            )}
            {errors.userData?.gamerTag && (
              <Input.Error>{errors.userData.gamerTag.message}</Input.Error>
            )}
          </FieldCol>

          <FieldCol>
            <Bold>{'Phone number'}</Bold>
            {editing ? (
              <StyledInput defaultValue={authData?.phoneNumber} disabled={true} />
            ) : (
              authData?.phoneNumber || '-'
            )}
          </FieldCol>

          <FieldCol>
            <Bold>{'Name'}</Bold>
            {editing ? (
              <StyledInput
                {...register('displayName', { validate: validate.player.name })}
                disabled={loading}
              />
            ) : (
              authData?.displayName || '-'
            )}
            {errors.displayName && <Input.Error>{errors.displayName.message}</Input.Error>}
          </FieldCol>

          <FieldCol>
            <EmailHeader verified={emailVerified}>{'Email'}</EmailHeader>
            {editing ? (
              <StyledInput
                {...register('email', { validate: validate.email })}
                disabled={loading}
              />
            ) : (
              authData?.email || '-'
            )}
            {errors.email && <Input.Error>{errors.email.message}</Input.Error>}
          </FieldCol>

          <FieldCol tw="overflow-visible">
            <Bold>{'Gender'}</Bold>
            <Controller
              control={control}
              name="userData.gender"
              render={({ field }) =>
                editing ? (
                  <Dropdown.Select
                    {...field}
                    options={USER_GENDER_OPTIONS}
                    isClearable
                    isDisabled={loading}
                  />
                ) : (
                  userData?.gender?.label ?? '-'
                )
              }
            />
          </FieldCol>

          <FieldCol>
            <Bold>{'Age'}</Bold>
            {editing ? (
              <StyledInput
                {...register('userData.age', { validate: validate.player.age })}
                type="number"
                disabled={loading}
              />
            ) : (
              userData?.age || '-'
            )}
            {errors.userData?.age && <Input.Error>{errors.userData.age.message}</Input.Error>}
          </FieldCol>

          <FieldCol>
            <Bold>{'Mock location allowed'}</Bold>
            {editing ? (
              <Controller
                control={control}
                name="userData.allowMockLocation"
                render={({ field: { onChange, value } }) => (
                  <Switch
                    color="blue"
                    defaultChecked={value}
                    disabled={loading}
                    label="Allow"
                    onToggle={onChange}
                    size="md"
                  />
                )}
              />
            ) : mockingAllowed ? (
              'Yes'
            ) : (
              'No'
            )}
          </FieldCol>

          {userData?.mockLocationDetected && (
            <FieldCol>
              <Bold tw="flex items-center gap-1">
                {'Detected mock locations'}
                <Icon.WarningSolid size="md" tw="text-red" />
              </Bold>

              {userData.mockLocationDetected}
            </FieldCol>
          )}

          {editing && (
            <FormButtons>
              <Button.Gray size="sm" onClick={onCancel} disabled={loading}>
                <Icon.Close size="sm" mr="1" />
                Cancel
              </Button.Gray>

              <Button.Submit
                size="sm"
                onClick={handleSubmit(onSubmit)}
                disabled={!objectIsEmpty(errors) || loading}
              >
                <Icon.Check size="sm" mr="1" />
                Save
              </Button.Submit>
            </FormButtons>
          )}
        </FormContainer>

        <ButtonContainer>
          <Button.Secondary
            size="sm"
            onClick={() => setEditing(true)}
            disabled={editing || loading}
          >
            <Icon.Edit size="sm" mr="1" />
            Edit Profile
          </Button.Secondary>

          <RBAC allow={[Role.ADMIN]}>
            <ClaimsModal authData={authData} onSave={addCustomClaims} />
          </RBAC>

          <Button.Gray size="sm" onClick={verifyEmail} disabled={emailVerified || loading}>
            <Icon.Mail size="sm" mr="1" />
            Verify Email
          </Button.Gray>
        </ButtonContainer>
      </Container>
    </Tab.Container>
  )
}

const Container = tw.div`flex flex-col sm:flex-row gap-4 h-full`
const LeftContainer = tw.div`flex flex-row sm:flex-col gap-4 sm:gap-3 h-full`
const StatusContainer = tw.div`flex flex-col gap-3 sm:items-center justify-between sm:justify-start w-full`
const Disabled = tw.pre`flex items-center justify-center w-full border-2 minWidth[120px] text-sm select-none rounded-sm px-3 py-1 
tracking-widest border-red text-red
`

const ProfileImage = tw.img`w-full h-full`
const ModalHover = tw.div`absolute bottom-0 flex w-full justify-center items-center p-1 bg-orange text-black text-xs sm:text-sm whitespace-nowrap`
const ModalButtonContainer = styled.div`
  ${tw`relative w-32 sm:w-44 h-32 sm:h-44 rounded-md shadow-md overflow-hidden flex-shrink-0`}
  ${({ editing }) => editing && tw`cursor-pointer`};
  &:hover > ${ModalHover} {
    ${tw`brightness-110`}
  }
`
const FormContainer = tw.div`relative grid gap-3 height[min-content] w-full py-2 px-4 grid-template-columns[repeat(auto-fill, minmax(max(40%,150px),1fr))]
shadow-inner bg-gray-100 rounded-md`
const FieldCol = tw.div`flex flex-col truncate overflow-x-scroll`
const Bold = tw.div`font-bold text-gray-900 whitespace-nowrap sticky left-0`
const EmailHeader = styled(Bold)`
  &::after {
    ${({ verified }) =>
      verified
        ? css`
            ${tw`text-green ml-1`}
            content: '\\2713';
          `
        : css`
            content: '';
          `}
  }
`
const StyledInput = tw(Input.Default)`w-full`
const FormButtons = tw.div`flex col-span-full items-center justify-end gap-2`
const ButtonContainer = tw.div`flex flex-col gap-2`
