import { useCallback } from 'react'
import { actionTypes } from '../../reducers/challengeReducer'

const useGetChallenges = (dispatch) => {
  // Changes for challenges
  const onNextChallenges = useCallback(
    (snapshot) => {
      const challenges = []
      snapshot.docChanges().forEach(async ({ doc, type }) => {
        const challenge = doc.data()

        if (type === 'added') challenges.push(challenge)
        if (type === 'modified') dispatch({ type: actionTypes.edit, challenge })
        if (type === 'removed') dispatch({ type: actionTypes.remove, challenge })
      })

      if (challenges.length) dispatch({ type: actionTypes.addMany, challenges })
    },
    [dispatch]
  )

  return { onNextChallenges }
}

export default useGetChallenges
