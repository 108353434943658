import React, { useState, useRef, useEffect, useCallback } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import tw, { styled, css } from 'twin.macro'
import { Button } from '../styles/Layout'
import { CloudinaryContext, Image } from 'cloudinary-react'
import { useIsMounted } from '../hooks/useIsMounted'
import config from '../config'
import Icon from '../styles/Icons'
import { IMAGE_EFFECT_OPTIONS } from '../utility/labeledOptions'
import { ClueImageBlurOptions } from '../enums'
import RangeSlider from './RangeSlider'

export const ImageEditor = ({ imageUrl, onSaveImage, onCancel }) => {
  const [imageEffect, setImageEffect] = useState()
  const [blurEffect, setBlurEffect] = useState()
  const [refreshReady, setRefreshReady] = useState(false)
  const [modifiedUrl, setModifiedUrl] = useState(imageUrl)
  const [addingBlur, setAddingBlur] = useState(false)
  const cropRef = useRef(null)
  const { isMounted } = useIsMounted()

  const createTransformationUrl = useCallback((url, imageEffect, blurEffect) => {
    const parts = url.split('upload/')
    return `${parts[0]}upload/${insertEffect(imageEffect)}${
      blurEffect ? insertBlur(blurEffect) : ''
    }${parts[1]}`
  }, [])

  const applyEffects = useCallback(() => {
    setModifiedUrl(createTransformationUrl(imageUrl, imageEffect, blurEffect))
    setRefreshReady(false)
  }, [blurEffect, createTransformationUrl, imageEffect, imageUrl])

  const resetEffects = useCallback(() => {
    setImageEffect()
    setBlurEffect()
    setModifiedUrl()
    setAddingBlur(false)
  }, [])

  useEffect(() => {
    if (!isMounted()) return
    setRefreshReady(!!imageEffect || !!blurEffect)
    !(imageEffect || blurEffect) && applyEffects()
  }, [applyEffects, blurEffect, imageEffect, isMounted])

  useEffect(() => {
    if (!isMounted()) return
    resetEffects()
  }, [imageUrl, isMounted, resetEffects])

  const insertEffect = (effect = '') => {
    const { value = '', amount = 0 } = effect
    return value && amount ? `${value}:${amount}/` : value ? `${value}/` : ''
  }

  const insertBlur = (effect = '') => {
    const { value = 'e_blur_region', amount = 1000, x, y, width, height } = effect
    return value && amount
      ? `${value}:${amount},x_${parseInt(x)},y_${parseInt(y)},w_${parseInt(width)},h_${parseInt(
          height
        )}/`
      : ''
  }

  const createPreviewUrl = (url) => {
    const parts = url.split('upload/')
    return `${parts[0]}upload/ar_1,c_crop/${parts[1]}`
  }

  const onOpenCropper = () => {
    setAddingBlur(true)
    setBlurEffect()
  }

  const onSaveBlurBox = () => {
    const imageElement = cropRef.current
    const cropper = imageElement.cropper
    setAddingBlur(false)
    setBlurEffect({ ...cropper.getData(), ...ClueImageBlurOptions })
  }

  const onRemoveBlurBox = () => {
    setAddingBlur(false)
    setBlurEffect()
  }

  const onSave = () => {
    // Transformations haven't been made yet. Create them now
    if (refreshReady) onSaveImage(createTransformationUrl(imageUrl, imageEffect, blurEffect))
    else onSaveImage(modifiedUrl)
  }

  return (
    <CloudinaryContext cloudName={config.cloudinary.NAME}>
      <Container>
        <>
          {addingBlur ? (
            <>
              <Cropper
                src={modifiedUrl || imageUrl}
                style={{ height: 500, width: '100%' }}
                initialAspectRatio={10 / 3}
                viewMode={2}
                guides={false}
                ref={cropRef}
              />
              <ButtonContainer>
                <Button.White ring size="sm" warning onClick={onRemoveBlurBox}>
                  <Icon.Close size="sm" mr="2" />
                  Cancel
                </Button.White>
                <Button.Submit ring size="sm" onClick={onSaveBlurBox}>
                  <Icon.Check size="sm" mr="2" />
                  Save Blur Box
                </Button.Submit>
              </ButtonContainer>
            </>
          ) : (
            // Transformation Grid
            <>
              <PreviewGrid>
                <Preview onClick={resetEffects} custom selected={!imageEffect}>
                  <PreviewImage publicId={createPreviewUrl(imageUrl)} />
                  <PreviewLabel>Original</PreviewLabel>
                  <PreviewSelected>
                    <Icon.Check />
                  </PreviewSelected>
                </Preview>
                {IMAGE_EFFECT_OPTIONS.map((effect, i) => (
                  <Preview
                    key={i}
                    onClick={() => setImageEffect(effect)}
                    selected={imageEffect?.value === effect.value}
                  >
                    <PreviewImage publicId={createTransformationUrl(config.clue.PREVIEW, effect)} />
                    <PreviewLabel>{effect.label}</PreviewLabel>
                    <PreviewSelected>
                      <Icon.Check />
                    </PreviewSelected>
                  </Preview>
                ))}
                <Preview onClick={onOpenCropper} custom selected={!!blurEffect}>
                  <PreviewImage
                    publicId={createTransformationUrl(config.clue.PREVIEW, '', {
                      x: 190,
                      y: 247,
                      width: 91,
                      height: 86
                    })}
                  />
                  <PreviewLabel>Draw Blur Box</PreviewLabel>
                  <PreviewSelected>
                    <Icon.Check />
                  </PreviewSelected>
                </Preview>
              </PreviewGrid>

              <ClueImageContainer>
                <RefreshOverlay show={refreshReady}>
                  <Button.Secondary ring size="sm" onClick={applyEffects}>
                    Refresh
                  </Button.Secondary>
                </RefreshOverlay>

                <ClueImage src={modifiedUrl || imageUrl} />
              </ClueImageContainer>

              {/* Image Effect Settings */}
              {imageEffect && (
                <ControlContainer>
                  <>
                    {!!imageEffect?.amount && (
                      <>
                        <PreviewText>{`Effect Amount: ${imageEffect.amount}`}</PreviewText>
                        <RangeSlider
                          color="red"
                          min={imageEffect.min}
                          max={imageEffect.max}
                          step={imageEffect.step}
                          value={imageEffect.amount}
                          onChange={({ target }) =>
                            setImageEffect({ ...imageEffect, amount: target.value })
                          }
                        />
                      </>
                    )}
                    <Button.Secondary ring size="sm" onClick={() => setImageEffect()}>
                      Remove Effect
                    </Button.Secondary>
                  </>
                </ControlContainer>
              )}

              {/* Blur Effect Settings */}
              {blurEffect && (
                <ControlContainer>
                  <>
                    {!!blurEffect?.amount && (
                      <>
                        <PreviewText>{`Blur Amount: ${blurEffect.amount}`}</PreviewText>
                        <RangeSlider
                          color="red"
                          max={blurEffect.max}
                          min={blurEffect.min}
                          onChange={({ target }) =>
                            setBlurEffect({ ...blurEffect, amount: target.value })
                          }
                          step={blurEffect.step}
                          value={blurEffect.amount}
                        />
                      </>
                    )}
                    <Button.Secondary ring size="sm" onClick={() => setBlurEffect()}>
                      Remove Blur Box
                    </Button.Secondary>
                  </>
                </ControlContainer>
              )}

              <ButtonContainer>
                <Button.White onClick={onCancel} ring size="sm">
                  <Icon.Close mr="2" size="sm" />
                  Cancel
                </Button.White>
                <Button.Submit onClick={onSave} ring size="sm">
                  <Icon.Check mr="2" size="sm" />
                  Save Image
                </Button.Submit>
              </ButtonContainer>
            </>
          )}
        </>
      </Container>
    </CloudinaryContext>
  )
}

const Container = tw.div`flex flex-col items-center justify-center bg-matrix-900 shadow-inner p-4 gap-6`
const PreviewGrid = tw.div`grid grid-template-columns[repeat(auto-fill, minmax(0, 140px))] justify-center w-full gap-2`
const Preview = styled.div`
  ${tw`relative rounded-md overflow-hidden cursor-pointer shadow-md`}
  ${({ custom, selected }) => css`
    & > ${PreviewLabel} {
      ${custom && tw`bg-red-400`}
      ${selected && tw`bg-gold-300`}
    }
    & > ${PreviewSelected} {
      ${selected && tw`visible`}
    }
  `}
`
const PreviewLabel = tw.div`absolute bottom-0 w-full p-1 bg-gold flex justify-center whitespace-nowrap`
const PreviewSelected = tw.div`absolute bottom-0 right-0 m-1 invisible`
const PreviewImage = styled(Image).attrs({
  secure: 'true',
  width: '200'
})``

const ControlContainer = tw.div`flex items-center gap-5 my-1`
const PreviewText = tw.span`text-sm text-gray-200`
const ButtonContainer = tw.div`flex gap-3 m-3`

const ClueImageContainer = tw.div`relative flex p-3 bg-matrix-700 rounded-md`
const RefreshOverlay = styled.div`
  ${tw`absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10 bg-gray bg-opacity-70`}
  ${({ show }) => (show ? tw`visible` : tw`hidden`)}
`

const ClueImage = styled.img`
  ${tw`relative`}
  max-width: 100%;
  max-height: 30rem;
`
