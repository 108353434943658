import React from 'react'
import tw from 'twin.macro'

export const QueryPlayerType = Object.freeze({
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  ID: 'id'
})

export const ProfileTab = {
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  INVENTORY: 'INVENTORY',
  PROFILE: 'PROFILE',
  PARTICIPATIONS: 'PARTICIPATIONS',
  STATS: 'STATS',
  TRANSACTIONS: 'TRANSACTIONS',
  XP: 'XP'
}

export const ActiveStatusType = Object.freeze({
  ACTIVE: 'ACTIVE',
  IDLE: 'IDLE',
  INACTIVE: 'INACTIVE'
})

export const ActiveStatus = Object.freeze({
  ACTIVE: {
    icon: Object.assign((props) => <Dot {...props} tw="bg-green ring-green-600" />),
    status: ActiveStatusType.ACTIVE
  },
  IDLE: {
    icon: Object.assign((props) => <Dot {...props} tw="bg-orange ring-orange-600" />),
    status: ActiveStatusType.IDLE
  },
  INACTIVE: {
    icon: Object.assign((props) => <Dot {...props} tw="bg-red ring-red-600" />),
    status: ActiveStatusType.INACTIVE
  }
})

const Dot = tw.div`w-3 h-3 rounded-full ring-2 flex-shrink-0`
