import React, { useMemo, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { usePrizes } from '../../contexts/PrizeContext'
import { PrizeInfoTab, PrizeTab } from '../../enums'
import { AvailableList } from './Instance/AvailableList'
import { ReservedList } from './Instance/ReservedList'
import { ClaimedList } from './Instance/ClaimedList'

export const MorePrizeInfo = () => {
  const { activeTab, prizeBeingEdited } = usePrizes()
  const { available, reserved, claimed } = prizeBeingEdited
  const [activeInfoTab, setActiveInfoTab] = useState(PrizeInfoTab.AVAILABLE)

  const colors = useMemo(() => {
    switch (activeTab?.id) {
      case PrizeTab.PHYSICAL:
        return {
          tabs: tw`bg-terracotta-700`,
          tab: (active) => (active ? tw`bg-gray-200 text-black` : tw`hover:bg-terracotta-800`)
        }
      case PrizeTab.DIGITAL:
      default:
        return {
          tabs: tw`bg-sky-700`,
          tab: (active) => (active ? tw`bg-gray-200 text-black` : tw`hover:bg-sky-800`)
        }
    }
  }, [activeTab])

  return (
    <>
      <Tabs color={colors.tabs} data-testid="prizetab-container">
        <Tab
          active={activeInfoTab === PrizeInfoTab.AVAILABLE}
          color={colors.tab(activeInfoTab === PrizeInfoTab.AVAILABLE)}
          onClick={() => setActiveInfoTab(PrizeInfoTab.AVAILABLE)}
        >
          {`${available ?? 0}`} Available
        </Tab>
        <Tab
          active={activeInfoTab === PrizeInfoTab.RESERVED}
          color={colors.tab(activeInfoTab === PrizeInfoTab.RESERVED)}
          onClick={() => setActiveInfoTab(PrizeInfoTab.RESERVED)}
        >
          {`${reserved ?? 0}`} Reserved
        </Tab>
        <Tab
          active={activeInfoTab === PrizeInfoTab.CLAIMED}
          color={colors.tab(activeInfoTab === PrizeInfoTab.CLAIMED)}
          onClick={() => setActiveInfoTab(PrizeInfoTab.CLAIMED)}
        >
          {`${claimed ?? 0}`} Claimed
        </Tab>
      </Tabs>

      <Container>
        {activeInfoTab === PrizeInfoTab.AVAILABLE && <AvailableList prize={prizeBeingEdited} />}
        {activeInfoTab === PrizeInfoTab.RESERVED && <ReservedList prize={prizeBeingEdited} />}
        {activeInfoTab === PrizeInfoTab.CLAIMED && <ClaimedList prize={prizeBeingEdited} />}
      </Container>
    </>
  )
}

const Container = tw.div`flex flex-col flex-1 w-full overflow-y-scroll`
const Tab = styled.div`
  ${tw`relative mt-2 px-4 py-1 cursor-pointer minWidth[150px] whitespace-nowrap text-center rounded-t-md text-lg tracking-wide select-none text-white`}
  ${({ color }) => color}
`
const Tabs = styled.div`
  ${tw`flex w-full pb-0 px-2 gap-2 first:pl-2 overflow-x-scroll`}
  ${({ color }) => color}
`
