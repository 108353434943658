import React from 'react'
import tw from 'twin.macro'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Authenticate, Button, Input } from '../../styles/Layout'
import { useForm } from 'react-hook-form'
import { useUser } from '../../contexts/UserContext'
import { handleError } from '../../utility/handleErrors'

export const CompleteSignup = ({ link }) => {
  const { activateAccount } = useUser()
  const navigate = useNavigate()
  const search = useLocation().search
  const email = new URLSearchParams(search).get('email')?.replace(' ', '+')

  const {
    formState: { errors },
    setError,
    register,
    handleSubmit
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: ''
    },
    mode: 'onChange'
  })

  const validateData = ({ password, repeatPassword }) => {
    let errors = []
    if (password !== repeatPassword)
      errors.push({ name: 'signup', message: "Passwords don't match" })
    return errors
  }

  const onSignup = async (data) => {
    const errors = validateData(data)
    if (errors.length) {
      return errors.forEach(({ name, message }) => setError(name, { type: 'required', message }))
    }
    try {
      const { password } = data
      await activateAccount({ email, link, password })
      navigate('/login')
    } catch (err) {
      console.error(err)
      setError('signup', { type: 'manual', message: handleError(err) })
    }
  }

  return (
    <>
      <Authenticate.Container {...register('signup')}>
        <Authenticate.Header>Complete Registration</Authenticate.Header>
        <Authenticate.Form>
          <div>
            <Input.Label>Email</Input.Label>
            <Input.Default disabled defaultValue={email} />
            <Input.Error>{errors.email?.message}</Input.Error>
          </div>
          <div>
            <Input.Label required>Password</Input.Label>
            <Input.Default
              {...register('password', {
                minLength: {
                  value: 6,
                  message: 'Password needs to be at least 6 characters'
                },
                required: 'Password is required'
              })}
              type="password"
            />
            <Input.Error>{errors.password?.message}</Input.Error>
          </div>
          <div>
            <Input.Label required>Confirm Password</Input.Label>
            <Input.Default
              {...register('repeatPassword', {
                minLength: {
                  value: 6,
                  message: 'Password needs to be at least 6 characters'
                },
                required: 'Password is required'
              })}
              type="password"
            />
            <Input.Error>{errors.repeatPassword?.message}</Input.Error>
          </div>
          <Input.Error tw="text-center my-2">{errors.signup?.message}</Input.Error>
          <Button.Secondary onClick={handleSubmit(onSignup)}>Create Account</Button.Secondary>
        </Authenticate.Form>
      </Authenticate.Container>
      <BottomContainer>
        Already have an account? &nbsp;
        <Link to="/login">
          <Button.Primary size="sm">Log In</Button.Primary>
        </Link>
      </BottomContainer>
    </>
  )
}

const BottomContainer = tw.div`inline-flex text-gray-700 text-lg mt-4 items-center`
