import React from 'react'
import tw, { css, styled } from 'twin.macro'
import format from 'date-fns/format'
import useBreakpoint from '../../../hooks/useBreakpoint'
import Icon from '../../../styles/Icons'
import { Button } from '../../../styles/Layout'
import { HvntPrizeModal } from './HvntPrizeModal'

export const HvntPrizeItem = ({ prize, onSelect, selected, ...props }) => {
  const { available, deliveryType, imageUrl, name, supplier, validTo, marketValue } = prize

  return (
    <PrizeCard onClick={() => onSelect(prize)} selected={selected} {...props}>
      <ImageWrapper>
        <Image src={imageUrl} />
      </ImageWrapper>
      <SummaryCol>
        <SummaryRow tw="text-lg font-medium">{`${name} from ${supplier ?? '-'}`}</SummaryRow>
        <SummaryRow tw="text-base">
          <strong>{available}</strong>&nbsp;
          {`Available prize${available > 1 ? 's' : ''}`}
        </SummaryRow>
        <SummaryRow>{`Delivered ${deliveryType}ly`}</SummaryRow>
        {validTo && (
          <SummaryRow>
            {'Expires:'}&nbsp;
            <strong>{format(new Date(validTo), 'LLL do yyy, HH:mm')}</strong>
          </SummaryRow>
        )}
        {marketValue && (
          <SummaryRow>{`Value: ${marketValue?.value} ${marketValue?.currency}`}</SummaryRow>
        )}
      </SummaryCol>
      <Select locked={false} selected={selected}>
        <Icon.Check size="xl" />
      </Select>
    </PrizeCard>
  )
}

export const ReservedPrizeItem = ({ prize, prizes, removePrize, updatePrize, ...props }) => {
  const { reservedAmount, deliveryType, imageUrl, locked, marketValue, name, supplier, validTo } =
    prize
  const { belowTarget } = useBreakpoint('lg')

  return (
    <PrizeCard tw="cursor-auto" {...props}>
      <ImageWrapper>
        <Image src={imageUrl} />
      </ImageWrapper>

      <SummaryCol>
        <SummaryRow tw="text-sm md:text-lg font-medium">{`${name} from ${supplier}`}</SummaryRow>
        <SummaryRow>
          {'Number of prizes:'}&nbsp;
          <strong>{reservedAmount}</strong>
        </SummaryRow>

        <SummaryRow>{`Delivered ${deliveryType}ly`}</SummaryRow>
        {validTo && (
          <SummaryRow>
            {'Expires:'}&nbsp;
            <strong>{format(validTo, 'LLL do yyy, HH:mm')}</strong>
          </SummaryRow>
        )}
        {marketValue && (
          <SummaryRow>{`Value: ${marketValue?.value} ${marketValue?.currency}`}</SummaryRow>
        )}
      </SummaryCol>
      {!locked && (
        <div tw="flex absolute top-0 right-0 gap-1 p-1">
          <HvntPrizeModal
            OpenButton={(props) => (
              <Button.Primary {...props} size="sm" tw="p-1 m-0">
                <Icon.Edit size="sm" />
              </Button.Primary>
            )}
            prize={prize}
            prizes={prizes}
            updatePrize={updatePrize}
          />
          <Button.Warning size="sm" onClick={removePrize} tw="p-1 m-0">
            <Icon.Trashcan size="sm" />
          </Button.Warning>
        </div>
      )}
      <Select locked={locked} selected={true}>
        {locked ? (
          <Icon.Lock size={belowTarget ? 'md' : 'lg'} />
        ) : (
          <Icon.Check size={belowTarget ? 'md' : 'lg'} />
        )}
      </Select>
    </PrizeCard>
  )
}

const PrizeCard = styled.div`
  ${tw`relative inline-flex max-height[11rem] rounded-md font-light
  cursor-pointer overflow-hidden bg-white-pure text-gray-900 shadow-md`}
  ${({ selected }) => selected && tw`ring-4 ring-norway`}
`
const SummaryCol = tw.div`relative flex flex-1 flex-col py-1 px-2 overflow-y-scroll text-xs md:text-sm`
const SummaryRow = tw.div`inline-flex my-1`
const ImageWrapper = tw.div`flex items-center max-w-1/4 shadow-md`
const Image = tw.img`w-full h-full aspect-ratio[1] object-cover`
const Select = styled.div`
  ${tw`absolute bottom-0 right-0 p-1 text-white-pure rounded-tl-md transition-transform ease-in-out duration-200`};
  ${({ locked, selected }) => css`
     {
      ${selected ? 'transform: scale(1)' : 'transform: scale(0)'};
      ${locked ? tw`bg-sky` : tw`bg-norway`};
    }
  `};
`
