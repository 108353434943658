import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Popup } from 'react-map-gl'
import tw, { styled } from 'twin.macro'
import destination from '@turf/destination'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'

export const RouteInfo = () => {
  const { activeRoute } = useHvntRoute()
  const { watch } = useFormContext()
  const { area = {}, numberOfCheckpoints } = watch()

  const crudeTimeCalculation = (estimatedDistance, numCheckpoints) => {
    const walkingSpeed = 1.4 * 60 // m/s, preferred walking speed
    const confusedPerCheckpoint = 7 // Minutes to complete challenges + figure out checkpoint location
    return Math.round(estimatedDistance / walkingSpeed) + confusedPerCheckpoint * numCheckpoints
  }

  return (
    <>
      {activeRoute && (
        <StyledPopup
          longitude={parseFloat(area.centerPoint.longitude)}
          latitude={parseFloat(
            destination(
              [area.centerPoint.longitude, area.centerPoint.latitude],
              area.radius / 1000,
              0,
              {
                units: 'kilometers'
              }
            ).geometry.coordinates[1]
          )}
          anchor="bottom"
          offset={[0, -10]}
          tipSize={0}
          closeOnClick={false}
          closeButton={false}
        >
          <Header>{activeRoute.start.name}</Header>
          {!!activeRoute.distance && (
            <Content data-testid="route-content">
              <span>{`Distance: ${activeRoute.distance ?? '-'} m`}</span>
              <span>{`Estimated time: ~${crudeTimeCalculation(
                activeRoute.distance,
                numberOfCheckpoints
              )} min`}</span>
            </Content>
          )}
        </StyledPopup>
      )}
    </>
  )
}

const StyledPopup = styled(Popup)`
   {
    & > .mapboxgl-popup-content {
      ${tw`background[none] shadow-none p-1 font-sans`}
    }
    & > .mapboxgl-popup-tip {
      ${tw`hidden`}
    }
    & > .mapboxgl-popup-content > div {
      ${tw`relative flex flex-col rounded-lg shadow-lg font-mono overflow-hidden bg-bismark-600 w-min whitespace-nowrap text-white`}
    }
  }
`

const Content = tw.div`flex flex-col p-3 gap-1 text-sm `
const Header = tw.div`flex justify-center text-base w-full p-2 bg-bismark`
