import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { EventLocationSorts } from '../../../enums'
import useSort from '../../../hooks/useSort'
import { List } from '../../../layout/List'
import { Items } from './Items/LocationItems'

export const LocationList = ({ locations = [] }) => {
  const { applySort, sortingBy, sortListBy } = useSort(EventLocationSorts.TYPE)
  const [sortedItems, setSortedItems] = useState(locations)

  // Sort items
  useEffect(() => {
    setSortedItems(applySort(locations))
  }, [applySort, locations])

  return (
    <Container>
      <List.Header>
        <HeaderData
          active={sortingBy?.sort === EventLocationSorts.ACTIVE}
          onClick={() => sortListBy(EventLocationSorts.ACTIVE)}
          inc={sortingBy?.inc}
          colSpan={1}
          tw="text-center"
        >
          Active
        </HeaderData>
        <HeaderData
          active={sortingBy?.sort === EventLocationSorts.TYPE}
          onClick={() => sortListBy(EventLocationSorts.TYPE)}
          inc={sortingBy?.inc}
          colSpan={1}
        >
          Type
        </HeaderData>
        <HeaderData
          active={sortingBy?.sort === EventLocationSorts.NAME}
          onClick={() => sortListBy(EventLocationSorts.NAME)}
          inc={sortingBy?.inc}
          colSpan={4}
        >
          Name
        </HeaderData>
      </List.Header>
      <List.Container>
        {sortedItems?.length ? <Items items={sortedItems} /> : <Empty>Nothing here</Empty>}
      </List.Container>
    </Container>
  )
}

const Container = tw.div`flex flex-col shadow-md`
const Empty = tw.div`flex justify-center items-center p-6 text-sm italic text-gray-700`
const HeaderData = tw(List.HeaderData)`text-matrix-800 bg-terracotta-300 hover:bg-terracotta-300`
