import React, { useState, useCallback, useEffect } from 'react'
import tw, { css, styled } from 'twin.macro'

export const LabelSwitch = ({
  children = [],
  checked,
  color,
  defaultChecked = false,
  disabled,
  onToggle,
  size,
  ...props
}) => {
  const [selected, setSelected] = useState(defaultChecked)

  const _onToggle = useCallback(
    ({ target }) => {
      setSelected(target.checked)
      onToggle(target.checked)
    },
    [onToggle]
  )

  // Control
  useEffect(() => {
    setSelected(checked ?? false)
  }, [checked])

  return (
    <Container disabled={disabled} {...props}>
      <Switch disabled={disabled} checked={selected}>
        <Input
          color={color}
          checked={selected}
          disabled={disabled}
          onChange={_onToggle}
          role="checkbox"
          type="checkbox"
        />
        <Slider color={color} />
        <Labels checked={selected} size={size}>
          {children.map((label, i) => (
            <Label key={i}>{label}</Label>
          ))}
        </Labels>
      </Switch>
    </Container>
  )
}
const Container = styled.div`
  ${tw`flex rounded-md overflow-hidden bg-gray-200 shadow-inner select-none`}
  ${({ disabled }) => disabled && tw`opacity-80`}
`
const Switch = styled.label`
  ${tw`flex relative text-center cursor-pointer z-10 w-full`}
  ${({ disabled }) => disabled && tw`cursor-auto opacity-50`}
`
const Slider = styled.div`
  ${tw`absolute top-0 left-0 h-full w-1/2 z-10 border-2 rounded-l-md rounded-r-none mix-blend-darken transition-all duration-200`}
  ${({ color }) =>
    color === 'blue'
      ? tw`bg-sky-400 border-sky-500`
      : color === 'red'
      ? tw`bg-matrix-300 border-matrix-400`
      : color === 'green'
      ? tw`bg-norway-400 border-norway-500`
      : tw`bg-gray-400 border-gray-500`}
`
const Input = styled.input`
  ${tw`absolute hidden w-full`}
  &:hover + ${Slider} {
    width: calc(52%);
    ${({ color, disabled }) =>
      !disabled &&
      (color === 'blue'
        ? tw`bg-sky-500 border-sky-600`
        : color === 'red'
        ? tw`bg-matrix-400 border-matrix-500`
        : color === 'green'
        ? tw`bg-norway-500 border-norway-600`
        : tw`bg-gray-500 border-gray-600`)}
  }
  &:checked ~ ${Slider} {
    ${tw`left-full -translate-x-full rounded-l-none rounded-r-md`}
  }
`
const Label = tw.div`flex flex-1 items-center justify-center h-full transition-colors duration-200 truncate`
const Labels = styled.div`
  ${tw`flex relative w-full h-full z-0`}
  ${({ checked, size = 'md' }) => css`
     {
      & > ${Label} {
        ${size === 'sm'
          ? tw`text-xs py-1 px-3`
          : size === 'lg'
          ? tw`text-base py-2 px-6`
          : tw`text-sm py-1.5 px-3`}
      }
      & > ${Label}:first-child {
        ${checked ? tw`text-gray` : tw`text-black`}
      }
      & > ${Label}:last-child {
        ${checked ? tw`text-black` : tw`text-gray`}
      }
    }
  `}
`
