import React, { useCallback, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { db, functions } from '../../../lib/firebase'
import { Items } from './Items/TransactionItems'
import { Tab } from '../../../layout/Player'
import { FormProvider, useForm } from 'react-hook-form'
import { Alerts, Toast } from '../../../utility/alerts'
import { transactionConverter } from '../../../utility/formatUserData'
import { usePlayer } from '../../../contexts/PlayerContext'
import { AdjustAmount } from '../AdjustAmount'
import { TransactionSorts } from '../../../enums'
import useSort from '../../../hooks/useSort'
import { List } from '../../../layout/List'
import { collection, orderBy, query } from 'firebase/firestore'
import { usePagination } from '../../../hooks/firebase/usePaginateFirestore'
import Paginator from '../../Paginator'
import { actionTypes } from '../../../reducers/playerReducer'

export const Transactions = () => {
  const { activePlayer, dispatch, loading, setLoading } = usePlayer()
  const methods = useForm({ mode: 'onChange' })
  const { applySort, sortingBy, sortListBy } = useSort(TransactionSorts.TIMESTAMP)
  const { transactions, uid, userData: { coins } = {} } = activePlayer
  const [sortedItems, setSortedItems] = useState(transactions)
  const [limit, setLimit] = useState(20)

  const { items = [], ...pagination } = usePagination(
    query(
      collection(db, 'users', uid, 'transaction-log'),
      orderBy('timestamp', 'desc')
    ).withConverter(transactionConverter),
    { limit }
  )

  // Sort items
  useEffect(() => {
    const sorted = applySort(items)
    setSortedItems(applySort(sorted))
    // Save transaction locations
    dispatch({ type: actionTypes.activePlayerData.add, data: { transactionLocations: sorted } })
  }, [applySort, dispatch, items])

  const onAdjustAmount = useCallback(
    async ({ amount, comment, reward }) => {
      const { value: ok } = await Alerts.User.ADJUST_BALANCE({ amount, reward })
      if (!ok) return
      try {
        setLoading(true)

        await functions.user.adjustCoinBalance({
          amount: reward ? amount : amount * -1,
          uid,
          ...(comment && { comment })
        })

        methods.setValue('amount')
        methods.setValue('comment')

        Toast.fire({ title: 'Balance updated!', icon: 'success' })
      } catch (err) {
        Alerts.User.ADJUST_FAILED(err)
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [methods, setLoading, uid]
  )

  const onReverseTransaction = useCallback(
    async ({ amount, id: transactionId }) => {
      const { value: ok } = await Alerts.User.REVERSE_TRANSACTION()
      if (!ok) return
      try {
        setLoading(true)

        await functions.user.adjustCoinBalance({
          amount: amount * -1,
          comment: 'Transaction reversed',
          reverse: true,
          transactionRef: transactionId,
          uid
        })

        Toast.fire({ title: 'Balance updated!', icon: 'success' })
      } catch (err) {
        Alerts.User.ADJUST_FAILED(err)
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, uid]
  )

  return (
    <FormProvider {...methods}>
      <Tab.Container tw="gap-6">
        <Tab.SplitSection>
          <Tab.Col>
            <Tab.Header>Current coin balance</Tab.Header>
            {`${coins} coins`}
          </Tab.Col>
          <Tab.Col>
            <Tab.Header>Adjust coin balance</Tab.Header>
            <AdjustAmount currentAmount={coins} onAdjustAmount={onAdjustAmount} loading={loading} />
          </Tab.Col>
        </Tab.SplitSection>

        <Tab.Section tw="overflow-y-hidden">
          <Tab.PaginatorHeader>
            {'Transaction history'}
            <Paginator.Firestore {...pagination} setLimit={setLimit} />
          </Tab.PaginatorHeader>

          <List.Header>
            <List.HeaderData colSpan={1} tw="text-center">
              Index
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.TIMESTAMP}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.TIMESTAMP)}
              colSpan={3}
            >
              Timestamp
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.TYPE}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.TYPE)}
              colSpan={2}
            >
              Type
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.REASON}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.REASON)}
              colSpan={3}
            >
              Reason
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.COINS_AMOUNT}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.COINS_AMOUNT)}
              colSpan={3}
            >
              Amount
            </List.HeaderData>
            <List.HeaderData
              active={sortingBy?.sort === TransactionSorts.COINS_NEW}
              inc={sortingBy?.inc}
              onClick={() => sortListBy(TransactionSorts.COINS_NEW)}
              colSpan={3}
            >
              New Balance
            </List.HeaderData>
          </List.Header>

          <List.Container>
            {sortedItems?.length ? (
              <Items
                items={sortedItems}
                onReverseTransaction={onReverseTransaction}
                uid={activePlayer.uid}
              />
            ) : (
              <Empty>Nothing here</Empty>
            )}
          </List.Container>
        </Tab.Section>
      </Tab.Container>
    </FormProvider>
  )
}

const Empty = tw.div`flex justify-center items-center p-4 text-sm text-gray-700`
