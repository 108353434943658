import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { GlobalStyles as BaseStyles, theme } from 'twin.macro'

const CustomStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  body {
    height: 100vh;
    width: 100vw;
    font-family: 'Hind', sans-serif;
  }

  #root {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: ${theme`colors.bismark.300`};
  }
  
  @media (max-width:640px) {
    #root {
      flex-direction: column;
      & > #sidebar {
        order: 2;
      }
    }
  }

  /* Sweet-alert container */
  body.swal2-height-auto {
    height: 100vh !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
  }

  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 10px;
  }

  .firebase-emulator-warning {
    position: absolute;
    display: flex;
    overflow: hidden;
    top: 0;
    margin: 0 !important;
    color: rgba(0,0,0,0) !important;
    background-color: rgba(0,0,0,0) !important;
    border: none !important;
    pointer-events: none;
    user-select: none;
  }

  .firebase-emulator-warning::before {
    position: absolute;
    left: 3px;
    top: 3px;
    content:'';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: orangered;
    border: 1px solid #992211;
  }

  .Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
  }

  .Toastify__toast--dark {
  background: ${theme`colors.black`};
  color: ${theme`colors.gold`};
  }
  .Toastify__toast--default {
    background: ${theme`colors.white`};
    color: ${theme`colors.black`};
  }
  .Toastify__toast--info {
    background: ${theme`colors.white`};
    color: ${theme`colors.gold`}
  }
  .Toastify__toast--success {
    background: ${theme`colors.green.300`};
    color: ${theme`colors.black`}
  }
  .Toastify__toast--warning {
    background: #f1c40f;
  }
  .Toastify__toast--error {
    background: ${theme`colors.red.400`};
  }
  .Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
  }
 
`
const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)
export default GlobalStyles
