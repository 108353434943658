import uniq from 'lodash/uniq'

export const ConversationTabs = Object.freeze({
  UNRESOLVED: 'UNRESOLVED',
  RESOLVED: 'RESOLVED'
})

export const SupportScreens = Object.freeze({
  CHAT: 'CHAT',
  CONVERSATIONS: 'CONVERSATIONS',
  ALL: 'ALL'
})

export const NotificationStatusTypes = Object.freeze({
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  WAITING: 'WAITING'
})

export const ConversationFilters = Object.freeze({
  QUERY: {
    field: 'query',
    filter: (arr, value) => {
      const filteredIds = arr.filter(
        (item) => item.id && item.id.toLowerCase().indexOf(value) !== -1
      )
      const filteredMessages = arr.filter((item) =>
        item.messages?.some(
          (message) => message.message && message.message?.toLowerCase().indexOf(value) !== -1
        )
      )
      const filteredNames = arr.filter(
        (item) =>
          item.user?.displayName && item.user.displayName.toLowerCase().indexOf(value) !== -1
      )
      const filteredUserIds = arr.filter(
        (item) => item.user?.uid && item.user.uid.toLowerCase().indexOf(value) !== -1
      )

      return uniq(
        filteredIds.concat(filteredMessages).concat(filteredNames).concat(filteredUserIds)
      )
    }
  },
  RESOLVED: {
    field: 'resolved',
    filter: (arr, value) => {
      return arr.filter((item) => (value ? item.resolved : !item.resolved))
    }
  }
})

export const ConversationSorts = Object.freeze({
  CREATED_AT: (arr, inc) =>
    arr.sort((a, b) => {
      var aHas = !!a.createdAt
      var bHas = !!b.createdAt
      if (aHas && bHas) return inc ? b.createdAt - a.createdAt : a.createdAt - b.createdAt
      return aHas ? -1 : bHas ? 1 : 0
    }),
  LAST_SENT: (arr, inc) =>
    arr.sort((a, b) => {
      const lastMessageA = a.messages?.[a.messages?.length - 1] ?? {}
      const lastMessageB = b.messages?.[b.messages?.length - 1] ?? {}
      return inc
        ? lastMessageB?.sentAt - lastMessageA?.sentAt
        : lastMessageA?.sentAt - lastMessageB?.sentAt
    }),
  RESOLVED_AT: (arr, inc) =>
    arr.sort((a, b) => {
      var aHas = !!a.resolvedAt
      var bHas = !!b.resolvedAt
      if (aHas && bHas) return inc ? b.resolvedAt - a.resolvedAt : a.resolvedAt - b.resolvedAt
      return aHas ? -1 : bHas ? 1 : 0
    }),
  UNREAD: (arr, inc) =>
    arr.sort((a, b) => {
      const unreadA = a.messages.filter((message) => message.sentByUser && !message.seenAt).length
      const unreadB = b.messages.filter((message) => message.sentByUser && !message.seenAt).length
      if (unreadA && unreadB) return inc ? unreadB - unreadA : unreadA - unreadB
      return unreadA ? -1 : unreadB ? 1 : 0
    })
})
