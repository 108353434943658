import React, { useState } from 'react'
import tw, { styled, css } from 'twin.macro'

export const RadioGroup = ({ name, options, defaultChecked, onChange, color }) => {
  const [selected, setSelected] = useState(defaultChecked)

  const onSelect = (current) => {
    if (selected !== current) {
      setSelected(current)
      onChange(current)
    }
  }
  return (
    <StyledRadioGroup>
      {options.map((option, i) => (
        <RadioButton
          color={color}
          key={i}
          option={option}
          name={name}
          checked={option.value === selected}
          onSelect={onSelect}
        ></RadioButton>
      ))}
    </StyledRadioGroup>
  )
}

const StyledRadioGroup = tw.div`flex justify-center items-center`

const RadioButton = ({ name, checked, onSelect, option: { label, value }, color }) => {
  return (
    <ButtonContainer onClick={() => onSelect(value)}>
      <HiddenButton type="radio" name={name} id={value} />
      <StyledButton color={color} checked={checked} />
      <Label>&nbsp;{label}</Label>
    </ButtonContainer>
  )
}

const ButtonContainer = tw.div`flex justify-center items-center mx-1 relative `
const StyledButton = styled.span`
  transition: background 200ms ease;
  ${tw`w-4 h-4 rounded-full pointer-events-none border-2`};
  ${({ checked, color }) => css`
    ${color === 'red'
      ? checked
        ? tw`bg-red-500 border-red-300`
        : tw`bg-red-200 border-red-300`
      : color === 'green'
      ? checked
        ? tw`bg-green-600 border-green-400`
        : tw`bg-green-200 border-green-400`
      : color === 'blue'
      ? checked
        ? tw`bg-blue-400 border-blue-600`
        : tw`bg-blue-100 border-blue-400`
      : checked
      ? tw`bg-gray-600 border-gray-400`
      : tw`bg-gray-200 border-gray-300`}

    ${ButtonContainer}:hover > & {
      ${!checked &&
      (color === 'red'
        ? tw`border-red-500`
        : color === 'green'
        ? tw`border-green-600`
        : color === 'blue'
        ? tw`border-blue-600`
        : tw`border-gray-400`)}
    }
  `};
`
const Label = tw.label`flex font-sans font-light text-sm cursor-pointer`
const HiddenButton = tw.input`flex w-4 h-4 absolute left-0 opacity-0 cursor-pointer`
