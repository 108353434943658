import React, { useRef } from 'react'
import tw, { css, styled } from 'twin.macro'
import { useSupport } from '../../contexts/SupportContext'
import { ConversationFilters, ConversationSorts, ConversationTabs } from '../../enums'
import { FilterInput } from '../Filter'

export const ConversationFilter = ({ filter, sort }) => {
  const { activeTab } = useSupport()
  const inputRef = useRef()
  const { sortingBy, sortListBy } = sort

  return (
    <Container>
      <FilterInput
        {...ConversationFilters.QUERY}
        {...filter}
        placeholder="Filter (id, message, name, uid)"
        ref={inputRef}
      />

      <SortContainer>
        <SortButton
          active={sortingBy?.sort === ConversationSorts.LAST_SENT}
          onClick={() => sortListBy(ConversationSorts.LAST_SENT)}
          inc={sortingBy?.inc}
        >
          Last message
        </SortButton>
        <SortButton
          active={sortingBy?.sort === ConversationSorts.UNREAD}
          onClick={() => sortListBy(ConversationSorts.UNREAD)}
          inc={sortingBy?.inc}
        >
          Unread
        </SortButton>
        <SortButton
          active={sortingBy?.sort === ConversationSorts.CREATED_AT}
          onClick={() => sortListBy(ConversationSorts.CREATED_AT)}
          inc={sortingBy?.inc}
        >
          Created at
        </SortButton>
        {activeTab === ConversationTabs.RESOLVED && (
          <SortButton
            active={sortingBy?.sort === ConversationSorts.RESOLVED_AT}
            onClick={() => sortListBy(ConversationSorts.RESOLVED_AT)}
            inc={sortingBy?.inc}
          >
            Resolved at
          </SortButton>
        )}
      </SortContainer>
    </Container>
  )
}

const Container = tw.div`flex flex-col w-full p-1 md:p-3 gap-4`
const Row = tw.div`flex items-center gap-1 whitespace-nowrap flex-wrap`

const SortContainer = tw(Row)`grid gap-2 grid-cols-2 sm:grid-cols-4`
const SortButton = styled.div`
  ${tw`flex py-1 px-2 md:px-3 rounded-sm cursor-pointer select-none gap-0.5 md:gap-1 
  text-sm sm:text-sm justify-center truncate`};
  ${({ active, inc }) => css`
    ${active
      ? css`
           {
            ${tw`bg-gold-700 hover:bg-gold-600`}
            ${inc ? ' &:before { content: "↓ "; }' : '&:before { content: "↑ "; }'}
          }
        `
      : tw`bg-bismark-600 hover:bg-bismark-400`};
  `}
`
