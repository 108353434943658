import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { useAreas } from '../../contexts/AreasContext'
import { useCheckpoint } from '../../contexts/CheckpointContext'
import { useCheckpointList } from '../../hooks/useCheckpointList'
import { CheckpointSorts, Role } from '../../enums'
import Icon from '../../styles/Icons'
import { Button } from '../../styles/Layout'
import RBAC from '../RBAC'
import { CheckpointFilter } from './CheckpointFilter'
import { Items } from './CheckpointItem'
import useSort from '../../hooks/useSort'
import useFilter from '../../hooks/useFilter'
import { Action, List } from '../../layout/List'
import { useDebounce } from 'react-use'

export const CheckpointList = () => {
  const { activeCity } = useAreas()
  const { filteredItems, setFilteredItems } = useCheckpointList()
  const {
    addNewCheckpoint,
    checkpoints: { regular: checkpoints },
    focusedItem
  } = useCheckpoint()

  const { applyFilters, ...filterMethods } = useFilter()
  const { applySort, sortingBy, sortListBy } = useSort(CheckpointSorts.UPDATED)

  // Filter and sort items
  useEffect(() => {
    setFilteredItems(applySort(applyFilters(checkpoints)))
  }, [applyFilters, applySort, checkpoints, setFilteredItems])

  // Scroll to item after 100ms to allow time for item collapse
  useDebounce(
    () => {
      const id = focusedItem?.checkpoint?.id
      const element = document.getElementById(id)
      if (!element || !id) return

      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    100,
    [focusedItem]
  )

  return (
    <>
      <List.Filter>
        <CheckpointFilter filter={filterMethods} />
      </List.Filter>

      <Action.Header>
        <Action.Right>
          <RBAC allow={[Role.CREATOR]}>
            <Button.Primary
              data-testid="add-checkpoint-button"
              onClick={() => addNewCheckpoint({ activeCity })}
              ring
              size="sm"
            >
              <Icon.Plus size="sm" mr="2" />
              {'Create New Checkpoint'}
            </Button.Primary>
          </RBAC>

          <Action.More />
        </Action.Right>
      </Action.Header>

      <List.Header>
        <CenterData
          colSpan={1}
          onClick={() => sortListBy(CheckpointSorts.ACTIVE)}
          active={sortingBy?.sort === CheckpointSorts.ACTIVE}
          inc={sortingBy.inc}
        >
          Active
        </CenterData>
        <List.HeaderData
          colSpan={4}
          onClick={() => sortListBy(CheckpointSorts.NAME)}
          active={sortingBy?.sort === CheckpointSorts.NAME}
          inc={sortingBy.inc}
        >
          Name
        </List.HeaderData>
        <List.HeaderData
          colSpan={3}
          onClick={() => sortListBy(CheckpointSorts.UPDATED)}
          active={sortingBy?.sort === CheckpointSorts.UPDATED}
          inc={sortingBy.inc}
        >
          Updated
        </List.HeaderData>
      </List.Header>

      <List.Container>
        {filteredItems?.length ? (
          <Items items={filteredItems} />
        ) : (
          <List.Empty>{`${
            checkpoints?.length ? 'No filtered items...' : 'Nothing here...'
          }`}</List.Empty>
        )}
      </List.Container>
    </>
  )
}

const CenterData = tw(List.HeaderData)`text-center`
