import React from 'react'
import tw, { styled } from 'twin.macro'
import { useEffect } from 'react'
import { useHvntRoute } from '../../../contexts/HvntRouteContext'

export const RouteItem = ({ active, init, item }) => {
  const { buildRoute, innerPoints, setHoveredItem } = useHvntRoute()

  // Initial route to display
  useEffect(() => {
    init.id === item.start?.id && buildRoute(item)
  }, [buildRoute, init.id, item])

  const onClick = (item) => {
    console.log({ item })
    buildRoute(item)
  }

  const onHover = (item) => {
    const { start } = item
    const checkpoint = innerPoints?.features.filter(
      ({ properties }) => properties?.id === start.id
    )[0].properties
    setHoveredItem({ checkpoint })
  }

  return (
    <Container
      active={active}
      onClick={() => onClick(item)}
      onMouseOver={() => onHover(item)}
      onMouseOut={() => setHoveredItem()}
    >
      {item.status?.icon}&nbsp;&nbsp;
      <Name>
        <div>{item.start?.name}</div>
        {item.status?.status !== 'OK' && <StatusText>{item.status?.message}</StatusText>}
      </Name>
    </Container>
  )
}

const Container = styled.div`
  ${tw`flex items-center rounded-sm shadow-md cursor-pointer font-light px-2 py-1 select-none
 text-sm`}
  ${({ active }) =>
    active
      ? tw`bg-gold-300 hover:bg-gold-400 active:bg-gold-500`
      : tw`bg-white-pure hover:bg-gold-200 active:bg-gold-300`}
`
const Name = tw.div`flex flex-col text-gray-800`
const StatusText = tw.div`font-mono text-xs`
