import { ClueImageType, ClueType } from '../enums'
import { auth, db, GeoPoint } from '../lib/firebase'
import {
  CLUE_TYPE_OPTIONS,
  DIFFICULTY_OPTIONS,
  IMAGE_LICENSE_OPTIONS,
  RADIUS_OPTIONS,
  TREASURE_ZOOM_OPTIONS
} from './labeledOptions'
import { createOption, getOption } from './helperFunctions'
import {
  isCoordinates,
  isGoogleImage,
  isImageUrl,
  isNumber,
  isStringOrNumber
} from './validationFunctions'

export const clueConverter = {
  fromFirestore: (snapshot) => {
    const clue = snapshot.data()
    const id = snapshot.id
    const errors = []
    const { active, auto, fromMercator, difficulty, price, source, type } = clue

    let formattedClue = {
      active: active ?? true,
      ...(auto && { auto }),
      difficulty:
        getOption(difficulty, DIFFICULTY_OPTIONS) || addError({ errors, type: 'difficulty' }),
      ...(fromMercator && { fromMercator }),
      id: id ? id : addError({ errors, type: 'broken' }),
      price: isNumber(price) ? price : addError({ errors, type: 'price' }),
      ...(source && { source }),
      type:
        getOption(type, CLUE_TYPE_OPTIONS) ||
        addError({ errors, type: 'broken', fallback: createOption('broken') }),
      free: price === 0
    }

    formattedClue = {
      ...formattedClue,
      ...converterFromType(formattedClue.type).fromFirestore({ ...clue, errors })
    }

    return { ...formattedClue, errors }
  },
  fromJson: (data) => {
    const { active, auto, difficulty, errors = [], id, path, price, type } = data

    let formattedClue = {
      active: active ?? true,
      ...(auto && { auto }),
      difficulty:
        getOption(difficulty, DIFFICULTY_OPTIONS) || addError({ errors, type: 'difficulty' }),
      errors,
      id: id || addError({ errors, type: 'broken' }),
      path: `${path}/clues/${id}`,
      price: isNumber(price) ? price : addError({ errors, type: 'price' }),
      type:
        ClueType[type] || addError({ errors, type: 'broken', fallback: createOption('broken') }),
      free: price === 0
    }

    formattedClue = {
      ...formattedClue,
      ...converterFromType(formattedClue.type)?.fromFirestore({ ...data, ...formattedClue })
    }

    return { ...formattedClue, errors }
  },
  fromMercator: (doc) => {
    const clue = doc.data()
    const id = doc.id
    const { active, errors = [], difficulty, propsUsed, source, type } = clue

    let generatedClue = {
      active: active ?? true,
      auto: true,
      candidate: true,
      difficulty:
        getOption(difficulty, DIFFICULTY_OPTIONS) || addError({ errors, type: 'difficulty' }),
      errors,
      fromMercator: true,
      id: id ?? addError({ errors, type: 'broken' }),
      mercatorPath: doc.ref.path,
      price: 50, // todo
      propsUsed,
      ...(source && { source }),
      type:
        getOption(type, CLUE_TYPE_OPTIONS) ||
        addError({ errors, type: 'broken', fallback: createOption('broken') })
    }

    generatedClue = {
      ...generatedClue,
      ...converterFromType(generatedClue.type)?.fromFirestore(clue)
    }

    return { ...generatedClue, errors }
  },
  toFirestore: (clue) => {
    const { active, auto, createdAt, difficulty, fromMercator, id, propsUsed, source, type } = clue
    return {
      active,
      ...(auto && { auto }),
      ...(createdAt && { createdAt }),
      ...(difficulty && { difficulty: difficulty.value }),
      ...(fromMercator && { fromMercator }),
      id,
      ...(propsUsed && { propsUsed }),
      ...(source && { source }),
      updatedAt: new Date(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`),
      ...converterFromType(type).toFirestore(clue)
    }
  }
}

const areaClueConverter = {
  fromFirestore: ({ errors = [], radius, ...clue }) => ({
    ...clue,
    radius: getOption(radius, RADIUS_OPTIONS) || addError({ errors, type: 'radius' })
  }),
  toFirestore: ({ free, radius }) => ({
    difficulty: 1,
    price: free ? 0 : 50,
    radius: parseFloat(radius.value),
    type: 'area shot' // legacy
  })
}

const imageClueConverter = {
  fromFirestore: ({
    errors = [],
    imageText,
    imageType,
    imageUrl,
    license,
    original = false,
    radius
  }) => {
    let imageClue = {
      ...(imageText && {
        imageText: isStringOrNumber(imageText)
          ? imageText
          : addError({ errors, type: 'imageText', fallback: '' })
      }),
      imageUrl:
        isImageUrl(imageUrl) || isGoogleImage(imageUrl)
          ? imageUrl
          : addError({ errors, type: 'imageUrl' }),
      ...(imageType && {
        imageType: ClueImageType[imageType] || addError({ errors, type: 'imageType' })
      }),
      original,
      type: getOption(ClueType.IMAGE, CLUE_TYPE_OPTIONS)
    }

    if (!original) {
      switch (imageType) {
        case ClueImageType.STREET_VIEW: {
          // Has no license
          imageClue = {
            ...imageClue,
            ...(radius && { radius }),
            type: getOption(ClueType.STREET_VIEW_OLD, CLUE_TYPE_OPTIONS)
          }
          break
        }
        default: {
          imageClue = {
            ...imageClue,
            licenseType:
              getOption(license?.type, IMAGE_LICENSE_OPTIONS) ||
              addError({ errors, type: 'licenseType' }),
            ...(license?.type !== 'cc0' && {
              licenseAuthor: isStringOrNumber(license?.author)
                ? license?.author
                : addError({ errors, type: 'licenseAuthor' }),
              licenseSource: license?.source
            })
          }
          break
        }
      }
    }
    return imageClue
  },
  toFirestore: ({
    free,
    imageType,
    imageText,
    imageUrl,
    licenseAuthor,
    licenseSource,
    licenseType,
    original,
    radius
  }) => ({
    ...(imageType && { imageType }),
    ...(imageText && { imageText }),
    imageUrl: imageUrl?.modified ?? imageUrl?.original ?? imageUrl,
    ...(licenseType && {
      ...{
        license: {
          deed: getOption(licenseType?.value, IMAGE_LICENSE_OPTIONS)?.deed,
          type: licenseType.value,
          ...(licenseAuthor && { author: licenseAuthor }),
          ...(licenseSource && { source: licenseSource })
        }
      }
    }),
    ...(original && { original }),
    price: free ? 0 : 50,
    ...(radius && { radius }),
    type: ClueType.IMAGE
  })
}

export const streetviewConverter = {
  fromFirestore: (data) => {
    const {
      bearing,
      distance,
      errors = [],
      imageText,
      imageUrl,
      panoId,
      panoLocation,
      radius
    } = data

    return {
      ...(imageText && {
        imageText: isStringOrNumber(imageText)
          ? imageText.toString()
          : addError({ errors, type: 'imageText' })
      }),
      imageUrl: isImageUrl(imageUrl) ? imageUrl : addError({ errors, type: 'imageUrl' }),
      metadata: {
        bearing,
        distance,
        panoId,
        ...(panoLocation && {
          panoLocation: { latitude: panoLocation.latitude, longitude: panoLocation.longitude }
        }),
        radius
      }
    }
  },
  fromGenerated: (data) => {
    const { createdAt, id, panoLocation, ...clue } = data

    return {
      ...clue,
      createdAt: createdAt ? new Date(createdAt) : new Date(),
      panoLocation: new GeoPoint(panoLocation.latitude, panoLocation.longitude),
      updatedAt: new Date(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  },
  toFirestore: (data) => {
    const { free, imageText, imageUrl, metadata = {} } = data
    const { auto, bearing, distance, panoId, panoLocation, price, radius } = metadata
    return {
      ...(auto && { auto }),
      ...(bearing && { bearing }),
      ...(distance && { distance }),
      ...(imageText && { imageText }),
      ...(panoId && { panoId }),
      ...(panoLocation && {
        panoLocation: new GeoPoint(panoLocation.latitude, panoLocation.longitude)
      }),
      imageUrl,
      price: free ? 0 : price ?? 50,
      ...(radius && { radius }),
      type: ClueType.STREET_VIEW
    }
  }
}

const textClueConverter = {
  fromFirestore: ({ centerPoint, errors = [], latitude, longitude, place, placeType, text }) => ({
    ...(placeType && {
      centerPoint: centerPoint
        ? centerPoint
        : isCoordinates(latitude) && isCoordinates(longitude)
        ? { latitude, longitude }
        : addError({ errors, type: 'centerPoint' }),
      place: isStringOrNumber(place) ? place : addError({ errors, type: 'place' }),
      placeType: isStringOrNumber(placeType)
        ? placeType
        : addError({ errors, type: 'placeType', fallback: 'poi' }),
      type: getOption(ClueType.POI, CLUE_TYPE_OPTIONS)
    }),
    text: isStringOrNumber(text) ? text.toString() : addError({ errors, type: 'text' })
  }),
  toFirestore: ({ difficulty, free, text }) => ({
    price: free ? 0 : 10 * (6 - difficulty.value),
    text,
    type: ClueType.TEXT
  })
}

const poiClueConverter = {
  fromFirestore: textClueConverter.fromFirestore,
  toFirestore: (data) => {
    const { centerPoint, difficulty, free, latitude, longitude, place, placeType, text, textType } =
      data

    return {
      centerPoint: centerPoint
        ? new GeoPoint(centerPoint.latitude, centerPoint.longitude)
        : new GeoPoint(latitude, longitude),
      ...(place && { place }),
      ...(placeType && { placeType }),
      price: free ? 0 : 10 * (6 - difficulty.value),
      text,
      ...(textType && { textType }),
      type: ClueType.TEXT
    }
  }
}

const treasureClueConverter = {
  fromFirestore: ({ difficulty, errors = [], imageUrl, rotated, zoom }) => ({
    imageUrl: isImageUrl(imageUrl) ? imageUrl : addError({ errors, type: 'imageUrl' }),
    ...(rotated && { rotated }),
    zoom:
      getOption(zoom, TREASURE_ZOOM_OPTIONS) ??
      DIFF_TO_ZOOM[difficulty.value] ??
      addError({ errors, type: 'zoom' })
  }),
  toFirestore: ({ difficulty, free, imageUrl, rotated, zoom }) => {
    return {
      imageUrl,
      price: free ? 0 : 60,
      ...(rotated && { rotated }),
      type: ClueType.TREASURE,
      zoom: zoom?.value || DIFF_TO_ZOOM[difficulty.value]
    }
  }
}

export const converterFromType = (type) => {
  switch (type?.value) {
    case ClueType.AREA:
      return areaClueConverter
    case ClueType.IMAGE:
    case ClueType.STREET_VIEW_OLD:
      return imageClueConverter
    case ClueType.POI:
      return poiClueConverter
    case ClueType.STREET_VIEW:
      return streetviewConverter
    case ClueType.TEXT:
      return textClueConverter
    case ClueType.TREASURE:
      return treasureClueConverter
    default:
      return { fromFirestore: () => {}, toFirestore: () => {} }
  }
}

const addError = ({ errors, type, fallback = undefined }) => {
  errors.push(type)
  return fallback
}

const DIFF_TO_ZOOM = {
  1: 14,
  2: 15,
  3: 16,
  4: 17,
  5: 18
}
