import React, { createContext, useState, useMemo, useCallback, useReducer, useEffect } from 'react'
import { db } from '../lib/firebase'
import { locationReducer } from '../reducers/locationsReducer'
import { actionTypes, cellReducer } from '../reducers/cellReducer'
import { useAreas } from './AreasContext'
import { useMap } from './MapContext'
import { featureCollection } from '@turf/helpers'
import useS2Cells from '../hooks/useS2Cells'
import { candidateCellConverter } from '../utility/formatCandidates'
import useGetCells from '../hooks/firebase/useGetCells'
import useGetPlayableLocations from '../hooks/firebase/useGetPlayableLocations'
import { CellScanMode, LocationTab } from '../enums'
import uniqBy from 'lodash/uniqBy'
import { FormProvider, useForm } from 'react-hook-form'
import { S2_CELL_LEVEL_OPTIONS } from '../utility/labeledOptions'
import { playableLocationsConverter } from '../utility/formatPlayableLocations'

export const LocationsContext = createContext()

export const LocationsProvider = ({ children }) => {
  const formMethods = useForm({
    defaultValues: {
      enrichOptions: {
        elevation: false,
        google: false,
        mapbox: false,
        wiki: false
      },
      fetchOptions: {
        google: false,
        wiki: true
      },
      generateCandidates: false,
      locationLimit: 1000,
      maxCells: 100,
      s2Level: S2_CELL_LEVEL_OPTIONS[1],
      scanMode: CellScanMode.CLICK,
      scanRadius: 2000
    },
    mode: 'onChange'
  })
  const [locations, dispatch] = useReducer(locationReducer, [])
  const [candidateCells, cDispatch] = useReducer(cellReducer, [])
  const [locationCells, lDispatch] = useReducer(cellReducer, [])
  const [playableCells, pDispatch] = useReducer(cellReducer, [])

  const { activeCity, activeCountry } = useAreas()
  const { onNextPlayableLocationCells } = useGetPlayableLocations(pDispatch)

  const { clearFocus, focusOnBbox, focusOnPoint } = useMap()
  const { getS2RectFromId } = useS2Cells()
  const { onNextCandidateCells } = useGetCells()
  const [activeState, setActiveState] = useState()
  const [activeTab, setActiveTab] = useState(LocationTab.GENERATE)
  const [dragging, setDragging] = useState(false)
  const [loading, setLoading] = useState(false)
  const [queriedCells, setQueriedCells] = useState({})
  const [s2Cells, setS2Cells] = useState([])

  const locationCellData = useMemo(
    () => featureCollection(locationCells.map(getS2RectFromId)),
    [locationCells, getS2RectFromId]
  )

  const candidateCellData = useMemo(
    () => featureCollection(candidateCells.map((cell) => getS2RectFromId(cell))),
    [candidateCells, getS2RectFromId]
  )
  const playableCellData = useMemo(
    () => featureCollection(playableCells.map((cell) => getS2RectFromId(cell))),
    [playableCells, getS2RectFromId]
  )

  const addCells = useCallback((cells) => {
    setS2Cells((current = []) => uniqBy([...current, ...cells], 'properties.id'))
  }, [])

  const removeCells = useCallback((cells) => {
    setS2Cells((current = []) =>
      current.filter(({ properties }) =>
        cells.every((cell) => cell.properties.id !== properties.id)
      )
    )
  }, [])

  const clearS2Cells = useCallback(() => {
    setS2Cells([])
  }, [])

  const clearScanning = useCallback(() => {
    formMethods.setValue('scanCenterPoint')
  }, [formMethods])

  const clearLocations = useCallback(() => {
    setQueriedCells({})
    dispatch({ type: 'CLEAR_LOCATIONS' })
  }, [])

  // Reset values to default
  const resetDefaults = useCallback(() => {
    cDispatch({ type: actionTypes.clear })
    lDispatch({ type: actionTypes.clear })
    pDispatch({ type: actionTypes.clear })
    clearS2Cells()
    clearLocations()
    clearScanning()
  }, [clearS2Cells, clearScanning, clearLocations])

  // Subscribe to cells in Sweden
  // *** Skip to save firestore reads
  useEffect(() => {
    if (activeCountry?.id !== 'se') return
    const unsubscribe = db
      .collection('playable-locations')
      .withConverter(playableLocationsConverter)
      .onSnapshot(onNextPlayableLocationCells)

    return unsubscribe
  }, [activeCountry, onNextPlayableLocationCells])

  // Subscribe to cells by country
  useEffect(() => {
    if (!activeCountry?.id) return clearFocus()

    const unsubscribe = db
      .collection('mercator-request-log')
      .where('country', '==', activeCountry.id)
      .withConverter(candidateCellConverter)
      .onSnapshot((onNext) => onNextCandidateCells(onNext, cDispatch, lDispatch))

    if (activeCity) focusOnPoint(activeCity, { minZoom: 11, maxZoom: 14 })
    else focusOnBbox(activeCountry.bbox, { padding: 200 })

    return () => {
      unsubscribe()
      resetDefaults()
    }
  }, [
    activeCity,
    activeCountry,
    clearFocus,
    focusOnBbox,
    focusOnPoint,
    onNextCandidateCells,
    resetDefaults
  ])

  const defaultValues = useMemo(
    () => ({
      activeState,
      activeTab,
      addCells,
      candidateCellData,
      clearLocations,
      clearS2Cells,
      clearScanning,
      dispatch,
      dragging,
      loading,
      locationCellData,
      locations,
      playableCellData,
      queriedCells,
      removeCells,
      s2Cells,
      setActiveState,
      setActiveTab,
      setDragging,
      setLoading,
      setQueriedCells,
      setS2Cells
    }),
    [
      activeState,
      activeTab,
      addCells,
      candidateCellData,
      clearS2Cells,
      clearLocations,
      clearScanning,
      dragging,
      loading,
      locationCellData,
      locations,
      playableCellData,
      queriedCells,
      removeCells,
      s2Cells
    ]
  )
  return (
    <FormProvider {...formMethods}>
      <LocationsContext.Provider value={defaultValues}>{children}</LocationsContext.Provider>
    </FormProvider>
  )
}

// Hook
export const useLocations = () => {
  const context = React.useContext(LocationsContext)
  if (context === undefined)
    throw new Error('`useLocations` hook must be used within a `LocationsProvider` component')
  return context
}
