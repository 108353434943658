import React, { useCallback, useMemo } from 'react'
import tw, { styled, theme } from 'twin.macro'
import config from '../../config'
import { useSupport } from '../../contexts/SupportContext'
import Icon from '../../styles/Icons'
import { Button, MoreButton } from '../../styles/Layout'
import { ExternalLink, ExternalRoute } from '../../styles/Link'
import { timeAgo } from '../../utility/helperFunctions'
import { format } from 'date-fns'

export const Items = ({ items, refresh }) => {
  const { activeConversation, loading, openConversation } = useSupport()

  const onClickItem = useCallback(
    (conversation) => {
      console.log({ conversation })
      if (loading) return
      openConversation(conversation)
    },
    [loading, openConversation]
  )

  return useMemo(
    () =>
      items.map((item, i) => (
        <ConversationItem
          active={activeConversation?.id === item?.id}
          key={item.id}
          item={item}
          index={i}
          onClick={onClickItem}
        />
      )),
    [activeConversation, items, onClickItem, refresh] // eslint-disable-line react-hooks/exhaustive-deps
  )
}

export const ConversationItem = ({ active, item, onClick }) => {
  const { resolveConversation, unresolveConversation } = useSupport()
  const { id, messages = [], resolved, resolvedAt, user } = item

  const latest = messages[messages.length - 1] ?? {}
  const latestTimeAgo = timeAgo(new Date(), latest.sentAt?.toDate())

  const unread = useMemo(
    () => messages.filter((message) => message.sentByUser && !message.seenAt),
    [messages]
  )
  return (
    <Container active={active}>
      {!!unread?.length && <Unread>{unread.length}</Unread>}
      <div tw="flex w-full">
        <MessageContainer onClick={() => onClick(item)}>
          <Image>
            <img
              src={
                latest?.sentByUser
                  ? config.user.DEFAULT_USER_IMAGE
                  : config.support.DEFAULT_SUPPORT_IMAGE
              }
            />
          </Image>
          <Message>
            <User>{user.displayName || user.email || user.uid || '-'}</User>
            {latest.sentAt && (
              <LatestContainer>
                <Latest>{latest.message}</Latest>
                <TimeAgo>• {latestTimeAgo}</TimeAgo>
              </LatestContainer>
            )}
          </Message>
          {resolved && (
            <Resolved>
              <Icon.Check size="md" />
            </Resolved>
          )}
        </MessageContainer>
        <MoreContainer>
          <MoreButton
            tw="hover:bg-gray-500 active:bg-gray-600 p-1"
            color={theme`colors.bismark.700`}
            content={
              <>
                <Button.Submit
                  size="sm"
                  onClick={() =>
                    resolved ? unresolveConversation({ id }) : resolveConversation({ id })
                  }
                  ring
                >
                  {resolved ? (
                    <Icon.ThumbsDown size="sm" mr="2" />
                  ) : (
                    <Icon.ThumbsUp size="sm" mr="2" />
                  )}
                  {resolved ? 'Unresolve Conversation' : 'Resolve Conversation'}
                </Button.Submit>

                <ExternalRoute to={`/users?id=${user.uid}`}>
                  <Button.Secondary ring size="sm" tw="w-full">
                    <Icon.User size="sm" mr="2" />
                    Visit User Page
                  </Button.Secondary>
                </ExternalRoute>
                <ExternalLink
                  href={`${config.firebase.FIRESTORE_URL}/support-chat/${id}`}
                  tw="mt-auto"
                >
                  <Button.White ring size="sm" tw="w-full">
                    <Icon.Fire size="sm" mr="2" />
                    Show Chat in Firebase
                  </Button.White>
                </ExternalLink>
                <ExternalLink href={`${config.firebase.FIRESTORE_URL}/users/${user.uid}`}>
                  <Button.White ring size="sm" tw="w-full">
                    <Icon.Fire size="sm" mr="2" />
                    Show User in Firebase
                  </Button.White>
                </ExternalLink>
              </>
            }
          />
        </MoreContainer>
      </div>
      <ButtomContainer>
        <div>{`Created ${format(item.createdAt, 'dd/MM-yy, HH:mm')}`}</div>
        {resolvedAt && <div>{`✓ Resolved ${format(resolvedAt, 'dd/MM-yy, HH:mm')}`}</div>}
      </ButtomContainer>
    </Container>
  )
}

const Container = styled.div`
  ${tw`relative flex flex-col w-full items-center bg-gray-300 hover:bg-gray-400 rounded-md cursor-pointer shadow-md`}
  ${({ active }) => active && tw`ring-8 ring-gold-600`}
`
const MessageContainer = styled.div`
  ${tw`relative flex flex-1 items-center p-2 gap-2 md:gap-3 text-gray-900 truncate`}
`
const MoreContainer = tw.div`self-center m-2`
const Message = tw.div`flex flex-col w-full truncate`
const User = tw.div`font-semibold text-base md:text-lg`
const LatestContainer = tw.div`flex items-center gap-3 text-sm md:text-base`
const Latest = tw.div`gap-3 pl-2 lg:pl-3 truncate`
const TimeAgo = tw.div`text-gray-600 italic font-medium text-xs md:text-sm`
const ButtomContainer = tw.div`flex w-full justify-between px-2 py-1 flex-wrap text-xs 
bg-matrix-800 text-gray-300 font-medium rounded-b-md -m-0.5 select-none`
const Unread = tw.div`absolute -top-1 -left-1 z-20 flex justify-center items-center w-6 h-6 rounded-full bg-sky-600 ring-4 ring-sky-400 text-sky-100 select-none`
const Image = tw.div`flex-none w-10 h-10 lg:w-14 lg:h-14 rounded-lg shadow-md overflow-hidden select-none`
const Resolved = tw.div`absolute bottom-0.5 left-0 p-0.5 md:p-1 rounded-tr-md bg-green-600 text-white shadow-md`
