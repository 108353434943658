import React from 'react'
import tw from 'twin.macro'
import { ATag } from '../../../styles/Link'

export const LinkItem = ({ url }) => {
  return (
    <ItemContainer>
      <Header tw="bg-sky-200 text-sky-900">LINK</Header>
      <ATag href={url} tw="p-2 break-all">
        {url}
      </ATag>
    </ItemContainer>
  )
}

export const PropsItem = ({ props }) => {
  return (
    <ItemContainer>
      <Header tw="bg-norway-200 text-norway-800">USED PROPS</Header>
      <Props>
        {Object.keys(props).map((key) => (
          <div key={key} tw="flex-col">
            <ATag href={'https://www.wikidata.org/wiki/Property:P31'} tw="font-bold">
              {key}
            </ATag>
            {props[key].map(({ value }, i) => (
              <div key={i} tw="break-all">
                {value}
              </div>
            ))}
          </div>
        ))}
      </Props>
    </ItemContainer>
  )
}
export const ExtractItem = ({ children }) => {
  return (
    <ItemContainer>
      <Header tw="bg-amethyst-100 text-amethyst-800">SUMMARY</Header>
      <Content>{children}</Content>
    </ItemContainer>
  )
}

const ItemContainer = tw.div`relative flex flex-col shadow-md font-light`
const Content = tw.div`flex p-2 bg-white`
const Props = tw.div`flex flex-col p-2 gap-3`
const Header = tw.div`flex p-2 text-gray-800 font-semibold`
