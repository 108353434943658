import React, { useCallback, useEffect } from 'react'
import tw, { styled, theme } from 'twin.macro'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Card } from '../../styles/Layout'
import Icon from '../../styles/Icons'
import { Loader } from '../Loader/Loader'
import { useSeason } from '../../contexts/SeasonsContext'
import { SeasonStatus, SeasonType } from '../../enums'
import { SeasonForm } from './SeasonForm'
import { getOption } from '../../utility/helperFunctions'
import { SEASON_TYPE_OPTIONS } from '../../utility/labeledOptions'
import RBAC from '../RBAC'
import { setNextStatus } from '../../utility/formatSeasons'
import { useIsMounted } from '../../hooks/useIsMounted'
import { Alerts } from '../../utility/alerts'
import { CreateSeasonMap } from './Map/CreateSeasonMap'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import { Action } from '../../layout/List'

export const CreateSeason = ({ season = {} }) => {
  const {
    adding,
    deleteSeason,
    loading,
    saveSeason,
    setValidateForm,
    stopEditingSeason,
    validateForm
  } = useSeason()
  const { mounted } = useIsMounted()
  const methods = useForm({
    defaultValues: {
      ...merge(
        {
          active: false,
          countries: [],
          items: {
            common: {
              arItemAssetPath: '',
              assetPath: '',
              value: 1,
              weight: 1
            },
            rare: {
              arItemAssetPath: '',
              assetPath: '',
              value: 3,
              weight: 0.1
            }
          },
          itemsMinSpacing: 100,
          itemsPerS2Cell: 40,
          maxNumberOfItems: 150,
          name: '',
          playableWorldWide: true,
          type: getOption(SeasonType.SEASON, SEASON_TYPE_OPTIONS),
          uiElements: {
            counter: {
              assetPath: ''
            }
          }
        },
        {
          ...omit(season, ['errors', 'path', 'updatedAt'])
        }
      )
    },
    mode: 'onBlur'
  })
  const { clearErrors, handleSubmit, register, setError, setValue, watch } = methods
  const { active, dateRange } = watch()

  const activeChanged = active ? !season.active : season.active
  const canDeleteSeason = !adding && season.status?.removable
  const locked = season.status?.locked

  const currentStatus = adding
    ? active
      ? SeasonStatus.UPCOMING
      : SeasonStatus.DRAFT
    : season.status
  const nextStatus = adding
    ? ''
    : setNextStatus({ active, dateRange }) === season.status
    ? ''
    : setNextStatus({ active, dateRange })

  const onSubmitSeason = useCallback(
    async (formData) => {
      const { countries, playableWorldWide } = formData
      if (!playableWorldWide && !countries.length)
        return setError('countries', { type: 'manual', message: 'Select at least one country' })

      // Show activation information
      if (formData.active && !season.active) {
        const { value: ok } = await mounted(Alerts.Season.ACTIVATE_INFO())
        if (!ok) return
      }
      // Show deactivation information
      if (!formData.active && season.active) {
        if (season.status === SeasonStatus.UPCOMING) {
          const { value: ok } = await mounted(Alerts.Season.DEACTIVATE_UPCOMING_INFO())
          if (!ok) return
        }
      }
      // Save the season
      await saveSeason({ ...season, ...formData })
    },
    [mounted, saveSeason, season, setError]
  )

  // Activate and require validation
  const onActivateSeason = useCallback(async () => {
    setValidateForm(true)
    setValue('active', true)
  }, [setValidateForm, setValue])

  // Deactivate and skip validation
  const onDeactivateSeason = useCallback(async () => {
    setValidateForm(false)
    setValue('active', false)
  }, [setValidateForm, setValue])

  // Delete season if possible
  const onDeleteSeason = useCallback(async () => {
    const { value: remove } = await mounted(Alerts.Season.DELETE_WARNING())
    if (!remove) return

    await deleteSeason(season)
  }, [deleteSeason, mounted, season])

  // Register fields onMount
  useEffect(() => {
    register('active')
    if (!validateForm) clearErrors()
  }, [clearErrors, register, validateForm])

  return (
    <FormProvider {...methods}>
      <Container>
        <FormContainer>
          <Card.Container
            header={adding ? 'Create New Season' : `Edit ${season.name ?? 'Season'}`}
            color={theme`colors.amethyst`}
            xHover={theme`colors.amethyst.600`}
            xActive={theme`colors.amethyst.700`}
            onClose={stopEditingSeason}
          >
            <Loader loading={loading} />
            <Action.Header>
              <Action.Active active={active} changed={activeChanged} />
              <Action.Status status={currentStatus} nextStatus={nextStatus} tw="ml-auto" />
              <Action.More
                content={
                  <>
                    {active ? (
                      <Button.Primary
                        size="sm"
                        onClick={onDeactivateSeason}
                        disabled={locked || loading}
                        ring
                      >
                        <Icon.Moon size="sm" mr="2" />
                        Deactivate
                      </Button.Primary>
                    ) : (
                      <Button.Secondary
                        size="sm"
                        onClick={onActivateSeason}
                        disabled={locked || loading}
                        ring
                      >
                        <Icon.Sun size="sm" mr="2" />
                        Activate
                      </Button.Secondary>
                    )}
                    <RBAC>
                      <Button.Warning
                        size="sm"
                        onClick={onDeleteSeason}
                        disabled={!canDeleteSeason || locked || loading}
                        ring
                      >
                        <Icon.Trashcan size="sm" mr="2" />
                        Delete
                      </Button.Warning>
                    </RBAC>
                  </>
                }
              />
            </Action.Header>

            <SeasonForm locked={locked} />
            <Card.Footer color={theme`colors.amethyst`}>
              <Button.White onClick={stopEditingSeason} ring tw="mr-auto">
                <Icon.Close mr="2" />
                Cancel
              </Button.White>
              {active ? (
                <Button.Submit onClick={handleSubmit(onSubmitSeason)} disabled={loading} ring>
                  <Icon.Check mr="2" />
                  Save Season
                </Button.Submit>
              ) : (
                <Button.Primary onClick={handleSubmit(onSubmitSeason)} disabled={loading} ring>
                  <Icon.Download mr="2" />
                  Save Draft
                </Button.Primary>
              )}
            </Card.Footer>
          </Card.Container>
        </FormContainer>
        <MapContainer>
          <CreateSeasonMap locked={locked} />
        </MapContainer>
      </Container>
    </FormProvider>
  )
}

const FormContainer = tw.div`flex flex-col h-full transition-all duration-300`
const MapContainer = tw.div`flex justify-center overflow-hidden h-full minHeight[50vh]`
const Container = styled.div`
  ${tw`flex flex-col lg:flex-row h-full w-full transition-all overflow-scroll`}
  & > ${FormContainer} {
    ${tw`w-full lg:w-1/2`}
  }
  & > ${MapContainer} {
    ${tw`w-full lg:w-1/2 lg:p-0`}
  }
`
