import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { Controller, useFormContext } from 'react-hook-form'
import { Section } from '../../Hvnt/FormSection'
import { Button, Input } from '../../../styles/Layout'
import { validate } from '../../../utility/formValidation'
import Icon from '../../../styles/Icons'
import { useInfoScreen } from '../../../contexts/InfoScreenContext'
import DatePicker from '../../DatePicker'
import config from '../../../config'
import { Alerts } from '../../../utility/alerts'
import { ImageInput, ImagePopup } from '../../Image'

export const ImageTypeForm = ({ flagUpload, locked }) => {
  const { validateForm } = useInfoScreen()
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext()

  // Save image from file and create image id
  const onSelectFile = async (e) => {
    function readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
      })
    }
    const files = e.target?.files
    if (files && files.length > 0) {
      const file = files[0]
      if (file.size < config.MAX_IMAGE_UPLOAD_SIZE) {
        flagUpload(true)
        return await readFile(file)
      } else {
        Alerts.Image.TOO_BIG(file)
      }
    }
  }

  return (
    <>
      <Section
        label="Upload screen image"
        field="imageUrl"
        error={errors.imageUrl}
        required
        locked={locked}
      >
        <Controller
          control={control}
          name="imageUrl"
          rules={{ validate: validateForm ? validate.image : null }}
          render={({ field: { onChange } }) => {
            const url = watch('imageUrl')
            return (
              <ImageContainer disabled={locked} hide={url}>
                {url && (
                  <>
                    <PopupImage expand={false} src={url} />
                    <DeleteButton
                      onClick={() => {
                        flagUpload(false)
                        onChange(undefined)
                      }}
                    >
                      <Icon.Trashcan size="sm" />
                    </DeleteButton>
                  </>
                )}
                <UploadButton as="label" htmlFor="img-upload">
                  <ImageInput
                    id="img-upload"
                    onChange={async (file) => onChange(await onSelectFile(file))}
                  />
                  <Icon.Image mr="2" />
                  {url ? 'Change Image' : 'Select Image'}
                </UploadButton>
              </ImageContainer>
            )
          }}
        />
      </Section>

      <Section
        label="When should it be displayed?"
        field="dateRange"
        error={errors.dateRange}
        locked={locked}
        required
      >
        <Controller
          control={control}
          name="dateRange"
          rules={{ validate: validateForm ? validate.dateRange : null }}
          render={({ field: { value, onChange }, fieldState }) => (
            <DatePicker
              error={fieldState.error}
              onChange={onChange}
              shouldValidate={validateForm}
              startDisabled={locked}
              value={value}
            />
          )}
        />
        <Input.Error>{errors.dateRange?.message}</Input.Error>
      </Section>
    </>
  )
}

const PopupImage = tw(ImagePopup)`h-full maxHeight[24rem] shadow-md rounded-md`
const DeleteButton = tw(Button.White)`absolute top-1 right-1 p-1.5`
const UploadButton = tw(Button.Primary)`absolute bottom-0`
const ImageContainer = styled.div`
  ${tw`relative flex width[fit-content] min-height[40px]`}
  ${({ disabled, hide }) => css`
    & > ${UploadButton}, ${DeleteButton} {
      ${hide && tw`invisible`}
    }
    &:hover > ${UploadButton} {
      ${!disabled && hide && tw`visible w-full`}
    }
    &:hover > ${DeleteButton} {
      ${!disabled && hide && tw`visible`}
    }
  `}
`
