import latinize from 'latinize'
import { GeoPoint, FieldValue, db, auth } from '../lib/firebase'

export const countryConverter = {
  fromFirestore: (snapshot) => {
    const { active, bbox, centerPoint, cities = [], name } = snapshot.data()
    const id = snapshot.id

    return {
      active,
      bbox,
      centerPoint: { latitude: centerPoint.latitude, longitude: centerPoint.longitude },
      cities: cityConverter.fromFirestore({ cities, country: name, id }),
      name,
      id
    }
  },
  toFirestore: ({ adding, centerPoint, name, ...country }) => {
    const topicName = latinize(name)
      ?.trim()
      .toLowerCase()
      .replace(/[^0-9A-Za-z]+/g, '')

    return {
      ...country,
      ...(adding && { active: false, createdAt: FieldValue.serverTimestamp() }), // on create
      centerPoint: new GeoPoint(centerPoint.latitude, centerPoint.longitude),
      name,
      topicName,
      updatedAt: FieldValue.serverTimestamp(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  }
}

export const cityConverter = {
  // Converts cities object to an array
  fromFirestore: ({ cities, country, id }) =>
    Object.keys(cities).map((city) => {
      const { centerPoint } = cities[city]
      return {
        ...cities[city],
        centerPoint: { latitude: centerPoint.latitude, longitude: centerPoint.longitude },
        country,
        name: city,
        path: `${id}/${city}`
      }
    }),
  toFirestore: (data) => {
    const { active, centerPoint, id, name } = data
    const topicName = latinize(name)
      ?.trim()
      .toLowerCase()
      .replace(/[^0-9A-Za-z]+/g, '')

    return {
      active: active ?? false,
      centerPoint: new GeoPoint(centerPoint.latitude, centerPoint.longitude),
      name,
      topicName: `${id}_${topicName}`
    }
  }
}
