import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import Icon from '../../styles/Icons'
import { OrderStyle } from '../../styles/Select/PaginatorStyle'
import Dropdown from '../Dropdown'

export const FirestoreFilter = ({ onUpdate, options = [] }) => {
  const [sortField, setSortField] = useState(options[0]?.value)
  const [order, setOrder] = useState('desc')

  useEffect(() => {
    if (!sortField) return
    onUpdate([sortField, order])
  }, [onUpdate, order, sortField])

  return (
    <>
      <Dropdown.Paginator
        defaultValue={options[0]}
        options={options}
        onChange={({ value }) => setSortField(value)}
        style={OrderStyle}
      />
      <Dropdown.Paginator
        defaultValue={orderOptions[0]}
        options={orderOptions}
        onChange={({ value }) => setOrder(value)}
        style={OrderStyle}
      />
    </>
  )
}

const Label = tw.div`flex justify-center gap-1`
const orderOptions = [
  {
    value: 'desc',
    label: (
      <Label>
        desc
        <Icon.SortDown size="xs" />
      </Label>
    )
  },
  {
    value: 'asc',
    label: (
      <Label>
        asc
        <Icon.SortUp size="xs" />
      </Label>
    )
  }
]
