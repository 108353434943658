import React from 'react'
import tw, { styled } from 'twin.macro'

export const Overlay = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

const Container = styled.div`
  ${tw`absolute flex flex-col z-10 overflow-y-hidden pointer-events-none gap-3`}
  & > * {
    ${tw`pointer-events-auto`}
  }
`
