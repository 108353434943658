import React from 'react'

export const ImageInput = (props) => (
  <input
    accept="image/png, image/jpg, image/jpeg, image/webp"
    id="file-upload"
    tw="hidden"
    type="file"
    {...props}
  />
)
