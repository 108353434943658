import React, { Fragment, useEffect } from 'react'
import { PageLayoutCol } from '../../styles/Layout'
import tw, { css, styled } from 'twin.macro'
import { PlayerList } from '../Player/PlayerList'
import { Outlet, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { usePlayer } from '../../contexts/PlayerContext'
import { PlayerProfile } from '../Player/Profile/PlayerProfile'
import { PlayerMap } from '../Player/Map/PlayerMap'
import { Loader } from '../Loader/Loader'
import { MapProvider } from '../../contexts/MapContext'

const PlayersPage = () => {
  const { activePlayer, addingUser, fetchingUser, players, visitDeepRoute } = usePlayer()
  const navigate = useNavigate()
  const location = useLocation()
  const [params] = useSearchParams()

  // Visit deep route
  useEffect(() => {
    const id = params.get('id')
    const tab = params.get('tab') ?? 'profile'
    if (!id) return

    visitDeepRoute({ id, tab })
  }, [location, params, visitDeepRoute])

  // Update Routes through side effects
  useEffect(() => {
    if (addingUser) return navigate('add')
    if (!activePlayer) navigate('', { replace: true })
  }, [activePlayer, addingUser, navigate])

  return (
    <Page>
      <Routes>
        <Route
          path="/"
          element={
            <Container expand={activePlayer}>
              <ListContainer>
                <Loader loading={fetchingUser} text="Fetching user" />
                <Outlet />
              </ListContainer>
              <MapContainer>
                <MapProvider>
                  <PlayerMap />
                </MapProvider>
              </MapContainer>
            </Container>
          }
        >
          <Route path="" element={<PlayerList items={players} />} />
          <Route
            path="/:userId/*"
            element={activePlayer ? <PlayerProfile player={activePlayer} /> : <Fragment />}
          />
        </Route>
      </Routes>
    </Page>
  )
}

export default PlayersPage

const Page = tw(PageLayoutCol)`lg:flex-row lg:justify-center`
const ListContainer = tw.div`relative flex flex-col lg:flex-row minHeight[50vh] w-full h-full z-10 transition-all duration-300 `
const MapContainer = tw.div`flex flex-1 overflow-hidden z-0 minHeight[50vh] w-full h-full transition-all duration-300`
const Container = styled.div`
  ${tw`flex flex-col lg:flex-row w-full`}
  ${({ expand }) => css`
     {
      & > ${ListContainer} {
        ${expand ? tw`lg:w-7/12` : tw`lg:w-1/2`}
      }
      & > ${MapContainer} {
        ${expand ? tw`lg:w-5/12` : tw`lg:w-1/2`}
      }
    }
  `}
`
