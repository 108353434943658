import uniqBy from 'lodash/uniqBy'

export const actionTypes = {
  add: 'ADD_CONVERSATION',
  set: 'SET_CONVERSATIONS',
  remove: 'REMOVE_CONVERSATION',
  edit: 'EDIT_CONVERSATION',
  editMany: 'EDIT_CONVERSATIONS',
  clear: 'CLEAR_CONVERSATIONS'
}

export const conversationReducer = (state, action) => {
  // console.log({ state, action })
  switch (action.type) {
    case actionTypes.add: {
      const { conversation } = action
      return uniqBy([...state, conversation], 'id')
    }
    case actionTypes.set: {
      const { conversations } = action
      return uniqBy([...state, ...conversations], 'id')
    }
    case actionTypes.remove: {
      const { conversation } = action
      return state.filter((item) => item.id !== conversation.id)
    }
    case actionTypes.edit: {
      const { conversation } = action
      return state.map((item) =>
        item.id === conversation.id ? { ...item, ...conversation } : item
      )
    }
    case actionTypes.clear: {
      return []
    }
    default:
      return state
  }
}
