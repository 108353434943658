import {
  conditionalDelete,
  createOption,
  createStringArray,
  mapKeyValueToOption
} from './helperFunctions'
import { auth, db, FieldValue, GeoPoint } from '../lib/firebase'
import {
  isImageUrl,
  isStringOrNumber,
  isGeoPoint,
  isInteger,
  isTimestamp,
  isValidDateRange,
  isValidPrize
} from './validationFunctions'
import { hvntPrizeConverter } from './formatPrizes'
import config from '../config'
import { HVNT_CATEGORY_OPTIONS } from './labeledOptions'
import { ChallengeDistributionType, HvntStatus, HvntStatusType } from '../enums'

export const hvntConverter = {
  fromFirestore: (snapshot) => {
    const errors = []
    const addError = (error, fallback = undefined) => {
      errors.push(error)
      return fallback
    }
    const id = snapshot.id
    const path = snapshot.ref.path
    const {
      active,
      category,
      centerPoint,
      cityPath,
      createdAt,
      description,
      endDate,
      entryCode,
      featured,
      hidden,
      highScores,
      imageUrl,
      mapUrl,
      name,
      numberOfCheckpoints,
      prize,
      prizes = [],
      radius,
      routeOptions: area = {},
      startDate,
      status,
      tags,
      unlisted,
      updatedAt
    } = snapshot.data()

    let formattedHvnt = {
      active: active ?? false,

      area: {
        ...area,
        centerPoint: isGeoPoint(centerPoint)
          ? { latitude: centerPoint.latitude, longitude: centerPoint.longitude }
          : addError('centerPoint'),
        challenge: {
          distribution: Array.isArray(area.challenge?.distribution)
            ? area.challenge.distribution
            : [area.challenge?.distribution ?? ChallengeDistributionType.NONE]
        },
        mapUrl: isImageUrl(mapUrl) ? mapUrl : addError('mapUrl'),
        radius: isInteger(radius) ? radius : addError('radius'),
        tags: {
          ...(Array.isArray(area.tags?.custom) && {
            custom: area.tags.custom.map(createOption),
            useCustom: !!area.tags.custom.length
          }),
          ...(Array.isArray(area.tags?.regular)
            ? {
                regular: area.tags.regular.map(createOption),
                useRegular: !!area.tags.regular.length
              }
            : {
                regular: area.tags?.regular ?? true,
                useRegular: area.tags?.regular ?? true
              })
        }
      },
      category: category
        ? mapKeyValueToOption({
            key: 'label',
            value: category,
            options: HVNT_CATEGORY_OPTIONS
          }) ?? createOption(category) // New unknown category, create option
        : addError('category'),
      ...(cityPath && { cityPath }),
      ...(isTimestamp(createdAt) && { createdAt: new Date(createdAt.toDate()) }),
      dateRange: isValidDateRange({ startDate, endDate })
        ? { startDate: startDate.toDate(), endDate: endDate.toDate() }
        : addError('dateRange'),
      description: isStringOrNumber(description) ? description : addError('description'),
      ...(entryCode && { entryCode }),
      ...(featured && { featured }),
      id,
      ...(hidden && { hidden }),
      ...(highScores && { highScores }),
      imageUrl: isImageUrl(imageUrl) ? imageUrl : addError('imageUrl'),
      name: isStringOrNumber(name) ? name : addError('name'),
      numberOfCheckpoints: isInteger(numberOfCheckpoints)
        ? numberOfCheckpoints
        : addError('numberOfCheckpoints'),
      path,
      // Accept older prize objects
      ...(prize && {
        prizes: isValidPrize(prize)
          ? [hvntPrizeConverter.fromFirestore(prize)]
          : addError('prize', prize ? [hvntPrizeConverter.fromFirestore(prize)] : undefined)
      }),
      ...(prizes.length
        ? {
            prizes: prizes.every((prize) => isValidPrize(prize))
              ? prizes
                  .map((prize) => hvntPrizeConverter.fromFirestore(prize))
                  .sort((a, b) => a.order - b.order)
              : addError(
                  'prizes',
                  prizes.length ? prizes.map(hvntPrizeConverter.fromFirestore(prize)) : []
                )
          }
        : addError('prizes')),

      ...(tags && { tags: tags?.map(createOption) }),
      ...(unlisted && { unlisted }),
      ...(isTimestamp(updatedAt) && { updatedAt: updatedAt.toDate() })
    }

    formattedHvnt.status = setDisplayStatus({ ...formattedHvnt, errors, status })

    return { ...formattedHvnt, errors }
  },

  toFirestore: (data) => {
    const {
      active,
      area: {
        centerPoint,
        challenge,
        customRoute,
        mapUrl,
        radius,
        tags: areaTags,
        ...routeOptions
      },
      category,
      cityPath,
      createdAt,
      dateRange,
      description,
      entryCode,
      featured,
      id,
      imageUrl,
      name,
      numberOfCheckpoints,
      prize,
      prizes = [],
      tags = [],
      unlisted
      // hidden
      // s2Cells
    } = data

    return {
      active,
      category: conditionalDelete(category?.value),
      ...(centerPoint
        ? {
            centerPoint: new GeoPoint(centerPoint.latitude, centerPoint.longitude)
          }
        : { centerPoint: FieldValue.delete() }),
      ...(cityPath && { cityPath }),
      createdAt: createdAt ?? new Date(),
      endDate: conditionalDelete(dateRange?.endDate),
      startDate: conditionalDelete(dateRange?.startDate),
      description: conditionalDelete(description),
      ...(entryCode && { entryCode }),
      featured: conditionalDelete(featured),
      id,
      imageUrl: conditionalDelete(imageUrl),
      mapUrl: conditionalDelete(mapUrl),
      name: conditionalDelete(name),
      numberOfCheckpoints: conditionalDelete(numberOfCheckpoints),
      // Support old prize objects
      ...(prize
        ? { prizes: [hvntPrizeConverter.toFirestore(prize)] }
        : { prizes: FieldValue.delete() }),
      ...(prizes.length
        ? {
            prizes: prizes.map((prize, i) => ({
              ...hvntPrizeConverter.toFirestore(prize),
              order: i // Sets the prize order
            }))
          }
        : { prizes: FieldValue.delete() }),
      radius: conditionalDelete(radius),
      routeOptions: {
        challenge: {
          distribution: challenge?.distribution ?? [ChallengeDistributionType.NONE]
        },
        ...(customRoute
          ? createRouteOptions({ numberOfCheckpoints, ...routeOptions })
          : Object.keys(createRouteOptions()).reduce(
              (acc, field) => ({ ...acc, [field]: FieldValue.delete() }),
              {}
            )),
        tags: {
          regular:
            Array.isArray(areaTags?.regular) && areaTags.regular.length
              ? createStringArray(areaTags.regular)
              : areaTags?.useRegular ?? true,

          custom:
            Array.isArray(areaTags?.custom) && areaTags.custom.length
              ? createStringArray(areaTags.custom)
              : false
        }
      },
      tags: createStringArray(tags),
      ...(unlisted && { unlisted }),
      updatedAt: FieldValue.serverTimestamp(),
      updatedBy: db.doc(`users/${auth.currentUser.uid}`)
    }
  }
}

// creates route options object
export const createRouteOptions = (options = {}) => {
  const { FIRST_EASY_PROPORTION, MAX_ANGLE, TARGET_DISTANCES } = config.hvntRoute
  const customDistances = getCustomDistancesArr(options)

  const distances = {
    start: options.distances?.start ?? TARGET_DISTANCES.start,
    max: options.distances?.max ?? TARGET_DISTANCES.max,
    min: options.distances?.min ?? TARGET_DISTANCES.min,
    default: options.distances?.default ?? TARGET_DISTANCES.default,
    ...customDistances.reduce(
      (obj, key) => ({ ...obj, [key.index]: options.distances?.[key.index] ?? key.distance }),
      {}
    )
  }
  const easyProportion = options.easyProportion ?? FIRST_EASY_PROPORTION
  const maxAngle = options.maxAngle ?? MAX_ANGLE

  return { distances, easyProportion, maxAngle }
}

// calculates easy distances array
export const getCustomDistancesArr = ({ easyProportion = 0, numberOfCheckpoints = 0 }) => {
  const { TARGET_DISTANCES } = config.hvntRoute
  if (!Number(easyProportion) || !Number(numberOfCheckpoints)) return []
  const customDistances = [
    ...Array(
      Math.max(
        Math.min(
          Math.floor(Number(numberOfCheckpoints || 0) * Number(easyProportion || 0)),
          numberOfCheckpoints - 1
        ),
        0
      )
    )
  ].map((_, i) => ({
    index: i + 1,
    distance: TARGET_DISTANCES[i + 1] ?? TARGET_DISTANCES.default
  }))
  return customDistances
}

const setDisplayStatus = ({ active, errors = [], status }) => {
  // These four cases are what's supported and updated by backend
  switch (status) {
    case HvntStatusType.DRAFT:
      return HvntStatus.DRAFT

    case HvntStatusType.ENDED:
      return HvntStatus.ENDED

    case HvntStatusType.LIVE:
      if (errors.length) return HvntStatus.ERROR
      if (!active) return HvntStatus.PAUSED
      return HvntStatus.LIVE

    case HvntStatusType.UPCOMING:
      if (errors.length) return HvntStatus.ERROR
      return HvntStatus.UPCOMING

    default:
      return HvntStatus.UNSET
  }
}

/**
 * Only used internally for display purposes
 * Backend ultimately calculates the final hvnt status
 * */
export const setNextStatus = ({ active, activeChanged, dateRange = {} }) => {
  const { startDate, endDate } = dateRange
  const now = new Date()
  if (endDate < now) return HvntStatus.ENDED
  if (active) {
    if (startDate > now) return HvntStatus.UPCOMING
    if (startDate < now && now < endDate) return HvntStatus.LIVE
  }
  if (activeChanged && startDate < now && now < endDate) return HvntStatus.PAUSED
  return HvntStatus.DRAFT
}
