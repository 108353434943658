import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import tw, { theme } from 'twin.macro'
import { useLocations } from '../../contexts/LocationsContext'
import Icon from '../../styles/Icons'
import { Button } from '../../styles/Layout'

export const ClearData = () => {
  const { clearLocations, clearS2Cells, clearScanning, locations, s2Cells } = useLocations()
  const { control } = useFormContext()
  const { scanCenterPoint } = useWatch({ control })

  return (
    <Container>
      {!!s2Cells.length && (
        <Button.White ring size="sm" onClick={clearS2Cells}>
          <Icon.Close mr="2" size="sm" color={theme`colors.red`} />
          Clear Cells
        </Button.White>
      )}

      {!!scanCenterPoint && (
        <Button.White ring size="sm" onClick={clearScanning}>
          <Icon.Close mr="2" size="sm" color={theme`colors.red`} />
          Clear Scan Circle
        </Button.White>
      )}

      {!!locations.length && (
        <Button.White ring size="sm" onClick={clearLocations}>
          <Icon.Close mr="2" size="sm" color={theme`colors.red`} />
          Clear Locations
        </Button.White>
      )}
    </Container>
  )
}
const Container = tw.div`flex flex-col gap-2 p-0.5`
