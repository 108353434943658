import React from 'react'
import { RadioGroup } from '../RadioGroup'
import tw, { styled, css } from 'twin.macro'
import config from '../../config'

export const MapSwitcher = ({ onSwitch, color = 'blue', defaultChecked = 'default', ...pos }) => {
  const changeMapStyle = (current) => {
    switch (current) {
      case 'default': {
        onSwitch(config.mapbox.ALTERNATE_MAP)
        break
      }
      case 'satellite': {
        onSwitch(config.mapbox.SATELLITE_MAP)
        break
      }
      case 'performance': {
        onSwitch(config.mapbox.MONOCHROME_MAP)
        break
      }
    }
  }

  return (
    <MapSwitchContainer pos={pos}>
      <RadioContainer>
        <RadioGroup
          name="mapStyle"
          defaultChecked={defaultChecked}
          options={[
            { label: 'Default', value: 'default' },
            { label: 'Satellite', value: 'satellite' },
            { label: 'Performance', value: 'performance' }
          ]}
          onChange={changeMapStyle}
          color={color}
        />
      </RadioContainer>
    </MapSwitchContainer>
  )
}
const MapSwitchContainer = styled.div`
  ${tw`absolute flex p-1 m-1 z-10 `}
  ${({ pos: { top, bottom, left, right } }) => css`
    ${top && tw`top-0`}
    ${bottom && tw`bottom-0`}
    ${left && tw`left-0`}
    ${right && tw`right-0`}
  `}
`
const RadioContainer = tw.div`flex justify-center items-center w-auto h-9 p-1 rounded-sm bg-white border-4`
