import React, { useMemo } from 'react'
import { Popup as GlPopup } from 'react-map-gl'
import tw, { css, styled, theme } from 'twin.macro'
import { useCorporate } from '../../../contexts/CorporateContext'
import { EventLocationType, Role } from '../../../enums'
import Icon from '../../../styles/Icons'
import { Button, Status } from '../../../styles/Layout'
import { isJsonParsable } from '../../../utility/validationFunctions'
import RBAC from '../../RBAC'

const LocationPopups = ({ focusedItem, hoveredItem, ...props }) => {
  const showOnHover = useMemo(
    () => hoveredItem && hoveredItem.id !== focusedItem?.id,
    [focusedItem, hoveredItem]
  )

  return useMemo(
    () => (
      <>
        {focusedItem && <Popup {...props} item={focusedItem} focused={true} />}
        {showOnHover && <Popup {...props} item={hoveredItem} />}
      </>
    ),
    [focusedItem, hoveredItem, props, showOnHover]
  )
}

export default LocationPopups

const Popup = ({ item, focused, onEdit, onClose }) => {
  const { eventBeingEdited, locationBeingEdited } = useCorporate()
  const { active = true, centerPoint, name } = item ?? {}
  const editable =
    focused && item.type !== EventLocationType.HIDDEN && !locationBeingEdited && eventBeingEdited
  const { latitude, longitude } = centerPoint
    ? isJsonParsable(centerPoint)
      ? JSON.parse(centerPoint)
      : centerPoint
    : {}

  return (
    <>
      {latitude && longitude && (
        <StyledPopup
          anchor="bottom-left"
          color={item.color}
          closeButton={false}
          closeOnClick={focused}
          dynamicPosition={true}
          latitude={latitude}
          longitude={longitude}
          offset={[5, -10]}
          onClose={() => focused && onClose && onClose()}
          sortByDepth={true}
        >
          <Row>
            <Status active={active} />
            <Text>{name}</Text>
          </Row>
          <RBAC allow={[Role.CREATOR, Role.EDITOR]}>
            <>
              {editable && (
                <EditButton size="sm" onClick={() => onEdit && onEdit(item)}>
                  <Icon.Edit mr="2" size="sm" />
                  Edit
                </EditButton>
              )}
            </>
          </RBAC>
        </StyledPopup>
      )}
    </>
  )
}

const StyledPopup = styled(GlPopup)`
  ${({ color = theme`colors.matrix` }) => css`
    & > .mapboxgl-popup-content {
      ${tw`background[none] shadow-none p-1 font-sans max-width[200px]`}
    }
    & > .mapboxgl-popup-tip {
      ${tw`hidden`}
    }
    & > .mapboxgl-popup-content > div {
      ${tw`flex flex-col gap-1 p-1 rounded-lg rounded-bl-none shadow-lg text-white-pure`};
      ${color && `background: ${color}`}
    }
  `}
`
const Row = tw.div`flex items-center gap-3 p-2`
const Text = tw.span`font-sans font-light text-sm text-center`
const EditButton = tw(Button.Secondary)`m-0 rounded-none w-full py-0.5`
