import React, { useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Calendar } from 'react-date-range'
import { endOfDay, format } from 'date-fns'
import '../../styles/dateRangeStyle.css'
import 'react-date-range/dist/theme/default.css'
import { Button } from '../../styles/Layout'
import startOfDay from 'date-fns/startOfDay'
import Icon from '../../styles/Icons'

export const PrizeDateRange = ({ validDate, setValidDate }) => {
  const [open, setOpen] = useState(validDate)
  const onClose = useCallback(() => {
    setOpen((open) => !open)
    if (open) setValidDate()
  }, [open, setValidDate])
  const startDate = startOfDay(new Date())

  return open ? (
    <Container>
      <DatesContainer>
        <Text>Valid until</Text>
        <StyledDate>{validDate ? format(new Date(validDate), 'LLL do, HH:mm') : '–'}</StyledDate>
        <CloseX onClick={onClose}>
          <Icon.Close />
        </CloseX>
      </DatesContainer>
      <Calendar
        date={validDate}
        fixedHeight={true}
        minDate={startDate}
        onChange={(date) => setValidDate(endOfDay(date))}
      />
    </Container>
  ) : (
    <Button.Secondary onClick={onClose} tw="mx-0 sm:w-min">
      <Icon.Calendar mr="2" />
      {'Yes, please'}
    </Button.Secondary>
  )
}

const Container = tw.div`flex flex-col font-sans shadow-md bg-sky-300 overflow-hidden my-2 rounded-t-md`
const DatesContainer = tw.div`relative flex flex-wrap justify-center items-center font-light text-gray-900 p-2 gap-2`
const Text = tw.div`text-lg whitespace-nowrap`
const StyledDate = tw.div`bg-white-pure text-gray-800 px-5 py-2 text-center rounded-md shadow-inner`
const CloseX = tw.div`absolute right-2 p-1 cursor-pointer hover:bg-sky-400 active:bg-sky-500 rounded-sm`
