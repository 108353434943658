import { useCallback } from 'react'
import { db } from '../../lib/firebase'
import { actionTypes } from '../../reducers/clueReducer'
import { createImageClues } from '../../utility/formatCandidates'
import { clueConverter } from '../../utility/formatClues'

const useGetClues = (dispatch) => {
  // Query snapshot for candidates
  const onNextClues = useCallback(
    (snapshot) => {
      const clues = []
      snapshot.docChanges().forEach(async ({ doc, type }) => {
        const clue = { id: doc.id, path: doc.ref.path, ...doc.data() }
        if (type === 'added') clues.push(clue)
        if (type === 'modified') dispatch({ type: actionTypes.clues.edit, clue })
        if (type === 'removed') dispatch({ type: actionTypes.clues.remove, clue })
      })
      if (clues.length) dispatch({ type: actionTypes.clues.addMany, clues })
    },
    [dispatch]
  )

  const onNextCandidateClues = useCallback(
    (snapshot, { metadata, path }) => {
      const clues = []
      snapshot.docChanges().forEach(({ doc, type }) => {
        // Add new path
        const newPath = `${path}/clues/${doc.id}`
        const clue = { ...clueConverter.fromMercator(doc), path: newPath }
        if (type === 'added') clues.push(clue)
        if (type === 'modified') dispatch({ type: actionTypes.candidates.edit, clue })
        if (type === 'removed') dispatch({ type: actionTypes.candidates.remove, clue })
      })
      if (clues.length) {
        const data = createImageClues(metadata) ?? []
        const imageClues = data.map((clue) => {
          // Create new ref
          const newRef = db.collection(`${path}/clues`).doc()
          return { ...clue, id: newRef.id, path: newRef.path }
        })
        dispatch({ type: actionTypes.candidates.addMany, clues: imageClues.concat(clues) })
      }
    },
    [dispatch]
  )

  return {
    onNextCandidateClues,
    onNextClues
  }
}

export default useGetClues
