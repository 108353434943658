import { useCallback } from 'react'
import bbox from '@turf/bbox'
import pointGrid from '@turf/point-grid'
import { polygon } from '@turf/helpers'
import { S2 } from 's2-geometry'
import { deboxByKey } from 's2-cell-draw'

const useS2Cells = () => {
  const getS2Path = useCallback(({ latitude, longitude, level }) => {
    const key = S2.latLngToKey(latitude, longitude, level)
    const { path } = deboxByKey(key)
    return path
  }, [])

  const getS2RectsInFeature = useCallback(
    ({ feature, level = 13 }) => {
      // 500 points per feature should be sufficient
      const pointsToCheck = pointGrid(bbox(feature), 500, { units: 'meters' })
      const s2Cells = {}

      // S2 keys from coordinates
      for (const { geometry } of pointsToCheck.features) {
        const [longitude, latitude] = geometry.coordinates
        const key = S2.latLngToKey(latitude, longitude, level)
        const id = S2.keyToId(key)
        if (s2Cells[id]) continue
        s2Cells[id] = { id, key, path: getS2Path({ latitude, longitude, level }) }
      }

      // Create GeoJson features
      const s2Rects = Object.keys(s2Cells).map((id) =>
        polygon([[...s2Cells[id].path, s2Cells[id].path[0]]], { id, key: s2Cells[id].key })
      )
      return s2Rects
    },
    [getS2Path]
  )

  const getS2RectFromCenter = useCallback(({ centerPoint, level }) => {
    if (!centerPoint || !level) return

    const key = S2.latLngToKey(centerPoint.latitude, centerPoint.longitude, level)
    const id = S2.keyToId(key)
    const s2Feature = deboxByKey(key)
    // Create polygon with circular coordinates
    const pol = polygon([[...s2Feature.path, s2Feature.path[0]]], { id, key, level })

    return pol
  }, [])

  const getS2RectFromId = useCallback((cell) => {
    const { id, ...data } = cell
    const key = S2.idToKey(id)
    const s2Feature = deboxByKey(key)
    const [longitude, latitude] = s2Feature.center
    const pol = polygon([[...s2Feature.path, s2Feature.path[0]]], {
      id,
      key,
      ...data,
      centerPoint: { latitude, longitude }
    })
    return pol
  }, [])

  const getLevel12Children = useCallback((cell) => {
    const { key, s2Level } = cell.properties
    if (s2Level !== 11) return

    const childKeys = [0, 1, 2, 3].map((i) => `${key + i}`)
    const children = childKeys.map((key) => {
      const s2Feature = deboxByKey(key)
      const id = S2.keyToId(key)
      // Create polygon with circular coordinates
      return polygon([[...s2Feature.path, s2Feature.path[0]]], { id, key, level: 12 })
    })

    return children
  }, [])

  return { getLevel12Children, getS2RectsInFeature, getS2RectFromCenter, getS2RectFromId }
}
export default useS2Cells
