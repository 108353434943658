import React, { useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { Button } from '../../styles/Layout'
import { usePrizes } from '../../contexts/PrizeContext'
import { format } from 'date-fns'
import Icon from '../../styles/Icons'
import RBAC from '../RBAC'
import { PrizeTab, Role } from '../../enums'
import { Item } from '../../layout/List'
import { ATag, HvntTag } from '../../styles/Link'
import config from '../../config'
import { db, FieldValue } from '../../lib/firebase'
import { ImagePopup } from '../Image'
import { Alerts } from '../../utility/alerts'
import { capitalize } from '../../utility/helperFunctions'
import { objectIsEmpty } from '../../utility/validationFunctions'

export const Items = ({ items }) => {
  const { expandedItem, setExpandedItem } = usePrizes()

  const onClickItem = useCallback(
    (prize, opened) => {
      console.log({ prize })
      opened ? setExpandedItem(prize) : setExpandedItem()
    },
    [setExpandedItem]
  )

  return items.map((item) => (
    <PrizeItem
      key={item.id}
      item={item}
      onClick={onClickItem}
      expanded={expandedItem?.id === item?.id}
    />
  ))
}

export const PrizeItem = ({ item, expanded, onClick }) => {
  const { activeTab, deletePrize, startEditingPrize } = usePrizes()

  //Display reserved HVNT information in summary
  const hvntReservations = useMemo(
    () =>
      item.reservedPrizes.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.hvnt.id]: acc[curr.hvnt.id] ? (acc[curr.hvnt.id] += 1) : 1
        }),
        {}
      ),
    [item.reservedPrizes]
  )

  const onArchive = async () => {
    if (item.reserved) return Alerts.Prize.ARCHIVE_NOT_POSSIBLE(item)
    const { value: ok } = await Alerts.Prize.CONFIRM_ARCHIVE()
    if (!ok) return

    try {
      await db.collection('prizes').doc(item.id).update({ active: false, archivedAt: new Date() })
      Alerts.Prize.UPDATE_SUCCESS()
    } catch (err) {
      console.error(err)
      return Alerts.Prize.UPDATE_FAILED(err)
    }
  }

  const onUnarchive = async () => {
    const { value: ok } = await Alerts.Prize.CONFIRM_UNARCHIVE(item)
    if (!ok) return
    try {
      await db
        .collection('prizes')
        .doc(item.id)
        .update({ active: true, archivedAt: FieldValue.delete() })
      Alerts.Prize.UPDATE_SUCCESS()
    } catch (err) {
      console.error(err)
      return Alerts.Prize.UPDATE_FAILED(err)
    }
  }

  return (
    <Item.Container id={item.id} data-testid="prize-item">
      <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
        <Item.HeaderData colSpan={1}>
          <Item.Center>{item.status?.icon() ?? '-'}</Item.Center>
        </Item.HeaderData>
        <Item.HeaderData colSpan={3}>{item.name ?? '-'}</Item.HeaderData>
        {!item.active && (
          <Item.HeaderData colSpan={2}>{capitalize(item.deliveryType) ?? '-'}</Item.HeaderData>
        )}
        <Item.HeaderData colSpan={1}>{item.available}</Item.HeaderData>
        <Item.HeaderData colSpan={1}>{item.reserved}</Item.HeaderData>
        <Item.HeaderData colSpan={1}>{item.claimed}</Item.HeaderData>
        {activeTab?.id === PrizeTab.DIGITAL && (
          <Item.HeaderData colSpan={2}>
            {item.validTo ? format(item.validTo, 'LLL do yyyy, HH:mm') : '-'}
          </Item.HeaderData>
        )}
        {!item.active && (
          <Item.HeaderData colSpan={2}>
            {item.archivedAt ? format(item.archivedAt, 'LLL do yyyy') : '-'}
          </Item.HeaderData>
        )}
      </Item.Header>

      <Item.Summary expanded={expanded}>
        <RBAC allow={[Role.CREATOR]}>
          <Item.ActionButtons>
            {item.active ? (
              <>
                <Button.Secondary
                  size="sm"
                  onClick={() => startEditingPrize(item)}
                  data-testid="edit-button"
                >
                  <Icon.Edit size="sm" mr="2" />
                  Edit Prize
                </Button.Secondary>
                <Button.Gray size="sm" onClick={onArchive}>
                  <Icon.Archive size="sm" mr="2" />
                  Archive Prize
                </Button.Gray>
              </>
            ) : (
              <>
                <Button.Gray size="sm" onClick={onUnarchive} disabled={!item.validTo < new Date()}>
                  <Icon.Archive size="sm" mr="2" />
                  Unarchive Prize
                </Button.Gray>
                <Button.Warning size="sm" onClick={() => deletePrize(item)}>
                  <Icon.Trashcan size="sm" mr="2" />
                  Delete Prize
                </Button.Warning>
              </>
            )}
          </Item.ActionButtons>
        </RBAC>

        {item.imageUrl && (
          <Item.Image>
            <ImagePopup src={item.imageUrl} />
          </Item.Image>
        )}

        <Item.SummaryList>
          {!!item.errors?.length && (
            <Item.Row>
              <Item.Bold tw="text-red-600">{'Missing:'}</Item.Bold>
              <Item.Text tw="italic">{item.errors.join(', ')}</Item.Text>
            </Item.Row>
          )}

          <Item.Row>
            <Item.Bold>{'Id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/${item.path}`}>{item.id}</ATag>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Status:'}</Item.Bold>
            <Item.Status>
              {item.status?.icon() ?? '-'}
              {item.status.message}
            </Item.Status>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Description:'}</Item.Bold>
            <Item.Text>{item.description ?? '-'}</Item.Text>
          </Item.Row>

          <Item.Row>
            <Item.Bold>{'Category:'}</Item.Bold>
            <Item.Text>{item.category?.label ?? '-'}</Item.Text>
          </Item.Row>

          {!objectIsEmpty(hvntReservations) && (
            <Item.Row>
              <Item.Bold>{'Reservations:'}</Item.Bold>
              <ItemList>
                {Object.entries(hvntReservations).map(([key, value]) => (
                  <Item.Col key={key}>
                    <ItemWrapper tw="flex gap-2">
                      <HvntTag to={`/hvnts?id=${key}`}>{key}</HvntTag> - x{value}
                    </ItemWrapper>
                  </Item.Col>
                ))}
              </ItemList>
            </Item.Row>
          )}

          {item.validTo && (
            <Item.Row>
              <Item.Bold>{'Valid To:'}</Item.Bold>
              <Item.Text>{format(item.validTo, 'LLL do yyyy, HH:mm')}</Item.Text>
            </Item.Row>
          )}

          {item.marketValue && (
            <Item.Row>
              <Item.Bold>{'Value:'}</Item.Bold>
              <Item.Text>{`${item.marketValue?.value} ${item.marketValue?.currency?.label} per item`}</Item.Text>
            </Item.Row>
          )}

          {item.extra && (
            <Item.Row>
              <Item.Bold>{'Extra:'}</Item.Bold>
              <Item.Text>{item.extra}</Item.Text>
            </Item.Row>
          )}

          <Item.Row>
            <Item.Bold>{'Created:'}</Item.Bold>
            <Item.Text>
              {item.createdAt ? format(item.createdAt, 'LLL do yyyy, HH:mm') : '-'}
            </Item.Text>
          </Item.Row>
          {item.archivedAt && (
            <Item.Row>
              <Item.Bold>{'Archived:'}</Item.Bold>
              <Item.Text>{format(item.archivedAt, 'LLL do yyyy, HH:mm')}</Item.Text>
            </Item.Row>
          )}
        </Item.SummaryList>
      </Item.Summary>
    </Item.Container>
  )
}

const ItemWrapper = tw(Item.Text)`truncate block`
const ItemList = tw.div`flex flex-col flex-wrap w-full`
