import { featureCollection, lineString, point } from '@turf/helpers'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { HvntFormTab } from '../enums'
import { randomIntFromInterval } from '../utility/helperFunctions'
import { useHvnt } from './HvntContext'
import { useMap } from './MapContext'

export const HvntRouteContext = createContext()

export const HvntRouteProvider = ({ children }) => {
  const { activeCheckpoints, adding, customCheckpoints, setActiveFormTab } = useHvnt()
  const { setValue, watch } = useFormContext()
  const { focusOnFeatures } = useMap()
  const { area = {}, numberOfCheckpoints } = watch()
  const [activeRoute, setActiveRoute] = useState()
  const [editingRoute, setEditingRoute] = useState(false)
  const [hoveredItem, setHoveredItem] = useState()
  const [innerPoints, setInnerPoints] = useState()
  const [routesData, setRoutesData] = useState()
  const [s2CellData, setS2CellData] = useState()

  // Filters out checkpoints based on tags
  const eligibleCheckpoints = useMemo(() => {
    let filteredRegular = area.tags?.useRegular ? activeCheckpoints.features : []
    let filteredCustom = []

    if (area.tags?.regular?.length) {
      filteredRegular = activeCheckpoints.features?.filter(({ properties }) =>
        properties.tags?.some((tag) => area.tags?.regular?.some((ref) => ref.value === tag.value))
      )
    }

    if (area.tags?.custom?.length) {
      filteredCustom = customCheckpoints.features?.filter(({ properties }) =>
        properties.customTags?.some((tag) =>
          area.tags?.custom?.some((ref) => ref.value === tag.value)
        )
      )
    }

    return featureCollection(filteredRegular.concat(filteredCustom))
  }, [
    area.tags?.useRegular,
    area.tags?.regular,
    area.tags?.custom,
    activeCheckpoints,
    customCheckpoints.features
  ])

  // Gets random route from starting checkpoint item
  const buildRoute = useCallback((item) => {
    const randomRoute = item.routes[randomIntFromInterval(0, item.routes.length - 1)]

    // Get the line data
    let lineData
    if (randomRoute?.route?.length > 1) {
      lineData = lineString(
        randomRoute.route.map((point) => point.coordinates),
        { name: 'lineString' }
      )
    }

    // Get all checkpoints as a feature collection
    const routeData = featureCollection(
      randomRoute?.route?.map((stop, i) =>
        point([stop.centerPoint.longitude, stop.centerPoint.latitude], {
          ...stop,
          index: i + 1
        })
      ) || [point([item.start.centerPoint.longitude, item.start.centerPoint.latitude], item.start)]
    )

    // Update active route
    setActiveRoute({
      distance: Math.round(randomRoute?.totalDistance) ?? 0,
      lineData,
      routeData,
      start: item.start
    })
  }, [])

  const resetRoutes = useCallback(() => {
    setActiveRoute()
    setRoutesData()
  }, [])

  // Adds centerpoint of hvnt circle
  const addHvntCircle = useCallback(
    ({ centerPoint }) => {
      setActiveFormTab(HvntFormTab.AREA)
      setValue('area.centerPoint', centerPoint)
      setValue('area.mapUrl') // reset this
    },
    [setActiveFormTab, setValue]
  )
  // Funky assign to trigger side effect on these first distances
  const { 1: first, 2: second, 3: third, 4: fourth } = area?.distances ?? {}
  // Reset routes data if any area data changes
  useEffect(() => {
    resetRoutes()
  }, [
    area.centerPoint,
    area.customRoute,
    area.easyProportion,
    area.maxAngle,
    area.radius,
    area.tags?.custom,
    area.tags?.regular,
    area.tags?.useCustom,
    area.tags?.useRegular,
    area.distances?.default,
    area.distances?.max,
    area.distances?.min,
    area.distances?.start,
    numberOfCheckpoints,
    first,
    second,
    third,
    fourth,
    resetRoutes
  ])

  // Reset tab on unmount
  useEffect(() => {
    return () => {
      setActiveFormTab(HvntFormTab.DETAILS)
    }
  }, [setActiveFormTab])

  // Zoom on mount
  useEffect(() => {
    if (adding) focusOnFeatures(activeCheckpoints, { padding: 80 })
  }, [activeCheckpoints, adding, focusOnFeatures])

  const defaultValues = useMemo(
    () => ({
      activeRoute,
      addHvntCircle,
      buildRoute,
      editingRoute,
      eligibleCheckpoints,
      hoveredItem,
      innerPoints,
      routesData,
      s2CellData,
      setEditingRoute,
      setHoveredItem,
      setInnerPoints,
      setRoutesData,
      setS2CellData
    }),
    [
      activeRoute,
      addHvntCircle,
      buildRoute,
      editingRoute,
      eligibleCheckpoints,
      hoveredItem,
      innerPoints,
      routesData,
      s2CellData
    ]
  )

  return <HvntRouteContext.Provider value={defaultValues}>{children}</HvntRouteContext.Provider>
}

// Hook
export const useHvntRoute = () => {
  const context = useContext(HvntRouteContext)
  if (context === undefined)
    throw new Error('`useHvntRoute` hook must be used within a `HvntRouteProvider` component')
  return context
}
