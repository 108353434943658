export const handleError = ({ code, message }) => {
  switch (code) {
    case 'auth/user-not-found': {
      return "Couldn't find user"
    }

    case 'auth/wrong-password': {
      return 'Wrong password'
    }

    case 'unauthenticated': {
      return 'Unauthenticated'
    }

    case 'auth/invalid-action-code': {
      return 'Invalid or expired link'
    }

    default: {
      return message
    }
  }
}
