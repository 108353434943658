import React, { useCallback, useEffect, useState } from 'react'
import tw, { styled, css } from 'twin.macro'

export const Checkbox = ({ checked = false, disabled, onCheck, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const _onCheck = useCallback(
    (checked) => {
      !disabled && setIsChecked(!checked)
      onCheck && onCheck(!checked)
    },
    [disabled, onCheck]
  )

  return (
    <CheckboxContainer
      {...props}
      checked={isChecked}
      disabled={disabled}
      onClick={() => _onCheck(isChecked)}
    >
      <StyledCheckbox />
    </CheckboxContainer>
  )
}
const StyledCheckbox = tw.div`rounded border-2`
const CheckboxContainer = styled.div`
  ${tw`flex items-center relative cursor-pointer font-light text-gray-900`}
  ${({ checked, color, disabled, label, labelLeft, size = 'sm' }) => css`
    ${disabled && tw`pointer-events-none text-gray-400`};
    ${label ? (size === 'sm' ? tw`gap-2` : size === 'md' ? tw`gap-2` : tw`gap-3`) : ''}
    ${size === 'sm' ? tw`text-sm` : size === 'md' ? tw`text-base` : tw`text-xl`}
    ${labelLeft
      ? css`
          &::before {
            ${tw`whitespace-nowrap`}
            content: '${label}';
          }
        `
      : css`
          &::after {
            ${tw`whitespace-nowrap`}
            content: '${label}';
          }
        `};
    &:hover > ${StyledCheckbox} {
      ${disabled
        ? ''
        : color === 'red'
        ? tw`border-matrix-500`
        : color === 'blue'
        ? tw`border-sky-600`
        : color === 'green'
        ? tw`border-norway-700`
        : tw`border-gray-700`}
    }
    & > ${StyledCheckbox} {
      transition: background 100ms ease;
      ${size === 'sm' ? tw`w-4 h-4` : size === 'md' ? tw`w-5 h-5` : tw`w-6 h-6`}
      ${color === 'red'
        ? checked
          ? disabled
            ? tw`bg-matrix-400 border-matrix-500 opacity-40`
            : tw`bg-matrix-300 border-matrix-500`
          : disabled
          ? tw`border-matrix-400 opacity-40`
          : tw`border-matrix-500`
        : color === 'green'
        ? checked
          ? disabled
            ? tw`bg-norway-400 border-norway-600 opacity-40`
            : tw`bg-norway-400 border-norway-600`
          : disabled
          ? tw`border-norway-400 opacity-40`
          : tw`border-norway-500`
        : color === 'blue'
        ? checked
          ? disabled
            ? tw`bg-sky-400 border-sky-600 opacity-40`
            : tw`bg-sky-400 border-sky-600`
          : disabled
          ? tw`border-sky-600 opacity-30`
          : tw`bg-sky-100 border-sky-400`
        : checked
        ? disabled
          ? tw`bg-gray-300 border-gray-600 opacity-30`
          : tw`bg-gray-400 border-gray-600`
        : disabled
        ? tw`border-gray-300 opacity-30`
        : tw`border-gray-500`}
    }
  `};
`
