import React from 'react'
import { Controller } from 'react-hook-form'
import tw from 'twin.macro'
import { useFormContext } from 'react-hook-form'
import { Input } from '../../styles/Layout'
import { ImageModal } from '../Modal'
import Icon from '../../styles/Icons'
import config from '../../config'
import { useUser } from '../../contexts/UserContext'

export const ProfilePicture = ({ flagUpload, loading }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext({ mode: 'onChange' })
  const { userBeingEdited } = useUser()

  return (
    <ImageSection>
      <Controller
        control={control}
        name="photoURL"
        render={({ field: { value, onChange } }) => (
          <ImageContainer>
            <Image src={value || config.user.DEFAULT_USER_IMAGE} />
            {userBeingEdited && (
              <ImageModal
                OpenButton={(props) => (
                  <ModalButton {...props}>
                    <Icon.Camera />
                  </ModalButton>
                )}
                flagUpload={flagUpload}
                imageUrl={value}
                loading={loading}
                title="Profile Picture"
                setImageUrl={onChange}
                tw="h-4/5"
              />
            )}
          </ImageContainer>
        )}
      />
      <Input.Error>{errors?.photoURL?.message}</Input.Error>
    </ImageSection>
  )
}
const ImageContainer = tw.div`flex justify-center items-center relative `
const Image = tw.img`max-width[120px] rounded-full shadow-md `
const ModalButton = tw.div`absolute -bottom-2 -right-2 rounded-full bg-gray-400 hover:bg-gray-500 p-2`
const ImageSection = tw.div`relative flex flex-col items-center justify-start height[min-content]`

export default ProfilePicture
