import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { PageLayout } from '../../styles/Layout'
import { CreateEvent } from '../CorporateEvents/CreateEvent'
import { useCorporate } from '../../contexts/CorporateContext'
import { EventList } from '../CorporateEvents/EventList'
import { CreateEventMap } from '../CorporateEvents/Map/EventLocationMap'

const CorporateEventsPage = () => {
  const { adding, eventBeingEdited, expandedItem } = useCorporate()
  const navigate = useNavigate()

  // Update Routes through side effects
  useEffect(() => {
    if (eventBeingEdited) navigate(adding ? 'create' : `${eventBeingEdited.id}`, { replace: true })
    else navigate('', { replace: true })
  }, [adding, eventBeingEdited, navigate])

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Page>
            <ListContainer>
              <EventList />
            </ListContainer>
            <MapContainer>
              <CreateEventMap locations={expandedItem?.locations ?? []} />
            </MapContainer>
          </Page>
        }
      />
      <Route
        path="/*"
        element={
          <Page>
            <CreateEvent event={eventBeingEdited} />
          </Page>
        }
      />
    </Routes>
  )
}

export default CorporateEventsPage

const Page = tw(PageLayout)`flex-col lg:flex-row`
const ListContainer = tw.div`flex flex-col w-full lg:w-1/2 h-full`
const MapContainer = tw.div`flex justify-center overflow-hidden w-full lg:w-1/2 h-full minHeight[50vh] transition-all duration-300`
