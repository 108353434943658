import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import tw from 'twin.macro'
import { Action } from '../../../layout/List'
import Icon from '../../../styles/Icons'
import { Button, Input } from '../../../styles/Layout'
import { validate } from '../../../utility/formValidation'
import { Modal } from '../../Modal'
import { HvntPrizeItem } from './HvntPrizeItem'

export const HvntPrizeModal = ({
  addPrize,
  disabled,
  prize,
  prizes,
  updatePrize,
  OpenButton = Object.assign((props) => (
    <Button.Gray {...props}>
      <Icon.Star mr="2" />
      Open Modal
    </Button.Gray>
  ))
}) => {
  const { watch } = useFormContext()
  const [dateRange, hvntPrizes] = watch(['dateRange', 'prizes'])
  const [selectedPrize, setSelectedPrize] = useState()
  const [reservedAmount, setReservedAmount] = useState()
  const [error, setError] = useState(
    prize && prize.reservedAmount && validate.hvnt.prize({ ...prize, dateRange })
  )
  const availablePrizes = useMemo(
    () =>
      prizes?.filter(({ available, errors, validTo }) => {
        if (available <= 0) return false
        if (errors?.length) return false
        if (validTo && validTo < new Date()) return false
        return true
      }),
    [prizes]
  )

  // Validate prizes
  useEffect(() => {
    if (!reservedAmount || !selectedPrize) return setError('')

    setError(
      validate.hvnt.prize({
        ...selectedPrize,
        dateRange,
        previousPrize: prize,
        prizes: hvntPrizes,
        reservedAmount
      })
    )
  }, [dateRange, hvntPrizes, reservedAmount, prize, prizes, selectedPrize])

  const onCancel = useCallback(() => {
    setSelectedPrize()
    setReservedAmount()
  }, [])

  const onSubmit = useCallback(() => {
    if (prize) updatePrize({ ...selectedPrize, reservedAmount: Number(reservedAmount) })
    else addPrize({ ...selectedPrize, reservedAmount: Number(reservedAmount) })
    onCancel()
  }, [addPrize, onCancel, prize, reservedAmount, selectedPrize, updatePrize])

  const RenderPrizes = () => (
    <Container>
      <Action.Header>
        <Button.Filter size="sm" as={Link} to="/prizes" target="_blank" rel="noopener noreferrer">
          <Icon.ExternalLink size="sm" mr="2" /> Prize List
        </Button.Filter>
      </Action.Header>
      {availablePrizes.length ? (
        <List>
          {availablePrizes.map((prize, i) => (
            <HvntPrizeItem
              key={i}
              prize={prize}
              onSelect={() => setSelectedPrize((p) => (p?.id !== prize.id ? prize : undefined))}
              selected={selectedPrize?.path === prize.path}
            />
          ))}
        </List>
      ) : (
        <Empty>{'No prizes available for reservation'}</Empty>
      )}

      <Footer>
        <Input.Label required tw="text-gray-100">
          Reserve how many?
        </Input.Label>
        <Input.Default
          id="amount"
          placeholder="Amount"
          size="sm"
          tw="text-sm w-20"
          type="number"
          defaultValue={reservedAmount}
          disabled={!prizes.length}
          onInput={({ currentTarget }) => setReservedAmount(currentTarget.value)}
        />

        <Input.Error tw="text-lg mt-2">{error}</Input.Error>
      </Footer>
    </Container>
  )

  return (
    <Modal
      OpenButton={(props) => !disabled && <OpenButton {...props} />}
      title="Reserve prizes"
      disabled={disabled}
      onCancel={onCancel}
      onOpen={() => {
        if (!prize) return
        setSelectedPrize(prize)
        setReservedAmount(prize.reservedAmount)
      }}
      SubmitButton={(props) => (
        <Button.Submit {...props} disabled={error || !reservedAmount || !selectedPrize}>
          <Icon.Check mr="2" />
          Save Prize
        </Button.Submit>
      )}
      onSubmit={onSubmit}
      body={RenderPrizes}
      tw="h-full lg:h-4/5"
    />
  )
}

const Container = tw.div`flex flex-col w-full h-full`
const List = tw.div`flex flex-1 flex-col py-6 px-12 gap-4`
const Footer = tw.div`sticky bottom-0 flex flex-col items-center justify-center p-2 bg-bismark-600`
const Empty = tw.div`flex justify-center items-center w-full h-full text-lg`
