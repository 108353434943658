import React from 'react'
import tw from 'twin.macro'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Section } from '../Hvnt/FormSection'
import { Button, Input } from '../../styles/Layout'
import DatePicker from '../DatePicker'
import { SEASON_TYPE_OPTIONS } from '../../utility/labeledOptions'
import { useSeason } from '../../contexts/SeasonsContext'
import Dropdown from '../Dropdown'
import { validate } from '../../utility/formValidation'
import Icon from '../../styles/Icons'
import { SeasonPrize } from './SeasonPrize'

export const SeasonForm = ({ locked }) => {
  const { validateForm } = useSeason()
  const {
    control,
    formState: { errors },
    register
  } = useFormContext()
  const { append, fields, remove, swap } = useFieldArray({ control, name: 'prizes' })

  return (
    <Form>
      <Section label="Season Type" locked={locked} field="type" error={errors.type} required>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Dropdown.Select
              {...field}
              isDisabled={locked}
              options={SEASON_TYPE_OPTIONS}
              placeholder="Choose or create category"
              tw="w-full sm:w-1/2"
            />
          )}
        />
        <Input.Error>{errors.type?.message}</Input.Error>
      </Section>

      <Section label="Season Name" field="name" error={errors.name} required>
        <Input.Default
          {...register('name', { validate: validateForm ? validate.name : null })}
          placeholder="Name"
        />
        <Input.Error>{errors.name?.message}</Input.Error>
      </Section>

      <Section
        label="Season Duration"
        locked={locked}
        field="dateRange"
        error={errors.dateRange}
        required
      >
        <Controller
          control={control}
          name="dateRange"
          rules={{ validate: validateForm ? validate.season.dateRange : null }}
          render={({ field: { value, onChange }, fieldState }) => (
            <DatePicker
              error={fieldState.error}
              onChange={onChange}
              startDisabled={locked}
              shouldValidate={validateForm}
              value={value}
            />
          )}
        />
        <Input.Error>{errors.dateRange?.message}</Input.Error>
      </Section>

      <Section
        label="Items"
        locked={locked}
        field={['items.common', 'items.rare']}
        error={errors.items}
        required
        tw="gap-3"
      >
        <Col>
          <Input.Label tw="text-lg">Common Item</Input.Label>
          <ItemContainer>
            <Col>
              <Input.Label required>Asset path</Input.Label>
              <Input.Default
                {...register('items.common.assetPath', {
                  validate: validateForm ? validate.season.urlPath : null
                })}
                disabled={locked}
                placeholder="Path to asset"
              />
              <Input.Error>{errors.items?.common?.assetPath?.message}</Input.Error>
            </Col>
            <Col>
              <Input.Label required>AR item asset path</Input.Label>
              <Input.Default
                {...register('items.common.arItemAssetPath', {
                  validate: validateForm ? validate.season.urlPath : null
                })}
                disabled={locked}
                placeholder="Path to AR item asset"
              />
              <Input.Error>{errors.items?.common?.arItemAssetPath?.message}</Input.Error>
            </Col>
            <Row tw="flex-wrap">
              <Col>
                <Input.Label required>Value</Input.Label>
                <Input.Default
                  {...register('items.common.value', {
                    validate: validateForm ? validate.positiveInteger : null,
                    valueAsNumber: true
                  })}
                  disabled={locked}
                  type="number"
                  placeholder="Value"
                />
                <Input.Error>{errors.items?.common?.value?.message}</Input.Error>
              </Col>
              <Col>
                <Input.Label required>Weight</Input.Label>
                <Input.Default
                  {...register('items.common.weight', {
                    validate: validateForm ? validate.season.weight : null,
                    valueAsNumber: true
                  })}
                  disabled={locked}
                  type="number"
                  placeholder="Between 0 and 1.0"
                />
                <Input.Error>{errors.items?.common?.weight?.message}</Input.Error>
              </Col>
            </Row>
          </ItemContainer>
        </Col>
        <Col>
          <Input.Label tw="text-lg">Rare Item</Input.Label>
          <ItemContainer>
            <Col>
              <Input.Label required>Asset path</Input.Label>
              <Input.Default
                {...register('items.rare.assetPath', {
                  validate: validateForm ? validate.season.urlPath : null
                })}
                disabled={locked}
                placeholder="Path to asset"
              />
              <Input.Error>{errors.items?.rare?.assetPath?.message}</Input.Error>
            </Col>
            <Col>
              <Input.Label required>AR item asset path</Input.Label>
              <Input.Default
                {...register('items.rare.arItemAssetPath', {
                  validate: validateForm ? validate.season.urlPath : null
                })}
                disabled={locked}
                placeholder="Path to AR item asset"
              />
              <Input.Error>{errors.items?.rare?.arItemAssetPath?.message}</Input.Error>
            </Col>
            <Row tw="flex-wrap">
              <Col>
                <Input.Label required>Value</Input.Label>
                <Input.Default
                  {...register('items.rare.value', {
                    validate: validateForm ? validate.positiveInteger : null,
                    valueAsNumber: true
                  })}
                  disabled={locked}
                  type="number"
                  placeholder="Value"
                />
                <Input.Error>{errors.items?.rare?.value?.message}</Input.Error>
              </Col>
              <Col>
                <Input.Label required>Weight</Input.Label>
                <Input.Default
                  {...register('items.rare.weight', {
                    validate: validateForm ? validate.season.weight : null,
                    valueAsNumber: true
                  })}
                  disabled={locked}
                  type="number"
                  placeholder="Between 0 and 1.0"
                />
                <Input.Error>{errors.items?.rare?.weight?.message}</Input.Error>
              </Col>
            </Row>
          </ItemContainer>
        </Col>
      </Section>

      <Section
        label="Item Spawning"
        locked={locked}
        field={['itemsMinSpacing', 'itemsPerS2Cell', 'maxNumberOfItems']}
        error={errors.itemsMinSpacing || errors.itemsPerS2Cell || errors.maxNumberOfItems}
        required
        tw="gap-2"
      >
        <Row tw="flex-wrap">
          <Col>
            <Input.Label>Max total items</Input.Label>
            <Input.Default
              {...register('maxNumberOfItems', {
                validate: validateForm ? validate.season.numberOfItems : null,
                valueAsNumber: true
              })}
              disabled={locked}
              placeholder="1 - 500"
              type="number"
            />
            <Input.Error>{errors.maxNumberOfItems?.message}</Input.Error>
          </Col>
          <Col>
            <Input.Label>Max items per S2 cell</Input.Label>
            <Input.Default
              {...register('itemsPerS2Cell', {
                validate: validateForm ? validate.season.numberOfItems : null,
                valueAsNumber: true
              })}
              disabled={locked}
              placeholder="1 - 500"
              type="number"
            />
            <Input.Error>{errors.itemsPerS2Cell?.message}</Input.Error>
          </Col>
          <Col>
            <Input.Label>Item spacing (m)</Input.Label>
            <Input.Default
              {...register('itemsMinSpacing', {
                validate: validateForm ? validate.season.minSpacing : null,
                valueAsNumber: true
              })}
              disabled={locked}
              placeholder="1 - 200 m"
              type="number"
            />
            <Input.Error>{errors.itemsMinSpacing?.message}</Input.Error>
          </Col>
        </Row>
      </Section>

      <Section
        label="UI Elements"
        locked={locked}
        field="uiElements.counter"
        error={errors.uiElements}
        required
        tw="gap-3"
      >
        <Col>
          <Input.Label tw="text-lg">Counter</Input.Label>
          <ItemContainer>
            <Col>
              <Input.Label required>Asset path</Input.Label>
              <Input.Default
                {...register('uiElements.counter.assetPath', {
                  validate: validateForm ? validate.season.urlPath : null
                })}
                disabled={locked}
                placeholder="Path to asset"
              />
            </Col>

            <Input.Error>{errors.uiElements?.counter?.assetPath?.message}</Input.Error>
          </ItemContainer>
        </Col>
      </Section>

      <Section
        label="Competitive Prizes"
        locked={locked}
        field="prizes"
        error={errors.prizes}
        tw="gap-2"
      >
        <PrizesContainer>
          {!!fields?.length &&
            fields.map((field, i) => (
              <SeasonPrize
                key={field.id}
                index={i}
                locked={locked}
                onMoveDown={() => swap(i, i + 1)}
                onMoveUp={() => swap(i, i - 1)}
                onRemove={() => remove(i)}
              />
            ))}
        </PrizesContainer>
        {!locked && (
          <Button.Primary tw="self-start" onClick={() => append({ name: '', reservedAmount: 1 })}>
            <Icon.Plus mr="2" />
            Add Prize
          </Button.Primary>
        )}
      </Section>
    </Form>
  )
}

const Form = tw.div`relative flex flex-1 flex-col w-full overflow-scroll`
const Col = tw.div`flex flex-col flex-1`
const Row = tw.div`flex flex-1 gap-2`
const ItemContainer = tw.div`flex flex-col w-full px-3 py-4 gap-2 rounded-md bg-bismark-200 shadow-inner`
const PrizesContainer = tw.div`flex flex-col gap-3`
