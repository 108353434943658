import React, { Fragment } from 'react'
import { useUser } from '../../contexts/UserContext'
import { Navigate } from 'react-router-dom'
import { userIsPermitted } from '../../utility/rbac'

const RBAC = ({ allow = [], children, redirect }) => {
  const { user } = useUser()

  return user ? (
    userIsPermitted(allow, user) ? (
      children
    ) : redirect ? (
      <Navigate to={redirect} />
    ) : (
      <Fragment />
    )
  ) : (
    <Navigate to="/login" />
  )
}

export default RBAC
