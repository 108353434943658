import React from 'react'
import { Modal } from '../../Modal'
import tw from 'twin.macro'
import { Button, Input } from '../../../styles/Layout'
import Icon from '../../../styles/Icons'
import { Switch } from '../../Layout/Switch'
import { Controller, useForm } from 'react-hook-form'
import Dropdown from '../../Dropdown'
import { ROLE_OPTIONS } from '../../../utility/labeledOptions'
import { getOption } from '../../../utility/helperFunctions'
import { Role } from '../../../enums'

export const ClaimsModal = ({ authData, onSave }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      isAdmin: authData.isAdmin,
      roles: authData.roles.map((role) => getOption(role, ROLE_OPTIONS))
    }
  })

  // Format data
  const onSubmit = async ({ isAdmin, roles }) => {
    onSave({
      ...(isAdmin && { isAdmin }),
      ...(roles.length && { roles: roles.map((role) => role.value) })
    })
  }

  return (
    <Modal
      body={() => (
        <Container>
          <Col>
            <Input.Label>Add Roles</Input.Label>
            <Controller
              control={control}
              name="roles"
              render={({ field }) => (
                <Dropdown.Select
                  {...field}
                  options={ROLE_OPTIONS}
                  isMulti={true}
                  isOptionDisabled={({ value }) => [Role.VISITOR].includes(value)}
                />
              )}
            />
          </Col>

          <Controller
            control={control}
            name="isAdmin"
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                defaultChecked={value}
                color="red"
                label="Admin Access"
                onToggle={onChange}
              />
            )}
          />
        </Container>
      )}
      title="Assign Role"
      tw="w-11/12 md:w-1/2 lg:w-2/5"
      onSubmit={handleSubmit(onSubmit)}
      OpenButton={(props) => (
        <Button.Gray size="sm" {...props}>
          <Icon.Lock size="sm" mr="2" />
          Assign Role
        </Button.Gray>
      )}
    />
  )
}

const Container = tw.div`flex flex-col gap-12 px-24 py-12 w-full justify-center`
const Col = tw.div`flex flex-col`
