import React, { forwardRef } from 'react'
import tw, { css, styled, theme } from 'twin.macro'

const RangeSlider = (props, ref) => {
  const { color, disabled, hideSteps, max, min, onChange, step, tickEvery, value } = props
  const sliderProps = { hideSteps, max, min, step, tickEvery }

  return (
    <Container {...props} hide={hideSteps}>
      <Slider {...sliderProps}>
        <Track
          {...sliderProps}
          color={color}
          disabled={disabled}
          onChange={onChange}
          value={value}
          ref={ref}
        />
      </Slider>
    </Container>
  )
}

export default forwardRef(RangeSlider)

const Container = styled.div`
  ${tw`px-0.5 pb-3`}
  ${({ hide }) => hide && tw`pb-0`}
`
const Slider = styled.div`
  ${({ hideSteps, max, min, size = '0.375rem', step, tickEvery = 1 }) => css`
    ${tw`relative  font-sans font-light text-gray-700 `}
    ${!hideSteps &&
    css`
       {
        background: linear-gradient(to right, ${theme`colors.gray.300`} 1px, transparent 1px)
          repeat-x;
        background-size: calc((100% - ${size}) / ((${max} - ${min}) / ${step}) * ${tickEvery})
          calc(${size});
        background-position-x: calc(${size} / 2 - 1px / 2);
        background-position-y: calc(${size} * 3);
        &:before {
          ${tw`absolute -left-0.5 top-6 font-size[x-small]`};
          content: '${min}';
        }
        &:after {
          ${tw`absolute -right-0.5 top-6 font-size[x-small]`};
          content: '${max}';
        }
      }
    `}
  `}
`
const style = {
  track: tw`outline-none appearance-none h-2 w-full rounded-sm ring-2 ring-gray-300 from-gray-100 to-gray-200 bg-gradient-to-b`,
  thumb: ({ color }) => css`
    ${tw`opacity-100 appearance-none h-4 w-1.5 rounded-sm ring-2`}
    ${color === 'red'
      ? tw`bg-matrix-300 ring-matrix-400`
      : color === 'blue'
      ? tw`bg-sky-400 ring-sky-500`
      : color === 'green'
      ? tw`bg-norway-400 ring-norway-600`
      : tw`bg-gray-400 ring-gray-600`}
  `
}
const Track = styled.input.attrs(({ color = 'black', disabled, max, min, step, ...props }) => ({
  color,
  disabled,
  type: 'range',
  max,
  min,
  size: '6px',
  step,
  ...props
}))`
  ${style.track}
  ${({ disabled }) =>
    disabled
      ? css`
          &::-moz-range-thumb {
            ${style.thumb}
            ${tw`bg-gray-200 hover:bg-gray-200 ring-gray-300`}
          }
          &::-webkit-slider-thumb {
            ${style.thumb}
            ${tw`bg-gray-200 hover:bg-gray-200 ring-gray-300`}
          }
        `
      : css`
          ${tw`cursor-pointer`}
          &:hover {
            &::-webkit-slider-thumb {
              ${tw`transform[scale(1.1)]`}
            }
          }
          &::-webkit-slider-thumb {
            ${style.thumb}
          }
          &::-moz-range-thumb {
            ${style.thumb}
          }
        `}
`
