import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import tw from 'twin.macro'
import { Map, NavigationControl, Source, Layer } from 'react-map-gl'
import { Loader } from '../../Loader/Loader'
import config from '../../../config'
import { MapControl } from '../../Map/MapControl'
import { mapLayers } from '../../Map/MapLayers'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useMapControl } from '../../../hooks/useMapControl'
import { useMap } from '../../../contexts/MapContext'
import { capitalize } from '../../../utility/helperFunctions'
import { useDebounce } from 'react-use'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { AddCountries } from './AddCountries'

const START_COORDS = { latitude: 49.342644, longitude: 13.078419 }

export const CreateSeasonMap = ({ locked }) => {
  const mapRef = useRef(null)
  const { isMounted } = useIsMounted()
  const { containerRef, zoomToBbox, zoomToPoint } = useMapControl(mapRef)
  const { focusedMarker, focusedBbox, focusOnPoint } = useMap()

  const [busy, setBusy] = useState(true)
  const [cursor, setCursor] = useState('auto')
  const [hoveredCountry, setHoveredCountry] = useState()
  const [viewstate, setViewstate] = useState({
    longitude: START_COORDS.longitude,
    latitude: START_COORDS.latitude,
    maxZoom: 18,
    zoom: 2
  })
  const { control, clearErrors, watch } = useFormContext()
  const {
    append,
    fields: countries,
    remove
  } = useFieldArray({
    control,
    name: 'countries',
    shouldUnregister: false
  })

  const { playableWorldWide } = watch()
  // Viewport change
  const onMoveMap = useCallback(({ viewState }) => {
    setViewstate((v) => ({ ...v, ...viewState }))
  }, [])

  const onHover = useCallback(({ features = [] }) => {
    const { properties } = features[0] ?? {}
    if (!properties) return
    setCursor('pointer')
    setHoveredCountry({
      longitude: properties.LON,
      latitude: properties.LAT,
      name: properties.NAME,
      id: properties.ISO2
    })
  }, [])

  const onMouseLeave = useCallback(() => {
    setCursor('auto')
    setHoveredCountry()
  }, [])

  const onClickMap = useCallback(
    ({ features }) => {
      const { properties } = features?.[0] ?? {}
      if (!properties) return
      const countryId = properties.ISO2.toLowerCase()
      const name = capitalize(properties.NAME.toLowerCase())

      // Remove already selected country
      if (countries.some((c) => c.countryId === countryId)) {
        remove(countries.findIndex((c) => c.countryId === countryId))
      } else {
        append({ countryId, name })
        clearErrors('countries') // Clear validation errors
      }
    },
    [append, clearErrors, countries, remove]
  )

  const hoveredCountryISO2 = useMemo(() => hoveredCountry?.id ?? '', [hoveredCountry])
  const filterHover = useMemo(() => ['in', 'ISO2', hoveredCountryISO2], [hoveredCountryISO2])

  // Color selected countries
  const colorCurrent = useMemo(() => {
    if (busy) return {}
    if (!countries?.length) return { hover: 'rgba(132,172,220,0.5)' }
    const matchBorder = ['match', ['get', 'ISO2']]
    const matchCountry = ['match', ['get', 'ISO2']]
    const matchHover = ['match', ['get', 'ISO2']]

    for (const country of countries) {
      matchCountry.push(country.countryId.toUpperCase(), 'rgba(255,196,18,0.4)')
      matchBorder.push(country.countryId.toUpperCase(), 'rgba(249,194,99,0.7)')
      matchHover.push(country.countryId.toUpperCase(), 'rgba(236,169,72,0.3)')
    }
    matchCountry.push('rgba(0,0,0,0)')
    matchBorder.push('rgba(0,0,0,0)')
    matchHover.push('rgba(132,172,220,0.5)') // blue

    return {
      border: matchBorder,
      countries: matchCountry,
      hover: matchHover
    }
  }, [busy, countries])

  useEffect(() => {
    if (!playableWorldWide) return
    focusOnPoint({ centerPoint: START_COORDS }, { maxZoom: 2 })
  }, [focusOnPoint, playableWorldWide])

  // Zooms to focused marker or area
  useDebounce(
    () => {
      if (!isMounted() || busy) return
      if (focusedMarker) return zoomToPoint(focusedMarker)
      if (focusedBbox) return zoomToBbox(focusedBbox)
    },
    200,
    [focusedBbox, focusedMarker, busy, isMounted, zoomToBbox, zoomToPoint]
  )

  return (
    <MapContainer ref={containerRef}>
      <Overlay>
        <AddCountries locked={busy || locked} remove={remove} />
      </Overlay>
      <Map
        {...viewstate}
        cursor={cursor}
        id="createSeasonMap"
        interactiveLayerIds={locked || playableWorldWide ? null : [mapLayers.country.main().id]}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_SECRET}
        mapStyle={config.mapbox.NORTH_STAR}
        onClick={onClickMap}
        onLoad={() => setBusy(false)}
        onMove={onMoveMap}
        onMouseMove={onHover}
        onMouseLeave={onMouseLeave}
        ref={mapRef}
        style={{ width: '100vw', height: '100%' }}
      >
        <Loader loading={busy} text={busy ? 'Loading Map' : ''} />

        {!playableWorldWide && (
          <Source type="vector" url="mapbox://dennit.0haz83rx" id="countries">
            <Layer {...mapLayers.country.main(colorCurrent.countries)} />
            <Layer {...mapLayers.country.border(colorCurrent.border)} />
            <Layer {...mapLayers.country.highlight(colorCurrent.hover)} filter={filterHover} />
          </Source>
        )}

        <MapControl>
          <NavigationControl tw="bottom[-10px]" />
        </MapControl>
      </Map>
    </MapContainer>
  )
}

const MapContainer = tw.div`flex relative overflow-hidden justify-center font-light`
const Overlay = tw.div`absolute left-2 top-2 max-height[80%] flex z-10`
