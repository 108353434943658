import tw from 'twin.macro'

export const Tab = {
  Col: tw.div`flex flex-col`,
  Container: tw.div`relative flex flex-col p-4 h-full`,
  Header: tw.div`font-bold text-xl mb-2 select-none`,
  PaginatorHeader: tw.div`flex items-end justify-between font-bold text-xl mb-2 z-40`,
  Section: tw.div`flex flex-col`,
  SplitSection: tw.div`grid grid-cols-1 sm:grid-cols-2 gap-3`
}
