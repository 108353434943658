import React from 'react'
import { isToday } from 'date-fns'
import format from 'date-fns/format'
import tw, { css, styled } from 'twin.macro'
import config from '../../config'
import { relativeDateUpToDays } from '../../utility/helperFunctions'
import Icon from '../../styles/Icons'
import { NotificationStatusTypes } from '../../enums'

export const ChatMessage = ({ chatMessage }) => {
  const { appVersion = '', message, notificationStatus, seenAt, sentAt, sentByUser } = chatMessage

  const extra = sentByUser
    ? MESSAGE_EXTRAS.VERSION({ appVersion })
    : seenAt
    ? MESSAGE_EXTRAS.READ({ seenAt })
    : sentAt
    ? MESSAGE_EXTRAS.SENT()
    : ''

  const notificationExtra =
    notificationStatus == NotificationStatusTypes.SUCCESS ? (
      <Notification tw="text-green-600" />
    ) : notificationStatus == NotificationStatusTypes.FAILED ? (
      <Notification tw="text-terracotta" />
    ) : (
      ''
    )

  return (
    <Container incoming={sentByUser}>
      <MessageRow>
        <Image>
          <img
            src={sentByUser ? config.user.DEFAULT_USER_IMAGE : config.support.DEFAULT_SUPPORT_IMAGE}
          />
        </Image>
        <div tw="relative flex flex-col">
          <Timestamp>{relativeDateUpToDays(sentAt.toDate(), 1)}</Timestamp>
          <MessageWrapper>
            {notificationExtra}
            <Message>{message}</Message>
          </MessageWrapper>
          {extra}
        </div>
      </MessageRow>
    </Container>
  )
}

const Container = styled.div`
  ${tw`flex flex-col w-full`}
  ${({ incoming }) => css`
    ${incoming ? tw`self-start items-start` : tw`self-end items-end`};
    & > ${MessageRow} > * > * > ${Message} {
      ${incoming
        ? tw`bg-gradient-to-r from-norway-300 to-norway-400 rounded-bl-none text-left`
        : tw`bg-gradient-to-l from-gold-400 to-gold-500 rounded-br-none text-right`}
    }

    & > ${MessageRow} > * > ${MessageWrapper} {
      ${incoming ? tw` justify-start` : tw`justify-end`}
    }

    & > ${MessageRow} > * > ${Timestamp} {
      ${incoming ? tw`self-end` : tw`self-start`}
    }
    & > ${MessageRow} {
      ${incoming ? tw`flex-row` : tw`flex-row-reverse`}
    }
  `}
`
const Timestamp = tw.div`text-gray-600 mx-1 text-xs whitespace-nowrap select-none`
const Extra = tw.div`absolute -bottom-5 mx-1 flex gap-1 text-xs italic text-gray-400 whitespace-nowrap select-none`
const MessageRow = tw.div`flex gap-2 items-end my-4 w-5/6`
const MessageWrapper = tw.div`flex font-light text-sm md:text-base lg:text-lg items-center`
const Message = tw.div`rounded-xl shadow-md py-2.5 px-4 text-gray-900 width[fit-content] whitespace-pre-wrap`
const MESSAGE_EXTRAS = {
  READ: Object.assign(({ seenAt }) => (
    <Extra tw="right-0 text-green-600 before:content['✓']">{`Read ${
      isToday(seenAt.toDate())
        ? format(seenAt.toDate(), 'HH:mm')
        : relativeDateUpToDays(seenAt.toDate(), 1)
    }`}</Extra>
  )),
  SENT: Object.assign(() => <Extra tw="right-0">Sent</Extra>),
  VERSION: Object.assign(({ appVersion }) => (
    <Extra tw="left-0">{appVersion ? `Version ${appVersion}` : ''}</Extra>
  ))
}
const Image = tw.div`flex-none w-8 h-8 sm:w-10 sm:h-10 rounded-lg shadow-md overflow-hidden select-none`
const NotificationWrapper = tw.div`absolute top-1.5 -right-3 w-4 h-4 text-gray-600`
const Notification = Object.assign((props) => (
  <NotificationWrapper {...props}>
    <Icon.BellSolid size="xs" />
  </NotificationWrapper>
))
