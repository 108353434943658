import React, { useCallback, useState } from 'react'
import tw, { theme } from 'twin.macro'
import { useCheckpoint } from '../../../contexts/CheckpointContext'
import { useClue } from '../../../contexts/ClueContext'
import { Action, List } from '../../../layout/List'
import Icon from '../../../styles/Icons'
import { Button, Card } from '../../../styles/Layout'
import { Alerts, Toast } from '../../../utility/alerts'
import { Loader } from '../../Loader/Loader'
import { CandidateClueItems } from './CandidateClueItem'

export const CandidateClueList = ({ clues = [], loading }) => {
  const { reviewing } = useCheckpoint()
  const { acceptClue, rejectClue } = useClue()
  const [_loading, setLoading] = useState(false)

  // Accept all remaining clues
  const onAcceptAll = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all(clues.map((clue) => acceptClue(clue)))
      Toast.fire({
        title: 'Clues Accepted!',
        icon: 'success'
      })
    } catch (err) {
      Alerts.Clue.SAVE_FAILED(err)
      setLoading(false)
    }
  }, [acceptClue, clues])

  // Reject all remaining clues
  const onRejectAll = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all(clues.map((clue) => rejectClue(clue)))
      Toast.fire({
        title: 'Clues Rejected!',
        icon: 'success'
      })
    } catch (err) {
      Alerts.Clue.SAVE_FAILED(err)
      setLoading(false)
    }
  }, [clues, rejectClue])

  return (
    <Card.Container
      header={`${`${clues.length ? clues.length : ''} Generated Clues`}`}
      color={theme`colors.bismark.300`}
      reviewing={reviewing}
      size="sm"
    >
      <Loader loading={loading || _loading} />
      <Action.Header>
        <Action.Right>
          <Button.White size="sm" disabled={loading || !clues.length} ring onClick={onAcceptAll}>
            <Icon.ThumbsUpSolid size="sm" mr="2" color={theme`colors.green`} />
            Accept All
          </Button.White>
          <Button.White size="sm" disabled={loading || !clues.length} ring onClick={onRejectAll}>
            <Icon.ThumbsDownSolid size="sm" mr="2" color={theme`colors.red`} />
            Reject All
          </Button.White>
          <Action.More color={theme`colors.bismark.200`} />
        </Action.Right>
      </Action.Header>

      <ClueList>
        {clues.length ? (
          <CandidateClueItems items={clues} />
        ) : (
          <List.Empty>{'You have no clues...'}</List.Empty>
        )}
      </ClueList>
    </Card.Container>
  )
}

const ClueList = tw(List.Container)`gap-3 p-4`
