import React from 'react'
import tw, { css, styled, theme } from 'twin.macro'

export const RoleBadge = ({ admin = false, children }) => {
  return (
    <StyledBadge admin={admin}>
      <Ring tw="h-14 w-14" />
      <Ring tw="h-12 w-12 border-4" />
      {admin && <Ring tw="w-9 h-9" />}
      <Text>{children}</Text>
    </StyledBadge>
  )
}

const Ring = tw.div`absolute rounded-full h-12 w-12 border-2`
const Text = tw.div`absolute text-center z-10 bg-bismark-100 text-lg letterSpacing[0.18em] font-bold leading-5 shadow-inner py-0.5 px-2 rounded-sm`
const StyledBadge = styled.div`
  ${tw`relative flex items-center justify-center rounded-full w-16 h-16 select-none`}
  ${({ admin }) => css`
    & > * {
      border-color: ${admin ? theme`colors.blue.700` : theme`colors.matrix`};
      color: ${admin ? theme`colors.blue.700` : theme`colors.matrix`};
    }
    & > ${Text} {
      background-color: ${admin ? theme`colors.bismark.100` : theme`colors.matrix.50`};
    }
  `}
`
