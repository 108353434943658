import React, { useState } from 'react'
import tw, { styled, css } from 'twin.macro'
import { format } from 'date-fns'
import Icon from '../../../../styles/Icons'
import config from '../../../../config'
import { ATag, UserTag } from '../../../../styles/Link'
import { Button } from '../../../../styles/Layout'
import { Item } from '../../../../layout/List'

export const Items = ({ items, onReverse, path }) => {
  const [expandedItem, setExpandedItem] = useState()

  const onClickItem = (xpEntry, opened) => {
    console.log({ xpEntry })
    opened ? setExpandedItem(xpEntry) : setExpandedItem()
  }

  return items.map((xpEntry, i) => (
    <XPItem
      key={i}
      expanded={expandedItem?.id === xpEntry?.id}
      index={i}
      item={xpEntry}
      onClick={onClickItem}
      onReverse={onReverse}
      path={path}
    />
  ))
}

const XPItem = ({ expanded, index, item, onClick, onReverse, path }) => {
  const { id, level, metadata, reason, timestamp, type, xp } = item
  const { adjustedBy, comment, ref } = metadata

  return (
    <Item.Container>
      <Item.Header onClick={() => onClick(item, !expanded)} expanded={expanded}>
        <Item.HeaderData colSpan={1}>
          <Item.Center>{index}</Item.Center>
        </Item.HeaderData>
        <Item.HeaderData colSpan={3}>
          {timestamp ? format(timestamp, 'LLL do yyyy, HH:mm') : '-'}
        </Item.HeaderData>
        <Item.HeaderData colSpan={2}>{type}</Item.HeaderData>
        <Item.HeaderData colSpan={3}>{reason}</Item.HeaderData>
        <AmountData colSpan={2} type={type}>
          {xp.amount}
        </AmountData>
        <Item.HeaderData colSpan={2}>{xp.new}</Item.HeaderData>
        <Item.HeaderData colSpan={2}>{level.new ?? '-'}</Item.HeaderData>
      </Item.Header>

      <Item.Summary expanded={expanded} height="auto" tw="flex-col sm:flex-row">
        <Item.SummaryGrid>
          <Item.Col>
            <Item.Bold>{'Id:'}</Item.Bold>
            <ATag href={`${config.firebase.FIRESTORE_URL}/${path}/xp-log/${id}`}>{id}</ATag>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Type:'}</Item.Bold>
            <Item.Text>
              <pre>{type}</pre>
            </Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Reason:'}</Item.Bold>
            <Item.Text>
              <pre>{reason}</pre>
            </Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Amount:'}</Item.Bold>
            <Item.Text>{xp.amount}</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Old XP:'}</Item.Bold>
            <Item.Text>{xp.old}</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'New XP:'}</Item.Bold>
            <Item.Text>{xp.new}</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Old Level:'}</Item.Bold>
            <Item.Text>{level.old}</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'New Level:'}</Item.Bold>
            <Item.Text>{level.new}</Item.Text>
          </Item.Col>
          <Item.Col>
            <Item.Bold>{'Timestamp:'}</Item.Bold>
            <Item.Text>{timestamp ? format(timestamp, 'PP HH:mm:ss') : '-'}</Item.Text>
          </Item.Col>
          {ref && (
            <Item.Col>
              <Item.Bold>{'Reference:'}</Item.Bold>
              <ATag href={`${config.firebase.FIRESTORE_URL}/${ref}`}>original entry</ATag>
            </Item.Col>
          )}
          {adjustedBy && (
            <Item.Col>
              <Item.Bold>{'Adjusted by:'}</Item.Bold>
              <UserTag to={`/users?id=${adjustedBy}`}>{adjustedBy}</UserTag>
            </Item.Col>
          )}
          <Item.Col tw="col-span-2">
            <Item.Bold>{'Comment:'}</Item.Bold>
            <Item.Text>{comment ? `"${comment}"` : '-'}</Item.Text>
          </Item.Col>
        </Item.SummaryGrid>
        <Item.ActionButtons>
          <Button.Secondary size="sm" onClick={() => onReverse(item)}>
            <Icon.Refresh size="sm" mr="1" />
            Reverse
          </Button.Secondary>
        </Item.ActionButtons>
      </Item.Summary>
    </Item.Container>
  )
}

const AmountData = styled(Item.HeaderData)`
  ${tw`font-semibold`}
  ${({ type }) =>
    type === 'DEBIT'
      ? tw`text-red`
      : css`
          ${tw`text-green`}
          &:before {
            content: '+';
          }
        `}
`
