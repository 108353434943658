import React, { useState, useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { intervalToDuration, formatDuration, isDate, startOfHour, addHours, format } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDatePicker from 'react-datepicker'
import { Button, Info, Input } from '../../styles/Layout'
import { Modal } from '../Modal'
import Icon from '../../styles/Icons'

const DatePicker = ({ error, onChange, startDisabled, shouldValidate, title, value = {} }) => {
  const { endDate = null, startDate = null } = value
  const [prevValue, setPrevValue] = useState()

  const duration = useMemo(() => getDuration(startDate, endDate), [startDate, endDate])
  const endDisabled = useMemo(
    () => shouldValidate && startDisabled && value?.endDate && new Date(value.endDate) < new Date(),
    [value, startDisabled, shouldValidate]
  )

  const filterStartTime = (time) => {
    const selectedDate = new Date(time)
    return endDate
      ? selectedDate?.getTime() < endDate?.getTime() &&
          new Date().getTime() < selectedDate?.getTime()
      : new Date().getTime() < selectedDate?.getTime()
  }

  const filterEndTime = (time) => {
    const selectedDate = new Date(time)
    return startDate
      ? startDate?.getTime() < selectedDate?.getTime() &&
          new Date().getTime() < selectedDate?.getTime()
      : new Date().getTime() < selectedDate?.getTime()
  }

  const RenderDates = () => (
    <ModalBody>
      <Calendars>
        <DateWrapper disabled={startDisabled}>
          <Input.Label required>Start Date</Input.Label>
          <ReactDatePicker
            calendarContainer={({ className, children }) => (
              <div className={className} tw="relative">
                <Overlay />
                <div tw="relative">{children}</div>
              </div>
            )}
            dateFormat="LLL do, HH:mm"
            disabled={startDisabled}
            disabledKeyboardNavigation
            endDate={endDate}
            filterTime={filterStartTime}
            fixedHeight
            inline
            minDate={new Date()}
            maxDate={endDate}
            onChange={(date) => {
              if (date && date < new Date())
                onChange({ endDate, startDate: startOfHour(addHours(new Date(), 1)) })
              else
                onChange({ endDate, startDate: endDate ? new Date(Math.min(date, endDate)) : date })
            }}
            popperPlacement="top-end"
            popperModifiers={[
              { name: 'offset', options: { offset: [5, 10] } },
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  tether: false,
                  altAxis: true
                }
              }
            ]}
            selected={startDate}
            selectsStart
            shouldCloseOnSelect={false}
            showTimeSelect
            startDate={startDate}
            tabIndex="-1"
            timeFormat="HH:mm"
            timeIntervals={30}
          />
          <DisplayDate>
            {startDate ? format(new Date(startDate), 'LLL do, HH:mm') : '-'}
          </DisplayDate>
        </DateWrapper>

        <DateWrapper disabled={endDisabled}>
          <Input.Label required>End Date</Input.Label>
          <ReactDatePicker
            calendarContainer={({ className, children }) => (
              <div className={className} tw="relative">
                <Overlay />
                <div tw="relative">{children}</div>
              </div>
            )}
            disabled={endDisabled}
            disabledKeyboardNavigation
            dateFormat="LLL do, HH:mm"
            endDate={endDate}
            filterTime={filterEndTime}
            fixedHeight
            inline
            isClearable={endDate && !endDisabled}
            minDate={startDate ? new Date(startDate) : new Date()}
            onChange={(date) => {
              if (date && date < new Date())
                onChange({ endDate: startOfHour(addHours(new Date(), 1)), startDate })
              else
                onChange({
                  endDate: startDate ? new Date(Math.max(date, startDate)) : date,
                  startDate
                })
            }}
            placeholderText="Select an end date"
            popperPlacement="top-end"
            popperModifiers={[
              { name: 'offset', options: { offset: [5, 10] } },
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  tether: false,
                  altAxis: true
                }
              }
            ]}
            selected={endDate}
            selectsEnd
            shouldCloseOnSelect={false}
            showTimeSelect
            startDate={startDate}
            tabIndex="-1"
            timeIntervals={30}
            timeFormat="HH:mm"
          />
          <DisplayDate>{endDate ? format(new Date(endDate), 'LLL do, HH:mm') : '-'}</DisplayDate>
        </DateWrapper>
      </Calendars>
      <Footer>
        {duration && <Duration>{`That's ${duration}!`}</Duration>}
        {error && <Error>{error?.message}</Error>}
        {(startDate || endDate) && !startDisabled && (
          <ClearDatesButton size="sm" onClick={() => onChange()}>
            <Icon.Trashcan size="sm" mr="2" />
            Clear Dates
          </ClearDatesButton>
        )}
      </Footer>
    </ModalBody>
  )

  return (
    <PreviewContainer>
      <Modal
        OpenButton={(props) => (
          <ModalButtonContainer {...props}>
            <InputContainer>
              <Input.Label>Start Date</Input.Label>
              <DisplayDate disabled={startDisabled}>
                {startDate ? format(new Date(startDate), 'LLL do, HH:mm') : '-'}
              </DisplayDate>
            </InputContainer>
            <InputContainer>
              <Input.Label>End Date</Input.Label>
              <DisplayDate disabled={endDisabled}>
                {endDate ? format(new Date(endDate), 'LLL do, HH:mm') : '-'}
              </DisplayDate>
            </InputContainer>
          </ModalButtonContainer>
        )}
        title={title || 'Set start and end date'}
        SubmitButton={(props) => (
          <Button.Submit {...props} disabled={error || (!startDate && !endDate)}>
            <Icon.Check mr="2" />
            Done
          </Button.Submit>
        )}
        onCancel={() => onChange && onChange(prevValue)}
        onOpen={() => setPrevValue(value)}
        body={RenderDates}
      />

      {endDisabled ? (
        <Info>The end date cannot be changed!</Info>
      ) : (
        startDisabled && <Info>The start date cannot be changed!</Info>
      )}
    </PreviewContainer>
  )
}

export default DatePicker

const PreviewContainer = tw.div`flex flex-col gap-2 w-full items-center`
const ModalButtonContainer = tw.div`flex flex-wrap gap-2 w-full p-2 hover:bg-bismark-200 rounded-md`
const InputContainer = tw.div`flex-col flex-1`
const ModalBody = tw.div`flex flex-col items-center w-full gap-2`
const Calendars = tw.div`flex justify-center items-center sm:items-start w-full h-auto sm:h-full py-12 gap-4 flex-col sm:flex-row`
const Overlay = tw.div`absolute top-0 left-0 right-0 bottom-0 bg-gray opacity-50 z-10`
const Footer = tw.div`sticky bottom-0 flex flex-col w-full items-center gap-1 p-3 bg-gray-300 height[min-content] min-height[3.25rem] z-20`
const Error = tw.div`sticky bottom-0 text-red`
const ClearDatesButton = tw(Button.Warning)`absolute right-3`
const DateWrapper = styled.div`
  ${tw`relative flex flex-col`}
  & > * > * > ${Overlay} {
    ${({ disabled }) => (disabled ? tw`visible` : tw`invisible`)}
  }
`
const Duration = tw.div`text-lg text-bismark-700 italic`
const DisplayDate = styled.div`
  ${tw`py-1 px-2 flex justify-center items-center font-light rounded-sm minWidth[10rem] border border-gray-300 whitespace-nowrap`}
  ${({ disabled }) => (disabled ? tw`bg-gray-50 text-gray` : tw`bg-white-pure`)}
`

const getDuration = (start, end) => {
  if (!(isDate(start) && isDate(end))) return ''
  return formatDuration(
    intervalToDuration({
      start: new Date(start),
      end: new Date(end)
    }),
    {
      format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
      delimiter: ', '
    }
  )
}
