import React from 'react'
import tw, { styled } from 'twin.macro'
import Icon from '../../styles/Icons'
import { Input } from '../../styles/Layout'
import { Checkbox } from '../Checkbox'
import RangeSlider from '../RangeSlider'
import { LabelSwitch } from '../LabelSwitch'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useAreas } from '../../contexts/AreasContext'

export const EnrichmentSettings = () => {
  const { activeCity } = useAreas()
  const { control } = useFormContext()
  const { fetchOptions } = useWatch({ control })

  return (
    <Container>
      <Controller
        control={control}
        name="generateCandidates"
        render={({ field: { onChange, value } }) => (
          <LabelSwitch
            checked={value}
            color="blue"
            disabled={true || !activeCity} // disabled for now
            size="md"
            onToggle={onChange}
          >
            <Row>
              <Icon.Sparkles size="sm" mr="2" />
              Only Locations
            </Row>
            <Row>
              <Icon.Pin size="sm" mr="2" />
              Add Checkpoints
            </Row>
          </LabelSwitch>
        )}
      />
      <Controller
        control={control}
        name={'locationLimit'}
        render={({ field }) => (
          <Col>
            <Input.Label tw="gap-1">
              {'Max Locations per Cell:'} <pre>{field.value}</pre>
            </Input.Label>
            <RangeSlider
              {...field}
              onChange={({ target }) => field.onChange(Number(target.value))}
              color="blue"
              min={10}
              max={1000}
              step="10"
              tickEvery={3}
            />
          </Col>
        )}
      />
      <Col>
        <Input.Label>Get Locations From:</Input.Label>
        <Controller
          control={control}
          name="fetchOptions.google"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              disabled={true} // Api closed
              color="blue"
              label="Playable Locations"
              onCheck={onChange}
              tw="font-mono"
            />
          )}
        />
        <Controller
          control={control}
          name="fetchOptions.wiki"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              color="blue"
              label="Wikipedia"
              onCheck={onChange}
              tw="font-mono"
            />
          )}
        />
      </Col>

      <Col>
        <Input.Label>Add More Data:</Input.Label>
        <Controller
          control={control}
          name="enrichOptions.google"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              color="blue"
              disabled={true || !fetchOptions.google}
              label="Google Place Data"
              onCheck={onChange}
              tw="font-mono"
            />
          )}
        />
        <Controller
          control={control}
          name="enrichOptions.elevation"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              disabled={true}
              color="blue"
              label="Elevation"
              onCheck={onChange}
              tw="font-mono"
            />
          )}
        />
        <Controller
          control={control}
          name="enrichOptions.mapbox"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              color="blue"
              disabled={true}
              label="Mapbox Tags"
              onCheck={onChange}
              tw="font-mono"
            />
          )}
        />
        <Controller
          control={control}
          name="enrichOptions.wiki"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              color="blue"
              disabled={true || !fetchOptions.wiki}
              label="Wiki Data"
              onCheck={onChange}
              tw="font-mono"
            />
          )}
        />
      </Col>
    </Container>
  )
}

const Container = tw.div`relative flex flex-col w-full gap-4 p-4 px-10 bg-bismark-100 text-bismark-800 text-sm rounded-md shadow-inner`
const Row = tw.div`flex items-center`
const Col = styled.div`
  ${tw`flex flex-col w-full gap-1`}
  & > :not(${Input.Label}) {
    ${tw`px-2`}
  }
`
