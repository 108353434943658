import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import tw, { theme } from 'twin.macro'
import { useCorporate } from '../../../contexts/CorporateContext'
import { EventLocationType } from '../../../enums'
import { Action } from '../../../layout/List'
import Icon from '../../../styles/Icons'
import { Button, Card } from '../../../styles/Layout'
import { AddARLocation } from './AddARLocation'
import { AddHiddenLocation } from './AddHiddenLocation'
import { AddTriviaLocation } from './AddTriviaLocation'

export const AddLocation = (location) => {
  const { saveEventLocation, stopEditingEventLocation } = useCorporate()
  const methods = useForm({
    defaultValues: { active: true, ...location },
    mode: 'all'
  })
  const { handleSubmit, setValue, watch } = methods
  const { active } = watch()
  const activeChanged = active ? !location.active : location.active
  const importing = location.type === EventLocationType.HIDDEN

  // Save location
  const onSubmit = async (formData) => {
    await saveEventLocation({ ...location, ...formData })
  }

  // Save locations (multiple)
  const onImportLocations = async (locations) => {
    await Promise.all(locations.map(async (location) => await saveEventLocation(location)))
  }

  const renderForm = (type) => {
    switch (type) {
      case EventLocationType.AR: {
        return <AddARLocation onSubmit={handleSubmit(onSubmit)} />
      }
      case EventLocationType.HIDDEN: {
        return <AddHiddenLocation onSubmit={onImportLocations} />
      }
      case EventLocationType.LOGO: {
        return <AddTriviaLocation isLogo={true} onSubmit={handleSubmit(onSubmit)} />
      }
      case EventLocationType.TRIVIA: {
        return <AddTriviaLocation onSubmit={handleSubmit(onSubmit)} />
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <Card.Container
        color={theme`colors.terracotta.400`}
        xHover={theme`colors.terracotta.500`}
        xActive={theme`colors.terracotta.600`}
        onClose={stopEditingEventLocation}
        size="sm"
        header={importing ? `Import ${location.type} Locations` : `Add ${location.type} Location`}
        tw="overflow-hidden"
      >
        <Action.Header color={theme`colors.terracotta.200`}>
          <Action.Active active={active} changed={activeChanged} />
          <Action.More
            tw="hover:bg-terracotta-300"
            content={
              <>
                {active ? (
                  <Button.Primary
                    size="sm"
                    onClick={() => setValue('active', false)}
                    disabled={false}
                    ring
                  >
                    <Icon.Moon size="sm" mr="2" />
                    Deactivate Location
                  </Button.Primary>
                ) : (
                  <Button.Secondary
                    size="sm"
                    onClick={() => setValue('active', true)}
                    disabled={false}
                    ring
                  >
                    <Icon.Sun size="sm" mr="2" />
                    Activate Location
                  </Button.Secondary>
                )}
              </>
            }
          />
        </Action.Header>
        <Container>{renderForm(location.type)}</Container>
      </Card.Container>
    </FormProvider>
  )
}

const Container = tw.div`relative flex flex-col h-full w-full overflow-scroll`
